import { useTranslation } from 'react-i18next'
import Switch from '../../../../../../shared/components/atoms/buttons/Switch'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateFormSetting from '../../../QuestionPage/hooks/useUpdateFormSetting'

interface IFormLogicSwitch { }

const FormLogicSwitch: React.FC<IFormLogicSwitch> = () => {
  const { data } = useGetFormData()
  const { t } = useTranslation()

  const updateFormSettings = useUpdateFormSetting()

  const hasLogic = !!data?.template.__settings?.logic

  const handleEnableFormLogic = () => {
    updateFormSettings('logic', !hasLogic)
  }
  return (
    <div className="gap-x-4 flex">
      <Switch onChange={handleEnableFormLogic} checked={hasLogic} />
      <p className="text-primary-gray text-sm">{t('forms.logic_enable')}</p>
    </div>
  )
}

export default FormLogicSwitch
