import { dispatch } from 'use-bus'
import { useFormContext } from '../../../core/providers/FormProvider'
import Question from './Question'
import QuestionContext from './QuestionContext/QuestionContext'
import { useFormSection } from './providers/FormSectionProvider'
import { useQuestionsScroll } from './hooks/useQuestionsScroll'
import useGetQuestionIndex from './hooks/useGetQuestionIndex'
import { useFormLogic } from './providers/FormLogicProvider'
import ProductSections from './sections/ProductSection/ProductSections'

export type DispatchableEvent = {
  key: string,
  dispatch: Function
}

export const FormEvents: Record<string, DispatchableEvent> = {
  QuestionHasError: {
    key: 'form-questions/question-has-error',
    dispatch: (uuid: string) => dispatch({ type: FormEvents.QuestionHasError.key, payload: uuid }),
  },
  QuestionAnswered: {
    key: 'form-questions/question-answered',
    dispatch: (uuid: string) => dispatch({ type: FormEvents.QuestionAnswered.key, payload: uuid }),
  },
  FocusQuestion: {
    key: 'form-questions/focus-question',
    dispatch: (uuid: string) => dispatch({ type: FormEvents.FocusQuestion.key, payload: uuid }),
  },
} as const

type QuestionsProps = {
}

const Questions = () => {
  const { responseConfig: [responseConfig] } = useFormContext()
  const { submission: [submission] } = useFormContext()
  const { currentSection } = useFormSection()

  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  const questionsRef = useQuestionsScroll()
  const getQuestionIndex = useGetQuestionIndex()
  const { allEnabledQuestions } = useFormLogic()

  const questionsToDisplay = currentSection?.type === 'PRODUCT' && !isFunkeOrAdvario
    ? allEnabledQuestions.filter((q) => q.sectionType === 'PRODUCT')
    : currentSection?.processableFields

  if (currentSection?.type === 'PRODUCT' && !isFunkeOrAdvario) {
    const productSections = submission?.sections.filter((s) => s.type === 'PRODUCT')
    if (!productSections) return null
    return <ProductSections productSections={productSections} />
  }

  return (
    <div className="flex flex-col overflow-y-visible h-auto mt-12">
      {questionsToDisplay?.map((question) => (
        <QuestionContext
          key={question.__uuid}
          questionText={question.label || ''}
          subtitle={question.subtitle || ''}
          required={question.__settings?.misc?.hidden === true ? false : question.validation.required?.value}
          questionHint={question.hint}
          question={question}
          ref={(item: HTMLDivElement) => { questionsRef.current[getQuestionIndex(question)] = item }}
          hidden={question.__settings?.misc?.hidden === true}
        >
          <Question question={question} />
        </QuestionContext>
      ))}

    </div>
  )
}

export default Questions
