import { MdClose } from 'react-icons/md'
import { AssignmentType } from '../../../../types/shared/AssignmentTypes'
import UserAvatar from '../../atoms/UserAvatar/UserAvatar'

interface IAssigneePill {
  assignment: AssignmentType
  onClick: () => void
  multipleAssignees: boolean
}

const AssigneePill: React.FC<IAssigneePill> = ({
  assignment,
  onClick,
  multipleAssignees,
}) => {
  const { email } = assignment.user

  return (
    <div className="flex gap-2 pr-2 text-sm bg-gray-100 rounded-full" key={`${assignment.id}-assign`}>
      <UserAvatar name={email} />
      <div className="whitespace-nowrap">{email}</div>
      {multipleAssignees
        && (
          <button
            type="button"
            onClick={onClick}
            className="font-bold text-gray-300 hover:text-gray-400 text-lg"
            data-cy="assignee-remove-user"
          >
            <MdClose />
          </button>
        )}
    </div>
  )
}

export default AssigneePill
