import { useTranslation } from 'react-i18next'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import Menu from '../../../../shared/components/organisms/menus/Menu'
import { dateTimeFormatter } from '../../../../shared/constants/dateFormat'
import { Workflow } from '../../../../types/Workflow/Workflow'
import useOpenWorkflowBuilder from '../../hooks/useOpenWorkflowBuilder'
import useOpenWorkflowDetails from '../../hooks/useOpenWorkflowDetails'
import useWorkflowMutations from '../../hooks/useWorkflowMutations'

type WorkflowsTableRowProps = {
  workflow: Workflow
}

const WorkflowsTableRow: React.FC<WorkflowsTableRowProps> = ({ workflow }) => {
  const { t } = useTranslation()
  const { remove } = useWorkflowMutations()
  const goToWorkflowBuilder = useOpenWorkflowBuilder(workflow)
  const goToWorkflowDetails = useOpenWorkflowDetails(workflow)

  return (
    <TableRow
      onClick={() => goToWorkflowDetails()}
    >
      <TableCell>
        {workflow.name}
      </TableCell>
      <TableCell>{dateTimeFormatter(workflow.created_at, false)}</TableCell>
      <TableCell>{dateTimeFormatter(workflow.updated_at, false)}</TableCell>
      <TableCell>{workflow.published_at && dateTimeFormatter(workflow.published_at, false)}</TableCell>
      <TableCell>
        <Menu menuItems={[{ name: t('general.edit'), onClick: goToWorkflowBuilder }, { name: t('general.Delete'), onClick: () => remove(workflow) }]} />
      </TableCell>
    </TableRow>
  )
}

export default WorkflowsTableRow
