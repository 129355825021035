import { useState } from 'react'
import { MdSend } from 'react-icons/md'
import { useMutation, useQueryClient } from 'react-query'
import TipTapEditor from '../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import messengerQueryKeys from '../../../Messenger/messengerQueryKeys'
import { apiPostChatMessage } from '../../../Messenger/service'

const SubmissionPageMessageInput = ({ modelId, model }: { modelId: string, model: string }) => {
  const [message, setMessage] = useState<string>()
  const client = useQueryClient()

  const { mutate } = useMutation(() => apiPostChatMessage(
    {
      model_type: model, model_id: modelId, text_message: message,
    },
  ), {
    onSuccess: () => {
      client.refetchQueries([messengerQueryKeys.messages, model, modelId])
    },
    onMutate: () => setMessage(''),
  })

  return (
    <div className="py-2 flex space-x-4 items-center">
      <TipTapEditor
        onEnterShift={mutate}
        content={message}
        placeholder="Message"
        className="shadow"
        onUpdate={(e) => setMessage(e)}
      />
      <button type="button" onClick={() => mutate()}>
        <MdSend />
      </button>
    </div>
  )
}

export default SubmissionPageMessageInput
