import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../../../shared/components/atoms/Checkbox/Checkbox'
import Label from '../../../../../../shared/components/atoms/Label'
import useGetCustomerDocuments from '../../../../../Company/components/documents/hooks/useGetCustomerDocuments'
import useGetRequisitionDocuments from '../../hooks/useGetRequisitionDocuments'
import useSetRequired from '../../hooks/useSetRequired'

interface IGeneralInformationRequiredDocs {
  disabled?: boolean
}

const GeneralInformationRequiredDocs: FC<IGeneralInformationRequiredDocs> = ({ disabled }) => {
  const { t } = useTranslation()
  const { data: docs, isLoading } = useGetCustomerDocuments()
  const { data: requisitionDocs, isLoading: isLoadingDocs } = useGetRequisitionDocuments()
  const toggleDocument = useSetRequired()

  if (isLoading || isLoadingDocs) return <div>Loading ...</div>

  const isAttached = (id: number) => (requisitionDocs?.filter((rd) => rd.id === id)?.length || 0) > 0 || false

  return (
    <div className="flex flex-col space-y-6">

      <Label label={t('req.required-documents', 'Required documents')} />

      {docs?.map((d) => (
        <Checkbox
          disabled={disabled}
          key={d.id}
          id={d.id.toString()}
          name={d.name}
          label={d.name}
          defaultChecked={isAttached(d.id)}
          onChange={(v) => toggleDocument({ ...d, attached: !v })}
        />
      ))}

    </div>
  )
}
export default GeneralInformationRequiredDocs
