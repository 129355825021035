import React, {
  createContext, useContext, useMemo,
} from 'react'

import { useTranslation } from 'react-i18next'
import { FormSectionType, FormTemplateType } from '../../../../types/Forms/formTypes'

const getFinalSectionConfig = (): FormSectionType => (
  {
    __uuid: 'Confirmation',
    name: '',
    type: 'FINAL',
    fields: [],
    actions: { edit: false, remove: false, rename: false },
    max_sections: 1,
    loop: {
      loopable: false,
      __looped: false,
    },
  }
)

type IFormDataContext = {
  form?: FormTemplateType
  sectionsIndex: FormSectionType[]
  navigationSectionsIndex: FormSectionType[]
}

const formDataContext = createContext({} as IFormDataContext)

type FormDataProviderProps = React.PropsWithChildren<{ form?: FormTemplateType }>

const FormDataProvider: React.FC<FormDataProviderProps> = ({ children, form }) => {
  const { t } = useTranslation()

  const sectionsIndex: FormSectionType[] = useMemo(
    () => [...form?.sections || [], { ...getFinalSectionConfig(), name: t('forms.confirmation_section') }],
    [form?.sections],
  )
  const navigationSectionsIndex: FormSectionType[] = useMemo(
    () => {
      const newFormSections = form?.sections.reduce((sections: FormSectionType[], currentSection: FormSectionType) => {
        if (currentSection.type === 'PRODUCT' && sections.filter((s) => s.type === 'PRODUCT').length > 0) {
          return [...sections]
        }
        return [...sections, currentSection]
      }, [])

      return [...newFormSections || [], { ...getFinalSectionConfig(), name: t('forms.confirmation_section') }]
    },

    [form?.sections],
  )

  const values: IFormDataContext = useMemo(() => ({
    form,
    sectionsIndex,
    navigationSectionsIndex,
  }), [form, sectionsIndex, navigationSectionsIndex])

  return (
    <formDataContext.Provider value={values}>
      {children}
    </formDataContext.Provider>
  )
}

export default FormDataProvider

export const useFormData = () => useContext(formDataContext)
