import { useTranslation } from 'react-i18next'

interface ITableHeader {
  isMinified: boolean
}

const TableHeader: React.FC<ITableHeader> = ({ isMinified }) => {
  const { t } = useTranslation()

  return (
    <div className={`flex text-secondary-gray font-medium border-b border-border-gray py-1 gap-x-3 ${isMinified && 'text-xs'}`}>
      <p className="w-2/12">{(t('general.offer_date')).toUpperCase()}</p>
      <p className="w-4/12">{(t('generic.status')).toUpperCase()}</p>
      {!isMinified && <p className="w-2/12">{(t('requisition_offers.list_offer_price')).toUpperCase()}</p>}
      <p className="w-4/12">{(t('requisition_definition.requisition_name')).toUpperCase()}</p>
    </div>
  )
}

export default TableHeader
