/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useSubmitOffer from '../hooks/useSubmitOffer'

interface props {
  validateForm: () => boolean
}

const SubmitOfferButton = ({ validateForm }: props) => {
  const { t } = useTranslation()
  const [validatingOffer, setValidatingOffer] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleSubmitOffer = useSubmitOffer(validateForm, setValidatingOffer)

  const handleDelayedClick = () => {
    setValidatingOffer(true)
    setTimeout(() => { buttonRef.current?.click() }, 1000)
  }

  return (
    <>
      <Button
        variant="solid"
        color="lhotse-orange"
        className="w-full justify-center flex h-full"
        onClick={handleDelayedClick}
        dataCy="submit-offer-button"
      >
        {validatingOffer && <div><CoreLoadingIcon /></div>}
        <p className="whitespace-normal">
          {t('supplier_view.offer_submit')}
        </p>
      </Button>
      <button
        type="button"
        className="hidden"
        onClick={handleSubmitOffer}
        ref={buttonRef}
      />
    </>
  )
}

export default SubmitOfferButton
