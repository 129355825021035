import React, { ForwardedRef, forwardRef } from 'react'
import ReactFlow, { Background, Controls } from 'reactflow'

import classnames from 'classnames'
import 'reactflow/dist/style.css'
import useWorkflowChartStructure from '../hooks/useWorkflowChartStructure'
import WorkflowChartLayout from './WorkflowChartLayout'
import { useWorkflowChart } from '../providers/WorkflowChartProvider'

// todo enable tailwind config import (by linking it inside node modules)
// import tailwindConfig from '../../../../tailwind.config'

// const Styles = resolveConfig(tailwindConfig)

const onInit = (reactFlowInstance: any) => { }

export type WorkflowChartProps = React.PropsWithChildren<{}> & React.HTMLAttributes<HTMLDivElement>

export const WorkflowChart = forwardRef(({
  children, className, ...props
}: WorkflowChartProps, flowRef: ForwardedRef<HTMLDivElement>) => {
  const {
    nodes, setNodes, edges, setEdges, onEdgesChange, onNodesChange,
  } = useWorkflowChartStructure()

  const { nodeTypes, edgeTypes } = useWorkflowChart()

  return (
    <div
      ref={flowRef}
      className={classnames('w-full h-full  bg-white', className)}
      {...props}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onInit={onInit}
        fitView
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        zoomOnScroll={false}
        preventScrolling={false}
      >
        <Controls showInteractive={false} />
        {/* <Background color={Styles.theme?.colors?.['rock-gray']} gap={16} /> */}
        <Background color="#aaa" gap={16} />
        {children}
        <WorkflowChartLayout updateNodes={setNodes} updateEdges={setEdges} />
      </ReactFlow>
    </div>
  )
})

WorkflowChart.displayName = 'Workflow chart'

export default WorkflowChart
