import { MdCheck, MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import Button from '../../atoms/buttons/Button'
import ButtonIcon from '../../atoms/buttons/icon/ButtonIcon'

interface ISaveCancelFormButtons {
  onCancel: () => void,
  onSave?: () => void,
  cancelString?: string,
  saveString?: string,
  icons?: boolean,
  className?: string
  isSubmitButton?: boolean
  canSave?: boolean
}

const SaveCancelFormButtons: React.FC<ISaveCancelFormButtons> = ({
  onCancel, onSave, cancelString, saveString, icons, className = '', isSubmitButton, canSave,
}) => {
  const { t } = useTranslation()
  return (
    <div className={`flex justify-end gap-2 ${className}`}>
      {icons
        ? (
          <>
            <ButtonIcon icon={MdClose} onClick={onCancel} />
            <ButtonIcon icon={MdCheck} onClick={onSave} />
          </>
        )
        : (
          <>
            <Button
              color="secondary-gray"
              onClick={onCancel}
              variant="outlined"
              className="text-xs"
            >
              {t(cancelString || 'generic.cancel')}
            </Button>
            {isSubmitButton
              ? (
                <Button variant="solid" color="lhotse-orange" type="submit" className="text-xs" dataCy="confirm-button">
                  {t(saveString || 'generic.confirm')}
                </Button>
              )
              : (
                <Button
                  variant="solid"
                  color="sky-blue-light"
                  onClick={onSave}
                  className="text-xs"
                  dataCy="confirm-button"
                  disabled={canSave === false}
                >
                  {t(saveString || 'generic.confirm')}
                </Button>
              )}
          </>
        )}
    </div>
  )
}

export default SaveCancelFormButtons
