import { Dispatch, SetStateAction } from 'react'
import { useMutation } from 'react-query'
import { apiAssignUser } from '../../../../../modules/Basecamp/services/BasecampService'
import { AssignmentType } from '../../../../../types/shared/AssignmentTypes'
import toastr from '../../toastr/toastr'

const useAssignUser = (
  {
    setLocalAssignments, modelId, origin, model = 'requisition',
  }: {
    setLocalAssignments?: Dispatch<SetStateAction<AssignmentType[] | undefined>>,
    modelId: string | number; origin: string,
    model? : 'requisition'|'form_submission'|'intake-flow' },
) => useMutation((userEmail: string) => apiAssignUser({
  model,
  id: modelId,
  email: userEmail,
  message: '',
  origin,
}), {
  onSuccess: (newData) => {
    setLocalAssignments && setLocalAssignments((current) => (current ? [...current, newData] : current))
  },
  onError: () => {
    toastr.error(undefined, 'errorAssignmentsAdding')
  },
})

export default useAssignUser
