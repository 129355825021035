// REFACTOR
import { useTranslation } from 'react-i18next'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import useGetApprovalStatus from '../../../components/layout/ReqTopbar/hooks/useGetApprovalStatus'
import useShowApproval from '../../../components/layout/ReqTopbar/hooks/useShowApproval'
import ReqTopbarApprovalPendingText from '../../../components/layout/ReqTopbar/ReqTopbarApprovalPendingText'
import useGetRequisitionData from '../../Definition/hooks/useGetRequisition'
import OfferViewHeaderRow from './HeaderRow'
import OfferViewSectionsWrapper from './OfferViewSections/OfferViewSectionsWrapper'
import OfferVisibilityProvider from '../providers/OfferVisibilityProvider'
import useIsSapPrSent from '../../../hooks/useIsSapPrSent'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import Warning from '../../../../../shared/components/organisms/WarningBanner/WarningBanner'

interface IOfferViewContainer {
  useLastCallState: [
    number[] | undefined,
    React.Dispatch<React.SetStateAction<number[] | undefined>>
  ]
}

const OfferViewContainer: React.FC<IOfferViewContainer> = ({ useLastCallState }) => {
  const { t } = useTranslation()
  const requisitionId = useGetRequisitionId()

  const { data: requisitionData } = useGetRequisitionData()

  const { data } = useGetApprovalStatus(requisitionId)

  const approvalForAward = useShowApproval('award', requisitionData?.status || 'DRAFT')
  const approvalPending = data?.approval_status === 'needed_not_approved' && approvalForAward
  const approvalPermission = !!data?.can_approve
  const showSuccessMessage = window.location.host.includes('funke')
  const isSapPrSent = useIsSapPrSent()

  const showOffers = approvalPermission || !approvalPending
  // const [visibleOffers, setVisibleOffers] = useState([0])

  return (
    <div className="flex flex-col h-auto relative w-full">
      {isSapPrSent && showSuccessMessage && (
        <Warning severity="success">
          <div dangerouslySetInnerHTML={{ __html: `<div>${t('req.sap_transfer_funke_message')}</div>` }} />
          <Button type="button" className="btn-secondary mt-4" onClick={() => window.open('https://s1-eu.ariba.com/gb/?realm=FUNKECHILDSITE&locale=de_DE', '_blank')}>{t('req.sap_transfer_funke_cta', 'Go to FUNKEPRO')}</Button>
        </Warning>
      )}
      {requisitionData?.status === 'OPEN' && (
        <Warning severity="info" className="mx-auto">
          <div dangerouslySetInnerHTML={{ __html: `<div>${t('req.offer_acceptance_after_closing', 'Sie können erst ein Angebot akzeptieren, sobald die Angebotsabgabefrist abgelaufen ist.')}</div>` }} />
        </Warning>
      )}
      {/* <div className="h-12 bg-black absolute l-0 left-0 right-0" /> */}
      {showOffers
        ? (
          <OfferVisibilityProvider>
            <OfferViewHeaderRow useLastCallState={useLastCallState} />
            <OfferViewSectionsWrapper />
          </OfferVisibilityProvider>
        )
        : (
          <div className="flex h-full w-[1200px] 2xl:w-[1680px] justify-center text-gray-600 my-6 text-xl">
            <ReqTopbarApprovalPendingText text={t('award.offer_selection_release_pending')} />
          </div>
        )}
    </div>
  )
}

export default OfferViewContainer
