import { useTranslation } from 'react-i18next'
import useGetCalculatedPrices from './hooks/useGetCalculatedPrices'
import { priceToGermanPriceString } from '../../../../shared/constants/currencyFormat'

const ShippingPrice: React.FC = () => {
  const { data: prices } = useGetCalculatedPrices()
  const { t } = useTranslation()

  if (!prices?.tax) return null

  return (
    <div className="flex gap-x-3 w-max min-w-1/4 justify-center text-right items-center">
      <div className="flex flex-col w-max justify-center">
        <p className="text-2xl">
          {priceToGermanPriceString(prices?.tax)}
        </p>
        <p className="text-xs mt-1 text-left">{t('generic.tax')}</p>
      </div>
    </div>
  )
}

export default ShippingPrice
