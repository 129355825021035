import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'

import IntakeSidepanelSummary from './IntakeSidepanel/IntakeSidepanelSummary'

import { StickySidePanel } from '../../../shared/components/layout/StickySidePanel'
import useTabs from '../../../shared/hooks/useTabs'
import useIntakeSidebar from '../hooks/useIntakeSidebar'
import IntakeSidepanelBlockDetails from './IntakeSidepanel/IntakeSidepanelBlockDetails'
import IntakeSidepanelChangelog from './IntakeSidepanel/IntakeSidepanelChangeLog'
import IntakeSidepanelComments from './IntakeSidepanel/IntakeSidepanelComments'
import IntakeSidePanelActiveStep from './IntakeSidepanel/IntakeSidePanelActiveStep/IntakeSidePanelActiveStep'

export const IntakeFlowOverviewPageSidePanel = () => {
  const {
    activeWorkflow, selectedBlock,
  } = useIntakeFlow()
  const { t } = useTranslation()

  const tabsData = useMemo(() => [
    {
      id: 'summary',
      title: t('intake.sidepanel_summary', 'Summary'),
      current: !selectedBlock,
      TabComponent: IntakeSidepanelSummary,
    },
    selectedBlock && {
      title: t('intake.sidepanel_block_details', 'Details'),
      current: true,
      TabComponent: IntakeSidepanelBlockDetails,
    },
    {
      title: t('intake.sidepanel_comments', 'Comments'),
      current: false,
      TabComponent: IntakeSidepanelComments,
    },
    {
      title: t('intake.sidepanel_changelog', 'Change log'),
      current: false,
      TabComponent: IntakeSidepanelChangelog,
    },
  ], [selectedBlock])

  const {
    navItems, changeTab, current: selectedTab, updateTabs,
  } = useTabs(tabsData)

  useEffect(() => {
    updateTabs(tabsData)
  }, [tabsData])

  const TabsBody = selectedTab?.TabComponent

  const { toggleMinified, minified } = useIntakeSidebar()

  return (
    <StickySidePanel
      open={!minified}
      onToggle={toggleMinified}
      tabs={navItems}
      onTabChange={changeTab}
      title={activeWorkflow?.status}
      header={(
        <div className="flex flex-row">
          {/* <Assignees origin="intake-flow" model="intake-flow" modelId={intakeId} /> */}
        </div>
      )}
      footer={(
        selectedTab?.id === 'summary'
        && <IntakeSidePanelActiveStep />
        )}
    >
      <div className="flex-col flex justify-between h-full flex-auto">
        <div className=" flex  h-full">{TabsBody && (<TabsBody />)}</div>
      </div>
    </StickySidePanel>
  )
}

export default IntakeFlowOverviewPageSidePanel
