import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabbedNavItemType } from '../../../types/shared/TabbedNavItemType'
import scrollIntoViewWithOffset from '../helpers/scrollIntoViewWithOffset'
import useIsInViewport from '../../../shared/hooks/useIsInViewport'

export const useIntakeFlowTabs = ({ offset }: {offset: number} = { offset: 0 }) => {
  const { t } = useTranslation()
  const flowchartRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null)
  const detailsRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null)

  const { isInViewport: isFlowchartInViewport, setViewportObserverFor: setFlowchartViewportObserver } = useIsInViewport({ rootMargin: '-215px' })
  const { isInViewport: areDetailsInViewport, setViewportObserverFor: setDetailsViewportObserver } = useIsInViewport()

  const [tabs, setTabs] = useState<TabbedNavItemType[]>([
    {
      tabIndex: 0,
      title: t('intake.tab_overview_section'),
      current: true,
    },
    {
      tabIndex: 1,
      title: t('intake.tab_details_section'),
      current: false,
    },
  ])

  const setSelectedTab = (tabIndex: number) => {
    const newNavItems = tabs.map((item: TabbedNavItemType) => ({
      ...item,
      current: item.tabIndex === tabIndex,
    }))
    setTabs(newNavItems)
  }

  const onTabChange = (tabIndex: number) => {
    setSelectedTab(tabIndex)

    if (tabIndex === 0) {
      flowchartRef.current && scrollIntoViewWithOffset(flowchartRef.current, offset)
    } else {
      detailsRef.current && scrollIntoViewWithOffset(detailsRef.current, offset)
    }
  }

  useEffect(() => {
    if (isFlowchartInViewport && areDetailsInViewport) return

    if (isFlowchartInViewport) {
      setSelectedTab(0)
    } else if (areDetailsInViewport) {
      setSelectedTab(1)
    }
  }, [isFlowchartInViewport, areDetailsInViewport])

  return {
    tabs,
    onTabChange,
    flowRef: (element: HTMLDivElement) => {
      flowchartRef.current = element
      setFlowchartViewportObserver(element)
    },
    detailsRef: (element: HTMLDivElement) => {
      detailsRef.current = element
      setDetailsViewportObserver(element)
    },
  }
}

export default useIntakeFlowTabs
