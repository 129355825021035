import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import toastr from '../../../../shared/components/organisms/toastr/toastr'
import ReqDefQueryKeys from '../../../Requisition/subModules/Definition/constants/queryKeys'
import { apiPostCompanyAddress } from '../../services/CompanyAddressesService'
import { CompanyAddressType } from '../../../../types/Company/AddressTypes'
import AddressForm from './AddressForm/AddressForm'

export interface IAddressesFormCreate {
  onSuccess?: (companyAddresses: CompanyAddressType[]) => void
  onSubmit?: (companyAddress: CompanyAddressType) => void
}

const AddressesFormCreate: React.FC<IAddressesFormCreate> = ({ onSuccess, onSubmit }) => {
  const cache = useQueryClient()
  const { modal } = useUtils()

  const { mutate: postMutate } = useMutation(
    (item: CompanyAddressType) => apiPostCompanyAddress(item),
    {
      onSuccess: (newData) => cache.setQueryData(
        [ReqDefQueryKeys.companyAddressesData],
        () => {
          toastr.success('success.address_added', 'successAddressAdded')
          modal.set({ isOpen: false })
          onSuccess?.(newData.data)
          return newData.data
        },
      ),
    },
  )

  return (
    <AddressForm
      onCancel={() => modal.set({ isOpen: false })}
      onSubmit={onSubmit || postMutate}
    />
  )
}

export default AddressesFormCreate
