import { ReactElement } from 'react'
import { MdOutlineModeComment } from 'react-icons/md'
import { useUtils } from '../../../../../../../../core/providers/UtilsProvider'
import { LineItemItemAttributeType, LineItemType } from '../../../../../../../../types/shared/LineItemDataTypes'
import { OffersOfferType } from '../../../../../../../../types/shared/OfferDataTypes'
import Tooltip from '../../../../../../../../shared/components/organisms/tooltip/Tooltip'
import BannerModal from '../../../../../../../SupplierOffer/components/Footer/Banner/BannerModal'
import SingleLineItemValue from './SingleLineItemValue'

export type LineItemAwardScreenType = LineItemType & { children?: ReactElement }

interface ISingleLineItem {
  offer: OffersOfferType
  itemAttribute: LineItemItemAttributeType
}

const SingleLineItem: React.FC<ISingleLineItem> = ({
  offer, itemAttribute,
}) => {
  const utils = useUtils()

  const singleLineItem = offer?.line_items
    ?.find(
      (lineItem) => itemAttribute.id === lineItem.item_attribute_id,
    ) as LineItemAwardScreenType | undefined

  const isLineItemTooLong = (singleLineItem?.value?.length || 0) > 5

  const lineItemComment = singleLineItem?.comment?.comment || ''

  const onClick = () => {
    if (isLineItemTooLong) {
      utils.modal.set({
        content: <BannerModal reason={singleLineItem?.value || ''} />,
        isOpen: true,
        title: itemAttribute?.description,
      })
    }
  }

  return (
    <Tooltip
      className="flex justify-between w-full items-center px-4 min-h-[24px]"
      tooltip={lineItemComment}
      showCondition={!!lineItemComment}
    >
      <button
        type="button"
        className={`${!isLineItemTooLong && 'cursor-text'} flex items-center w-full`}
        onClick={onClick}
      >
        <SingleLineItemValue singleLineItem={singleLineItem} />
        {lineItemComment && <MdOutlineModeComment className="text-gray-400 min-w-[16px]" />}
      </button>
    </Tooltip>
  )
}

export default SingleLineItem
