import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import FindTenantForm from './FindTenantForm'

interface props {
  setLinks: Dispatch<SetStateAction<number[]>>
  setSubmitted: Dispatch<SetStateAction<boolean>>
}
const FindTenant = ({ setLinks, setSubmitted }: props) => {
  const { t } = useTranslation()

  return (
    <>
      <p className="text-gray-500 pb-3">
        {t('login.enter_address')}
      </p>
      <FindTenantForm
        setLinks={setLinks}
        setSubmitted={setSubmitted}
      />
    </>
  )
}

export default FindTenant
