import { useMemo } from 'react'
import useGetCalculatedPrices from './useGetCalculatedPrices'

export const useGetCalculatedLineItems = (offerToken: string = '') => {
  const { data: prices } = useGetCalculatedPrices(offerToken)

  const lineItems = useMemo(() => prices?.product_prices.flatMap(
    ({ lineItemsPrices }) => Object.values(lineItemsPrices),
  ) || [], [prices])

  return {
    lineItems,
    hasAnyLineItems: lineItems.length > 0,
    totalNetShipping: lineItems.map(({ netTotalShippingCost }) => netTotalShippingCost).reduce((shipping, sum) => shipping + sum, 0),
    getById: (lineItemId: number | undefined) => lineItems.find((lineItem) => lineItem.lineItemId === lineItemId),
  }
}

export default useGetCalculatedLineItems
