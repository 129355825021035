import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import Table from '../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../shared/components/atoms/SharedTable/TableContainer'
import useGetCompanyForms from '../../hooks/useGetCompanyForms'
import CreateFormButton from '../buttons/CreateFormButton'
import CompanyFormsListRow from './CompanyFormsListRow'
import TableHead from '../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../shared/components/atoms/SharedTable/TableHeader'

const CompanyFormsList: React.FC = () => {
  const { data: companyForms, isLoading } = useGetCompanyForms()
  const { t } = useTranslation()

  if (isLoading) return <CoreLoadingIcon />

  if (!companyForms?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-y-10">
        <div className="flex flex-col gap-y-4 items-center">
          <p className="text-lg">{t('forms.no_forms')}</p>
          <p className="text-secondary-gray text-sm">You dont have any forms for your organization. Create a new form.</p>
        </div>
        <CreateFormButton />
      </div>
    )
  }

  return (
    <TableContainer>
      <TableHead>
        <TableHeader>Name</TableHeader>
        <TableHeader />
        <TableHeader />
        <TableHeader />
        <TableHeader />
      </TableHead>
      <Table>
        {companyForms?.map((companyForm) => (
          <CompanyFormsListRow
            key={companyForm.__uuid}
            companyForm={companyForm}
          />
        ))}
      </Table>
    </TableContainer>
  )
}

export default CompanyFormsList
