import { useTranslation } from 'react-i18next'
import {
  MdCalendarToday, MdMailOutline, MdOutlineLocalOffer,
} from 'react-icons/md'
import { useHistory } from 'react-router'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import RequisitionRowType from '../../../../../types/Basecamp/RequisitionRowType'
import RequisitionStatusesType from '../../../../../types/Basecamp/RequisitionStatusesType'
import statusSwitch from '../../../utils/requisitionStatusStyles'

const itemContainerStyles = 'flex w-4/12 gap-2 pb-3'
const iconStyles = 'text-gray-400 text-md min-w-[20px]'
const helperTextStyles = 'text-gray-400 font-light text-xs'
const statNumberStyles = 'font-light text-xs'

const isLast = (last: boolean) => (last ? 'justify-end' : 'justify-center')

// TODO: Refactor component
const BasecampRequisitionsStatusBar: React.FC<{ data: RequisitionRowType }> = ({ data }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const step = statusSwitch(data.status)

  const thirdStepComponent = (status: RequisitionStatusesType, date: string) => {
    if (data.offer_action_required) {
      return (
        <Button
          variant="solid"
          color="lhotse-orange"
          className="text-sm font-medium"
          onClick={() => history.push(`/requisition/req-${data.id}/award`)}
        >
          {t('basecamp.award_now')}
        </Button>
      )
    }
    if (['OPEN', 'DRAFT', 'AWARDED'].includes(status) && date) {
      return (
        <>
          <MdCalendarToday className={iconStyles} />
          <div className="flex flex-col items-start">
            <div className={statNumberStyles}>{new Date(data.ends_at).toLocaleDateString('de-DE')}</div>
            <div className={helperTextStyles}>{t('generic.due_date')}</div>
          </div>
        </>
      )
    }
    return <> </>
  }

  return (
    <div className="flex w-full gap-8 mt-4">
      <div className={`${!step.number && step.borderColor} flex border-b-2 w-4/12`}>
        <div className={`text-sm font-medium ${step.textColor}`}>
          {t(step.name)}
        </div>
      </div>
      <div className={`flex gap-2 border-b-2 ${step.number === 1 && step.borderColor} w-full justify-center`}>
        <div className={`${itemContainerStyles} ${isLast(false)}`}>
          <MdMailOutline className={iconStyles} />
          <div>
            <div className={statNumberStyles}>{data.supplier_invites || 0}</div>
            <div className={helperTextStyles}>{t('generic.invite_plural')}</div>
          </div>
        </div>

        <div className={`${itemContainerStyles} ${isLast(false)}`}>
          <MdOutlineLocalOffer className={iconStyles} />
          <div className="flex flex-col">
            <div className={statNumberStyles}>
              {data.offers || 0}
            </div>
            <div className={helperTextStyles}>{t('generic.offer_plural')}</div>
          </div>
        </div>

        <div className={`${itemContainerStyles} ${isLast(false)}`}>
          <MdMailOutline className={iconStyles} />
          <div>
            <div className={statNumberStyles}>{data.open_chats || 0}</div>
            <div className={helperTextStyles}>{t('generic.chat_plural')}</div>
          </div>
        </div>
      </div>
      <div className={`${itemContainerStyles} ${isLast(true)}
      border-b-2 ${step.number === 2 && step.borderColor}`}
      >
        {thirdStepComponent(step.name, data.ends_at)}
      </div>
    </div>
  )
}

export default BasecampRequisitionsStatusBar
