import FormField from './FormField'
import { FormQuestionValue } from '../../types/Forms'

import { InputType } from '../../types/shared/InputType'

export const FormFieldPlaceholder = () => (
  <FormField
    updateField={() => {}}
    field={
      {
        id: 'placeholder',
        input: { type: 'TEXT', name: '', placeholder: '' } as InputType,
        options: null,
        value: { value: '' } as FormQuestionValue,
        media: null,
        disabled: true,
      }
    }
  />
)

export default FormFieldPlaceholder
