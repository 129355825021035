import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import FormField from '../../../Forms-v2/FormField'
import { generateField } from '../../../Forms-v2/helpers'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import useWorkflowMutations from '../../hooks/useWorkflowMutations'

type WorkflowDraft = {
  name: string
}

export const NewWorkflowModalBody = () => {
  const { t } = useTranslation()
  const [workflowDrat, setWorkflowDraft] = useState<WorkflowDraft>({ name: '' })
  const { modal } = useUtils()
  const { create } = useWorkflowMutations()

  const [errors, setErrors] = useState<WorkflowDraft>({ name: '' })

  const validate = () => {
    const errors = {} as WorkflowDraft

    if (!workflowDrat.name) {
      errors.name = t('generic.required', 'Required')
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleUpdate = (updatedField: keyof WorkflowDraft, updatedValue: string | undefined) => {
    if (updatedValue === undefined) return

    setWorkflowDraft({ ...workflowDrat, [updatedField]: updatedValue })
  }

  const handleSubmit = () => {
    if (!validate()) return
    create(workflowDrat)
    modal.set({ isOpen: false })
  }

  return (
    <div className="flex flex-col gap-11">
      <FormField
        className="w-full"
        field={generateField(
          {
            id: 'name',
            input: { type: 'TEXT', name: '', placeholder: t('generic.title_placeholder', 'Title here') },
            value: { value: workflowDrat.name },
            error: errors.name,
          },
        )}
        updateField={(updatedField) => handleUpdate(updatedField.id, updatedField.value?.value as string)}
        readonly={false}
      />
      <div>
        <SaveCancelFormButtons
          canSave
          onCancel={() => modal.set({ isOpen: false })}
          onSave={handleSubmit}
        />
      </div>
    </div>
  )
}

export default NewWorkflowModalBody
