import { AddressQuestionValueNameWithFullAddress, FormQuestionContentType, FormQuestionValueType } from '../../../types/Forms'
import { dateFormatter, dateTimeFormatter } from '../../../shared/constants/dateFormat'
import { useFormField } from '../providers/FormFieldProvider'

const valueToString = (type: FormQuestionContentType, value: FormQuestionValueType) => {
  if (type === 'DATE') {
    return dateFormatter(value as string)
  }

  if (type === 'DATE_TIME') {
    return dateTimeFormatter(value as string)
  }

  if (type === 'ADDRESS') {
    const {
      name, street, zip, city, state, country,
    } = (value as AddressQuestionValueNameWithFullAddress)
    const countryName = country?.name ?? ''
    const addressArray = [name ?? '', street ?? '', city ?? '', zip ?? '', state ?? '', countryName].filter((val) => val !== '')
    return addressArray.join(', ')
  }

  if (typeof value === 'string') {
    return value
  }

  if (type === 'MULTI_SELECT') {
    return value ? (Array.isArray(value) ? value : [value])
      .filter((value) => !!value)
      .map((value) => value.value)
      .filter((value) => !!value).join(', ')
      : 'Missing selected value'
  }

  return value.toString()
}

const missingValue = (type: FormQuestionContentType) => {
  if (type === 'ADDRESS') {
    return 'Missing selected address'
  }

  if (['NUMBER', 'PRICE', 'TEXT', 'TEXT_AREA', 'SELECT', 'MULTI_SELECT', 'PRICE'].includes(type)) {
    return 'Missing value'
  }

  return 'Missing selected value'
}

export const FormFieldDisplayGeneric = () => {
  const { field: { id, value, input: { type } } } = useFormField()

  const getValue = () => {
    if (type === 'MULTI_SELECT') { // @ts-ignore
      return value && Object.values(value)
    }
    // @ts-ignore
    return value?.value
  }

  return (
    <div className="flex flex-col gap-4 text-base font-normal">
      <div key={`form-field-display-${id}`} className="">
        {/* @ts-ignore */}
        {(getValue() && valueToString(type, getValue())) || missingValue(type)}
      </div>
    </div>
  )
}

export default FormFieldDisplayGeneric
