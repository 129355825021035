import { Dispatch, SetStateAction } from 'react'
import { AssignmentType } from '../../../../types/shared/AssignmentTypes'
import PopoverComponent from '../popover/PopoverComponent'
import UsersList from '../UsersList/UsersList'
import AssigneePill from './AssigneePill'
import AssigneePopoverButton from './AssigneePopoverButton'
import useAssignUser from './hooks/useAssignUser'
import useDeleteAssignment from './hooks/useDeleteAssignment'

interface IAssigneesPopup {
  modelId: string | number
  localAssignments: AssignmentType[] | undefined
  maxAssignments?: number
  setLocalAssignments: Dispatch<SetStateAction<AssignmentType[] | undefined>>
  origin: string
  dataCy: string | undefined
  scrollableElement: HTMLElement
  model?: 'requisition' | 'form_submission' | 'intake-flow'
}

const AssigneesPopup: React.FC<IAssigneesPopup> = ({
  modelId, localAssignments, setLocalAssignments, origin, dataCy, scrollableElement, model = 'requisition',
}) => {
  const { mutate: deleteAssignment } = useDeleteAssignment(setLocalAssignments)
  const { mutate: assignUser } = useAssignUser({
    setLocalAssignments, modelId, origin, model,
  })

  return (
    <PopoverComponent
      closeOnClick={false}
      buttonCy={dataCy || 'assignees-popover-button'}
      popoverButton={<AssigneePopoverButton localAssignments={localAssignments} />}
      popoverContentClassName="bg-white flex w-full flex-col gap-2 p-4 h-80 overflow-auto"
      scrollableElement={scrollableElement}
    >
      <div className="flex flex-col">
        <div className="flex flex-wrap gap-2">
          <div className="flex flex-wrap gap-2" data-cy="assignees-invited">
            {!!localAssignments?.length && localAssignments?.map((assignment) => (
              <AssigneePill
                key={assignment.id}
                onClick={() => deleteAssignment(assignment.id)}
                multipleAssignees={localAssignments?.length !== 1}
                assignment={assignment}
              />
            ))}
          </div>
          <div className="flex flex-col w-full gap-2">
            <UsersList
              onClick={assignUser}
              localAssignments={localAssignments}
            />
          </div>
        </div>
      </div>
    </PopoverComponent>
  )
}

export default AssigneesPopup
