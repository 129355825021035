import { Route, Switch } from 'react-router'
import CoreAuthenticating from '../../core/components/CoreAuthenticating'
import { getTenant } from '../../core/utils/tenancy'
import AuthenticationLayout from './layout/AuthenticationLayout'
import ForgotPassword from './submodules/ForgotPassword/ForgotPassword'
import JoinPage from './submodules/Join/JoinPage'
import Login from './submodules/Login/Login'
import ResetPassword from './submodules/ResetPassword/ResetPassword'
import FindTenant from './submodules/Tenant/Tentant'
import useGetSSOProviders from './submodules/Login/hooks/useGetSSOProviders'

const AuthenticationPage = () => {
  const tenant = getTenant()

  const { isLoading } = useGetSSOProviders()

  if (isLoading) return <CoreAuthenticating />

  return (
    <AuthenticationLayout>
      {tenant === 'app' && <FindTenant />}

      {tenant !== 'app' && (
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/join">
            <JoinPage />
          </Route>
        </Switch>
      )}
    </AuthenticationLayout>
  )
}

export default AuthenticationPage
