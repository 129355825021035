import ToggleCommentButton from '../../../../shared/components/atoms/buttons/ToggleCommentButton'
import useGetOffer from '../../hooks/useGetOffer'
import useOfferIsDisabled from '../../hooks/useOfferIsDisabled'
import useUpdateOfferValue from '../../hooks/useUpdateOfferValue'
import useUpdateOfferValueCache from '../../hooks/useUpdateOfferValueCache'

const ToggleDeliveryCommentButton: React.FC = () => {
  const { data: offerData } = useGetOffer()
  const deliveryComment = offerData?.offer?.delivery_time_estimate_comment

  const updateOfferValue = useUpdateOfferValue()
  const updateOfferValueCache = useUpdateOfferValueCache()

  const isDisabled = useOfferIsDisabled()

  const toggleDeliveryComment = () => {
    const newComment = deliveryComment !== null ? null : ''
    updateOfferValueCache(newComment, 'delivery_time_estimate_comment')
    return updateOfferValue('delivery_time_estimate_comment', newComment)
  }

  return (
    <ToggleCommentButton
      enabled={typeof deliveryComment === 'string'}
      toggleEnabled={toggleDeliveryComment}
      disabled={isDisabled}
    />
  )
}

export default ToggleDeliveryCommentButton
