import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import useGetTotalPrice from '../../hooks/useGetTotalPrice'

interface IConfirmationSectionPriceCheck { }

const ConfirmationSectionPriceCheck: React.FC<IConfirmationSectionPriceCheck> = () => {
  const { t } = useTranslation()

  const {
    isLoading, priceEqual, shouldCompare,
  } = useGetTotalPrice()

  if (!shouldCompare) return null
  if (isLoading) return <CoreLoadingIcon />

  if (priceEqual) {
    return (
      <div className="w-full bg-green-100 border-green-600 border rounded-md p-4 text-sm">
        {t('approval.price_sum_equal_to_total')}
      </div>
    )
  }

  return (
    <div className="w-full bg-red-100 border-red-600 border rounded-md p-4 text-sm">
      {t('approval.price_sum_not_equal_to_total')}
    </div>
  )
}

export default ConfirmationSectionPriceCheck
