import { FunctionComponent } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import FilterPill from './FilterPill'
import Button from '../buttons/Button'
import { MetaBucketType } from '../../../../types/shared/GeneralDataTypes'

interface IFilterBlock {
  filterTitle: string,
  filterOptions: MetaBucketType[],
  selectedFilter: string,
  setSelectedFilter(value: string): void
}

const FilterBlock: FunctionComponent<IFilterBlock> = ({
  filterTitle,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-1 ">
      <RadioGroup value={selectedFilter} onChange={setSelectedFilter} className="flex flex-col w-fit gap-1 overflow-auto h-40">
        <RadioGroup.Label className="text-sm text-gray-500 flex items-center justify-between">
          {filterTitle}
          {!!selectedFilter.length && <Button color="lhotse-orange" onClick={() => setSelectedFilter('')} className="text-xs py-0">{t('generic.clear')}</Button>}
        </RadioGroup.Label>

        {filterOptions.map((item) => (
          <RadioGroup.Option
            value={item.key}
            key={item.key}
          >
            {({ checked }) => (
              <FilterPill
                name={item.key}
                amount={item.doc_count}
                selected={checked}
              />
            )}

          </RadioGroup.Option>
        ))}

      </RadioGroup>
    </div>
  )
}

export default FilterBlock
