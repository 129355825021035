import NextSectionButton from './NextSectionButton'
import PreviousSectionButton from './PreviousSectionButton'

import { useLocalStorage } from '../../../core/providers/LocalStorageProvider'
import Header from './Header/Header'

const FormSectionsNavigationButtons: React.FC = () => {
  const [getLocalStorage] = useLocalStorage()
  const minified = getLocalStorage('sidebarMinified')

  return (
    <div className={`${minified ? 'pl-24' : 'pl-56'} h-20 bottom-0 left-0 flex flex-1 z-30
      fixed w-full px-4 bg-background shadow-[10px_0px_5px_rgba(0,0,0,0.1)]`}
    >
      <div className="flex w-full py-5 px-2">
        <div className="flex w-full justify-between gap-4">
          <div className="w-48"><PreviousSectionButton /></div>
          <div><Header /></div>
          <div className="w-48"><NextSectionButton /></div>
        </div>
      </div>
    </div>
  )
}

export default FormSectionsNavigationButtons
