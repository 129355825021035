import {
  useMutation, useQueryClient,
} from 'react-query'
import toastr from '../../shared/components/organisms/toastr/toastr'

export const useApiDelete = (itemId: number | string, {
  queryId, request, onSuccess, onError,
}: {
  queryId: string | any[]
  request(id: number | string): Promise<any>
  onSuccess(): void
  onError(): void
  identifier?: string
}) => {
  const cache = useQueryClient()

  const { mutate: deleteMutate } = useMutation(() => request(itemId), {
    onSuccess: () => {
      cache.setQueryData(
        [queryId],
        (oldData: any) => oldData?.filter((e: any) => e.id !== itemId && e.uuid !== itemId),
      )
      toastr.success('success.item_deleted', 'successDelete')
      onSuccess()
    },
    onError: () => {
      onError()
    },

  })

  return deleteMutate
}

export const useApiFetchMore = ({ queryId, request }
  : { queryId: string, request(page: number): Promise<any> }) => {
  const cache = useQueryClient()

  const { mutate: fetchMore } = useMutation((page: number) => request(page), {
    onSuccess: (newData: any) => {
      cache.setQueryData(
        [queryId],
        (oldData: { data: any, meta: any } | undefined) => {
          if (!oldData) return newData

          return ({
            data: [...oldData.data, ...newData.data],
            meta: newData.meta,
          })
        },
      )
    },
  })

  return fetchMore
}

export const useEmptyCache = (key: string) => {
  const cache = useQueryClient()
  return () => cache.setQueryData(
    [key],
    () => ({
      data: [],
      meta: [],
    }),
  )
}
