import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../shared/components/atoms/inputs/Checkbox'
import { FormQuestionType } from '../../../../types/Forms'
import useSynchronizedFormUpdate from '../hooks/useSynchronizedFormUpdate'
import useUpdateQuestionLoopSettings from '../hooks/useUpdateQuestionLoopSettings'

type CopyQuestionValueCheckboxProps = {
  question?: FormQuestionType
}

export const CopyQuestionValueCheckbox = ({ question }: CopyQuestionValueCheckboxProps) => {
  const { t } = useTranslation()
  const updateLoopSettings = useUpdateQuestionLoopSettings(question)

  const updateLoopSettingsAndSyncForm = useSynchronizedFormUpdate((hasCopiedValuesFromParent: boolean) => {
    updateLoopSettings({
      hasCopiedValuesFromParent,
    })
  })

  const isLoopedSection = question?.__loop?.parentQuestionUuid

  if (!isLoopedSection || !question || question.__loop?.is_loop_trigger) return null

  return (
    <div className="flex">
      <div className="mr-2 flex items-center">
        <Checkbox disabled={question.__loop?.hasCopiedValuesFromParentisForced} checked={question.__loop?.hasCopiedValuesFromParent || false} onChange={updateLoopSettingsAndSyncForm} />
      </div>
      <div>
        {t('form.should_copy_looped_value')}
      </div>
    </div>
  )
}

export default CopyQuestionValueCheckbox
