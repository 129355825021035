/* eslint-disable react/jsx-props-no-spreading */
import { Droppable } from 'react-beautiful-dnd'
import { INodeChildren } from '../../../../../../../types/shared/GeneralTypes'

interface IDnDDroppable extends INodeChildren {
  droppableId: string
  droppableType: string
}

const DnDDroppable: React.FC<IDnDDroppable> = ({ children, droppableId, droppableType }) => (
  <Droppable
    droppableId={droppableId}
    type={droppableType}
  >

    {(provided) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className="space-y-4 w-full"
      >
        {children}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
)
export default DnDDroppable
