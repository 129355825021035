import { MessageType } from '../../../../../../types/Messenger/types'
import { getMessageBubbleClasses } from '../../../../utils'
import MessageBody from './MessageBody'
import ReplyButton from './ReplyButton'
import Timestamp from './Timestamp'

interface IMessage {
  message: MessageType
}

const Message: React.FC<IMessage> = ({
  message,
}) => (
  <div className="w-full">
    <div className={`w-full flex ${message.is_my_message ? 'pl-10' : 'pr-10'}`} id={`message-${message.uuid}`}>
      <div className={`w-full flex-col p-3 ${getMessageBubbleClasses(message)} rounded-md flex`}>
        <MessageBody message={message} />
        <div className="flex w-full items-center justify-end">
          <Timestamp message={message} />
          <ReplyButton message={message} />
        </div>
      </div>
    </div>
  </div>
)

export default Message
