import { t } from 'i18next'
import { priceToGermanPriceString } from '../../../../shared/constants/currencyFormat'
import useGetCalculatedPrices from './hooks/useGetCalculatedPrices'

const GrossPrice: React.FC = () => {
  const { data: calculatedPrices } = useGetCalculatedPrices()

  return (
    <div className="flex gap-x-3 w-max min-w-1/4 justify-center text-right items-center">
      <div className="flex flex-col w-max justify-center">
        <p className="text-2xl text-green-700 font-semibold">{priceToGermanPriceString(calculatedPrices?.gross || 0)}</p>
        <p className="text-xs mt-1 text-left">{`${t('generic.price')} (${t('generic.gross')})`}</p>
      </div>
    </div>
  )
}

export default GrossPrice
