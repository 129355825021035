import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import useDeclineRequisitionOffers from './hooks/useDeclineOffers'

const RequisitionTopbarCancelRequisitionModal: React.FC = () => {
  const { t } = useTranslation()
  const utils = useUtils()
  const requisitionId = useGetRequisitionId()
  const closeModal = () => utils.modal.set({ isOpen: false })

  const declineOffers = useDeclineRequisitionOffers(requisitionId)

  return (
    <div>
      <div className="flex flex-col w-full gap-y-4">
        <p>{t('req_award.decline-all-offers-confirm')}</p>
      </div>
      <div className="flex pt-4 w-full justify-end">
        <div className="gap-x-8 flex items-center">
          <button type="button" onClick={closeModal}>
            {t('generic.cancel')}
          </button>
          <Button
            className="font-semibold"
            variant="solid"
            color="#AF0000"
            onClick={declineOffers}
          >
            {t('requisition.abort_requisition')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RequisitionTopbarCancelRequisitionModal
