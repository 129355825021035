import { useTranslation } from 'react-i18next'
import {
  Redirect,
  Route, Switch, useRouteMatch,
} from 'react-router'
import PageTopbar from '../../shared/components/layout/PageTopBar'
import CompanyInputs from '../CompanyInputs/CompanyInputs'
import IntakeConfigurationFormsTab from './components/IntakeConfigurationFormsTab'
import IntakeConfigurationTabbar from './components/IntakeConfigurationTabbar'
import IntakeConfigurationWorkflowsTab from './components/IntakeConfigurationWorkflowsTab'

const IntakeConfiguration = () => {
  const { t } = useTranslation()
  const { path } = useRouteMatch()

  return (
    <div className="w-full">
      <PageTopbar
        title={t('account.intake-configuration', 'Intake Configuration')}
      />
      <div className="flex flex-1">
        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
          <div className="sticky top-0 pl-5 pt-5 flex w-52 flex-col overflow-y-auto">
            <IntakeConfigurationTabbar />
          </div>
        </aside>
        <div className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto p-5">
          <Switch>
            <Route path="/intake-configuration" exact>
              <Redirect to={`${path}/forms`} />
            </Route>
            <Route path={`${path}/forms`}>
              <IntakeConfigurationFormsTab />
            </Route>
            <Route path={`${path}/inputs`}>
              <CompanyInputs />
            </Route>
            <Route path={`${path}/workflows`}>
              <IntakeConfigurationWorkflowsTab />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default IntakeConfiguration
