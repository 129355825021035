import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AvailableTenants from './components/AvailableTenants'
import FindTenant from './components/FindTenant'

const Tenant = () => {
  const { t } = useTranslation()
  const [links, setLinks] = useState<number[]>([])
  const [submitted, setSubmitted] = useState(false)

  return (
    <>
      <p className="text-3xl">{t('login.headline')}</p>
      {!submitted
        ? (
          <FindTenant setLinks={setLinks} setSubmitted={setSubmitted} />
        )
        : <AvailableTenants links={links} setSubmitted={setSubmitted} />}
    </>
  )
}
export default Tenant
