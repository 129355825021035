import { useTranslation } from 'react-i18next'
import NoPreviousRequisitionsMessage from './NoPreviousRequisitionsMessage'
import SupplierRequisitionsTable from './SupplierRequisitionsTable'
import useGetSupplierOffers from './useGetSupplierOffers'

const SupplierRequisitionsBody = () => {
  const { t } = useTranslation()

  const { data: supplierRequisitions } = useGetSupplierOffers()

  return (
    <div className="text-sm text-primary-gray">
      {supplierRequisitions?.length
        ? (
          <div>
            <div className="flex flex-col pb-6">
              <p className="font-medium text-black">{t('supplier.past_requisition_data')}</p>
              <p>{t('supplier.past_req_explainer')}</p>
            </div>
            <SupplierRequisitionsTable />
          </div>
        )
        : <NoPreviousRequisitionsMessage />}
    </div>
  )
}

export default SupplierRequisitionsBody
