import * as Yup from 'yup'
import validation from '../../../../../shared/constants/validationErrors'

const validationSchema = Yup.object().shape({
  password: Yup
    .string()
    .required(validation.required)
    .matches(/(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]))/, validation.passwordWeak),

  first_name: Yup
    .string()
    .required(validation.required),
  last_name: Yup
    .string()
    .required(validation.required),
})

export default validationSchema
