import { useTranslation } from 'react-i18next'
import lhotseLogo from '../../core/assets/lhotse-logo.svg'
import { getApiUrl, getTenant } from '../../core/utils/tenancy'

const DashPanelAuth = () => {
  const { t } = useTranslation()
  const localStorageToken = localStorage.getItem('lhotseAuth')
  const lhotseAuth = localStorageToken ? JSON.parse(localStorageToken) : null

  const baseUrl = window.location.origin

  return (
    <div className="flex justify-center items-center h-screen w-screen ">
      <div id="panel-token" className="h-0 w-0 overflow-hidden">
        Bearer
        {' '}
        {lhotseAuth.token}
      </div>
      <div id="panel-email" className="h-0 w-0 overflow-hidden">
        {lhotseAuth.email}
      </div>
      <div id="panel-url" className="h-0 w-0 overflow-hidden">
        {baseUrl}
      </div>
      <div id="panel-tenant" className="h-0 w-0 overflow-hidden">
        {getTenant()}
      </div>
      <div id="panel-api" className="h-0 w-0 overflow-hidden">
        {getApiUrl()}
      </div>

      <div className="flex flex-col items-center justify-center w-full">
        <img src={lhotseLogo} className="w-1/12" alt="" />
        <p className=" pt-12 text-center w-full">
          {t('dash_panel.authentication_successful')}
        </p>
      </div>
    </div>
  )
}

export default DashPanelAuth
