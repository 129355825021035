import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import Input from '../../../../shared/components/atoms/Input/Input'
import useUrlParams from '../../hooks/useUrlParams'

const LhotseHomeCreateSearch = () => {
  const { t } = useTranslation()

  const { getParam, setParam } = useUrlParams()

  return (
    <div>
      <Input
        placeholder={t('generic.search', 'Search')}
        defaultValue={getParam('query')}
        className="pl-8"
        onChange={(e) => {
          setParam({ query: e.currentTarget.value })
        }}
        lead={(
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 mr-10">
            <MagnifyingGlassIcon className="w-4 opacity-70" />
          </div>
        )}
      />
    </div>
  )
}

export default LhotseHomeCreateSearch
