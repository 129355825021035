import { useLocation, useParams } from 'react-router'

export default () => {
  const { pathname } = useLocation()
  const { token } = useParams<{ token?: string }>()

  if (pathname.includes('supplier-portal')) {
    return token || pathname?.split('/')[3]
  }

  return ''
}
