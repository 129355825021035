import { useTranslation } from 'react-i18next'

const BasecampNoResults = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex justify-center h-96 items-center" data-cy="no-results">
      {t('generic.no_results')}
    </div>
  )
}

export default BasecampNoResults
