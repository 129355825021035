import { cloneDeep } from 'lodash'
import { FormQuestionType } from '../../../../../types/Forms'
import { getMergedDeepOverwritingArrays } from '../../../../../shared/utils/objectHelpers'
import { getLoopedQuestionValues } from './cloneQuestionValues'

export const cloneQuestion = (
  originalSectionQuestion: FormQuestionType,
  getLoopedQuestionUuid = (__uuid: string | undefined | null) => __uuid || '',
) => getMergedDeepOverwritingArrays(
  cloneDeep(originalSectionQuestion),
  {
    __uuid: getLoopedQuestionUuid(originalSectionQuestion.__uuid),
    __logic: {
      questionUUID: getLoopedQuestionUuid(originalSectionQuestion.__logic?.questionUUID) || '',
      switch: {
        cases: originalSectionQuestion.__logic?.switch?.cases.map(
          (gotoCase) => ({
            ...gotoCase,
            target: getLoopedQuestionUuid(gotoCase.target),
          }),
        ) || [],
        default: getLoopedQuestionUuid(originalSectionQuestion.__logic?.switch?.default),
      },
    },
    __loop: {
      parentQuestionUuid: originalSectionQuestion.__loop?.parentQuestionUuid || originalSectionQuestion.__uuid,
    },
    values: getLoopedQuestionValues(originalSectionQuestion),
  },
)

export default cloneQuestion
