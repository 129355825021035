// REFACTOR
import { useQueryClient } from 'react-query'
import { RequisitionInviteType, RequisitionDataType } from '../../../../../../../types/shared/RequisitionDataTypes'
import { undef } from '../../../../../../../types/shared/GeneralTypes'
import useGetRequisitionId from '../../../../../../../shared/hooks/useGetRequisitionId'
import AcceptanceStateType from '../../../../../../../types/Requisition/AcceptanceStateType'
import RequisitionDefinitionQueryKeys from '../../../../Definition/constants/queryKeys'
import queryKeys from '../../../constants/OfferViewQueryKeys'

const useSetOfferStatus = (
  invite: RequisitionInviteType,
) => {
  const cache = useQueryClient()

  const requisitionId = useGetRequisitionId()
  return (newStatus: AcceptanceStateType) => {
    if (newStatus === 'OFFER_ACCEPTED') {
      cache
        .setQueryData<undef<RequisitionDataType>>(
          [RequisitionDefinitionQueryKeys.requisitionData, requisitionId],
          (oldData: RequisitionDataType | undefined) => (
            oldData
              ? ({ ...oldData, status: 'PURCHASED' })
              : undefined),
        )
    }
    cache
      .setQueryData<undef<RequisitionInviteType[]>>(
        [queryKeys.OFFERS_DATA, requisitionId],
        (oldInviteData) => {
          if (oldInviteData) {
            return oldInviteData
              .map((oldInvite) => (oldInvite.id === invite.id
                ? { ...oldInvite, status: newStatus }
                : oldInvite))
          }
          return oldInviteData
        },
      )
  }
}

export default useSetOfferStatus
