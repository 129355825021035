import { useLocalStorage } from '../../../core/providers/LocalStorageProvider'

const useIntakeSidebar = () => {
  const [getLocalStorage, setValue] = useLocalStorage()

  const minified = getLocalStorage('intakeSidebarMinified', 'false')

  const toggleMinified = () => setValue('intakeSidebarMinified', JSON.stringify(!minified))

  const setMinifiedState = (state:boolean) => setValue('intakeSidebarMinified', JSON.stringify(state))

  return { minified, toggleMinified, setMinifiedState }
}

export default useIntakeSidebar
