import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { apiPublishForm } from '../../../constants/formService'
import useGetFormData from '../../../hooks/useGetFormData'
import useGetFormParams from '../../hooks/useGetFormParams'

const PublishFormButton = () => {
  const { t } = useTranslation()

  const { refetch } = useGetFormData()

  const { formId } = useGetFormParams()

  const handlePublishForm = async () => {
    await apiPublishForm(formId)
    refetch()
  }

  return (
    <Button
      variant="solid"
      color="lhotse-orange"
      className="flex gap-x-2"
      onClick={handlePublishForm}
    >
      <p>{t('forms.publish_forms')}</p>
    </Button>
  )
}

export default PublishFormButton
