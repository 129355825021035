import { useTranslation } from 'react-i18next'
import { useUtils } from '../../core/providers/UtilsProvider'
import Button from '../../shared/components/atoms/buttons/Button'
import Titlebar from '../../shared/components/layout/Titlebar'
import CompanyInputForm from './CompanyInputForm'
import CompanyInputFilters from './CompanyInputFilters'

const CompanyInputsHeader: React.FC = () => {
  const { t } = useTranslation()
  const { modal } = useUtils()

  const openCreateInputModal = () => {
    modal.set({
      isOpen: true,
      title: 'Create custom Input',
      content: <CompanyInputForm />,
      className: 'max-w-[450px]',
    })
  }

  return (
    <Titlebar title="generic.inputs_plural">
      <div className="flex flex-row gap-2">
        <CompanyInputFilters />
        <Button
          className="text-sm"
          variant="outlined"
          onClick={openCreateInputModal}
        >
          {t('inputs.add_input', 'Add Input')}
        </Button>
      </div>
    </Titlebar>
  )
}

export default CompanyInputsHeader
