import { useTranslation } from 'react-i18next'
import useGetSSOProviders from '../hooks/useGetSSOProviders'

const OrWithEmailMessage = () => {
  const { data: ssoProviders } = useGetSSOProviders()
  const { t } = useTranslation()

  return ssoProviders && ssoProviders.data.length > 1
    ? (
      <div
        className="flex justify-center items-center"
        data-cy="or-with-email-message"
      >
        <span className="w-4/12 h-px bg-gray-300" />
        <p className="w-6/12 text-center text-gray-400 text-sm">{t('login.sign_in_with_email')}</p>
        <span className="w-4/12 h-px bg-gray-300" />
      </div>
    )
    : null
}

export default OrWithEmailMessage
