import TipTapReadOnly from '../../../../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import FileValue from './FileValue'
import { LineItemAwardScreenType } from './SingleLineItem'

interface ISingleLineItemValue {
  singleLineItem: LineItemAwardScreenType | undefined
}

const SingleLineItemValue: React.FC<ISingleLineItemValue> = ({
  singleLineItem,
}) => {
  if (singleLineItem?.children) return singleLineItem?.children

  return singleLineItem?.files?.length
    ? <FileValue singleLineItem={singleLineItem} />
    : <TipTapReadOnly className="line-clamp-1 text-left" text={singleLineItem?.value || '-'} />
}

export default SingleLineItemValue
