import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAbility } from '@casl/react'
import LabelInfo from '../../../../shared/components/atoms/texts/LabelInfo'
import RowCell from '../../../../shared/components/layout/RowCell'
import { useSuppliersProvider } from '../../providers/SuppliersProvider'
import useGetPaginatedSuppliers from '../../hooks/useGetPaginatedSuppliers'
import SuppliersResults from './SuppliersResults'
import SuppliersSearchResults from './SuppliersSearchResults'
import TitlebarSearch from '../../../../shared/components/layout/TitlebarSearch'
import { AbilityContext } from '../../../../core/providers/AbilityProvider'
import { ABILITIES } from '../../../../config/ability'

const SuppliersTable: React.FC = () => {
  const { isLoading } = useGetPaginatedSuppliers()
  const ability = useAbility(AbilityContext)
  const { t } = useTranslation()

  const { searchQuery: [searchQuery, setSearchQuery], scrollState: [scrollState] } = useSuppliersProvider()

  useEffect(() => {
    const scrollableDiv = document.getElementById('scrollableDiv') as HTMLElement
    scrollableDiv.scrollTo({ top: scrollState })
  }, [isLoading])

  return (
    <div className="w-full 2xl:w-[1300px] flex flex-col justify-center gap-y-2">
      {ability.can(ABILITIES.larrySearch) ? <TitlebarSearch inputState={[searchQuery, setSearchQuery]} dataCy="searchSuppliersInput" className="h-14 text-gray-400 border-none shadow-sm hover:shadow-md focus:shadow-md" /> : '' }
      <div className="flex">
        <RowCell width="w-6/12">
          <LabelInfo label={t('generic.name')} textClassName="text-secondary-gray" />
        </RowCell>
        <RowCell width="w-4/12">
          <LabelInfo label={t('suppliers.list_category')} textClassName="text-secondary-gray" />
        </RowCell>
        <RowCell width="w-2/12">
          <LabelInfo label={t('suppliers.list_country')} textClassName="text-secondary-gray" />
        </RowCell>
      </div>
      {searchQuery
        ? <SuppliersSearchResults searchQuery={searchQuery} />
        : <SuppliersResults searchQuery={searchQuery} />}
    </div>
  )
}

export default SuppliersTable
