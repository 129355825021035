import { invalidIdStateType } from '../../../utils/validationUtils'
import Row from '../Row/Row'
import { SectionOrLineItemType } from './hooks/useGetSectionsOrLineItems'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'

interface IRows {
  section: SectionOrLineItemType
  updateAutoSave: () => void
  invalidIds: invalidIdStateType | undefined
  sectionIndex: number
}

const Rows: React.FC<IRows> = ({
  section, updateAutoSave, invalidIds, sectionIndex,
}) => (
  <>
    {section.item_attributes.map((row, rowIndex) => (
      <Row
        key={row.id}
        row={row as LineItemItemAttributeType}
        updateAutoSave={updateAutoSave}
        sectionIndex={sectionIndex}
        firstRow={rowIndex === 0 && sectionIndex === 0}
        invalidIds={invalidIds}
      />
    ))}
  </>
)

export default Rows
