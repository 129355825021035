import MediaTable from '../../../../../../../shared/components/atoms/FileUpload/MediaTable'
import useGetRequisitionData from '../../../hooks/useGetRequisition'
import useDeleteRequisitionAttachment from './Hooks/useDeleteRequisitionAttachment'
import useGetRequisitionAttachments from './Hooks/useGetRequisitionAttachments'

type AttachmentsTableProps = {}

const AttachmentsTable: React.FC<AttachmentsTableProps> = () => {
  const { data: attachments } = useGetRequisitionAttachments()
  const { data: requisition } = useGetRequisitionData()
  const { mutate: remove } = useDeleteRequisitionAttachment()

  if (!requisition || !attachments || attachments?.length === 0) return null

  return (
    <MediaTable media={attachments || []} onDelete={remove} />
  )
}

export default AttachmentsTable
