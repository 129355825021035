import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import useGetWorkflows from '../../hooks/useGetWorkflows'
import WorkflowsTableBody from './WorkflowsTableBody'
import WorkflowsTableHeader from './WorkflowsTableHeader'

export const WorkflowsTable = () => {
  const { data: workflows, isLoading } = useGetWorkflows()

  const { t } = useTranslation()

  if (isLoading) return (<div className="m-4"><CoreLoadingIcon /></div>)

  return (
    <TableContainer emptyState={!workflows?.length && (
      <div>
        {t('workflows.missing_workflows', 'You dont have any workflows for your organization.')}
      </div>
    )}
    >
      <WorkflowsTableHeader />
      <WorkflowsTableBody />
    </TableContainer>
  )
}

export default WorkflowsTable
