import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../shared/components/atoms/buttons/Button'
import WarningBanner from '../../../../../../../shared/components/organisms/WarningBanner/WarningBanner'
import useUpdateRequisitionStatus from '../../../../../hooks/useUpdateRequisitionStatus'
import useGetRequisitionData from '../../../../Definition/hooks/useGetRequisition'

interface ISearchInProgressBanner {
  hideCancelSearchButton?: boolean
}

const SearchInProgressBanner: React.FC<ISearchInProgressBanner> = ({ hideCancelSearchButton }) => {
  const { t } = useTranslation()

  const { mutate: updateReqStatus } = useUpdateRequisitionStatus()

  const { data: requisitionData } = useGetRequisitionData()
  const handleCancelSearch = () => {
    updateReqStatus({
      status: 'ANALYZED',
      reason: 'user cancelled supplier search',
    })
  }

  if (requisitionData?.status !== 'ANALYZING') return null

  return (
    <div className="flex w-full">
      <WarningBanner severity="warning" className="w-full mb-4" showSpinner>
        <div className="flex justify-between items-center w-full gap-x-4">
          <p className="text-xs">
            {t('requisition_suppliers.search_in_progres')}
          </p>
          {!hideCancelSearchButton && (
            <Button
              onClick={handleCancelSearch}
              variant="outlined"
              className="text-xs hover:text-sky-blue text-sky-blue-light"
            >
              {t('requisition_suppliers.cancel_search')}
            </Button>
          )}
        </div>
      </WarningBanner>
    </div>
  )
}

export default SearchInProgressBanner
