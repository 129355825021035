import { useTranslation } from 'react-i18next'
import { Can } from '../../providers/AbilityProvider'

interface IActionPanelInputHint {
  rawQuery: string
}

const ActionPanelInputHint: React.FC<IActionPanelInputHint> = ({ rawQuery }) => {
  const { t } = useTranslation()
  return (
    <Can I="seeActionPanelActions">
      <div className="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
        {t('generic.type_an_input')}
        <kbd
          className={
            `mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2
      ${rawQuery.startsWith('>') ? 'border-indigo-600 text-indigo-600' : 'border-gray-400 text-gray-900'}
    `
          }
        >
          &gt;
        </kbd>
        {t('action_panel.for_actions')}
      </div>
    </Can>
  )
}

export default ActionPanelInputHint
