import { useTranslation } from 'react-i18next'

const ActionPanelNoResults: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="py-14 px-6 text-center text-sm sm:px-14">
      <p className="mt-4 font-semibold text-gray-900">{t('generic.no_results')}</p>
      <p className="mt-2 text-gray-500">{t('action_panel.we_couldnt_find_anything')}</p>
    </div>
  )
}

export default ActionPanelNoResults
