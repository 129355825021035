import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import { InputSearchSelectItemType } from '../../../../../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { getMergedDeepOverwritingArrays } from '../../../../../../shared/utils/objectHelpers'
import { SearchSelectType } from '../../../../../../types/Forms'

const SearchSelectOptionSelection: React.FC = () => {
  const { t } = useTranslation()
  const { question: [question, setQuestion] } = useFormContext()

  const options: { id: SearchSelectType, label: string }[] = [
    { id: 'CUSTOM', label: t('question_builder.search_select.custom_option') },
    { id: 'MATERIAL_GROUPS', label: 'MATERIAL_GROUPS' },
    { id: 'COST_CENTER', label: 'COST_CENTER' },
    { id: 'ORDERS', label: 'ORDERS' },
    { id: 'PS_VALUE', label: 'PS_VALUE' },
  ]

  const onChange = (selection: InputSearchSelectItemType) => {
    if (!question || !selection) return

    setQuestion(getMergedDeepOverwritingArrays(question, { searchType: selection.id as SearchSelectType }))
  }

  const currentSelection = question?.searchType
    ? { id: question?.searchType, label: question?.searchType }
    : undefined

  if (question?.type !== 'SEARCH_SELECT') return null

  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-xs text-primary-gray">
        {t('question_builder.search_select.label')}
      </p>
      <div className="w-full max-w-lg">
        <SearchSelect
          onChange={onChange}
          options={options}
          currentSelection={currentSelection}
          className="text-sm border-border-gray"
          placeholder={t('question_builder.search_select.enter_option')}
        />
      </div>
    </div>
  )
}

export default SearchSelectOptionSelection
