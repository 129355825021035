import { useState } from 'react'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import InputNumber from '../../../../../../shared/components/atoms/inputs/InputNumber'
import useOfferIsDisabled from '../../../../hooks/useOfferIsDisabled'

interface IPriceInput {
  placeholder: string
  updateLineItem: (value: string | boolean | null) => void
  row: LineItemItemAttributeType
}

const PriceInput: React.FC<IPriceInput> = ({
  placeholder, updateLineItem, row,
}) => {
  const currentLineItem = row.line_items && row.line_items[0]
  const [value, setValue] = useState(currentLineItem?.value || '')

  const isDisabled = useOfferIsDisabled()

  return (
    <InputNumber
      placeholder={placeholder}
      onBlur={() => updateLineItem(value)}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="pr-5 w-full"
      disabled={isDisabled}
    />
  )
}

export default PriceInput
