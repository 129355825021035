import { useState } from 'react'

export const usePopupBoxState = () => {
  const [isOpen, setIsOpen] = useState(false)

  return {
    isOpen,
    toggle: () => setIsOpen(!isOpen),
    close: () => setIsOpen(false),
    open: () => setIsOpen(true),
  }
}

export default usePopupBoxState
