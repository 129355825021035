import { LineItemType } from '../../../../../../types/shared/LineItemDataTypes'
import useCheckOfferStatusAndUpdateIfSubmitted from '../../../../hooks/useCheckOfferStatusAndUpdateIfSubmitted'
import useGetToken from '../../../../hooks/useGetToken'
import { putCommentWithToken } from '../../../../services/commentService'

export default () => {
  const checkAndUpdateOfferStatus = useCheckOfferStatusAndUpdateIfSubmitted()
  const token = useGetToken()
  return async (
    initialLineItem: LineItemType,
    updateAutoSave: () => void,
  ) => {
    if (initialLineItem?.comment) {
      checkAndUpdateOfferStatus()
      await putCommentWithToken(initialLineItem.comment, token)
      updateAutoSave()
    }
  }
}
