import _ from 'lodash'
import { useQuery } from 'react-query'
import currencySymbol from '../../../../../../utils/currencySymbol'
import { getCatalogItem } from '../../../../../../services/UnifiedSearchService'
import { CATALOG_ITEM } from '../../../../../../constants/newReqQueryStrings'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'

const CatalogBuyPrice: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  if (!data) return <CoreLoadingIcon />

  return (
    <div className="flex items-center gap-0.5 whitespace-nowrap ">
      <p className="text-orange-500 text-2xl flex items-center">
        {currencySymbol[data.blockPricing[0].currency]}
        {' '}
        {_.divide((data.blockPricing[0].amount.value), 100)}
      </p>
    </div>
  )
}
export default CatalogBuyPrice
