import { LineItemItemAttributeType, LineItemOptionType, LineItemType } from '../../../../../../types/shared/LineItemDataTypes'
import { numberStringToGermanFloat } from '../../../../../../shared/constants/currencyFormat'
import RadioButton from '../../../../../../shared/components/atoms/inputs/RadioButton'
import useGetCalculatedLineItems from '../../../../../SupplierOffer/components/Footer/hooks/useGetCalculatedLineItems'
import useGetOrderedInvites from '../../hooks/useGetOrderedInvites'
import useSelectLineItem from '../../../../../SupplierOffer/components/DescriptionSection/Row/hooks/useSelectLineItem'

interface IOfferViewItemAttributeDescription {
  itemAttribute: LineItemItemAttributeType
  option: LineItemOptionType
}

const ItemPriceAttributeOption: React.FC<IOfferViewItemAttributeDescription> = (
  { itemAttribute, option },
) => {
  const invites = useGetOrderedInvites()
  const { mutate: selectLineItem } = useSelectLineItem()

  const firstInvite = invites[0]
  const defaultLineItem = (firstInvite?.offer?.line_items as LineItemType[])?.find(
    (lineItem) => lineItem?.item_attribute_option_id === option.id,
  )
  const calculatedLineItems = firstInvite?.calculated_price.product_prices.flatMap(
    ({ lineItemsPrices }) => Object.values(lineItemsPrices),
  ) || []
  const calculatedLineItem = defaultLineItem
    && calculatedLineItems.find(({ lineItemId }) => lineItemId === (defaultLineItem.id as number))

  if (itemAttribute.type !== 'price') return null

  const select = () => {
    invites.forEach((invite) => {
      const inviteLineItem = (invite?.offer?.line_items as LineItemType[])?.find(
        (lineItem) => lineItem?.item_attribute_option_id === option.id,
      )
      if (!inviteLineItem) return
      selectLineItem({ invite, lineItemId: inviteLineItem.id as number })
    })
  }

  return (
    <div className="flex gap-2 items-center">
      {itemAttribute.options.length > 1 && calculatedLineItem && (
      <RadioButton
        checked={calculatedLineItem.selected}
        onClick={select}
      />
      )}
      {`For ${numberStringToGermanFloat(option.option_value)} ${(itemAttribute.unit_of_measurement || 'pieces').toLowerCase()}`}
    </div>
  )
}

export default ItemPriceAttributeOption
