import { Combobox } from '@headlessui/react'
import classnames from 'classnames'
import {
  ChangeEvent, HTMLAttributes, useEffect, useState,
} from 'react'
import { HiChevronDown, HiX } from 'react-icons/hi'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import * as _ from 'lodash'
import InputError from '../../../../modules/Forms/FormResponsePage/QuestionTypes/atoms/InputError'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelectOption from './SearchSelectOption'
import SearchSelectOptions from './SearchSelectOptions'

export interface ISearchMultiSelect {
  onChange: (value: InputSearchSelectItemType[]) => void, // todo this callback actually receives value: InputSearchMultiSelectItemType | undefined
  onClear?: () => void,
  disabled?: boolean,
  children?: ReactI18NextChild | Iterable<ReactI18NextChild>,
  options?: InputSearchSelectItemType[],
  currentSelection?: InputSearchSelectItemType[]
  onInputChange?: (value: string) => any
  placeholder?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
  wrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionsWrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionsClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionClassName?: HTMLAttributes<HTMLDivElement>['className']
  nullable?: boolean
  error?: string,
  onAdd?: () => void,
  addButtonText?: string,
  isLoading?: boolean,
  alwaysVisible?: boolean,
  showArrow?: boolean,
  inputWrapperClassNames?: HTMLAttributes<HTMLDivElement>['className']
  showSelectionAsQuery?: boolean
  showSearchIcon?: boolean,
  inputId?: string
  multiple?: boolean
}

const SearchMultiSelect: React.FC<ISearchMultiSelect> = ({
  options, onChange, currentSelection, disabled, onInputChange, placeholder, className, nullable, wrapperClassName,
  error,
  onAdd,
  addButtonText,
  onClear = () => { },
  children,
  isLoading = false,
  alwaysVisible = false,
  showArrow = true,
  inputWrapperClassNames,
  showSelectionAsQuery = true,
  optionsWrapperClassName,
  optionsClassName,
  optionClassName,
  showSearchIcon = false,
  inputId = '',
}) => {
  const [query, setQuery] = useState('')
  const [selection, setSelection] = useState<InputSearchSelectItemType[] | undefined>(
    undefined,
  )

  useEffect(() => {
    setSelection((currentSelection && currentSelection.map((item) => ({ id: item?.id, label: item.label }))) || undefined)
  }, [])

  // If options change, and currentSelection is in options, update selection
  useEffect(() => {
    if (currentSelection && options) {
      const newSelection = currentSelection.filter((item) => options.find((option) => option.id === item.id))
      setSelection(newSelection)
    }
  }, [options])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    onInputChange?.(e.target.value)
  }

  const handleSelection = (value: InputSearchSelectItemType[]) => {
    if (!value) {
      setSelection([])
      onClear && onClear()
    }

    const val = value.filter((v) => (value.filter((valu) => valu.id === v.id).length || 0) < 2)

    setSelection(val)
    onChange(val as InputSearchSelectItemType[])
  }

  const filteredOptions = query === ''
    ? options
    : options?.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()))

  const optionsElements = filteredOptions && filteredOptions.length > 0 ? (
    <div className={classnames('max-h-44 overflow-auto', optionsWrapperClassName)}>
      {filteredOptions?.map((option) => (
        <SearchSelectOption key={option.id} option={option} className={optionClassName} />
      ))}
    </div>
  ) : null

  return (
    <Combobox
      as="div"
      value={selection || []}
      disabled={disabled}
      onChange={handleSelection}
      className={classnames(wrapperClassName)}
    >
      <div className="relative">
        <Combobox.Button as="div" className={classnames('relative flex flex-row gap-1 items-center', inputWrapperClassNames)}>
          {showSearchIcon && (
            <MagnifyingGlassIcon className="h-5 w-5 text-black" aria-hidden="true" />
          )}
          <Combobox.Input
            id={inputId}
            className={classnames(
              { inputErrorClassNames: !!error },
              'base-form-input',
              className,
            )}
            onChange={handleInputChange}
            displayValue={() => (showSelectionAsQuery ? (selection?.map((item) => item.label).join(', ') || '') : query)}
            placeholder={placeholder}
          />
          <div className="absolute inset-y-0 right-0 flex items-center mx-1 ">
            {nullable && selection && (
              <button
                type="button"
                className="flex items-center rounded-r-md focus:outline-none"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClear()
                }}
              >
                <HiX className="h-4 w-4 " aria-hidden="true" />
              </button>
            )}
            {showArrow && (
              <Combobox.Button
                className="flex items-center rounded-r-md focus:outline-none px-2"
              >
                <HiChevronDown className="h-5 w-5 text-black" aria-hidden="true" />
              </Combobox.Button>
            )}
          </div>

        </Combobox.Button>

        <SearchSelectOptions
          alwaysVisible={alwaysVisible}
          optionsElements={optionsElements}
          onAdd={onAdd}
          addButtonText={addButtonText}
          isLoading={isLoading}
          className={optionsClassName}
        >
          {children}
        </SearchSelectOptions>
      </div>
      <InputError error={error} />
    </Combobox>
  )
}

export default SearchMultiSelect
