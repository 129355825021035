import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import usePermissions from '../../../../../core/hooks/usePermissions'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../shared/components/atoms/SharedTable/TableRow'
import EditDeleteOptions from '../../../../../shared/components/organisms/menus/EditDeleteMenu'
import { dateFormat } from '../../../../../shared/constants/dateFormat'
import { FORM_DELETE } from '../../../../../shared/constants/permissions'
import { FormDataType } from '../../../../../types/Forms'
import { apiDeleteForm, apiGetForm } from '../../../constants/formService'
import useGetCompanyForms from '../../hooks/useGetCompanyForms'
import usePostCompanyForm from '../../hooks/usePostCompanyForm'

interface ICompanyFormsListRow {
  companyForm: FormDataType
}

const CompanyFormsListRow: React.FC<ICompanyFormsListRow> = ({ companyForm }) => {
  const history = useHistory()
  const { refetch } = useGetCompanyForms()
  const permissions = usePermissions([{ slug: FORM_DELETE }])
  const { mutate: postForm } = usePostCompanyForm()

  const handleOnEdit = () => {
    history.push(`/forms/${companyForm.__uuid}/content`)
  }

  const handleOpenForm = () => {
    history.push(`/forms/${companyForm.__uuid}/content`)
  }

  const handleOnDelete = async () => {
    await apiDeleteForm(companyForm.__uuid)
    refetch()
  }

  const handleCopy = async () => {
    const fullForm = await apiGetForm(companyForm.__uuid)
    postForm({ ...fullForm, name: fullForm.name.endsWith('(copy)') ? fullForm.name : `${fullForm.name} (copy)` })
  }

  const createdAt = dayjs(companyForm?.created_at).format(dateFormat)
  const updatedAt = dayjs(companyForm?.updated_at).format(dateFormat)

  const { t } = useTranslation()

  return (
    <TableRow onClick={handleOpenForm}>
      <TableCell>
        <div className="flex flex-col">
          <span className="font-bold">{companyForm.name}</span>
          <span>
            by
            {' '}
            {companyForm.creator.full_name}
          </span>
        </div>
      </TableCell>
      <TableCell />
      <TableCell>
        <span>{`${t('generic.created_on')} ${createdAt}`}</span>
      </TableCell>
      <TableCell>
        <span>{`${t('generic.last_updated')} ${updatedAt}`}</span>
      </TableCell>
      {permissions.get(FORM_DELETE).allowed
        ? (
          <TableCell>
            <EditDeleteOptions
              onEdit={handleOnEdit}
              onDelete={handleOnDelete}
              onCopy={handleCopy}
            />
          </TableCell>
        ) : null}
    </TableRow>
  )
}

export default CompanyFormsListRow
