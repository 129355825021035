import * as React from 'react'
import classnames from 'classnames'
import IntakeFlowchartApprovalNodeContent from './IntakeFlowchartApprovalNodeContent/IntakeFlowchartApprovalNodeContent'
import useBlockStatus from './hooks/useBlockStatus'
import { WorkflowChartNodeTemplate } from '../../../../../WorkflowChart'
import { WorkflowChartBlockNodeProps } from '../../../../../../types/Workflow/WorkflowChart'
import { useIntakeFlow } from '../../../../providers/IntakeFlowProvider'
import { useBlockIcon } from './hooks/useBlockIcon'

const blockContentComponents = {
  APPROVAL: IntakeFlowchartApprovalNodeContent,
  APPROVER: IntakeFlowchartApprovalNodeContent,
  GROUP: undefined,
  HELPER: undefined,
}

export const IntakeFlowchartNode = (node: WorkflowChartBlockNodeProps) => {
  const { data } = node
  const status = data.status || 'pending'
  const Icon = useBlockIcon(data)
  const Content = blockContentComponents[data.workflow_block_type?.name || 'APPROVAL']
  const { toggleBlockInspection } = useIntakeFlow()

  const { requiresCurrentUserApproval } = useBlockStatus(data)

  const handleClick = () => {
    toggleBlockInspection(data)
  }

  return (
    <WorkflowChartNodeTemplate
      node={node}
      className={classnames({ 'border border-sky-blue-light': requiresCurrentUserApproval })}
      onClick={handleClick}
    >
      <div className="flex flex-row gap-3 border-b border-b-ice-blue pb-2 w-full items-center">
        {Icon && (
          <div className={classnames('flex-grow-0 rounded-md p-1', {
            'bg-green-light text-white': status === 'approved',
            'bg-sky-blue text-white': ['ongoing', 'pending'].includes(status),
            'border-2  border-gray-400 text-gray-400': ['in_queue'].includes(status),
            'bg-gray-100 text-gray-800': status === 'skipped',
            'bg-red-500 text-white': status === 'declined',
          })}
          >
            <Icon className="h-6" />
          </div>
        )}
        <div className="flex-grow-1 font-bold">
          {data.name}
        </div>
      </div>
      <div className="w-full">
        {Content && <Content block={data} />}
      </div>
    </WorkflowChartNodeTemplate>
  )
}

export default IntakeFlowchartNode
