import useGetChatPreviews from '../../hooks/useGetChatPreviews'
import ChatWindow from './Chat/Chat'
import QAAssignments from './QAAssignments/QAAssignment'

const MessageClientChatListColumn: React.FC = () => {
  const { data: chatsPreviews } = useGetChatPreviews()

  const scrollableDiv = document.getElementById('scrollableDiv') as HTMLElement
  const maxHeight = (scrollableDiv && scrollableDiv.offsetHeight - 120) || 100

  return (
    <div
      className="w-1/3 overflow-visible overflow-y-auto rounded-bl-lg"
      id="chat-list-column"
      style={{ maxHeight }}
    >
      <QAAssignments />
      {chatsPreviews?.map((preview) => (
        <ChatWindow key={preview.modelId} preview={preview} />
      ))}
    </div>
  )
}

export default MessageClientChatListColumn
