import { FunctionComponent } from 'react'
import DatePicker from 'react-datepicker'
import { MdOutlineInsertInvitation } from 'react-icons/md'

interface IDatepickerRange {
  selected: (Date | null)[],
  onChange(date: (Date | null)[],
    event: React.SyntheticEvent<any, Event> | undefined): void,
  className?: string,
  selectTime?: boolean,
  placeholder?: string,
  minDate?: Date | null,
  maxDate?: Date | null,
}

const SharedDatepickerRange: FunctionComponent<IDatepickerRange> = ({
  selected,
  onChange,
  className,
  selectTime,
  placeholder,
  minDate,
  maxDate,
}) => (
  <div className="flex flex-col items-end 0">
    <div className="flex w-max relative">
      <MdOutlineInsertInvitation className="text-gray-400 absolute text-base z-10" style={{ left: 10, top: 10 }} />
      <DatePicker
        onChange={onChange}
        selected={selected[0]}
        startDate={selected[0]}
        endDate={selected[1]}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        previousMonthButtonLabel="<"
        nextMonthButtonLabel=">"
        shouldCloseOnSelect
        selectsRange
        showTimeSelect={selectTime}
        timeFormat="HH:mm"
        timeCaption="Zeit"
        dateFormat="dd.MM"
        className={`z-20 pl-8 rounded-md text-xs items-center flex ${className}`}
      />
    </div>
  </div>
)

SharedDatepickerRange.defaultProps = {
  className: '',
  selectTime: false,
  placeholder: 'Select date',
  minDate: undefined,
  maxDate: undefined,
}

export default SharedDatepickerRange
