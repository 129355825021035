import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import useListExternalDataEntities from '../../ExternalDataManager/hooks/useListExternalDataEntities'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { ExternalDataListEntity } from '../../../types/ExternalDataManager/ExternalDataManagerTypes'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { FilterSettingsType } from '../../../types/Forms'

interface ICompanyExternalDataSelect {
  handleFilterSettingsInput: (updatedSettings: Partial<FilterSettingsType>) => void
  selectedFileUuid: string | undefined
}
const DatasetSelect: React.FC<ICompanyExternalDataSelect> = ({ handleFilterSettingsInput, selectedFileUuid }) => {
  const { t } = useTranslation()
  const { data: externalDataEntities } = useListExternalDataEntities()

  // Map externalDataEntities to SearchSelect options
  let options: InputSearchSelectItemType[] = []
  if (externalDataEntities) {
    options = externalDataEntities.map((entity: ExternalDataListEntity) => ({
      id: entity.uuid,
      label: entity.name,
    }))
  }
  const selectedOption = options.find((option) => option.id === selectedFileUuid)

  // Set options in state to avoid uuid changes on rerender
  const [optionsState, setOptionsState] = useState<InputSearchSelectItemType[]>(options)
  useEffect(() => {
    setOptionsState(options)
  }, [externalDataEntities])

  const onSelectedReferenceDatasetChange = (uuid: string) => {
    handleFilterSettingsInput({ data_source: uuid })
  }

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">{t('inputs.referenced_dataset', 'Referenced Dataset')}</label>
      <SearchSelect
        onChange={(item) => onSelectedReferenceDatasetChange(item.id as string)}
        options={optionsState}
        placeholder={t('inputs.select_referenced_dataset', 'Select dataset to reference')}
        currentSelection={selectedOption}
      />
    </div>
  )
}

export default DatasetSelect
