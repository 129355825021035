import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useGetOffer from '../../../hooks/useGetOffer'
import NetGrossDropdownButton from './NetGrossDropdownButton'
import useToggleNetOrGross from './useToggleNetOrGross'

const NetGrossDropdown: React.FC = () => {
  const [netDropdownOpen, setNetDropdownOpen] = useState(false)
  const [isDropdownLoading, setIsDropdownLoading] = useState(false)

  const { t } = useTranslation()

  const { data: offerData } = useGetOffer()

  const toggleNetOrGross = useToggleNetOrGross(setNetDropdownOpen, setIsDropdownLoading)

  const isNet = offerData?.offer?.is_net

  return (
    <div className="flex flex-col pl-4 justify-center relative w-28">
      <div className="absolute w-max">
        {netDropdownOpen
          && (
            <button
              className="border p-2 text-left text-sm absolute w-full rounded-md overflow-hidden rounded-b-none bg-white -top-[37px] hover:bg-gray-100"
              type="button"
              onClick={toggleNetOrGross}
            >
              <p>{isNet ? t('supplier_offer.tax_incl') : t('supplier_offer.tax_excl')}</p>
            </button>
          )}
        <NetGrossDropdownButton
          setNetDropdownOpen={setNetDropdownOpen}
          isDropdownLoading={isDropdownLoading}
          netDropdownOpen={netDropdownOpen}
        />
      </div>

    </div>
  )
}

export default NetGrossDropdown
