import { useTranslation } from 'react-i18next'
import { IFloatingElements } from './FloatingElements'

interface ISaveButton extends Pick<IFloatingElements, | 'onSave' | 'setIsInputActive' | 'disabled'> {
  charError: boolean
}

const SaveButton: React.FC<ISaveButton> = ({
  onSave, setIsInputActive, disabled, charError,
}) => {
  const { t } = useTranslation()

  const isSaveDisabled = disabled || charError

  const onSaveHandler = () => {
    setIsInputActive?.(false)
    onSave()
  }
  return (
    <button
      type="button"
      onClick={onSaveHandler}
      className={`font-medium text-sm ${isSaveDisabled ? 'text-lhotse-gray' : 'text-sky-blue hover:brightness-75'}`}
      disabled={isSaveDisabled}
      data-cy="input-save-button"
    >
      <p>{t('generic.save')}</p>
    </button>
  )
}

export default SaveButton
