import { useCallback } from 'react'
import { omit, uniqBy } from 'lodash'
import { useRequisitionFilters } from '../provider/RequisitionFiltersProvider'
import { RequisitionFilterConfig, RequisitionFilterType } from '../types'

export const useRequisitionFilterMethods = () => {
  const { filters, setFilters, setFilterValues } = useRequisitionFilters()

  return {
    findFilter: useCallback((filterId: string) => filters.find(({ id }) => id === filterId), [filters]),

    addFilter: useCallback((newFilter: RequisitionFilterConfig) => {
      setFilters((currentFilters) => uniqBy([...currentFilters, newFilter], 'id'))
    }, [setFilters]),

    removeFilter: useCallback((filterToRemove: RequisitionFilterType) => {
      setFilters((currentFilters) => currentFilters.filter((filter) => filter.id !== filterToRemove.id))
      setFilterValues((currentFilterValues) => omit(currentFilterValues, filterToRemove.id))
    }, [setFilters]),

    removeAllFilters: useCallback(() => { setFilters([]); setFilterValues({}) }, [setFilters]),
  }
}

export default useRequisitionFilterMethods
