import dayjs from 'dayjs'

import { useQuery } from 'react-query'
import TipTapEditor from '../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import messengerQueryKeys from '../../../Messenger/messengerQueryKeys'
import { apiGetChatMessages } from '../../../Messenger/service'

const SubmissionPageMessageList = ({ modelId, model = 'requisition' }: { modelId: string, model: string }) => {
  // const { requisitionId } = useParams<{ requisitionId: string }>()
  const params = { model_type: model, model_id: modelId }
  const { data: messageData } = useQuery(
    [messengerQueryKeys.messages, model, modelId],
    () => apiGetChatMessages('', params),
  )

  return (
    <div className="text-xs space-y-2 flex flex-col p-2 ">
      {messageData?.sort((a, b) => (a.created_at < b.created_at ? 1 : -1)).map((m) => (
        <div key={m.uuid} className={` w-5/6 ${m.is_my_message ? 'self-end' : null} border-b bg-white`}>
          <div className="flex justify-between p-2">
            <div className="font-bold">{m.is_my_message ? 'you' : m.user.full_name}</div>
            <div>{dayjs(m.created_at).fromNow()}</div>
          </div>
          <TipTapEditor
            content={m.text_message}
            className="text-sm rounded-md mr-12 font-light shadow-none "
            expandedEditor
            editable={false}
            editorContentClassName="pl-2 pr-2 "
          />
        </div>
      ))}
    </div>
  )
}

export default SubmissionPageMessageList
