import { useTranslation } from 'react-i18next'
import TipTapReadOnly from '../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import useAutoSave from '../../../Requisition/utils/useAutoSave'
import useGetOffer from '../../hooks/useGetOffer'
import { invalidIdStateType } from '../../utils/validationUtils'
import AutoSaveBanner from './Sections/AutoSaveBanner'
import Sections from './Sections/Sections'

interface IDescriptionSection {
  requisitionId?: number
  invalidIds?: invalidIdStateType
}

const DescriptionSection: React.FC<IDescriptionSection> = ({
  requisitionId, invalidIds,
}) => {
  const { t } = useTranslation()
  const [autoSave, updateAutoSave] = useAutoSave()
  const { data: offerData } = useGetOffer()

  const requisitionData = offerData?.requisition

  return (
    <div className="border border-gray-200 rounded-md w-full bg-white text-sm">
      <AutoSaveBanner
        autoSave={autoSave}
        title={t('new_requisition.description_requirements')}
      />
      <div className="p-5 flex flex-col gap-y-5">
        {requisitionData?.description && <TipTapReadOnly text={requisitionData?.description} />}
        <Sections
          requisitionId={requisitionId}
          invalidIds={invalidIds}
          updateAutoSave={updateAutoSave}
        />
      </div>
    </div>
  )
}

export default DescriptionSection
