import SettingRow from './SettingRow'
import InputSelect, { IInputSelect } from '../atoms/InputSelect'

export type ISliderSettingRow = React.PropsWithChildren<{
  title: string
  description?: string
} & IInputSelect>

const SelectorSettingRow: React.FC<ISliderSettingRow> = ({
  children, title, description, ...selectorProp
}) => (
  <SettingRow title={title} description={description} input={(<InputSelect {...selectorProp} />)}>
    {children}
  </SettingRow>
)

export default SelectorSettingRow
