import { useTranslation } from 'react-i18next'
import Button from '../../atoms/buttons/Button'
import TextMidLineTitle from '../../atoms/texts/TextMidLineTitle'

interface INewAssignee {
  onClick: () => void
}

const NewAssignee: React.FC<INewAssignee> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-gray-400 text-sm mt-2">
        <TextMidLineTitle>{t('generic.new_assignee')}</TextMidLineTitle>
        <div className="text-gray-400 py-2">
          {t('generic.new_assignee_description')}
        </div>
      </div>
      <Button variant="solid" color="lhotse-orange" onClick={onClick}>{t('generic.new_assignee_button')}</Button>
    </>
  )
}

export default NewAssignee
