import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import ActionPanel from './ActionPanel'
import useBindCmdKShortcut from './hooks/useBindCmdKShortcut'

const ActionPanelContainer: React.FC = () => {
  const [open, setOpen] = useState(false)
  useBindCmdKShortcut(setOpen)

  const [rawQuery, setRawQuery] = useState('')

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setRawQuery('')}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>
        <ActionPanel setRawQuery={setRawQuery} setOpen={setOpen} rawQuery={rawQuery} />
      </Dialog>
    </Transition.Root>
  )
}

export default ActionPanelContainer
