import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { useState } from 'react'
import NewRequisitionTemplateTypeSelector from '../NewRequisitionTemplateTypeSelector'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'
import { RequisitionSearchDataDataType } from '../../../../../../types/Requisition/RequisitionSearchResultsDataTypes'
import { apiDuplicateRequisition } from '../../../../services/RequisitionService'
import Button from '../../../../../../shared/components/atoms/buttons/Button'

interface INewReqPastReqModalHeader {
  requisitionData: RequisitionSearchDataDataType
  preview: boolean
}

const NewReqPastReqModalHeader: React.FC<INewReqPastReqModalHeader> = (
  { requisitionData, preview },
) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { modal } = useUtils()
  const closeModal = () => modal.set({ isOpen: false })
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: createRequisition } = useMutation(apiDuplicateRequisition, {
    onSuccess: (newData) => {
      history.push(`/requisition/req-${newData.id}/define`)
      closeModal()
    },
    onError: () => { toastr.error('error.item_not_created', 'itemNotCreated') },
  })

  const createRequisitionFromTemplate = () => {
    if (requisitionData?.id) {
      setIsLoading(true)
      createRequisition(requisitionData?.id)
    }
  }

  return (
    <div
      className="text-gray-900 flex w-full justify-between items-center"
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1 w-max">
          {requisitionData?.name}
          <NewRequisitionTemplateTypeSelector
            type={Number(requisitionData?.type)}
          />
        </div>

      </div>
      {!preview
        && (
          <div className="w-full flex justify-end text-right px-4 text-sm" data-cy="create-from-template">
            <Button
              color="lhotse-orange"
              loading={isLoading}
              variant="solid"
              onClick={createRequisitionFromTemplate}
            >
              {t('new_requisition.start_template')}
            </Button>
          </div>
        )}
    </div>
  )
}

export default NewReqPastReqModalHeader
