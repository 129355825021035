// REFACTOR
import { useAbility } from '@casl/react'
import { useTranslation } from 'react-i18next'
import { ABILITIES } from '../../../../../../config/ability'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import useDebouncedQuery from '../../../../../../core/hooks/useDebouncedQuery'
import { AbilityContext } from '../../../../../../core/providers/AbilityProvider'
import SharedFilters from '../../../../../../shared/components/organisms/Filters/Filters'
import { CATALOG_ITEMS } from '../../constants/newReqQueryStrings'
import useOpenCatalogItemModal from '../../hooks/useOpenCatalogItemModal'
import { useFilters } from '../../providers/FiltersProvider'
import { apiGetCatalogItems } from '../../services/UnifiedSearchService'
import NewRequisitionTemplateItemsGrid from '../NewRequisitionTemplateItemsGrid'
import NewRequisitionTemplateCatalogItem from './NewRequisitionTemplateCatalogItem'

const NewReqCatalogTab: React.FC = () => {
  const { filters, query: [query] } = useFilters()
  const { t } = useTranslation()
  const ability = useAbility(AbilityContext)

  const openModal = useOpenCatalogItemModal()

  const {
    data: resolvedUnifiedData,
    isLoading,
  } = useDebouncedQuery(
    [CATALOG_ITEMS, query],
    apiGetCatalogItems,
    { query },
  )

  if (!ability.can(ABILITIES.mercateoSearch)) {
    return (
      <div className="w-full flex justify-center">
        <p className="text-sm text-gray-500">{t('new_requisition.no_results')}</p>
      </div>
    )
  }

  if (isLoading) return <CoreLoadingIcon />

  return (
    <div className="w-full flex flex-col items-end gap-y-2">
      <SharedFilters
        meta={resolvedUnifiedData?.meta}
        filterState={filters}
        query={query}
        disabled={!resolvedUnifiedData?.meta}
      />
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full">
          {resolvedUnifiedData?.data
            ? (
              <NewRequisitionTemplateItemsGrid
                openModal={openModal}
                itemComponent={NewRequisitionTemplateCatalogItem}
                data={resolvedUnifiedData.data}
              />
            )
            : (
              <div className="flex w-full h-full justify-center items-center">
                {t('new_requisition.type_for_results')}
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default NewReqCatalogTab
