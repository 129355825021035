import { MdOutlineLibraryAdd } from 'react-icons/md'
import useNavigate from '../../../../../shared/hooks/useNavigate'
import { FormDataType } from '../../../../../types/Forms/formTypes'

interface IFormPreview {
  form: FormDataType
}

const FormPreview: React.FC<IFormPreview> = ({ form }) => {
  const navigate = useNavigate()

  return (
    <button
      type="button"
      key={form.__uuid}
      className="flex flex-col gap-y-2"
      onClick={(e) => navigate(`/forms/${form.__uuid}/create`, e)}
    >
      <div className="border text-nds-tertiary-gray flex h-48 w-48 items-center justify-center shadow-sm rounded-md">
        {form.thumbnail.url
          ? (
            <img
              src={form.thumbnail.url}
              alt="file thumbnail"
              className="object-cover w-full h-full"
            />
          )
          : <MdOutlineLibraryAdd size={50} />}
      </div>
      <p className="text-xs text-primary-gray">{form.name}</p>
    </button>
  )
}

export default FormPreview
