import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import useGetReqSections from '../../Coordinate/hooks/useGetReqSections'
import { ReqDefEditorDataType } from '../constants/editorTypes'
import useGetRequisitionData from '../hooks/useGetRequisition'

export type ReqDefErrorsType = {
  allowSubmit: boolean
  missingEndsAt: boolean
  firstRowHasDescription: boolean
  firstRowHasQuantity: boolean
}

const useValidateRequisition = () => {
  const { data: requisitionData } = useGetRequisitionData()
  const { data: sectionsData } = useGetReqSections()
  const [errors, setErrors] = useState<any>()

  // Check if at least one product exists and has a description and quantity
  const getProductData = (sectionData: ReqDefEditorDataType | undefined) => {
    if (!sectionData) {
      return {
        hasDescription: false,
        hasQuantity: false,
      }
    }

    const productTypeSections = sectionData.filter((section) => section.section_type === 'PRODUCT')

    for (let i = 0; i < productTypeSections.length; i += 1) {
      const section = productTypeSections[i]
      const sectionAttributes = section.item_attributes
      const priceAttribute = sectionAttributes.find((attribute) => attribute.type === 'price')

      // Look for any section that has a description and a price and return
      if (section?.description !== '' && priceAttribute?.options[0]?.option_value) {
        return {
          hasDescription: true,
          hasQuantity: true,
        }
      }
    }

    return {
      hasDescription: false,
      hasQuantity: false,
    }
  }

  useEffect(() => {
    const missingEndsAt = !requisitionData?.ends_at

    const endsInUnder7Days = !missingEndsAt && dayjs(requisitionData?.ends_at).diff(new Date(), 'day') < 7

    const { hasDescription, hasQuantity } = getProductData(sectionsData)

    const allowSubmit = !missingEndsAt && hasDescription && hasQuantity

    setErrors({
      allowSubmit,
      missingEndsAt,
      endsInUnder7Days,
      firstRowHasDescription: hasDescription,
      firstRowHasQuantity: hasQuantity,
    })
  }, [requisitionData, sectionsData])

  return errors
}

export default useValidateRequisition
