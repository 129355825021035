import { useTranslation } from 'react-i18next'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import { requisitionDefinitionEditorRowInfoPicker } from '../../../../../Requisition/subModules/Definition/utils/rowInfoPicker'
import { invalidIdStateType } from '../../../../utils/validationUtils'
import Title from './Title'

interface IIconAndTitle {
  row: LineItemItemAttributeType
  invalidIds: invalidIdStateType | undefined
}

const IconAndTitle: React.FC<IIconAndTitle> = (
  { row, invalidIds },
) => {
  const { t } = useTranslation()
  const { icon: Icon } = requisitionDefinitionEditorRowInfoPicker(row.type)
  const isMultiLineInput = !!row.options.length

  return (
    <div className={`flex items-center gap-x-2 ${isMultiLineInput && 'pb-4'}`}>
      { Icon && (<Icon className="text-gray-400 text-xl mt-0.5 min-h-[20px] min-w-[20px]" />) }
      <div className="flex flex-col">
        <Title row={row} />
        {row.comment && <p className="text-sm text-gray-400">{row.comment.comment}</p>}
        {invalidIds?.rows?.includes(row.id)
          && <p className="text-red-600 text-xs">{t('supplier_offer.must_input_value')}</p>}
      </div>
    </div>
  )
}

export default IconAndTitle
