import { FormQuestionType } from '../../../../types/Forms'
import { useFormLogic } from '../providers/FormLogicProvider'

export default () => {
  const { allEnabledQuestions } = useFormLogic()

  return (questionOrUuidToCheck?: FormQuestionType | string) => allEnabledQuestions
    .filter(({ isFormElement }) => !isFormElement)
    .findIndex(({ __uuid }) => ((questionOrUuidToCheck as FormQuestionType)?.__uuid || questionOrUuidToCheck) === __uuid)
}
