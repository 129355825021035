import { t } from 'i18next'
import { MdError } from 'react-icons/md'
import { useQueryClient } from 'react-query'
import { LineItemItemAttributeType } from '../../../../../../../../../../types/shared/LineItemDataTypes'
import Tooltip from '../../../../../../../../../../shared/components/organisms/tooltip/Tooltip'
import useGetRequisitionId from '../../../../../../../../../../shared/hooks/useGetRequisitionId'
import { ReqDefEditorSectionType } from '../../../../../../constants/editorTypes'
import { handleToggleButton } from '../../../../../../utils/editorUtils'
import getRowButtonsInfo from './getRowButtonsInfo'

interface IMandatoryButton {
  isFirstRow: boolean | undefined
  updateAutoSave: () => void
  section: ReqDefEditorSectionType
  itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>
}

const MandatoryButton: React.FC<IMandatoryButton> = ({
  itemAttribute, isFirstRow, section, updateAutoSave,
}) => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()

  const {
    defaultButtonStates, buttonStates, displayButtons,
  } = getRowButtonsInfo(itemAttribute, !!isFirstRow)

  const mandatoryTooltip = buttonStates.isRequired ? 'req_def.mark_input_optional' : 'req_def.mark_input_mandatory'

  const handleOnClick = () => {
    if (!isFirstRow) {
      handleToggleButton('is_required', itemAttribute, defaultButtonStates, cache, requisitionId, section, updateAutoSave)
    }
  }

  return displayButtons.isRequired ? (
    <Tooltip
      tooltip={t(!isFirstRow ? mandatoryTooltip : 'req_def.input_is_mandatory')}
      showCondition
      className="flex items-center justify-center h-full"
      id={`mandatory ${itemAttribute.id}`}
    >
      <button
        type="button"
        data-cy="req-def-mandatory-button"
        onClick={handleOnClick}
        className="p-2 text-2xl"
      >
        <MdError className={`${!isFirstRow ? 'cursor-pointer' : 'cursor-default'} w-full h-full text-gray-400 ${buttonStates.isRequired && '!text-sky-blue-light'}`} />
      </button>
    </Tooltip>
  )
    : null
}

export default MandatoryButton
