import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React, { HTMLAttributes } from 'react'
import classnames from 'classnames'
import Button from '../atoms/buttons/Button'

export type PageHeaderProps = {
    title?: string
    children?: React.ReactNode
    sideSection?: React.ReactNode
    className?: HTMLAttributes<HTMLDivElement>['className']
}

export const PageHeaderWithBasicNavigation = React.forwardRef<HTMLDivElement, PageHeaderProps>(({
  title, className, sideSection, children,
}, ref) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={classnames('flex sticky top-0 flex-col items-start bg-background z-10 w-full pt-8 px-20 pb-12', className)} ref={ref}>
      <div className="mb-[10px]">
        <Button className="text-black-light-2 !p-0 text-sm" onClick={() => history.goBack()}>
          <ChevronLeftIcon className="h-[10px]" />
          {t('generic.back', 'Back')}
        </Button>
      </div>
      <div className="flex flex-row min-h-[2.25rem] justify-between w-full mb-5">
        <div className="text-2xl font-bold">{title || ''}</div>
        <div className="flex items-center">
          {sideSection}
        </div>
      </div>
      {children && (
        <div className="w-fit">
          {children}
        </div>
      )}
    </div>
  )
})

PageHeaderWithBasicNavigation.displayName = 'PageHeaderWithBasicNavigation'

export default PageHeaderWithBasicNavigation
