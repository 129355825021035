import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import { checkAndUploadFile } from '../../../../core/utils/fileUploadUtils'
import settingsQueryKeys from '../../../Settings/constants/settingsQueryKeys'
import useGetCompanyInfo from '../documents/hooks/useGetCompanyInfo'
import CompanyImageWrapper from './ImageWrapper'

const CompanyImageUpload = () => {
  const profileImageRef = useRef<HTMLInputElement>(null)
  const cache = useQueryClient()
  const [imageUploading, setImageUploading] = useState(false)
  const { data: companyData, isLoading } = useGetCompanyInfo()

  const onProfileImageUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const files = e?.target?.files
    if (files && companyData) {
      setImageUploading(true)
      try {
        const uploadedFile = await checkAndUploadFile(files[0], 'Company', companyData.id, 'image', true)
        setImageUploading(false)
        if (uploadedFile) {
          cache.setQueryData(settingsQueryKeys.COMPANY_INFO, (oldData: any) => ({
            ...oldData,
            image: uploadedFile?.url,
          }))
        }
      } catch {
        setImageUploading(false)
      }
    }
  }

  return (
    <div className="w-full bg-white p-3 rounded-lg border border-gray-300">
      <div className="flex gap-x-6 items-center">
        <button
          className={`flex justify-center items-center rounded-md h-20 min-w-[80px] max-w-[120px] w-fit ${!companyData?.image && 'border'} overflow-hidden`}
          type="button"
          onClick={() => profileImageRef?.current?.click()}
        >
          {imageUploading && <CoreLoadingIcon className="h-[18px]" />}
          <div className={`${imageUploading ? 'hidden' : ''}  rounded-lg overflow-hidden`}>
            {!isLoading
              ? <CompanyImageWrapper image={companyData?.image} />
              : <CoreLoadingIcon />}
          </div>
        </button>
        <p className="text-2xl">{companyData?.name}</p>
      </div>
      <input
        ref={profileImageRef}
        type="file"
        className="hidden"
        onChange={onProfileImageUpload}
      />
    </div>
  )
}

export default CompanyImageUpload
