import classnames from 'classnames'
import * as React from 'react'
import { HTMLAttributes } from 'react'
import { Position } from 'reactflow'
import { WorkflowChartBlockNodeProps } from '../../../../../types/Workflow/WorkflowChart'
import { NODE_HEIGHT, NODE_WIDTH } from '../../../helpers'
import { useWorkflowChart } from '../../../providers/WorkflowChartProvider'
import WorkflowChartNodeHandle from './WorkflowChartNodeHandle'

export type WorkflowChartNodeTemplateProps = React.PropsWithChildren<{
  node: WorkflowChartBlockNodeProps
}> & HTMLAttributes<HTMLDivElement>

export const WorkflowChartNodeTemplate = ({
  node: { id, data }, children, className, ...divProps
}: WorkflowChartNodeTemplateProps) => {
  const { isBlockSelected } = useWorkflowChart()

  return (
    <div
      className={classnames(
        'py-4 px-3 flex flex-col bg-white items-center shadow-lg rounded gap-6 text-black-light border hover:border-sky-blue-light w-64',
        {
          'border-sky-blue-light': isBlockSelected(data.uuid),
          'border-transparent': !isBlockSelected(data.uuid),
        },
        className,
      )}
      style={{ minWidth: NODE_WIDTH, minHeight: NODE_HEIGHT }}
      {...divProps}
    >
      <WorkflowChartNodeHandle id={id} type="target" />
      {children || (
        <div className="flex flex-row gap-3 border-b border-b-ice-blue pb-2 w-full items-center">
          <div className="flex-grow-1 font-bold">
            {data.name}
          </div>
        </div>
      )}
      <WorkflowChartNodeHandle id={id} type="source" />
      <WorkflowChartNodeHandle id={id} type="source" position={Position.Bottom} alias="helper" />
    </div>
  )
}
