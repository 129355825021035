import usePostRequisitionAttachment from './Hooks/usePostRequisitionAttachment'
import MediaUploadButton from '../../../../../../../shared/components/atoms/MediaUploadButton'

const AttachmentUpload: React.FC = () => {
  const { mutate: upload } = usePostRequisitionAttachment()

  return (
    <MediaUploadButton onUpload={upload} />
  )
}

export default AttachmentUpload
