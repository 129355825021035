import { invalidIdStateType } from '../utils/validationUtils'
import SupplierView from './SupplierView'
import ContentPreview from './ContentPreview'

interface IDetails {
  requisitionId?: number
  invalidIds: invalidIdStateType
}

const Details: React.FC<IDetails> = ({ requisitionId, invalidIds }) => (
  requisitionId
    ? <ContentPreview requisitionId={requisitionId} />
    : <SupplierView invalidIds={invalidIds} />)

export default Details
