import { useHistory, useLocation } from 'react-router'
import { useSuppliersProvider } from '../../Suppliers/providers/SuppliersProvider'
import { SupplierBodyOptionsType } from '../../../types/SupplierDetails/SupplierDetailsTypes'

export default (supplierBranchId: number | undefined, page?: SupplierBodyOptionsType) => {
  const { pathname } = useLocation()
  const history = useHistory()

  const {
    scrollState: [, setScrollState],
    origin: [, setOrigin],
  } = useSuppliersProvider()

  if (!supplierBranchId) return () => { }

  return () => {
    const scrollableDiv = document.getElementById('scrollableDiv')
    setScrollState(scrollableDiv?.scrollTop)
    setOrigin(pathname)
    history.push(`/supplier/${supplierBranchId}/${page || 'general'}`)
  }
}
