import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import BasecampNoResults from './NoResults'
import Requisition from './Requisition'
import useSearchRequisitions from './useSearchRequisitions'

const RequisitionsSearch: React.FC = () => {
  const { data: searchedRequisitions, isLoading: isLoadingSearch } = useSearchRequisitions()

  if (isLoadingSearch) {
    return (
      <div className="w-full flex justify-center h-96 items-center">
        <CoreLoadingIcon />
      </div>
    )
  }

  if (!searchedRequisitions?.data?.length && !isLoadingSearch) {
    return <BasecampNoResults />
  }

  return (
    <div className="flex flex-col gap-y-2 pb-4" data-cy="search-table">
      {searchedRequisitions?.data.map((requisition) => (
        <Requisition
          data={requisition}
          key={requisition.id}
        />
      ))}
    </div>
  )
}

export default RequisitionsSearch
