import { useTranslation } from 'react-i18next'
import Titlebar from '../../../../shared/components/layout/Titlebar'
import AddressesList from './AddressList/AddressesList'
import CompanyAddressesNewButton from './CompanyAddressesNewButton'

const CompanyAddresses = () => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col ">
      <Titlebar title={t('generic.address_plural')}>
        <CompanyAddressesNewButton />
      </Titlebar>
      <AddressesList />
    </div>
  )
}

export default CompanyAddresses
