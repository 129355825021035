import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'

export interface ICollapsible {
  title: string
  children?: React.ReactNode
  classNames?: string
  defaultOpen?: boolean,
  extraButtons?: React.ReactNode
}

const Collapsible: FC<ICollapsible> = ({
  title, defaultOpen = true, classNames, children, extraButtons,
}) => (
  <Disclosure defaultOpen={defaultOpen}>
    {({ open }) => (
      <div className="w-full">
        <div className={`
         w-full flex justify-between items-center text-sky-blue-light font-semibold
         border-b-[1px] border-rock-gray-light pb-2 mb-2 ${classNames}`}
        >
          <Disclosure.Button>
            <span>{title}</span>
          </Disclosure.Button>

          <div className="flex flex-row items-end">
            {extraButtons}
            <Disclosure.Button className="py-2">
              <span><ChevronUpIcon className={`text-sky-blue-light hover:text-sky-blue hover:stroke-[3px] transition-all h-5 ${!open ? 'rotate-180' : ' '}`} /></span>
            </Disclosure.Button>
          </div>

        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-y-95 opacity-0"
          enterTo="transform scale-y-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-y-100 opacity-100"
          leaveTo="transform scale-y-95 opacity-0"
        >
          <Disclosure.Panel className="pt-4">
            {children}
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
)

export default Collapsible
