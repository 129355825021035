import { useQueryClient } from 'react-query'
import deleteThumbnail from '../Services/ThumbnailService'
import toastr from '../../../../shared/components/organisms/toastr/toastr'
import useGetFormData from '../../hooks/useGetFormData'
import { UploadedFileType } from '../../../../shared/components/layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import { formsQueryKeys } from '../../constants/formService'

export default () => {
  const { data } = useGetFormData()
  const cache = useQueryClient()

  return async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: UploadedFileType,
  ) => {
    e.stopPropagation()
    if (!data || !file.uuid) return
    await deleteThumbnail(data.__uuid, file?.uuid)
    const QUERY_KEY = formsQueryKeys.apiGetForm(data?.__uuid)
    await cache.invalidateQueries(QUERY_KEY)
    toastr.success('success.item_deleted', QUERY_KEY)
  }
}
