import {
  FC,
  TextareaHTMLAttributes,
} from 'react'
import BaseInputWrapper from './BaseInputWrapper'
import BaseTextareaInput from './BaseTextareaInput'

export interface ITextareaInput extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onBlur'> {
  editable?: boolean | undefined
  loading?: boolean
  required?: boolean | undefined
  label?: string
  onBlur?: (value: string | number | readonly string[] | undefined) => void
  type?: string
}

const TextareaInput: FC<ITextareaInput> = ({
  label, defaultValue, loading, onBlur, editable = true, ...rest
}) => (
  <BaseInputWrapper label={label}>
    {!loading && editable ? <BaseTextareaInput {...rest} defaultValue={defaultValue} onBlur={onBlur} /> : <span className="text-sm p-2">{defaultValue}</span>}
  </BaseInputWrapper>
)

export default TextareaInput
