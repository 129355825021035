import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import './Pagination.scss'

export type PaginationProps = Omit<ReactPaginateProps, 'breakLabel' | 'nextLabel' | 'previousLabel'>

export const Pagination = ({ ...props }: PaginationProps) => (
  <ReactPaginate
    breakLabel="..."
    nextLabel=">"
    previousLabel="<"
    pageRangeDisplayed={3}
    renderOnZeroPageCount={() => null}
    containerClassName="pagination"
    activeClassName="active"
    pageClassName="page-item"
    pageLinkClassName="page-link"
    previousClassName="page-item"
    previousLinkClassName="page-link"
    nextClassName="page-item"
    nextLinkClassName="page-link"
    {...props}
  />

)
