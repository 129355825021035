import useGetActionPanelReqDetailsData from './hooks/useGetActionPanelReqDetailsData'
import DetailsBox from './DetailsBox'

const RequisitionDetails: React.FC = () => {
  const actionPanelReqDetailsData = useGetActionPanelReqDetailsData()

  return (
    <div className="flex flex-col border-b border-gray-200 gap-y-4 py-4 bg-white">
      <div className="flex flex-col gap-y-2 px-4">
        {actionPanelReqDetailsData.map((data) => (
          <DetailsBox
            title={data.title}
            text={data.text}
            shouldHide={data.shouldHide}
            key={data.title}
          />
        ))}
      </div>
    </div>
  )
}

export default RequisitionDetails
