import { useTranslation } from 'react-i18next'
import { getTenant } from '../../../../../core/utils/tenancy'
import GoogleLogo from '../../../assets/google-logo.png'
import OktaLogo from '../../../assets/okta-logo.png'
import AzureLogo from '../../../assets/azure-logo.svg'
import SSOTypes from '../../../../../types/Authentication/SSOTypes'

export const ssoConfigs = {
  google: {
    icon: <img src={GoogleLogo} alt="Google logo" className="h-full" width={20} />,
    href: `${process.env.REACT_APP_BASEURL}/${getTenant()}/login/google?company=1`,
    text: 'login.sign_in_with_google',
    backgroundColor: 'white',
    className: '',
  },
  okta: {
    icon: <img src={OktaLogo} alt="Okta logo" className="h-full" width={20} />,
    href: `${process.env.REACT_APP_BASEURL}/${getTenant()}/login/okta?company=1`,
    text: 'login.okta_login',
    backgroundColor: 'white',
  },
  azure: {
    icon: <img src={AzureLogo} alt="Azure logo" className="h-full" width={20} />,
    href: `${process.env.REACT_APP_BASEURL}/${getTenant()}/login/azure?company=1`,
    text: 'login.azure_login',
    backgroundColor: 'white',
  },
}

const SSOButton = ({ provider }: { provider: SSOTypes }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full flex justify-center items-center mb-4">
      <a
        data-cy="sso-login-button"
        className={`flex  border-gray-300  w-full border border-gray rounded-md p-3 text-sm font-medium ${ssoConfigs[provider]?.href || ''}`}
        style={{ backgroundColor: ssoConfigs[provider]?.backgroundColor || 'white' }}
        href={ssoConfigs[provider]?.href || ''}
      >
        <span className="mr-5">{ssoConfigs[provider]?.icon || ''}</span>
        <span>{t(ssoConfigs[provider]?.text || 'SSO Login')}</span>
      </a>
    </div>
  )
}

export default SSOButton
