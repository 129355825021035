import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { apiGetFormBuilder, formsQueryKeys } from '../../constants/formService'
import useGetInputTypes from '../../hooks/useGetInputTypes'
import useGetInputCategories from '../../hooks/useGetInputCategories'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'

export default (isTestForm = false) => {
  const { data: inputs, basicTypes, isLoading: inputsLoading } = useGetInputTypes()
  const { defaultCategory, isLoading: categoriesLoading } = useGetInputCategories()

  const builderQuery = useQuery(formsQueryKeys.apiGetFormBuilder(), () => apiGetFormBuilder(isTestForm))

  return useMemo(() => ({
    ...builderQuery,
    isLoading: builderQuery.isLoading || inputsLoading || categoriesLoading,
    data: builderQuery.data && inputs && defaultCategory && getMergedDeepOverwritingArrays(builderQuery.data, {
      sections: builderQuery.data?.sections?.map((section) => ({
        ...section,
        fields: section.fields.map((field) => (
          getMergedDeepOverwritingArrays(field, {
            validation: {
              required: { value: ['prod-quantity', 'prod-name', 'prod-description'].includes(field.key || '') },
            },
          }))),
      })),
    }),
  }), [inputs, basicTypes, defaultCategory, builderQuery, inputsLoading, categoriesLoading])
}
