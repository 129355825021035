import { useTranslation } from 'react-i18next'
import { MdStickyNote2 } from 'react-icons/md'
import { MessageChatPreviewType } from '../../../../../types/Messenger/types'

interface IChatInfo {
  preview: MessageChatPreviewType
}

const ChatInfo: React.FC<IChatInfo> = ({
  preview,
}) => {
  const { t } = useTranslation()

  const isNotes = preview.model === 'requisition'

  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center gap-2 text-sm text-left w-full pr-2">
        {isNotes && <MdStickyNote2 className="text-lhotse-orange" />}
        <p className=" text-xs text-left line-clamp-2 font-light">
          {t(preview.title)}
        </p>
      </div>
    </div>
  )
}

export default ChatInfo
