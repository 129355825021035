import { useTranslation } from 'react-i18next'
import ContainerRoundedGray from '../../../../../../../../../../shared/components/atoms/containers/ContainerRoundedGray'
import CatalogTieredShippingTable from './CatalogTieredShipping/CatalogTieredShippingTable'

interface ICatalogTieredShipping {
  catalogId: string
}
const CatalogTieredShipping: React.FC<ICatalogTieredShipping> = ({ catalogId }) => {
  const { t } = useTranslation()
  return (
    <ContainerRoundedGray>
      <div>{t('requisition_offers.list_offer_shipping_costs')}</div>
      <CatalogTieredShippingTable catalogId={catalogId} />
    </ContainerRoundedGray>
  )
}

export default CatalogTieredShipping
