import { useTranslation } from 'react-i18next'
import { MdOutlineAddComment } from 'react-icons/md'
import { LineItemItemAttributeType, LineItemOptionType, LineItemType } from '../../../../../../../types/shared/LineItemDataTypes'
import Tooltip from '../../../../../../../shared/components/organisms/tooltip/Tooltip'
import useOfferIsDisabled from '../../../../../hooks/useOfferIsDisabled'
import useToggleComment from '../../../../../hooks/useToggleComment'

interface IComment {
  initialLineItem: LineItemType | undefined
  sectionIndex: number
  row: LineItemItemAttributeType
  option: LineItemOptionType
}

const Comment: React.FC<IComment> = ({
  initialLineItem, sectionIndex, row, option,
}) => {
  const { t } = useTranslation()

  const handleToggleComment = useToggleComment(initialLineItem, sectionIndex, row, option.id)

  const isDisabled = useOfferIsDisabled()

  return (
    <Tooltip
      tooltip={t('generic.add_a_comment')}
      showCondition={!isDisabled}
      className="flex items-center justify-center h-full "
    >
      <button
        disabled={isDisabled}
        data-cy="add-comment-button"
        onClick={handleToggleComment}
        type="button"
      >
        <MdOutlineAddComment
          className={` min-h-[20px] min-w-[20px] ${initialLineItem?.comment ? 'text-sky-blue' : 'text-gray-400'}`}
        />
      </button>
    </Tooltip>
  )
}

export default Comment
