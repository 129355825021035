import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import Table from '../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import DocumentsListRow from './DocumentsListRow'
import useGetCustomerDocuments from './hooks/useGetCustomerDocuments'

const DocumentsList = () => {
  const { t } = useTranslation()

  const { data: documents, isLoading } = useGetCustomerDocuments()

  if (isLoading) return <CoreLoadingIcon />

  return (
    <TableContainer>
      <TableHead>
        <TableHeader>{t('generic.name')}</TableHeader>
        <TableHeader>{t('generic.type')}</TableHeader>
        <TableHeader>{t('generic.last_updated')}</TableHeader>
      </TableHead>
      <Table>
        {documents?.map((document: CustomerDocumentType) => (
          <DocumentsListRow
            document={document}
            key={`${document.id}-documentId`}
          />
        ))}
      </Table>
    </TableContainer>
  )
}

export default DocumentsList
