import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import ExpandedInfo from './ExpandedInfo'
import HeaderTags from './HeaderTags/HeaderTags'
import OpenMessengerButton from './OpenMessengerButton'
import BranchName from './BranchName'
import SupplierName from './SupplierName'
import { getIsWaiting } from './utils'

interface ISupplierHeader {
  invite: RequisitionInviteType
  useLastCallState: [
    number[] | undefined,
    React.Dispatch<React.SetStateAction<number[] | undefined>>
  ]
  isPurchased: boolean,
  isFullyVisible: boolean,
}

const SupplierHeader: React.FC<ISupplierHeader> = ({
  invite, useLastCallState, isPurchased, isFullyVisible,
}) => {
  const { t } = useTranslation()
  const isWaiting = getIsWaiting(invite)

  return (
    <div
      className={`p-5 w-72 gap-y-2 bg-white border-gray-200 border shadow-sm rounded-lg flex flex-1 flex-col relative transition-opacity ${isWaiting && 'text-gray-400'} ${!isFullyVisible && 'opacity-40 pointer-events-none'}`}
      data-cy="supplier-header"
    >
      <div className="flex flex-col gap-y-1">
        <div className="text-left text-sm text-rock-gray">{t('generic.from', 'From')}</div>
        <div className="flex items-center justify-between gap-x-2">
          <BranchName invite={invite} />
          <OpenMessengerButton invite={invite} />
        </div>
        <SupplierName invite={invite} />
        <HeaderTags invite={invite} />
      </div>
      <ExpandedInfo
        invite={invite}
        pricesExpanded
        useLastCallState={useLastCallState}
        isPurchased={isPurchased}
      />
    </div>
  )
}

export default SupplierHeader
