import CoreContainerLayout from '../../../../../core/components/layout/CoreContainerLayout'
import RowCell from '../../../../../shared/components/layout/RowCell'
import SupplierCountryBadge from '../../../../../shared/components/organisms/SupplierInfo/SupplierCountryBadge'
import useOpenSupplierDetailsPage from '../../../../SupplierDetails/hooks/useOpenSupplierDetailsPage'
import SupplierSearchType from '../../../../../types/Suppliers/SupplierSearchType'
import BranchInfoCell from './BranchInfoCell'
import SupplierCategoryCell from './SupplierCategoryCell'

export interface ISupplierTableRow {
  data: SupplierSearchType
}

const SupplierTableRow: React.FC<ISupplierTableRow> = ({ data }) => {
  const openDetailsModal = useOpenSupplierDetailsPage(data.id)

  return (
    <div
      role="button"
      aria-hidden
      className="text-left w-full 2xl:w-[1300px]"
      onClick={openDetailsModal}
      data-cy="supplier-table-row"
    >
      <CoreContainerLayout className="flex cursor-pointer py-4">
        <BranchInfoCell data={data} />
        <SupplierCategoryCell data={data} />
        <RowCell width="w-2/12">
          <SupplierCountryBadge country={data.countries[0]} />
        </RowCell>
      </CoreContainerLayout>
    </div>
  )
}

export default SupplierTableRow
