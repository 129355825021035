import SSOTypes from '../../../../../types/Authentication/SSOTypes'
import SSOButton from './SSOButton'

interface ILoginContainerSSOProviders {
  ssoProviders: SSOTypes & 'email'[]
}

const LoginContainerSSOProviders: React.FC<ILoginContainerSSOProviders> = ({ ssoProviders }) => (
  <div className="gap-y-4 flex flex-col mt-4">
    {ssoProviders.map((s) => (s === 'email' ? null : <SSOButton key={s} provider={s} />))}
  </div>
)
export default LoginContainerSSOProviders
