import uuid from 'react-uuid'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { insertAt } from '../../../../shared/utils/arrayHelpers'
import {
  FormQuestionType, FormQuestionValue,
  FormQuestionValueType,
  FormSectionType, FormTemplateType,
} from '../../../../types/Forms'
import useGetFormData from '../../hooks/useGetFormData'
import { cloneQuestion, cloneSection } from './helpers'
import useQuestionValues from './useQuestionValues'
import useUpdateSubmission from './useUpdateSubmission'

export type LoopSectionFieldValues = {
  [key: string]: Omit<FormQuestionValue, '__uuid'>
}

export default (question: FormQuestionType) => {
  const { submission: [submissionData, setSubmission], findSection } = useFormContext()
  const updateSubmission = useUpdateSubmission()
  const { data: formData } = useGetFormData()

  const [, setQuestionValues] = useQuestionValues(question)

  // get submission section __uuid
  // if it has a parent id use that
  // else use its __uuid
  // load the section from the form data

  const submissionSection = findSection(question.__uuid)

  const activeSectionUUID = submissionSection?.__uuid || submissionSection?.loop.__looped_parent_uuid

  const originalSection = (submissionData || formData?.template)?.sections
    .find((formSection) => formSection.__uuid === activeSectionUUID)

  return (fieldValues?: LoopSectionFieldValues) => {
    if (!submissionData || !originalSection || !submissionSection) return

    const newQuestionValue = [question?.options?.find(({ __uuid, uuid }) => uuid === 'yes' || __uuid === 'yes') || question?.values[0]]
    const { newForm } = setQuestionValues(newQuestionValue)
    const loopNumber = submissionSection.loop.__looped_section_count || 1

    const sectionQuestionUUIDs = submissionSection
      .fields.map(({ __uuid }) => __uuid)

    const getLoopedQuestionUuid = (__uuid: string | undefined | null) => (
      sectionQuestionUUIDs.includes(__uuid || '')
        ? `${__uuid}_${loopNumber}`
        : (__uuid || '')
    )

    const cloningQuestion = (question: FormQuestionType) => cloneQuestion(question, getLoopedQuestionUuid)

    const newSection: FormSectionType = cloneSection(originalSection, loopNumber, cloningQuestion)

    // Add default field values to new section
    if (fieldValues) {
      const newFields = newSection.fields.map((field) => {
        const newField = { ...field }

        if (field.key && fieldValues[field.key]) {
          newField.values = [{ ...fieldValues[field.key], __uuid: uuid() }]
        }

        return newField
      })

      newSection.fields = newFields
    }

    const sectionIndex = newForm?.sections
      .map((formSection) => formSection.__uuid)
      .indexOf(submissionSection.__uuid)

    if (sectionIndex !== undefined) {
      const newSections = insertAt(newForm?.sections || [], sectionIndex + 1, newSection)

      const newFormResponse: FormTemplateType = {
        ...submissionData,
        sections: newSections,
      }

      setSubmission(newFormResponse)
    }

    updateSubmission()
  }
  // take you to next section
}
