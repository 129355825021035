import { useMemo } from 'react'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { useFormLogic } from '../providers/FormLogicProvider'

export const useBackendSubmission = () => {
  const { submission: [submission] } = useFormContext()

  const { allProcessableQuestions } = useFormLogic()

  return useMemo(() => ({
    ...submission,
    __visible_field_uuids: allProcessableQuestions.map(({ __uuid }) => __uuid),
  }), [submission, allProcessableQuestions])
}

export default useBackendSubmission
