import { MdSend } from 'react-icons/md'
import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import useGetToken from '../../../../SupplierOffer/hooks/useGetToken'
import useGetAndSetActiveMessage from '../../../hooks/useGetAndSetActiveMessage'
import useGetMessengerUrlParams from '../../../hooks/useGetMessengerUrlParams'
import useSendMessage from '../../../hooks/useSendMessage'

const SendMessageButton: React.FC = () => {
  const { mutate: handleSendMessage } = useSendMessage()
  const [messageContent] = useGetAndSetActiveMessage()
  const { messageProperties: [{ files }] } = useMessengerContext()
  const { modelId } = useGetMessengerUrlParams()
  const token = useGetToken()

  const isSendDisabled = (!modelId && !token) || (!files.length && !messageContent)

  return (
    <div className="flex flex-col justify-between  space-y-2 p-3">
      <button
        type="button"
        className="px-2 items-center text-gray-400 justify-center align-middle flex rounded-full h-10 w-10"
        onClick={() => handleSendMessage()}
        disabled={isSendDisabled}
        data-cy="chat-send"
      >
        <MdSend className="text-2xl" />
      </button>
    </div>
  )
}

export default SendMessageButton
