import { FC, HTMLAttributes, ReactNode } from 'react'
import classnames from 'classnames'

export interface ICardTable {
  columns: ReactNode[],
  children?: ReactNode,
  headerClassNames?: HTMLAttributes<HTMLTableHeaderCellElement>['className'],
}

const CardTable: FC<ICardTable> = ({ columns, children, headerClassNames }) => (
  <div className="flow-root">
    <div className="overflow-visible">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="shadow rounded">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-white">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column?.toString()}
                    scope="col"
                    className={classnames(
                      'px-3 py-3.5 text-left text-sm font-semibold text-mountain-gray',
                      headerClassNames,
                    )}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {children}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export default CardTable
