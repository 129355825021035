import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import { MessageChatPreviewType } from '../../../types/Messenger/types'
import useGetChats from './useGetChats'

export default () => {
  const { t } = useTranslation()
  const requisitionId = useGetRequisitionId()

  const { data: chats, isLoading } = useGetChats()

  const chatsPreviews: MessageChatPreviewType[] | undefined = chats?.map((chat) => ({
    modelId: chat.supplier_invite_id || requisitionId,
    title: chat.supplier_branch_name || t('sherpa.internal_notes'),
    last_message: chat?.last_message || '',
    timestamp: chat?.created_at ? dayjs(chat?.created_at).fromNow() : undefined,
    created_at: chat?.created_at,
    model: chat.model_type,
  }))
    .sort((a, b) => {
      if (a.model === 'requisition' || b.model === 'requisition') return 0
      if (a.created_at && b.created_at) {
        return (dayjs(b.created_at)).diff(a.created_at)
      }
      return -1
    })

  return { data: chatsPreviews, isLoading }
}
