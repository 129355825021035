// REFACTOR
import {
  LineItemItemAttributeType, LineItemType, PostOrPutLineItemBodyType,
} from '../../../types/shared/LineItemDataTypes'
import { OfferDataType } from '../../../types/shared/OfferDataTypes'
import {
  postLineItem, putLineItem,
} from '../services/services'

export const multiLineItemAttributeTypes = ['price', 'multi-select', 'options']

export const checkIfInputDisabled = (
  offer: OfferDataType | undefined,
  supplierOfferEditable: boolean,
) => (
  ['OFFER_DECLINED_BY_CUSTOMER', 'OFFER_ACCEPTED', 'PURCHASED', 'CLOSED'].includes(offer?.status || '')
  || (offer?.requisition?.status === 'LAST_CALL_NEGOTIATION' && offer?.status === 'OFFER_SUBMITTED'))
  && !supplierOfferEditable

export const putOrPostLineItem = ({ lineItemId, ...attributes }: PostOrPutLineItemBodyType) => (
  lineItemId
    ? putLineItem({
      lineItemId, ...attributes,
    })
    : postLineItem({
      ...attributes,
    }))

export const findLineItem = (
  currentRow: LineItemItemAttributeType,
  optionId: number,
) => currentRow.line_items?.find(
  (lineItem) => lineItem?.item_attribute_option_id === optionId,
) as LineItemType

export const getLineItemFromRow = (row: LineItemItemAttributeType | undefined) => (
  row?.line_items?.length ? row.line_items[0] : false
)
