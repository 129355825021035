import dayjs from 'dayjs'
import i18n from '../../../i18n'
import LANGUAGES from '../../../shared/constants/languages'

const useSwitchLanguage = () => (newLanguage?: 'de' | 'en') => {
  const i18nLanguage = i18n.language === LANGUAGES.GERMAN ? LANGUAGES.ENGLISH : LANGUAGES.GERMAN
  const language = newLanguage || i18nLanguage
  dayjs.locale(language)
  localStorage.setItem(
    'language',
    language,
  )
  i18n.changeLanguage(language)
}

export default useSwitchLanguage
