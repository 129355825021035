import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../../core/providers/UtilsProvider'
import { RequisitionInviteType } from '../../../../../../../../types/shared/RequisitionDataTypes'
import { OfferActionType } from '../../../../constants/OfferViewTypes'
import useSetOfferStatus from '../../hooks/useSetOfferStatus'
import ActionModal from '../../../NegotationModals/ActionModal/ActionModal'

const useOpenActionModal = (
  invite: RequisitionInviteType,
) => {
  const utils = useUtils()

  const setStatus = useSetOfferStatus(invite)
  const { t } = useTranslation()

  const titles = {
    accept: t('req_award.accept_offer'),
    changes: t('req_award.request_changes'),
    decline: t('requistition_suppliers.decline'),
  }

  return (
    type: OfferActionType,
  ) => {
    utils.modal.set({
      isOpen: true,
      title: titles[type],
      content: <ActionModal
        type={type}
        invite={invite}
        setStatus={setStatus}
      />,
    })
  }
}

export default useOpenActionModal
