import { useQueryClient } from 'react-query'
import { useFormContext } from '../../../core/providers/FormProvider'
import { useIntakeFlow } from '../../Intake/providers/IntakeFlowProvider'

export const useRefetchOfferQuery = () => {
  const client = useQueryClient()
  const { submission: [submission, setSubmission] } = useFormContext()
  const intakeFlow = useIntakeFlow()

  if (submission && Object.keys(submission).length > 0) {
    return async (reference?:string) => {
      await client.refetchQueries(['offering-offers', reference])

      // Force Update of the state in order to re-render the product Table
      setSubmission({ ...submission! })
    }
  }
  if (intakeFlow.intake) {
    return () => {
      client.refetchQueries(['intake-offerings', intakeFlow?.intake?.uuid, 'paginated', 1])
    }
  }

  return () => {}
}

export default useRefetchOfferQuery
