import { useTranslation } from 'react-i18next'
import Table from '../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../shared/components/atoms/SharedTable/TableContainer'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import SectionWrapper from '../../../../../shared/components/organisms/SectionWrapper'
import getOfferReference from '../../../hooks/getOfferReference'
import useFormValidation from '../../hooks/useFormValidation'
import useGetQuestionIndex from '../../hooks/useGetQuestionIndex'
import { useFormLogic } from '../../providers/FormLogicProvider'
import { FormSectionEvents } from '../../providers/FormSectionProvider'
import ProductSectionOffers from '../ProductSection/ProductSectionOffers'
import ProductSectionTableHeader from '../ProductSection/ProuctSectionTable/ProductSectionTableHeader'
import ProductSectionTableMainRow from '../ProductSection/ProuctSectionTable/ProductSectionTableMainRow'
import ConfirmationQuestionComponent from './ConfirmationQuestionComponent'
import { EvaluatedFormSectionType } from '../../../../../types/Forms'

interface IConfirmationSectionsProductSection {
  section: EvaluatedFormSectionType
}
const ConfirmationSectionsProductSection: React.FC<IConfirmationSectionsProductSection> = ({ section }) => {
  const { t } = useTranslation()
  const { enabledSections } = useFormLogic()

  const { invalidQuestions } = useFormValidation()
  const getQuestionIndex = useGetQuestionIndex()

  return (
    <SectionWrapper
      title={t('forms.products.heading', 'Products')}
      actions={(
        <div>
          <Button
            className="btn btn-ghost text-sky-blue-light "
            onClick={() => {
              const firstProductSection = enabledSections
                .find((es) => es.name !== 'forms.confirmation_section' && es.type === 'PRODUCT')
              if (!firstProductSection) return
              FormSectionEvents.ProceedTo.dispatch(firstProductSection.__uuid)
            }}
          >
            {t('forms.proceed_to_section', 'Jump to section')}
          </Button>
        </div>
      )}
    >
      <TableContainer border={false}>
        <ProductSectionTableHeader actions={false} />
        <Table>
          {enabledSections.filter((es) => es.name !== 'forms.confirmation_section' && es.type === 'PRODUCT')
            .map((productSection) => (
              <ProductSectionTableMainRow
                key={productSection.__uuid}
                productSection={productSection}
              >
                <TableCell colSpan={4}>
                  <div className="gap-8 grid  py-4">
                    {productSection.enabledFields
                      .filter((question) => !question.isFormElement)
                      .map(
                        (q) => (
                          <ConfirmationQuestionComponent
                            key={q.__uuid}
                            questionIndex={getQuestionIndex(q) + 1}
                            invalidQuestions={invalidQuestions.map(({ __uuid }) => __uuid)}
                            question={q}
                          />
                        ),
                      )}
                  </div>
                  <ProductSectionOffers offerReferenceKey={getOfferReference(productSection.__uuid)} />
                </TableCell>
              </ProductSectionTableMainRow>
            ))}
        </Table>
      </TableContainer>
    </SectionWrapper>

  )
}

export default ConfirmationSectionsProductSection
