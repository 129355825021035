import { ChevronDoubleLeftIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Button from '../../../shared/components/atoms/buttons/Button'
import TabbedNav from '../../../shared/components/layout/TabbedNav/TabbedNav'
import { TabbedNavItemType } from '../../../types/shared/TabbedNavItemType'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'
import AbortRequestButton from './IntakeSidepanel/components/AbortRequestButton'
import useIntakeSidebar from '../hooks/useIntakeSidebar'

type IntakeFlowOverviewPageHeaderProps = {
  tabs: TabbedNavItemType[]
  onTabChange: (tabIndex: number) => void
}

export const IntakeFlowOverviewPageHeader = React.forwardRef<HTMLDivElement, IntakeFlowOverviewPageHeaderProps>(({ tabs, onTabChange }: IntakeFlowOverviewPageHeaderProps, ref) => {
  const { intake } = useIntakeFlow()
  const { t } = useTranslation()
  const history = useHistory()
  const { minified, toggleMinified } = useIntakeSidebar()

  return (
    <>
      <div className="flex  flex-col items-start bg-background z-10 w-full px-12 pt-4" ref={ref}>
        <div className="mb-[10px]">
          <Button className="text-black-light-2 !p-0 text-sm" onClick={() => history.goBack()}>
            <ChevronLeftIcon className="h-[10px]" />
            {t('generic.back', 'Back')}
          </Button>
        </div>
        <div className="flex gap-2 mb-5 min-h-[2.25rem] justify-between w-full">
          <div className="text-2xl font-bold">{intake?.name || intake?.uuid}</div>
          <div className="flex gap-2">
            <AbortRequestButton />
            <div>
              {minified && (
                <Button onClick={toggleMinified} className="bg-white h-full p-2 base-form-input w-fit">
                  <ChevronDoubleLeftIcon className="w-4" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=" sticky top-0 px-10 bg-background w-full z-50">
        <TabbedNav itemClassName="min-w-[180px] flex-auto" items={tabs} onTabChange={onTabChange} />
      </div>
    </>
  )
})

IntakeFlowOverviewPageHeader.displayName = 'IntakeFlowOverviewPageHeader'

export default IntakeFlowOverviewPageHeader
