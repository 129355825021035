import { ChangeEvent } from 'react'
import { LanguageType } from '../../../../types/shared/CoreTypes'
import FormikSelect from '../../../../shared/components/organisms/formik/FormikSelect'
import useUpdateUserLanguage from '../../hooks/useUpdateUserLanguage'

interface ILanguageSelect {
  values: { language: string }
  handleChange: (e: React.ChangeEvent) => void
}

const LanguageSelect: React.FC<ILanguageSelect> = ({
  values, handleChange,
}) => {
  const { mutate: updateLanguage } = useUpdateUserLanguage()

  const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newLanguage = event.target.value as LanguageType
    updateLanguage({ language: newLanguage })
    handleChange(event)
  }

  return (
    <FormikSelect
      name="language"
      handleChange={handleLanguageChange}
      values={values}
      label="generic.language"
      className="w-1/5"
      noPlaceholder
      options={[
        { value: 'en', label: 'generic.english' },
        { value: 'de', label: 'generic.german' },
      ]}
      dataCy="language-selector"
    />
  )
}

export default LanguageSelect
