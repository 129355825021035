import { MediaType } from '../../../../types/shared/MediaTypes'
import useFormMedia from './useFormMedia'

export const useFormFileDownload = () => {
  const media = useFormMedia()

  const download = ({ uuid, file_name }: MediaType) => {
    media?.saveFile(uuid, file_name)
  }

  return download
}

export default useFormFileDownload
