import { FormQuestionSettings, FormQuestionValue, InputTemplate } from '../../../types/Forms'
import uuid from '../../../core/utils/uuid'

export const getDefaultQuestionValues = ({ type, options }: Pick<InputTemplate, 'type' | 'options'>): FormQuestionValue[] => {
  if (type === 'FILE') return []

  if (type === 'KEY_VALUE' && !!options?.length) {
    return options.map(
      ({ value, display_name }) => ({
        __uuid: uuid('form-question-value'), value, label: display_name, predefined: true,
      }),
    )
  }

  return [{ __uuid: uuid('form-question-value'), value: '' }]
}

export const getDefaultLabelSettings = ({ type }: Pick<InputTemplate, 'type'>): FormQuestionSettings['labels'] | undefined => {
  if (type === 'KEY_VALUE') {
    return {
      predefined: true,
      readonly: false,
    }
  }

  if (['SELECT', 'MULTI_SELECT', 'FILE', 'SUPPLIER', 'SEARCH_SELECT'].includes(type)) {
    return undefined
  }

  return {
    predefined: false,
    readonly: false,
  }
}

export const getDefaultOptionsSettings = ({ type }: Pick<InputTemplate, 'type'>): FormQuestionSettings['options'] | undefined => {
  if (type === 'SELECT' || type === 'MULTI_SELECT') {
    return {
      customValues: false,
      other: false,
    }
  }

  return undefined
}

export const getDefaultAnswerSettings = ({ type, key }: Pick<InputTemplate, 'type' | 'key'>): FormQuestionSettings['answers'] | undefined => {
  if (type === 'KEY_VALUE' || type === 'MULTI_SELECT' || (type === 'NUMBER' && key === 'prod-quantity')) {
    return { min: 0, max: undefined }
  }

  return {
    min: 1,
    max: 1,
  }
}

export const getDefaultMiscSettings = ({ type }: Pick<InputTemplate, 'type'>) => ({
  hidden: false,
})
