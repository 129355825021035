import { useEffect } from 'react'
import {
  Redirect, Route, Switch, useParams, useRouteMatch,
} from 'react-router'
import Messenger from '../Messenger/Messenger'
import ExistingRequisitionRoutes from './components/ExistingRequisitionRoutes'
import RequisitionWrapper from './RequisitionWrapper'
import RequisitionAwardPage from './subModules/Award/AwardPage'
import CoordinatePage from './subModules/Coordinate/Coordinate'
import { useCoordinateProvider } from './subModules/Coordinate/providers/CoordinateProvider'
import RequisitionDefinitionPage from './subModules/Definition/RequisitionDefinitionPage'
import SubmissionPage from './subModules/Submission/SubmissionPage'
import RequisitionSapTransferPage from './subModules/SapTransfer/RequisitionSapTransferPage'

// TODO: use Nested routes
const RequisitionModuleRouter = () => {
  const { path } = useRouteMatch()

  const { searchQuery: [, setSearchQuery] } = useCoordinateProvider()

  const { requisitionId } = useParams<{ requisitionId: string }>()

  useEffect(() => {
    setSearchQuery('')
  }, [requisitionId])

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to="/new" />
      </Route>

      <Route path={`${path}/req-:requisitionId`} exact>
        <ExistingRequisitionRoutes />
      </Route>

      <Route path={`${path}/req-:requisitionId/transfer`}>
        <RequisitionWrapper>
          <RequisitionSapTransferPage />
        </RequisitionWrapper>
      </Route>

      <Route path={`${path}/req-:requisitionId/define`}>
        <RequisitionWrapper>
          <RequisitionDefinitionPage />
        </RequisitionWrapper>
      </Route>

      <Route path={`${path}/req-:requisitionId/submission`}>
        <RequisitionWrapper>
          <SubmissionPage />
        </RequisitionWrapper>
      </Route>

      <Route path={`${path}/req-:requisitionId/execute`}>
        <RequisitionWrapper>
          <CoordinatePage />
        </RequisitionWrapper>
      </Route>

      <Route path={`${path}/req-:requisitionId/chat/:modelType?/:modelId?/:messageReferenceId?`}>
        <RequisitionWrapper>
          <Messenger />
        </RequisitionWrapper>
      </Route>

      <Route path={`${path}/req-:requisitionId/award`}>
        <RequisitionWrapper>
          <RequisitionAwardPage />
        </RequisitionWrapper>
      </Route>

      <Route path={path}>
        <Redirect to="/" />
      </Route>

    </Switch>
  )
}

export default RequisitionModuleRouter
