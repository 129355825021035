import { useMutation, useQueryClient } from 'react-query'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'
import { putIntake } from '../services'
import { IntakeType } from '../../../types/Intake'
import useGetIntake from './useGetIntake'

export const useIntakeMutations = () => {
  const { intake } = useIntakeFlow()
  const { refetch } = useGetIntake(intake?.uuid || '')
  const client = useQueryClient()

  const update = useMutation(
    () => putIntake(intake!.uuid, intake!),
    {
      onMutate: async () => {
        await client.cancelQueries(['intake', intake!.uuid])
        const prevIntake = client.getQueryData<IntakeType>(['intake', intake!.uuid])
        if (!intake) return { prevIntake }
        client.setQueryData<IntakeType>(
          ['intake', intake!.uuid],
          intake,
        )
        return { prevIntake }
      },
      onSuccess: () => {
        refetch()
      },

    },
  )

  return { update }
}

export default useIntakeMutations
