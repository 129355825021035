import _ from 'lodash'
import { TagType } from '../../../../types/shared/GeneralTypes'
import SupplierTag from './SupplierTag'
import UnshownTagCount from './UnshownTagCount'

interface ISupplierTags {
  tags: TagType[] | undefined
  supplierBranchId: number
  numTags?: number
}

const SupplierTags: React.FC<ISupplierTags> = ({ tags, supplierBranchId, numTags = 3 }) => {
  const uniqueTags = tags
    ?.filter((value, index, self) => self.indexOf(value) === index)
    ?.filter((tag) => tag.name !== 'preferred')

  if (!uniqueTags?.length) return null

  return (
    <div className="flex items-center gap-3">
      {_(uniqueTags).take(numTags).value().map((tag) => (
        <SupplierTag key={tag.id} tag={tag} />
      ))}
      <UnshownTagCount uniqueTags={uniqueTags} supplierBranchId={supplierBranchId} />
    </div>
  )
}

export default SupplierTags
