import { useTranslation } from 'react-i18next'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'

const WorkflowsTableHeader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableHeader>
        {t('generic.name', 'Name')}
      </TableHeader>
      <TableHeader>
        {t('generic.created_at', 'Created')}
      </TableHeader>
      <TableHeader>
        {t('generic.updated_at', 'Updated')}
      </TableHeader>
      <TableHeader>
        {t('generic.published_at', 'Published')}
      </TableHeader>
      <TableHeader>
        {t('generic.action', 'Action')}
      </TableHeader>
    </TableHead>
  )
}

export default WorkflowsTableHeader
