import { Combobox } from '@headlessui/react'
import { Can } from '../../../providers/AbilityProvider'
import { FilteredActionPanelResultType } from '../../../../types/shared/ActionPanelTypes'
import ActionPanelFoundResult from './ActionPanelFoundResult'

interface IActionPanelResultsFound {
  filteredActions: FilteredActionPanelResultType[],
  isLoading: boolean
}

const ActionPanelResultsFound: React.FC<IActionPanelResultsFound> = (
  { filteredActions, isLoading },
) => (
  <Can I="seeActionPanelActions">
    {!isLoading && (filteredActions.length > 0) && (
      <Combobox.Options static className="max-h-80 space-y-4 overflow-y-auto px-4 pb-2">
        {filteredActions.length > 0 && (
          <li>
            <ul className="-mx-4 text-sm text-gray-700">
              {filteredActions.map((project) => (
                <ActionPanelFoundResult
                  project={project}
                  key={project.id}
                />
              ))}
            </ul>
          </li>
        )}
      </Combobox.Options>
    )}
  </Can>
)

export default ActionPanelResultsFound
