import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'
import IntakeSidePanelActiveDeclinedMessage from './IntakeSidePanelActiveDeclinedMessage'
import IntakeSidePanelActiveStepItem from './IntakeSidePanelActiveStepItem'
import IntakeSidePanelActiveStepRestart from './IntakeSidePanelActiveStepRestart'

const IntakeSidePanelActiveStep = () => {
  const { data } = useGetCurrentWorkflow()

  return (
    <div className="w-full p-6">
      <div className="flex flex-col pt-6 border-t bg-white ">
        {data?.status === 'ongoing' && data?.active_blocks.filter((b) => b.current_user_can_approve).map((block) => (
          <IntakeSidePanelActiveStepItem key={block.uuid} block={block} />
        ))}
        {data?.status === 'declined' && <IntakeSidePanelActiveDeclinedMessage />}
      </div>
      <IntakeSidePanelActiveStepRestart />
    </div>
  )
}

export default IntakeSidePanelActiveStep
