import { XMarkIcon } from '@heroicons/react/24/outline'
import { ReactNode } from 'react'
import { TabbedNavItemType } from '../../../types/shared/TabbedNavItemType'
import TabbedNav from './TabbedNav/TabbedNav'

export type StickySidePanelProps = React.PropsWithChildren<{
  open: boolean
  title?: string
  onToggle?: () => void
  tabs?: TabbedNavItemType[]
  onTabChange?: (tabIndex: number) => void
  header?: ReactNode | undefined
  footer?: ReactNode | undefined
}>

export const StickySidePanel = ({
  open, onToggle, tabs, onTabChange, title, header, children, footer,
}: StickySidePanelProps) => (
  <div className={`flex flex-col max-w-[475px] top-0 z-20 bottom-0 right-0 h-screen  bg-white 
      ${open ? 'sticky w-full' : 'fixed pt-5'}`}
  >
    <div className={`flex flex-col bg-white min-h-screen  w-full   ${open ? 'h-full' : 'rounded-tl-md rounded-bl-md'} pt-7`}>
      {open && (
        <div className="flex flex-col shadow-lg min-h-screen">
          <div className="flex flex-row mt-4 mb-6 px-6 justify-between items-center">
            {title && <h4 className="font-semibold text-lg text-sky-blue-light py-1 capitalize">{title}</h4>}
            <div className="flex space-x-3">
              {header}
              {onToggle && (
                <button type="button" onClick={onToggle}>
                  <XMarkIcon
                    className={`h-5 w-5 text-black-light-2 hover:text-sky-blue-light hover:cursor-pointer
              ${!open ? 'rotate-180' : 'rotate-0'} duration-300 transition-transform`}
                  />
                </button>
              )}
            </div>
          </div>
          {tabs && onTabChange && <TabbedNav items={tabs} onTabChange={onTabChange} />}
          <div className="flex flex-grow h-full mt-11 min-h-0 px-6 overflow-y-auto">
            {children}
          </div>
          <div className="mb-11">
            {footer}
          </div>
        </div>
      )}
    </div>
  </div>
)
