import {
  FC,
} from 'react'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelect, { ISearchSelect } from '../SearchSelect/SearchSelect'
import BaseInput from './BaseInput'
import BaseInputWrapper from './BaseInputWrapper'

export interface IInputWithDropDown extends ISearchSelect {
  required?: boolean | undefined
  label?: string
  currentSelection?: InputSearchSelectItemType
  options: InputSearchSelectItemType[]
  defaultValue?: string
  isLoading?: boolean
  disabled?: boolean
  onBlur: (value: string | number | readonly string[] | undefined) => void
}

const InputWithDropDown: FC<IInputWithDropDown> = ({
  label, options, onChange, onBlur, isLoading, defaultValue, currentSelection, disabled, ...rest
}) => {
  if (isLoading) return <div>Loading...</div>

  return (
    <BaseInputWrapper label={label}>
      <div className="relative mt-1 rounded-md flex items-center space-x-2">
        <SearchSelect
          disabled={disabled}
          className="text-sm border-none rounded-r-none
           bg-sky-blue-light disabled:bg-rock-gray-light disabled:text-black text-white"
          options={options}
          onChange={onChange}
          currentSelection={currentSelection}
        />

        <BaseInput
          type="text"
          {...rest}
          disabled={disabled}
          onBlur={onBlur}
          defaultValue={defaultValue}
          className="rounded-l-none flex-grow border "
        />
      </div>
    </BaseInputWrapper>
  )
}

export default InputWithDropDown
