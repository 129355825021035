import { WorkflowBlockTrigger, WorkflowBlockTriggerComparisonOperator } from '../../../../../../../types/Workflow/WorkflowBlock'
import useWorkflowBlockDraftMutations from '../../../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflow } from '../../../../../providers/WorkflowProvider'
import SearchSelect from '../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionContentType, InputTemplate } from '../../../../../../../types/Forms'

const comparisonMapping: Partial<Record<FormQuestionContentType, WorkflowBlockTrigger['comparison_operator'][]>> = {
  TEXT: ['EQUAL', 'NON_EQUAL', 'STARTS_WITH', 'ENDS_WITH'],
  NUMBER: ['EQUAL', 'NON_EQUAL', 'BIGGER_THAN', 'LOWER_THAN', 'EQUAL_LOWER_THAN', 'EQUAL_BIGGER_THAN'],
  PRICE: ['EQUAL', 'NON_EQUAL', 'BIGGER_THAN', 'LOWER_THAN', 'EQUAL_LOWER_THAN', 'EQUAL_BIGGER_THAN'],
  DATE: ['EQUAL', 'NON_EQUAL', 'BIGGER_THAN', 'LOWER_THAN', 'EQUAL_LOWER_THAN', 'EQUAL_BIGGER_THAN'],
}

const getComparisonOperators = (type: FormQuestionContentType) => comparisonMapping[type] || ['EQUAL', 'NON_EQUAL', 'STARTS_WITH', 'ENDS_WITH']

export const assureOperatorIsValid = (input: InputTemplate, currentOperator: WorkflowBlockTriggerComparisonOperator) => {
  const validOperators = getComparisonOperators(input.type)
  if (!validOperators.includes(currentOperator)) {
    return validOperators[0]
  }

  return currentOperator
}

export const TriggerComparisonSelector = ({ trigger }: { trigger: WorkflowBlockTrigger }) => {
  const { updateTrigger } = useWorkflowBlockDraftMutations()
  const { readonly } = useWorkflow()

  return (
    <SearchSelect
      options={getComparisonOperators(trigger.input.type).map((co) => ({ label: co, id: co }))}
      onChange={(item) => {
        updateTrigger({ ...trigger, comparison_operator: item.id as WorkflowBlockTrigger['comparison_operator'] })
      }}
      currentSelection={{ label: trigger.comparison_operator, id: trigger.comparison_operator }}
      disabled={readonly}
    />

  )
}

export default TriggerComparisonSelector
