import {
  InputSearchSelectItemType,
} from '../../../../../../../../../types/shared/InputSearchSelectTypes'

interface ILogicComparatorTextInput {
  value: string
  onChange: (value: InputSearchSelectItemType) => void
}

const LogicComparatorTextInput: React.FC<ILogicComparatorTextInput> = ({
  value, onChange,
}) => (
  <input
    className="w-full text-sm rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm max-w-[208px]"
    onBlur={(e) => onChange({ id: e.currentTarget.value, label: e.currentTarget.value })}
    placeholder="Input condition..."
    defaultValue={value}
  />
)

export default LogicComparatorTextInput
