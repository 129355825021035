import useGetMessages from '../../../hooks/useGetMessages'
import SendMessageButton from './SendMessageButton'
import TextInput from './TextInput'

const MessageInput: React.FC = () => {
  const { data: messages } = useGetMessages()

  if (!messages) return null

  return (
    <div
      id="chatMessageBox"
      className="flex  justify-between  rounded-br-lg w-full p-2 h-fit  cursor-auto"
    >
      <TextInput />
      <SendMessageButton />
    </div>
  )
}

export default MessageInput
