import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useGetInitialValues from '../hooks/useGetInitialValues'
import useSubmitUserData from '../hooks/useSubmitUserData'
import validationSchema from '../utils/validationSchema'
import FormikPasswordInput from './FormikPasswordInput/FormikPasswordInput'
import JoinFormSubmitButton from './JoinFormSubmitButton'
import JoinFormTextInput from './JoinFormTextInput'

const JoinForm = () => {
  const { t } = useTranslation()
  const { mutate: submitUserData } = useSubmitUserData()
  const initialValues = useGetInitialValues()

  const [showErrors, setShowErrors] = useState(false)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(e) => submitUserData(e)}
      validateOnBlur={false}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} className="flex flex-col gap-y-4">
          <JoinFormTextInput
            constants={props}
            name="email"
            label="Email"
            placeholder="Email"
          />
          <JoinFormTextInput
            constants={props}
            name="first_name"
            label={t('join.enter_first_name')}
            placeholder="Jane"
          />
          <JoinFormTextInput
            constants={props}
            name="last_name"
            label={t('join.enter_last_name')}
            placeholder="Müller"
          />
          <FormikPasswordInput constants={props} showErrors={showErrors} />
          <div className="flex pt-5 align-middle">
            <JoinFormSubmitButton password={props.values.password} setShowErrors={setShowErrors} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default JoinForm
