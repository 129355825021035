import { getUpdatedByUuid } from '../../../../shared/utils/arrayHelpers'
import { FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import useQuestionErrorMessage from '../hooks/useQuestionErrorMessage'
import useQuestionValues from '../hooks/useQuestionValues'
import FormInput from './atoms/FormInput'
import KeyValueQuestion from './atoms/KeyValueQuestion'
import { FormEvents } from '../Questions'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'

interface ILongQuestion {
  question: FormQuestionType
}

const LongQuestion: React.FC<ILongQuestion> = ({
  question,
}) => {
  const [questionValues, setQuestionValues] = useQuestionValues(question)
  const error = useQuestionErrorMessage(question)
  const isDisabled = useIsQuestionDisabled(question)

  const handleOnChange = (answer: FormQuestionValue, value: string) => {
    setQuestionValues(getUpdatedByUuid(questionValues, [
      {
        __uuid: answer.__uuid,
        value,
      },
    ]))
  }

  const handleComplete = () => {
    FormEvents.QuestionAnswered.dispatch(question.__uuid)
  }

  return (
    <KeyValueQuestion question={question}>
      {(answer) => (
        <FormInput
          wrapperClassName="w-full"
          shortResponse={question.type !== 'TEXT_AREA'}
          characterLimit={question.validation.max_characters?.value}
          value={answer.value as string || ''}
          onComplete={handleComplete}
          onChange={(value) => handleOnChange(answer, value)}
          invalid={!!error}
          disabled={isDisabled}
        />
      )}
    </KeyValueQuestion>
  )
}

export default LongQuestion
