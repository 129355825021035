import { useTranslation } from 'react-i18next'
import { isSupported } from '../../constants/FormVersion'
import useGetFormSubmissionData from '../hooks/useCheckForSubmission'
import ActionButton from './ActionButton'
import useGetFormSubmission from '../hooks/useGetFormSubmission'
import { getLocalInfo } from '../../../../core/services/userService'

const AdvarioHeader = () => {
  const { data: formData } = useGetFormSubmissionData()
  const { data: submission } = useGetFormSubmission()
  const userData = getLocalInfo()
  const { t } = useTranslation()
  const isActionButtonHidden = userData && !!submission?.assignments?.[0] && !['super_admin', 'purchaser'].includes(userData?.role)

  return (
    <div className="px-4 py-3 flex justify-between text-sm items-center border-b max-h-[65px] bg-background sticky top-0 z-50">
      <div className="flex h-fit">
        <p className="border-r-2 pr-2.5 text-nds-secondary-gray">
          {t('generic.forms_plural')}
        </p>
        <p className="pl-2.5 font-medium text-nds-primary-gray">{formData?.name || ''}</p>
      </div>
      <div className="flex gap-x-4 items-center">
        {isSupported(formData) && !isActionButtonHidden && <ActionButton />}
      </div>
    </div>
  )
}

export default AdvarioHeader
