import { FC } from 'react'
import classnames from 'classnames'
import { TabbedNavItemType } from '../../../../types/shared/TabbedNavItemType'

interface ITabbedNavItem {
  item: TabbedNavItemType,
  onTabChange: (tabTitle: number) => void
  className?: string
}

const TabbedNavItem: FC<ITabbedNavItem> = ({ className, item, onTabChange }) => (
  <button
    className={classnames(
      'flex-1 py-4 px-1 text-center border-b-2 font-medium text-sm transition-colors max-w-1/3 -mb-[2px]',
      className,
      {
        'border-sky-blue-light text-sky-blue-light hover:text-sky-blue hover:border-sky-blue': item.current,
        'border-transparent text-black-light-2 border-b-rock-gray-light hover:text-mountain-gray hover:border-black-light-2': !item.current,
      },
    )}
    aria-current={item.current ? 'page' : undefined}
    onClick={() => onTabChange(item.tabIndex)}
    type="button"
  >
    {item.title}
  </button>
)

export default TabbedNavItem
