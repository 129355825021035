import { useHistory } from 'react-router'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import { MessageModelType } from '../../../types/Messenger/types'
import useGetMessengerUrlParams from './useGetMessengerUrlParams'

const useSetSupplierInviteParams = (
  modelId: string | number,
  modelType: MessageModelType,
) => {
  const history = useHistory()
  const requisitionId = useGetRequisitionId()

  const { messageReferenceId, modelId: urlModelId } = useGetMessengerUrlParams()

  const baseUrl = `/requisition/req-${requisitionId}/chat/${modelType}/${modelId}`

  const extendedUrl = `${baseUrl}${messageReferenceId ? `/${messageReferenceId}` : ''}`

  return (click?: boolean) => {
    if (!modelType) return

    if (click || modelId !== urlModelId) {
      history.push(baseUrl)
    } else {
      history.push(extendedUrl)
    }
  }
}

export default useSetSupplierInviteParams
