import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'
import SharedAutoSave from '../../../../shared/components/organisms/AutoSave/AutoSave'
import useAutoSave from '../../../Requisition/utils/useAutoSave'
import useGetFinalOfferFileItem from '../../hooks/useGetFinalOfferFileItem'
import { invalidIdStateType } from '../../utils/validationUtils'
import CommentInput from './CommentInput'
import FinalOfferDocumentUploadAndCommentButton from './UploadAndCommentButtons'

interface IFinalOfferDocumentsSection {
  invalidIds: invalidIdStateType
}

const FinalOfferDocumentsSection: React.FC<IFinalOfferDocumentsSection> = ({
  invalidIds,
}) => {
  const { t } = useTranslation()

  const [finalOfferAutoSave, updateFinalOfferAutoSave] = useAutoSave()

  const finalOfferItem = useGetFinalOfferFileItem()

  return finalOfferItem
    ? (
      <div className="border border-gray-200 bg-white rounded-md">
        <div className="flex justify-between border-b border-gray-200 p-5 w-full text-sm">
          <div className="flex gap-x-3 items-center">
            <p className="text-base">{t('supplier_offer.final_offer_documents')}</p>
            {!!finalOfferItem.is_required && <MdError data-cy="supplier-offer-pricing-required-icon" className="flex text-orange-600 items-center" />}
          </div>
          <SharedAutoSave autoSave={finalOfferAutoSave} />
        </div>
        <div>
          <FinalOfferDocumentUploadAndCommentButton
            updateFinalOfferAutoSave={updateFinalOfferAutoSave}
            invalidIds={invalidIds}
          />
          <CommentInput updateAutoSave={updateFinalOfferAutoSave} />
        </div>
      </div>
    )
    : null
}

export default FinalOfferDocumentsSection
