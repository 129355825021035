import { Dispatch, SetStateAction } from 'react'
import { matchPath } from 'react-router'

const useGetRequisitionActions = (
  setStatus: Dispatch<SetStateAction<any>>,
) => {
  const pathData = matchPath<{ requisitionId: string }>(window.location.pathname, { path: '/requisition/req-:requisitionId' })

  return [
    {
      id: 1, name: `Set Status CLOSED (Req-${pathData?.params?.requisitionId})`, category: 'SetStatus', url: '#', func: () => setStatus({ status: 'CLOSED' }),
    },
  ]
}

export default useGetRequisitionActions
