import { useFormContext } from '../../../../../core/providers/FormProvider'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'
import QuestionChoices from '../QuestionChoices/QuestionChoices'

const SearchSelectQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'SEARCH_SELECT') return null

  return (
    <>
      <TextAndSubtitleInput />
      {question.searchType === 'CUSTOM' && <QuestionChoices /> }
    </>
  )
}

export default SearchSelectQuestion
