import { MdArrowRightAlt } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

interface IQuestionNavigatorRow {
  title: string
}

const QuestionNavigatorRow: React.FC<IQuestionNavigatorRow> = ({ title }) => {
  const { t } = useTranslation()

  return (
    <button
      type="button"
      className="flex gap-x-2 justify-between hover:bg-vibrant-blue hover:bg-opacity-20 px-3 py-2 rounded-md"
    >
      <p className="w-fit">1.</p>
      <div className="flex flex-col items-start w-full">
        <p>{title}</p>
        <p className="text-nds-secondary-gray text-sm">{t('forms.answer')}</p>
      </div>
      <MdArrowRightAlt className="text-nds-secondary-gray w-fit" />
    </button>
  )
}

export default QuestionNavigatorRow
