import { useAbility } from '@casl/react'
import { useTranslation } from 'react-i18next'
import { ABILITIES } from '../../../../config/ability'
import { AbilityContext } from '../../../../core/providers/AbilityProvider'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'
import PageTopbar from '../../../../shared/components/layout/PageTopBar'
import SupplierNewModal from '../SupplierNewModal'

const SupplierTopbar = () => {
  const ability = useAbility(AbilityContext)

  const { modal } = useUtils()
  const { t } = useTranslation()
  const handlerDisplayModal = () => {
    modal.set({
      title: t('suppliers.add_supplier'),
      content: <SupplierNewModal />,
      isLoading: false,
      isOpen: true,
    })
  }

  return (
    <PageTopbar
      title={t('suppliers.headline')}
      actions={
        (
          <div>
            {ability.can(ABILITIES.createSupplier)
              ? (
                <AddButton onClick={handlerDisplayModal} dataCy="add-supplier-data-button">
                  {t('suppliers.add_supplier')}
                </AddButton>
              ) : null}
          </div>
        )
      }
    />
  )
}

export default SupplierTopbar
