import { FC } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import { FormSubmissionType } from '../../../../types/Forms'
import Menu from '../../../../shared/components/organisms/menus/Menu'
import useDuplicateSubmission from '../../../Requisition/components/layout/ReqTopbar/hooks/useDuplicateSubmission'
import useDeleteSubmission from '../../../Basecamp/components/BasecampTable/Submissions/hooks/useDeleteSubmission'

interface ILhotseHomeTableRow {
  submission: FormSubmissionType
}
const LhotseHomeTableRow: FC<ILhotseHomeTableRow> = ({ submission }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { mutate: duplicateSubmission } = useDuplicateSubmission()
  const { mutate } = useDeleteSubmission()

  const handleRowClick = () => {
    let url = `/forms/${submission.form?.uuid}/submission/${submission.uuid}`

    if (submission.intake_flow_uuid) {
      url = `/intake-flow/${submission.intake_flow_uuid}`
    }

    history.push(url)
  }

  const submissionName = submission?.active_workflow?.entity.name || submission?.fields?.find(
    (f) => f.key === 'intake-name',
  )?.value?.[0]?.value as string || submission?.form?.name || '-'

  // Handle translations of workflow status
  let statusString = t('generic.draft', 'Draft')
  if (submission.intake_flow_uuid) {
    switch (submission?.active_workflow?.status) {
      case 'ongoing':
        statusString = t('intake.status_ongoing', 'Ongoing')
        break
      case 'declined':
        statusString = t('intake.status_declined', 'Declined')
        break
      case 'declined_by_requester':
        statusString = t('intake.status_declined_by_requester', 'Declined by requester')
        break
      default:
        statusString = '-'
    }
  }

  return (
    <TableRow onClick={handleRowClick}>
      <TableCell>{submissionName}</TableCell>
      <TableCell>{submission?.submitter?.full_name || submission?.submitter?.email}</TableCell>
      <TableCell>{new Date(submission?.created_at).toLocaleDateString('de-DE')}</TableCell>
      <TableCell><span className="text-sky-blue capitalize">{statusString}</span></TableCell>
      <TableCell>
        <Menu menuItems={[
          { name: 'Duplicate', onClick: () => duplicateSubmission(submission.uuid) },
          { name: 'Delete', onClick: () => mutate(submission), disabled: !!submission.intake_flow_uuid },
        ]}
        />

      </TableCell>
    </TableRow>
  )
}

export default LhotseHomeTableRow
