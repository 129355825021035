import { useMutation } from 'react-query'
import { Offer } from '../../../types/Offering'
import { deleteOffer } from '../../Offerings/services'
import useRefetchOfferQuery from './useRefetchQuery'

export const useDeleteOffer = () => {
  const refetchOfferQuery = useRefetchOfferQuery()

  return useMutation((offerToDelete: Offer) => deleteOffer(
    offerToDelete.uuid,
  ), {
    onSettled: async (a, b, offer) => {
      await refetchOfferQuery && refetchOfferQuery(offer.reference_key)
    },
  })
}

export default useDeleteOffer
