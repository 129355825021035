import { useEffect } from 'react'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { useGetUrlSearchParam } from '../../hooks/useGetUrlParam'

const ErrorToastr = () => {
  const error = useGetUrlSearchParam('error')

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        toastr.error(error, error)
      }, 500)
    }
  }, [])

  return (
    <div />
  )
}

export default ErrorToastr
