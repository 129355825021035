import CatalogMainContainer from './BodyContent/CatalogMainContainer'
import CatalogFeatures from './BodyContent/CatalogMainContainer/CatalogFeatures'
import CatalogProductDescription from './BodyContent/CatalogProductDescription'
import CatalogReferences from './BodyContent/CatalogReferences'

interface INewReqCatalogItemModal {
  catalogId: string
}

const NewReqCatalogItemModal: React.FC<INewReqCatalogItemModal> = ({ catalogId }) => (
  <div className="flex flex-col gap-y-4 pb-4">
    <CatalogMainContainer catalogId={catalogId} />
    <CatalogProductDescription catalogId={catalogId} />
    <div className="flex gap-2">
      <CatalogFeatures catalogId={catalogId} />
      <CatalogReferences catalogId={catalogId} />
    </div>
  </div>
)

export default NewReqCatalogItemModal
