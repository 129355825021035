import IntakeFormField from '../IntakeFormField'
import { IntakeSectionContentProps } from '../IntakeSection'

const IntakeBasicSection = ({ section }: IntakeSectionContentProps) => (
  <>
    {section.fields.map((field) => <IntakeFormField key={`intake-form-field-${field.id}`} field={field} />)}
  </>
)

export default IntakeBasicSection
