import { useTranslation } from 'react-i18next'
import SupplierUserType from '../../../../../../../types/Suppliers/SupplierUserType'
import Tag from './Tag'

interface IExternalSystemTag {
  supplierUser: SupplierUserType
}

const ExternalSystemTag: React.FC<IExternalSystemTag> = ({
  supplierUser,
}) => {
  const { t } = useTranslation()

  return supplierUser.supplier_branch.customer_supplier_id
    ? (
      <Tag
        text="External System ID ✓"
        className="bg-green-500 text-white bg-opacity-100"
      />
    )
    : (
      <Tag
        text="System ID missing!"
        className="bg-red-500 text-white bg-opacity-100"
      />
    )
}

export default ExternalSystemTag
