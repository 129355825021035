// Refactor to use useMutation
import { Dispatch, SetStateAction, useState } from 'react'
import { redirectToInstance } from '../../../../../core/utils/tenancy'
import { apiIdentifyTenant } from '../../../services/loginService'
import useGetQueryString from './useGetQueryString'

export default (
  setLinks: Dispatch<SetStateAction<number[]>>,
  setSubmitted: Dispatch<SetStateAction<boolean>>,
) => {
  const getQueryString = useGetQueryString()
  const [isLoading, setIsLoading] = useState(false)
  const mutate = async (email: string) => {
    setIsLoading(true)
    const res = await apiIdentifyTenant(email)
    if (res.length === 1) {
      redirectToInstance(res[0], getQueryString())
    } else {
      setLinks(res)
      setSubmitted(true)
    }
    setIsLoading(false)
  }

  return { mutate, isLoading }
}
