import { useEffect } from 'react'
import {
  Redirect,
  useHistory,
  useLocation, useParams, withRouter,
} from 'react-router-dom'
import CoreAuthenticating from '../../../core/components/CoreAuthenticating'
import { getToken } from '../../../core/utils/apiClient'
import { updateLocalAuth } from '../services/loginService'

const AuthenticationRoute = () => {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const { token } = useParams<{ token?: string }>()

  useEffect(() => {
    const prepFrontend = async () => {
      if (!token) {
        const existingToken = getToken()
        if (!existingToken) return <Redirect to="/login" />
        await updateLocalAuth(existingToken)
      } else {
        await updateLocalAuth(token)
      }
      return history.push(query.get('redirect') || '/')
    }

    prepFrontend()
  }, [token])

  return (
    <CoreAuthenticating />
  )
}

export default withRouter(AuthenticationRoute)
