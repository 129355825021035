import SearchInput from '../atoms/inputs/SearchInput'

interface ITitlebarSearch {
  inputState: [string, (input: string) => any]
  dataCy?: string,
    className?: string,
}

const TitlebarSearch: React.FC<ITitlebarSearch> = ({ inputState, dataCy, className }) => {
  const [searchInput, setSearchInput] = inputState

  return (
    <div className="flex text-sm">
      <SearchInput
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        dataCy={dataCy}
        className={className}
      />
    </div>
  )
}

export default TitlebarSearch
