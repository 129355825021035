import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import AddressType from '../../../../types/shared/AddressType'
import { AddressDataType } from '../../../../types/shared/RequisitionDataTypes'
import toastr from '../toastr/toastr'
import AddressForm from '../../../../modules/Company/components/addresses/AddressForm/AddressForm'
import useUpdateAddress from '../../../../modules/Company/components/addresses/hooks/useUpdateAddress'
import { CompanyAddressType } from '../../../../types/Company/AddressTypes'

interface IAddressesListEdit {
  address: AddressType
  onSuccess?: (data?: AddressDataType) => any
  onCancel?: () => void
}

const AddressesListEdit: React.FC<IAddressesListEdit> = ({ address, onSuccess, onCancel }) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const addressFormValues: CompanyAddressType = {
    ...address,
    country_id: address?.country?.id,
  }

  const { mutate: updateAddressMutate } = useUpdateAddress(
    address?.id,
    (data: AddressDataType) => {
      onSuccess?.(data)
      toastr.success(t('success.address_updated'), 'addressUpdatedSuccess')
    },
  )

  return (
    <AddressForm
      onCancel={() => {
        modal.close()
        onCancel?.()
      }}
      value={addressFormValues}
      onSubmit={(data) => {
        updateAddressMutate(data)
        modal.set({ isOpen: false })
      }}
    />
  )
}

export default AddressesListEdit
