import { t } from 'i18next'
import InputTextGray from '../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import useGetOffer from '../../hooks/useGetOffer'
import useUpdateOfferValue from '../../hooks/useUpdateOfferValue'

interface IDeliveryInput {
  updateDeliveryAutosave: () => void
}

const DeliveryInput: React.FC<IDeliveryInput> = ({
  updateDeliveryAutosave,
}) => {
  const { data: offerData } = useGetOffer()
  const updateOfferValue = useUpdateOfferValue()

  return (
    <InputTextGray
      placeholder={t('requisition_offers.list_offer_delivery_date')}
      fullWidth
      value={offerData?.offer?.delivery_time_estimate}
      onBlur={(e) => updateOfferValue('delivery_time_estimate', e.target.value, updateDeliveryAutosave)}
      dataCy="supplier-offer-delivery-input"
    />
  )
}

export default DeliveryInput
