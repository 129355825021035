import { FunnelIcon } from '@heroicons/react/24/outline'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ILhotseHomeStat {
  name: string,
  value: string | number,
  filterAction?: () => void
  filterActive?: boolean
}
const LhotseHomeStat: FC<ILhotseHomeStat> = ({
  name, value, filterAction, filterActive,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <dl className="">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5  shadow sm:p-6 base-form-input">
          <dt className="truncate text-sm font-medium text-gray-500">{name}</dt>
          <dd className="mt-1 flex justify-between items-end">
            <div className="text-2xl font-semibold tracking-tight text-gray-900">{value}</div>
            {filterAction && (
              <div
                tabIndex={-1}
                role="button"
                className={`float-right flex gap-1 ${filterActive ? 'text-sky-blue-light' : null}`}
                onClick={filterAction}
              >
                <FunnelIcon className="w-4" />
                {t('generic.view', 'View')}
              </div>
            )}
          </dd>
        </div>
      </dl>
    </div>
  )
}

export default LhotseHomeStat
