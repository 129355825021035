import { useTranslation } from 'react-i18next'
import useGetOffer from '../../hooks/useGetOffer'

const BuyerInfo: React.FC = () => {
  const { t } = useTranslation()
  const { data: offerData } = useGetOffer()

  return (
    <div className="flex min-h-[80px] h-[80px] overflow-hidden justify-between px-4 py-5 items-center gap-x-4 border-b border-gray-200">
      <div className="flex flex-col">
        <p>{offerData?.company?.name}</p>
        <p className="text-sm text-gray-400">{t('supplier_view.buyer')}</p>
      </div>
      {offerData?.company?.image && (
        <div className="h-18 w-[100px] h-[100px] flex items-center">
          <img
            src={offerData?.company?.image}
            alt={offerData?.company?.name}
          />
        </div>
      )}
    </div>
  )
}

export default BuyerInfo
