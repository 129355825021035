import { FormSubmissionType } from '../../../types/Forms'
import useGetLhotseHomeSubmissionsData from '../../Basecamp/components/BasecampTable/Submissions/hooks/useGetLhotseHomeSubmissionsData'
import useUrlParams from './useUrlParams'

export default function useFilteredSubmissions() {
  const { data, isLoading } = useGetLhotseHomeSubmissionsData()

  const { getParam: getFilter, setParam: setFilter } = useUrlParams()

  const filterSubmissions = (submission: FormSubmissionType) => {
    if (
      getFilter('query')
      && !JSON.stringify(submission.fields?.map((value) => value.value[0]?.value)).toLowerCase().includes(getFilter('query').toLowerCase())) return false

    if (getFilter('status')
    && getFilter('status') === 'DRAFT' && !!submission.intake_flow_uuid) return false

    return true
  }

  const filterDraftSubmissions = (submission: FormSubmissionType) => !submission.intake_flow_uuid

  const filteredSubmissions = data?.filter(filterSubmissions)

  const draftSubmissions = filteredSubmissions?.filter(filterDraftSubmissions)

  return {
    filteredSubmissions, draftSubmissions, setFilter, getFilter, data, isLoading,
  }
}
