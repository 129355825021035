import { useTranslation } from 'react-i18next'
import { MdOutlineModeComment } from 'react-icons/md'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import IndentationWrapper from '../../../../../../shared/components/atoms/IndentationWrapper'
import ItemPriceAttributeOption from './ItemPriceAttributeOption'

interface IOfferViewItemAttributeDescription {
  itemAttribute: LineItemItemAttributeType
  lastItem: boolean
}

const OfferViewItemAttributeDescription: React.FC<IOfferViewItemAttributeDescription> = (
  { itemAttribute, lastItem },
) => {
  const { t } = useTranslation()

  return (
    <div className={`sticky flex flex-col left-0 px-2 bg-white py-3 justify-between w-[230px] ${!lastItem && 'border-b border-gray-300'}`}>
      <p className="break-all">{t(itemAttribute.description || '')}</p>
      {!!itemAttribute?.options?.length
        && (
          <IndentationWrapper className="mt-2 flex flex-col gap-y-2 pl-4">
            {itemAttribute.options.map((option) => (
              <p key={option.id}>
                {itemAttribute.type === 'price'
                  ? <ItemPriceAttributeOption itemAttribute={itemAttribute} option={option} />
                  : option.option_value}
              </p>
            ))}
          </IndentationWrapper>
        )}
      {!!itemAttribute.comment?.comment && (
        <div className="flex pt-2 items-center gap-x-2">
          <MdOutlineModeComment className="text-gray-300 mt-1 h-[17px] w-[17px]" />
          <p>{itemAttribute.comment.comment}</p>
        </div>
      )}
    </div>
  )
}

export default OfferViewItemAttributeDescription
