import {
  apiGet, apiPost, apiGetData, apiPutData, apiDelete,
} from '../../../core/utils/api/generic'
import { FormSubmissionType } from '../../../types/Forms'
import BasecampRequisitionRowType from '../../../types/Basecamp/BasecampRequisitionRowType'
import SupplierUserType from '../../../types/Suppliers/SupplierUserType'

export type RequisitionFilters = Partial<{
  user_id: number[],
  approval_flow_assignees: number[],
  assigned_to: number[],
  created_between: string[],
  ends_between: string[],
  current_status: string[],
}>

export const apiGetRequisitions = async (page = 1, filters: RequisitionFilters = {}): Promise<BasecampRequisitionRowType> => apiGet<BasecampRequisitionRowType, { filter: RequisitionFilters, page: number }>('/requisitions', { page, filter: filters })

export const apiGetSubmissions = ({ params }: { params?: any }) => apiGetData<FormSubmissionType[]>('/forms/submissions', params)
export const apiGetLhotseHomeSubmissions = ({ params }: { params?: any }) => apiGetData<FormSubmissionType[]>('/forms/submissions/all', params)
export const apiDeleteSubmission = async (formUuid: string, submissionUuid: string) => apiDelete(`/forms/${formUuid}/submissions/${submissionUuid}`)

export const apiSearchRequisitions = async (query: string): Promise<BasecampRequisitionRowType> => apiPost('/requisitions/search', query)

export const apiGetUsers = () => apiGetData<SupplierUserType[]>('/users')

export const apiAssignUser = async ({
  model,
  id,
  email,
  message,
  origin,
}: {
  model: any,
  id: number | string,
  email: string,
  message: string,
  origin: string,
}) => apiPutData('assignments', {
  model,
  id,
  email,
  message,
  origin,
})

export const apiUnassignUser = async ({ assignmentId }: { assignmentId: number }) => apiDelete(`assignments/${assignmentId}`, assignmentId)
