import { useTranslation } from 'react-i18next'
import { InferStatusStatusType } from './hooks/useInferStatus'

const SubmissionsTableFilterButtons = ({ handleQueryUpdate, query }: { query: string[], handleQueryUpdate: (state: InferStatusStatusType) => void }) => {
  const { t } = useTranslation()
  return (
    <div className="flex gap-4 py-2  justify-end w-full">
      <button
        type="button"
        className={`flex items-center px-4 py-0.5 group relative bg-green-600 rounded-lg text-white hover:shadow-lg ${query.includes('transferred') ? '  ' : 'opacity-70'}`}
        onClick={() => handleQueryUpdate('transferred')}
      >
        {t('generic.transferred', 'Transferred')}
      </button>
      <button
        type="button"
        className={`flex items-center px-4 py-0.5 group relative bg-yellow-200 rounded-lg text-yellow-700  hover:shadow-lg ${query.includes('transfer_pending') ? '  ' : 'opacity-70'}`}
        onClick={() => handleQueryUpdate('transfer_pending')}
      >
        {t('generic.transfer-pending', 'Transfer Pending')}
      </button>
      <button
        type="button"
        className={`flex items-center px-4 py-0.5 group relative bg-blue-200 rounded-lg text-blue-700  hover:shadow-lg ${query.includes('submitted') ? '  ' : 'opacity-70'}`}
        onClick={() => handleQueryUpdate('submitted')}
      >
        {t('generic.submitted-to-procurement', 'Submitted to Procurement')}

      </button>
      <button
        type="button"
        className={`flex items-center px-4 py-0.5 group relative bg-gray-200 rounded-lg text-gray-700  hover:shadow-lg ${query.includes('draft') ? '  ' : 'opacity-70'}`}
        onClick={() => handleQueryUpdate('draft')}
      >
        {t('generic.draft', 'Draft')}
      </button>
    </div>
  )
}

export default SubmissionsTableFilterButtons
