import { HandleType, MarkerType } from 'reactflow'
import { merge, uniqBy } from 'lodash'
import {
  WorkflowChartEdge, WorkflowChartNode, WorkflowChartHelperNode, WorkflowChartGroupNode,
} from '../../../types/Workflow/WorkflowChart'
import { WorkflowStep } from '../../../types/Workflow/WorkflowBlock'
import uuid from '../../../core/utils/uuid'
import { isColumnNode } from './WorkflowChartNodeCreationHelpers'

export const getHandleId = (id: string, type: HandleType | 'helper') => `${type}-handle-${id}`

type ConnectionData = {source: WorkflowChartNode, target: WorkflowChartNode, type?: WorkflowChartEdge['type']}

const createEdge = (edgeData: Partial<WorkflowChartEdge> & Pick<WorkflowChartEdge, 'source' | 'target'>): WorkflowChartEdge => ({
  id: uuid('edge'),
  ...merge(
    {
      style: {
        strokeWidth: 2.5,
        opacity: 0,
      },
    },
    edgeData || {},
  ),
})

const createConnection = ({ source, target, type = 'straight' }: ConnectionData): WorkflowChartEdge => createEdge({
  id: `${source.id}-${target.id}`,
  type,
  source: source.parentNode || source.id,
  sourceHandle: getHandleId(source.parentNode || source.id, 'source'),
  target: target.id,
  markerEnd: {
    type: MarkerType.Arrow,
    width: 12,
    height: 14,
    strokeWidth: 1.5,
  },
})

type HelperConnectionData = {source: WorkflowChartHelperNode, target: WorkflowStep}

const createHelperEdgeId = ({ source, target }: HelperConnectionData): string => `${source.id}-${target.uuid}-helper-edge`

const createHelperToGroupConnection = ({ source, target }: HelperConnectionData): WorkflowChartEdge => createEdge({
  id: createHelperEdgeId({ source, target }),
  source: source.id,
  sourceHandle: getHandleId(source.id, 'source'),
  target: target.uuid,
  targetHandle: getHandleId(target.uuid, 'target'),
  type: 'straight',
})

const createGroupToHelperConnection = ({ source, target }: HelperConnectionData): WorkflowChartEdge => createEdge({
  id: createHelperEdgeId({ source, target }),
  source: target.uuid,
  sourceHandle: getHandleId(target.uuid, 'source'),
  target: source.id,
  targetHandle: getHandleId(source.id, 'target'),
  type: 'straight',
})

const createHelperConnection = ({ source, target }: HelperConnectionData) => {
  if (source.data.position < target.position) { return createHelperToGroupConnection({ source, target }) }
  if (source.data.position > target.position) { return createGroupToHelperConnection({ source, target }) }

  return createEdge({
    id: createHelperEdgeId({ source, target }),
    source: target.uuid,
    sourceHandle: getHandleId(target.uuid, 'helper'),
    target: source.id,
    type: 'straight',
  })
}

const createHelperEdge = (node: WorkflowChartHelperNode): WorkflowChartEdge | undefined => (
  (node.data.target && node.data.nodeId)
    ? createHelperConnection({ source: node, target: node.data.target as WorkflowStep })
    : undefined)

export const generateIntakeFlowchartEdges = (nodes: WorkflowChartNode[], type: WorkflowChartEdge['type'] = 'straight'): WorkflowChartEdge[] => {
  const findFlowchartColumnNodeByPosition = (position: number | null | undefined): WorkflowChartGroupNode | undefined => (position
    ? nodes.find((node) => isColumnNode(node) && (node.data as WorkflowStep).position === position) as WorkflowChartGroupNode
    : undefined)

  const interColumnsConnections = uniqBy(
    (
    nodes.filter(isColumnNode)
      .map((node) => {
        const nextGroupNode = findFlowchartColumnNodeByPosition((node.data as WorkflowStep).position + 1)
        return { source: node, target: nextGroupNode, type }
      })
      .filter(({ source, target }) => source && target) as ConnectionData[]
    ).map(createConnection),
    'id',
  )

  const helperEdges = (nodes.filter(({ type }) => type === 'HELPER') as WorkflowChartHelperNode[])
    .filter((node) => (!!node.data.target))
    .map(createHelperEdge)
    .filter((edge): edge is WorkflowChartEdge => !!edge)

  const outsideHelperEdges = ((nodes.filter(({ type }) => type === 'HELPER') as WorkflowChartHelperNode[])
    .filter((node) => (!node.data.target))
    .map((node) => {
      const target = findFlowchartColumnNodeByPosition(node.data.position - 1) || findFlowchartColumnNodeByPosition(node.data.position + 1)
      return { source: node, target: target?.data }
    }).filter(({ source, target }) => source && target) as HelperConnectionData[])
    .map(createHelperConnection)

  return [...interColumnsConnections, ...helperEdges, ...outsideHelperEdges]
}
