import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

interface IFilterPill {
  name: string,
  amount: number,
  selected: boolean,
}

const FilterPill: FunctionComponent<IFilterPill> = ({
  name, amount, selected,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={`
    flex justify-between w-48 gap-2
    text-xs text-gray-500
    hover:bg-indigo-100 hover:bg-opacity-50
    px-5 py-2
    rounded-md
    ${selected ? 'bg-indigo-200 bg-opacity-50' : 'bg-gray-100'}
  `}
    >
      <div className="whitespace-nowrap truncate w-36 text-left">{t(name)}</div>
      {amount && <div>{amount}</div>}
    </div>
  )
}

export default FilterPill
