import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MercateoBlockPriceType } from '../../../../../../../../../../types/Requisition/MercateoItemType'
import calculatePriceDifference from '../../../../../../utils/Catalog/calculatePriceDifference'
import calculateSavingPercentage from '../../../../../../utils/Catalog/calculateSavingPercentage'
import currencySymbol from '../../../../../../utils/currencySymbol'

interface ICatalogBlockPricingSave {
  originalPrice: MercateoBlockPriceType
  price: MercateoBlockPriceType
}

const CatalogBlockPricingSave: React.FC<ICatalogBlockPricingSave> = ({ originalPrice, price }) => {
  const { t } = useTranslation()

  const currency = currencySymbol[originalPrice.currency]

  const priceDifference = useMemo(
    () => calculatePriceDifference(originalPrice.amount, price.amount),
    [originalPrice.amount, price.amount],
  )

  const savingPercentage = useMemo(
    () => calculateSavingPercentage(originalPrice.amount, price.amount),
    [originalPrice.amount, price.amount],
  )

  return (
    <div className="flex items-center gap-0.5 whitespace-nowrap ">
      <p className="flex items-center">
        {t('catalog.you_save')}
        :
      </p>
      <p className="text-orange-500 text-xs flex items-center">
        {currency}
        {' '}
        {priceDifference}
        (
        {' '}
        {savingPercentage}
        %
        )
      </p>
    </div>
  )
}
export default CatalogBlockPricingSave
