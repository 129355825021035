import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface IAddNewTagButton {
  setShowCreate: Dispatch<SetStateAction<boolean>>
}

const AddNewTagButton: React.FC<IAddNewTagButton> = ({
  setShowCreate,
}) => {
  const { t } = useTranslation()

  return (
    <button
      type="button"
      onClick={() => setShowCreate(true)}
      className="text-sm text-primary-gray"
    >
      {`+ ${t('suppliers.add_tags')}`}
    </button>
  )
}

export default AddNewTagButton
