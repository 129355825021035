import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import Assignees from '../../../../../shared/components/organisms/AssigneesSelector/AssigneesSelector'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import statusSwitch from '../../../../Basecamp/utils/requisitionStatusStyles'
import useGetRequisitionData from '../../../subModules/Definition/hooks/useGetRequisition'
import ReqTopbarActionButtons from './ReqTopbarActionButtons'
import RequisitionTopbarCancelRequisitionBtn from './RequisitionTopbarCancelRequisitionBtn'

const RequisitionTopbarInformation: React.FC = () => {
  const { t } = useTranslation()
  const requisitionId = useGetRequisitionId()
  const { data: requisitionData, isLoading: isLoadingReq } = useGetRequisitionData()

  if (!requisitionData) return <CoreLoadingIcon />

  const statusData = statusSwitch(requisitionData.status)

  return (
    <div className="flex justify-end">
      <RequisitionTopbarCancelRequisitionBtn />

      <div
        className={`${statusData.borderColor} ${statusData.textColor} flex items-center px-2.5 py-0.5 text-xs rounded-full group`}
        data-cy="req-status"
      >
        {t(statusData.name)}
      </div>

      {!isLoadingReq && requisitionData && (
        <div className="flex items-center space-x-4 max-w-2/4 justify-end">
          <div className="flex items-center gap-2">

            <Assignees
              origin="requisition"
              modelId={requisitionId}
              assignments={requisitionData.assignments}
            />
          </div>
          <ReqTopbarActionButtons />
        </div>
      )}
    </div>
  )
}

export default RequisitionTopbarInformation
