import * as React from 'react'
import { FormQuestionContentType } from '../../types/Forms'

import { IntakeFormFieldValuesProps } from '../Intake/components/IntakeSections/IntakeFormField'
import FormFieldDisplayGeneric from './FormFieldDisplay/FormFieldDisplayGeneric'
import FormFieldDisplayMedia from './FormFieldDisplay/FormFieldDisplayMedia'
import FormFieldInput from './FormFieldInput'
import { useFormField } from './providers/FormFieldProvider'

const FormFieldDisplayComponents: Partial<Record<FormQuestionContentType, React.FC<IntakeFormFieldValuesProps>>> = {
  FILE: FormFieldDisplayMedia,
}

const getFormFieldDisplayComponent = (type: FormQuestionContentType) => FormFieldDisplayComponents[type] || FormFieldDisplayGeneric

export const FormFieldDisplay = () => {
  const { field: { input: { type } } } = useFormField()
  const DisplayComponent = getFormFieldDisplayComponent(type)

  return (<DisplayComponent />)
}

export default FormFieldInput
