import { RequisitionFilterConfig, RequisitionFilterValueType } from '../types'
import { useRequisitionFilters } from '../provider/RequisitionFiltersProvider'
import useRequisitionFilterMethods from './useRequisitionFilterMethods'

const getDateRangeApiValue = (value: RequisitionFilterValueType) => {
  if (!Array.isArray(value.apiValue)) return null

  if (value.apiValue.filter((apiValue) => !!apiValue).length !== 2) return null

  return value.apiValue
}

const getDefaultFilterApiValue = ({ apiValue, id }: RequisitionFilterValueType) => apiValue || id

const getFilterValueToApiTransformer = (filter: RequisitionFilterConfig | undefined) => {
  if (filter?.type === 'date-range') {
    return getDateRangeApiValue
  }

  return getDefaultFilterApiValue
}

export const useRequisitionFiltersApiValues = () => {
  const { filterValues } = useRequisitionFilters()
  const { findFilter } = useRequisitionFilterMethods()

  return Object.fromEntries(Object.entries(filterValues).map(([filterId, values]) => [
    filterId,
    values
      .map(getFilterValueToApiTransformer(findFilter(filterId)))
      .flat()
      .filter((apiValue) => !!apiValue),
  ])
    .filter(([, values]) => values.length > 0))
}

export default useRequisitionFiltersApiValues
