import { useEffect, useState } from 'react'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionNumberRange from '../../components/inputs/QuestionNumberRange'
import useUpdateQuestionSetting from '../../../hooks/useUpdateQuestionSetting'

const ChoicesRangeInput: React.FC = () => {
  const { question: [question] } = useFormContext()
  const updateQuestionSettings = useUpdateQuestionSetting()

  const numberOfChoicesValue = question?.__settings?.answers

  const minimumState = useState<number | undefined>(numberOfChoicesValue?.min)
  const maximumState = useState<number | undefined>(numberOfChoicesValue?.max)

  useEffect(() => {
    updateQuestionSettings({
      answers: {
        min: minimumState[0],
        max: maximumState[0],
      },
    })
  }, [minimumState[0], maximumState[0]])

  return (
    <QuestionNumberRange
      maxValue={100}
      minimumState={minimumState}
      maximumState={maximumState}
      placeholder="-"
    />
  )
}

export default ChoicesRangeInput
