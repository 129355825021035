import { useTranslation } from 'react-i18next'
import useGetMessages from '../../../hooks/useGetMessages'
import useGetMessengerUrlParams from '../../../hooks/useGetMessengerUrlParams'

const NoActivityText: React.FC = () => {
  const { t } = useTranslation()
  const { modelType } = useGetMessengerUrlParams()
  const isChat = modelType === 'supplier_invite'

  const { data: messages, isLoading } = useGetMessages()

  if ((messages?.length && !isLoading) || !modelType) return null

  return <div className="flex w-full justify-center h-full items-center text-sm text-gray-500">{isChat ? t('sherpa.no_messages') : t('sherpa.no_notes')}</div>
}

export default NoActivityText
