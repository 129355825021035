import { useEffect, useState } from 'react'
import { AssignmentType } from '../../../../types/shared/AssignmentTypes'
import AssigneesPopup from './AssigneesPopup'

interface IAssignees {
  origin: string,
  modelId: string | number,
  assignments?: AssignmentType[]
  maxAssignments?: number,
  readOnly?: boolean
  dataCy?: string
  model?: 'requisition' | 'form_submission' | 'intake-flow'
}

const Assignees: React.FC<IAssignees> = ({
  assignments, origin, modelId, maxAssignments = 2, readOnly, dataCy, model = 'requisition',
}) => {
  const [localAssignments, setLocalAssignments] = useState<AssignmentType[] | undefined>(
    assignments,
  )

  useEffect(() => {
    setLocalAssignments(assignments)
  }, [assignments])

  const scrollableElement = document.getElementById('chat-list-column') as HTMLElement

  return (
    <div className="flex items-center justify-end">
      {!readOnly && (
        <AssigneesPopup
          modelId={modelId}
          model={model}
          localAssignments={localAssignments}
          setLocalAssignments={setLocalAssignments}
          maxAssignments={maxAssignments}
          origin={origin}
          dataCy={dataCy}
          scrollableElement={scrollableElement}
        />
      )}
    </div>
  )
}

export default Assignees
