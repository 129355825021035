import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'
import Titlebar from '../../../../shared/components/layout/Titlebar'
import DocumentForm from './DocumentForm/DocumentForm'

const CompanyDocumentsHeader = () => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const triggerDocumentsModal = () => {
    modal.set(
      {
        isOpen: true,
        title: 'documents.create_new_document',
        content: (
          <DocumentForm />
        ),
      },
    )
  }
  return (
    <Titlebar title="generic.document_plural">
      <AddButton onClick={triggerDocumentsModal} dataCy="create-document-button">
        {t('documents.add_document')}
      </AddButton>
    </Titlebar>
  )
}

export default CompanyDocumentsHeader
