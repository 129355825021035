import { Dispatch, SetStateAction } from 'react'
import Checkbox from '../../../../shared/components/atoms/inputs/Checkbox'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import useGetToken from '../../hooks/useGetToken'
import useToggleDocumentAcceptance from './useToggleDocumentAcceptance'

interface IDocumentCheckbox {
  checked: boolean
  setChecked: Dispatch<SetStateAction<boolean>>
  document: CustomerDocumentType
}

const DocumentCheckbox: React.FC<IDocumentCheckbox> = ({
  setChecked, checked, document,
}) => {
  const token = useGetToken()

  const toggleDocumentAcceptance = useToggleDocumentAcceptance({
    token,
    onSuccess: () => setChecked((currentlyChecked) => !currentlyChecked),
  })

  return (
    <Checkbox
      onChange={() => toggleDocumentAcceptance(document.id)}
      checked={checked}
      dataCy={`document-${document.id}`}
    />
  )
}

export default DocumentCheckbox
