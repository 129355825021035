import ChatMessageEditor from './ChatMessageEditor/ChatMessageEditor'
import MessagesContainer from './MessagesContainer/MessagesContainer'

const MessagingContainer: React.FC = () => {
  const scrollableDiv = document.getElementById('scrollableDiv') as HTMLElement
  const maxHeight = scrollableDiv && scrollableDiv.offsetHeight - 170

  return (
    <div className="flex w-2/3 flex-col rounded-br-lg" id="messageBoxContainer">
      <MessagesContainer style={{ maxHeight }} />
      <ChatMessageEditor />
    </div>
  )
}

export default MessagingContainer
