import { useQuery } from 'react-query'
import keys from '../../../constants/reqCoordinateQueryKeys'
import useGetRequisitionId from '../../../../../../../shared/hooks/useGetRequisitionId'
import { getSupplierRecommendations } from '../../../../../services/RequisitionSuppliersService'

export default () => {
  const requisitionId = useGetRequisitionId()

  return useQuery(
    [keys.REQUISITION_SUPPLIERS, requisitionId],
    () => getSupplierRecommendations(requisitionId),
  )
}
