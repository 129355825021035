import { useRef, useState } from 'react'
import { INodeChildren } from '../../../../../types/shared/GeneralTypes'
import ModalPortal from './ModalPortal'
import useHideModalOnScroll from './useHideModalOnScroll'

interface IHoverModal extends INodeChildren {
  modalBody: JSX.Element
}

const HoverModal: React.FC<IHoverModal> = ({ children, modalBody: ModalBody }) => {
  const [isMouseOverChild, setIsMouseOverChild] = useState(false)
  const [isMouseOverModal, setIsMouseOverModal] = useState(false)

  useHideModalOnScroll(setIsMouseOverChild, setIsMouseOverModal)

  const childRef = useRef<HTMLDivElement>(null)

  const shouldShowModal = isMouseOverChild || isMouseOverModal

  const modalLeft = childRef.current?.getBoundingClientRect().left || 0
  const modalTop = childRef.current?.getBoundingClientRect().top || 0

  return (
    <>
      {shouldShowModal
        && (
          <ModalPortal
            style={{
              top: modalTop + 20,
              left: modalLeft + 20,
            }}
            onMouseEnter={() => setIsMouseOverModal(true)}
            onMouseLeave={() => setIsMouseOverModal(false)}
          >
            {ModalBody}
          </ModalPortal>
        )}
      <div
        onMouseEnter={() => setIsMouseOverChild(true)}
        onMouseLeave={() => setIsMouseOverChild(false)}
        ref={childRef}
      >
        {children}
      </div>
    </>
  )
}

export default HoverModal
