import { CalendarIcon, CheckIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import { WorkflowBlock } from '../../../../../../../types/Workflow/WorkflowBlock'
import { WorkflowBlockStatusEntity } from '../../../../../../../types/Workflow/WorkflowBlockStatus'
import { NodeTypes } from '../../../../../../WorkflowChart/providers/hooks/useWorkflowChartNodeTypes'

export type IconProps = React.SVGProps<SVGSVGElement> & { title?: string, titleId?: string }

const statusIcons: Record<WorkflowBlockStatusEntity['name'], typeof CalendarIcon | undefined | React.FC<IconProps>> = {
  completed: CheckIcon,
  in_queue: undefined,
  skipped: undefined,
  ongoing: undefined,
  approved: undefined,
  declined: undefined,
  failed: undefined,
  pending: undefined,
}

const blockTypeIcons: Record<keyof NodeTypes, typeof CalendarIcon | undefined | React.FC<IconProps>> = {
  APPROVAL: CalendarIcon,
  APPROVER: CalendarIcon,
  GROUP: undefined,
  HELPER: undefined,
}

export const useBlockIcon = (block: WorkflowBlock | undefined) => (block ? statusIcons[block.status || 'pending'] || blockTypeIcons[block.workflow_block_type?.name || 'APPROVAL'] : null)
