import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RecommendedSupplierType, RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'

import Button from '../../../../../../../shared/components/atoms/buttons/Button'
import SuppliersTableTag from '../../../../../../../shared/components/organisms/SupplierInfo/SupplierTags'
import HoverModal from '../../../../../../../shared/components/organisms/modals/HoverModal/HoverModal'
import SupplierBranchName from '../../../../../../Suppliers/components/BranchName'
import SupplierUserName from '../../../../../../Suppliers/components/SupplierProfile/SupplierUserName'

import useOpenSupplierDetailsModal from '../../../../../../SupplierDetails/hooks/useOpenSupplierDetailsModal'
import SelectSupplierCheckbox from '../SupplierRow/LeftButton/SelectSupplierCheckbox/SelectSupplierCheckbox'
import SingleReqStepper from '../SupplierRow/SingleReqStepper'
import SupplierRequisitionsPreviewModal from '../SupplierRow/SupplierRequisitionsPreviewModal/SupplierRequisitionsPreviewModal'
import useHandleOpenSupplierRequisitions from '../SupplierRow/SupplierRequisitionsPreviewModal/useHandleOpenSupplierRequisitions'

export interface ISupplierRow {
  showSelect: boolean,
  supplierUser: RecommendedSupplierType,
  supplierInvite?: RequisitionInviteType,
  status?: string,
  confidenceValue?: number, // TODO: change type to match confidence value implementation,
  actionComponent?: JSX.Element
}

const SupplierRow: FC<ISupplierRow> = ({
  showSelect,
  supplierUser,
  supplierInvite,
  status,
  confidenceValue,
  actionComponent,
}) => {
  const supplierBranch = supplierUser.supplier_branch
  const handleDisplayModal = useOpenSupplierDetailsModal(supplierBranch.id)
  const handleOpenSupplierRequisitions = useHandleOpenSupplierRequisitions(supplierBranch.id)
  const { t } = useTranslation()

  return (
    <tr key={`supplierUser-${supplierUser.id}`}>
      <td className="whitespace-nowrap py-4 pl-6 pr-3 text-mountain-gray flex flex-row align-middle justify-center">
        {showSelect && (<div className="mr-2 align-top"><SelectSupplierCheckbox supplierInvite={supplierInvite} /></div>)}
        <div className="flex-col flex-1">
          <div className="flex flex-row text-base">
            <SupplierBranchName
              disabledStar
              wasDeleted={supplierUser.deleted_at}
              supplierBranch={supplierBranch}
              className="hover:text-sky-blue text-base flex items-center gap-2 font-medium"
              onClick={handleDisplayModal}
            />
          </div>
          <Button
            type="button"
            className="text-sky-blue-light hover:text-sky-blue transition-colors text-xs px-0 py-0 mt-1"
            onClick={handleOpenSupplierRequisitions}
          >
            <HoverModal
              modalBody={<SupplierRequisitionsPreviewModal supplierBranchId={supplierBranch.id} />}
            >
              {t('generic.view-more', 'View more')}
            </HoverModal>
          </Button>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-base text-mountain-gray">
        <SupplierUserName supplierUser={supplierUser} />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-base text-mountain-gray">
        <SuppliersTableTag
          tags={supplierBranch.tags}
          supplierBranchId={supplierBranch.id}
          numTags={1}
        />
      </td>
      {status && (
        <td className="whitespace-nowrap px-3 py-4 text-base text-mountain-gray">
          <span className="capitalize text-sm">
            <SingleReqStepper step={supplierInvite?.status} />
          </span>
        </td>
      )}
      {confidenceValue && (
        <td className="whitespace-nowrap px-3 py-4 text-base text-rock-gray">
          {/* TODO: Change to match implementation of confidence levels */}
        </td>
      )}
      <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-base">
        {actionComponent || null}
      </td>
    </tr>
  )
}

export default SupplierRow
