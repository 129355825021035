import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'

export const useDraftState = <T>(initialState: T): [T, Dispatch<SetStateAction<T>>] => {
  const [draftState, setDraftState] = useState<T>(initialState)

  useEffect(() => {
    setDraftState(initialState)
  }, [initialState])

  return [draftState, setDraftState]
}

export default useDraftState
