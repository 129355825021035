import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { dateFormat } from '../../../../../shared/constants/dateFormat'
import { FormQuestionType } from '../../../../../types/Forms'
import { MediaType } from '../../../../../types/shared/MediaTypes'

import MediaTable from '../../../../../shared/components/atoms/FileUpload/MediaTable'
import getAddressLabel from '../../../../../shared/utils/formAddressLabels'
import useFormFileDownload from '../../hooks/useFormFileDownload'

interface IConfirmationSection {
  question: FormQuestionType
  invalidQuestions?: string[]
  questionIndex: number
}

const ConfirmationQuestionComponent
  : React.FC<IConfirmationSection> = ({ question, invalidQuestions }) => {
    const { t } = useTranslation()
    const handleDownload = useFormFileDownload()

    const isInvalidQuestion = invalidQuestions?.includes(question.__uuid)

    return (
      <div className={`pt-2 px-6 flex  ${isInvalidQuestion ? 'border-l-red-500 border-l-4' : ''}`}>
        <div className="space-y-2.5 w-full">
          <div className="text-sm font-semibold flex">
            <div className={`${isInvalidQuestion && 'text-red-600'}`}>
              {question.label || t('generic.no_title')}
              {' '}
              {question.validation.required?.value && '*'}
            </div>
          </div>
          <div className="text-sm ">
            {question.values.filter((q) => q.value !== null).length < 1 && ('-')}
            {question.values?.length ? question.values?.filter((f) => f.value)
              .map(({ __uuid, value }) => (
                <div key={__uuid}>
                  {typeof value === 'string' && question.type !== 'DATE' && (
                    <span className=" whitespace-pre-wrap ">
                      {value}
                    </span>
                  )}
                  {question.type === 'DATE' && dayjs(value as (Date | string)).format(dateFormat)}
                  {question.type === 'ADDRESS' && getAddressLabel(value)}
                </div>
              )) : '-'}
            {question.type === 'FILE' && question.values.map(({ value: mediaValue }) => mediaValue as MediaType).length > 0
              && (
                <MediaTable
                  media={question.values.map(({ value: mediaValue }) => mediaValue as MediaType) || []}
                />
              )}
          </div>
        </div>
      </div>
    )
  }

export default ConfirmationQuestionComponent
