import dayjs from 'dayjs'
import { FormQuestionValue } from '../../../../../../../types/Forms'
import { FormLogicQuestionType } from './checkIfAnswerFulfillsRequirement'
import { getUsesUUIDs } from './constants'

export default (
  question: FormLogicQuestionType,
  questionAnswers: FormQuestionValue[],
  questionLogic: string,
) => {
  const usesUUIDs = getUsesUUIDs(question)

  const hasMultipleTextInputs = !getUsesUUIDs(question)
    && (question.question.__settings?.answers?.min || 1) > 1

  if (usesUUIDs) {
    const questionAnswerUUIDs = questionAnswers
      .map((questionAnswer) => questionAnswer.__uuid)
    return questionAnswerUUIDs.includes(questionLogic)
  }

  if (hasMultipleTextInputs) {
    const questionAnswerValues = questionAnswers
      .map((questionAnswer) => questionAnswer.value)
    return questionAnswerValues.includes(questionLogic)
  }

  if (question.questionType === 'DATE') {
    const startDate = questionAnswers[0]?.value as string
    const endDate = questionAnswers[1]?.value as string

    return dayjs(questionLogic).isBetween(startDate, endDate)
  }

  const questionAnswerValues = questionAnswers
    .map((questionAnswer) => questionAnswer.value)
  return questionAnswerValues.includes(questionLogic)
}
