import { useTranslation } from 'react-i18next'
import SharedFileUploadPill from '../../../../../../shared/components/layout/SharedFileUploadPill/SharedFileUploadPill'
import useGetFormData from '../../../../hooks/useGetFormData'
import ThumbnailDropzone from './ThumbnailDropzone'
import ThumbnailPreview from './ThumbnailPreview'
import useDeleteFormThumbnail from '../../../hooks/useDeleteFormThumbnail'
import useUpdateFormSetting from '../../../QuestionPage/hooks/useUpdateFormSetting'
import SliderSettingRow from '../../../../../../shared/components/organisms/SliderSettingRow'

const GeneralSettings: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useGetFormData()
  const existingThumbnail = data?.thumbnail
  const remove = useDeleteFormThumbnail()

  const updateFormSettings = useUpdateFormSetting()
  const { shouldValidateOnSectionChange, offerRequired, uniteEnabled } = data?.template.__settings || {}

  return (
    <div className="bg-white px-6 py-4 rounded-md flex flex-col gap-y-4 text-primary-gray">
      <p className="font-medium">{t('forms.general_settings')}</p>
      <p className="text-primary-gray text-sm">This is how the form will be displayed to users when accessed in the Lhotse application.</p>
      <div className="flex flex-col gap-y-4">
        <SliderSettingRow
          title={t('forms.settings.allow_skip_validation.label')}
          description={t('forms.settings.allow_skip_validation.description')}
          onChange={() => (
            updateFormSettings('shouldValidateOnSectionChange', !shouldValidateOnSectionChange)
          )}
          checked={!shouldValidateOnSectionChange}
        />
        <SliderSettingRow
          title={t('forms.settings.offer_required.label')}
          description={t('forms.settings.offer_required.description')}
          onChange={() => (
            updateFormSettings('offerRequired', !offerRequired)
          )}
          checked={offerRequired}
        />
        <SliderSettingRow
          title={t('forms.settings.unite_enabled')}
          description={t('forms.settings.unite_enabled.description')}
          onChange={() => (
            updateFormSettings('uniteEnabled', !uniteEnabled)
          )}
          checked={uniteEnabled}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <p className="text-sm">{t('forms.thumbnail')}</p>
        {!existingThumbnail?.url
          ? <ThumbnailDropzone />
          : <SharedFileUploadPill file={existingThumbnail} removeFile={remove} />}
      </div>
      <ThumbnailPreview />
    </div>
  )
}

export default GeneralSettings
