import React, { useEffect, useMemo } from 'react'
import { Workflow } from '../../../types/Workflow/Workflow'
import useOpenWorkflowBuilder from '../hooks/useOpenWorkflowBuilder'
import { WorkflowBlock, WorkflowStep, WorkflowStepDraft } from '../../../types/Workflow/WorkflowBlock'
import useToggleableState, { ToggleStateFunction } from '../../../core/hooks/useToggleableState'
import useFindWorkflowBlockStep from '../hooks/useFindWorkflowBlockStep'
import useWorkflowStructureMutations from '../hooks/useWorkflowStructureMutations'

const generateBlockDraft = (): WorkflowBlock => ({
  uuid: '',
  name: '',
  triggers: [],
  approvers: [],
  reminder: '12H',
  tasks: [],
  type: 'APPROVAL',
})

export type WorkflowContextType = {
    workflow: Workflow,
    readonly: boolean,
    openBuilder: () => void,
    selectedBlock: WorkflowBlock | null,
    selectBlock: (selectedBlock: WorkflowBlock | null) => void,
    toggleBlockSelection: (selectedBlock: WorkflowBlock | null) => void,
    selectedStep: WorkflowStepDraft | null,
    sidePanelOpen: boolean,
    toggleSidePanel: ToggleStateFunction,
    removeBlock: (block: Pick<WorkflowBlock, 'uuid'>) => void,
    removeSelectedBlock: () => void,
    createStepDraft: (at: number) => void,
    createBlockDraft: (stepUuid: string) => void,
    deselect: () => void
}

export type WorkflowProviderProps = React.PropsWithChildren<{
    workflow: Workflow,
    readonly?: boolean
}>;

const WorkflowContext = React.createContext({} as WorkflowContextType)

export const WorkflowProvider = ({ workflow, readonly = true, children }: WorkflowProviderProps) => {
  const openBuilder = useOpenWorkflowBuilder(workflow)

  const [selectedBlock, setSelectedBlock] = React.useState<WorkflowBlock | null>(null)
  const [selectedStep, setSelectedStep] = React.useState<WorkflowStepDraft | null>(null)
  const [sidePanelOpen, toggleSidePanel] = useToggleableState(false)
  const findBlockStep = useFindWorkflowBlockStep(workflow)
  const { removeBlock: sendBlockRemovalRequest } = useWorkflowStructureMutations(workflow)

  // if workflow data updates, update the data of the selected block.
  useEffect(() => {
    if (selectedBlock) {
      const blockStep = findBlockStep(selectedBlock)
      if (blockStep) setSelectedStep(blockStep)

      setSelectedBlock(blockStep?.blocks.find(
        (block) => block.uuid === selectedBlock.uuid,
      ) || null)
    }
  }, [workflow])

  const deselect = React.useCallback(() => {
    setSelectedBlock(null)
    setSelectedStep(null)
    toggleSidePanel(false)
  }, [])

  const selectBlock = React.useCallback((block: WorkflowBlock | null) => {
    setSelectedBlock(block)
    const blockStep = findBlockStep(block)
    if (blockStep) setSelectedStep(blockStep)
    toggleSidePanel(!!block)
  }, [toggleSidePanel, setSelectedBlock])

  const removeBlock = React.useCallback((block: Pick<WorkflowBlock, 'uuid'>) => {
    sendBlockRemovalRequest(block)
    if (selectedBlock?.uuid === block.uuid) {
      deselect()
    }
  }, [selectedBlock, sendBlockRemovalRequest, selectBlock, toggleSidePanel])

  const createStepDraft = React.useCallback((at: number) => {
    setSelectedStep({ position: at, blocks: [] })
    selectBlock(generateBlockDraft())
  }, [selectBlock])

  const createBlockDraft = React.useCallback((stepUuid: WorkflowStep['uuid']) => {
    setSelectedStep(workflow.steps.find(({ uuid }) => uuid === stepUuid) || null)
    selectBlock(generateBlockDraft())
  }, [workflow, selectBlock])

  const workflowContextState = useMemo<WorkflowContextType>(() => ({
    workflow,
    readonly,
    openBuilder,
    selectedBlock,
    selectBlock,
    toggleBlockSelection: (block) => {
      if (block && block.uuid === selectedBlock?.uuid) {
        selectBlock(null)
      } else {
        selectBlock(block)
      }
    },
    selectedStep,
    sidePanelOpen,
    toggleSidePanel,
    removeBlock,
    removeSelectedBlock: () => {
      if (!selectedBlock) return
      removeBlock(selectedBlock)
    },
    createStepDraft,
    createBlockDraft,
    deselect,
  }), [workflow, readonly, openBuilder, sidePanelOpen, toggleSidePanel, selectedBlock, selectBlock, selectedStep, removeBlock, createStepDraft, createBlockDraft])

  return React.createElement(WorkflowContext.Provider, {
    value: workflowContextState,
  }, children)
}

export const useWorkflow = () => React.useContext(WorkflowContext)

export default WorkflowProvider
