import classNames from 'classnames'
import { MdArrowForward } from 'react-icons/md'
import Calendar from '../../../shared/components/organisms/Calendar/Calendar'

const getDate = (date: string | Date | undefined | null) => (date ? new Date(date) : undefined)

type IntakeDateInputProps = {
  onChange: (newDate: Date, newSecondDate: Date | '' | undefined) => void
  type: 'DATE_TIME' | 'DATE_RANGE' | 'DATE'
  date: string,
  secondDate?: string
  disabled?: boolean
}
export const FormDateInput = ({
  onChange, type, date, secondDate, disabled,
}: IntakeDateInputProps) => {
  const isDateTime = type === 'DATE_TIME'
  const dateFormat = `dd.MM.yyyy${isDateTime ? ' HH:mm' : ''}`
  const dateSeparator = '.'
  const formattedAndSeparatedFormat = `${dateFormat?.replaceAll('.', dateSeparator)}`

  const selectedFirstDate = getDate(date)
  const selectedSecondDate = secondDate && getDate(secondDate)

  // if (Number.isNaN(selectedFirstDate.getTime())) return null

  const handleChange = (firstDate: Date | undefined, secondDate?: Date | '') => {
    if (firstDate) {
      onChange && onChange(firstDate, secondDate)
    }
  }

  return (
    <div className="flex gap-x-2 relative w-full items-center ">
      <Calendar
        disabled={disabled}
        onChange={(e) => handleChange(e, selectedSecondDate)}
        selected={selectedFirstDate}
        showTimeSelect={isDateTime}
        wrapperClassName="w-full"
        dateFormat={formattedAndSeparatedFormat}
        placeholder={formattedAndSeparatedFormat.toUpperCase()}
      />
      {selectedSecondDate
        && (
          <>
            <MdArrowForward size={50} className="text-secondary-gray" />
            <Calendar
              disabled={disabled}
              onChange={(e) => handleChange(selectedFirstDate, e)}
              selected={selectedSecondDate}
              placeholder={formattedAndSeparatedFormat.toUpperCase()}
              wrapperClassName="w-full"
              dateFormat={formattedAndSeparatedFormat}
              minDate={selectedFirstDate}
              showTimeSelect={isDateTime}
            />
          </>
        )}
    </div>
  )
}

export default FormDateInput
