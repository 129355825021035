import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import { FormikInputType } from '../../../../../types/shared/FormikType'
import ErrorMessage from '../../ErrorMessage'
import { getUnderlineInputClasses } from './underlineConstants'

interface IPhoneNumberInputUnderline extends Omit<FormikInputType, 'customOnChange'> {
  handleChange: (e: React.ChangeEvent) => void
  placeholder?: string
  type?: string,
}

const PhoneNumberInputUnderline: React.FC<IPhoneNumberInputUnderline> = ({
  type, name, dataCy, placeholder, values, error,
  handleOnEnter, disabled, label, handleChange,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col overflow-visible relative w-full">
      {label && (
        <label htmlFor={name} className="text-gray-400 mb-1 text-xs outline-none">
          <div className="flex justify-between">
            {t(label)}
          </div>
        </label>
      )}
      <PhoneInput
        enableLongNumbers={26}
        disabled={disabled}
        specialLabel=""
        autoFormat={false}
        containerClass="w-full"
        inputProps={{
          name,
          type,
          'data-cy': dataCy,
        }}
        onChange={(x, y, e) => handleChange(e)}
        placeholder={placeholder || ''}
        value={(values && values[name].replace(' ', '')) || ''}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && handleOnEnter) {
            e.preventDefault()
            handleOnEnter()
          }
        }}
        inputClass={getUnderlineInputClasses(error?.props.error)}
      />
      <ErrorMessage error={error?.props.error} className="absolute left-0 -bottom-5 w-fit" />
    </div>
  )
}

export default PhoneNumberInputUnderline
