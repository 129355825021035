import { useTranslation } from 'react-i18next'
import { MdNoAccounts } from 'react-icons/md'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useBulkDeleteSupplierInvites from '../../hooks/useBulkDeleteSupplierInvites'
import { useCoordinateProvider } from '../../providers/CoordinateProvider'

const RemoveInvitesButton: React.FC = () => {
  const { t } = useTranslation()
  const { selectedInvites: [selectedInvites] } = useCoordinateProvider()

  const handleBunkDeleteSupplierInvites = useBulkDeleteSupplierInvites()

  return (
    <Button
      variant="solid"
      color="lhotse-orange"
      className="text-sm"
      onClick={handleBunkDeleteSupplierInvites}
      disabled={!selectedInvites.length}
      dataCy="coordinate-remove-invites-button"
    >
      <MdNoAccounts size={18} />
      <p className="text-xs whitespace-nowrap">{t('req_coordinate.remove_invited_suppliers')}</p>
    </Button>
  )
}

export default RemoveInvitesButton
