import { useAbility } from '@casl/react'
import CoreTopbarLayout from '../../../../core/components/layout/CoreTopbarLayout'
import usePermissions from '../../../../core/hooks/usePermissions'
import { REQUISITION_ADD } from '../../../../shared/constants/permissions'
import Topbar from './components/layout/Topbar'
import NewRequisitionContainer from './NewRequisitionContainer'
import FiltersProvider from './providers/FiltersProvider'
import { AbilityContext } from '../../../../core/providers/AbilityProvider'
import { ABILITIES } from '../../../../config/ability'

const NewRequisitionPage = () => {
  const permissions = usePermissions([{ slug: REQUISITION_ADD }])
  const ability = useAbility(AbilityContext)

  const topBar = permissions.get(REQUISITION_ADD).allowed && !ability.can(ABILITIES.submissionsOnly) ? <Topbar /> : null

  return (
    <FiltersProvider>
      <CoreTopbarLayout topbar={topBar}>
        <NewRequisitionContainer />
      </CoreTopbarLayout>
    </FiltersProvider>
  )
}

export default NewRequisitionPage
