import { FormQuestionType } from '../../../../types/Forms'
import useGetFormSubmission from '../hooks/useGetFormSubmission'
import QuestionError from './atoms/QuestionError'
import { MediaType } from '../../../../types/shared/MediaTypes'
import useFormUploads from '../hooks/useFormUploads'
import { useQuestionAnswersLimit } from './hooks/useQuestionAnswersLimit'
import { FormEvents } from '../Questions'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'
import FileField from '../../components/fields/FileField'

interface IFileQuestion {
  question: FormQuestionType
}

const FileQuestion: React.FC<IFileQuestion> = ({ question }) => {
  const {
    upload, remove, enabled,
  } = useFormUploads(question)
  const { canAdd, max: maxFiles } = useQuestionAnswersLimit(question)

  const { data: submissionFormData } = useGetFormSubmission()
  const isDisabled = useIsQuestionDisabled(question)

  const uploadDisabled = !enabled || isDisabled

  const canModify = (enabled && !submissionFormData?.submitted) || undefined

  const handleUpload = async (files: File[]) => {
    await upload(files)

    if (files.length + question.values.length === maxFiles) {
      FormEvents.QuestionAnswered.dispatch(question.__uuid)
    }
  }

  const mediaList = question.values.map(({ value }) => value as MediaType) || []

  return (
    <FileField
      mediaList={mediaList}
      onUpload={handleUpload}
      onDelete={({ uuid }) => remove(uuid)}
      disabled={uploadDisabled}
      canModify={canModify}
      canAdd
      errorMessage={<QuestionError question={question} />}
    />
  )
}

export default FileQuestion
