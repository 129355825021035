import { MdClose } from 'react-icons/md'
import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import { emptyMessageReply } from '../../../constants'

interface ICloseButton {
  inChat: boolean | undefined
}

const CloseButton: React.FC<ICloseButton> = ({
  inChat,
}) => {
  const { messageProperties: [, setMessageProperties] } = useMessengerContext()

  const setMessageReply = () => {
    setMessageProperties((messageProperties) => ({
      ...messageProperties,
      messageReply: emptyMessageReply,
    }))
  }

  if (!inChat) return null

  return (
    <button type="button" onClick={setMessageReply}>
      <MdClose className="text-xl mr-2 font-bold text-sky-blue" />
    </button>
  )
}

export default CloseButton
