import useChangeOfferStatus from './useChangeOfferStatus'
import useGetOffer from './useGetOffer'

export default () => {
  const changeOfferStatus = useChangeOfferStatus()
  const { data: offer } = useGetOffer()

  return () => {
    if (offer?.status === 'OFFER_SUBMITTED' || offer?.status === 'OFFER_DECLINED_BY_SUPPLIER') {
      changeOfferStatus('OFFER_IN_EDITING')
    }
  }
}
