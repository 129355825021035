import { FC } from 'react'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { AddressDataType } from '../../../types/shared/RequisitionDataTypes'
import useGetAddressData from '../../hooks/useGetAddressData'
import SelectInput from '../atoms/Input/SelectInput'

export interface IAddressSelect {
  onChange?: (address: InputSearchSelectItemType) => void
  onClear?: () => void
  selected?: AddressDataType
  label?: string
  disabled?: boolean
  isLoading?: boolean
}
const AddressSelect: FC<IAddressSelect> = ({
  selected, label, isLoading, onChange, onClear, disabled = false,
}) => {
  const { data: addressData } = useGetAddressData()

  const handleChange = (address: InputSearchSelectItemType) => {
    onChange && onChange(address)
  }

  const handleClear = () => {
    onClear && onClear()
  }

  const options = addressData?.map((address) => ({
    id: address.id,
    label: `${address.name} - ${address.city}`,
  })) || []

  return (
    <SelectInput
      label={label}
      disabled={disabled}
      isLoading={isLoading}
      onClear={handleClear}
      currentSelection={(selected && { id: selected?.id, label: `${selected.name} - ${selected.city}` }) || undefined}
      onChange={handleChange}
      options={options}
      placeholder="Enter an address"
    />
  )
}

export default AddressSelect
