import { ArrowLeftIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { FC, useState } from 'react'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import Input from '../../../../../../shared/components/atoms/Input/Input'
import SearchSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import Table from '../../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import TabbedNav from '../../../../../../shared/components/layout/TabbedNav/TabbedNav'
import { priceToGermanPriceString } from '../../../../../../shared/constants/currencyFormat'
import useUnite from '../../../../hooks/useUniteDetails'
import UniteSearchStockStatus from './UniteSearchStockStatus'
import { LoopSectionFieldValues } from '../../../hooks/useLoopSection'

interface IUniteSearchModalDetails {
  activeItem: string | undefined
  setActiveItem: (itemId: string | undefined) => void
  onAdd: (data: LoopSectionFieldValues) => void
}

const UniteSearchModalDetails: FC<IUniteSearchModalDetails> = ({ setActiveItem, activeItem, onAdd }) => {
  const { item } = useUnite(activeItem)
  const [quantity, setQuantity] = useState<number>(1)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [activeImage, setActiveImage] = useState<number>(0)

  const handleChangeQuantity = (newQuantity: number) => {
    if (newQuantity < 0) return
    setQuantity(newQuantity)
  }

  if (item.isLoading) return <div>Loading...</div>

  const { data } = item

  const handleAdd = () => {
    onAdd({ 'prod-name': { value: item.data.name }, 'prod-quantity': { value: quantity } })
  }

  return (

    <div>
      <Button className="btn-ghost" onClick={() => setActiveItem(undefined)}>
        <ArrowLeftIcon className="w-4" />
        {' '}
        Back
      </Button>
      <div className="grid grid-cols-2">
        <div className="flex flex-col justify-start items-between ">
          <div className=" flex justify-center p-8">
            <img className="h-96" src={data.images?.[activeImage]?.url} alt={data.description} />
          </div>
          <div className="h-24 flex items-center space-x-4 overflow-x-auto max-w-full overflow-y-hidden pr-8">
            {data.images.map((image: { url: string }, idx: number) => (
              <div
                key={image.url}
                role="button"
                tabIndex={-1}
                onClick={() => setActiveImage(idx)}
                className="w-20 h-20 cursor-pointer flex items-center"
              >
                <img src={image.url} alt={image.url} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-between space-y-8">
          <div className="flex flex-col">
            <UniteSearchStockStatus inStock={data.inStock} />
            <div className="font-semibold text-lg max-h-24 line-clamp-3">{data.name}</div>
            <div className="lead-6 font-semibold text-black-light-2">{data.manufacturer}</div>
          </div>

          <div className="w-full flex gap-y-2 items-end">

            <div className="flex space-x-4">
              <BaseInputWrapper label="Quantity">
                <div className="flex items-center  base-form-input rounded-lg">
                  <Button
                    className="rounded-r-none h-full bg-none "
                    onClick={() => handleChangeQuantity(quantity - 1)}
                  >
                    <MinusIcon className="w-4" />
                  </Button>
                  <Input
                    className="text-center border-none rounded-none"
                    value={quantity}
                    type="number"
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => handleChangeQuantity(parseInt(e.currentTarget?.value, 10) || 1)}
                  />
                  <Button
                    className=" rounded-l-none bg-none h-full outline-none focus:ring-0 focus:ring-offset-0
"
                    onClick={() => handleChangeQuantity(quantity + 1)}
                  >
                    <PlusIcon className="w-4" />
                  </Button>
                </div>
              </BaseInputWrapper>
              <Input label="Price" disabled value={`${priceToGermanPriceString(data.price)} {${data.orderUnit}}`} />
              <Input label="VAT" disabled value={`${data.vat * 100} %`} />
            </div>

          </div>
          <div className="flex flex-col gap-y-4">
            <BaseInputWrapper label="Select costcenter">
              <SearchSelect options={[{ id: '123', label: '040 Material Purchasing' }]} onChange={() => console.log('select!')} />
            </BaseInputWrapper>
            <BaseInputWrapper label="Select delivery address">
              <SearchSelect options={[{ id: '123', label: '040 Material Purchasing' }]} onChange={() => console.log('select!')} />
            </BaseInputWrapper>
          </div>
          <div><Button disabled={!data.inStock} className=" btn-primary w-full" onClick={handleAdd}>Add</Button></div>
          <div className="flex justify-between w-full text-sm">
            <div>
              Delivered in
              {' '}
              {data.deliveryTime}
              {' '}
              days
            </div>
            <div>
              Shipping costs:
              {' '}
              {priceToGermanPriceString(data.shippingCosts)}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-6">
        <TabbedNav
          onTabChange={(index) => setActiveTab(index)}
          items={[
            { title: 'Description', tabIndex: 0, current: activeTab === 0 },
            { title: 'Product details', tabIndex: 1, current: activeTab === 1 },
            { title: 'Documents', tabIndex: 2, current: activeTab === 2 },
          ]}
        />
        {activeTab === 0 && (
          <div className="mt-6 text-sm" dangerouslySetInnerHTML={{ __html: data.description }} />
        )}
        {activeTab === 1 && (
          <div className="mt-6  ">
            <TableContainer border={false}>
              <Table>
                {[
                  { label: 'Article Id', attribute: 'manufacturerArticleId' },
                  { label: 'Classification Id', attribute: 'classificationId' },
                  { label: 'GTIN', attribute: 'gtin' },
                ].map((row) => (
                  <TableRow key={row.attribute}>
                    <TableCell className="font-semibold border-t-none">{row.label}</TableCell>
                    <TableCell>{data[row.attribute]}</TableCell>
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
        )}
        {activeTab === 2 && (
          <div className="mt-6  ">
            Hello
          </div>
        )}
      </div>
    </div>

  )
}

export default UniteSearchModalDetails
