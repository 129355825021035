import { useQuery } from 'react-query'
import { apiFetchRequisitionApproval } from '../../../services/RequisitionService'
import queryKeys from '../../Definition/constants/queryKeys'
import useShowApproval from '../../../components/layout/ReqTopbar/hooks/useShowApproval'
import useGetRequisitionData from '../../Definition/hooks/useGetRequisition'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'

const useShowCoordinate = () => {
  const requisitionId = useGetRequisitionId()

  const { data: requisitionData, isLoading } = useGetRequisitionData()

  const { data } = useQuery(
    [queryKeys.requisitionApproval, requisitionId],
    () => apiFetchRequisitionApproval(requisitionId),
  )
  const approvalForCoordinate = useShowApproval('coordinate', requisitionData?.status || 'DRAFT')

  const approvalPending = data?.approval_status === 'needed_not_approved' && approvalForCoordinate

  const approvalPermission = !!data?.can_approve

  return { data: approvalPermission || !approvalPending, isLoading }
}

export default useShowCoordinate
