import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import LoginContainerLoginForm from './components/LoginContainerLoginForm'
import SSOProviders from './components/SSOProviders'
import useGetSSOProviders from './hooks/useGetSSOProviders'

const Login = () => {
  const { data: ssoProviders, isLoading: isLoadingSSOProviders, isError: failedToLoadLoginOptions } = useGetSSOProviders()

  if (isLoadingSSOProviders) return <CoreLoadingIcon />

  return (
    <div className="flex flex-col gap-y-6">
      <SSOProviders />
      {((ssoProviders && ssoProviders?.data?.includes('email')) || failedToLoadLoginOptions) && <LoginContainerLoginForm />}
    </div>
  )
}
export default Login
