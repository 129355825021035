import { FC } from 'react'
import { Link } from 'react-router-dom'
import { VerticalNavItemType } from '../../../../types/shared/VerticalNavItemType'

const VerticalNavItem: FC<VerticalNavItemType> = ({
  title, children, link, disabled,
}) => {
  const isActive: boolean = window.location.href.search(link) > -1

  return (
    <li>
      <span className={`${isActive ? ' text-sky-blue-light font-semibold' : null} ${disabled ? 'cursor-not-allowed' : ''}`}>
        {disabled
          ? <span className="text-rock-gray">{title}</span>
          : (
            <Link to={link}>
              {title}
            </Link>
          )}
      </span>
      <ul className="pl-2 space-y-1 mt-2">
        {children?.map((child) => (
          <VerticalNavItem
            key={child.link}
            {...child}
          />
        ))}
      </ul>
    </li>
  )
}

export default VerticalNavItem
