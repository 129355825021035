import useGetFormData from '../../../hooks/useGetFormData'
import AutosaveIndicator from '../../FormsPage/AutosaveIndicator'
import FormTitleInput from './FormTitleInput'
import PublishFormButton from './PublishFormButton'
import ViewFormButton from './ViewFormButton'

interface IFormBuilderTopbar {
  disabled: boolean,
}

const FormBuilderTopbar: React.FC<IFormBuilderTopbar> = ({ disabled }) => {
  const { data: companyForm } = useGetFormData()

  return (
    <div className="flex justify-between pb-3 items-center w-full">
      <FormTitleInput disabled={disabled} />
      <div className="gap-x-12 flex items-center">
        <AutosaveIndicator />
        {!companyForm?.published
          ? <PublishFormButton />
          : <ViewFormButton />}
      </div>
    </div>
  )
}
export default FormBuilderTopbar
