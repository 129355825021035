import toast, { Toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { MdClose, MdErrorOutline, MdInfoOutline } from 'react-icons/md'

interface IToastBase {
  alertStyles: { bgColor: string, textColor: string },
  translationString?: string, iconNum: number, id: any,
  toastElement: Toast
  additionalTextValue?: string
}

const ToastBase: React.FC<IToastBase> = ({
  alertStyles, translationString, iconNum, id, toastElement, additionalTextValue,
}) => {
  const { t } = useTranslation()

  const iconStyles = `${alertStyles.textColor} text-xl`

  const iconPicker = (iconNumber: number) => {
    switch (iconNumber) {
      case 2: return <MdErrorOutline className={iconStyles} />
      case 3: return <MdInfoOutline className={iconStyles} />
      case 1:
      default:
        return <IoMdCheckmarkCircleOutline className={iconStyles} />
    }
  }
  return (
    <div className={`${toastElement.visible ? 'animate-enter' : 'animate-leave'} max-w-md w-full ${alertStyles.bgColor}  drop-shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`} data-cy={`toastr-${translationString}`}>
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          {iconPicker(iconNum)}
          <div className="ml-3 flex-1">
            <p className={`text-sm font-medium ${alertStyles.textColor}`} data-cy="toastr-text">
              {`${translationString ? t(translationString) : t('error.oops_generic')} ${additionalTextValue || ''}`}
            </p>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => toast.remove(id || translationString)}
        className={`${alertStyles.textColor} items-center flex justify-center bg-transparent p-4 z-50`}
      >
        <MdClose />
      </button>
    </div>
  )
}

const ToastBaseWrapper = (
  alertStyles: { bgColor: string, textColor: string },
  translationString: string,
  iconNum: number,
  id: any,
  additionalTextValue?: string,
) => toast.custom(
  (toastElement) => (
    <ToastBase
      alertStyles={alertStyles}
      translationString={translationString}
      iconNum={iconNum}
      id={id}
      toastElement={toastElement}
      additionalTextValue={additionalTextValue}
    />
  ),
  {
    position: 'bottom-right',
    duration: 4000,
    id,
  },
)
const toastr = {
  success: (translationString: string, id?: any, additionalTextValue?: string) => ToastBaseWrapper({ bgColor: 'bg-green-100', textColor: 'text-green-600' }, translationString, 1, id || translationString, additionalTextValue),
  error: (translationString?: string, id?: any, additionalTextValue?: string) => ToastBaseWrapper({ bgColor: 'bg-red-100', textColor: 'text-red-600' }, translationString || 'error.oops_generic', 2, id || translationString || 'generic.error', additionalTextValue),
  info: (translationString: string, id?: any, additionalTextValue?: string) => ToastBaseWrapper({ bgColor: 'bg-blue-100', textColor: 'text-sky-blue' }, translationString, 3, id, additionalTextValue),
}

export default toastr
