import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useFormField } from '../providers/FormFieldProvider'
import useSearchSelectOptions from '../../../shared/hooks/useSearchSelectOptions'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'

export const FormFieldSearchSelect = () => {
  const { field: { value, input, disabled }, setValues } = useFormField()
  const { t } = useTranslation()

  const {
    options, selected, selectedToOption, visibleColumns,
  } = useSearchSelectOptions(
    { values: value ? [value] : [], inputId: input.uuid, ...input },
  )

  // If the ID of the selected option changes, update the form. Checking for ID prevents an infinite loop
  useEffect(() => {
    if (selected?.id) {
      setValues(selectedToOption(selected))
    } else {
      // reset the question value if the selected option is null
      setValues([])
    }
  }, [selected?.id])

  if (input.type !== 'SEARCH_SELECT') return null

  const handleSelect = (currentSelection: InputSearchSelectItemType) => {
    if (!currentSelection) return
    setValues(selectedToOption(currentSelection))
  }

  return (
    <SearchSelect
      disabled={disabled}
      onChange={handleSelect}
      options={options}
      currentSelection={selected}
      placeholder={t('question.search_option')}
      visibleColumns={visibleColumns}
    />
  )
}

export default FormFieldSearchSelect
