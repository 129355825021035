import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'
import { FormSubmissionType } from '../../../../../../types/Forms/formTypes'
import { apiDeleteSubmission } from '../../../../services/BasecampService'
import useGetSubmissionsData from './useGetSubmissionsData'

const useDeleteSubmission = () => {
  const { refetch } = useGetSubmissionsData()
  const cache = useQueryClient()

  const { modal } = useUtils()

  return useMutation(
    (submission: FormSubmissionType) => apiDeleteSubmission(submission.form.uuid, submission.uuid),
    {
      onMutate: async (submission) => {
        await cache.cancelQueries('form_submissions')

        const previousSubmissions = cache.getQueryData<FormSubmissionType[]>('form_submissions')

        cache.setQueryData<FormSubmissionType[] | undefined>(
          'form_submissions',
          previousSubmissions?.filter((item) => item.uuid !== submission.uuid),
        )

        return { previousSubmissions }
      },
      onSuccess: () => {
        refetch()
          .then(() => {
            toastr.success('success.item_deleted', 'successRequisitionDeleted')
            modal.set({ isOpen: false })
          })
      },
      onError: () => {
        toastr.error('error.failed_to_delete', 'errorReqDuplicate')
      },
    },
  )
}

export default useDeleteSubmission
