import { useTranslation } from 'react-i18next'
import SupplierUserType from '../../../../types/Suppliers/SupplierUserType'
import generateUserName from '../../../utils/generateUserName'
import Button from '../../atoms/buttons/Button'
import UserAvatar from '../../atoms/UserAvatar/UserAvatar'

interface IAssigneeButton {
  user: SupplierUserType
  onClick: () => void
}

const AssigneeButton: React.FC<IAssigneeButton> = ({ user, onClick }) => {
  const { t } = useTranslation()
  const userName = t(generateUserName(user))

  return (
    <button
      onClick={onClick}
      type="button"
      className="gap-2 text-sm text-gray-900 hover:text-sky-blue px-0 py-0 justify-start flex"
    >
      <UserAvatar name={user.email} />
      {userName}
    </button>
  )
}

export default AssigneeButton
