import { useEffect } from 'react'
import { scrollToMessage, scrollToBottomOfChat } from '../utils'
import useGetMessages from './useGetMessages'
import useGetMessengerUrlParams from './useGetMessengerUrlParams'

export default () => {
  const { messageReferenceId } = useGetMessengerUrlParams()

  const { data: messages } = useGetMessages()

  useEffect(() => {
    if (messageReferenceId) {
      scrollToMessage(messageReferenceId)
    } else {
      scrollToBottomOfChat()
    }
  }, [messages])
}
