import { useTranslation } from 'react-i18next'
import MediaTable from '../../../../shared/components/atoms/FileUpload/MediaTable'
import MediaUploadButton from '../../../../shared/components/atoms/MediaUploadButton'
import { MediaType } from '../../../../types/shared/MediaTypes'

type FileFieldProps = {
  mediaList: MediaType[]
  onUpload?: (files: File[]) => void
  onDelete?: (media: MediaType) => void
  disabled: boolean
  canModify: true | undefined
  canAdd: boolean
  errorMessage: React.ReactNode
}

const FileField: React.FC<FileFieldProps> = ({
  mediaList,
  onUpload,
  onDelete,
  disabled,
  canModify,
  canAdd,
  errorMessage,
}) => {
  const { t } = useTranslation()

  return (
    <div className={disabled ? 'cursor-not-allowed' : ''}>
      {mediaList.length > 0 && (
        <MediaTable disabled={disabled} media={mediaList} onDelete={canModify && onDelete} />
      )}
      <div className="mt-4">{onUpload && canModify && canAdd && <MediaUploadButton onUpload={onUpload} />}</div>
      <span className="italic">{mediaList.length === 0 && disabled && t('question.file_cannot_upload_when_draft')}</span>
      {errorMessage}
    </div>
  )
}

export default FileField
