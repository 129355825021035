import { useTranslation } from 'react-i18next'
import { MdArrowForwardIos } from 'react-icons/md'
import { useFormSection } from './providers/FormSectionProvider'
import { useFormContext } from '../../../core/providers/FormProvider'

const NextSectionButton = () => {
  const { t } = useTranslation()

  const { responseConfig: [responseConfig] } = useFormContext()
  const { goToNextSection, currentSection } = useFormSection()

  if (!currentSection?.nextSection || responseConfig?.viewType === 'form') return null

  return (
    <button
      type="button"
      className="disabled:opacity-25 bg-sky-blue-light hover:bg-sky-blue transition-colors
       px-6 text-sm py-2.5 text-white rounded-md flex gap-x-2 items-center w-fit"
      onClick={goToNextSection}
    >
      <p className="whitespace-nowrap">
        {t('forms.next_section')}
      </p>
      <MdArrowForwardIos />
    </button>
  )
}

export default NextSectionButton
