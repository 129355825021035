import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import useGetOffer from '../../../hooks/useGetOffer'
import BannerModal from './BannerModal'

const BannerChangesRequested: React.FC = () => {
  const { t } = useTranslation()
  const utils = useUtils()

  const { data: offerData } = useGetOffer()
  const reason = offerData?.status_timeline[0]?.reason

  return (
    <button
      type="button"
      className="flex text-left py-3 px-8 bg-yellow-100 bg-opacity-80 w-full"
      onClick={() => utils.modal.set({
        isOpen: true,
        title: t('generic.changes_requested'),
        content: <BannerModal reason={reason} changesRequested />,
      })}
    >
      <p>{t('supplier_offer.changes_were_requested')}</p>
    </button>
  )
}

export default BannerChangesRequested
