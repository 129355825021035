import { t } from 'i18next'
import { MdOutlineFolder } from 'react-icons/md'
import TipTapReadOnly from '../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import { SectionOrLineItemType } from './hooks/useGetSectionsOrLineItems'

interface ITitle {
  sectionIndex: number
  section: SectionOrLineItemType
}

const Title: React.FC<ITitle> = ({
  sectionIndex, section,
}) => (
  <div className={`flex gap-x-2 pt-5 pb-2 ${sectionIndex === 0 && 'pt-0'}`}>
    <MdOutlineFolder size={20} className="text-gray-300" />
    <TipTapReadOnly
      text={section?.description || `${t('requisition_definition.section')} ${sectionIndex + 1}`}
      className="w-full"
    />
  </div>
)

export default Title
