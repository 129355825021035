import { apiGet, apiGetData } from '../../../core/utils/api/generic'
import { ExternalDataColumn, ExternalDataListEntity } from '../../../types/ExternalDataManager/ExternalDataManagerTypes'

// external-data-manager/entities
export const apiListExternalDataEntities = async () => apiGet<ExternalDataListEntity[]>('external-data-manager/entities')

// external-data-manager/entity/{{fileUuid}}/columns
export const apiListExternalDataEntityColumns = async (fileUuid: string) => apiGet<ExternalDataColumn[]>(`external-data-manager/entity/${fileUuid}/columns`)

// external-data-manager/query/{{fileUuid}}{{query_string}}, where query_string looks like "?{column}=eq.Some Value"
export const apiGetExternalDataEntityData = async (
  fileUuid: string,
  queryString?: string,
) => apiGet(`external-data-manager/query/${fileUuid}${queryString}`)
