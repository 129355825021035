// REFACTOR
import { useAbility } from '@casl/react'
import { Tab } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MdAllInbox, MdHistory, MdStarRate, MdUploadFile,
} from 'react-icons/md'
import { useHistory, useParams } from 'react-router'
import { ABILITIES } from '../../../../config/ability'
import CoreContainerLayout from '../../../../core/components/layout/CoreContainerLayout'
import CorePageContainerLayout from '../../../../core/components/layout/CorePageContainerLayout'
import usePermissions from '../../../../core/hooks/usePermissions'
import { AbilityContext } from '../../../../core/providers/AbilityProvider'
import { FORM_SUBMIT, REQUISITION_ADD } from '../../../../shared/constants/permissions'
import NewReqCatalogTab from './components/Catalog/NewReqCatalogTab'
import NewRequisitionTabHeader from './components/NewRequisitionTabHeader'
import NewReqPastRequisitionTab from './components/PastRequisitions/NewReqPastRequisitionTab'
import FormsTab from './FormsTab/FormsTab'
import { useFilters } from './providers/FiltersProvider'
import NewReqRecommendedCatalog from './components/Catalog/NewReqRecommendedCatalog'
import NewReqRecommendedPastReq from './components/PastRequisitions/NewReqRecommendedPastReq'
import FormsRecommendation from './FormsTab/FormsRecommendation'

const NewRequisitionContainer = () => {
  const { t } = useTranslation()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const history = useHistory()

  const { tab: currentTab } = useParams<{ tab: string }>()

  const permissions = usePermissions([{ slug: REQUISITION_ADD }, { slug: FORM_SUBMIT }])

  const ability = useAbility(AbilityContext)
  const { query: [query] } = useFilters()

  const allTabs = [
    {
      title: t('RECOMMENDED'),
      icon: <MdStarRate />,
      key: 'recommendation',
      hidden: !permissions.get(REQUISITION_ADD).allowed || ability.can(ABILITIES.submissionsOnly),
    },
    {
      title: t('new_requisition.previous_requisitions'),
      icon: <MdHistory />,
      key: 'requisitions',
      hidden: !permissions.get(REQUISITION_ADD).allowed || ability.can(ABILITIES.submissionsOnly),
    },
    {
      title: 'Forms',
      icon: <MdUploadFile />,
      key: 'forms',
      hidden: !permissions.get(FORM_SUBMIT).allowed,
    },
    {
      title: t('requisition.catalogue_item'),
      icon: <MdAllInbox />,
      key: 'catalogs',
      hidden: !ability.can(ABILITIES.mercateoSearch),
    },
  ]

  const availableTabs = allTabs.filter((tab) => !tab.hidden)

  const currentTabIndex = availableTabs.findIndex((tab) => tab.key === currentTab)

  useEffect(() => {
    if (currentTabIndex === -1 && availableTabs.length > 0) {
      history.replace(`/new/${availableTabs[0].key}`)
    }
  }, [permissions])

  return (
    <CorePageContainerLayout className="flex overflow-y-auto gap-4">
      <CoreContainerLayout fit>
        <Tab.Group selectedIndex={selectedTabIndex} onChange={setSelectedTabIndex}>
          <Tab.List className="flex border-b border-gray-200 items-center justify-between px-4 ">
            <div className="flex">
              {availableTabs.map((tab, index) => (
                <NewRequisitionTabHeader
                  icon={tab.icon}
                  key={tab.key}
                  link={tab.key}
                  selected={tab.key === currentTab || (currentTab === undefined && index === 0)}
                >
                  {tab.title}
                </NewRequisitionTabHeader>
              ))}
            </div>
          </Tab.List>
          <Tab.Panels className="flex h-full">
            {(currentTab === 'recommendation' || !currentTab) && (
              <div className="flex flex-col p-8 w-full">
                {query
                  ? (
                    <>
                      <NewReqRecommendedPastReq selectTab={() => setSelectedTabIndex(1)} />
                      <NewReqRecommendedCatalog selectTab={() => setSelectedTabIndex(2)} />
                      <FormsRecommendation />
                    </>
                  )
                  : (
                    <div className="flex w-full h-full justify-center items-center p-8">
                      {t('new_requisition.type_for_results')}
                    </div>
                  )}
              </div>
            )}

            {currentTab === 'requisitions' && (
              <div className="flex w-full h-full p-8">
                <NewReqPastRequisitionTab />
              </div>
            )}

            {currentTab === 'forms' && <div className="p-8 w-full"><FormsTab /></div>}
            {currentTab === 'catalogs' && (
              <div className="flex w-full h-full p-8">
                <NewReqCatalogTab />
              </div>
            )}
          </Tab.Panels>
        </Tab.Group>
      </CoreContainerLayout>
    </CorePageContainerLayout>
  )
}
export default NewRequisitionContainer
