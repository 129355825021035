import { EdgeProps, useReactFlow } from 'reactflow'
import React from 'react'
import WorkflowChartEdge from './WorkflowChartEdge'
import { WorkflowChartAddGroupButton } from '../WorkflowChartNodes/components/WorkflowChartAddGroupButton'
import { ADD_BUTTON_SIZE } from '../../../Intake/helpers'
import { useWorkflowChart } from '../../providers/WorkflowChartProvider'

export const WorkflowChartEdgeWithExtendButton = (props: EdgeProps) => {
  const { addStep } = useWorkflowChart()
  const { getNode } = useReactFlow()
  const { target } = props

  const handleClick = () => {
    const at = getNode(target)?.data?.position
    if (!at) return
    addStep(at)
  }

  return (
    <WorkflowChartEdge
      {...props}
      labelWidth={ADD_BUTTON_SIZE}
      labelHeight={ADD_BUTTON_SIZE}
    >
      <WorkflowChartAddGroupButton onClick={handleClick} />
    </WorkflowChartEdge>
  )
}

export default WorkflowChartEdgeWithExtendButton
