import Questions from '../Questions'

export const PlainFormSection = () => (
  <div className="px-4">
    <Questions />
  </div>

)

export default PlainFormSection
