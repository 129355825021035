import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { UseMutateFunction } from 'react-query'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import BasecampRequisitionRowType from '../../../../types/Basecamp/BasecampRequisitionRowType'

interface IInfiniteScrollWithScroller {
  data: BasecampRequisitionRowType[]
  queryPage?: number
  getMoreData: (() => Promise<any>) | UseMutateFunction<any, unknown, void, unknown>
  rowComponent: React.ComponentType<any>
  showEndMessage?: boolean
  hasNextPage?: boolean
}

const InfiniteScrollWithScroller: React.FC<IInfiniteScrollWithScroller> = ({
  data, getMoreData, hasNextPage,
  rowComponent: RowComponent,
}) => {
  const { t } = useTranslation()
  if (!data.length) return null

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={getMoreData}
      hasMore={!!hasNextPage}
      loader={(
        <div className="w-full flex justify-center mt-2 mb-2">
          <CoreLoadingIcon />
        </div>
      )}
      endMessage={(
        <div className="flex w-full justify-center mt-3 mb-3">
          {t('generic.end_of_scroll')}
        </div>
      )}
      pullDownToRefreshThreshold={10}
    >
      <div className="flex flex-col gap-y-2 " data-cy="infiniteScroll-Table">
        {data?.map((page) => (
          page?.data?.map((row) => (
            <RowComponent
              data={row}
              key={row.id}
            />
          ))))}
      </div>
    </InfiniteScroll>
  )
}

export default InfiniteScrollWithScroller
