import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import NoResults from '../../../../shared/components/atoms/display/NoResults'
import useGetSearchedSuppliers from '../../hooks/useGetSearchedSuppliers'
import SupplierTableRow from './SupplierTableRow/SupplierTableRow'

interface ISuppliersSearchResults {
  searchQuery: string
}
const SuppliersSearchResults: React.FC<ISuppliersSearchResults> = ({ searchQuery }) => {
  const { data: supplierData, isLoading } = useGetSearchedSuppliers(searchQuery)

  if (isLoading) {
    return (
      <div className="w-full flex justify-center h-96 items-center">
        <CoreLoadingIcon />
      </div>
    )
  }

  if (!isLoading && !supplierData?.data?.length) {
    return <NoResults />
  }

  return (
    <div className="flex flex-col gap-y-2 pb-4" data-cy="search-table">
      {supplierData?.data.map((data) => (
        <SupplierTableRow
          data={data}
          key={data.id}
        />
      ))}
    </div>
  )
}

export default SuppliersSearchResults
