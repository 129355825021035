import { mapValues, set, keyBy } from 'lodash'
import { ValidationError } from 'yup'
import { getQuestionValues } from './formValidationSchema'
import { FormQuestionType } from '../../../../types/Forms'
import { generateYupSchema } from './programaticYup'

export type OfferingError = { name: 'offer_required' | 'offer_not_selected'}
export type ErrorsIndex = { [key: string | 'offerings']: ValidationError | ValidationError[] | ErrorsIndex | OfferingError };
export type FormError = ValidationError | ValidationError[] | ErrorsIndex | OfferingError
export const assertIsPlainValidationError = (error: FormError | undefined): error is ValidationError => !!error && !Array.isArray(error) && error.name === 'ValidationError' && typeof error.path === 'string'
export const assertIsValidationErrorsArray = (error: FormError | undefined): error is ValidationError[] => !!error && Array.isArray(error) && (error.length > 0 && error.every((singleError) => singleError.name === 'ValidationError' && typeof singleError.path === 'string'))
export const assertIsErrorsIndex = (error: FormError | undefined): error is ValidationError => !assertIsPlainValidationError(error)

export const getFirstError = (error: ValidationError | ValidationError[] | ErrorsIndex | OfferingError | undefined): ValidationError | undefined => {
  if (!error) return undefined

  if (assertIsPlainValidationError(error)) return error

  if (assertIsValidationErrorsArray(error)) return error[0]

  return Object.values(error).find(getFirstError) as ValidationError | undefined
}
export const validate = (allQuestions: FormQuestionType[], questionsToValidate?: FormQuestionType[]): ErrorsIndex | null => {
  const values = getQuestionValues(allQuestions)
  const validator = generateYupSchema(questionsToValidate || allQuestions)

  try {
    validator.validateSync(values, { abortEarly: false, context: keyBy(allQuestions, '__uuid') })
    return null
  } catch (validationError) {
    if (!(validationError instanceof ValidationError)) return null

    const errors = mapValues(
      validationError.inner
        .filter((error) => !!error.path)
        .reduce((errors, error) => set(errors, error.path!, error), {}),
      (error: ValidationError | ValidationError[]) => (Array.isArray(error) ? error.filter(() => true) : error),
    )

    return Object.keys(errors).length > 0 ? errors : null
  }
}

export default validate
