// REFACTOR
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import TipTapReadOnly from '../../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import ItemAttribute from './ItemAttribute'
import { useOfferVisibilityContext } from '../../providers/OfferVisibilityProvider'

type OfferViewSectionType = {
  id: string | number;
  description: string;
  section_type: string;
  item_attributes: Partial<LineItemItemAttributeType>[];
}

interface IOfferViewSection {
  section: OfferViewSectionType
}

const OfferViewSection: React.FC<IOfferViewSection> = ({ section }) => {
  const { t } = useTranslation()
  const [dropdownExpanded, setDropdownExpanded] = useState(true)
  const sectionDescription = section?.description

  const itemAttributes = (section.item_attributes as LineItemItemAttributeType[]).filter((itemAttribute) => itemAttribute.type !== 'attribute')

  // Use visibility context to set width of section
  const { visibleOffers } = useOfferVisibilityContext()

  return (
    <div className={`${visibleOffers.length > 1 ? 'w-full' : ''} h-fit mx-8`}>
      <div className="px-5">
        <button
          className="flex items-center justify-between sticky pl-[8px] left-0"
          onClick={() => setDropdownExpanded((expanded) => !expanded)}
          type="button"
        >
          <TipTapReadOnly text={t(sectionDescription)} className="text-xl my-2 text-left h-full break-all" />
          <div className="text-xl pr-3.5 text-gray-400 flex items-center">
            {dropdownExpanded ? <MdExpandLess size={25} /> : <MdExpandMore size={25} />}
          </div>
        </button>
      </div>
      <div className="flex flex-col bg-white rounded-md w-fit mb-4">
        {dropdownExpanded
          ? itemAttributes.map((itemAttribute, index) => (
            <ItemAttribute
              itemAttribute={itemAttribute}
              lastItem={index === section.item_attributes.length - 1}
              key={itemAttribute.id}
            />
          ))
          : <span className="bg-white h-2 rounded-md" />}
      </div>
    </div>
  )
}

export default OfferViewSection
