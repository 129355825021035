// REFACTOR
import { useTranslation } from 'react-i18next'

interface ITextMidLineTitle {
  children: string,
}

const TextMidLineTitle: React.FC<ITextMidLineTitle> = ({
  children,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={`flex w-full justify-center relative
      after:content-['']
      after:block
      after:w-full
      after:h-px
      after:bg-gray-400
      after:absolute
      after:right-0
      after:top-1/2

      before:content-['']
      before:block
      before:w-1/2
      before:h-px
      before:bg-gray-400
      before:absolute
      before:left-0
      before:top-1/2
`}
    >
      <span className="bg-white z-10 px-2">
        {t(children)}
      </span>
    </div>
  )
}

export default TextMidLineTitle
