import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { ReactComponent as LhotseIcon } from '../../../../../core/assets/logo-icon-lhotse-nofill.svg'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import BaseInputWrapper from '../../../../../shared/components/atoms/Input/BaseInputWrapper'
import { WorkflowBlockTask } from '../../../../../types/Workflow/WorkflowBlock'
import Input from '../../../../../shared/components/atoms/Input/Input'
import ToggleButton from '../../../../../shared/components/atoms/buttons/ToggleButton'
import { ToggleOption } from '../../../../../types/shared/ToggleButtonTypes'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'

interface IWorkflowActionFormProps {
  onConfirm: (task: WorkflowBlockTask, taskIndex?: number) => void
  selectedTask?: WorkflowBlockTask
  taskIndex?: number
  readonly?: boolean
}

const Icon: React.ReactNode = <LhotseIcon className="w-5 h-5 !fill-black" />

const WorkflowActionForm: React.FC<IWorkflowActionFormProps> = ({
  onConfirm, selectedTask, readonly, taskIndex,
}) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const [showTaskForm, setShowTaskForm] = useState(!!selectedTask)

  const draftTask: WorkflowBlockTask = {
    name: '',
    description: '',
    required: 1,
    assignees: [],
  }
  const [task, setTask] = useState(selectedTask || draftTask)

  const actionTypeOptions: InputSearchSelectItemType[] = [{
    id: 'lhotse',
    label: 'Lhotse',
    icon: Icon,
  }]

  const actionOptions: InputSearchSelectItemType[] = [{
    id: 'task-action',
    label: 'Create a task',
  }]

  const toggleButtonOptions: ToggleOption[] = [{
    id: 'required',
    label: 'Yes',
  }, {
    id: 'optional',
    label: 'No',
  }]

  const onConfirmButtonClick = () => {
    onConfirm(task, taskIndex)
    modal.set({ isOpen: false })
  }

  const handleActionOptionChange = (option: any) => {
    if (option.id === 'task-action') {
      setShowTaskForm(true)
    } else {
      setShowTaskForm(false)
    }
  }

  const handleInput = (e: any) => {
    setTask({ ...task, [e.target.name]: e.target.value })
  }

  const handleToggleButton = (option: ToggleOption) => {
    setTask({ ...task, required: option.id === 'required' ? 1 : 0 })
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <BaseInputWrapper label={t('actions.action_type', 'Action type')}>
          <SearchSelect
            onChange={() => {}}
            placeholder={t('actions.action_type_placeholder', 'Select action type')}
            options={actionTypeOptions}
            currentSelection={selectedTask ? actionTypeOptions[0] : undefined}
            disabled={readonly}
          />
        </BaseInputWrapper>
        <BaseInputWrapper label={t('actions.select_an_action', 'Select an action')}>
          <SearchSelect
            onChange={handleActionOptionChange}
            placeholder={t('actions.select_an_action', 'Select an action')}
            options={actionOptions}
            currentSelection={selectedTask ? actionOptions[0] : undefined}
            disabled={readonly}
          />
        </BaseInputWrapper>
      </div>
      {showTaskForm && (
        <div className="flex flex-col gap-4">
          <Input
            label={t('actions.name', 'Name')}
            value={task.name}
            onChange={handleInput}
            placeholder={t('actions.name_placeholder', 'Enter a name for the task')}
            name="name"
            id="name"
            disabled={readonly}
          />
          <Input
            label={t('actions.description', 'Description')}
            value={task.description}
            onChange={handleInput}
            placeholder={t('actions.description_placeholder', 'Enter a description for the task')}
            name="description"
            id="description"
            disabled={readonly}
          />
          <BaseInputWrapper label={t('actions.assignees', 'Assignees')}>
            <SearchSelect
              onChange={() => {}}
              disabled // TODO: implement assignees, irrelevant for now
                // because assignees are always the same as the block assignees
              placeholder={t('actions.assignees_placeholder', 'Select assignees')}
            />
          </BaseInputWrapper>
          <BaseInputWrapper label={t('actions.required', 'Required')}>
            <div className="flex">
              <ToggleButton
                options={toggleButtonOptions}
                onChange={handleToggleButton}
                selectedOptionId={task.required ? 'required' : 'optional'}
                disabled={readonly}
              />
            </div>
          </BaseInputWrapper>
        </div>
      )}
      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          {/* <Button variant="ghost" className="text-black-light-2 bg-transparent !p-0" onClick={() => {}}> */}
          {/*  {t('common.Delete', 'Delete')} */}
          {/* </Button> */}
        </div>
        <div className="flex flex-row gap-4">
          <Button onClick={() => modal.close()} variant="outlined">
            {t('common.cancel', 'Cancel')}
          </Button>
          <Button
            variant="solid"
            className="bg-sky-blue-light text-white"
            onClick={onConfirmButtonClick}
            disabled={readonly}
          >
            {t('common.confirm', 'Confirm')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default WorkflowActionForm
