import { Dispatch, SetStateAction } from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { FileType } from '../../../../../../../../../types/shared/FileTypes'
import { filterForNonDuplicateFiles, checkFileRules, postFile } from '../../../../../../../../../core/utils/fileUploadUtils'
import { ReqDefEditorSectionType } from '../../../../../constants/editorTypes'
import { updateSection } from '../../../../../utils/editorUtils'

const useUploadSectionFiles = () => {
  const cache = useQueryClient()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  return async (
    files: FileList,
    section: ReqDefEditorSectionType,
    setLoadingFiles: Dispatch<SetStateAction<boolean>>,
  ) => {
    const uniqueFiles = filterForNonDuplicateFiles(files, section.files)
    const approvedFiles = uniqueFiles.filter((file) => checkFileRules(file))

    const getUploadedFiles = (): Promise<FileType[]> => new Promise((resolve) => {
      const uploadedFiles: FileType[] = []
      approvedFiles.forEach(async (file, index, array) => {
        const uploadedFile = await postFile(file, 'ItemSection', section.id)
        if (uploadedFile) uploadedFiles.push(uploadedFile)
        if (uploadedFiles.length === array.length) {
          resolve(uploadedFiles)
        }
      })
    })

    setLoadingFiles(true)
    const uploadedFiles = await getUploadedFiles()
    setLoadingFiles(false)

    updateSection(
      cache,
      requisitionId,
      section,
      'files',
      section.files.length
        ? [...section.files, ...uploadedFiles]
        : [...uploadedFiles],
    )
  }
}

export default useUploadSectionFiles
