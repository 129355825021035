import { useEffect } from 'react'
import { useFormContext } from '../../../../core/providers/FormProvider'
import useCheckForSubmission from './useCheckForSubmission'
import useGetFormSubmission from './useGetFormSubmission'

export default () => {
  const { data: formData } = useCheckForSubmission()
  const { data: submission, isLoading } = useGetFormSubmission()
  const { responseConfig: [responseConfig, setResponseConfig] } = useFormContext()
  const { submission: [, setSubmission] } = useFormContext()

  useEffect(() => {
    if ((submission || formData) && !isLoading) {
      const newSubmission = submission?.body || formData?.template
      if (newSubmission) {
        delete newSubmission.section_types
        setSubmission(newSubmission)
      }
    }
    return () => { setSubmission(undefined) }
  }, [submission, formData])

  useEffect(() => {
    setResponseConfig({
      ...responseConfig,
      activeSectionUUID: formData?.template.sections?.[0].__uuid,
    })

    // or load whatever question is waiting to be filled out
  }, [formData])
}
