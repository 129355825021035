import { useState } from 'react'
import { useQuery } from 'react-query'
import ContainerRoundedGray from '../../../../../../../../shared/components/atoms/containers/ContainerRoundedGray'
import Titlebar from '../../../../../../../../shared/components/layout/Titlebar'
import { CATALOG_ITEM } from '../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../services/UnifiedSearchService'
import { MercateoArticleReferenceType } from '../../../../../../../../types/Requisition/MercateoItemType'
import onlyArticleReferences from '../../../../utils/onlyArticleReference'
import CatalogReferencesTable from './CatalogReferences/CatalogReferencesTable'

const CatalogReferences: React.FC<{
  catalogId: string,
  className?: string
}> = ({ catalogId, className }) => {
  const [reference, setReference] = useState<MercateoArticleReferenceType[] | []>([])

  useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId), {
    onSuccess: (res) => {
      setReference(onlyArticleReferences(res))
    },
  })

  if (!reference.length) return null

  return (
    <ContainerRoundedGray className={className}>
      <Titlebar small title="Article References" />
      <CatalogReferencesTable articleReference={reference} />
    </ContainerRoundedGray>
  )
}
export default CatalogReferences
