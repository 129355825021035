import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { useEffect } from 'react'
import RequisitionFilterBadge from './components/RequisitionFilterBadge'
import { RequisitionFilterType } from './types'
import DivRow from '../../../../../shared/components/atoms/DivRow'
import { PopupBox } from '../../../../../shared/components/atoms/PopupBox'
import usePopupBoxState from '../../../../../shared/components/atoms/PopupBox/hooks/usePopupBoxState'
import { useRequisitionFilters } from './provider/RequisitionFiltersProvider'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import RequisitionFilterValueSelector from './components/RequistionValueSelector'
import useRequisitionFilterMethods from './hooks/useRequisitionFilterMethods'
import useAvailableRequisitionFilterValues from './hooks/useAvailableRequisitionFilterValues'

type RequisitionsFilterProps = { filter: RequisitionFilterType }

const RequisitionsFilterMultiselect = ({ filter }: RequisitionsFilterProps) => {
  const { getSelectedFilterValues, addFilterValue } = useRequisitionFilters()
  const selectedValues = getSelectedFilterValues(filter)
  const { isOpen, close, toggle } = usePopupBoxState()
  const { removeFilter } = useRequisitionFilterMethods()
  const availableValues = useAvailableRequisitionFilterValues(filter)

  const isFilled = selectedValues.length > 0

  useEffect(() => {
    if (availableValues.length === 0) close()
  }, [availableValues])

  const handleClick = () => {
    if (availableValues.length === 0) {
      close()
      return
    }

    toggle()
  }

  return (
    <DivRow className="relative">
      <DivRow
        onClick={handleClick}
        className={classnames(
          ' border text-sm rounded px-3 py-2 gap-3 justify-between min-w-[200px] max-w-[400px] cursor-pointer select-none flex-nowrap',
          { 'border-gray-300': !isFilled },
          { 'border-sky-blue-light text-sky-blue-light bg-ice-blue': isFilled },
        )}
      >
        <DivRow
          className="gap-1"
        >
          <div>
            {filter.icon}
          </div>
          <div>{filter.label}</div>
        </DivRow>
        {isFilled && (
        <DivRow className="flex-wrap gap-2">
          {selectedValues.map((value) => (
            <RequisitionFilterBadge
              key={value.identifier || value.id}
              value={value}
              filter={filter}
            >
              {value.label}
            </RequisitionFilterBadge>
          ))}
        </DivRow>
        ) }
        <DivRow className="gap-1">
          <Button className="!p-1" onClick={toggle}>
            <ChevronDownIcon className="h-4" />
          </Button>
          <Button className="px-0" onClick={() => removeFilter(filter)}>
            <XMarkIcon className="h-4" />
          </Button>
        </DivRow>
      </DivRow>
      <PopupBox open={isOpen} onClose={close} className="w-full z-30">
        <RequisitionFilterValueSelector filter={filter} onChange={(filterValue) => addFilterValue(filter, filterValue)} />
      </PopupBox>
    </DivRow>
  )
}

export default RequisitionsFilterMultiselect
