import {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react'
import { INodeChildren } from '../../../../../types/shared/GeneralTypes'

type OfferVisibilityContextType = {
  visibleOffers: number[],
  setVisibleOffers: Dispatch<SetStateAction<number[]>>
}

const OfferVisibilityContext = createContext<OfferVisibilityContextType>({} as OfferVisibilityContextType)

export function useOfferVisibilityContext() {
  return useContext(OfferVisibilityContext)
}

const OfferVisibilityProvider: React.FC<INodeChildren> = ({ children }) => {
  const [visibleOffersState, setVisibleOffersState] = useState([0])

  const values: OfferVisibilityContextType = useMemo(
    () => ({ visibleOffers: visibleOffersState, setVisibleOffers: setVisibleOffersState }),
    [visibleOffersState],
  )

  return (
    <OfferVisibilityContext.Provider value={values}>
      {children}
    </OfferVisibilityContext.Provider>
  )
}

export default OfferVisibilityProvider
