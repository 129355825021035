import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { ExternalDataColumn } from '../../../types/ExternalDataManager/ExternalDataManagerTypes'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import useListDataEntityColumns from '../../ExternalDataManager/hooks/useListDataEntityColumns'
import { FilterConditionType, FilterSettingsType } from '../../../types/Forms'

interface IDatasetInputValueColumnSelect {
  handleFilterConditionsUpdate: (updatedConditions: Partial<FilterConditionType>) => void
  datasetUuid: string | undefined
  selectedColumnName: string | undefined
}
const DatasetParentComparisonColumnSelect: React.FC<IDatasetInputValueColumnSelect> = (
  { handleFilterConditionsUpdate, datasetUuid, selectedColumnName },
) => {
  const { t } = useTranslation()
  const { data: dataEntityColumns } = useListDataEntityColumns(datasetUuid)

  // Map externalDataEntities to SearchSelect options
  let options: InputSearchSelectItemType[] = []
  if (dataEntityColumns) {
    options = dataEntityColumns.map((entity: ExternalDataColumn) => ({
      id: uuid(),
      label: entity.column_name,
    }))
  }
  // Set options in state to avoid uuid changes on rerender
  const [optionsState, setOptionsState] = useState<InputSearchSelectItemType[]>(options)
  useEffect(() => {
    setOptionsState(options)
  }, [dataEntityColumns])

  const selectedColumn = optionsState.find((option) => option.label === selectedColumnName)

  const onColumnSelect = (column_name: string) => {
    handleFilterConditionsUpdate({ parent_column: column_name })
  }

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">
        {t('inputs.select_parent_column', 'Select column in the dataset to compare the parent input value to')}
      </label>
      <SearchSelect
        onChange={(item) => onColumnSelect(item.label)}
        options={optionsState}
        placeholder={t('inputs.select_parent_column', 'Select column in the dataset to compare the parent input value to')}
        currentSelection={selectedColumn}
      />
    </div>
  )
}

export default DatasetParentComparisonColumnSelect
