import { useTranslation } from 'react-i18next'
import { ValidationConfig, ValidationProperty, ValidationPropertyConfig } from '../ValidationSchemas'
import QuestionInputNumber from '../../../components/inputs/QuestionInputNumber'
import QuestionSliderSettingRow from '../../../components/inputs/QuestionSliderSettingRow'
import { useFormContext } from '../../../../../../../core/providers/FormProvider'
import useValidationProperty from './hooks/useValidationProperty'
import { FormQuestionType, FormQuestionValidationProperty } from '../../../../../../../types/Forms'

type NumberPropertyProps = {
  question: FormQuestionType
  property: ValidationProperty
  config: ValidationPropertyConfig
  onUpdate: (updatedValue?: FormQuestionValidationProperty<number>) => void
}

export const NumberValidationProperty = ({
  question, property, config, onUpdate,
}: NumberPropertyProps) => {
  const { t } = useTranslation()
  const { currentValue, isChecked } = useValidationProperty<number>(property)

  return (
    <QuestionSliderSettingRow
      title={t(`forms.validation.settings.${property}.label`)}
      description={t(`forms.validation.settings.${property}.description`)}
      onChange={() => (
        onUpdate(isChecked ? undefined : { value: config.default as number, target: undefined })
      )}
      checked={isChecked}
    >
      <QuestionInputNumber
        placeholder={t(`forms.validation.settings.${property}.placehoder`)}
        value={currentValue}
        precision={0}
        onChange={(e) => onUpdate({ value: parseInt(e.currentTarget.value.split('.').join(''), 10), target: undefined })}
      />
    </QuestionSliderSettingRow>
  )
}

export default NumberValidationProperty
