import {
  apiGet, apiGetData, apiPostData, ParamsObject,
} from '../../core/utils/api/generic'
import { MessageChatType, MessageType } from '../../types/Messenger/types'

export const apiPostChatMessage = (body: any, token?: string) => apiPostData(
  token
    ? `/${token}/messages`
    : '/messages',
  body,
)

export const apiGetChats = (requisitionId: string) => apiGet<MessageChatType[]>(`/requisition/${requisitionId}/messages`)

export const apiGetChatMessages = (token: string, params: ParamsObject) => apiGetData<MessageType[]>(
  token
    ? `/${token}/messages`
    : '/messages',
  params,
)
