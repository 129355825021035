import { useTranslation } from 'react-i18next'
import { addressToAddressString } from '../../../../../../core/utils/stringUtils'
import { dateTimeFormatter } from '../../../../../../shared/constants/dateFormat'
import useGetOffer from '../../../../hooks/useGetOffer'

export default () => {
  const { t } = useTranslation()
  const { data: offerData } = useGetOffer()

  return [
    {
      title: t('supplier_view.req_id'),
      text: `REQ-${offerData?.requisition?.id}`,
    },
    {
      title: t('supplier_view.supplier_phone'),
      text: offerData?.company?.phone_number?.toString(),
      shouldHide: !offerData?.company?.phone_number,
    },
    {
      title: t('supplier_view.delivery_address'),
      text: offerData?.requisition?.delivery_address ? addressToAddressString(offerData?.requisition?.delivery_address) : t('generic.pending'),
    },
    {
      title: t('requisition_definition.ends_at'),
      text: dateTimeFormatter(offerData?.requisition.ends_at || ''),
      shouldHide: !offerData?.requisition.ends_at,
    },
    {
      title: t('requisition_definition.expected_delivery_date'),
      text: dateTimeFormatter(offerData?.requisition.expected_delivery_date || ''),
      shouldHide: !offerData?.requisition.expected_delivery_date,
    },
    {
      title: t('supplier_view.questions_due_date'),
      text: dateTimeFormatter(offerData?.requisition.questions_due_date || ''),
      shouldHide: !offerData?.requisition.questions_due_date,
    },
  ]
}
