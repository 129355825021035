import { useAbility } from '@casl/react'
import { ABILITIES } from '../../../config/ability'
import CoreLoading from '../../../core/components/CoreLoading'
import { AbilityContext } from '../../../core/providers/AbilityProvider'
import SupplierTabs from '../components/SupplierTabs'
import SupplierTitle from '../components/SupplierTitle'
import useIsSupplierDetailsLoading from '../hooks/useIsSupplierDetailsLoading'
import BackToSupplierSearchBanner from './BackToSupplierSearchBanner'
import SupplierBody from './SupplierBody'

const SupplierDetailsPage = () => {
  const isLoading = useIsSupplierDetailsLoading()
  const ability = useAbility(AbilityContext)

  if (isLoading) return <div><CoreLoading /></div>

  const isEditable = ability.can(ABILITIES.createSupplier)

  return (
    <div className="flex flex-col w-full">
      <BackToSupplierSearchBanner />
      <SupplierTitle editable={isEditable} />
      <SupplierTabs />
      <SupplierBody />
    </div>
  )
}

export default SupplierDetailsPage
