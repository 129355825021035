import CompanyDocumentsHeader from './CompanyDocumentsHeader'
import DocumentsList from './DocumentsList'

const CompanyDocuments = () => (
  <div className="flex w-full flex-col ">
    <CompanyDocumentsHeader />
    <DocumentsList />
  </div>
)

export default CompanyDocuments
