interface IMessageAuthor {
  author: string
}

const MessageAuthor: React.FC<IMessageAuthor> = ({
  author,
}) => (
  <p className="text-sm text-left font-bold text-sky-blue-light">
    {author}
  </p>
)

export default MessageAuthor
