import { useHistory, useLocation } from 'react-router'

export default function useUrlParams() {
  const history = useHistory()
  const { search } = useLocation()

  const getParam = (parameter:string) => new URLSearchParams(search).get(parameter) || ''

  const setParam = (value:Record<string, string|undefined>) => {
    const params = Object.fromEntries(new URLSearchParams(search))
    Object.entries(value).forEach(([key, val]) => {
      params[key] = val || ''
    })

    history.replace({
      pathname: window.location.pathname,
      search: `?${new URLSearchParams(params)}`,
    })
  }

  return { getParam, setParam }
}
