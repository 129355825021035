import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../../../shared/components/atoms/buttons/Button'
import CatalogBuyEstimatedDelivery from './CatalogBuyContainer/CatalogBuyEstimatedDelivery'
import CatalogBuyPrice from './CatalogBuyContainer/CatalogBuyPrice'
import CatalogQuantitySelector from './CatalogBuyContainer/CatalogBuyQuantitySelector'
import CatalogBuyReturnPolicy from './CatalogBuyContainer/CatalogBuyReturnPolicy'
import CatalogTieredShipping from './CatalogBuyContainer/CatalogTieredShipping'
import CatalogMOQ from './CatalogBuyContainer/CatalogMOQ'
import CatalogPiecesPerOrder from './CatalogBuyContainer/CatalogPiecesPerOrder'

const CatalogBuyContainer: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-fit
    border rounded-xl
    flex justify-center flex-col
    p-6 gap-y-2 mt-4"
    >

      <CatalogBuyPrice catalogId={catalogId} />

      <CatalogQuantitySelector />

      <CatalogPiecesPerOrder
        catalogId={catalogId}
      />

      <CatalogMOQ catalogId={catalogId} />

      <Button color="lhotse-orange" variant="solid">{t('catalog.buy_now')}</Button>

      <Button color="sky-blue" variant="outlined">{t('catalog.add_to_basket')}</Button>

      <CatalogBuyEstimatedDelivery catalogId={catalogId} />

      <CatalogTieredShipping catalogId={catalogId} />

      <CatalogBuyReturnPolicy catalogId={catalogId} />
    </div>
  )
}
export default CatalogBuyContainer
