import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import useGetMessages from '../../../hooks/useGetMessages'
import useScrollToActiveMessageOnLoad from '../../../hooks/useScrollToActiveMessageOnLoad'
import MessageBoxActivity from './MessageBubble/MessageBubble'
import MessageBoxNoActivityMessage from './NoActivityText'
import MessageBoxSelectChatMessage from './SelectChatText'

interface IMessageBox {
  style?: React.CSSProperties
}

const MessageBox: React.FC<IMessageBox> = ({
  style,
}) => {
  const { data: messages, isLoading } = useGetMessages()
  useScrollToActiveMessageOnLoad()

  if (isLoading) {
    return (
      <div className="bg-background w-full h-full flex justify-center items-center">
        <CoreLoadingIcon />
      </div>
    )
  }

  return (
    <div
      className="h-full flex w-full cursor-auto bg-background"
      style={style}
    >
      <div
        className="p-4 overflow-y-auto flex w-full flex-col relative"
        id="message-container"
      >
        {messages?.map((message) => (
          <MessageBoxActivity
            key={message?.uuid}
            message={message}
          />
        ))}
        <MessageBoxNoActivityMessage />
        <MessageBoxSelectChatMessage />
      </div>
    </div>
  )
}

export default MessageBox
