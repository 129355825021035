import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import SelectorSettingRow from '../../../../../../shared/components/organisms/SelectorSettingRow'
import { QuestionUserType, QuestionUserTypes } from '../../../../../../types/Forms/formTypes'
import OptionType from '../../../../../../types/shared/OptionType'
//
type UserOption = { label: string, value: QuestionUserType }
const typeFromOption = (option: UserOption): QuestionUserType => option.value

export const UserSettings: React.FC = () => {
  const { t } = useTranslation()
  const { question: [question], updateQuestion } = useFormContext()
  if (!question) return null

  const typeToOption = (type: QuestionUserType): UserOption => ({ label: t(`forms.question.user.${type}`), value: type })

  const handleChange = (selected: OptionType) => {
    updateQuestion({ __active_for: typeFromOption(selected as UserOption) })
  }

  const availableUserTypes = QuestionUserTypes.map(typeToOption)

  return (
    <SelectorSettingRow
      title={t('forms.settings.active_for')}
      description={t('forms.settings.active_for_description')}
      options={availableUserTypes}
      value={typeToOption(question.__active_for)}
      handleChange={handleChange}
    />

  )
}

export default UserSettings
