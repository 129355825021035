import { useTranslation } from 'react-i18next'
import { useFormField } from '../providers/FormFieldProvider'
import FormNumericInput from '../FormInputs/FormNumericInput'

export const FormFieldNumber = () => {
  const { t } = useTranslation()
  const { field: { id, disabled, value }, updateValue } = useFormField()

  let formattedPrice = ''
  if (value?.value !== undefined && !Number.isNaN(value?.value)) {
    formattedPrice = (value?.value?.toString())?.replace('.', '')
      ?.replace(',', '.') || '0'
  }

  return (
    <div className="flex flex-col gap-4 text-base font-normal">
      <div key={`intake-field-${id}`} className="">
        <FormNumericInput
          disabled={disabled}
          placeholder={t('generic.type_your_response')}
          onChange={(updatedValue) => updateValue(updatedValue)}
          defaultValue={formattedPrice}
        />
      </div>
    </div>
  )
}

export default FormFieldNumber
