import { useQuery, useQueryClient } from 'react-query'
import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import keys from '../../../constants/RequisitionQueryStrings'
import { getSupplierInvites } from '../../../services/RequisitionSuppliersService'

const useGetSupplierInvites = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()
  const queryKey = [keys.INVITES, requisitionId]

  const currentSupplierInviteData = cache.getQueryData<RequisitionInviteType[]>(queryKey)

  const newSupplierInvitesData = useQuery(
    queryKey,
    () => getSupplierInvites(requisitionId),
    { enabled: !currentSupplierInviteData },
  )

  return currentSupplierInviteData
    ? { data: currentSupplierInviteData, isLoading: false }
    : newSupplierInvitesData as { data: RequisitionInviteType[], isLoading: boolean }
}

export default useGetSupplierInvites
