import { useTranslation } from 'react-i18next'

const BannerLastCall: React.FC = () => {
  const { t } = useTranslation()

  return (
    <p className="flex text-left py-3 px-8 bg-yellow-100 bg-opacity-80">
      {t('supplier_offer.last_call_banner')}
    </p>
  )
}

export default BannerLastCall
