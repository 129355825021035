import { useQuery } from 'react-query'
import { apiGetSubmission } from '../../constants/formService'
import useGetFormParams from '../../FormBuilder/hooks/useGetFormParams'

export default () => {
  const { submissionId, formId } = useGetFormParams()
  return useQuery(
    ['get-submission', submissionId, formId],
    () => apiGetSubmission(formId, submissionId),
    {
      enabled: !!(formId && submissionId),
      staleTime: Infinity,
    },
  )
}
