import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import WarningBanner from '../../../../../../shared/components/organisms/WarningBanner/WarningBanner'
import RequisitionCoordinateProvider, { useCoordinateProvider } from '../../providers/CoordinateProvider'
import NewSupplierModal from './NewSupplierModal'

const NoResults: React.FC = () => {
  const { t } = useTranslation()
  const { modal } = useUtils()

  const { searchQuery: [searchQuery] } = useCoordinateProvider()

  const handlerDisplayModal = () => {
    modal.set({
      title: 'req_coordinate.create_new_supplier',
      content: (
        <RequisitionCoordinateProvider>
          <NewSupplierModal initialValues={{ name: searchQuery }} />
        </RequisitionCoordinateProvider>),
      isLoading: false,
      isOpen: true,
    })
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center text-center h-48">
        <h3 className="text-lg font-semibold text-mountain-gray">{t('coordinate.no_results_found', 'No results found')}</h3>
        <p className="mt-3 text-md text-rock-gray">
          {t('coordinate.no_search_results', 'No results match the search term. Try another search term or removing filters')}
        </p>
      </div>
      {/* <WarningBanner severity="info"> */}
      {/*  <div className="flex items-center justify-between w-full" data-cy="no-results"> */}
      {/*    <p className="text-xs">{t('req_coordinate.no_existing_supplier')}</p> */}
      {/*    <div className="flex w-2/6 justify-end items-center"> */}
      {/*      <Button */}
      {/*        color="sky-blue" */}
      {/*        variant="outlined" */}
      {/*        className="text-xs" */}
      {/*        onClick={handlerDisplayModal} */}
      {/*        dataCy="add-supplier-button" */}
      {/*      > */}
      {/*        {t('generic.create')} */}
      {/*      </Button> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </WarningBanner> */}
    </>

  )
}

export default NoResults
