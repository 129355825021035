import { LineItemType } from '../../../../../types/shared/LineItemDataTypes'
import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import TagsGroup from '../../../../../shared/components/atoms/tags/TagsGroup'
import { dateTimeFormatter } from '../../../../../shared/constants/dateFormat'

export default (
  invite: RequisitionInviteType,
  finalOfferFilesItem: LineItemType | undefined,
) => {
  const supplierTags = invite?.supplier_user?.supplier_branch?.tags?.filter((tag) => ['general', 'supplierBranch.general'].includes(tag.type)) || []

  return [{
    id: 'supplierOfferNumber',
    value: invite.offer?.supplier_offer_no,
    item_attribute_id: 'supplierOfferNumber',
    item_attribute_option_id: 0,
  }, {
    id: 'lastUpdate',
    value: invite.offer?.updated_at ? dateTimeFormatter(invite.offer?.updated_at) : '-',
    item_attribute_id: 'lastUpdate',
    item_attribute_option_id: 0,
  },
  {
    id: 'deliveryPeriod',
    value: invite.offer?.delivery_time_estimate,
    item_attribute_id: 'deliveryPeriod',
    item_attribute_option_id: 0,
    comment: { id: 'deliveryPeriodComment', comment: invite.offer?.delivery_time_estimate_comment },
  },
  {
    id: 'finalOfferFiles',
    value: null,
    item_attribute_option_id: 0,
    files: finalOfferFilesItem?.files,
    comment: finalOfferFilesItem?.comment,
    item_attribute_id: 'finalOfferFiles',
  },
  {
    id: 'supplierTags',
    value: null,
    item_attribute_option_id: 0,
    item_attribute_id: 'supplierTags',
    children: <TagsGroup tags={supplierTags} />,
  },
  ]
}
