import { useState } from 'react'
import Header from '../SupplierDetailsPage/Header'
import Users from './Users'

const SupplierContacts = () => {
  const [showNewUserForm, setShowNewUserForm] = useState(false)

  return (
    <div className="px-7">
      <div className="bg-white rounded-lg">
        <Header
          showNewUserForm={showNewUserForm}
          setShowNewUserForm={setShowNewUserForm}
        />
        <Users
          showNewUserForm={showNewUserForm}
          setShowNewUserForm={setShowNewUserForm}
        />
      </div>
    </div>
  )
}

export default SupplierContacts
