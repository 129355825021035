import { useEffect } from 'react'
import { useQuery } from 'react-query'
import ability, { FEATURE_FLAGS, updateAbility } from '../../../config/ability'
import { getLocalInfo } from '../../services/userService'
import { apiGet } from '../../utils/api/generic'

export default () => {
  const userData = getLocalInfo()
  const { data: featureFlags, isLoading: isLoadingFeatureFlags } = useQuery(
    'featureFlags',
    () => apiGet<{ [key in keyof typeof FEATURE_FLAGS]: boolean }>('/feature-flags'),
  )

  useEffect(() => {
    updateAbility(ability, userData, featureFlags)
  }, [userData, featureFlags])

  return { isLoadingFeatureFlags }
}
