import SharedFileUploadPill from '../../../../../shared/components/layout/SharedFileUploadPill/SharedFileUploadPill'
import { SectionOrLineItemType } from './hooks/useGetSectionsOrLineItems'

interface IFiles {
  section: SectionOrLineItemType
}

const Files: React.FC<IFiles> = ({
  section,
}) => (
  <div className="flex gap-x-2 gap-y-2 pb-2 flex-wrap">
    {section.files?.map((file) => <SharedFileUploadPill file={file} key={file.name} />)}
  </div>
)

export default Files
