import Titlebar from '../../../../../shared/components/layout/Titlebar'
import CreateFormButton from '../buttons/CreateFormButton'

const CompanyFormsHeader: React.FC = () => (
  <Titlebar title="generic.forms_plural">
    <div className="flex gap-x-2">
      <CreateFormButton isTestForm />
      <CreateFormButton isTestForm={false} />
    </div>
  </Titlebar>
)

export default CompanyFormsHeader
