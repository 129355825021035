import { useTranslation } from 'react-i18next'
import useCloseModal from '../../../../../../../../core/hooks/useCloseModal'
import toastr from '../../../../../../../../shared/components/organisms/toastr/toastr'
import { ModalDataType } from '../useGetOfferViewActionModalData'

const useSubmitReason = (data: ModalDataType, modalType: 'changes' | 'accept' | 'decline') => {
  const { t } = useTranslation()

  const closeModal = useCloseModal()

  return (reason: string) => {
    if (!reason && modalType === 'changes') {
      return toastr.error(modalType === 'changes' ? t('req_award.provide_reason_changes') : t('req_award.provide_reason_decline'), 'reason_alert')
    }
    data.actionHandler()
    return closeModal()
  }
}

export default useSubmitReason
