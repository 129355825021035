// Function uses an input uuid to look up an input, and then gets the filter_settings field
// from the input. The filter_settings field can be empty, or it can be an object of type FilterSettingsType.
// If it isn't empty, use filter_settings to get the data source, the input_value_column, and the visible_columns.
// use the data_source nad input value column to look up the data source using the ExternalDataManager API.

import _ from 'lodash'
import useGetInputById from '../../modules/Forms/hooks/useGetInputById'
import useGetExternalDataEntityData from '../../modules/ExternalDataManager/hooks/useGetExternalDataEntityColumnData'

import useCurrentSection from '../../modules/Forms/FormResponsePage/providers/FormSectionProvider/hooks/useCurrentSection'
import { useIntakeFlow } from '../../modules/Intake/providers/IntakeFlowProvider'

const useGetSearchSelectOptionsFromFile = (inputUuid: string | null | undefined) => {
  const { data: input } = useGetInputById(inputUuid)

  const filterSettings = input?.filter_settings
  const {
    data_source, conditions,
    input_value_column, visible_columns,
  } = filterSettings || {}

  let queryString = ''

  // Attempt to load both form section and Intake from respective contexts
  // (only one will be defined, depending on where the select is used)
  const formSection = useCurrentSection()
  const { intake } = useIntakeFlow()

  if (conditions && conditions?.length > 0) {
    let parentVal

    // Check if the parent input is in the section, and has a value
    if (formSection) {
      parentVal = formSection?.fields.find(
        (field) => field.inputId === conditions[0].parent_input_uuid,
      )?.values?.[0]?.value
    } else if (intake) {
      // Get parent value from intake sections
      parentVal = intake.sections.find(
        (section) => section.fields.find(
          (field) => field.input.uuid === conditions[0].parent_input_uuid,
        ),
      )?.fields.find(
        (field) => field.input.uuid === conditions[0].parent_input_uuid,
      )?.value?.value
    }

    if (parentVal) {
      queryString = `?${conditions[0].parent_column}=eq.${parentVal}`
    }
  }

  if (visible_columns) {
    // if query string is not empty, use & instead of ?
    if (queryString) {
      queryString += '&'
    } else {
      queryString += '?'
    }
    queryString += `select=id,${visible_columns}`
  }

  const { data: dataObjects } = useGetExternalDataEntityData(data_source, queryString)

  const visibleColumns = visible_columns?.split(',')

  // Map the data objects to options and return it
  const options = dataObjects?.map((dataObject: { [x: string]: any; id: any }) => ({
    id: dataObject.id,
    label: input_value_column ? dataObject[input_value_column] : '',
    dataFields: visibleColumns
      ? _.pick(dataObject, visibleColumns) // Include the data fields that are visible, for rendering in the search select
      : dataObject,
  }))

  return { options, visibleColumns }
}

export default useGetSearchSelectOptionsFromFile
