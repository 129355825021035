import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../core/components/CoreLoadingIcon'
import Table from '../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../shared/components/atoms/SharedTable/TableHeader'
import CompanyInput from './CompanyInput'
import CompanyInputsHeader from './CompanyInputsHeader'
import InputTemplatesProvider, { useInputTemplates } from './providers/InputTemplatesProvider'

const CompanyInputsContent: React.FC = () => {
  const { t } = useTranslation()
  const { templates: { data: inputs, isLoading } } = useInputTemplates()

  return (
    <div className="">
      <CompanyInputsHeader />
      <TableContainer>
        <TableHead>
          <TableHeader>{t('inputs.table_name', 'Input Name')}</TableHeader>
          <TableHeader>{t('inputs.type', 'Type')}</TableHeader>
          <TableHeader>{t('inputs.key', 'Key')}</TableHeader>
          <TableHeader>{t('inputs.custom_key', 'Custom Key')}</TableHeader>
          <TableHeader />
        </TableHead>
        <Table>
          {
            isLoading
              ? <CoreLoadingIcon />
              : inputs?.map((i) => <CompanyInput key={i.uuid} input={i} />)
          }
        </Table>
      </TableContainer>
    </div>
  )
}

const CompanyInputs: React.FC = () => (
  <InputTemplatesProvider>
    <CompanyInputsContent />
  </InputTemplatesProvider>
)

export default CompanyInputs
