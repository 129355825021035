import dayjs from 'dayjs'
import save from 'save-file'
import apiClient from '../../../core/utils/apiClient'
import toastr from '../../../shared/components/organisms/toastr/toastr'

const generateCSVData = async (
  requisitionId: string,
) => {
  const data = await apiClient.get(`requisitions/${requisitionId}/offers/export`, {
    responseType: 'blob',
    headers: { Accept: 'application/file' },
  })

  const blb = new Blob([data.data], {
    type: data.headers['content-type'],
  })

  const filename = `REQ-${requisitionId}_Offers_${dayjs().format('YYYY-MM-DD')}.xlsx`
  try {
    await save(blb, filename)
  } catch {
    toastr.error('error.generic')
  }
}

export default generateCSVData
