import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'
import { CATALOG_ITEM } from '../../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../../services/UnifiedSearchService'

const CatalogBuyEstimatedDelivery: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { t } = useTranslation()

  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  if (!data) return <CoreLoadingIcon />

  const formattedDate = dayjs(new Date())
    .add(data.orderDetails.deliveryTime, 'day')
    .format('dddd, MMMM DD')

  return (
    <div className="flex flex-col">
      <div>
        {t('catalog.get_it_by')}
      </div>
      <div className="font-medium whitespace-nowrap">
        {formattedDate}
      </div>
    </div>
  )
}

export default CatalogBuyEstimatedDelivery
