import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../../../shared/components/atoms/buttons/Button'
import { RecommendedSupplierType } from '../../../../../../../../types/shared/RequisitionDataTypes'
import useCreateSupplierInvite from '../../../../hooks/useCreateSupplierInvite'
import CoreLoadingIcon from '../../../../../../../../core/components/CoreLoadingIcon'

interface IAddSupplierBtn {
  supplierUser: RecommendedSupplierType
}
const AddSupplierBtn: FC<IAddSupplierBtn> = ({ supplierUser }) => {
  const { t } = useTranslation()
  const { mutate: createSupplierInvite, isLoading } = useCreateSupplierInvite()

  return (
    <Button
      className={`${supplierUser.recommended ? 'bg-emerald-600 text-white' : 'btn-secondary'} transition-colors hover:${supplierUser.recommended ? 'bg-green-400' : 'bg-sky-blue-light'} text-white`}
      variant="primary"
      onClick={() => createSupplierInvite({ supplierUserId: supplierUser.id })}
    >
      {isLoading ? (
        <div className="flex justify-center items-center gap-x-2 whitespace-nowrap">
          {isLoading && <CoreLoadingIcon />}
        </div>
      ) : (
        <span>
          +
          {' '}
          {t('coordinate.add_supplier', 'Add Supplier')}
        </span>
      )}

    </Button>
  )
}

export default AddSupplierBtn
