import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Offering } from '../../../types/Offering'
import { deleteOffering, postOffering, putOffering } from '../services'
import useIntakeId from './useIntakeId'

export const useOfferingMutations = () => {
  const intakeId = useIntakeId()
  const queryClient = useQueryClient()

  const invalidateOfferingOffers = useCallback(() => {
    queryClient.invalidateQueries(['intake-offerings'])
  }, [queryClient])

  const create = useMutation((newOffering: Omit<Offering, 'uuid'>) => postOffering(intakeId, newOffering), {
    onSettled: invalidateOfferingOffers,
  })

  const update = useMutation((updatedOffering: Partial<Offering> & Pick<Offering, 'uuid'>) => putOffering(intakeId, updatedOffering), {
    onSettled: invalidateOfferingOffers,
  })

  const remove = useMutation(
    (offeringUuid: string) => deleteOffering(intakeId, offeringUuid),
    {
      onSettled: invalidateOfferingOffers,
    },
  )

  return { create, update, remove }
}

export default useOfferingMutations
