import {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react'
import { emptyMessageReply } from '../../modules/Messenger/constants'
import { MessageClientContentType, MessageContentType, MessageReplyType } from '../../types/Messenger/types'
import { INodeChildren } from '../../types/shared/GeneralTypes'

type MessagePropertiesType = {
  messageReply: MessageReplyType
  files: File[]
  messageContent: MessageContentType | undefined
}

const emptyMessageProperties = {
  messageReply: emptyMessageReply,
  files: [],
  messageContent: undefined,
}

type IMessengerContext = {
  chatState: [
    MessageClientContentType | undefined,
    Dispatch<SetStateAction<MessageClientContentType | undefined>>
  ],
  messageProperties: [
    MessagePropertiesType,
    Dispatch<SetStateAction<MessagePropertiesType>>
  ]
}

const messengerContext = createContext<IMessengerContext>({} as IMessengerContext)

const MessengerProvider: React.FC<INodeChildren> = ({ children }) => {
  const chatState = useState<MessageClientContentType>()
  const messageProperties = useState<MessagePropertiesType>(emptyMessageProperties)

  const values = useMemo(
    () => ({ chatState, messageProperties }),
    [{ chatState, messageProperties }],
  )

  return (
    <messengerContext.Provider value={values}>
      {children}
    </messengerContext.Provider>
  )
}

export const useMessengerContext = () => useContext(messengerContext)

export default MessengerProvider
