import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from '../EditorSection/SectionWrapper/SectionHeader'
import useAutoSave from '../../../../../utils/useAutoSave'
import AttachmentsSectionContent from './AttachmentsSectionContent'

const AttachmentsSection: React.FC = () => {
  const { t } = useTranslation()
  const [autoSave, updateAutoSave] = useAutoSave()
  const [expanded, setExpanded] = useState(true)

  return (
    <div className="w-full">
      <SectionHeader
        title={t('generic.attachment_plural')}
        toggleSection={setExpanded}
        sectionVisible={expanded}
        autoSave={autoSave}
      />
      <AttachmentsSectionContent
        expanded={expanded}
        updateAutoSave={updateAutoSave}
      />
    </div>
  )
}

export default AttachmentsSection
