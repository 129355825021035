import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'

interface IShippingTax {
  invite: RequisitionInviteType
}

const ShippingTax: React.FC<IShippingTax> = ({
  invite,
}) => {
  const { t } = useTranslation()
  const { offer } = invite

  return offer?.shipping_costs_tax
    ? (
      <div className="flex justify-between items-center">
        <p>{t('req_award.shipping_costs_tax')}</p>
        <p>{`${offer?.shipping_costs_tax}%`}</p>
      </div>
    )
    : null
}

export default ShippingTax
