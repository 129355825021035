import { useFormContext } from '../../../../../core/providers/FormProvider'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const SupplierQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'SUPPLIER') return null

  return (
    <TextAndSubtitleInput />
  )
}

export default SupplierQuestion
