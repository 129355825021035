import { useFormLogic } from '../providers/FormLogicProvider'

export default () => {
  const { allEnabledQuestions } = useFormLogic()

  const getQuestionAttribute = (attributeIdentifier:string):string => allEnabledQuestions
    .find((a) => a.key === attributeIdentifier)?.values[0]?.value as string || ''

  return getQuestionAttribute
}
