import { useCoordinateProvider } from '../../../../../../providers/CoordinateProvider'

const useHandleCoordinateCheckboxChange = () => {
  const {
    selectedInvites: [, setSelectedInvites],
    toggleAll: [, setToggleAll],
  } = useCoordinateProvider()

  return (checked: boolean, selectedUserId: number | undefined) => {
    if (selectedUserId) {
      setSelectedInvites((selectedIds) => (checked
        ? selectedIds.filter((id) => id !== selectedUserId)
        : [...selectedIds, selectedUserId]))
      if (checked) {
        setToggleAll(false)
      }
    }
  }
}

export default useHandleCoordinateCheckboxChange
