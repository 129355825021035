import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../core/providers/UtilsProvider'
import ConfirmationModal from '../../../shared/components/organisms/modals/ConfirmationModal'
import useChangeOfferStatus from '../hooks/useChangeOfferStatus'

const SubmitModal: React.FC = () => {
  const { t } = useTranslation()
  const { modal } = useUtils()

  const changeOfferStatus = useChangeOfferStatus()

  const submitOffer = async () => {
    changeOfferStatus('OFFER_SUBMITTED')
    modal.set({ isOpen: false })
  }

  return (
    <ConfirmationModal
      onConfirm={submitOffer}
      confirmString={t('generic.submit')}
    >
      <p className="text-base">
        {t('supplier_offer.submit_offer_confirm')}
      </p>
    </ConfirmationModal>
  )
}
export default SubmitModal
