import { useEffect, useState } from 'react'
import InputNumber from '../../../../../../shared/components/atoms/inputs/InputNumber'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'
import useGetOffer from '../../../../hooks/useGetOffer'
import useOfferIsDisabled from '../../../../hooks/useOfferIsDisabled'

interface ILineItem {
  placeholder: string
  updateLineItem?: (value: string | null) => void
  dataCy?: string
  value: string | number | null
  unit?: string
  precision?: number
}

const LineItem: React.FC<ILineItem> = (
  {
    unit,
    value,
    placeholder, updateLineItem, dataCy, precision,
  },
) => {
  const [input, setInput] = useState(value || '')

  useEffect(() => {
    if (value) setInput(value)
  }, [value])

  const { data: offerData } = useGetOffer()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (offerData?.accepted_at) {
      toastr.error('supplier_view.offer_accepted_cannot_be_updated', 'errorOfferUpfateAfterAccept')
      return
    }

    setInput(value)
  }

  const isDisabled = useOfferIsDisabled() || !updateLineItem

  return (
    <InputNumber
      placeholder={placeholder}
      value={input}
      suffix={unit}
      onChange={handleInputChange}
      onBlur={(e, updatedValue) => updateLineItem && updateLineItem(updatedValue)}
      className="w-full px-4"
      dataCy={dataCy}
      precision={precision}
      disabled={isDisabled}
    />
  )
}

export default LineItem
