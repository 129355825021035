import RadioButton from '../../../../../shared/components/atoms/inputs/RadioButton'
import useGetSupplierBranchId from '../../../hooks/useGetSupplierBranchId'
import useGetSupplierBranch from '../../../hooks/useGetSupplierBranch'
import useHandleSourceChange from './useHandleSourceChange'

interface ISupplierDetailsSourceButton {
  option: string
}

const SupplierDetailsSourceButton: React.FC<ISupplierDetailsSourceButton> = ({
  option,
}) => {
  const { data: branchData } = useGetSupplierBranch()
  const supplierBranchId = useGetSupplierBranchId()

  const isLhotseSupplier = branchData?.source?.toLowerCase() === 'lhotse'
  const handleChange = useHandleSourceChange(supplierBranchId as number)

  const isChecked = option === 'Lhotse' ? isLhotseSupplier : !isLhotseSupplier

  return (
    <div className="flex gap-x-1 items-center">
      <RadioButton checked={isChecked} onClick={handleChange} dataCy="source-radio-button" />
      <p className="text-xs">{option}</p>
    </div>
  )
}

export default SupplierDetailsSourceButton
