import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import Tooltip from '../../../../../../shared/components/organisms/tooltip/Tooltip'

interface ITitle {
  row: LineItemItemAttributeType
}

const Title: React.FC<ITitle> = (
  { row },
) => {
  const { t } = useTranslation()
  const isMultiLineInput = !!row.options.length

  return (
    <div className="flex items-center">
      <p className="break-all">{row.description}</p>
      {(!!row.is_required && !isMultiLineInput)
        && (
          <Tooltip showCondition tooltip={t('generic.input_required')} className="pl-2">
            <MdError className="text-orange-600" />
          </Tooltip>
        )}
    </div>
  )
}

export default Title
