import { Link } from 'react-router-dom'
import lhotseIcon from '../../../assets/lhotse-logo.svg'
import lhotseIconSmall from '../../../assets/logo-icon-lhotse.svg'

const CoreSidebarLogo: React.FC<{ minified?: boolean }> = ({ minified }) => (
  <Link to="/">
    {minified
      ? (
        <div className="w-full">
          <img src={lhotseIconSmall} alt="Lhotse" className="mt-5 w-10 h-10" />
        </div>
      )
      : (
        <img
          src={lhotseIcon}
          alt="Lhotse"
          className="p-2 mx-2 mt-5 w-4/5 max-w-2xl h-10 justify-self-start"
        />
      )}
  </Link>
)

export default CoreSidebarLogo
