import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { StickySidePanel } from '../../../../shared/components/layout/StickySidePanel'
import WorkflowBlockDraftProvider from '../../providers/WorkflowBlockDraftProvider'
import { useWorkflow } from '../../providers/WorkflowProvider'
import useTabs from '../../../../shared/hooks/useTabs'

import WorkflowMenu from '../misc/WorkflowMenu'
import WorkflowBuilderSidepanelBlockDetails from './WorkflowBuilderSidepanelBlockDetails/WorkflowBuilderSidepanelBlockDetails'
import WorkflowBuilderSidepanelActions from './WorkflowBuilderSidepanelActions/WorkflowBuilderSidepanelActions'

export const WorkflowBuilderSidepanel = () => {
  const { t } = useTranslation()
  const {
    sidePanelOpen, deselect, removeSelectedBlock, selectedBlock, readonly,
  } = useWorkflow()

  const tabsData = useMemo(() => [
    selectedBlock && {
      title: t('workflow.sidepanel_general', 'General'),
      current: true,
      TabComponent: WorkflowBuilderSidepanelBlockDetails,
    },
    {
      title: t('workflow.sidepanel_actions', 'Actions'),
      current: false,
      TabComponent: WorkflowBuilderSidepanelActions,
    },
  ], [selectedBlock])

  const {
    navItems, changeTab, current: selectedTab, updateTabs,
  } = useTabs(tabsData)

  useEffect(() => {
    updateTabs(tabsData)
  }, [tabsData])

  if (!selectedBlock) return null

  const menuItems = [
    !readonly && {
      name: t('general.remove', 'Remove'),
      onClick: removeSelectedBlock,
    },
  ].filter(Boolean)

  const TabsBody = selectedTab?.TabComponent

  return (
    <WorkflowBlockDraftProvider block={selectedBlock}>
      <StickySidePanel
        open={sidePanelOpen}
        onToggle={deselect}
        tabs={navItems}
        onTabChange={changeTab}
        header={(
          <div className="flex flex-row">
            <WorkflowMenu items={menuItems} />
          </div>
        )}
        title={`${t('generic.edit', 'Edit')} ${selectedBlock.name}`}
      >
        <div className="flex-col flex justify-between h-full flex-auto">
          <div className=" flex  h-full">{TabsBody && (<TabsBody />)}</div>
        </div>
      </StickySidePanel>

    </WorkflowBlockDraftProvider>
  )
}

export default WorkflowBuilderSidepanel
