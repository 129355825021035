import * as React from 'react'
import { IntakeSectionType } from '../../../../types/Intake'
import IntakeBasicSection from './IntakeSectionsContent/IntakeBasicSection'
import { FormSectionCategoryType } from '../../../../types/Forms'
import SectionWrapper from '../../../../shared/components/organisms/SectionWrapper'
import IntakeFlowDetailsActions from '../IntakeFlowDetailsActions'

type IntakeSectionProps = {
  section: IntakeSectionType
}

export type IntakeSectionContentProps = {
  section: IntakeSectionType
}

const sectionComponents: Partial<Record<FormSectionCategoryType, React.FC<IntakeSectionContentProps>>> = {
  GENERAL_INFO: IntakeBasicSection,
}

export const IntakeSection = ({ section }: IntakeSectionProps) => {
  const Content = sectionComponents[section.type || 'GENERAL_INFO']

  return (
    <SectionWrapper title={section.name} actions={<IntakeFlowDetailsActions />}>
      <div className="p-6 grid md:grid-cols-1 2xl:grid-cols-2 4xl:grid-cols-3 gap-8">{Content && <Content section={section} />}</div>
    </SectionWrapper>
  )
}
export default IntakeSection
