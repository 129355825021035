import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MdArrowDropDown } from 'react-icons/md'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import useGetOffer from '../../../hooks/useGetOffer'

interface INetGrossDropdownButton {
  setNetDropdownOpen: Dispatch<SetStateAction<boolean>>
  isDropdownLoading: boolean
  netDropdownOpen: boolean
}

const NetGrossDropdownButton: React.FC<INetGrossDropdownButton> = ({
  setNetDropdownOpen, isDropdownLoading, netDropdownOpen,
}) => {
  const { t } = useTranslation()
  const { data: offerData } = useGetOffer()

  const isNet = offerData?.offer?.is_net

  return (
    <button
      type="button"
      className={`flex gap-x-2 items-center w-full justify-between border p-2 rounded-md hover:bg-gray-100 text-sm ${netDropdownOpen && 'rounded-t-none'}`}
      onClick={() => setNetDropdownOpen((isOpen) => !isOpen)}
    >
      {isDropdownLoading
        ? <CoreLoadingIcon />
        : <p>{isNet ? t('supplier_offer.tax_excl') : t('supplier_offer.tax_incl')}</p>}
      <MdArrowDropDown className={netDropdownOpen ? 'transform rotate-180' : ''} />
    </button>
  )
}

export default NetGrossDropdownButton
