import {
  FormQuestionType, FormSectionType, LoopQuestionLogic,
} from '../../../../../../../../../types/Forms'

import LogicComparatorDropdown from '../LogicComparatorSelector/LogicComparatorDropdown'
import useQuestionMutations from '../../../hooks/useQuestionMutations'

interface IFormLogicOptions {
  question: FormQuestionType
  section: FormSectionType
  logicCase: LoopQuestionLogic
}

const FormLogicLoopCase: React.FC<IFormLogicOptions> = ({ logicCase, question, section }) => {
  const { updateLoop } = useQuestionMutations(question.__uuid, section)

  return (
    <div>
      <p className="text-sm">
        When answer is
      </p>
      <LogicComparatorDropdown value={logicCase.value.id} onChange={(value) => updateLoop({ value })} question={question} />
    </div>
  )
}

export default FormLogicLoopCase
