import { useTranslation } from 'react-i18next'
import { IoMdEye } from 'react-icons/io'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useOpenRequisitionPreview from '../../../subModules/NewRequisition/hooks/useOpenRequisitionPreview'
import useGetRequisitionData from '../../../subModules/Definition/hooks/useGetRequisition'

const PreviewButton: React.FC = () => {
  const openModal = useOpenRequisitionPreview(true)
  const { t } = useTranslation()
  const { data: requisitionData } = useGetRequisitionData()

  if (requisitionData?.form_submission) return null

  return (
    <Button
      className="text-sm  bg-white text-gray-500 border "
      onClick={() => openModal(requisitionData)}
      dataCy="req-preview-button"
    >
      <IoMdEye className="w-4 h-4" />
      {t('generic.preview')}
    </Button>
  )
}

export default PreviewButton
