import { MdCheckCircleOutline } from 'react-icons/md'

interface ICheckmarkIndicator {
  checked: boolean
  showError: boolean
}

const CheckmarkIndicator: React.FC<ICheckmarkIndicator> = ({ checked, showError }) => {
  const uncheckedColor = showError ? 'text-red-600' : 'text-gray-300'
  return (
    <MdCheckCircleOutline
      className={checked ? 'text-green-500' : uncheckedColor}
      data-cy="checkmark-indicator"
    />
  )
}

export default CheckmarkIndicator
