import { useMutation, useQueryClient } from 'react-query'
import { RequisitionSearchDataType } from '../../../types/Requisition/RequisitionSearchResultsDataTypes'
import { TEMPLATE_ELEMENTS } from '../subModules/NewRequisition/constants/newReqQueryStrings'
import { fetchUnifiedElements } from '../subModules/NewRequisition/services/UnifiedSearchService'
import { MutateFilterType } from '../../../types/Requisition/MutateFilterType'

export function useSearchUnifiedElements() {
  const cache = useQueryClient()

  return useMutation(
    (body: MutateFilterType) => {
      const { query, filters } = body
      return fetchUnifiedElements({
        query,
        dateRange: filters.dateRange,
        supplierName: filters.supplierName,
        category: filters.category,
      })
    },
    {
      onSuccess: (newData: RequisitionSearchDataType, inputValues) => cache.setQueryData(
        [TEMPLATE_ELEMENTS, inputValues.query],
        () => newData.data as any,
      ),
    },
  )
}

export default useSearchUnifiedElements
