import { Dispatch, SetStateAction } from 'react'
import NoInstanceFound from './NoInstanceFound'
import SelectInstance from './SelectInstance'

interface props {
  links: number[]
  setSubmitted: Dispatch<SetStateAction<boolean>>
}
const AvailableTenants = ({ links, setSubmitted }: props) => (links.length
  ? <SelectInstance links={links} />
  : <NoInstanceFound setSubmitted={setSubmitted} />)

export default AvailableTenants
