import { useMutation, useQueryClient } from 'react-query'
import { useMessengerContext } from '../../../../core/providers/MessengerProvider'
import toastr from '../../../../shared/components/organisms/toastr/toastr'
import useGetToken from '../../../SupplierOffer/hooks/useGetToken'
import { emptyMessageReply } from '../../constants'
import messengerQueryKeys from '../../messengerQueryKeys'
import { apiPostChatMessage } from '../../service'
import { MessageType } from '../../../../types/Messenger/types'
import { scrollToBottomOfChat } from '../../utils'
import useGetChats from '../useGetChats'
import useIntakeId from '../../../Intake/hooks/useIntakeId'
import useGetIntakeMessagePayload from './useGetIntakeMessagePayload'
import useGetAndSetActiveIntakeMessage from './useGetAndSetActiveIntakeMessage'

export default () => {
  const { messageProperties: [, setMessageProperties] } = useMessengerContext()
  const modelType = 'intake_flow'
  const modelId = useIntakeId()

  const [, setMessageContent] = useGetAndSetActiveIntakeMessage()
  const cache = useQueryClient()
  const getMessagePayload = useGetIntakeMessagePayload()

  return useMutation(
    async () => apiPostChatMessage(await getMessagePayload()),
    {
      onMutate: () => {
        setMessageContent('')
        setMessageProperties((messageProperties) => ({
          ...messageProperties,
          messageReply: emptyMessageReply,
          files: [],
        }))
      },
      onSuccess: (newData: MessageType) => {
        const QUERY_KEY = messengerQueryKeys.messages(modelType, Number(modelId))
        cache.setQueryData(QUERY_KEY, (oldData: MessageType[] | undefined) => (
          oldData
            ? [...oldData, newData]
            : [newData]
        ))

        scrollToBottomOfChat()
      },
      onError: () => { toastr.error('error.item_not_sent', 'errorSherpaMessageSend') },
    },
  )
}
