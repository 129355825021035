import useGetOffer from '../../hooks/useGetOffer'
import useUpdateOfferValue from '../../hooks/useUpdateOfferValue'
import useUpdateOfferValueCache from '../../hooks/useUpdateOfferValueCache'
import Comment from '../DescriptionSection/Row/Comment'

const CommentInput: React.FC = () => {
  const { data: offerData } = useGetOffer()

  const updateOfferValueCache = useUpdateOfferValueCache()
  const updateOfferValue = useUpdateOfferValue()

  const deliveryComment = offerData?.offer?.delivery_time_estimate_comment

  return typeof deliveryComment === 'string'
    ? (
      <div className="p-5 pt-0 w-full ">
        <Comment
          comment={deliveryComment || ''}
          onChange={(e) => updateOfferValueCache(e, 'delivery_time_estimate_comment')}
          onBlur={(e) => updateOfferValue('delivery_time_estimate_comment', e.currentTarget.value)}
        />
      </div>
    )
    : null
}

export default CommentInput
