import {
  apiDelete, apiPost, apiPutData,
} from '../../../core/utils/api/generic'
import { CompanyAddressType } from '../../../types/Company/AddressTypes'

export const apiPostCompanyAddress = async (data: CompanyAddressType) => apiPost('/addresses/company', data)

export const apiUpdateAddress = async (address: CompanyAddressType, addressId: number) => apiPutData(`addresses/${addressId}`, address)

export const apiDeleteAddress = async (addressId: number) => {
  await apiDelete(`addresses/${addressId}`, addressId)
  return addressId
}
