import { MdOutlineCancel } from 'react-icons/md'
import { FormQuestionType, FormQuestionValue } from '../../../../../types/Forms'
import useQuestionValues from '../../hooks/useQuestionValues'
import FormInput from './FormInput'
import { getUpdatedByUuid } from '../../../../../shared/utils/arrayHelpers'
import { FormEvents } from '../../Questions'

type KeyValueRowProps = {
  question: FormQuestionType,
  index: number,
  answer: FormQuestionValue,
  visibleKey?: boolean
  onRemove: () => void
}

export const KeyValueRow = ({
  question, answer, visibleKey = true, children, onRemove,
}: React.PropsWithChildren<KeyValueRowProps>) => {
  const [questionValues, updateQuestionValues] = useQuestionValues(question)

  const updateLabel = (label: string) => {
    updateQuestionValues(getUpdatedByUuid(questionValues, [
      {
        __uuid: answer.__uuid,
        label,
      },
    ]))
  }

  const handleComplete = () => {
    FormEvents.QuestionAnswered.dispatch(question.__uuid)
  }

  const canRemove = questionValues.length > (question?.__settings?.answers?.min || 1)

  return (
    <div className="flex flex-row gap-x-2 items-center justify-center">
      {visibleKey && (
        <FormInput
          shortResponse
          characterLimit={question.validation.max_characters?.value}
          value={answer.label || ''}
          onChange={updateLabel}
          onComplete={handleComplete}
          wrapperClassName="flex-grow"
          disabled={question.__settings?.labels?.readonly}
        />
      )}
      {children}
      {visibleKey && canRemove && (
      <button
        type="button"
        tabIndex={-1}
        className="flex justify-center items-center"
        onClick={onRemove}
      >
        <MdOutlineCancel className="w-5 h-5" />
      </button>
      )}
    </div>
  )
}

export default KeyValueRow
