import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../core/providers/UtilsProvider'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { replaceById } from '../../../shared/utils/arrayHelpers'
import companyQueryStrings from '../constants/companyQueryStrings'
import { apiUpdateCustomerDocuments } from '../services/CompanyService'
import { DocumentContentType, DocumentType } from '../../../types/Company/DocumentType'

function useUpdateCustomerDocuments(
  { document, documentId }:
    { document: DocumentType | DocumentContentType, documentId?: number },
) {
  const cache = useQueryClient()
  const utils = useUtils()

  const { mutate } = useMutation(() => apiUpdateCustomerDocuments({
    document, documentId,
  }), {
    onSuccess: (newData) => {
      cache.setQueryData(
        [companyQueryStrings.customer_documents],
        (oldData: DocumentType[] | undefined) => replaceById(
          oldData,
          newData.id,
          newData,
        ),
      )
      utils.modal.set({ isOpen: false })
      toastr.success('success.item_updated', 'company-document')
    },
  })

  return mutate
}

export default useUpdateCustomerDocuments
