import RequisitionStatusesType from '../../../types/Basecamp/RequisitionStatusesType'

export default function requisitionStatusStyles(status : RequisitionStatusesType) {
  const step = {
    name: status,
    number: 0,
    textColor: 'text-secondary-gray',
    borderColor: 'border-gray-400',
  }

  if (['PURCHASED'].includes(status)) {
    step.number = 2
    step.textColor = 'text-green-600'
    step.borderColor = 'border-green-600'
  } if (['CANCELED', 'CLOSED', 'NOT_PURCHASED'].includes(status)) {
    step.number = -1
    step.textColor = 'text-red-500'
    step.borderColor = 'border-red-500'
  } if (['PUBLISHED', 'OPEN', 'OFFERS_RECEIVED'].includes(status)) {
    step.number = 2
    step.textColor = 'text-yellow-600'
    step.borderColor = 'border-yellow-400'
  }

  return step
}
