import useDebouncedQuery from '../../../../core/hooks/useDebouncedQuery'
import { useBasecampContext } from '../../../../core/providers/BasecampProvider'
import { apiSearchRequisitions } from '../../services/BasecampService'

export default () => {
  const { requisitionSearch: [searchQuery] } = useBasecampContext()

  return useDebouncedQuery(
    ['requisitions', searchQuery],
    apiSearchRequisitions,
    { query: searchQuery },
  )
}
