import dayjs from 'dayjs'
import { useQueryClient } from 'react-query'
import { useUtils } from '../../../../../../../../core/providers/UtilsProvider'
import { RequisitionDataType } from '../../../../../../../../types/shared/RequisitionDataTypes'
import useGetRequisitionId from '../../../../../../../../shared/hooks/useGetRequisitionId'
import toastr from '../../../../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../../../../types/shared/GeneralTypes'
import { lastCallStateType } from '../../../../../../components/layout/ReqTopbar/RequisitionTopbarLastCallConfirmation'
import queryKeys from '../../../../constants/OfferViewQueryKeys'
import { inviteToLastCall, triggerLastCall } from '../../../../services/offersService'
import ReqDefQueryKeys from '../../../../../Definition/constants/queryKeys'

export default (deadline: Date | undefined, lastCallState: lastCallStateType) => {
  const { modal } = useUtils()
  const reqId = useGetRequisitionId()
  const cache = useQueryClient()

  const supplierInvitesQueryKey = [queryKeys.OFFERS_DATA, reqId]
  const requisitionQueryKey = [ReqDefQueryKeys.requisitionData, reqId]

  return async () => {
    if (lastCallState) {
      const [selectedOfferIds, setSelectedOfferIds] = lastCallState

      if (!deadline) {
        toastr.error('negotiation_modal.days')
      } else if (selectedOfferIds) {
        if (dayjs(deadline).isBefore(dayjs())) {
          toastr.error('Pick date in future :)')
        } else {
          try {
            await Promise.all(
              selectedOfferIds?.map((offerId) => inviteToLastCall(offerId, deadline)),
            )
            triggerLastCall(reqId)
            toastr.success('award.last_call_successful')
            setSelectedOfferIds(undefined)
            cache.refetchQueries(supplierInvitesQueryKey)
            cache.setQueryData<undef<RequisitionDataType>>(requisitionQueryKey, (
              oldReq: undef<RequisitionDataType>,
            ) => (
              oldReq
                ? { ...oldReq, status: 'LAST_CALL_NEGOTIATION' }
                : oldReq
            ))
            modal.set({ isOpen: false })
          } catch { toastr.error() }
        }
      }
    }
  }
}
