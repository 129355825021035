import { useHistory } from 'react-router'
import { IStringChildren } from '../../../../../types/shared/GeneralTypes'

interface INewRequisitionTabHeader extends IStringChildren {
  icon: React.ReactElement
  link: string
  selected: boolean
}

const NewRequisitionTabHeader: React.FC<INewRequisitionTabHeader> = ({
  children, icon, link, selected,
}) => {
  const history = useHistory()

  return (
    <button
      type="button"
      onClick={() => history.push(`/new/${link}`)}
      className={`p-5
      flex items-center text-sm
      focus:outline-none focus:ring-0
      ${selected ? 'text-sky-blue border-b-2 border-blue-600' : 'hover:bg-white/[0.12]'}  `}
    >
      <span className="mr-2 text-lg">{icon}</span>
      <span>
        {children}
      </span>
    </button>
  )
}

export default NewRequisitionTabHeader
