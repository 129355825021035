import { useQuery } from 'react-query'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import useGetOffer from '../../SupplierOffer/hooks/useGetOffer'
import useGetToken from '../../SupplierOffer/hooks/useGetToken'
import messengerQueryKeys from '../messengerQueryKeys'
import { apiGetChatMessages } from '../service'
import useGetMessengerUrlParams from './useGetMessengerUrlParams'

export default () => {
  const { modelType, modelId: modelIdParam } = useGetMessengerUrlParams()

  const token = useGetToken()

  const requisitionId = useGetRequisitionId()

  const { data: offer } = useGetOffer()

  const modelId = modelType === 'requisition' ? requisitionId : modelIdParam
  const modelIdUrlParam = modelId || offer?.id
  const paramsObj = { model_type: modelType || 'supplier_invite', model_id: modelIdUrlParam || '' }

  const QUERY_KEY = messengerQueryKeys.messages(modelType, Number(modelId))

  return useQuery(
    QUERY_KEY,
    () => apiGetChatMessages(token, paramsObj),
    { enabled: !!(modelType || token) },
  )
}
