import { useTranslation } from 'react-i18next'
import { TEMPLATE_ELEMENTS } from '../../constants/newReqQueryStrings'
import { useFilters } from '../../providers/FiltersProvider'
import { fetchUnifiedElements } from '../../services/UnifiedSearchService'
import NewRequisitionTemplateItem from '../NewRequisitionTemplateItem'
import NewRequisitionTemplateItemsGrid from '../NewRequisitionTemplateItemsGrid'
import useOpenRequisitionPreview from '../../hooks/useOpenRequisitionPreview'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import useDebouncedQuery from '../../../../../../core/hooks/useDebouncedQuery'
import SharedFilters from '../../../../../../shared/components/organisms/Filters/Filters'

const NewReqPastRequisitionTab: React.FC = () => {
  const { filters, query: [query] } = useFilters()
  const { t } = useTranslation()

  const { data: resolvedUnifiedData, isLoading } = useDebouncedQuery(
    [TEMPLATE_ELEMENTS, query],
    fetchUnifiedElements,
    {
      query,
      dateRange: filters[0].dateRange,
      supplierName: filters[0].supplierName,
      category: filters[0].category,
    },
  )

  const openModal = useOpenRequisitionPreview()

  if (isLoading) return <CoreLoadingIcon />

  return (
    <div className="w-full flex flex-col items-end gap-y-2">
      <SharedFilters
        meta={resolvedUnifiedData?.meta}
        filterState={filters}
        query={query}
        disabled={!resolvedUnifiedData?.meta}
      />
      <div className="flex flex-col h-full w-full">
        <div className="flex w-full">
          {resolvedUnifiedData?.data
            ? (
              <NewRequisitionTemplateItemsGrid
                openModal={openModal}
                itemComponent={NewRequisitionTemplateItem}
                data={resolvedUnifiedData.data}
              />
            )
            : (
              <div className="flex w-full h-full justify-center items-center">
                {t('new_requisition.type_for_results')}
              </div>
            )}
        </div>

      </div>

    </div>
  )
}

export default NewReqPastRequisitionTab
