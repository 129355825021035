import { useEffect } from 'react'
import useGetSupplierInvites from '../../../hooks/useGetSupplierInvites'
import { useCoordinateProvider } from '../../../providers/CoordinateProvider'

function useMonitorSelectAllSuppliersToggle() {
  const {
    toggleAll: [toggleAll],
    selectedInvites: [selectedSuppliers, setSelectedInvites],
  } = useCoordinateProvider()

  const { data: allInvites } = useGetSupplierInvites()

  useEffect(() => {
    const createdInvites = allInvites?.filter((invite) => invite.status === 'CREATED').map((invite) => invite.id) || []
    if (toggleAll) {
      setSelectedInvites(createdInvites)
    } else if (selectedSuppliers.length === createdInvites.length) {
      setSelectedInvites([])
    }
  }, [toggleAll, allInvites, setSelectedInvites])
}

export default useMonitorSelectAllSuppliersToggle
