import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import AcceptanceStateType from '../../../../../../../types/Requisition/AcceptanceStateType'
import IsDeclinedTag from './IsDeclinedTag'
import IsResubmittedTag from './IsResubmittedTag'
import IsWaitingTag from './IsWaitingTag'
import LastCallTag from './LastCallTag'
import SourceTag from './SourceTag'
import ExternalSystemTag from './ExternalSystemTag'

interface ISupplierHeaderTags {
  invite: RequisitionInviteType
}

const SupplierHeaderTags: React.FC<ISupplierHeaderTags> = ({ invite }) => {
  const currentStatus: AcceptanceStateType = invite?.status

  return (
    <div className="flex gap-x-2">
      <SourceTag invite={invite} />
      <LastCallTag invite={invite} />
      <IsWaitingTag currentStatus={currentStatus} />
      <IsResubmittedTag invite={invite} />
      <IsDeclinedTag invite={invite} />
      <ExternalSystemTag supplierUser={invite.supplier_user} />
    </div>
  )
}

export default SupplierHeaderTags
