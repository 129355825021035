import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { useRequisitionFilters } from './provider/RequisitionFiltersProvider'
import RequisitionFilterButton from './components/RequisitionFilterButton'
import useRequisitionFilterMethods from './hooks/useRequisitionFilterMethods'

const RequisitionsFiltersBar = () => {
  const { t } = useTranslation()
  const { filters } = useRequisitionFilters()
  const { removeAllFilters } = useRequisitionFilterMethods()

  if (filters.length === 0) return null

  return (
    <div className="flex flex-col justify-between bg-white p-2 min-h-[6em] gap-2.5">
      <div className="flex flex-wrap items-center items-start gap-1 flex-grow">
        {filters.map((filter) => (<filter.component key={`filter-element-${filter.id}`} filter={filter} />))}

      </div>
      <div className="flex flex-row justify-between">
        <div>
          <RequisitionFilterButton className="text-sky-blue-light" text={`${t('requisitions.add_filter')}`} />
        </div>
        <Button
          onClick={removeAllFilters}
          className="text-sky-blue-light round-lg border-solid border-sky-blue-light border-2 max-h-fit"
        >
          {t('requisitions.remove_filters')}
        </Button>
      </div>
    </div>

  )
}

export default RequisitionsFiltersBar
