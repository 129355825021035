import { useContext } from 'react'
import { ABILITIES } from '../../../config/ability'
import { AbilityContext } from '../../../core/providers/AbilityProvider'

export const useIsSupplierOfferEditable = () => {
  const ability = useContext(AbilityContext)

  return {
    userCanEditAnyOffer: ability.can(ABILITIES.editAnySupplierOffer),
  }
}

export default useIsSupplierOfferEditable
