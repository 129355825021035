import { useTranslation } from 'react-i18next'
import {
  Redirect, Route, Switch, useRouteMatch,
} from 'react-router'
import PageTopbar from '../../shared/components/layout/PageTopBar'
import CompanyGeneralTab from './components/CompanyGeneralTab'
import CompanyTabbar from './components/CompanyTabbar'

const CompanyPage = () => {
  const { t } = useTranslation()
  const { path } = useRouteMatch()

  return (
    <div className="w-full">
      <PageTopbar
        title={t('account.company_settings')}
      />
      <div className="flex flex-1">
        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
          <div className="sticky top-0 pl-5 pt-5 flex w-52 flex-col overflow-y-auto">
            <CompanyTabbar />
          </div>
        </aside>
        <div className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto p-5">
          <Switch>
            <Route path={path} exact>
              <Redirect to={`${path}/general`} />
            </Route>
            <Route path={`${path}/general`}>
              <CompanyGeneralTab />
            </Route>

          </Switch>
        </div>
      </div>
    </div>
  )
}

export default CompanyPage
