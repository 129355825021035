import useGetSupplierBranch from './useGetSupplierBranch'
import useGetSupplierBranchUsers from './useGetSupplierBranchUsers'
import useGetSupplierTags from './useGetSupplierTags'

export default () => {
  const { isLoading: isLoadingBranch } = useGetSupplierBranch()
  const { isLoading: isLoadingUsers } = useGetSupplierBranchUsers()
  const { isLoading: isLoadingGeneral } = useGetSupplierTags('general')
  const { isLoading: isLoadingCertificates } = useGetSupplierTags('certificates')
  const { isLoading: isLoadingPortfolio } = useGetSupplierTags('portfolio')

  return isLoadingBranch
    || isLoadingUsers
    || isLoadingGeneral
    || isLoadingCertificates
    || isLoadingPortfolio
}
