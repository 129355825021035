import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import Button from './Button'

interface ISubmitButton extends INodeChildren {
  isLoading: boolean
}
const SubmitButton: React.FC<ISubmitButton> = (
  { children, isLoading },
) => (
  <Button
    variant="solid"
    color="lhotse-orange"
    type="submit"
    loading={isLoading}
    dataCy="submit-button"
  >
    {children}
  </Button>
)

export default SubmitButton
