// REFACTOR
import { useTranslation } from 'react-i18next'
import { MdAllInbox } from 'react-icons/md'
import Tooltip from '../../../../../../shared/components/organisms/tooltip/Tooltip'
import { RequisitionSearchDataDataType } from '../../../../../../types/Requisition/RequisitionSearchResultsDataTypes'
import CatalogThumbnail from './NewRequisitionTemplateCatalogItem/CatalogThumbnail'

interface INewRequisitinoTemplateCatalogItem {
  item: RequisitionSearchDataDataType,
  onClick: () => void
}

const NewRequisitionTemplateCatalogItem: React.FC<INewRequisitinoTemplateCatalogItem> = (
  { item, onClick },
) => {
  const show = item?.name.length > 55
  const { t } = useTranslation()
  return (
    <Tooltip tooltip={item.name} showCondition={show}>
      <button
        type="button"
        onClick={onClick}
        className="flex border rounded-lg w-full px-5 bg-white py-3
      hover:bg-blue-50 hover:bg-opacity-50"
        data-cy="modal-item"
      >
        <div className="flex justify-between text-xs items-center gap-2 w-5/6 h-16">
          <div className="flex items-start flex-col gap-y-4">
            <div className="font-regular text-sm text-left line-clamp-1">
              {item.name}
            </div>
            <div className="flex  items-center gap-2 text-gray-500">
              <MdAllInbox />
              <p>{t('requisition.catalogue_item')}</p>
            </div>
          </div>
          <div className="flex flex-col justify-between items-end gap-y-4">
            <div className="flex items-center gap-1">
              <p className="text-sm">
                {(item.price || 0) / 100}
                €
              </p>
              <p className="text-gray-500">/piece</p>
            </div>
            <div className="truncate text-gray-400">
              {item.id}
            </div>
          </div>
        </div>
        <div className="w-1/6 h-16 flex items-center justify-center ">
          <CatalogThumbnail
            image={item.image}
            name={item.name}
          />
        </div>
      </button>
    </Tooltip>
  )
}

export default NewRequisitionTemplateCatalogItem
