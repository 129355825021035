import Switch, { ISwitch } from '../atoms/buttons/Switch'
import SettingRow from './SettingRow'

export type ISliderSettingRow = React.PropsWithChildren<{
  title: string
  description?: string
} & ISwitch>

const SliderSettingRow: React.FC<ISliderSettingRow> = ({
  children, title, description, ...switchProps
}) => (
  <SettingRow title={title} description={description} input={(<Switch {...switchProps} />)}>
    {children}
  </SettingRow>
)

export default SliderSettingRow
