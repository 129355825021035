import { useMutation, useQueryClient } from 'react-query'
import { apiCreateFormTags, apiDeleteFormTags } from '../../../../../constants/formService'
import useGetFormId from '../../../../hooks/useGetFormId'
import { FormTag } from '../../../../../../../types/Forms'

export const useFormTagsMutations = () => {
  const client = useQueryClient()
  const formId = useGetFormId()

  const handleUpdate = () => {
    client.invalidateQueries(['form-tags', formId])
  }

  const attach = useMutation(
    (tags: string[]) => apiCreateFormTags(formId, tags),
    { onSuccess: handleUpdate },
  )

  const detach = useMutation(
    (tags: string[]) => apiDeleteFormTags(formId, tags),
    { onSuccess: handleUpdate },
  )

  return { attach, detach }
}

export default useFormTagsMutations
