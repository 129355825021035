import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import useGetToken from '../../hooks/useGetToken'
import DocumentPreview from './DocumentPreview'

interface IDocumentName {
  document: CustomerDocumentType
  checked: boolean
  setChecked: Dispatch<SetStateAction<boolean>>
}

const DocumentName: React.FC<IDocumentName> = ({
  document, checked, setChecked,
}) => {
  const { t } = useTranslation()
  const token = useGetToken()

  const { modal } = useUtils()
  const handleDisplayPreview = () => {
    modal.set(
      {
        isOpen: true,
        title: document.name,
        content: <DocumentPreview
          document={document}
          token={token}
          setChecked={setChecked}
          checked={checked}
        />,
      },
    )
  }

  return (
    <button type="button" className="flex gap-x-1" onClick={handleDisplayPreview}>
      <p>{t('generic.i_accept')}</p>
      <span className="text-sky-blue font-semibold">{document.name}</span>
    </button>
  )
}

export default DocumentName
