import { isExistingFileType } from '../../../types/shared/FileTypes'
import { LineItemItemAttributeType } from '../../../types/shared/LineItemDataTypes'
import useFindAndUpdateLineItemFiles from '../components/DescriptionSection/Row/hooks/useFindAndUpdateLineItemFiles'
import { deleteFileWithToken } from '../services/fileService'
import { getLineItemFromRow } from '../utils/utils'
import useCheckOfferStatusAndUpdateIfSubmitted from './useCheckOfferStatusAndUpdateIfSubmitted'
import useGetToken from './useGetToken'
import {
  UploadedFileType,
} from '../../../shared/components/layout/SharedFileUploadPill/SharedFileUploadPillTypes'

const useRemoveSupplierOfferFile = (row: LineItemItemAttributeType) => {
  const lineItem = getLineItemFromRow(row)
  const token = useGetToken()

  const findAndUpdateLineItemFiles = useFindAndUpdateLineItemFiles()

  const checkOfferStatusAndUpdateIfSubmitted = useCheckOfferStatusAndUpdateIfSubmitted()

  return async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    file: UploadedFileType,
  ) => {
    e.stopPropagation()
    if (lineItem && isExistingFileType(file)) {
      await deleteFileWithToken(file, token)
      findAndUpdateLineItemFiles(file, row, true)
      checkOfferStatusAndUpdateIfSubmitted()
    }
  }
}

export default useRemoveSupplierOfferFile
