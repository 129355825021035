import { useTranslation } from 'react-i18next'
import { defaultPriceString } from './constants'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import { priceToGermanPriceString } from '../../../../../../../shared/constants/currencyFormat'

interface ITaxRate {
  invite: RequisitionInviteType
  currentStatus: string | undefined
}

const TaxRate: React.FC<ITaxRate> = ({
  invite, currentStatus,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-center">
      <p>{t('generic.tax_rate')}</p>
      <p>{`${currentStatus !== 'INVITED' ? priceToGermanPriceString(invite.calculated_price.tax || 0) : defaultPriceString}`}</p>
    </div>
  )
}

export default TaxRate
