import AddressType from '../../../types/shared/AddressType'
import { LineItemSectionType, PostLineItemFunctionType, PutLineItemFunctionType } from '../../../types/shared/LineItemDataTypes'
import { CustomerDocumentAcceptanceType, OfferDataType } from '../../../types/shared/OfferDataTypes'
import {
  apiGet, apiGetData, apiPost, apiPostData, apiPut, apiPutData,
} from '../../../core/utils/api/generic'

export const putOfferStatus = async (token: string, status: string): Promise<OfferDataType> => apiPutData(`/offer/${token}/status`, { status })
export const getLineItemData = (token: string) => apiGetData<LineItemSectionType[]>(`/offer/${token}/line-items`)
export const getRequisitionDocuments = (requisitionId: string) => apiGetData(`/requisitions/${requisitionId}/documents`)
export const toggleDocumentAcceptance = (token: string, documentId: number, location?: string, name?: string): Promise<CustomerDocumentAcceptanceType> => apiPostData(`/offer/${token}/acceptance/${documentId}`, { location, name })
export const putNDAStatus = (token: string, status: string) => apiPut(`/offer/${token}/status`, { status })
export const getOfferData = (token: string) => apiGetData<OfferDataType>(`/offer/${token}`)
export const getSupplierBranchAddresses = (supplierBranchId: number | undefined) => apiGet<AddressType[]>(`/addresses/supplier-branches/${supplierBranchId}`)
export const putRequisitionValue = (key: string, value: string | boolean | null, token: string) => apiPut(`/offer/${token}`, { [key]: value })

export const postLineItem: PostLineItemFunctionType = ({
  token, item_attribute_id, lineItemValue, optionId, taxRatePercent, netTotalShippingCost, selected,
}) => apiPost(`/offer/${token}/line-items`, {
  item_attribute_id, value: lineItemValue, item_attribute_option_id: optionId, tax_rate_percent: taxRatePercent, net_total_shipping_cost: netTotalShippingCost, selected,
})

export const putLineItem: PutLineItemFunctionType = ({
  token, lineItemId, item_attribute_id, lineItemValue, taxRatePercent, netTotalShippingCost, selected,
}) => apiPut(`/offer/${token}/line-items/${lineItemId}`, {
  item_attribute_id, id: lineItemId, value: lineItemValue, tax_rate_percent: taxRatePercent, net_total_shipping_cost: netTotalShippingCost, selected,
})

export const putSelectLineItem = ({ offerId, lineItemId }: {offerId: number, lineItemId: number}) => apiPut(`/select-line-item/${offerId}/${lineItemId}`)
