import { useTranslation } from 'react-i18next'
import Mousetrap from 'mousetrap'
import React, {
  createContext, ReactNode, useContext, useMemo,
} from 'react'
import type { QuestionItemType } from './QuestionItem'
import QuestionItems from './QuestionItems'
import QuestionButton from '../inputs/QuestionButton'
import QuestionItemInput from './QuestionItemInput'

type QuestionItemsListContext<T extends QuestionItemType = QuestionItemType> = {
  onDragEnd: (from: number, to: number) => void
  onCreate?: () => void
  onDelete?: (item: T) => void
  onChange: (item: T) => void
  items: T[]
  ItemComponent: ({ item }: { item: T }) => ReactNode
}

type QuestionItemsListProps<T extends QuestionItemType> = React.PropsWithChildren<Omit<QuestionItemsListContext<T>, 'ItemComponent'> & {
  title: string
  description: string
  ItemComponent?: ({ item }: { item: T }) => ReactNode
}>

const questionItemsListContext = createContext({} as QuestionItemsListContext)

export const QuestionItemsList = <T extends QuestionItemType>({
  onDragEnd, onCreate, onDelete, onChange, items, title, description, ItemComponent = QuestionItemInput,
}: QuestionItemsListProps<T>) => {
  const { t } = useTranslation()

  const contextData = useMemo<QuestionItemsListContext<T>>(() => ({
    onDragEnd, onCreate, onDelete, onChange, items, ItemComponent,
  }), [onDragEnd, onCreate, onDelete, onChange, items])

  Mousetrap.bind('meta+shift+o', () => { onCreate && onCreate(); return false })

  return (
    <questionItemsListContext.Provider value={contextData as unknown as QuestionItemsListContext}>
      <div className="rounded-lg flex flex-col bg-white py-4 px-6 gap-y-4 w-full">
        <div className="flex flex-col gap-y-2">
          <p className="text-primary-gray font-medium">{title}</p>
          <p className="text-sm text-secondary-gray">
            {description}
          </p>
        </div>
        <QuestionItems />
        {onCreate && (
          <QuestionButton
            type="button"
            className="flex gap-x-2 items-center text-primary-gray text-xs"
            onClick={onCreate}
          >
            <p>+</p>
            <p className="underline">{t('generic.add')}</p>
          </QuestionButton>
        )}
      </div>
    </questionItemsListContext.Provider>
  )
}

export default QuestionItemsList

export const useQuestionItemsList = <T extends QuestionItemType = QuestionItemType>() => useContext(questionItemsListContext) as unknown as QuestionItemsListContext<T>
