import { useCallback, useMemo } from 'react'
import { EvaluatedFormSectionType, FormSectionType } from '../../../../../../types/Forms'
import { useFormData } from '../../FormDataProvider'
import useGetLogicAllowedQuestions from './useGetLogicAllowedQuestions'

export default () => {
  const enabledQuestionsUuids = useGetLogicAllowedQuestions()

  const { sectionsIndex } = useFormData()

  const evaluateSection = useCallback((section: FormSectionType): EvaluatedFormSectionType => {
    const activeFields = section.fields?.filter(
      (question) => !question.__loop?.is_loop_trigger || section.loop.__looped,
    )
      .filter((question) => question.__active_for === 'REQUESTER')

    const activeAndEnabledByLogicFields = activeFields.filter(
      (question) => enabledQuestionsUuids.includes(question.__uuid),
    )

    const enabledFields = activeAndEnabledByLogicFields
      .filter((question) => !question.__settings?.misc?.hidden)

    const processableFields = activeAndEnabledByLogicFields
      .filter((question) => !question.isFormElement)

    return {
      ...section,
      enabledFields,
      processableFields,
      disabledFields: activeFields.filter(
        (activeField) => !enabledFields.find((enabledField) => enabledField.__uuid === activeField.__uuid),
      ),
    }
  }, [sectionsIndex])

  return useMemo<EvaluatedFormSectionType[]>(() => {
    const enabledIndex = (sectionsIndex || [])
      .map<EvaluatedFormSectionType>(evaluateSection)
      .filter((section) => (section.enabledFields?.length || 0) > 0 || section.__uuid === 'Confirmation')

    return enabledIndex.map((section, index) => ({
      ...section,
      nextSection: index + 1 < enabledIndex.length ? enabledIndex[index + 1] : undefined,
      previousSection: index > 0 ? enabledIndex[index - 1] : undefined,
    }))
  }, [sectionsIndex])
}
