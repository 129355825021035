import { useQueryClient } from 'react-query'
import { OfferDataType } from '../../../types/shared/OfferDataTypes'
import { OfferStatusType } from '../../../types/SupplierOffer/SupplierOfferDataTypes'
import queryKeys from '../constants/queryKeys'
import { putOfferStatus } from '../services/services'
import useGetToken from './useGetToken'

export default () => {
  const cache = useQueryClient()
  const token = useGetToken()

  return async (
    status: OfferStatusType,
  ) => {
    if (status !== 'OFFER_IN_EDITING') {
      await putOfferStatus(token, status)
    }

    cache.setQueryData<OfferDataType | undefined>(
      [queryKeys.offer],
      (oldOffer: OfferDataType | undefined) => (
        oldOffer
          ? ({
            ...oldOffer,
            status,
          })
          : oldOffer),
    )
  }
}
