import { Dispatch, SetStateAction } from 'react'
import useCheckOfferStatusAndUpdateIfSubmitted from '../../../hooks/useCheckOfferStatusAndUpdateIfSubmitted'
import useGetOffer from '../../../hooks/useGetOffer'
import useGetToken from '../../../hooks/useGetToken'
import { putRequisitionValue } from '../../../services/services'

export default (
  setNetDropdownOpen: Dispatch<SetStateAction<boolean>>,
  setIsDropdownLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const token = useGetToken()

  const { data: offerData, refetch } = useGetOffer()

  const checkOfferStatusAndUpdateIfSubmitted = useCheckOfferStatusAndUpdateIfSubmitted()

  const isNet = offerData?.offer?.is_net

  return async () => {
    setNetDropdownOpen(false)
    setIsDropdownLoading(true)
    await putRequisitionValue('is_net', !isNet, token)
    checkOfferStatusAndUpdateIfSubmitted()
    await refetch()
    setIsDropdownLoading(false)
  }
}
