import { useQuery } from 'react-query'
import { useEffect } from 'react'
import { getLocalInfo, setLocalInfo } from '../../services/userService'
import settingsQueryKeys from '../../../modules/Settings/constants/settingsQueryKeys'
import { apiGetUser } from '../../../modules/Settings/services/SettingsService'

export default async () => {
  const userData = getLocalInfo()
  const { data: currentUser } = useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser)

  useEffect(() => {
    if (!currentUser || !userData) {
      return
    }

    const updateLocalStorageRole: boolean = currentUser.role !== userData.role

    if (!updateLocalStorageRole) {
      return
    }

    const { role } = currentUser
    setLocalInfo({ role })
  }, [currentUser])
}
