import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'
import { useUtils } from '../../../core/providers/UtilsProvider'
import {
  apiGetApprovalFlow, apiPostApprovalFlow, apiPostApprovalFlowReject, apiPostUnrejectApprovalFlow,
} from '../services/WorkflowService'
import useUpdateRequisitionStatus from './useUpdateRequisitionStatus'

export default () => {
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const { mutate: updateStatus } = useUpdateRequisitionStatus()

  const { data: approvalData, refetch, isLoading: isLoadingApprovalData } = useQuery('workflow', () => apiGetApprovalFlow(requisitionId))
  const { mutate: approve } = useMutation(() => apiPostApprovalFlow(requisitionId), {
    onSuccess: () => refetch(),
  })

  const { mutate: unreject } = useMutation(() => apiPostUnrejectApprovalFlow(requisitionId), {
    onSuccess: () => {
      refetch()
      updateStatus({ status: 'PENDING_APPROVAL', reason: 'Manually unrejected' })
    },
  })

  const { mutate: reject } = useMutation(
    (reason: string) => apiPostApprovalFlowReject(requisitionId, reason),
    {
      onSuccess: () => refetch(),
    },
  )

  const { approvalFlowDrawer } = useUtils()

  return {
    approvalData, approvalFlowDrawer, approve, reject, unreject, updateStatus, isLoadingApprovalData,
  }
}
