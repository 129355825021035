import React from 'react'
import { getBezierPath, EdgeProps } from 'reactflow'

export type IntakeFlowchartEdgeProps = React.PropsWithChildren<EdgeProps> & {
  labelWidth: number
  labelHeight: number
}

export const WorkflowChartEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  children,
  labelWidth,
  labelHeight,
}: IntakeFlowchartEdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {children && (
      <foreignObject
        width={labelWidth}
        height={labelHeight}
        x={labelX - labelWidth / 2}
        y={labelY - labelHeight / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
          {children}
      </foreignObject>
      )}
    </>
  )
}

export default WorkflowChartEdge
