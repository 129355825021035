import { FormQuestionType } from '../../../../types/Forms'
import OtherResponse from './OtherResponseInput'
import ResponseChoice from './ResponseChoice'
import useQuestionErrorMessage from '../hooks/useQuestionErrorMessage'
import OtherOptionResponseChoice from './OtherOptionResponseChoice'

interface IResponseChoices {
  question: FormQuestionType
}

const ResponseChoices: React.FC<IResponseChoices> = ({
  question,
}) => {
  const { options } = question
  const error = useQuestionErrorMessage(question)

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-col gap-y-3 w-fit">
        {options?.map((option, index) => (
          <ResponseChoice
            key={option.__uuid}
            index={index}
            option={option}
            question={question}
            error={error}
          />
        ))}
        <OtherOptionResponseChoice question={question} />
      </div>
      <OtherResponse question={question} />
    </div>
  )
}

export default ResponseChoices
