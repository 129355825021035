import { useTranslation } from 'react-i18next'
import { MdOpenInNew } from 'react-icons/md'
import useHandleOpenSupplierRequisitions from './useHandleOpenSupplierRequisitions'

interface ISeeAllRequisitionsButton {
  supplierBranchId: number
}

const SeeAllRequisitionsButton: React.FC<ISeeAllRequisitionsButton> = ({
  supplierBranchId,
}) => {
  const { t } = useTranslation()

  const handleOpenSupplierRequisitions = useHandleOpenSupplierRequisitions(supplierBranchId)

  return (
    <button
      className="flex gap-x-2 items-center text-sm w-full justify-end underline border-border-gray border-t pt-2"
      type="button"
      onClick={handleOpenSupplierRequisitions}
      data-cy="view-all-reqs-button"
    >
      <p>{t('supplier.view_all_reqs')}</p>
      <MdOpenInNew />
    </button>
  )
}

export default SeeAllRequisitionsButton
