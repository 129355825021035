import { useTranslation } from 'react-i18next'
import SectionHeader from '../../../../shared/components/layout/SectionHeader'
import SupplierRequisitionsBody from './SupplierRequisitionsBody'

const ReqsAndOffers: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-white rounded-lg">
      <SectionHeader title={t('suppliers.reqs_and_offers')} />
      <div className="p-6">
        <SupplierRequisitionsBody />
      </div>
    </div>
  )
}

export default ReqsAndOffers
