import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { debounce } from 'lodash'
import {
  FC, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import Input from '../../../../../../shared/components/atoms/Input/Input'
import { priceToGermanPriceString } from '../../../../../../shared/constants/currencyFormat'
import useUniteSearch from '../../../../hooks/useUniteSearch'
import UniteSearchModalDetails from './UniteSearchModalDetails'
import UniteSearchStockStatus from './UniteSearchStockStatus'
import { LoopSectionFieldValues } from '../../../hooks/useLoopSection'

interface IUniteSearchModal {
  initialSearch?: string
  onAdd: (data: LoopSectionFieldValues) => void
}

const UniteSearchModal: FC<IUniteSearchModal> = ({ initialSearch, onAdd }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<string | undefined>(undefined)
  const [results, setResults] = useState<any[]>([])
  const { t } = useTranslation()

  const { mutateAsync: search } = useUniteSearch()

  const performSearch = useMemo(() => debounce(async (query: string) => {
    const queryResults = await search(query)
    setResults(queryResults?.data || [])
    setIsLoading(false)
  }, 800), [search])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.value.length < 3) return
    performSearch.cancel()
    setIsLoading(true)
    performSearch(e.target?.value)
  }

  useEffect(() => {
    if (initialSearch) handleSearch({ target: { value: initialSearch } } as React.ChangeEvent<HTMLInputElement>)
  }, [initialSearch])

  return (

    <div>
      {!activeItem
        && (
          <div>
            <div className="flex gap-2 sticky top-0 mt-6 bg-white pb-2">
              <Input
                autoFocus
                placeholder={t('generic.search', 'Search')}
                defaultValue={initialSearch}
                className="pl-8"
                onChange={handleSearch}
                lead={(
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 mr-10">
                    <MagnifyingGlassIcon className="w-4 opacity-70" />
                  </div>
                )}
              />
              {isLoading && <div className=""><CoreLoadingIcon /></div>}
            </div>
            <div className="grid grid-cols-1 divide-y ">
              {results.length ? results.map((result) => (
                <div className="p-4 hover:bg-gray-50 cursor-pointer py-8" key={result.id}>
                  <div
                    className="flex  justify-between h-full gap-8 items-center"
                    onClick={() => setActiveItem(result.id)}
                    role="button"
                    tabIndex={-1}
                  >
                    <div className="w-52 flex"><img className="w-48 max-h-44" src={result.previewImage?.url} alt={result.description} /></div>
                    <div className="flex-grow flex flex-col justify-between h-24">
                      <div className="flex justify-between">
                        <div className="font-semibold h-4 max-w-3/4">
                          <div className="line-clamp-1">{result.name}</div>
                        </div>
                        <div className="w-48 font-semibold flex justify-between items-center pb-8">
                          <div>{priceToGermanPriceString(result.price)}</div>
                          <UniteSearchStockStatus inStock={result.inStock} />
                        </div>
                      </div>
                      <div className="flex justify-between text-xs ">
                        {[{ title: 'Manufacturer', attribute: 'manufacturer' }, { title: 'Delivery time', attribute: 'deliveryTime' }, { title: 'Article Id', attribute: 'manufacturerArticleId' }].map((row) => (
                          <div key={result.id + row.attribute} className="">
                            <div className="font-semibold">{result[row.attribute]}</div>
                            <div className=" black-light-2">{row.title}</div>
                          </div>
                        ))}
                        <div key="shipping" className="w-48">
                          <div className="">{priceToGermanPriceString(result.shippingCosts)}</div>
                          <div className=" black-light-2">Shipping costs</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )) : null}
            </div>
          </div>
        )}

      {activeItem
        && <UniteSearchModalDetails setActiveItem={setActiveItem} activeItem={activeItem} onAdd={onAdd} />}

    </div>

  )
}

export default UniteSearchModal
