import classNames from 'classnames'
import React, {
  HTMLAttributes, useRef,
} from 'react'
import { Spacing } from '../../../../types/shared/Styles'
import useClickOutside from '../../../hooks/UseClickOutside'

export type PopupBoxProps = React.PropsWithChildren<{
  open: boolean
  onClose: () => void
  x?: `${'left' | 'right'}-${Spacing}`
  y?: `${'top' | 'bottom'}-${Spacing}`
} & HTMLAttributes<HTMLDivElement>>

export const PopupBox = ({
  open, x = 'left-0', y = 'top-full', className, children, onClose, ...divProps
}: PopupBoxProps) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>

  useClickOutside(ref, onClose)

  return (
    (open && (
      <div
        ref={ref}
        className={classNames(
          className,
          'absolute rounded bg-white p-2 h-fit',
          x,
          y,
        )}
        {...divProps}
      >
        {children}
      </div>
    )) || null
  )
}

export default PopupBox
