import { useMutation } from 'react-query'
import { ActiveWorkflowActionType } from '../../../types/Workflow/ActiveWorkflow'
import { postActiveWorkflowAction } from '../services'
import useGetCurrentWorkflow from './useGetCurrentWorkflow'

export const useActiveWorkflowMutation = () => {
  const { data, refetch } = useGetCurrentWorkflow()

  const update = useMutation(
    (actionData: ActiveWorkflowActionType) => postActiveWorkflowAction(data?.uuid!, actionData),
    { onSuccess: () => refetch() },
  )
  return { update }
}

export default useActiveWorkflowMutation
