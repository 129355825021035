import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../core/components/CoreLoadingIcon'
import { CATALOG_ITEM } from '../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../services/UnifiedSearchService'

const CatalogProductTitle: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  if (!data) return <CoreLoadingIcon />

  return (
    <>
      <div className="text-2xl font-medium text-left">{data.description}</div>
      <div>
        By
        {' '}
        {data.manufacturerName}
      </div>
    </>
  )
}

export default CatalogProductTitle
