import { useMutation } from 'react-query'
import { putActiveWorkflowTaskStatus } from '../services'
import useGetCurrentWorkflow from './useGetCurrentWorkflow'

const useUpdateTaskStatusMutation = () => {
  const { data, refetch } = useGetCurrentWorkflow()
  const activeWorkflowUuid = data?.uuid

  const updateTaskStatus = useMutation(
    (props: { taskUuid: string, status: 0 | 1 }) => {
      const { taskUuid, status } = props
      const payload = {
        status,
        parent_type: 'active_workflow',
        parent_uuid: activeWorkflowUuid || '',
      }
      return putActiveWorkflowTaskStatus(taskUuid!, payload)
    },
    { onSuccess: () => refetch() },
  )
  return { updateTaskStatus }
}

export default useUpdateTaskStatusMutation
