// REFACTOR - types
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import usePermissions from '../../../../../core/hooks/usePermissions'
import Dot from '../../../../../shared/components/atoms/Dot'
import EditDeleteOptions from '../../../../../shared/components/organisms/menus/EditDeleteMenu'
import TwoTierTableRow from '../../../../../shared/components/organisms/TwoTierTableRow'
import { FORM_EDIT } from '../../../../../shared/constants/permissions'
import { FormQuestionType, FormSectionType } from '../../../../../types/Forms/formTypes'
import useGetFormParams from '../../hooks/useGetFormParams'
import useDeleteQuestion from './hooks/useDeleteQuestion'

interface IFormQuestion {
  question: FormQuestionType
  sectionIndex: number
  questionIndex: number
  section: FormSectionType
}

const FormQuestion: React.FC<IFormQuestion> = ({ question, questionIndex, section }) => {
  const { t } = useTranslation()
  const deleteQuestion = useDeleteQuestion(section, question)
  const history = useHistory()
  const { formId } = useGetFormParams()
  const permissions = usePermissions([{ slug: FORM_EDIT }])

  const gotoQuestionPage = () => {
    history.push(`/forms/${formId}/section/${section.__uuid}/question/${question.__uuid}/content`)
  }

  const canDelete = question.actions?.remove

  return (
    <Draggable
      draggableId={question.__uuid}
      key={question.__uuid}
      index={questionIndex}
      isDragDisabled={!section.actions.edit}
    >
      {(provided) => (
        <div
          className="flex w-full space-x-4 my-2"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <TwoTierTableRow
            onClick={gotoQuestionPage}
            title={(
              <div className="text-sm">
                <span className="font-semibold">{question.inputName ? question.inputName : ''}</span>
                {' '}
                <span>{question.label ? question.label : '-'}</span>
              </div>
            )}
            menuElement={(
              <EditDeleteOptions
                onDelete={canDelete ? deleteQuestion : undefined}
                hidden={false && !permissions.get(FORM_EDIT).allowed}
              />
            )}
            dragHandleProps={provided.dragHandleProps || undefined}
          >
            <div className="flex gap-x-2">
              <p>
                {
                  question.isFormElement
                    ? t('forms.form_elements.subheader')
                    : t(`forms.${question.type}_question`.toLowerCase())
                }
              </p>
              {question.validation.required?.value && (
                <>
                  <Dot />
                  <p>{t('generic.required')}</p>
                </>
              )}
              {question.key && (
                <>
                  <Dot />
                  <p>
                    {t('forms.key')}
                    {': '}
                    {question.key}
                  </p>
                </>
              )}
            </div>
          </TwoTierTableRow>
        </div>
      )}
    </Draggable>
  )
}

export default FormQuestion
