import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import { invalidIdStateType } from '../../../../utils/validationUtils'
import MultilineOptions from '../MultiLineInput/MultilineOptions'
import IconAndTitle from './IconAndTitle'

interface IReqInfo {
  row: LineItemItemAttributeType
  invalidIds: invalidIdStateType | undefined
}

const ReqInfo: React.FC<IReqInfo> = (
  { row, invalidIds },
) => {
  const isMultiLineInput = !!row.options.length

  return (
    <div className={`flex ${!isMultiLineInput && 'w-1/2'}`}>
      <IconAndTitle
        row={row}
        invalidIds={invalidIds}
      />
      {isMultiLineInput && <MultilineOptions row={row} />}
    </div>
  )
}

export default ReqInfo
