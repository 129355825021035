import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import useGetOffer from '../../../hooks/useGetOffer'
import BannerModal from './BannerModal'

const BannerAccepted: React.FC = () => {
  const { t } = useTranslation()
  const utils = useUtils()

  const { data: offerData } = useGetOffer()
  const reason = offerData?.status_timeline[0]?.reason

  const acceptedBannerClassNames = 'flex text-left py-3 px-8 bg-green-400 bg-opacity-20 text-green-700'

  return reason
    ? (
      <button
        type="button"
        className={acceptedBannerClassNames}
        onClick={() => utils.modal.set({
          isOpen: true,
          title: t('supplier_offer.offer_was_accepted'),
          content: <BannerModal reason={reason} />,
        })}
      >
        {t('supplier_offer.offer_accepted_with_note')}
      </button>
    )
    : <p className={acceptedBannerClassNames}>{t('supplier_offer.offer_accepted')}</p>
}

export default BannerAccepted
