import { useTranslation } from 'react-i18next'

interface IErrorMessage {
  error: string
  className?: string
  dataCy?: string
  shouldHide?: boolean
}

const ErrorMessage: React.FC<IErrorMessage> = ({
  error, className, dataCy, shouldHide,
}) => {
  const { t } = useTranslation()

  if (shouldHide) return null

  return <div className={`w-full text-alert-bright text-xs ${className}`} data-cy={dataCy}>{t(error)}</div>
}

export default ErrorMessage
