import { useTranslation } from 'react-i18next'
import generateUserName from '../../../../shared/utils/generateUserName'
import SupplierUserType from '../../../../types/Suppliers/SupplierUserType'
import NameInner from './NameInner'

interface ISupplierUserName {
  supplierUser: SupplierUserType | undefined
  className?: string
  email?: boolean
  onClick?: () => void
}

const SupplierUserName: React.FC<ISupplierUserName> = ({
  supplierUser, className, email, onClick,
}) => {
  const { t } = useTranslation()
  const userName = email ? supplierUser?.email : t(generateUserName(supplierUser))

  if (!(supplierUser && userName)) {
    return <div className="bg-red-500 rounded-full text-sm text-white px-3">No Email Provided!</div>
  }

  const componentClassName = `flex gap-1 items-center justify-start ${supplierUser?.deleted_at ? 'text-gray-400' : ''} ${onClick ? 'cursor-pointer' : 'cursor-default'} ${className}`

  return onClick
    ? (
      <button
        className={componentClassName}
        onClick={onClick}
        type="button"
      >
        <NameInner userName={userName} />
      </button>
    )
    : (
      <div className={componentClassName}>
        <NameInner userName={userName} />
      </div>
    )
}

export default SupplierUserName
