import { useTranslation } from 'react-i18next'
import ResponseChoice from './ResponseChoice'
import { FormQuestionType } from '../../../../types/Forms'

interface IOtherOptionResponseChoice {
  question: FormQuestionType
}

const OtherOptionResponseChoice: React.FC<IOtherOptionResponseChoice> = ({ question }) => {
  const { t } = useTranslation()

  const includeOtherOption = !!question.__settings?.options?.other

  if (!includeOtherOption) return null

  return (
    <div className="flex flex-col w-full gap-y-3">
      <ResponseChoice
        index={question.options?.length || 0}
        question={question}
        option={{
          __uuid: 'other',
          display_name: t('generic.other'),
          value: '',
          label: question.question_label,
        }}
      />
    </div>
  )
}

export default OtherOptionResponseChoice
