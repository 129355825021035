import { useMemo } from 'react'
import { format, subDays, parse } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { RequisitionFilterType, RequisitionFilterValueType } from '../types'
import { getLocalInfo } from '../../../../../../core/services/userService'
import useUsers from '../../../../../../shared/hooks/queries/useUsers'

export const toApiDate = (date: Date | null | undefined) => (date ? format(date, 'yyyy-MM-dd hh:mm:ss') : null)
export const fromApiDate = (apiDate: string | null | undefined) => (apiDate ? parse(apiDate, 'yyyy-MM-dd hh:mm:ss', new Date()) : null)

export const useRequisitionFilterValues = (filter: RequisitionFilterType): RequisitionFilterValueType[] => {
  const { data: usersList } = useUsers()
  const { t } = useTranslation()

  return useMemo<RequisitionFilterValueType[]>(() => {
    const userData = getLocalInfo()

    if (filter.id === 'current_status') {
      return [
        {
          id: 'Open',
          label: t('requisition_filter.open'),
        },
        {
          id: 'Closed',
          label: t('requisition_filter.Closed'),
        },
        {
          id: 'Draft',
          label: t('requisition_filter.draft'),
        },
        {
          id: 'Analyzing',
          label: t('requisition_filter.analyzing'),
        },
        {
          id: 'Analyzed',
          label: t('requisition_filter.analyzed'),
        },
        {
          id: 'Purchased',
          label: t('requisition_filter.purchased'),
        },
        {
          id: 'Canceled',
          label: t('requisition_filter.cancelled'),
        },
      ]
    }

    if (filter.id === 'created_between' || filter.id === 'ends_between') {
      const getDateRangeFromDaysAgo = (days: number = 0) => [toApiDate(subDays(new Date(), days)), toApiDate(new Date())]

      return [
        {
          id: 'date_filter_today',
          label: t('requisition_filter.today'),
          apiValue: getDateRangeFromDaysAgo(),
        },
        {
          id: 'date_filter_week',
          label: t('requisition_filter.last_week'),
          apiValue: getDateRangeFromDaysAgo(7),
        },
        {
          id: 'date_filter_month',
          label: t('requisition_filter.last_month'),
          apiValue: getDateRangeFromDaysAgo(30),
        },
        {
          id: 'date_filter_custom',
          label: t('requisition_filter.custom_date_range'),
          apiValue: null,
        },
      ] as RequisitionFilterValueType[]
    }

    if (filter.id === 'user_id' || filter.id === 'approval_flow_assignees' || filter.id === 'assigned_to') {
      return [
        {
          id: 'user_filter_me', label: 'Me', apiValue: userData?.id,
        },
        ...(usersList?.map(
          (user) => ({ id: user.id, label: `${user.first_name} ${user.last_name}` }),
        ) || []),
      ] as RequisitionFilterValueType[]
    }

    return [] as RequisitionFilterValueType[]
  }, [filter, usersList])
}

export default useRequisitionFilterValues
