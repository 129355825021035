import { useTranslation } from 'react-i18next'
import { MdCheck, MdStopCircle } from 'react-icons/md'
import CoreLoading from '../../../../core/components/CoreLoading'
import { priceToGermanPriceString } from '../../../../shared/constants/currencyFormat'
import { FormQuestionType } from '../../../../types/Forms'
import { useFormLogic } from '../../../Forms/FormResponsePage/providers/FormLogicProvider'
import useGetInvitesWithOffers from '../Award/hooks/useGetInvitesWithOffers'
import useGetRequisitionData from '../Definition/hooks/useGetRequisition'
import SubmissionPageHeaderRequisitionStatus from './SubmissionPageHeaderRequisitionStatus'
import useGetTotalPrice from '../../../Forms/FormResponsePage/hooks/useGetTotalPrice'

const SubmissionPageHeader = () => {
  const { data: requisitionData, isLoading: isLoadingRequisitionData } = useGetRequisitionData()

  const { t } = useTranslation()
  const { data: offers } = useGetInvitesWithOffers()
  const { allEnabledQuestions } = useFormLogic()

  const {
    priceEqual, value,
  } = useGetTotalPrice(requisitionData?.form_submission?.uuid, requisitionData?.form_submission?.body)

  const hasOffer = offers?.length || false
  const offerNetPrice = hasOffer ? value : 0

  if (isLoadingRequisitionData) return <CoreLoading />

  const costCenter = allEnabledQuestions?.find((q: FormQuestionType) => q.key === 'saps4-costcenter')

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-col w-full">
        <div className="bg-white p-6 rounded-md border ">
          <span className="font-bold">
            {t('approval.overview_headline')}
          </span>
          <table className="table-auto mt-4">
            <tbody>
              <tr>
                <td className="ext-primary-gray text-sm pr-6">{t('generic.status')}</td>
                <td className="text-sm"><SubmissionPageHeaderRequisitionStatus status={requisitionData?.status} /></td>
              </tr>
              {costCenter
                && (
                  <tr>
                    <td className="ext-primary-gray text-sm pr-6">{t('requisition_definition.cost_center')}</td>
                    <td className="text-sm">{(typeof costCenter?.values?.[0]?.value === 'string' && costCenter?.values?.[0]?.value) || '-'}</td>
                  </tr>
                )}
              <tr>
                <td className="ext-primary-gray text-sm pr-6">{t('approval.reason')}</td>
                <td className="text-sm">{requisitionData?.name}</td>
              </tr>
              <tr>
                <td className="ext-primary-gray text-sm pr-6">
                  {t('approval.requestor')}
                </td>
                <td className="text-sm">
                  {requisitionData?.user?.first_name}
                  {' '}
                  {requisitionData?.user?.last_name}
                </td>
              </tr>
              {hasOffer && (
                <tr>
                  <td className="ext-primary-gray text-sm pr-6 align-top items-start">
                    {' '}
                    {t('approval.offer_available_question')}
                  </td>
                  <td className="text-sm flex flex-col items-start justify-start align-top">
                    <div>
                      <div>
                        {t('generic.yes')}
                      </div>
                      {`${t('generic.price')}:  ${offerNetPrice && priceToGermanPriceString(offerNetPrice)}`}
                      {' '}
                      <br />
                      {priceEqual ? (
                        <span className=" flex space-x-1 items-center">
                          <MdCheck />
                          <span>
                            {t('approval.price_sum_equal_to_total')}
                          </span>
                        </span>
                      ) : (
                        <span className="text-xs text-red-700 flex space-x-1 items-center">
                          <MdStopCircle />
                          <span>{t('approval.price_sum_not_equal_to_total')}</span>
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SubmissionPageHeader
