import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import { CompanyUserType } from '../../../../../types/shared/RequisitionDataTypes'
import ConfirmationModal from '../../../../../shared/components/organisms/modals/ConfirmationModal'
import { apiPostResetUserPassword } from '../../../services/CompanyService'

export default (user: CompanyUserType) => {
  const { modal } = useUtils()
  const { t } = useTranslation()

  return [
    {
      name: t('generic.reset_password'),
      onClick: () => modal.set(
        {
          isOpen: true,
          title: t('generic.confirm'),
          content: (
            <ConfirmationModal
              onConfirm={() => {
                apiPostResetUserPassword({ email: user.email })
                modal.set({ isOpen: false })
              }}
            >
              {t('account.users_confirm_reset_password')}
            </ConfirmationModal>),
        },
      ),
    },
  ]
}
