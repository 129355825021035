// REFACTOR
// REPLACE INPUTSELECT WITH INPUTSEARCHSELECT
// DELETE INPUTSELECT
import { useTranslation } from 'react-i18next'
import InputSelect from '../../../../../shared/components/atoms/InputSelect'
import OptionType from '../../../../../types/shared/OptionType'
import { CustomerDocumentType } from '../../../../../types/SupplierOffer/SupplierOfferDataTypes'
import { documentTypes, documentTypesObject } from '../../../constants/documentTypeConstants'
import { DocumentContentType } from '../../../../../types/Company/DocumentType'

interface IDocumentFormTypeInput {
  documentForm?: CustomerDocumentType | DocumentContentType
  handleChange: (v: OptionType) => void
}

const DocumentFormTypeInput: React.FC<IDocumentFormTypeInput> = ({
  documentForm, handleChange,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center w-2/5 gap-4">
      <label className="text-gray-400">{t('account.document_type')}</label>
      <InputSelect
        className="text-sm w-2/3"
        options={documentTypes}
        handleChange={handleChange}
        value={documentForm?.type === 'nda' ? documentTypesObject.nda : { value: 'other', label: 'Other' }}
      />
    </div>
  )
}

export default DocumentFormTypeInput
