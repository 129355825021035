import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { MdCheck, MdClose } from 'react-icons/md'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import useDuplicateSubmission from '../../components/layout/ReqTopbar/hooks/useDuplicateSubmission'
import useApprovals from '../../hooks/useApprovals'
import ApprovalRejectForm from './ApprovalFlowRejectForm'
import useGetRequisitionId from '../../../../shared/hooks/useGetRequisitionId'

interface IApprovalFlowButtons {
}

const ApprovalFlowButtons:
  React.FC<IApprovalFlowButtons> = () => {
    const { t } = useTranslation()
    const {
      approvalData, approve, reject, unreject, updateStatus,
    } = useApprovals()
    const { modal } = useUtils()
    const requisitionId = useGetRequisitionId()

    const { mutate } = useDuplicateSubmission()

    const handleCreateNewSubmission = () => {
      mutate(requisitionId)
    }

    const isLastStep = approvalData?.steps?.filter((s) => s.approval_status !== 'NOT_REQUIRED').slice(-1)[0]?.approval_status !== 'APPROVAL_QUEUED' || false

    const handleRejectApproval = () => {
      modal.set({
        isOpen: true,
        title: t('approval.reject_question'),
        content: <ApprovalRejectForm
          handleModalClose={() => modal.set({ isOpen: false })}
          handlereject={(reason2: string) => {
            reject(reason2)
            modal.set({ isOpen: false })
          }}
        />,
      })
    }

    return (
      <div className="flex gap-x-2 ">
        {approvalData?.steps?.map((s) => s.approval_status).includes('REJECTED')
          ? (
            <>
              <button onClick={() => handleCreateNewSubmission()} type="button" className="bg-vibrant-blue px-4 py-3 rounded-md text-sm text-white flex gap-x-2 items-center">
                {t(
                  'submission.new_request_from_submission',
                )}
              </button>
              {approvalData?.current_user_approval_pending && (
                <button onClick={() => unreject()} type="button" className="bg-nds-secondary-gray px-4 py-3 rounded-md text-sm text-white flex gap-x-2 items-center">
                  <MdClose />
                  {t('submission.unreject')}
                </button>
              )}
            </>
          )
          : approvalData?.current_user_approval_pending && (
            <>
              <button
                onClick={() => {
                  if (isLastStep) {
                    updateStatus({ status: 'READY_FOR_TRANSFER', reason: 'Manually unrejected' })
                  }
                  approve()
                }}
                type="button"
                className="bg-nds-dark-green-accessible px-4 py-3 rounded-md text-sm text-white  flex gap-x-2 items-center"
              >
                <MdCheck />
                {t('approval.approve')}
              </button>
              <button onClick={handleRejectApproval} type="button" className="bg-nds-accessible-red px-4 py-3 rounded-md text-sm text-white flex gap-x-2 items-center">
                <MdClose />
                {t('approval.reject')}
              </button>
            </>
          )}

      </div>
    )
  }

export default ApprovalFlowButtons
