import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Collapsible from '../../../../../../shared/components/atoms/Collapsible/Collapsible'
import SelectInput from '../../../../../../shared/components/atoms/Input/SelectInput'
import AddressSelect from '../../../../../../shared/components/organisms/AddressSelect'
import TipTapEditor from '../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import useIsReqEditable from '../../../../utils/useIsReqEditable'
import useGetRequisitionData from '../../hooks/useGetRequisition'
import useUpdateRequisition from '../../hooks/useUpdateRequisition'
import { apiGetCategories } from '../../services/metadataService'
import MetaTable from '../Shared/MetaTable'
import GeneralInformationDateInput from './GeneralInformationDateInput'
import GeneralInformationRequiredDocs from './GeneralInformationRequiredDocs'

const GeneralInformation = () => {
  const { t } = useTranslation()
  const { data: requisitionData, isLoading } = useGetRequisitionData()
  const { editable: isEditable } = useIsReqEditable()

  const { data: categories, isLoading: isLoadingCategories } = useQuery('categories', apiGetCategories)
  const { mutate: updateRequisition } = useUpdateRequisition()

  return (
    <Collapsible title={t('req.general_information', 'General Information')} classNames="text-3xl">
      <div className="flex flex-col space-y-6">
        <AddressSelect
          disabled={!isEditable}
          label={t('req.delivery_address', 'Delivery Address')}
          selected={requisitionData?.delivery_address}
          isLoading={isLoading}
          onClear={() => updateRequisition({ delivery_address_id: null })}
          onChange={(selection) => updateRequisition({ delivery_address_id: parseInt(selection.id.toString(), 10) })}
        />
        <AddressSelect
          disabled={!isEditable}
          label={t('req.invoice_addres', 'Invoice Address')}
          selected={requisitionData?.billing_address}
          isLoading={isLoading}
          onClear={() => updateRequisition({ billing_address_id: null })}
          onChange={(selection) => updateRequisition({ billing_address_id: parseInt(selection.id.toString(), 10) })}
        />

        {/* <GeneralInformationDateInput
          disabled={!isEditable}
          label="Question Deadline"
          requisition={requisitionData}
          dateField="questions_due_date"
          isLoading={isLoading}
        /> */}

        <GeneralInformationDateInput
          disabled={!isEditable}
          label={t('req.offer_deadline', 'Offer Deadline')}
          requisition={requisitionData}
          dateField="ends_at"
          isLoading={isLoading}
        />

        <GeneralInformationDateInput
          disabled={!isEditable}
          label={t('req.expected_delivery_date', 'Expected Delivery Date')}
          requisition={requisitionData}
          dateField="expected_delivery_date"
          isLoading={isLoading}
        />

        <TipTapEditor
          editable={isEditable}
          label={t('req.general_description', 'General Description')}
          content={requisitionData?.description}
          onBlur={(editor) => updateRequisition({ description: editor.editor.getHTML() })}
        />
        {/* <Label label="Requisition Metadata" /> */}

        {/* <GeneralInformationFiles requisition={requisitionData} disabled={!isEditable} /> */}

        {categories && categories?.length > 0 && (
          <SelectInput
            disabled={!isEditable}
            label={t('generic.category', 'Category')}
            isLoading={isLoadingCategories}
            options={categories?.map((c) => ({ id: c.id, label: c.name }))}
            onChange={(selection) => updateRequisition({ category_id: parseInt(selection.id.toString(), 10) })}
            currentSelection={requisitionData?.category
              ? { id: requisitionData.category?.id, label: requisitionData.category?.name } : undefined}
          />
        )}

        <GeneralInformationRequiredDocs disabled={!isEditable} />

        <MetaTable metaData={requisitionData?.meta_data_fields} />

      </div>
    </Collapsible>
  )
}
export default GeneralInformation
