import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { apiPostSubmissionValue } from '../../constants/formService'
import {
  FormTemplateType, SubmissionValueCalculationItemType,
  SubmissionValueCalculationType,
} from '../../../../types/Forms/formTypes'
import useGetFormParams from '../../FormBuilder/hooks/useGetFormParams'
import { useFormLogic } from '../providers/FormLogicProvider'

export default (submissionIdProp?:string, submissionDataProp?:FormTemplateType) => {
  const { submissionId: submissionIdInfered } = useGetFormParams()
  const { allEnabledQuestions } = useFormLogic()
  const [value, setValue] = useState<any>(0)

  const {
    submission: [submissionDataInfered],
  } = useFormContext()

  const submissionId = submissionIdProp || submissionIdInfered
  const submissionData = submissionDataProp || submissionDataInfered

  const priceQuestions = allEnabledQuestions.filter((q) => ['prod-quantity', 'off-unit_price',
  ].includes(q.key || ''))
  const summarizableInputs:SubmissionValueCalculationItemType[] = []
  priceQuestions.forEach((i, index) => {
    if (index % 2 !== 0) return
    summarizableInputs.push({
      quantity: typeof priceQuestions?.[index]?.values?.[0]?.value === 'string' ? priceQuestions?.[index]?.values?.[0]?.value as string : '',
      price: typeof priceQuestions?.[index + 1]?.values?.[0]?.value === 'string' ? priceQuestions?.[index + 1]?.values?.[0]?.value as string : '',
    })
  })

  const { mutate, isLoading } = useMutation(
    ['get-submission-value', submissionId],
    (data:SubmissionValueCalculationType) => apiPostSubmissionValue(data),
    { onSuccess: (data) => setValue(data) },
  )

  const mutateDebounced = useCallback(debounce(mutate, 400), [mutate])

  useEffect(() => {
    if (summarizableInputs) {
      mutateDebounced({
        line_items_prices: summarizableInputs,
      })
    }
  }, [submissionData])

  return {
    mutate, isLoading, value,
  }
}
