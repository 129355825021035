import axios from 'axios'
import { RequisitionApprovalDataType, RequisitionDataType } from '../../../types/shared/RequisitionDataTypes'
import {
  apiDelete,
  apiGet, apiGetData, apiPost, apiPostData, apiPut,
} from '../../../core/utils/api/generic'
import RequisitionStatusType from '../../../types/Basecamp/RequisitionStatusesType'
import getInvitedSuppliersURL from '../utils/invitedSuppliersURL'

// APPROVAL
export const apiFetchRequisitionApproval = (requisitionId: string) => apiGetData<RequisitionApprovalDataType>(`/requisitions/${requisitionId}/approval`)
export const apiPostRequisitionApproval = (requisitionId: string, status: RequisitionStatusType) => apiPostData<{message:string, status:number }>(`/requisitions/${requisitionId}/approval/${status}`)

export const getElementSections = async (id: number) => apiGet(`/requisitions/${id}/sections`)

export const apiCreateRequisition = (requisitionData: Partial<RequisitionDataType>) => apiPostData('/requisitions', requisitionData)
export const apiPutRequisition = (requisitionId: string, payload: { category_id: string | number | undefined }) => apiPut(`/requisitions/${requisitionId}`, payload)
export const apiPutRequisitionStatus = (requisitionId: string, payload: { status: string, reason: string }) => apiPut(`/requisitions/${requisitionId}/status`, payload)
export const apiDeleteRequisition = (requisitionId: number) => apiDelete(`/requisitions/${requisitionId}`, requisitionId)

export const inviteSuppliers = (payload: any) => axios.post(getInvitedSuppliersURL(), payload)

export const apiDuplicateRequisition = async (requisitionId: number) => apiPost(`/requisitions/${requisitionId}/duplicate`)

export const apiDeclineRequisitionOffers = async (requisitionId: string) => apiDelete(`/requisitions/${requisitionId}/decline-offers`)
