import { useQueryClient } from 'react-query'
import toastr from '../../../../../../../../../../shared/components/organisms/toastr/toastr'
import useGetRequisitionId from '../../../../../../../../../../shared/hooks/useGetRequisitionId'
import useSendSupplierInviteMutation from '../../../../../../../../hooks/useSendSupplierInviteMutation'
import ReqDefQueryKeys from '../../../../../../../Definition/constants/queryKeys'
import { useCoordinateProvider } from '../../../../../../providers/CoordinateProvider'

const useSendSupplierInvites = () => {
  const { mutate: postInvites } = useSendSupplierInviteMutation()
  const {
    loadingInvites: [, setLoadingInvites],
  } = useCoordinateProvider()
  const cache = useQueryClient()
  const reqId = useGetRequisitionId()
  return async (supplierInviteId: number, reason?: string) => {
    try {
      setLoadingInvites((loadingInvites) => [...loadingInvites, supplierInviteId])
      await postInvites({ supplierInviteId, reason })

      cache.refetchQueries([ReqDefQueryKeys.requisitionData, reqId])
    } catch {
      toastr.error()
    }
  }
}

export default useSendSupplierInvites
