import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineNotListedLocation } from 'react-icons/md'
import Tooltip from '../../../../shared/components/organisms/tooltip/Tooltip'
import { FormQuestionType, FormSettingsType } from '../../../../types/Forms'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import CopyQuestionValueCheckbox from './CopyQuestionValueCheckbox'
import DefinedChoiceSelectionInfo from './DefinedChoiceSelectionInfo'
import RangeChoiceSelectionInfo from './RangeChoiceSelectionInfo'
import UniteSearch from '../sections/ProductSection/UniteSearch/UniteSearch'

interface IQuestionContext extends INodeChildren {
  questionText: string
  subtitle: string
  required?: boolean
  questionHint?: string | null
  question?: FormQuestionType
  hidden?: boolean
}

const QuestionContext = React.forwardRef<HTMLDivElement, IQuestionContext>(({
  questionText, subtitle, required, questionHint, question,
  children, hidden,
}, ref) => {
  const { t } = useTranslation()
  return (
    <div className={`flex justify-center mb-12 last:mb-0 ${hidden ? 'hidden' : ''}`} ref={ref}>
      <div className="w-full flex">

        <div className="flex flex-col gap-y-2 w-full">
          <div className="flex gap-x-4">
            <div className="flex flex-col">
              <div className="flex gap-x-2 items-center">
                <p className={`font-semibold text-black ${question?.isFormElement && 'font-bold'}`}>{`${questionText || t('generic.untitled')}${required ? '*' : ''}`}</p>
                {questionHint && (
                  <Tooltip showCondition tooltip={questionHint}>
                    <div className="h-6 w-6 bg-quaternary-grey items-center justify-center flex rounded-md">
                      <MdOutlineNotListedLocation
                        size={16}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
              {subtitle && <p className="text-nds-secondary-gray  my-2 text-sm">{subtitle}</p>}
              {/* todo form-structure-refactor clean up both below components, related to TextQuestion */}
              <DefinedChoiceSelectionInfo
                choiceSelectionSetting={undefined}
                questionType="TEXT"
              />
              <RangeChoiceSelectionInfo choiceSelectionSetting={undefined} />
            </div>
          </div>
          <div className="flex space-x-2 w-full items-center">
            <div className="w-full">{children}</div>
          </div>
          <CopyQuestionValueCheckbox question={question} />
        </div>
      </div>
    </div>
  )
})

QuestionContext.displayName = 'QuestionContext'

export default QuestionContext
