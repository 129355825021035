import { useState } from 'react'
import coreColors, { coreColorsType } from '../../../constants/coreColors'

interface ICheckbox {
  onChange?: (v: boolean) => void
  disabled?: boolean
  checked?: boolean
  className?: string
  color?: coreColorsType
  id?: string
  rounded?: boolean
  dataCy?: string
}
const Checkbox: React.FC<ICheckbox> = ({
  disabled, checked, className, color, onChange, id, rounded, dataCy,
}) => {
  const [checkedState, setCheckedState] = useState(checked)

  const parsedChecked = (checked !== undefined && onChange) ? checked : checkedState

  const style = { backgroundColor: coreColors[color || ''] }

  return (
    <input
      id={id}
      type="checkbox"
      className={`
    appearance-none
    hover:bg-rock-gray
    checked:border-transparent
    ring-0
    focus:ring-sky-blue-light focus:ring-1
    border-rock-gray
    disabled:bg-rock-gray
    cursor-pointer
    ${rounded ? 'rounded-full' : 'rounded-sm'}
    ${color || `${!disabled && 'hover:text-sky-blue-light checked:bg-sky-blue-light'} focus:text-sky-blue-light`}
    ${className}
    `}
      data-cy={dataCy}
      style={style}
      disabled={disabled}
      checked={parsedChecked}
      onChange={() => {
        onChange?.(!parsedChecked)
        setCheckedState(!parsedChecked)
      }}
    />
  )
}

export default Checkbox
