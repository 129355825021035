import { useTranslation } from 'react-i18next'
import Table from '../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'
import useFilteredSubmissions from '../../hooks/useFilteredSubmissions'
import LhotseHomeTableRow from './LhotseHomeTableRow'

const LhotseHomeTable = () => {
  const { t } = useTranslation()

  const { filteredSubmissions, isLoading } = useFilteredSubmissions()
  return (
    <TableContainer>
      <TableHead>
        <TableHeader>{t('generic.name', 'Name')}</TableHeader>
        <TableHeader>{t('generic.requestor', 'Requestor')}</TableHeader>
        <TableHeader>{t('generic.created-at', 'Created at')}</TableHeader>
        <TableHeader>{t('generic.status', 'Status')}</TableHeader>
        <TableHeader />
      </TableHead>
      <Table isLoading={isLoading}>
        {filteredSubmissions?.map((submission) => (
          <LhotseHomeTableRow key={submission.uuid} submission={submission} />
        ))}
      </Table>
    </TableContainer>
  )
}

export default LhotseHomeTable
