import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHighlightOff, MdLoop, MdTaskAlt } from 'react-icons/md'
import { RequisitionInviteType } from '../../../../../../../../types/shared/RequisitionDataTypes'
import AcceptanceStateType from '../../../../../../../../types/Requisition/AcceptanceStateType'
import useGetRequisitionData from '../../../../../Definition/hooks/useGetRequisition'
import { getSupplierButtonStyles } from '../../../../utils/OfferViewUtils'
import useOpenActionModal from './useOpenActionModal'
import useOpenNegotiationModal from './useOpenNegotiationModal'

// REFACTOR
const useGetOfferActions = (
  invite: RequisitionInviteType,
  isDisabled: boolean,
  setLastCall: Dispatch<SetStateAction<number[] | undefined>>,
) => {
  const { t } = useTranslation()

  const { data: requisition } = useGetRequisitionData()

  const openActionModal = useOpenActionModal(invite)
  const openNegotiationModal = useOpenNegotiationModal(invite, setLastCall)

  const isRequestChangesDisabled = (isDisabled && !(invite.status === 'OFFER_IN_EDITING'))

  const buttonStyles = (
    buttonStatus: AcceptanceStateType,
  ) => getSupplierButtonStyles(
    invite,
    buttonStatus,
    buttonStatus === 'OFFER_CHANGES_REQUESTED' ? isDisabled || isRequestChangesDisabled : isDisabled,
  )

  return [
    {
      key: 'accept',
      buttonClasses: buttonStyles('OFFER_ACCEPTED').buttonClasses,
      buttonTitle: <p className={`text-xs w-full ${buttonStyles('OFFER_ACCEPTED').textClasses}`}>{t('req_award.accept_offer')}</p>,
      disabled: isDisabled || !['DEADLINE_EXTENDED', 'CLOSED'].includes(requisition?.status || ''),
      onClick: () => openActionModal('accept'),
      icon: <MdTaskAlt
        className={`text-2xl ${buttonStyles('OFFER_ACCEPTED').iconClasses}`}
        data-cy="award-accept-button-icon"
      />,
      dataCy: 'accept-req-button',
    },
    {
      key: 'decline',
      buttonClasses: buttonStyles('OFFER_DECLINED_BY_CUSTOMER').buttonClasses,
      buttonTitle: <p className={`text-xs w-full ${buttonStyles('OFFER_DECLINED_BY_CUSTOMER').textClasses}`}>{t('requistition_suppliers.decline')}</p>,
      disabled: isDisabled,
      onClick: () => openActionModal('decline'),
      icon: <MdHighlightOff
        className={`text-2xl ${buttonStyles('OFFER_DECLINED_BY_CUSTOMER').iconClasses}`}
        data-cy="award-decline-button-icon"
      />,
      dataCy: 'decline-req-button',
    },
  ]
}

export default useGetOfferActions
