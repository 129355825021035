import { Suspense } from 'react'
import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'
import Table from '../../../../../../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../../../../../shared/components/atoms/SharedTable/TableHeader'
import TableRow from '../../../../../../../../../../shared/components/atoms/SharedTable/TableRow'
import { CATALOG_ITEM } from '../../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../../services/UnifiedSearchService'
import CatalogBlockPricingPrice from './CatalogBlockPricingPrice'
import CatalogBlockPricingSave from './CatalogBlockPricingSave'

interface ICatalogBlockPricingTable {
  catalogId: string
}

const CatalogBlockPricingTable: React.FC<ICatalogBlockPricingTable> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  if (!data) return <CoreLoadingIcon />

  if (!data.blockPricing?.length) return null

  return (
    <TableContainer>
      <TableHead>
        <TableHeader>Quantity</TableHeader>
        <TableHeader>Price</TableHeader>
      </TableHead>
      <Table data-cy="blockPricing-table">
        {data.blockPricing.map((price, index) => (
          <TableRow key={price.amount.value}>
            <TableCell first>
              {index > 0 && '>= '}
              {price.lowerBound}
            </TableCell>
            <TableCell last>
              <div className="flex gap-3">
                <CatalogBlockPricingPrice price={price} highlight={index > 0} />
                {index > 0
                  && (
                    <Suspense fallback={<CoreLoadingIcon />}>
                      <CatalogBlockPricingSave originalPrice={data.blockPricing[0]} price={price} />
                    </Suspense>
                  )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  )
}
export default CatalogBlockPricingTable
