import { ForwardedRef, forwardRef } from 'react'
import useGetCurrentIntake from '../hooks/useGetCurrentIntake'
import useGetPaginatedIntakeOfferings from '../hooks/useGetPaginatedIntakeOfferings'
import IntakeSection from './IntakeSections/IntakeSection'
import IntakeOfferingSection from './IntakeSections/IntakeSectionsContent/IntakeOfferingSection/IntakeOfferingSection'
import SectionWrapper from '../../../shared/components/organisms/SectionWrapper'

export const IntakeFlowOverviewPageDetails = forwardRef((props, detailRef: ForwardedRef<HTMLDivElement>) => {
  const { data: intake } = useGetCurrentIntake()
  const { data: offerings } = useGetPaginatedIntakeOfferings(1)

  if (!intake) return null

  return (
    <div ref={detailRef} className="flex flex-col gap-11 text-black-light max-w-7xl mx-auto">
      {intake.sections.map((section) => (
        <IntakeSection
          key={`intake-section-${section.id}`}
          section={section}
        />
      ))}
      {offerings && (offerings?.data.length || 0) > 0 && (
        <SectionWrapper title="Product">
          <IntakeOfferingSection offerings={offerings.data} />
        </SectionWrapper>
      )}
    </div>
  )
})
IntakeFlowOverviewPageDetails.displayName = 'IntakeFlowOverviewPageDetails'
export default IntakeFlowOverviewPageDetails
