import lodash from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import useDebouncedValue from './useDebouncedValue'

export type debouncedSearchResponseType = {
  [key: string]: any;
  query: string;
}

export function useDebouncedQuery<T>(
  key: string[],
  apiRequest: (body: any) => Promise<T>,
  query: { query: string, [key: string]: any },
) {
  const [prevSearch, setPrevSearch] = useState<any>()
  const DEBOUNCE_TIME = 500

  const debouncedSearch = useDebouncedValue(query, DEBOUNCE_TIME)

  const { data, isLoading, refetch } = useQuery(
    key,
    () => apiRequest(query),
    { enabled: false },
  )

  if (
    debouncedSearch
    && lodash(debouncedSearch).isEqual(query)
    && !lodash(debouncedSearch).isEqual(prevSearch)
  ) {
    setPrevSearch(debouncedSearch)
    refetch()
  }

  const isLoadingDebounced = isLoading || prevSearch?.query !== query.query

  return ({
    data, isLoading: isLoadingDebounced, plainIsLoading: isLoading, refetch,
  })
}

export default useDebouncedQuery
