import { useMutation } from 'react-query'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import { apiPostTransmission } from '../../../Requisition/services/WorkflowService'
import { apiSubmitSubmission } from '../../constants/formService'
import useGetFormSubmission from './useGetFormSubmission'

/*
This is a hook to "Soft-commit" a FormSubmission. This is built especially
for Advario where Purchasers can submit a requisition that is then
checked and changed by purchasers.
*/

export default (completeAction?:()=>void) => {
  const { data: Form } = useGetFormSubmission()
  const utils = useUtils()
  const { mutate: triggerTransmission, isLoading: loadingTransmission } = useMutation(
    ['transmissions', Form?.uuid],
    (requisitionId:string) => apiPostTransmission(requisitionId),
    { onSuccess: () => window.location.reload(), onSettled: () => completeAction && completeAction() },
  )

  const { mutate: submit } = useMutation(
    'submitSubmission',
    () => apiSubmitSubmission(Form?.form.uuid || '', Form?.uuid || ''),
    {
      onSuccess: async (data) => {
        triggerTransmission(data.entity_id.toString())
        utils.modal.set({ isOpen: false })
      },
    },
  )

  const submitAndTransfer = async () => {
    submit()
  }

  return { submitAndTransfer }
}
