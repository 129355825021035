import { MixedSchema, Reference } from 'yup'
import { getReferencedQuestion } from './getReferencedQuestion'
import { getTestedQuestion } from './getTestedQuestion'
import { getQuestionAnswerForComparison } from './getQuestionAnswerForComparison'

/**
 * @param {string | number | array | Reference} requestedValue
 * @param {MixedSchema} schema
 * @returns {{targetQuestion: FormQuestionType, relatedValue: (string|number|string[]|number[]|undefined|*), relatedQuestion: (FormQuestionType|undefined)}}
 */
export const getTargetValueAndQuestions = (requestedValue, schema) => {
  const targetQuestion = getReferencedQuestion(requestedValue, schema)
  const currentQuestion = getTestedQuestion(schema)

  return {
    targetQuestion,
    targetValue: targetQuestion
      ? getQuestionAnswerForComparison(targetQuestion, currentQuestion?.validation[schema.type]?.answerSelector)
      : requestedValue,
    currentQuestion,
  }
}

export default getTargetValueAndQuestions
