import { useMemo } from 'react'
import useRequisitionFilterOptions from './useRequisitionFilterOptions'
import { useRequisitionFilters } from '../provider/RequisitionFiltersProvider'

export const useAvailableRequisitionFilters = () => {
  const allFilters = useRequisitionFilterOptions()
  const { filters: selectedFilters } = useRequisitionFilters()

  return useMemo(() => allFilters.filter((filter) => !selectedFilters.some((selectedFilter) => selectedFilter.id === filter.id)), [selectedFilters, allFilters])
}

export default useAvailableRequisitionFilters
