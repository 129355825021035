import React from 'react'
import classnames from 'classnames'
import PageHeaderWithBasicNavigation from '../../../../shared/components/layout/PageHeaderWithBasicNavigation'
import { useWorkflow } from '../../providers/WorkflowProvider'
import WorkflowBuilderHeaderControls from './WorkflowBuilderHeaderControls'

export const WorkflowBuilderHeader = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { workflow, sidePanelOpen } = useWorkflow()

  return (
    <PageHeaderWithBasicNavigation title={workflow.name} ref={ref} className={classnames({ '!pr-2': sidePanelOpen })} sideSection={<WorkflowBuilderHeaderControls />} />
  )
})

WorkflowBuilderHeader.displayName = 'WorkflowPageHeader'

export default WorkflowBuilderHeader
