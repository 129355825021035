import { MdOutlineAddComment } from 'react-icons/md'

interface IToggleCommentButton {
  enabled: boolean
  toggleEnabled: () => void
  disabled?: boolean
}

const ToggleCommentButton: React.FC<IToggleCommentButton> = ({
  enabled, toggleEnabled, disabled,
}) => (
  <button
    type="button"
    onClick={toggleEnabled}
    disabled={disabled}
  >
    <MdOutlineAddComment
      className={`min-w-[20px] ${enabled ? 'text-sky-blue' : 'text-gray-400'}`}
      size={20}
    />
  </button>
)

export default ToggleCommentButton
