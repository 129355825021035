import { useUtils } from '../../../../core/providers/UtilsProvider'
import useGetFormParams from './useGetFormParams'

export default () => {
  const params = useGetFormParams()
  const { modal } = useUtils()

  return modal.get.isOpen
    ? window.location.href.split('/')[4]
    : params?.formId
}
