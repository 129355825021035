import { MdAdd } from 'react-icons/md'
import { FORM_SUBMIT, REQUISITION_ADD } from '../../../../shared/constants/permissions'
import usePermissions from '../../../hooks/usePermissions'
import CoreLoadingIcon from '../../CoreLoadingIcon'
import CoreSidebarButton from './CoreSidebarButton'

interface ICreateNewRequisitionButton {
  minified: boolean
}

const CreateNewRequisitionButton: React.FC<ICreateNewRequisitionButton> = ({ minified }) => {
  const permissions = usePermissions([{ slug: REQUISITION_ADD }, { slug: FORM_SUBMIT }])

  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  if (!isFunkeOrAdvario) return <div className="h-8" />
  if (permissions.get(REQUISITION_ADD).isLoading) return <CoreLoadingIcon className="mt-4" />

  return (
    <div className="h-18 w-full block">
      {permissions.oneOf([{ slug: REQUISITION_ADD }, { slug: FORM_SUBMIT }]) && (
        <CoreSidebarButton
          minified={minified}
          item={{
            name: 'basecamp.new_requisition',
            sections: ['/new'],
            href: '/new',
            icon: MdAdd,
            type: 'button',
            cy: 'basecamp-create-req-button',
          }}
        />
      )}
    </div>
  )
}

export default CreateNewRequisitionButton
