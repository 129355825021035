import { invalidIdStateType } from '../utils/validationUtils'
import CoreSections from './CoreSections/CoreSections'
import TopBanner from './TopBanner/TopBanner'

interface ISupplierView {
  invalidIds: invalidIdStateType
}

const SupplierView: React.FC<ISupplierView> = ({ invalidIds }) => (
  <div className="flex flex-col w-2/3 relative">
    <TopBanner />
    <CoreSections invalidIds={invalidIds} />
  </div>
)

export default SupplierView
