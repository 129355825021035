import { useFormContext } from '../../../../core/providers/FormProvider'
import FormSectionNavigationButton from './FormSectionNavigationButton'
import { FormSectionType } from '../../../../types/Forms'
import { useFormSection } from '../providers/FormSectionProvider'

const FormSectionsNavigationHeader: React.FC = () => {
  const { submission: [submissionData] } = useFormContext()

  const { responseConfig: [responseConfig] } = useFormContext()
  const { currentSection } = useFormSection()
  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  if (responseConfig?.viewType !== 'simple') return null

  const rawSections = [...submissionData?.sections || [], { __uuid: 'Confirmation', name: 'Confirmation', type: 'FINAL' } as FormSectionType]
  const firstProductSectionIndex = rawSections.findIndex((section) => section.type === 'PRODUCT')

  const sections = rawSections.filter((section, index) => (section.type !== 'PRODUCT' || firstProductSectionIndex === index) || isFunkeOrAdvario)

  const currentIndex = sections.findIndex((s) => s.__uuid === currentSection?.__uuid)
  const positionDecimal = ((currentIndex + 1) / sections.length) * 100
  const middleAlignPercentage = 100 / sections.length / 2
  const targetPosition = positionDecimal - middleAlignPercentage
  const positionPercent = `${targetPosition}%`

  return (
    <div className="flex flex-col bg-white  pt-4 gap-y-4">
      <div className="flex font-semibold max-w-screen overflow-auto
      justify-between w-full -mb-0.5 "
      >
        {sections?.map((section, index) => (
          <FormSectionNavigationButton
            key={section.__uuid}
            section={section}
            index={index}
          />
        ))}
      </div>
      <div className="overflow-hidden rounded-full w-full bg-gray-300">
        <div className="h-1 rounded-full bg-sky-blue" style={{ width: positionPercent }} />
      </div>
    </div>
  )
}

export default FormSectionsNavigationHeader
