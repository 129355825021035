import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../../core/providers/UtilsProvider'
import { RequisitionInviteType } from '../../../../../../../../types/shared/RequisitionDataTypes'
import NegotiationModal from '../../../NegotationModals/OfferViewNegotiationModal/NegotiationModal'

const useOpenNegotiationModal = (
  invite: RequisitionInviteType,
  setLastCall: Dispatch<SetStateAction<number[] | undefined>>,
) => {
  const { modal } = useUtils()
  const { t } = useTranslation()

  return () => {
    modal.set({
      isOpen: true,
      title: t('offer_view.negotation_how_want_to'),
      content: <NegotiationModal
        invite={invite}
        setLastCall={setLastCall}
      />,
    })
  }
}

export default useOpenNegotiationModal
