import React, {
  createContext, useContext, useMemo,
} from 'react'

import { PartialSection } from '../../../../../core/providers/FormProvider'
import { EvaluatedFormSectionType, FormQuestionType } from '../../../../../types/Forms'
import useEvaluatedSections from './hooks/useEvaluatedSections'

type IFormLogicContext = {
  enabledSections: EvaluatedFormSectionType[],
  allEnabledQuestions: FormQuestionType[],
  allProcessableQuestions: FormQuestionType[],
  enabledQuestionsExceptProducts: FormQuestionType[],
  isSectionEnabled: (section: PartialSection) => boolean
  findSectionIndex: (uuidToCheck: string) => number
}

const formLogicContext = createContext({} as IFormLogicContext)

type FormLogicProviderProps = React.PropsWithChildren<{}>

const FormLogicProvider: React.FC<FormLogicProviderProps> = ({ children }) => {
  const enabledSections = useEvaluatedSections()

  const values: IFormLogicContext = useMemo(() => ({
    enabledSections,
    enabledQuestionsExceptProducts: enabledSections
      .filter(({ type }) => type !== 'PRODUCT')
      .flatMap(({ enabledFields }) => enabledFields),
    allEnabledQuestions: enabledSections.flatMap(({ enabledFields, type }) => enabledFields.map((ef) => ({ ...ef, sectionType: type }))),
    allProcessableQuestions: enabledSections.flatMap(({ processableFields }) => processableFields),
    isSectionEnabled: ({ __uuid: uuidToCheck }: PartialSection) => !!enabledSections.find(({ __uuid }) => __uuid === uuidToCheck),
    findSectionIndex: (uuidToCheck: string) => enabledSections.findIndex(({ __uuid }) => uuidToCheck === __uuid),
  }), [enabledSections])

  return (
    <formLogicContext.Provider value={values}>
      {children}
    </formLogicContext.Provider>
  )
}

export default FormLogicProvider

export const useFormLogic = () => useContext(formLogicContext)
