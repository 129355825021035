import { useMutation, useQueryClient } from 'react-query'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import queryKeys from '../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorSectionType } from '../constants/editorTypes'
import { deleteRow } from '../services/editorService'

export default function useDeleteAttribute() {
  const requisitionId = useGetRequisitionId()
  const cache = useQueryClient()
  return useMutation(
    (attributeId: number) => deleteRow(attributeId),
    {
      onMutate: (attributeId) => {
        const data: ReqDefEditorSectionType[] | undefined = cache.getQueryData([queryKeys.SECTIONS_DATA, requisitionId])
        cache.setQueryData(
          [queryKeys.SECTIONS_DATA, requisitionId],
          () => data?.map((d) => ({ ...d, item_attributes: d?.item_attributes?.filter((ia) => ia.id !== attributeId) })),
        )
      },
    },
  )
}
