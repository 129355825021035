import SharedAutoSave from '../../../../../shared/components/organisms/AutoSave/AutoSave'

interface IAutoSaveBanner {
  autoSave: boolean
  dataCy?: string
  title: string
}

const AutoSaveBanner: React.FC<IAutoSaveBanner> = ({
  autoSave, dataCy, title,
}) => (
  <div className="flex justify-between border-b p-5 text-sm" data-cy={dataCy}>
    <p
      className="text-base"
      data-cy="req-def-description"
    >
      {title}
    </p>
    <SharedAutoSave autoSave={autoSave} />
  </div>
)

export default AutoSaveBanner
