import {
  apiGet, apiPost, apiPut, apiPutData,
} from '../../../core/utils/api/generic'
import {
  ActiveWorkflow,
  ActiveWorkflowActionType,
  TaskStatusPayloadType,
  TaskStatusUpdateResponseType,
} from '../../../types/Workflow/ActiveWorkflow'

export const getActiveWorkflow = (intakeId: string) => apiGet<ActiveWorkflow>(`/active-workflow/${intakeId}`)

export const postActiveWorkflowAction = (activeWorkflowId: string, data:ActiveWorkflowActionType) => apiPost<ActiveWorkflow>(`/active-workflow/${activeWorkflowId}/action`, data)

export const putActiveWorkflowTaskStatus = (taskUuid: string, data: TaskStatusPayloadType) => apiPutData<TaskStatusUpdateResponseType>(`/tasks/${taskUuid}/status`, data)

export default getActiveWorkflow
