import {
  WorkflowBlock, WorkflowBlockApprover, WorkflowBlockTrigger, WorkflowStep,
} from '../../../types/Workflow/WorkflowBlock'
import { apiDelete, apiPost, apiPut } from '../../../core/utils/api/generic'

export type CreateWorkflowStepRequestBody = {
  position: WorkflowStep['position']
  blocks: Partial<WorkflowBlock>[]
}

const assureApproverIsBackendCompatible = (approver: Partial<WorkflowBlockApprover>) => ({
  ...approver,
  user_id: approver.user ? approver.user.id : approver.user_id,
})

const assureInputIsBackendCompatible = (input: Partial<WorkflowBlockTrigger['input']>) => ({
  ...input,
  input_uuid: input.uuid,
  input_type: input.type,
})

const assureTriggerIsBackendCompatible = (trigger: Partial<WorkflowBlockTrigger>) => ({
  ...trigger,
  input: trigger.input && assureInputIsBackendCompatible(trigger.input),
  input_uuid: trigger.input
    ? trigger.input.uuid : trigger.input_uuid,
  input_type: trigger.input ? trigger.input.type : trigger.input_type,
})

const assureBlockIsBackendCompatible = (block: Partial<WorkflowBlock>) => ({
  ...block,
  triggers: block.triggers?.map(assureTriggerIsBackendCompatible),
  approvers: block.approvers?.map(assureApproverIsBackendCompatible),
})

const assureStepIsBackendCompatible = (step: Partial<CreateWorkflowStepRequestBody>) => ({
  ...step,
  blocks: step.blocks?.map(assureBlockIsBackendCompatible),
})

export const createWorkflowStep = (workflowUuid: string, workflowVersion: number, workflowStepData: CreateWorkflowStepRequestBody) => apiPost(`/workflows/${workflowUuid}/version/${workflowVersion}/steps`, assureStepIsBackendCompatible(workflowStepData))

export const createWorkflowBlock = (workflowUuid: string, workflowVersion: number, workflowStepUuid: string, workflowBlocksData: Partial<WorkflowBlock>[]) => apiPost(`/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks`, assureStepIsBackendCompatible({ blocks: workflowBlocksData }))

export const updateWorkflowBlock = (workflowUuid: string, workflowVersion: number, workflowStepUuid: string, workflowBlockUuid: string, workflowBlocksData: Partial<WorkflowBlock>) => apiPut(`/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}`, assureBlockIsBackendCompatible(workflowBlocksData))

export const deleteWorkflowBlock = (workflowUuid: string, workflowVersion: number, workflowStepUuid: string, workflowBlockUuid: string) => apiDelete(`/workflows/${workflowUuid}/version/${workflowVersion}/steps/${workflowStepUuid}/blocks/${workflowBlockUuid}`)
