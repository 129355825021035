import { useMutation, useQueryClient } from 'react-query'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { apiPutSubmission } from '../../constants/formService'
import useGetFormParams from '../../FormBuilder/hooks/useGetFormParams'
import useGetFormSubmission from './useGetFormSubmission'
import { FormSubmissionType } from '../../../../types/Forms'
import useBackendSubmission from './useBackendSubmission'

export default () => {
  const { formId, submissionId } = useGetFormParams()
  const { submission: [submission] } = useFormContext()

  const { data: submissionFormData } = useGetFormSubmission()
  const queryClient = useQueryClient()

  const submissionWithBackendData = useBackendSubmission()

  const submissionFormQueryKeys = ['get-submission', submissionId, formId]
  const { mutate } = useMutation(
    'update-form',
    () => apiPutSubmission(formId, submissionId, {
      body: submissionWithBackendData,
    }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(submissionFormQueryKeys)

        const previousSubmission = queryClient.getQueryData(submissionFormQueryKeys)

        queryClient.setQueryData<FormSubmissionType>(
          submissionFormQueryKeys,
          (input?: FormSubmissionType) => ({ ...(input || {}), body: submission }) as FormSubmissionType,
        )

        return { previousSubmission } as { previousSubmission: FormSubmissionType }
      },
      onError: (err, newTodo, context?: { previousSubmission: FormSubmissionType }) => {
        queryClient.setQueryData(submissionFormQueryKeys, context?.previousSubmission)
        queryClient.invalidateQueries(submissionFormQueryKeys)
      },
    },
  )

  if (submissionId && !submissionFormData?.submitted) {
    return () => {
      setTimeout(() => { mutate() }, 0)
    }
  }

  return () => { }
}
