import { useFormData } from '../../providers/FormDataProvider'
import { useFormLogic } from '../../providers/FormLogicProvider'
import ConfirmationSectionsProductSection from './ConfirmationSectionsProductSection'
import ConfirmationSectionsSection from './ConfirmationSectionsSection'

const ConfirmationSections: React.FC = () => {
  const { enabledSections } = useFormLogic()
  const { navigationSectionsIndex } = useFormData()

  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  const navSectionsUuids = navigationSectionsIndex.map((section) => section.__uuid)

  return (
    <div className="flex flex-col  gap-y-4 pb-6  w-full" id="printoverview">
      <div className="flex flex-col space-y-8">
        {enabledSections.filter((es) => navSectionsUuids.includes(es.__uuid) || isFunkeOrAdvario).filter((es) => es.name !== 'forms.confirmation_section')
          .map(
            (s) => (s.type !== 'PRODUCT' || isFunkeOrAdvario ? (
              <ConfirmationSectionsSection
                section={s}
                key={s.__uuid}
              />
            ) : (
              <ConfirmationSectionsProductSection
                section={s}
                key={s.__uuid}
              />
            )),
          )}
      </div>
    </div>

  )
}

export default ConfirmationSections
