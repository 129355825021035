import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { apiLoginUser } from '../../../services/loginService'

const useLoginMutation = () => {
  const history = useHistory()

  const query = new URLSearchParams(useLocation().search)

  const getQueryString = () => {
    if (query.get('redirect')) {
      return `redirect=${query.get('redirect')}`
    }
    return ''
  }

  return useMutation(apiLoginUser, {
    onError: () => {
      toastr.error('error.login_failed', 'login-failed')
    },
    onSuccess: (token) => {
      history.push(`/sso-login/${token}?${getQueryString()}`)
    },
  })
}

export default useLoginMutation
