/* eslint-disable react/no-unused-prop-types */

import { useTranslation } from 'react-i18next'
import { ValidationConfig, ValidationPropertyConfig } from '../ValidationSchemas'
import QuestionSliderSettingRow from '../../../components/inputs/QuestionSliderSettingRow'
import useValidationProperty from './hooks/useValidationProperty'
import { FormQuestionType, FormQuestionValidationProperty } from '../../../../../../../types/Forms'

type BooleanPropertyProps = {
  question: FormQuestionType
  property: keyof ValidationConfig
  config: ValidationPropertyConfig
  onUpdate: (updatedValue?: FormQuestionValidationProperty<boolean>) => void
}

export const BooleanValidationProperty = ({ property, onUpdate }: BooleanPropertyProps) => {
  const { t } = useTranslation()
  const { currentValue } = useValidationProperty<boolean>(property)

  return (
    <QuestionSliderSettingRow
      title={t(`forms.validation.settings.${property}.label`)}
      description={t(`forms.validation.settings.${property}.description`)}
      onChange={() => (
        onUpdate({ value: !currentValue, target: undefined })
      )}
      checked={currentValue}
    />
  )
}

export default BooleanValidationProperty
