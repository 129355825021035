import { Redirect } from 'react-router-dom'
import { INodeChildren } from '../../../types/shared/GeneralTypes'
import { getToken } from '../../utils/apiClient'
import useUpdateFeatureFlagAbility from './useUpdateFeatureFlagAbility'
import useGetCurrentUser from './useGetCurrentUser'
import CoreLoading from '../../components/CoreLoading'

const AuthMiddleware: React.FC<INodeChildren> = ({ children }) => {
  const token = getToken()

  const { isLoadingFeatureFlags } = useUpdateFeatureFlagAbility()
  useGetCurrentUser()

  if (!token) {
    return <Redirect to={`/login?redirect=${window.location.pathname}`} />
  }

  if (isLoadingFeatureFlags) return <CoreLoading />

  return <div>{children}</div>
}

export default AuthMiddleware
