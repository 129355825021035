import { FC } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import InputWithDropDown from '../../../../../../shared/components/atoms/Input/InputWithDropdown'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import ReqDefRowType from '../../../../../../types/Requisition/rowType'
import { ITEM_ATTRIBUTE_TYPE_ATTRIBUTE, ITEM_ATTRIBUTE_TYPE_PRICE } from '../../constants/sectionKeys'
import usePostNewAttribute from '../../hooks/usePostNewAttribute'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import usePutAttribute from '../../hooks/usePutAttribute'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useDeleteAttribute from '../../hooks/useDeleteAttribute'
import QuestionTypes from '../../constants/questionTypes'
import EmptyState from '../../../../../../shared/components/organisms/EmptyState/EmptyState'
import Label from '../../../../../../shared/components/atoms/Label'
import DeleteButton from '../../../../../../shared/components/atoms/buttons/DeleteButton'
import useAutoSave from '../../../../utils/useAutoSave'
import MandatoryButton
  from '../ReqDefContainer/EditorSection/SectionWrapper/EditorRow/EditorRowOptions/MandatoryButton'
import { ReqDefEditorSectionType } from '../../constants/editorTypes'

export interface ISupplierQuestions {
  section: ReqDefEditorSectionType,
  attributes?: Omit<LineItemItemAttributeType, 'line_items'>[]
  isLoading?: boolean
  disabled?: boolean
}
const SupplierQuestions: FC<ISupplierQuestions> = ({
  section, attributes, isLoading, disabled,
}) => {
  const { t } = useTranslation()
  const isQuestionType = ({ type }: { type: ReqDefRowType }) => ![ITEM_ATTRIBUTE_TYPE_PRICE, ITEM_ATTRIBUTE_TYPE_ATTRIBUTE]
    .includes(type)
  const addQuestion = usePostNewAttribute()
  const { mutate: updateAttribute } = usePutAttribute()
  const { mutate: deleteAttribute } = useDeleteAttribute()

  const [, updateAutoSave] = useAutoSave()

  return (
    <div className="flex flex-col space-y-2">
      <Label label={t('req.supplier_questions', 'Supplier Questions')} />

      {attributes?.filter(isQuestionType)
        .map((attribute) => (
          <div key={attribute.id} className="flex space-x-2 items-center">
            <InputWithDropDown
              disabled={disabled}
              key={attribute.id}
              isLoading={isLoading}
              onChange={(selection) => updateAttribute({ ...attribute, type: selection.id as ReqDefRowType })}
              onBlur={(value) => updateAttribute({ ...attribute, description: value?.toString() || '' })}
              options={QuestionTypes}
              currentSelection={{ id: attribute.id, label: QuestionTypes.find((item) => item.id === attribute.type)?.label as ReqDefRowType }}
              defaultValue={attribute.description || ''}
            />
            {!disabled && (
              <>
                <MandatoryButton
                  isFirstRow={false}
                  updateAutoSave={updateAutoSave}
                  section={section}
                  itemAttribute={attribute}
                />
                <DeleteButton deleteFunction={() => deleteAttribute(attribute.id)} />
              </>

            )}
          </div>
        ))}
      {!disabled
        ? <AddButton label={t('req.add_supplier_question', 'Add supplier question')} onClick={() => addQuestion(section.id, 'Text')} />
        : null}
    </div>
  )
}

export default SupplierQuestions
