import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from 'react-icons/ai'
import { useLocalStorage } from '../../../providers/LocalStorageProvider'

interface ICoreSidebarMinifyButton {
  minified: boolean
}

const CoreSidebarMinifyButton: React.FC<ICoreSidebarMinifyButton> = ({ minified }) => {
  const [, setValue] = useLocalStorage()

  const toggleMinified = () => {
    setValue('sidebarMinified', JSON.stringify(!minified))
  }

  return (
    <button
      className="hover:bg-gray-100 hover:text-gray-600 border-t border-gray-100 mt-4 py-2 flex w-full justify-center text-white"
      type="button"
      onClick={toggleMinified}
    >
      {minified ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />}
    </button>
  )
}

export default CoreSidebarMinifyButton
