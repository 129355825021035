import { useTranslation } from 'react-i18next'
import { MdAllInbox, MdBookmarkBorder, MdHistory } from 'react-icons/md'

const iconStyles = 'text-md'
const textStyles = 'text-xs'

interface INewRequisitionTemplateTypeSelector {
  type: number
  className?: string
}
const NewRequisitionTemplateTypeSelector: React.FC<INewRequisitionTemplateTypeSelector> = (
  { type, className },
) => {
  const { t } = useTranslation()
  const selectTypeIcon = (TemplateType: number) => {
    switch (TemplateType) {
      case 1: return (
        <>
          <MdBookmarkBorder className={iconStyles} />
          <p className={textStyles}>{t('requisition.lhotse_template')}</p>
        </>
      )
      case 2: return (
        <>
          <MdHistory className={iconStyles} />
          <p className={textStyles}>
            {t('requisition.previous_requisition')}
          </p>
        </>
      )
      case 3: return (
        <>
          <MdAllInbox className={iconStyles} />
          <p className={textStyles}>{t('requisition.catalogue_item')}</p>
        </>
      )
      default: return (
        <MdHistory className={iconStyles} />
      )
    }
  }

  return (
    <div className={`text-gray-400 gap-1 items-center flex ${className}`}>
      {selectTypeIcon(type)}
    </div>
  )
}

export default NewRequisitionTemplateTypeSelector
