import { useState } from 'react'

const useIsTooltipTextTruncated = () => {
  const [textRef, setTextRef] = useState<HTMLParagraphElement>()

  const isTextTruncated = textRef
    ? textRef.offsetWidth < textRef.scrollWidth
    : false

  return { ref: (ref: HTMLParagraphElement) => setTextRef(ref), isTextTruncated }
}

export default useIsTooltipTextTruncated
