import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
// import TextInput from '../../../../../shared/components/forms/Inputs/formik/TextInput'
import SubmitButton from '../../../../../shared/components/atoms/buttons/SubmitButton'
import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import TextInput from '../../../../../shared/components/organisms/formik/FormikTextInput'
import useResetPassword from '../../../hooks/useResetPassword'
import validationSchema from '../utils/ResetPasswordForm.validation'

interface IResetPasswordForm {
  token: string
  email: string
}
const ResetPasswordForm: React.FC<IResetPasswordForm> = ({ token, email }) => {
  const { t } = useTranslation()

  const initialValues = {
    email,
    password: '',
    token,
  }

  const { mutate: submitResetPassword, isLoading } = useResetPassword()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(v) => submitResetPassword(v)}
      validateOnBlur={false}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <input type="hidden" value={token || ''} />

          <label htmlFor="email">
            <p className="py-2">{t('login.enter_address')}</p>
            <TextInput
              type="email"
              name="email"
              handleChange={handleChange}
              placeholder="Email"
              values={values}
              inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors.email && 'border-red-500'}`}
              error={errors.email && touched.email && (
                <ErrorMessage error={errors.email} dataCy="email-error" />
              )}
              handleOnEnter={handleSubmit}
              required
              dataCy="email-input"
            />
          </label>
          <label htmlFor="password">
            <p className="py-2">{t('login.enter_password')}</p>
            <TextInput
              type="password"
              name="password"
              handleChange={handleChange}
              placeholder="password"
              values={values}
              inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors.password && 'border-red-500'}`}
              error={errors.password && touched.password && (
                <ErrorMessage error={errors.password} dataCy="password-error" />
              )}
              handleOnEnter={handleSubmit}
              required
              dataCy="password-input"
            />
          </label>
          <div className="flex justify-end pt-5 align-middle">
            <SubmitButton isLoading={isLoading}>
              {t('generic.reset_password')}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
