import WorkflowsTableRow from './WorkflowsTableRow'
import useGetWorkflows from '../../hooks/useGetWorkflows'
import Table from '../../../../shared/components/atoms/SharedTable/Table'

const WorkflowsTableBody: React.FC = () => {
  const { data: workflows } = useGetWorkflows()

  return (
    <Table>
      {workflows?.map((workflow) => (
        <WorkflowsTableRow
          key={`workflow-${workflow.uuid}-row`}
          workflow={workflow}
        />
      ))}
    </Table>
  )
}

export default WorkflowsTableBody
