import { createContext } from 'react'
import { createContextualCan } from '@casl/react'
import ability from '../../config/ability'
import { INodeChildren } from '../../types/shared/GeneralTypes'

export const AbilityContext = createContext(ability)
export const Can = createContextualCan(AbilityContext.Consumer)

const AbilityProvider: React.FC<INodeChildren> = ({ children }) => (
  <AbilityContext.Provider value={ability}>
    {children}
  </AbilityContext.Provider>
)

export default AbilityProvider
