import { useQueryClient } from 'react-query'
import { LineItemSectionType, LineItemType } from '../../../types/shared/LineItemDataTypes'
import OfferViewQueryKeys from '../../Requisition/subModules/Award/constants/OfferViewQueryKeys'

export default () => {
  const cache = useQueryClient()

  return (
    data: { data: LineItemType },
    variables: any,
    sectionIndex: number,
  ) => {
    cache.setQueryData<LineItemSectionType[] | undefined>([OfferViewQueryKeys.SECTIONS_DATA], (
      oldData: LineItemSectionType[] | undefined,
    ) => {
      if (!oldData || !data) { return oldData }
      const updatedData = oldData

      const { data: updatedLineItem } = data

      const itemAttribute = updatedData[sectionIndex].item_attributes
        .find((attribute) => attribute.id === variables.item_attribute_id)

      if (!itemAttribute) return oldData

      if (!['price', 'multi-select', 'yes-no', 'options'].includes(itemAttribute.type || '')) {
        itemAttribute.line_items = [updatedLineItem]
        return updatedData
      }

      if (!variables.lineItemId) {
        itemAttribute.line_items = [...itemAttribute.line_items, updatedLineItem]
        return updatedData
      }

      const mappedLineItems = itemAttribute
        .line_items.map((lineItem: LineItemType) => (
          lineItem.id === updatedLineItem.id
            ? updatedLineItem
            : lineItem
        ))
      itemAttribute.line_items = mappedLineItems

      return updatedData
    })
  }
}
