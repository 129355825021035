import { useTranslation } from 'react-i18next'
import Assignees from '../../../../../shared/components/organisms/AssigneesSelector/AssigneesSelector'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import useGetRequisitionData from '../../../../Requisition/subModules/Definition/hooks/useGetRequisition'

const QAAssignment: React.FC = () => {
  const { t } = useTranslation()
  const requisitionId = useGetRequisitionId()

  const { data: requisitionData } = useGetRequisitionData()

  return (
    <div className="overflow-visible overflow-y-visible">
      <div className="border-b p-3 flex w-full justify-between ">
        <div className="flex flex-col gap-y-1">
          <p className="text-sm text-left line-clamp-2">
            {t('qa.assignments_title')}
          </p>
          <p className="text-xs text-gray-500">{t('qa.assignments_subtitle')}</p>
        </div>
        <div className="flex items-center gap-y-1 pl-1">
          <Assignees
            origin="qa"
            modelId={requisitionId}
            assignments={requisitionData?.assignments}
            dataCy="qa-assignment-assignees"
          />
        </div>
      </div>
    </div>
  )
}
export default QAAssignment
