import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { INodeChildren } from '../../../types/shared/GeneralTypes'
import TitlebarSearch from './TitlebarSearch'

export interface ITitleBar extends INodeChildren {
  title: string | ReactElement
  inputState?: [string, (input: string) => any]
  small?: boolean
  dataCy?: string
}

const Titlebar: React.FC<ITitleBar> = ({
  title, children, inputState, small, dataCy,
}) => {
  const { t } = useTranslation()
  return (
    <div className={`gap-4 ${small ? 'pb-2' : 'pb-3'} w-full`}>
      <div className="flex justify-between items-center relative w-full pb-2 border-b border-gray-30">
        <div className={`${small ? 'text-sm' : 'text-lg'} `}>
          {typeof title === 'string' ? t(title) : title}
        </div>
        {inputState && <TitlebarSearch inputState={inputState} dataCy={dataCy} />}
        {children}
      </div>
    </div>
  )
}

export default Titlebar
