import { useState } from 'react'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import FileUpload from '../../../../../../shared/components/atoms/FileUpload/FileUpload'
import useOfferIsDisabled from '../../../../hooks/useOfferIsDisabled'
import useRemoveSupplierOfferFile from '../../../../hooks/useRemoveSupplierOfferFile'
import useUploadSupplierOfferFile from '../../../../hooks/useUploadSupplierOfferFile'

interface IOfferFileUpload {
  row: LineItemItemAttributeType
  updateAutoSave: () => void
}

const OfferFileUpload: React.FC<IOfferFileUpload> = ({ row, updateAutoSave }) => {
  const [fileIsLoading, setFileIsLoading] = useState(false)
  const lineItem = row?.line_items?.length ? row.line_items[0] : false

  const removeFile = useRemoveSupplierOfferFile(row)
  const uploadFile = useUploadSupplierOfferFile(row, setFileIsLoading, updateAutoSave)

  const isDisabled = useOfferIsDisabled()
  return (
    <FileUpload
      uploadFile={uploadFile}
      removeFile={removeFile}
      files={lineItem ? lineItem.files : []}
      fileIsLoading={fileIsLoading}
      disabled={isDisabled}
    />
  )
}

export default OfferFileUpload
