import { PlusIcon } from '@heroicons/react/24/outline'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import CorePageContainerLayout from '../../core/components/layout/CorePageContainerLayout'
import CoreTopbarLayout from '../../core/components/layout/CoreTopbarLayout'
import Button from '../../shared/components/atoms/buttons/Button'
import PageTopbar from '../../shared/components/layout/PageTopBar'
import LhotseHomeTable from './components/LhotseHomeTable/LhotseHomeTable'
import LhotseHomeSearch from './components/LhotseHomeTable/LhotseHomeSearch'
import LhotseHomeStats from './components/LhotseHomeStats/LhotseHomeStats'

const LhotseHome = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <CoreTopbarLayout topbar={(
      <PageTopbar
        title="Intake Flows"
        actions={(
          <Button className="btn-primary" title={t('generic.new-intake-flow', 'New Intake Flow')} onClick={() => history.push('/intake-flows/create')}>
            <PlusIcon className="w-4" />
            {t('generic.new-intake-flow', 'New Intake Flow')}
          </Button>
        )}
      />
    )}
    >
      <CorePageContainerLayout className="flex justify-center">
        <div className="flex flex-col max-w-4xl w-full gap-y-4">
          <LhotseHomeStats />
          <LhotseHomeSearch />
          <LhotseHomeTable />
        </div>
      </CorePageContainerLayout>
    </CoreTopbarLayout>
  )
}

export default LhotseHome
