import { useQuery } from 'react-query'
import { getCustomerDocuments } from '../../../../Requisition/subModules/Definition/services/documentsService'
import companyQueryStrings from '../../../constants/companyQueryStrings'

const useGetCustomerDocuments = () => useQuery(
  [companyQueryStrings.customer_documents],
  getCustomerDocuments,
)

export default useGetCustomerDocuments
