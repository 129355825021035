import TipTapEditor from '../../organisms/TipTapEditor/TipTapEditor'
import { TipTapOnBlurPropType } from '../../organisms/TipTapEditor/TipTapEditorTypes'

interface ITipTapInputGray {
  placeholder?: string
  onChange?: (text: string) => void
  onBlur?: TipTapOnBlurPropType
  content?: string
  dataCy?: string
  className?: string
  editorContentClassName?: string
}

const TipTapInputGray: React.FC<ITipTapInputGray> = ({
  placeholder, onChange, onBlur, content, dataCy, className, editorContentClassName,
}) => (
  <TipTapEditor
    placeholder={placeholder}
    onUpdate={onChange}
    className={`text-sm w-full rounded-md flex text-black max-w-full cursor-text border border-gray-200 ProseMirror-TipTapInputGray ${className}`}
    editorContentClassName={`p-0 placeholder-gray-400 ${editorContentClassName}`}
    onBlur={onBlur}
    expandedEditor
    content={content || ''}
    dataCy={dataCy}
  />
)

export default TipTapInputGray
