// REFACTOR (not actually but test)
import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import TypeTag from './TypeTag'

interface IIsResubmittedTag {
  invite: RequisitionInviteType
}

const IsResubmittedTag: React.FC<IIsResubmittedTag> = ({
  invite,
}) => {
  const { t } = useTranslation()
  const isResubmitted = invite?.status_timeline && invite?.status_timeline[0]?.name === 'OFFER_SUBMITTED'
    && invite?.status_timeline[1]?.name === 'OFFER_CHANGES_REQUESTED'

  return isResubmitted
    ? (
      <TypeTag
        lhotseSupplier={false}
        title={t('requisition_offers.resubmitted')}
        waiting
      />
    )
    : null
}

export default IsResubmittedTag
