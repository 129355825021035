import { FormikErrors, FormikTouched } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormikHandleChangeType } from '../../../../../../types/shared/FormikType'
import JoinFormValueType from '../../../../../../types/Authentication/JoinFormValueType'
import JoinFormTextInput from '../JoinFormTextInput'
import PasswordValidationInfo from './PasswordValidationInfo'

interface IFormikPasswordInput {
  constants: {
    handleChange: FormikHandleChangeType, values: JoinFormValueType,
    touched: FormikTouched<JoinFormValueType>, errors: FormikErrors<JoinFormValueType>,
    handleSubmit: () => void
  }
  showErrors: boolean
}

const FormikPasswordInput: React.FC<IFormikPasswordInput> = ({
  constants, showErrors,
}) => {
  const { password } = constants.values
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-2">
      <JoinFormTextInput
        constants={constants}
        name="password"
        label={t('login.enter_password')}
        placeholder={t('general.password')}
      />
      <PasswordValidationInfo password={password} showErrors={showErrors} />
    </div>
  )
}

export default FormikPasswordInput
