import { FC, useState } from 'react'
import Input from '../../../../../../shared/components/atoms/Input/Input'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import usePutAttribute from '../../hooks/usePutAttribute'

export interface IProductDetailsRequirementsItem {
  attribute: Omit<LineItemItemAttributeType, 'line_items'>
  disabled?: boolean
}

const ProductDetailsRequirementsItem: FC<IProductDetailsRequirementsItem> = ({ attribute, disabled }) => {
  const [attr, setAttr] = useState(attribute)
  const { mutate: updateAttribute } = usePutAttribute()

  const handleBlur = (key: string, value: string) => {
    setAttr((a) => ({ ...a, [key]: value }))

    updateAttribute({
      ...attr, [key]: value,
    })
  }

  return (
    <div className="flex space-x-2 items-center flex-grow">
      <Input
        disabled={disabled}
        type="text"
        placeholder="Provide Label/Description"
        defaultValue={attribute.description}
        onBlur={(description) => handleBlur('description', description?.toString() || '')}
      />
      <Input
        disabled={disabled}
        type="text"
        placeholder="Provide Value"
        defaultValue={JSON.parse(attribute.configuration)?.value}
        onBlur={(value) => handleBlur('configuration', JSON.stringify({ value: value?.toString() || '' }))}
      />
    </div>
  )
}

export default ProductDetailsRequirementsItem
