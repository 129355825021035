import { useQuery } from 'react-query'
import { apiGetData } from '../../../../core/utils/api/generic'
import useGetSupplierBranchId from '../../hooks/useGetSupplierBranchId'
import { SupplierRequisitionType } from '../../../../types/SupplierDetails/SupplierRequisitionType'

export default (supplierBranchIdParam?: number) => {
  const urlSupplierBranchId = useGetSupplierBranchId()

  const supplierBranchId = supplierBranchIdParam || urlSupplierBranchId

  const getSupplierBranchRequisitions = (currentSupplierBranchId: number) => apiGetData<SupplierRequisitionType[]>(`/supplier-branches/${currentSupplierBranchId}/requisitions`)
  return useQuery(['supplier-reqs', supplierBranchId], () => getSupplierBranchRequisitions(supplierBranchId))
}
