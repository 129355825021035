import { useQueryClient } from 'react-query'
import { LineItemItemAttributeType, LineItemSectionType, LineItemType } from '../../../types/shared/LineItemDataTypes'
import { deepCopy } from '../../../shared/utils/dataParsing'
import OfferViewQueryKeys from '../../Requisition/subModules/Award/constants/OfferViewQueryKeys'
import { deleteCommentWithToken, postCommentWithToken } from '../services/commentService'
import { postLineItem } from '../services/services'
import useCheckOfferStatusAndUpdateIfSubmitted from './useCheckOfferStatusAndUpdateIfSubmitted'
import useGetToken from './useGetToken'
import useUpdateLineItemCommentCache from './useUpdateLineItemCommentCache'

const useToggleComment = (
  initialLineItem: LineItemType | undefined,
  sectionIndex: number,
  row: LineItemItemAttributeType,
  optionId: number,
) => {
  const cache = useQueryClient()
  const token = useGetToken()
  const updateCommentCache = useUpdateLineItemCommentCache()
  const checkOfferStatusAndUpdateIfSubmitted = useCheckOfferStatusAndUpdateIfSubmitted()

  return async () => {
    let lineItemId = initialLineItem?.id

    if (!initialLineItem) {
      const { data: newLineItem } = await postLineItem({
        optionId,
        item_attribute_id: row.id,
        lineItemValue: '',
        token,
      })

      lineItemId = newLineItem?.id

      cache.setQueryData<LineItemSectionType[] | undefined>([OfferViewQueryKeys.SECTIONS_DATA], (
        oldData: LineItemSectionType[] | undefined,
      ) => {
        const sections: LineItemSectionType[] = deepCopy(oldData)
        const editedRow = sections[sectionIndex].item_attributes
          .find((itemAttribute) => itemAttribute.id === row.id) as LineItemItemAttributeType
        editedRow?.line_items?.push(newLineItem)
        return sections
      })
    }

    if (lineItemId) {
      if (initialLineItem?.comment) {
        await deleteCommentWithToken(initialLineItem.comment.id as number, token)
        updateCommentCache(null, sectionIndex, row, optionId)
      } else {
        const { data: newComment } = await postCommentWithToken(lineItemId as number, 'LineItem', token)
        updateCommentCache(newComment, sectionIndex, row, optionId)
      }
    }
    checkOfferStatusAndUpdateIfSubmitted()
  }
}

export default useToggleComment
