import QuestionInputWrapper from '../QuestionInputWrapper'
import RadioButton, { IRadioButton } from '../../../../../../shared/components/atoms/inputs/RadioButton'

export const QuestionRadioButton = (props: IRadioButton) => (
  <QuestionInputWrapper>
    {(inputProps) => (
      <RadioButton
        {...inputProps}
        {...props}
      />
    )}
  </QuestionInputWrapper>
)

export default QuestionRadioButton
