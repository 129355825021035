import { FC } from 'react'
import { TabbedNavItemType } from '../../../../types/shared/TabbedNavItemType'
import TabbedNavItem from './TabbedNavItem'

export interface ITabbedNav {
  items: TabbedNavItemType[],
  onTabChange: (tab: number) => void
  itemClassName?: string
}

const TabbedNav: FC<ITabbedNav> = ({ itemClassName, items, onTabChange }) => (
  <nav className="-mb-px flex justify-center w-full border-b-rock-gray-light border-2 border-transparent" aria-label="Tabs">
    {items.map((item) => (
      <TabbedNavItem key={item.title} item={item} className={itemClassName} onTabChange={onTabChange} />
    ))}
  </nav>
)

export default TabbedNav
