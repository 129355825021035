import { ForwardedRef, forwardRef } from 'react'

import 'reactflow/dist/style.css'
import { WorkflowChart } from '../../../WorkflowChart'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'
import IntakeFlowchartLayout from './IntakeFlowchartLayout'
import IntakeFlowchartNode from './components/IntakeFlowchartNodes/IntakeFlowchartNode'
import IntakeFlowchartGroupNode from './components/IntakeFlowchartNodes/IntakeFlowchartGroupNode'

export const IntakeFlowchart = forwardRef((props, flowRef: ForwardedRef<HTMLDivElement>) => {
  const { workflow, selectedBlock } = useIntakeFlow()

  if (!workflow) {
    return null
  }

  return (
    <WorkflowChart
      ref={flowRef}
      workflow={workflow}
      selectedBlock={selectedBlock}
      nodes={{
        APPROVAL: IntakeFlowchartNode,
        APPROVER: IntakeFlowchartNode,
        GROUP: IntakeFlowchartGroupNode,
      }}
      className="w-full bg-white h-[500px]"
    >
      <IntakeFlowchartLayout />
    </WorkflowChart>
  )
})
IntakeFlowchart.displayName = 'IntakeFlowchart'

export default IntakeFlowchart
