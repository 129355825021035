import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import TypeTag from './TypeTag'

interface ISourceTag {
  invite: RequisitionInviteType
}

const SourceTag: React.FC<ISourceTag> = ({
  invite,
}) => (
  <TypeTag lhotseSupplier={!invite?.supplier_user?.supplier_branch?.source} />
)

export default SourceTag
