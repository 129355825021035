import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import removeDuplicatesById from '../../../../../shared/utils/removeDuplicatesById'
import useGetSupplierInvites from '../hooks/useGetSupplierInvites'
import InviteHeader from './SupplierSelectionContainer/InviteHeader'
import CardTable from '../../../../../shared/components/atoms/CardTable/CardTable'
import SupplierRow from './ReqCoordinate/NewSupplierRow/NewSupplierRow'
import SupplierSelectionNameHeader from './SupplierSelectionContainer/SupplierSelectionNameHeader'
import SelectionActionButtons from './SupplierSelectionContainer/SelectionActionButtons'

const SupplierSelectionContainer: React.FC = () => {
  const { t } = useTranslation()
  const { data: invites } = useGetSupplierInvites()

  const sortedInvites: RequisitionInviteType[] | undefined = removeDuplicatesById(invites?.sort((a) => (a.status === 'CREATED' ? 1 : -1)) || [])
  const tableColumns = [
    <SupplierSelectionNameHeader key="supplier-name-th" />,
    t('coordinate.contact_name', 'Contact name'),
    t('coordinate.tags', 'Tags'),
    t('coordinate.status', 'Status'),
    t('coordinate.action', 'Action'),
  ]

  return (
    <div data-cy="supplier-selection-container">
      <InviteHeader />
      <div className="flex flex-col rounded-lg gap-y-2" data-cy="invited-supplier-rows">
        <CardTable columns={tableColumns}>
          {sortedInvites?.map((invite) => (
            <SupplierRow
              showSelect
              key={`${invite.id}-supplierUser`}
              supplierUser={invite.supplier_user}
              supplierInvite={invite}
              status={invite.status}
              actionComponent={<SelectionActionButtons supplierUser={invite.supplier_user} supplierInvite={invite} />}
            />
          ))}

          {/*  If no sorted invites, show message saying that search should be used first */}
          { sortedInvites.length === 0 && (
          <td colSpan={tableColumns.length}>
            <div className="h-48 flex flex-col justify-center items-center text-center">
              <h3 className="text-lg font-semibold text-mountain-gray">
                {t('coordinate.no_suppliers_selected', 'No suppliers selected')}
              </h3>
              <p className="mt-3 text-md text-rock-gray">
                {t('coordinate.add_suppliers_from_tab', 'You can add suppliers to your request from the Supplier Search tab')}
              </p>
            </div>
          </td>
          ) }
        </CardTable>
      </div>
    </div>
  )
}

export default SupplierSelectionContainer
