import { MouseEventHandler } from 'react'
import useNavigate from '../../../../../shared/hooks/useNavigate'
import { useCoordinateProvider } from '../../../../Requisition/subModules/Coordinate/providers/CoordinateProvider'
import SubmissionTitle from './SubmissionTitle'
import SubmissionActions from './SubmissionActions'
import { FormSubmissionType } from '../../../../../types/Forms/formTypes'

export interface ISubmission {
  submission: FormSubmissionType
}

const Submission: React.FC<ISubmission> = ({ submission }) => {
  const { searchQuery: [, setSearchQuery] } = useCoordinateProvider()
  const navigate = useNavigate()
  const rowOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    const url = `/forms/${submission.form?.uuid}/submission/${submission.uuid}`
    navigate(url, e)
    setSearchQuery('')
  }

  return (
    <button
      type="button"
      className=" bg-white rounded-md px-6 py-4 flex w-full justify-between
      border border-gray-300 border-opacity-60 text-start items-center
    hover:bg-blue-50 hover:bg-opacity-50"
      onClick={rowOnClick}
    >
      <SubmissionTitle submission={submission} />
      <SubmissionActions submission={submission} />
    </button>
  )
}

export default Submission
