import { PlusIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { WorkflowBlockReminder, WorkflowBlockReminders } from '../../../../../types/Workflow/WorkflowBlock'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'
import useWorkflowBlockDraftMutations from '../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import useWorkflowBuilderApproversModal from './hooks/useWorkflowBuilderApproversModal'
import BaseInputWrapper from '../../../../../shared/components/atoms/Input/BaseInputWrapper'
import { useWorkflow } from '../../../providers/WorkflowProvider'
import Checkbox from '../../../../../shared/components/atoms/Checkbox/Checkbox'

export const WorkflowBuilderBlockApprovals = ({ className, ...divProps }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation()
  const { readonly } = useWorkflow()
  const { block } = useWorkflowBlockDraft()
  const { setDeadline, toggleSkipRequestorApproval } = useWorkflowBlockDraftMutations()

  const openApproversModal = useWorkflowBuilderApproversModal()

  return (
    <div className={classnames('flex flex-col gap-6 text-black-light', className)} {...divProps}>
      <div className="flex flex-col">
        <h2 className="text-left font-semibold text-md">{t('workflow.block.approvals', 'Approvals')}</h2>
        <hr className="border-rock-gray-light mt-2" />
      </div>
      <Checkbox id={block.uuid} label={t('workflow.skip-for-requestor', 'Skip for Requestor')} checked={block.options?.allowApproveUserRequester} onChange={toggleSkipRequestorApproval} />

      <div className="flex flex-col gap-6">
        <button type="button" className="items-center border border-rock-gray-light rounded flex flex-row justify-between py-2 px-4 base-form-input" onClick={openApproversModal}>
          <span>
            {block.approvers.length}
            {' '}
            {t('workflow.block.approvers', 'approvers')}
          </span>
          <span><PlusIcon className="h-4" /></span>
        </button>
      </div>
      <div className="flex flex-col gap-6">
        <BaseInputWrapper label={t('workflow.block.deadlines', 'Deadline')}>
          <SearchSelect
            options={WorkflowBlockReminders.map((wr) => ({ label: wr, id: wr }))}
            currentSelection={{ label: block.reminder, id: block.reminder }}
            onChange={(item: InputSearchSelectItemType) => setDeadline(item.id as WorkflowBlockReminder)}
            disabled={readonly}
          />
        </BaseInputWrapper>
      </div>
    </div>
  )
}

export default WorkflowBuilderBlockApprovals
