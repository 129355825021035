import {
  MdFilePresent, MdImage, MdPictureAsPdf,
} from 'react-icons/md'
import dayjs from 'dayjs'
import classnames from 'classnames'
import { HTMLAttributes } from 'react'
import { MediaType } from '../../../../types/shared/MediaTypes'
import TableRow from '../SharedTable/TableRow'
import TableCell from '../SharedTable/TableCell'
import { dateTimeFormat } from '../../../constants/dateFormat'

interface IMediaTableRow {
  media: MediaType,
  rowClassName?: HTMLAttributes<HTMLDivElement>['className']
  cellClassName?: HTMLAttributes<HTMLDivElement>['className']
}

export const MediaIcon = ({ type }: { type: MediaType['type'] }) => {
  const FileIcon = (() => {
    if (type === 'pdf') return MdPictureAsPdf

    if (type === 'image') return MdImage

    return MdFilePresent
  })()

  return <FileIcon className="text-gray-400 text-2xl" />
}

const MediaTableRow: React.FC<React.PropsWithChildren<IMediaTableRow>> = ({
  media, children, rowClassName, cellClassName,
}) => (
  <TableRow className={rowClassName}>
    <TableCell className={cellClassName} first>
      <div className="truncate max-w-xs 2xl:max-w-md ">{media.file_name}</div>
    </TableCell>
    <TableCell className={cellClassName}>
      <MediaIcon type={media.type} />
    </TableCell>
    <TableCell
      last={!children}
      className={classnames({
        'last:text-right': !children,
      }, cellClassName)}
    >
      {dayjs(media.created_at).format(dateTimeFormat)}
    </TableCell>
    {children && (
      <TableCell className={cellClassName} last>
        {children}
      </TableCell>
    )}
  </TableRow>
)

export default MediaTableRow
