import useGetOffer from '../../../hooks/useGetOffer'
import BannerAccepted from './BannerAccepted'
import BannerChangesRequested from './BannerChangesRequested'
import BannerDeclined from './BannerDeclined'
import BannerDeclinedToOffer from './BannerDeclinedToOffer'
import BannerLastCall from './BannerLastCall'
import BannerSubmitted from './BannerSubmitted'
import BannerSubmittedLastCall from './BannerSubmittedLastCall'

export const bannerStates = ['OFFER_ACCEPTED', 'OFFER_DECLINED_BY_CUSTOMER', 'OFFER_SUBMITTED', 'OFFER_DECLINED_BY_SUPPLIER']

const Banner: React.FC = () => {
  const { data: offerData } = useGetOffer()

  const getActiveBanner = () => {
    switch (offerData?.status) {
      case 'OFFER_ACCEPTED': return <BannerAccepted />
      case 'OFFER_DECLINED_BY_CUSTOMER': return <BannerDeclined />
      case 'OFFER_SUBMITTED':
        if (offerData.requisition.status === 'LAST_CALL_NEGOTIATION') {
          return <BannerSubmittedLastCall />
        }
        return <BannerSubmitted />
      case 'OFFER_DECLINED_BY_SUPPLIER': return <BannerDeclinedToOffer />
      case 'OFFER_IN_EDITING':
        if (offerData.requisition.status === 'LAST_CALL_NEGOTIATION') { return <BannerLastCall /> }
        if (offerData?.offer.changes_requested_at) { return <BannerChangesRequested /> }
        return null
      default: return null
    }
  }

  let dataCy: string = offerData?.status || ''

  if (offerData?.status === 'OFFER_IN_EDITING') {
    dataCy = offerData.requisition.status === 'LAST_CALL_NEGOTIATION' ? 'LAST_CALL_NEGOTIATION' : 'CHANGES_REQUESTED'
  }

  return getActiveBanner()
    ? (
      <div id="supplier-offer-banner" data-cy={`${dataCy}-banner`}>
        {getActiveBanner()}
      </div>
    )
    : null
}

export default Banner
