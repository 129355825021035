import React, {
  createContext, useContext, useEffect,
} from 'react'
import { useLocation } from 'react-router'
import useTracking from '../../core/hooks/useTracking'
import { TrackingEvents } from '../../config/analytics'

type RoutesTrackingContext = {}

const routesTrackingContext = createContext({} as RoutesTrackingContext)

const RoutesTrackingProvider: React.FC< React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation()
  const track = useTracking()

  useEffect(() => {
    track(TrackingEvents.navigation.pageChange)
  }, [location])

  return (
    <routesTrackingContext.Provider value={{}}>
      {children}
    </routesTrackingContext.Provider>
  )
}

export default RoutesTrackingProvider

export const useRoutesTracking = () => useContext(routesTrackingContext)
