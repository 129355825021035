import uuid from 'react-uuid'

export const generateUuid = (prefix = '', suffix = '') => [prefix, uuid(), suffix].filter((value) => !!value).join('_')
export default generateUuid

type ObjectWithUuid = Record<string, unknown> & {__uuid: string}

export const assureHasUuid = <T extends ObjectWithUuid>(object: T, prefix = ''): T => {
  if (object.__uuid && prefix && !object.__uuid.startsWith(prefix)) {
    throw new Error(`Invalid uuid "${object.__uuid}". Expected prefix "${prefix}"`)
  }

  return ({
    ...object,
    __uuid: object.__uuid || generateUuid(prefix),
  })
}

export const assuringHasUuid = (prefix: string) => <T extends ObjectWithUuid>(object: T): T => assureHasUuid(object, prefix)
