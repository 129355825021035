import useElementSize from '../../shared/hooks/useElementSize'
import IntakeFlowOverviewPageDetails from './components/IntakeFlowOverviewPageDetails'
import IntakeFlowOverviewPageHeader from './components/IntakeFlowOverviewPageHeader'
import IntakeFlowOverviewPageSidePanel from './components/IntakeFlowOverviewPageSidePanel'
import IntakeFlowchart from './components/IntakeFlowchart/IntakeFlowchart'
import useGetCurrentWorkflow from './hooks/useGetCurrentWorkflow'
import useIntakeFlowTabs from './hooks/useIntakeFlowTabs'
import useIntakeSidebar from './hooks/useIntakeSidebar'
import IntakeFlowProvider from './providers/IntakeFlowProvider'

export const IntakeFlowOverviewPage = () => {
  const { data: intake, isLoading } = useGetCurrentWorkflow()
  const [headerRef, { height: headerHeight }] = useElementSize()
  const {
    tabs, onTabChange, flowRef, detailsRef,
  } = useIntakeFlowTabs({ offset: headerHeight + 1 })

  const { minified } = useIntakeSidebar()

  if (isLoading || !intake) return null

  return (
    <IntakeFlowProvider>
      <div className="flex flex-row w-full">
        <div className="flex flex-col pb-24 relative w-full">
          <IntakeFlowOverviewPageHeader tabs={tabs} onTabChange={onTabChange} ref={headerRef} />
          <div className="flex flex-col gap-6">
            <div className="-ml-px">
              <IntakeFlowchart ref={flowRef} />
            </div>
            <div className="flex flex-row gap-4">
              <div className="px-12 min-w-full">
                <IntakeFlowOverviewPageDetails ref={detailsRef} />
              </div>
            </div>
          </div>
        </div>
        {!minified && <IntakeFlowOverviewPageSidePanel />}
      </div>
    </IntakeFlowProvider>
  )
}

export default IntakeFlowOverviewPage
