import { useMemo } from 'react'
import { useFormLogic } from '../providers/FormLogicProvider'
import validate, { getFirstError } from '../validation/formValidation'
import { EvaluatedFormQuestionType } from '../../../../types/Forms'

export default () => {
  const { allEnabledQuestions } = useFormLogic()

  // Note: There was a different way to determine which questions to validate for Funke and Advario (Done in DEV-3143).
  // But for some reason we also now need to validate all questions (DEV-3178)
  // const questionsToEvaluate = isFunkeOrAdvario ? allEnabledQuestions : enabledQuestionsExceptProducts
  const questionsToEvaluate = allEnabledQuestions

  const errors = useMemo(() => validate(questionsToEvaluate), [questionsToEvaluate])

  const validatedQuestions = useMemo<EvaluatedFormQuestionType[]>(() => questionsToEvaluate.map(
    (question) => ({
      ...question,
      __error: getFirstError(errors?.[question.__uuid]),
    }),
  ), [errors])

  const invalidQuestions = useMemo(() => validatedQuestions.filter(({ __error }) => !!__error), [validatedQuestions])

  return {
    errors,
    validatedQuestions,
    invalidQuestions,
  }
}
