import { LineItemItemAttributeType } from '../../../../../../../types/shared/LineItemDataTypes'
import RadioButton from '../../../../../../../shared/components/atoms/inputs/RadioButton'
import RequisitionOptionType from '../../../../../../../types/Requisition/RequisitionOptionType'
import useUpdateLineItem from '../../hooks/useUpdateLineItem'
import Comment from '../OneLineItem/Comment'
import { getActiveLineItem, getCurrentLineItem } from './utils'

interface IOptionSelect {
  option: RequisitionOptionType
  sectionIndex: number
  row: LineItemItemAttributeType
  updateAutoSave: () => void
}

const OptionSelect: React.FC<IOptionSelect> = ({
  option, sectionIndex, row, updateAutoSave,
}) => {
  const updateLineItem = useUpdateLineItem(updateAutoSave, sectionIndex)

  const currentLineItem = getCurrentLineItem(row, option)
  const activeLineItem = getActiveLineItem(row)

  const onChange = () => {
    if (activeLineItem?.id !== currentLineItem?.id || !activeLineItem) {
      updateLineItem('1', currentLineItem?.id as number, row?.id, option?.id)
    }

    if (activeLineItem) {
      updateLineItem('0', activeLineItem.id as number, row.id, activeLineItem?.item_attribute_option_id as number)
    }
  }

  return row.type === 'options'
    ? (
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-x-2 ">
          <RadioButton
            checked={currentLineItem?.value === '1'}
            onClick={onChange}
            dataCy="radio-button"
          />
          <p>{option?.option_value}</p>
        </div>
        <Comment
          initialLineItem={currentLineItem}
          sectionIndex={sectionIndex}
          row={row}
          option={option}
        />
      </div>
    )
    : null
}

export default OptionSelect
