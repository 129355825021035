import { useTranslation } from 'react-i18next'
import { TEMPLATE_ELEMENTS } from '../../constants/newReqQueryStrings'
import { useFilters } from '../../providers/FiltersProvider'
import { fetchUnifiedElements } from '../../services/UnifiedSearchService'
import NewRequisitionTemplateItem from '../NewRequisitionTemplateItem'
import NewRequisitionTemplateItemsGrid from '../NewRequisitionTemplateItemsGrid'
import useOpenRequisitionPreview from '../../hooks/useOpenRequisitionPreview'
import NewReqRecommendedGrid from '../NewReqRecommendedGrid'
import useDebouncedQuery from '../../../../../../core/hooks/useDebouncedQuery'

interface INewReqRecommendedGrid {
  selectTab: () => void
}

const NewReqRecommendedPastReq: React.FC<INewReqRecommendedGrid> = ({ selectTab }) => {
  const { t } = useTranslation()
  const { filters, query: [query] } = useFilters()

  const { data: resolvedUnifiedData, isLoading } = useDebouncedQuery(
    [TEMPLATE_ELEMENTS, query],
    fetchUnifiedElements,
    {
      query,
      dateRange: filters[0].dateRange,
      supplierName: filters[0].supplierName,
      category: filters[0].category,
    },
  )

  const openModal = useOpenRequisitionPreview()

  return (
    <NewReqRecommendedGrid
      selectTab={selectTab}
      isLoading={isLoading}
      title={t('new_requisition.previous_requisitions')}
      showMoreIcon={!!resolvedUnifiedData?.data?.length}
      gridItemComponent={(
        <NewRequisitionTemplateItemsGrid
          openModal={openModal}
          itemComponent={NewRequisitionTemplateItem}
          data={resolvedUnifiedData?.data || []}
        />
      )}
    />

  )
}

export default NewReqRecommendedPastReq
