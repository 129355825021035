import {
  FC,
} from 'react'

import SearchSelect, { ISearchSelect } from '../SearchSelect/SearchSelect'
import BaseInputWrapper from './BaseInputWrapper'

export interface ISelectInput extends ISearchSelect {
  editable?: boolean | undefined
  required?: boolean | undefined
  label?: string
  placeholder?: string
  isLoading?: boolean
}

const SelectInput: FC<ISelectInput> = ({
  label, currentSelection, isLoading, editable = true, ...rest
}) => {
  if (isLoading) return <div>Loading ...</div>
  return (
    <BaseInputWrapper label={label}>
      {!isLoading && editable ? (
        <SearchSelect
          currentSelection={currentSelection}
          {...rest}
          className=" peer block "
        />
      ) : <span className="text-sm p-2">{currentSelection?.label || ''}</span>}
    </BaseInputWrapper>
  )
}

export default SelectInput
