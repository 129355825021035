import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import useGetOfferActions from './hooks/useGetOfferActions'
import ActionButton from './ActionButton'

interface IActionButtonRow {
  invite: RequisitionInviteType
  isDisabled: boolean
  useLastCallState: [
    number[] | undefined,
    React.Dispatch<React.SetStateAction<number[] | undefined>>
  ]
}

const ActionButtonRow: React.FC<IActionButtonRow> = ({
  invite, isDisabled, useLastCallState,
}) => {
  const [lastCallOptions, setLastCallOptions] = useLastCallState
  const offerActions = useGetOfferActions(invite, isDisabled, setLastCallOptions)

  return (
    <div className="flex justify-around pt-3 border-t border-gray-300 border-px h-full gap-x-2">

      {offerActions.map((action) => (
        <ActionButton
          key={action.key}
          action={action}
        />
      ))}

    </div>
  )
}

export default ActionButtonRow
