import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { undef } from '../../../../../../../../types/shared/GeneralTypes'
import { CustomerDocumentType } from '../../../../../../../../types/SupplierOffer/SupplierOfferDataTypes'
import keys from '../ReqDefDocumentsQueryKeys'

const useUpdateRequisitionDocuments = () => {
  const cache = useQueryClient()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  return (newCustomerDocuments: CustomerDocumentType[]) => {
    cache.setQueryData<undef<CustomerDocumentType[]>>([keys.REQUISITION_DOCUMENTS, requisitionId], (
      oldReqDocs: undef<CustomerDocumentType[]>,
    ) => newCustomerDocuments || oldReqDocs)
  }
}
export default useUpdateRequisitionDocuments
