import { MdOutlineComment } from 'react-icons/md'
import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import useOpenChat from '../../../../../Messenger/hooks/useOpenChat'

interface IOpenMessengerButton {
  invite: RequisitionInviteType
}

const OpenMessengerButton: React.FC<IOpenMessengerButton> = ({ invite }) => {
  const openChat = useOpenChat(invite.name, invite.id, 'supplier_invite')

  return (
    <button
      type="button"
      onClick={openChat}
    >
      <MdOutlineComment className="min-h-[16px] min-w-[16px]" />
    </button>
  )
}

export default OpenMessengerButton
