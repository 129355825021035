import { useQuery } from 'react-query'
import TipTapReadOnly from '../../../../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import { CATALOG_ITEM } from '../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../services/UnifiedSearchService'

const CatalogProductDescription: React.FC<{
  className?: string
  catalogId: string
}> = ({ catalogId, className = '' }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  return (
    <div className={className} data-cy="catalog-item-description">
      <TipTapReadOnly text={data?.details?.descriptionLong || ''} />
    </div>
  )
}

export default CatalogProductDescription
