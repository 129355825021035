import { Dispatch, SetStateAction } from 'react'
import { CompanyAddressType } from '../../../../../types/Company/AddressTypes'
import PlacesAutocompleteComponent from './PlacesAutocomplete'

interface ILocationSearch {
  setAutoFillAddressFields: Dispatch<SetStateAction<Partial<CompanyAddressType> | undefined>>
}

const LocationSearch: React.FC<ILocationSearch> = ({
  setAutoFillAddressFields,

}) => (
  <PlacesAutocompleteComponent
    selectAddress={(googleData) => {
      setAutoFillAddressFields((currentAutoFillAddress) => ({
        city: googleData.city || currentAutoFillAddress?.city,
        street: googleData.street || currentAutoFillAddress?.street,
        zip: googleData.zip || currentAutoFillAddress?.zip,
        name: googleData.name || currentAutoFillAddress?.name,
        country_code: googleData.country_code || currentAutoFillAddress?.country_code,
        country_id: googleData.country_id || currentAutoFillAddress?.country_id,
      }))
    }}
  />
)

export default LocationSearch
