import { useUtils } from '../../../../../../../core/providers/UtilsProvider'
import { useCoordinateProvider } from '../../../providers/CoordinateProvider'
import useSendSupplierInvites from '../../ReqCoordinate/SupplierRow/LeftButton/SelectSupplierCheckbox/hooks/useSendSupplierInvites'

const useSendBulkInvite = () => {
  const {
    selectedInvites: [selectedInvites],
  } = useCoordinateProvider()

  const sendSupplierInvite = useSendSupplierInvites()

  const utils = useUtils()

  const sendBulkInvite = async () => {
    utils.modal.set({ isOpen: false })
    selectedInvites.forEach((invite) => sendSupplierInvite(invite))
  }
  return sendBulkInvite
}

export default useSendBulkInvite
