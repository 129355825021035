import { Link } from 'react-router-dom'
import useUrlIncludes from '../../../../../core/hooks/useUrlIncludes'
import useGetMessengerUrlParams from '../../../hooks/useGetMessengerUrlParams'
import { MessageType } from '../../../../../types/Messenger/types'
import { scrollToMessage } from '../../../utils'
import ReplyPreview from './ReplyPreview'

interface IReplyLink {
  referenceMessage: MessageType | undefined
}

const ReplyLink: React.FC<IReplyLink> = ({ referenceMessage }) => {
  const { modelId, requisitionId, modelType } = useGetMessengerUrlParams()

  const newUrl = `/requisition/req-${requisitionId}/chat/${modelType}/${modelId}/${referenceMessage?.uuid}`

  const isChatScreen = useUrlIncludes('chat')

  if (!referenceMessage?.uuid) return null

  return (
    <Link
      to={isChatScreen ? newUrl : '#'}
      className="cursor-pointer"
      onClick={() => referenceMessage?.uuid && scrollToMessage(referenceMessage.uuid)}
    >
      <ReplyPreview
        message={referenceMessage?.text_message}
        author={referenceMessage?.user.full_name}
      />
    </Link>
  )
}

export default ReplyLink
