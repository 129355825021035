import { getTargetValueAndQuestions } from '../helpers/getTargetValueAndQuestions'
import { getMissingTargetValueError } from '../helpers/getMissingTargetValueError'

export const exact = function (requestedValue) {
  return this.test('exact', 'has to be exact', (value, schema) => {
    if (value === undefined) return true

    const {
      targetValue,
      targetQuestion,
    } = getTargetValueAndQuestions(requestedValue, schema)

    if (targetValue === undefined && value !== undefined) {
      return getMissingTargetValueError(targetQuestion, schema)
    }

    return value === targetValue ? true : schema.createError({
      message: `value must be equal to ${targetValue}`,
      path: schema.path,
    })
  })
}

export default exact
