import { Dispatch, SetStateAction } from 'react'
import { QueryClient } from 'react-query'
import { OfferDataType } from '../../../../types/shared/OfferDataTypes'
import toastr from '../../../../shared/components/organisms/toastr/toastr'
import { toggleDocumentAcceptance } from '../../services/services'
import type { NDASignatureStateType } from './NDAFormInteractiveElements'

export const handleAcceptNDA = async (
  token: string,
  nda: any,
  cache: QueryClient,
  NDASignatureState: NDASignatureStateType,
  setAcceptingNDA: Dispatch<SetStateAction<boolean>>,
) => {
  if (NDASignatureState.signed) {
    //  BLOCKED: Backend should return whether all the NDAs have been signed or not
    setAcceptingNDA(true)
    await toggleDocumentAcceptance(
      token,
      nda.id,
      NDASignatureState.location,
      NDASignatureState.name,
    )
    window.location.reload()
    // cache.setQueryData('offer-data', (offerData: any) => ({
    //   ...offerData,
    //   customer_document_acceptance: [
    //     ...offerData?.customer_document_acceptance, newDocumentAcceptance,
    //   ],
    // }))
  } else {
    toastr.error('supplier_offer.you_need_to_sign_nda', 'errorNDANotSigned')
  }
}

export const declineNDA = () => {
  toastr.error('supplier_view.nda_declind_alert', 'errorNDANotAccepted')
}

export const signNDA = (
  NDASignatureState: NDASignatureStateType,
  setNDASignatureState: Dispatch<SetStateAction<NDASignatureStateType>>,
) => {
  if (!NDASignatureState.name) { return toastr.error('supplier_offer.fill_out_name', 'errorNDANoName') }
  if (!NDASignatureState.location) { return toastr.error('supplier_offer.select_location', 'errorNDALocation') }
  return setNDASignatureState({ ...NDASignatureState, signed: true })
}

export const checkActiveNDAs = (offer: OfferDataType | undefined) => {
  const acceptedDocumentIds = offer?.customer_document_acceptance?.map((document) => document.id)
  const NDAs = offer?.customer_documents?.filter((document) => document?.type === 'nda' && !acceptedDocumentIds?.includes(document.id))
  if (NDAs?.length) return NDAs[0]
  return undefined
}
