import { useMemo } from 'react'
import { RequisitionFilterType, RequisitionFilterValueType } from '../types'
import { useRequisitionFilters } from '../provider/RequisitionFiltersProvider'
import useRequisitionFilterValues from './useRequistionFilterValues'

export const useAvailableRequisitionFilterValues = (filter: RequisitionFilterType) => {
  const allValues: RequisitionFilterValueType[] = useRequisitionFilterValues(filter)
  const { getSelectedFilterValues } = useRequisitionFilters()
  const selectedValues = getSelectedFilterValues(filter)

  return useMemo(() => allValues.filter((value) => !selectedValues.some((selectedValue) => selectedValue.id === value.id)), [selectedValues, allValues])
}

export default useAvailableRequisitionFilterValues
