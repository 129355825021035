import { useTranslation } from 'react-i18next'
import { FormQuestionNumberOfChoiceSettingType } from '../../../../types/Forms/formTypes'

interface IRangeChoiceSelectionInfo {
  choiceSelectionSetting: FormQuestionNumberOfChoiceSettingType | undefined
}

const RangeChoiceSelectionInfo: React.FC<IRangeChoiceSelectionInfo> = ({
  choiceSelectionSetting,
}) => {
  const { t } = useTranslation()

  const getText = () => {
    const lowerBound = String(choiceSelectionSetting?.value?.[0] || '')
    const upperBound = String(choiceSelectionSetting?.value?.[1] || '')
    // move to function
    const translation = t('forms.select_between_x_and_x')
      ?.replace('{{VARIABLE1}}', lowerBound)
      ?.replace('{{VARIABLE2}}', upperBound)

    return translation
  }

  if (choiceSelectionSetting?.type !== 'range') return null

  return <p className="text-sm text-nds-primary-gray">{getText()}</p>
}

export default RangeChoiceSelectionInfo
