import { useCallback, useMemo } from 'react'
import { InputSearchSelectItemType } from '../../types/shared/InputSearchSelectTypes'
import {
  FormQuestionResponseType,
  FormQuestionValue,
  SearchSelectType,
} from '../../types/Forms'
import { useGetCustomerSearch } from './useGetCustomerSearch'
import useGetSearchSelectOptionsFromFile from './useGetSearchSelectOptionsFromFile'

type SearchSelectOptionsMetadata = (
  | {
      searchType: SearchSelectType | undefined | null;
      options?: FormQuestionResponseType[];
    }
  | {
      searchType: Exclude<SearchSelectType, 'CUSTOM'> | undefined;
      options: undefined;
    }
) & {
  values: FormQuestionValue[];
  inputId?: string | null;
};

export const useSearchSelectOptions = ({
  searchType,
  options: optionsData,
  values,
  inputId,
}: SearchSelectOptionsMetadata) => {
  const {
    data: dynamicOptionsData,
    isCustomSearch,
    isLoading,
  } = useGetCustomerSearch(searchType)

  const { options: externalDataOptions, visibleColumns } = useGetSearchSelectOptionsFromFile(inputId)

  const options: InputSearchSelectItemType[] | undefined = useMemo(
    () => (isCustomSearch
      ? optionsData?.map((option) => ({
        id: option.__uuid || option.uuid || '',
        label: option.display_name || option.value,
      })) || []
      : dynamicOptionsData?.map((option) => ({
        id: option.id,
        label: option.value,
      }))),
    [dynamicOptionsData, isCustomSearch],
  )

  // use externalDataOptions if it's not an empty array, otherwise use dynamicOptionsData
  const optionsDataToUse = externalDataOptions && externalDataOptions.length > 0 ? externalDataOptions : options

  const selected = useMemo(
    () => {
      // If externalDataOptions only have one option, return that option as preselected
      if (externalDataOptions && externalDataOptions.length === 1) return externalDataOptions[0]

      return optionsDataToUse?.find((option: InputSearchSelectItemType) => option.label === values[0]?.value)
    },
    [externalDataOptions, optionsDataToUse, values],
  )

  const selectedToOption = useCallback(
    (selected: InputSearchSelectItemType) => ({
      __uuid: selected.id.toString(),
      value: selected.label,
    }),
    [optionsDataToUse],
  )

  return {
    options: optionsDataToUse,
    visibleColumns,
    selected,
    selectedToOption,
    isLoading,
  }
}

export default useSearchSelectOptions
