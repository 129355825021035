import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../core/providers/FormProvider'
import Switch from '../../../../shared/components/atoms/buttons/Switch'
import PageTopbar from '../../../../shared/components/layout/PageTopBar'
import SaveQuestionButton from '../FormsPage/SaveQuestionButton'
import FormNavigation from '../FormsPage/FormNavigation'
import { getQuestionTypeTranslation } from '../../formUtils'
import useUpdateQuestionValidation from '../hooks/useUpdateQuestionValidation'

const QuestionTopbar = () => {
  const { t } = useTranslation()

  const { question: [question] } = useFormContext()
  const isRequired = question?.validation.required?.value
  const updateQuestionValidation = useUpdateQuestionValidation()

  if (!question) return null

  const questionTypeTitle = t(getQuestionTypeTranslation(question.type))

  return (
    <div
      id="expanded-header"
      className="bg-white"
    >
      <FormNavigation />
      <PageTopbar
        titleComponent={(
          <div className="flex flex-col gap-y-2">
            <p>{questionTypeTitle}</p>
            {!question.isFormElement && (
              <div className="flex gap-x-2 items-center">
                <p className="text-sm text-secondary-gray">Required?</p>
                <Switch
                  disabled={!question?.actions?.edit}
                  onChange={() => updateQuestionValidation({ required: { value: !isRequired } })}
                  checked={isRequired}
                />
              </div>
            )}
          </div>
        )}
        actions={<SaveQuestionButton />}
      />
    </div>
  )
}

export default QuestionTopbar
