import { useQueryClient } from 'react-query'
import _ from 'lodash'
import { apiPost } from '../../../../../core/utils/api/generic'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import OfferViewQueryKeys from '../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorDataType, ReqDefEditorSectionType } from '../constants/editorTypes'
import useAutoSave from '../../../utils/useAutoSave'
import useAddItemAttributeOption from './useAddItemAttributeOption'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'

// The setting of defaults should ideally happen on the server, but it may break backwards compatibility
const applyDefaults = (newAttribute: any) => {
  const attribute = _.cloneDeep(newAttribute)

  const defaultButtonStates = {
    isSum: newAttribute.type === 'price',
    isRequired: false,
    addComment: typeof newAttribute.comment === 'string',
  }

  const buttonStates = {
    isSum: (newAttribute?.is_sum !== null)
      ? newAttribute.is_sum
      : defaultButtonStates.isSum,
    isRequired: newAttribute?.is_required !== 0 && newAttribute?.is_required !== null
      ? newAttribute.is_required
      : defaultButtonStates.isRequired,
    addComment: typeof newAttribute?.comment?.comment === 'string',
  }

  attribute.is_sum = buttonStates.isSum
  attribute.is_required = buttonStates.isRequired
  attribute.comment = buttonStates.addComment ? newAttribute.comment : null

  return attribute
}

const usePostNewAttribute = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()
  const [, updateAutoSave] = useAutoSave()

  return async (sectionId: number, type = 'attribute'): Promise<LineItemItemAttributeType | null> => {
    try {
      const response = await apiPost(`/item-sections/${sectionId}/item-attributes`, { type })
      const queryKey = [OfferViewQueryKeys.SECTIONS_DATA, requisitionId]

      let newAttribute = response.data
      newAttribute = applyDefaults(newAttribute)

      cache.setQueryData<ReqDefEditorDataType>(queryKey, (
        oldSections: undef<ReqDefEditorDataType>,
      ) => {
        if (!oldSections) return []

        const newSections = oldSections.map(
          (section) => (section.id === sectionId
            ? { ...section, item_attributes: [...section.item_attributes, newAttribute] }
            : section
          ),
        )
        return newSections
      })

      updateAutoSave()
      return newAttribute
    } catch {
      toastr.error('error.item_not_created', 'errorAttributeCreation')
      return null
    }
  }
}

export default usePostNewAttribute
