import useDebouncedQuery from '../../../../../../../../core/hooks/useDebouncedQuery'
import { apiPostSupplierUserSearch } from '../../../../../../../Suppliers/services/SupplierBranchesService'
import queryKeys from '../../../../constants/reqCoordinateQueryKeys'
import { useCoordinateProvider } from '../../../../providers/CoordinateProvider'

export default () => {
  const { searchQuery: [searchQuery] } = useCoordinateProvider()

  const query = searchQuery || '*'

  return useDebouncedQuery(
    [queryKeys.SEARCH_QUERY, query],
    apiPostSupplierUserSearch,
    { query },
  )
}
