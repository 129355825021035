import { FC } from 'react'
import { VerticalNavItemType } from '../../../../types/shared/VerticalNavItemType'
import VerticalNavItem from './VerticalNavItem'

interface IVerticalNav {
  items: VerticalNavItemType[]
}

const VerticalNav: FC<IVerticalNav> = ({ items }) => (
  <ul className="space-y-5 text-sm sticky top-12">
    {items.map((item) => (
      <VerticalNavItem
        key={item.link}
        disabled={item.disabled}
        {...item}
      />
    ))}
  </ul>
)

export default VerticalNav
