import {
  createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useState,
} from 'react'
import { uniq } from 'lodash'
import { RequisitionFilterConfig, RequisitionFilterType, RequisitionFilterValueType } from '../types'

type RequisitionFiltersContext = {
  filters: RequisitionFilterConfig[],
  filterValues: Record<string, RequisitionFilterValueType[]>,
  setFilters: Dispatch<SetStateAction<RequisitionFilterConfig[]>>,
  setFilterValues: Dispatch<SetStateAction<Record<string, RequisitionFilterValueType[]>>>,
  setFilterValue: (filter: RequisitionFilterType, someValue: RequisitionFilterValueType) => void
  updateFilterValues: (filter: RequisitionFilterType, someValues: RequisitionFilterValueType[]) => void
  addFilterValue: (filter: RequisitionFilterType, someValue: RequisitionFilterValueType) => void
  removeFilterValue: (filter: RequisitionFilterType, someValue: RequisitionFilterValueType) => void
  getSelectedFilterValues: (filter: RequisitionFilterType) => RequisitionFilterValueType[]
  getFirstSelectedFilterValue: (filter: RequisitionFilterType) => RequisitionFilterValueType
}

const requisitionFiltersContext = createContext<RequisitionFiltersContext>({} as RequisitionFiltersContext)

export const RequisitionFiltersProvider = ({ children } : React.PropsWithChildren) => {
  const [filters, setFilters] = useState<RequisitionFilterConfig[]>([])
  const [filterValues, setFilterValues] = useState<Record<string, RequisitionFilterValueType[]>>({})

  const updateFilterValues = useCallback((filter: RequisitionFilterType, someValues: RequisitionFilterValueType[]) => {
    setFilterValues({ ...filterValues, [filter.id]: someValues })
  }, [filterValues])

  const setFilterValue = useCallback((filter: RequisitionFilterType, someValue: RequisitionFilterValueType) => {
    updateFilterValues(filter, [someValue])
  }, [updateFilterValues])

  const addFilterValue = useCallback((filter: RequisitionFilterType, someValue: RequisitionFilterValueType) => {
    setFilterValues({ ...filterValues, [filter.id]: uniq([...(filterValues[filter.id] || []), someValue]) })
  }, [filterValues])

  const removeFilterValue = useCallback((filter: RequisitionFilterType, value: RequisitionFilterValueType) => {
    setFilterValues((currentFilterValues) => ({
      ...filterValues,
      [filter.id]: (currentFilterValues[filter.id] || []).filter(({ id }) => id !== value.id),
    }))
  }, [filterValues])

  const getSelectedFilterValues = useCallback((filter: RequisitionFilterType) => filterValues[filter.id] || [], [filterValues])

  const getFirstSelectedFilterValue = useCallback((filter: RequisitionFilterType) => getSelectedFilterValues(filter)[0], [getSelectedFilterValues])

  const context = useMemo(() => ({
    filters,
    setFilters,
    filterValues,
    setFilterValues,
    setFilterValue,
    updateFilterValues,
    addFilterValue,
    removeFilterValue,
    getSelectedFilterValues,
    getFirstSelectedFilterValue,
  }), [filters, filterValues, setFilterValue, addFilterValue, removeFilterValue, getSelectedFilterValues])

  return (
    <requisitionFiltersContext.Provider value={context}>
      {children}
    </requisitionFiltersContext.Provider>
  )
}

export const useRequisitionFilters = () => useContext(requisitionFiltersContext)
