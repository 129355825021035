import { useTranslation } from 'react-i18next'

interface IFileUploadDragAndDrop {
  fileUploadRef: React.RefObject<HTMLInputElement>
  disabled: boolean

}

const FileUploadDragAndDrop: React.FC<IFileUploadDragAndDrop> = (
  { fileUploadRef, disabled },
) => {
  const { t } = useTranslation()

  return (
    <button
      className="flex flex-col border-dotted border-gray-300 border rounded-lg p-4 text-gray-300 gap-y-3 w-56"
      onClick={() => fileUploadRef?.current?.click()}
      type="button"
      data-cy="file-upload-field"
      disabled={disabled}
    >
      <div>
        <p className="text-xs">{t('req_def.drag_and_drop')}</p>
      </div>
      <div className={`${disabled ? 'text-gray-400 bg-gray-200' : 'text-blue-700 bg-blue-100'} w-full  flex justify-center p-1 rounded-md font-semibold text-sm`}>
        <p>{t('req_def.choose_file')}</p>
      </div>
    </button>
  )
}

export default FileUploadDragAndDrop
