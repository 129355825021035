import {
  Dispatch, SetStateAction, useEffect, useMemo,
} from 'react'
import { useEdgesState, useNodesState } from 'reactflow'
import {
  generateHelperNodes,
  generateIntakeFlowchartEdges,
  mapWorkflowStepsToFlowchartNodes,
} from '../helpers'
import { useWorkflowChart } from '../providers/WorkflowChartProvider'
import { WorkflowChartNode, WorkflowChartNodeType } from '../../../types/Workflow/WorkflowChart'

export const useWorkflowFlowchart = () => {
  const { workflow, readonly } = useWorkflowChart()

  const nodesData = useMemo(() => {
    const flowchartNodes = mapWorkflowStepsToFlowchartNodes(workflow?.steps || [])
    const helperNodes = readonly ? [] : generateHelperNodes(flowchartNodes)
    return [...flowchartNodes, ...helperNodes]
  }, [workflow])

  const edgesData = useMemo(() => generateIntakeFlowchartEdges(nodesData, readonly ? 'straight' : 'EDGE_WITH_ADD_BUTTON'), [nodesData])

  const [nodes, setNodes, onNodesChange] = useNodesState<WorkflowChartNodeType>(nodesData)
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgesData)

  useEffect(() => {
    setNodes(nodesData)
  }, [nodesData])

  useEffect(() => {
    setEdges(edgesData)
  }, [edgesData])

  return {
    nodes, setNodes: setNodes as Dispatch<SetStateAction<WorkflowChartNode[]>>, onNodesChange, edges, setEdges, onEdgesChange,
  }
}

export default useWorkflowFlowchart
