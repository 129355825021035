import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import InputGrayUnderline from '../../../../../shared/components/atoms/inputs/underlined/InputGrayUnderline'
import { CustomerDocumentType } from '../../../../../types/SupplierOffer/SupplierOfferDataTypes'
import { DocumentContentType } from '../../../../../types/Company/DocumentType'

interface IDocumentFormNameInput {
  documentForm: CustomerDocumentType | DocumentContentType
  formTouched: boolean
  handleChange: (v: string) => void
}

const DocumentFormNameInput: React.FC<IDocumentFormNameInput> = ({
  formTouched, documentForm, handleChange,
}) => {
  const isNameError = documentForm?.name?.length === 0

  return (
    <div className="w-1/3">
      <InputGrayUnderline
        name="documentName"
        placeholder="account.document_name_placeholder"
        handleChange={handleChange}
        value={documentForm?.name || ''}
        className="border-b border-gray-200"
        dataCy="document-name-input"
      />
      {formTouched && isNameError && <ErrorMessage error="generic.required" />}
    </div>
  )
}

export default DocumentFormNameInput
