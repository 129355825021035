import {
  Cog6ToothIcon, DocumentIcon, HomeIcon, RectangleStackIcon,
} from '@heroicons/react/24/outline'
import { useBasecampContext } from '../../../providers/BasecampProvider'
import { CoreNavigationLinkType } from '../../../../types/shared/CoreNavigationLinkType'
import useGetCurrentUser from '../../../hooks/useGetCurrentUser'

export default () => {
  const { requisitionSearch: [, setRequisitionSearch] } = useBasecampContext()

  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  const currentUserRole = useGetCurrentUser()?.data?.role

  const navigationItems: CoreNavigationLinkType[] = [
    {
      name: 'basecamp.basecamp',
      sections: ['/', '/requisition', '/dashboard', '/submissions', '/requisitions'],
      href: '/',
      icon: () => <HomeIcon className="h-6" />,
      current: true,
      type: 'link',
      onClick: () => { setRequisitionSearch('') },
      visible: isFunkeOrAdvario,
    },
    {
      name: 'generic.intake-flows',
      sections: ['/intake-flows', '/intake-flows/create', '/intake-flow', '/intake-flow/:id'],
      href: '/intake-flows',
      icon: () => <HomeIcon className="h-6" />,
      current: false,
      type: 'link',
      visible: !isFunkeOrAdvario,
    },
    {
      name: 'generic.intake-configuration',
      sections: ['/intake-configuration', 'intake-configuration/forms', 'intake-configuration/workflows', 'intake-configuration/inputs'],
      href: '/intake-configuration',
      icon: () => <DocumentIcon className="h-6" />,
      current: false,
      type: 'link',
      visible: ['admin', 'super_admin'].includes(currentUserRole || ''),
    },
    {
      name: 'generic.supplier_plural',
      sections: ['/suppliers', '/supplier'],
      href: '/suppliers',
      icon: () => <RectangleStackIcon className="h-6" />,
      current: false,
      type: 'link',
      visible: true,
    },
    {
      name: 'generic.company',
      type: 'link',
      icon: () => <Cog6ToothIcon className="h-6" />,
      current: false,
      sections: ['/company'],
      href: '/company',
      visible: ['admin', 'super_admin', 'purchaser'].includes(currentUserRole || ''),
    },
  ]

  return navigationItems
}
