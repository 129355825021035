import { useQueryClient } from 'react-query'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'
import { apiPost } from '../../../../../core/utils/api/generic'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import { ReqDefEditorSectionType } from '../constants/editorTypes'
import { updateItemAttribute } from '../utils/editorUtils'
import useAutoSave from '../../../utils/useAutoSave'

const useAddItemAttributeOption = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()
  const [_, updateAutoSave] = useAutoSave()

  return async (section: ReqDefEditorSectionType, itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>) => {
    try {
      const { data: newOption } = await apiPost(`/item-attributes/${itemAttribute.id}/options`, { option_value: '' })
      updateItemAttribute(cache, requisitionId, section, itemAttribute, 'options', [...itemAttribute.options, newOption])
      updateAutoSave()
    } catch {
      toastr.error()
    }
  }
}

export default useAddItemAttributeOption
