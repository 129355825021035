export type DefaultDataType = {
  [index: string]: { costCenter: string }
}

export const advarioDefaultData: DefaultDataType = {
  'service@lhotse.de': { costCenter: '000' },
  'henning.hopmann@adexpartners.com': { costCenter: '545007000 HSSE (0545)' },
  'daniela.meiswinkel@advario.com': { costCenter: '40008510 Procurement NewCo (0040)' },
  'lars.rompe@extern.oiltanking.com': { costCenter: '40008510 Procurement NewCo (0040)' },
  'martin.schrode@advario.com': { costCenter: '40008510 Procurement NewCo (0040)' },
  'stefanie.hoelscher@advario.com': { costCenter: '000' },
  'tom.van.rijn@advario.com': { costCenter: '000' },
  'oliver.brandt@advario.com': { costCenter: '40011110 IT Projects (0040)' },
  'katja.van.ooijen@advario.com': { costCenter: '545001000 General Management (0545)' },
  'marina.stadnik@advario.com': { costCenter: '545008001 PMO (0545)' },
  'giulia.bido@advario.com': { costCenter: '545007000 HSSE (0545)' },
  'nina.buchholz@advario.com': { costCenter: '000' },
  'helena.vargas.serrato@advario.com': { costCenter: '545004000 People (0545)' },
  'Claudia.van.der.hulst@advario.com': { costCenter: '545004500 Trainees (0545)' },
}

export type PurchaserGroupsType = {
  [index: string]: { name: string, email: string }[]
}
export const advarioPurchaserGroups: PurchaserGroupsType = {
  _400: [
    { name: 'Lars Rompe', email: 'lars.rompe@extern.oiltanking.com' },
    { name: 'Nina Buchholz', email: 'nina.buchholz@advario.com' },
  ],
  _545: [
    { name: 'Tom van Rijn', email: 'tom.van.rijn@advario.com' },
    { name: 'Claudia van der Hulst', email: 'Claudia.van.der.hulst@advario.com' },
  ],
}
