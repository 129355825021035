import { useMutation, useQueryClient } from 'react-query'
import { RequisitionInviteType } from '../../../types/shared/RequisitionDataTypes'
import { useCoordinateProvider } from '../subModules/Coordinate/providers/CoordinateProvider'
import { postSupplierInvite } from '../services/RequisitionSuppliersService'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../types/shared/GeneralTypes'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import { replaceById } from '../../../shared/utils/arrayHelpers'
import reqCoordinateQueryKeys from '../subModules/Coordinate/constants/reqCoordinateQueryKeys'

const useSendSupplierInviteMutation = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()

  const {
    loadingInvites: [, setLoadingInvites],
  } = useCoordinateProvider()

  return useMutation(({ supplierInviteId, reason }: {
    supplierInviteId: number, reason?: string
  }) => postSupplierInvite(requisitionId, supplierInviteId, reason), {
    onSuccess: (newData) => {
      cache.setQueryData([reqCoordinateQueryKeys.REQUISITION_INVITES, requisitionId], (
        oldData: undef<RequisitionInviteType[]>,
      ) => replaceById(oldData, newData.id, newData))

      setLoadingInvites((loadingInvites) => loadingInvites.filter(
        (inviteId) => inviteId !== newData.id,
      ))

      toastr.success('success.invitation_sent', 'successInvitationSent')
    },
    onError: () => { toastr.error() },
  })
}

export default useSendSupplierInviteMutation
