import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface IRCSourcingMoreButton {
  newSectionNumber: number
  setActiveSection: Dispatch<SetStateAction<number>>
}

const RCSourcingMoreButton: React.FC<IRCSourcingMoreButton> = ({
  setActiveSection, newSectionNumber,
}) => {
  const { t } = useTranslation()

  return (
    <button
      className="flex justify-end"
      type="button"
      onClick={() => setActiveSection(newSectionNumber)}
      data-cy={`more-button-${newSectionNumber}`}
    >
      <p className="text-sky-blue">{t('generic.more')}</p>
    </button>
  )
}

export default RCSourcingMoreButton
