import FormDateInput from '../FormInputs/FormDateInput'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldDate = () => {
  const {
    field: {
      id, value, disabled, input: { type },
    }, updateValue,
  } = useFormField()

  if (!type.includes('DATE')) { return null }

  return (
    <div className="flex flex-col gap-4 text-base font-normal">
      <div key={`intake-field${id}`} className="">
        <FormDateInput
          disabled={disabled}
          date={value?.value as string}
          type={type as 'DATE'}
          onChange={(selectedDate) => updateValue(selectedDate)}
        />
      </div>
    </div>
  )
}

export default FormFieldDate
