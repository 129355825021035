import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useDuplicateSubmission from '../../../../Requisition/components/layout/ReqTopbar/hooks/useDuplicateSubmission'
import useGetCurrentIntake from '../../../hooks/useGetCurrentIntake'
import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'

const IntakeSidePanelActiveStepRestart = () => {
  const { data } = useGetCurrentWorkflow()
  const { data: activeIntake } = useGetCurrentIntake()
  const { mutate: restartSubmission } = useDuplicateSubmission()
  const { t } = useTranslation()

  const handleRestart = async (submissionUuid: string) => {
    restartSubmission(submissionUuid)
  }

  if (!['declined', 'completed', 'declined_by_requester'].includes(data?.status || '')) return null

  return (
    <Button className="btn-primary w-full" onClick={() => activeIntake?.submission_uuid && handleRestart(activeIntake?.submission_uuid)}>
      {t('generic.restart', 'Restart')}
    </Button>
  )
}

export default IntakeSidePanelActiveStepRestart
