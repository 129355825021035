import CoreTopbarLayout from '../../core/components/layout/CoreTopbarLayout'
import BasecampContainer from './components/BasecampContainer'
import BasecampTopbar from './components/layout/Topbar'

const BasecampPage = () => (
  <CoreTopbarLayout topbar={<BasecampTopbar />}>
    <BasecampContainer />
  </CoreTopbarLayout>
)

export default BasecampPage
