import { useQueryClient } from 'react-query'
import { LineItemType, LineItemSectionType } from '../../../types/shared/LineItemDataTypes'
import { undef } from '../../../types/shared/GeneralTypes'
import OfferViewQueryKeys from '../../Requisition/subModules/Award/constants/OfferViewQueryKeys'

export default () => {
  const cache = useQueryClient()

  return (rowId: number, newLineItem: LineItemType) => {
    cache.setQueryData<undef<LineItemSectionType[]>>(OfferViewQueryKeys.SECTIONS_DATA, (
      sections: undef<LineItemSectionType[]>,
    ) => {
      const newSections = sections?.map((sectionData) => ({
        ...sectionData,
        item_attributes: sectionData.item_attributes.map((itemAttributeData) => (
          itemAttributeData.id === rowId
            ? ({
              ...itemAttributeData,
              line_items: [newLineItem],
            })
            : itemAttributeData)),
      }))
      return newSections
    })
  }
}
