import { useFormContext } from '../../../../core/providers/FormProvider'
import { FormTemplateType, FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import useGetFormSubmission from './useGetFormSubmission'
import { getUpdatedByUuidWithoutNew } from '../../../../shared/utils/arrayHelpers'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import { useFormSection } from '../providers/FormSectionProvider'
import { StateCallback } from '../../../../core/hooks/useStateWithCallback'

export default (question: FormQuestionType) => {
  const { submission: [submission, setSubmission] } = useFormContext()
  const { data: submissionFormData } = useGetFormSubmission()
  const { validateQuestions } = useFormSection()

  const allQuestions = submission?.sections?.flatMap(({ fields }) => fields)
  const questionSection = submission?.sections?.find(
    (section) => section.fields.find((sectionQuestion) => sectionQuestion.__uuid === question.__uuid),
  )

  const questionValues = (
    questionSection?.fields
      ?.find((templateQuestion) => templateQuestion.__uuid === question.__uuid)?.values) || []

  // duplicated
  const setQuestionValues = (values: FormQuestionValue[], onUpdate?: StateCallback<FormTemplateType | undefined>) => {
    if (!submission || submissionFormData?.submitted || !questionSection || !allQuestions) return {}

    const updatedQuestions = allQuestions
      .filter((questionToUpdate) => (questionToUpdate.__uuid === question.__uuid)
        || (questionToUpdate.__loop?.parentQuestionUuid === question.__uuid && questionToUpdate.__loop?.hasCopiedValuesFromParent))
      .map(
        (questionToUpdate) => getMergedDeepOverwritingArrays(questionToUpdate, { values }),
      )

    const newForm: FormTemplateType = getMergedDeepOverwritingArrays(
      submission,
      {
        sections: submission.sections.map((section) => ({ ...section, fields: getUpdatedByUuidWithoutNew(section.fields, updatedQuestions) })),
      },
    )

    setSubmission(newForm, onUpdate)
    const updatedQuestion = updatedQuestions.find(({ __uuid }) => question.__uuid === __uuid)

    const isRelatedToUpdatedQuestion = (question: FormQuestionType) => Object.values(question.validation)
      .filter((validation) => !!validation)
      .some(({ target }) => target === updatedQuestion?.__uuid)

    if (updatedQuestion) validateQuestions([updatedQuestion, ...updatedQuestions.filter(isRelatedToUpdatedQuestion)])
    return { newForm, updatedQuestion } as const
  }

  return [questionValues, setQuestionValues] as const
}
