import { IntakeFieldType } from '../../../../types/Intake'
import FormField from '../../../Forms-v2/FormField'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'

type IntakeFormFieldProps = {
  field: IntakeFieldType
}

export type IntakeFormFieldValuesProps = {}

export const IntakeFormField = ({ field }: IntakeFormFieldProps) => {
  const { editModeOn, updateField } = useIntakeFlow()

  return (
    <FormField field={field} readonly={!editModeOn} updateField={updateField} />
  )
}

export default IntakeFormField
