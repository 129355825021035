import { useLocation } from 'react-router'
import { useUtils } from '../../../core/providers/UtilsProvider'
import SupplierDetailsModal from '../SupplierDetailsModal/SupplierDetailsModal'
import { useSuppliersProvider } from '../../Suppliers/providers/SuppliersProvider'
import SupplierDetailsModalHeader from '../SupplierDetailsModal/SupplierDetailsModalHeader'

const useOpenSupplierDetailsModal = (supplierBranchId: number | undefined) => {
  const utils = useUtils()
  const { pathname } = useLocation()

  const {
    supplierBranchId: [, setSupplierBranchId],
    scrollState: [, setScrollState],
    origin: [, setOrigin],
    activeSupplierModalTab: [, setActiveSupplierModalTab],
  } = useSuppliersProvider()

  if (!supplierBranchId) return () => { }

  return () => {
    const scrollableDiv = document.getElementById('scrollableDiv')
    setActiveSupplierModalTab('general')
    setScrollState(scrollableDiv?.scrollTop)
    setSupplierBranchId(supplierBranchId)
    setOrigin(pathname)
    utils.modal.set({
      isOpen: true,
      title: <SupplierDetailsModalHeader supplierBranchId={supplierBranchId} />,
      content: <SupplierDetailsModal />,
    })
  }
}

export default useOpenSupplierDetailsModal
