import Badge, { BadgeProps } from '../../../../../../shared/components/atoms/tags/Badge'
import { FormTag } from '../../../../../../types/Forms'

type TagBadgeProps = {
  tag: FormTag
} & Omit<BadgeProps, 'bgColor' | 'txtColor' | 'rounded'>

export const TagBadge = ({ tag, ...badgeProps }: TagBadgeProps) => (
  <Badge
    className="cursor-pointer"
    bgColor="ice-blue"
    txtColor="black"
    rounded="rounded-sm"
    {...badgeProps}
  >
    {tag.name}
  </Badge>
)
export default TagBadge
