import { useFormContext } from '../../../../../core/providers/FormProvider'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const LongQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'TEXT_AREA') return null

  return <TextAndSubtitleInput />
}

export default LongQuestion
