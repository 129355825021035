import { useLocation } from 'react-router'

export default () => {
  const query = new URLSearchParams(useLocation().search)

  return () => {
    if (query.get('redirect')) {
      return `${query.get('redirect')}`
    }
    return ''
  }
}
