import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'

const CatalogProductImageError = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center">
      <MdError size={48} className="text-gray-500" />
      <p>
        {t('catalog.error.loading_image')}
      </p>
    </div>
  )
}

export default CatalogProductImageError
