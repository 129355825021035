import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import AddButton from '../../../../../shared/components/atoms/buttons/AddButton'
import { Offer } from '../../../../../types/Offering'
import getOfferTemplate from '../../../../Offerings/components/forms/constants/OfferTemplate'
import useCreateOffer from '../../../hooks/useCreateOffer'
import ProductSectionOfferModal from './ProductSectionOfferModal/ProductSectionOfferModal'
import useUpdateOffer from '../../../hooks/useUpdateOffer'

type IProductSectionOffersAddButton = {
  defaultValues?: Partial<Offer>
}

const ProductSectionOffersAddButton: React.FC<IProductSectionOffersAddButton> = ({ defaultValues }) => {
  const utils = useUtils()
  const { t } = useTranslation()
  const { mutateAsync: createOffer } = useCreateOffer()
  const { mutateAsync: updateOffer } = useUpdateOffer()

  const handleAddOffer = async () => {
    const newOffer = getOfferTemplate(
      defaultValues || {},
    )

    const { data: offer } = await createOffer({ ...newOffer, reference_key: newOffer.reference_key || '-' })

    utils.modal.set({ content: <ProductSectionOfferModal offer={offer} updateOffer={updateOffer} />, title: t('generic.add-offer', 'Add offer'), isOpen: true })
  }

  return (

    <div className="flex items-end">
      <AddButton onClick={handleAddOffer} label={t('generic.add-offer', 'Add offer')} />
    </div>
  )
}

export default ProductSectionOffersAddButton
