import dayjs from 'dayjs'

export const dateFormat = 'DD.MM.YYYY'
export const timeFormat = 'HH:mm'
export const dateTimeFormat = 'DD.MM.YYYY HH:mm'
export const dateTimeFormatTimeline = 'dd.MM.yyyy HH:mm'
export const dateTimeFormatChat = 'YYYY-MM-DD HH:mm'

export const timeToHHMMSS = (date: Date) => {
  const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
  const minutes = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
  const seconds = date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`
  return `${hours}:${minutes}:${seconds}`
}

export const dateTimeFormatter = (date: Date | string, withTime = true) => dayjs(
  new Date(date),
).format(withTime ? dateTimeFormat : dateFormat)

export const dateFormatter = (date: Date | string) => dayjs(
  new Date(date),
).format(dateFormat)

export const calculateTimeAgo = (date: string) => dayjs(date).fromNow()

export const filterPassedTime = (
  minDate: Date | undefined,
  shouldFilter: boolean | undefined,
  time?: Date,
) => {
  if (time && minDate && shouldFilter) {
    const selectedDate = new Date(time)

    return minDate.getTime() < selectedDate.getTime()
  }
  return true
}
