// REFACTOR
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../../../../../../../core/providers/LocalStorageProvider'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import AcceptanceStateType from '../../../../../../../types/Requisition/AcceptanceStateType'
import useGetRequisitionData from '../../../../Definition/hooks/useGetRequisition'
import {
  acceptOffer, declineOffer, requestChanges,
} from '../../../utils/OfferViewUtils'

export type ModalDataType = {
  actionHandler: () => void;
  text: string
  finePrint: string
  buttonText: string
  placeholder: string
  buttonColor: string
}

export default (
  modalType: 'accept' | 'decline' | 'changes',
  setStatus: (status: AcceptanceStateType) => any,
  invite: RequisitionInviteType,
  reason: string,
): ModalDataType => {
  const { data: requisition } = useGetRequisitionData()
  const [getLocalStorage] = useLocalStorage()
  const { language } = getLocalStorage('lhotseAuth')
  const { t } = useTranslation()

  const data = {
    actionHandler: () => { },
    text: '',
    finePrint: '',
    buttonText: '',
    placeholder: t('generic.add_custom_note'),
    buttonColor: '',
  }

  switch (modalType) {
    case 'accept':
      data.actionHandler = () => acceptOffer(invite, setStatus, reason, language, requisition)
      data.text = t('req_award.about_to_accept')
      data.finePrint = t('req_award.accepting_declines_others')
      data.buttonText = t('req_award.accept_offer')
      data.buttonColor = '#008200'
      break
    case 'decline':
      data.actionHandler = () => declineOffer(invite, setStatus, reason, language)
      data.text = t('req_award.about_to_decline')
      data.buttonText = t('requisition_offers.decline_offer')
      data.buttonColor = '#AF0000'
      break
    case 'changes':
      data.actionHandler = () => requestChanges(invite, setStatus, reason, language)
      data.text = t('req_award.we_will_notify')
      data.finePrint = t('req_award.they_have_chance')
      data.buttonText = t('req_award.request_changes')
      data.placeholder = t('req_award.what_should_be_changed')
      data.buttonColor = '#DC9055'
      break
    default:
      break
  }

  return data
}
