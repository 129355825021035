import { useState } from 'react'
import Button from '../../../../shared/components/atoms/buttons/Button'

interface IApprovalRejectForm {
  handleModalClose: () => void,
  handlereject: (reason: string) => void,
}
const ApprovalRejectForm: React.FC<IApprovalRejectForm> = ({
  handleModalClose, handlereject,
}) => {
  const [reason, setReason] = useState<string>('')
  return (
    <div className="flex flex-col space-y-4">
      <div>
        You are about to reject the requisition Surface Hub for conference room,
        once this has been done it cannot be reversed. Please provide the reason
        for rejecting it below to proceed.
      </div>
      <div className="flex flex-col space-y-2">
        <span>Reject reason</span>
        <input type="textarea" value={reason} onChange={(e) => setReason(e.target.value)} id="reject_reason" name="reject_reason" className="border border-gray-600 rounded-lg p-4" />

      </div>
      <div className="flex justify-end space-x-2">
        <Button onClick={() => handleModalClose()} color="nds-tertiary-gray" variant="outlined">Cancel</Button>
        <Button onClick={() => handlereject(reason)} color="nds-accessible-red" variant="solid">Reject</Button>
      </div>
    </div>
  )
}

export default ApprovalRejectForm
