import { FC } from 'react'
import { useHistory } from 'react-router'
import SectionWrapper from '../../../../shared/components/organisms/SectionWrapper'
import { FormDataType } from '../../../../types/Forms'

interface ILhotseHomeCreateResult {
  form: FormDataType
}
const LhotseHomeCreateResult: FC<ILhotseHomeCreateResult> = ({ form }) => {
  const history = useHistory()
  return (
    <SectionWrapper>
      <div className="p-4 flex gap-4 items-center hover:bg-gray-100" onClick={() => history.push(`/forms/${form.__uuid}/create`)} tabIndex={-1} role="button">
        <div className="rounded"><img src={form.thumbnail.url} alt={form.thumbnail.name} className="w-16 h-16 bg-sky-blue rounded" /></div>
        <div className="font-semibold">{form.name}</div>
      </div>
    </SectionWrapper>
  )
}

export default LhotseHomeCreateResult
