import Button, { IButton } from '../../../../shared/components/atoms/buttons/Button'

export type WorkflowActionButtonProps = IButton

export const WorkflowActionButton = ({ children, ...props }: WorkflowActionButtonProps) => (
  <Button
    {...{ variant: 'solid', className: 'bg-sky-blue-light text-white', ...props }}
  >
    {children}
  </Button>
)

export default WorkflowActionButton
