import { useMutation, useQueryClient } from 'react-query'
import { useMemo } from 'react'
import { WorkflowBlock } from '../../../types/Workflow/WorkflowBlock'
import { Workflow } from '../../../types/Workflow/Workflow'
import {
  createWorkflowBlock,
  createWorkflowStep,
  CreateWorkflowStepRequestBody, deleteWorkflowBlock,
  updateWorkflowBlock,
} from '../services/workflowStructureServices'
import useFindWorkflowBlockStep from './useFindWorkflowBlockStep'

export const useWorkflowStructureMutations = (workflow: Workflow | undefined) => {
  const queryClient = useQueryClient()
  const findBlockStep = useFindWorkflowBlockStep(workflow)

  const invalidate = () => {
    queryClient.invalidateQueries(['workflows'])
    queryClient.invalidateQueries(['workflow', workflow?.uuid, 'draft'])
    queryClient.invalidateQueries(['workflow', workflow?.uuid, 'version', workflow?.version])
  }

  const throwWorkflowUndefinedError = () => Promise.reject(new Error('Workflow undefined'))
  const throwStepUndefinedError = () => Promise.reject(new Error('Step undefined'))

  const addBlock = useMutation(({ stepUuid, data }: {stepUuid: string, data: Partial<WorkflowBlock>}) => {
    if (!workflow) return throwWorkflowUndefinedError()
    return createWorkflowBlock(workflow.uuid, workflow.version!, stepUuid, [data])
  }, {
    onSettled: invalidate,
  })

  const updateBlock = useMutation((block: Partial<WorkflowBlock> & Pick<WorkflowBlock, 'uuid'>) => {
    if (!workflow) return throwWorkflowUndefinedError()
    const step = findBlockStep(block)
    if (!step) return throwStepUndefinedError()
    return updateWorkflowBlock(workflow.uuid, workflow.version!, step.uuid, block.uuid, block)
  }, {
    onSettled: invalidate,
  })

  const removeBlock = useMutation(
    (block: Pick<WorkflowBlock, 'uuid'>) => {
      if (!workflow) return throwWorkflowUndefinedError()
      const step = findBlockStep(block)
      if (!step) return throwStepUndefinedError()
      return deleteWorkflowBlock(workflow.uuid, workflow.version!, step.uuid, block.uuid)
    },
    {
      onSettled: invalidate,
    },
  )

  const addStep = useMutation((stepData: CreateWorkflowStepRequestBody) => {
    if (!workflow) return throwWorkflowUndefinedError()
    return createWorkflowStep(workflow.uuid, workflow.version!, stepData)
  }, {
    onSettled: invalidate,
  })

  return useMemo(() => ({
    addBlock: addBlock.mutate,
    updateBlock: updateBlock.mutate,
    removeBlock: removeBlock.mutate,
    addStep: addStep.mutate,
  }), [workflow])
}

export default useWorkflowStructureMutations
