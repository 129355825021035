import { useTranslation } from 'react-i18next'
import useGetFormData from '../../../../hooks/useGetFormData'

const ThumbnailPreview: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useGetFormData()
  const existingThumbnail = data?.thumbnail

  if (!existingThumbnail?.url) return null

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-primary-gray text-sm">{t('forms.preview')}</p>
      <div className="flex flex-col gap-y-2">
        <img
          src={existingThumbnail?.url}
          alt="Thumbnail preview"
          className="object-contain h-40 max-w-[240px] w-fit"
        />
        <p className="text-xs">{existingThumbnail?.name}</p>
      </div>
    </div>
  )
}

export default ThumbnailPreview
