import { PartialSection } from '../../../../core/providers/FormProvider'
import { useFormLogic } from '../providers/FormLogicProvider'
import { FormSectionEvents, useFormSection } from '../providers/FormSectionProvider'

interface IFormSectionNavigationButton {
  section: PartialSection
  index: number
}

const FormSectionNavigationButton: React.FC<IFormSectionNavigationButton> = ({
  section, index,
}) => {
  const { isSectionEnabled } = useFormLogic()
  const { currentSection } = useFormSection()

  const isSectionActive = currentSection?.__uuid === section.__uuid
  const loopNumber = section.loop?.__looped_section_count

  const title = (`${section.name || `GROUP ${index + 1}`}${loopNumber ? ` (${loopNumber})` : ''}`)

  return (
    <div className="w-full border-r text-center flex justify-center">
      <button
        key={section.__uuid}
        type="button"
        onClick={() => {
          FormSectionEvents.ProceedTo.dispatch(section.__uuid)
        }}
        className={`${isSectionActive ? 'text-sky-blue-light ' : ''}
        disabled:opacity-25  py-2 px-6 w-full block
        text-sm font-medium hover:text-sky-blue-light hover:border-sky-blue-light transition-colors`}
        disabled={!isSectionEnabled(section)}
      >
        <p className="whitespace-nowrap">{title}</p>
      </button>
    </div>
  )
}

export default FormSectionNavigationButton
