import { LineItemCommentType } from '../../../../../types/shared/LineItemDataTypes'
import {
  apiDelete, apiGetData, apiPost, apiPut,
} from '../../../../../core/utils/api/generic'
import { ReqDefEditorDataType, ReqDefEditorSectionType } from '../constants/editorTypes'

export const getRequisitionSections = (requisitionId: string) => apiGetData<ReqDefEditorSectionType[]>(`/requisitions/${requisitionId}/sections`)

// sections
export const putSectionTitle = (sectionId: number, description: string | undefined) => apiPut(`/item-sections/${sectionId}`, { description, section_type: 'PRODUCT' })
export const postNewSection = (
  requisitionId: string,
  section_type:string = 'PRODUCT',
) => apiPost('/item-sections', { model: 'Requisition', model_id: requisitionId, section_type })
export const swapSection = (
  oldEditorData: ReqDefEditorDataType,
  sourceIndex: number,
  destinationIndex: number,
) => {
  const sectionId1 = oldEditorData[sourceIndex].id
  const sectionId2 = oldEditorData[destinationIndex].id
  return apiPut(`/item-sections/item-section/${sectionId1}/swap/${sectionId2}`)
}
export const deleteSection = (sectionId: number) => apiDelete(`/item-sections/${sectionId}`)

// rows
export const swapRow = (section: any, sourceIndex: number, destinationIndex: number) => {
  const row1id = section.item_attributes[sourceIndex].id
  const row2id = section.item_attributes[destinationIndex].id
  return apiPut(`/item-attributes/${row1id}/swap/${row2id}`)
}
export const putRowDescription = (itemId: number, description: string | undefined) => apiPut(`/item-attributes/${itemId}`, { description })
export const deleteRow = (rowId: number) => apiDelete(`/item-attributes/${rowId}`)

// pricing row
export const postQuantity = (rowId: number) => apiPut(`/item-attributes/${rowId}`, { itemAttribute: rowId })
export const deleteQuantity = (option_id: number) => apiDelete(`/item-attributes/${option_id}`)

// comments
export const deleteComment = (commentId: number) => apiDelete(`/comment/${commentId}`)
export const putComment = (comment: LineItemCommentType | null) => apiPut(`/comment/${comment?.id}`, { comment: comment?.comment })
export const postComment = (model_id: number, model: 'ItemAttribute' | 'LineItem') => apiPost('/comment', { model, model_id, comment: '' })

// measurement type
export const putMeasurementType = (rowId: number, body: any) => apiPut(`/item-attributes/${rowId}`, body)

// options
export const putOption = (optionId: number, value: string) => apiPut(`/options/${optionId}`, {
  option_value: value,
})

export const deleteOption = (optionId: number) => apiDelete(`/options/${optionId}`)
export const postOption = (itemAttributeId: number, optionValue?: string) => apiPost(`/item-attributes/${itemAttributeId}/options`, { option_value: optionValue ?? '' })
