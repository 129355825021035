import QuestionInputWrapper from '../QuestionInputWrapper'
import InputNumber, { IInputNumber } from '../../../../../../shared/components/atoms/inputs/InputNumber'

export const QuestionInputNumber = (props: IInputNumber) => (
  <QuestionInputWrapper>
    {(inputProps) => (
      <InputNumber
        {...inputProps}
        {...props}
      />
    )}
  </QuestionInputWrapper>
)

export default QuestionInputNumber
