import { useMutation } from 'react-query'
import { apiPostSubmissionStatus } from '../../constants/formService'
import useGetFormSubmission from './useGetFormSubmission'
import { FormSubmissionStatusNameType } from '../../../../types/Forms'

export default () => {
  const { data: Form } = useGetFormSubmission()
  return useMutation(
    ['updateStatus', Form?.uuid],
    ({ reason = '', status }: {reason?:string, status: FormSubmissionStatusNameType}) => apiPostSubmissionStatus(Form?.form.uuid!, Form?.uuid!, reason, status),
    { onSettled: () => window.location.reload() },
  )
}
