import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import TipTapEditor from '../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import useGetAndSetActiveMessage from '../../../hooks/useGetAndSetActiveMessage'
import useHandleAddFiles from '../../../hooks/useHandleAddFiles'
import useSendMessage from '../../../hooks/useSendMessage'

const TextInput: React.FC = () => {
  const [messageContent, setMessageContent] = useGetAndSetActiveMessage()
  const { messageProperties: [{ files, messageReply }] } = useMessengerContext()

  const { mutate: handleSendMessage } = useSendMessage()

  const handleAddFiles = useHandleAddFiles()

  return (
    <div
      className="bg-white flex-grow rounded-md "
      style={{ maxWidth: 'calc(100% - 72px)' }}
    >
      <TipTapEditor
        expandedEditor
        onUpdate={setMessageContent}
        content={messageContent}
        onEnterShift={handleSendMessage}
        handleAddFiles={handleAddFiles}
        files={files}
        filesOnTop
        editorContentClassName="max-h-24"
        messageReply={!!messageReply?.referenceId}
        dataCy="tipTapChatEditor"
      />
    </div>
  )
}

export default TextInput
