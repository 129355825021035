import { MdOutlineAddComment } from 'react-icons/md'
import useGetFinalOfferFileItem from '../../hooks/useGetFinalOfferFileItem'
import useToggleComment from '../../hooks/useToggleComment'

interface ICommentButton {
  updateFinalOfferAutoSave: () => void
}

const CommentButton: React.FC<ICommentButton> = ({ updateFinalOfferAutoSave }) => {
  const finalOfferItem = useGetFinalOfferFileItem()
  const comment = finalOfferItem?.line_items[0]?.comment?.comment

  const toggleComment = useToggleComment(finalOfferItem?.line_items[0], 1, finalOfferItem, 0)

  const handleToggleComment = () => {
    toggleComment()
    updateFinalOfferAutoSave()
  }

  return (
    <button
      type="button"
      onClick={handleToggleComment}
      data-cy="final-offer-comment-button"
    >
      <MdOutlineAddComment
        className={`text-lg cursor-pointer min-w-[20px] ${typeof comment === 'string' ? 'text-sky-blue' : 'text-gray-400'}`}
        size={20}
      />
    </button>
  )
}

export default CommentButton
