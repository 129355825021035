import { useQuery } from 'react-query'
import { apiListExternalDataEntities } from '../services/ExternalDataServices'

const useListExternalDataEntities = () => {
  const { data, isLoading, error } = useQuery('entities', apiListExternalDataEntities)
  return {
    data,
    isLoading,
    error,
  }
}

export default useListExternalDataEntities
