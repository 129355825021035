import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { MdEuroSymbol } from 'react-icons/md'
import NumberFormat from 'react-number-format'
import { FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import useQuestionValues from '../hooks/useQuestionValues'
import useUpdateSubmission from '../hooks/useUpdateSubmission'
import { blurInputOnEnter } from '../../../../shared/utils/inputUtils'
import KeyValueQuestion from './atoms/KeyValueQuestion'
import { getUpdatedByUuid } from '../../../../shared/utils/arrayHelpers'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'

interface INumberQuestion {
  question: FormQuestionType
}

const PriceQuestion: React.FC<INumberQuestion> = ({
  question,
}) => {
  const { t } = useTranslation()
  const [questionValues, setQuestionValues] = useQuestionValues(question)

  const updateForm = useUpdateSubmission()
  const isDisabled = useIsQuestionDisabled(question)

  const onChange = (newValue: FormQuestionValue) => {
    setQuestionValues(getUpdatedByUuid(questionValues, [newValue]))
  }

  return (
    <KeyValueQuestion question={question}>
      {(answer) => (
        <div className="flex gap-x-2 relative w-full">
          <NumberFormat
            required={false}
            thousandsGroupStyle="thousand"
            value={answer.value as string || ''}
            disabled={isDisabled}
            decimalSeparator=","
            thousandSeparator="."
            displayType="input"
            type="text"
            decimalScale={2}
            fixedDecimalScale
            className={`${isDisabled ? 'bg-transparent' : ''} base-form-input  date-input`}
            onChange={({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => onChange({ ...answer, value })}
            onBlur={updateForm}
            placeholder={t('type_number')}
            onKeyDown={blurInputOnEnter}
            allowNegative={false}
          />
        </div>
      )}
    </KeyValueQuestion>
  )
}

export default PriceQuestion
