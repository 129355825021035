import { FormQuestionType } from '../../../../types/Forms'
import ResponseChoices from '../ResponseChoices/ResponseChoices'
import QuestionError from './atoms/QuestionError'

interface IMultiSelectQuestion {
  question: FormQuestionType
}

const MultiSelectQuestion: React.FC<IMultiSelectQuestion> = ({
  question,
}) => (
  <>
    <ResponseChoices question={question} />
    <QuestionError question={question} />
  </>
)

export default MultiSelectQuestion
