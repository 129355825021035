import { useFormLogic } from '../providers/FormLogicProvider'
import useGetFormSubmissionPrices from './useGetFormSubmissionPrices'
import { FormQuestionValueType, FormTemplateType } from '../../../../types/Forms'

export const useGetTotalPrice = (submissionIdProp?:string, submissionDataProp?:FormTemplateType) => {
  const { allEnabledQuestions } = useFormLogic()
  const submissionPrices = useGetFormSubmissionPrices(submissionIdProp, submissionDataProp)

  const givenTotalPrice = allEnabledQuestions.find((q) => q.key === 'off-total_price')?.values?.[0]?.value || 0

  // Check if price is visible and deined
  const formatGivenPrice = (price:FormQuestionValueType) => price
    .toString()
    .replace('.', '')
    .replace(',', '.')

  const priceEqual = !givenTotalPrice
    ? true
    : parseFloat(submissionPrices.value) === parseFloat(formatGivenPrice(givenTotalPrice))
  const shouldCompare = !!givenTotalPrice && window.location.host.includes('fraport')

  return {
    priceEqual, shouldCompare, ...submissionPrices,
  }
}

export default useGetTotalPrice
