import { useInfiniteQuery } from 'react-query'
import queryKeys from '../constants/SuppliersQueryKeys'
import { apiGetSuppliers } from '../services/SuppliersService'

export default (perPage = 15) => useInfiniteQuery(
  queryKeys.SUPPLIERS,
  ({ pageParam = 1 }) => apiGetSuppliers(pageParam, perPage),
  {
    getNextPageParam: (data) => (
      (data.meta?.last_page && (data.meta?.current_page < data.meta?.last_page))
        ? data.meta.current_page + 1
        : false
    ),
  },
)
