import { useMutation, useQueryClient } from 'react-query'
import { putSelectLineItem } from '../../../../services/services'
import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import queryKeys from '../../../../../Requisition/subModules/Award/constants/OfferViewQueryKeys'
import useGetRequisitionId from '../../../../../../shared/hooks/useGetRequisitionId'

type LineItemMutationArguments ={invite: RequisitionInviteType, lineItemId: number}
const useSelectLineItem = () => {
  const client = useQueryClient()
  const requisitionId = useGetRequisitionId()

  return useMutation((({ invite, lineItemId }: LineItemMutationArguments) => putSelectLineItem({ offerId: invite.offer.id, lineItemId })) as () => Promise<void>, {
    onSuccess: (data: any, { invite }: LineItemMutationArguments) => {
      client.invalidateQueries(['calculated-price', invite.token])
      client.invalidateQueries([queryKeys.OFFERS_DATA, requisitionId])
    },
  })
}

export default useSelectLineItem
