import { Children, cloneElement, isValidElement } from 'react'
import { INodeOrStringChildren } from '../../../../types/shared/GeneralTypes'

interface ITableRow extends INodeOrStringChildren {
  flex?: boolean
  className?: string
  onClick?: () => void
  active?: boolean
}

const TableRow: React.FC<ITableRow> = ({
  children, flex, className, onClick, active,
}) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child)
    }
    return child
  })

  return (
    <tr
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={`${flex ? 'flex' : 'table-row'}  my-2 ${active ? 'bg-gray-100' : ''}  ${className}`}
    >
      {childrenWithProps}
    </tr>
  )
}

export default TableRow
