import useIsReqEditable from '../../../utils/useIsReqEditable'
import RequisitionTopbarSearch from '../../RequisitionTopbarSearch/RequisitionTopbarSearch'
import RequisitionTopbarInformation from './RequisitionTopbarInformation'
import RequisitionTopbarLastCallConfirmation from './RequisitionTopbarLastCallConfirmation'

interface IRequisitionTopbar {
  useLastCallState?: [
    number[] | undefined,
    React.Dispatch<React.SetStateAction<number[] | undefined>>
  ]
}

const RequisitionTopbar: React.FC<IRequisitionTopbar> = ({
  useLastCallState,
}) => {
  const { editable } = useIsReqEditable()
  const [lastCall] = useLastCallState || []

  return (
    <div
      className="w-full border-b px-8 py-6 flex justify-between right-0 bg-white z-10 space-x-4"
      id="page-topbar"
    >
      <RequisitionTopbarSearch disabled={!editable} />
      {lastCall
        ? <RequisitionTopbarLastCallConfirmation useLastCallState={useLastCallState} />
        : <RequisitionTopbarInformation />}
    </div>
  )
}

export default RequisitionTopbar
