import { DocumentTypeOptionType } from '../../../types/Company/DocumentType'

export const documentTypes: DocumentTypeOptionType[] = [
  { id: 1, label: 'documents.type_nda', value: 'nda' },
  { id: 2, label: 'documents.type_other', value: 'other' },
]

export const documentTypesObject: {nda: DocumentTypeOptionType, other: DocumentTypeOptionType} = {
  nda: { id: 1, label: 'documents.type_nda', value: 'nda' },
  other: { id: 2, label: 'documents.type_other', value: 'other' },
}
