import _ from 'lodash'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardTable from '../../../../../../shared/components/atoms/CardTable/CardTable'
import MediaTableRow from '../../../../../../shared/components/atoms/FileUpload/MediaTableRow'
import MediaTableRowActions from '../../../../../../shared/components/atoms/FileUpload/MediaTableRowActions'
import Label from '../../../../../../shared/components/atoms/Label'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import EmptyState from '../../../../../../shared/components/organisms/EmptyState/EmptyState'
import { ReqDefEditorSectionType } from '../../constants/editorTypes'
import useUploadSectionFiles from '../ReqDefContainer/EditorSection/ItemAttributeSection/hooks/useUploadSectionFiles'

import { itemSectionMediaService } from '../../services/attachmentsService'

export interface IProductDetailsFiles {
  section: ReqDefEditorSectionType,
  refreshSectionData: () => void,
  disabled?: boolean
}

const ProductDetailsFiles: FC<IProductDetailsFiles> = ({ section, disabled, refreshSectionData }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const uploadSectionFiles = useUploadSectionFiles()
  const { remove } = itemSectionMediaService(section?.uuid || '')

  const ref = useRef<HTMLInputElement>(null)

  const tableColumns = [t('generic.name'), t('generic.type'), t('media.updated_at'), t('generic.actions')]
  const filesEmpty = _.isEmpty(section?.files)

  return (
    <div className="flex flex-col space-y-2">
      <Label label={t('generic.attachments', 'Attachments')} />
      <CardTable columns={tableColumns}>
        {section?.files?.map((file) => (
          <MediaTableRow media={file} key={`address-row-${file.uuid}`}>
            <MediaTableRowActions
              media={file}
              onDelete={() => { remove(file.uuid); refreshSectionData() }}
            />
          </MediaTableRow>
        ))}
        {filesEmpty && !disabled ? (
          <tr>
            <td colSpan={tableColumns.length}>
              <EmptyState
                onClick={() => ref.current?.click()}
                message={t('generic.no_attachments_added', 'No attachments added')}
                buttonMessage={t('generic.add_attachment', 'Add Attachment')}
              />
            </td>
          </tr>
        ) : null}
      </CardTable>
      {!disabled && !filesEmpty
        ? (
          <AddButton
            label={t('generic.add_attachment', 'Add Attachment')}
            onClick={() => ref.current?.click()}
            loading={isLoading}
          />
        )
        : null}
      <input
        disabled={disabled}
        ref={ref}
        multiple
        className="hidden"
        type="file"
        onChange={(files) => files.target.files && uploadSectionFiles(
          files.target.files,
          section,
          setIsLoading,
        )}
      />
    </div>
  )
}
export default ProductDetailsFiles
