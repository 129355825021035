import { useTranslation } from 'react-i18next'
import TableHead from '../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../shared/components/atoms/SharedTable/TableHeader'

const ProductSectionTableHeader = ({ actions = true }: { actions?: boolean }) => {
  const { t } = useTranslation()

  return (
    <TableHead>
      <TableHeader className="w-2" />
      <TableHeader>
        <div className="flex flex-col">
          <div className="font-bold">{t('generic.name', 'Name')}</div>
          <div>{t('generic.cost-center', 'Cost Center')}</div>
        </div>
      </TableHeader>
      <TableHeader>
        <div className="flex flex-col">
          <div className="font-bold">{t('generic.offer', 'Offer')}</div>
          <div>{t('generic.total-net-price', 'Total Net Price')}</div>
        </div>
      </TableHeader>
      <TableHeader />
    </TableHead>
  )
}

export default ProductSectionTableHeader
