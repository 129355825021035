import { useMutation, useQueryClient } from 'react-query'
import useGetRequisitionData from '../../../../hooks/useGetRequisition'
import { uploadRequisitionAttachments } from '../../../../services/attachmentsService'
import toastr from '../../../../../../../../shared/components/organisms/toastr/toastr'
import keys from './ReqDefAttachmentsQueryKeys'

export default () => {
  const { data: requisition } = useGetRequisitionData()
  const cache = useQueryClient()

  return useMutation(
    (files: File[]) => {
      if (!requisition) return Promise.resolve(null)

      return uploadRequisitionAttachments(requisition.uuid, files)
    },
    {
      onSuccess: (data) => {
        cache.setQueryData([keys.REQUISITION_ATTACHMENTS, requisition?.uuid], () => data)
      },
      onError: () => {
        toastr.error('error.upload_failed', 'failedUpload')
      },
    },
  )
}
