import { useQuery } from 'react-query'
import { apiGetTransmissionPreview } from '../services/WorkflowService'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'

export const useGetTransmissionPreview = () => {
  const requisitionId = useGetRequisitionId()

  return useQuery(['transmission-preview', requisitionId], () => apiGetTransmissionPreview(requisitionId))
}

export default useGetTransmissionPreview
