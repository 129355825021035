import { useTranslation } from 'react-i18next'
import { RequisitionSearchDataCatalogType, RequisitionSearchDataDataType } from '../../../../../types/Requisition/RequisitionSearchResultsDataTypes'

interface INewRequisitionTemplateCatalogItem {
  data: (RequisitionSearchDataCatalogType | RequisitionSearchDataDataType)[]
  itemComponent: React.ElementType
  openModal: (selected: any) => void
  maxElements?: number
}

const NewRequisitionTemplateItemsGrid:
  React.FC<INewRequisitionTemplateCatalogItem> = (
    {
      data, itemComponent: ItemComponent, openModal, maxElements = 6,
    },
  ) => {
    const { t } = useTranslation()

    const trimmedData = data?.filter(
      (element, index: number) => index < maxElements,
    )

    if (!data?.length) {
      return (
        <div className="w-full flex justify-center">
          <p className="text-sm text-gray-500">{t('new_requisition.no_results')}</p>
        </div>
      )
    }

    return (
      <div className="w-full gap-4 h-auto grid grid-cols-2 2xl:grid-cols-3" data-cy="past-req-grid">
        {
          trimmedData.map((tab) => (
            <ItemComponent
              item={tab}
              onClick={() => openModal(tab)}
              key={tab.id}
            />
          ))
        }
      </div>
    )
  }

export default NewRequisitionTemplateItemsGrid
