import useGetOffer from '../../../hooks/useGetOffer'
import DeclineToOfferButton from './DeclineToOfferButton'
import SubmitOfferButton from './SubmitOfferButton'

const Buttons: React.FC<{ validateForm: () => boolean }> = (
  { validateForm },
) => {
  const { data: offerData } = useGetOffer()

  const supplierActionedStates = ['OFFER_SUBMITTED', 'OFFER_ACCEPTED', 'OFFER_DECLINED_BY_CUSTOMER']

  return !supplierActionedStates.includes(offerData?.status || '')
    ? (
      <div className="w-max flex gap-x-2">
        <SubmitOfferButton validateForm={validateForm} />
        <DeclineToOfferButton />
      </div>
    )
    : null
}

export default Buttons
