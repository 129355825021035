export const REQUISITION_ADD = 'requisition-add'
export const REQUISITION_VIEW = 'requisition-view'
export const REQUISITION_EDIT = 'requisition-edit'
export const REQUISITION_DELETE = 'requisition-delete'
export const REQUISITION_RECOMMENDATIONS = 'requisition-recommendations'

// Suppliers permissions
export const SUPPLIER_ADD = 'supplier-add'
export const SUPPLIER_VIEW = 'supplier-view'
export const SUPPLIER_EDIT = 'supplier-edit'
export const SUPPLIER_DELETE = 'supplier-delete'

// Offer permissions
export const OFFER_VIEW = 'offer-view'
export const OFFER_ACCEPT = 'offer-accept'
export const OFFER_NEGOTIATE = 'offer-negotiate'
export const OFFER_DECLINE = 'offer-decline'

// Organisation permissions
export const ORGANISATION_VIEW = 'organisation-view'
export const ORGANISATION_EDIT = 'organisation-edit'

// Forms permissions
export const FORM_VIEW = 'form-view'
export const FORM_ADD = 'form-add'
export const FORM_EDIT = 'form-edit'
export const FORM_SUBMIT = 'form-submit'
export const FORM_DELETE = 'form-delete'
export const FORM_PUBLISH = 'form-publish'

// Users permissions
export const USER_VIEW = 'user-view'
export const USER_EDIT = 'user-edit'
export const USER_ADD = 'user-add'
export const USER_DELETE = 'user-delete'

/*
     * This role should be manually added for OPS
     * this allows you to do everything everywhere.
     */
export const EVERYTHING = 'everything-everything'
