import { OffersOfferType } from '../../../../../../../types/shared/OfferDataTypes'
import ShippingCosts from './ShippingCosts'
import ShippingTax from './ShippingTax'
import TaxRate from './TaxRate'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'

interface IAdditionalCosts {
  invite: RequisitionInviteType
  currentStatus: string | undefined
}

const AdditionalCosts: React.FC<IAdditionalCosts> = ({
  invite, currentStatus,
}) => (
  <>
    <ShippingCosts invite={invite} />
    <ShippingTax invite={invite} />
    <TaxRate invite={invite} currentStatus={currentStatus} />
  </>
)

export default AdditionalCosts
