import SearchInput from '../../../../../../../shared/components/atoms/inputs/SearchInput'
import { useCoordinateProvider } from '../../../providers/CoordinateProvider'

interface IReqCoordinateSearch {
  activeSection: number
}

const ReqCoordinateSearch: React.FC<IReqCoordinateSearch> = ({
  activeSection,
}) => {
  const { searchQuery: [query, setQuery] } = useCoordinateProvider()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  return activeSection !== 1
    ? (
      <div className="flex flex-grow">
        <div className="relative mt-1 flex flex-grow items-center">
          <SearchInput onChange={onChange} value={query} className="py-3 border-white shadow-sm" />

          {/* TODO: Implement Filter functionality */}
          {/* <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5"> */}
          {/*  <div className="inline-flex items-center rounded border border-rock-gray-light px-2 py-1 font-sans text-sm font-medium text-gray-400 "> */}
          {/*    <button type="button" className="text-sky-blue-light bg-ice-blue px-3 py-1 mr-1 font-medium rounded-md transition-colors"> */}
          {/*      Supplier Name */}
          {/*    </button> */}
          {/*    <button type="button" className="text-rock-gray hover:bg-ice-blue hover:text-sky-blue-light px-3 py-1 font-medium rounded-md transition-colors"> */}
          {/*      Portfolio */}
          {/*    </button> */}
          {/*  </div> */}
          {/*  <div className="inline-flex items-center px-4 font-sans text-sm font-medium text-sky-blue-light hover:text-sky-blue cursor-pointer"> */}
          {/*    Filter */}
          {/*  </div> */}
          {/* </div> */}
        </div>
      </div>
    )
    : null
}

export default ReqCoordinateSearch
