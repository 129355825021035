import { Dispatch, SetStateAction } from 'react'
import { matchPath } from 'react-router'
import useGetNavigationActions from './hooks/useGetNavigationActions'
import useGetRequisitionActions from './hooks/useGetRequisitionActions'

/* eslint-disable import/prefer-default-export */
export const useFilterActionPanelData = (
  setStatus: Dispatch<SetStateAction<any>>,
) => {
  const pathData = matchPath<{ requisitionId: string }>(window.location.pathname, { path: '/requisition/req-:requisitionId' })
  const requisitionActions = useGetRequisitionActions(setStatus)
  const navigationActions = useGetNavigationActions()

  return (rawQuery: string) => {
    const query = rawQuery.toLowerCase().replace(/^[#>/]/, '')
    if (rawQuery === '>' && pathData?.params?.requisitionId) {
      return requisitionActions
    }
    if (rawQuery.startsWith('/')) {
      return navigationActions.filter((n) => n.name.toLowerCase().includes(query))
    }
    return []
  }
}
