import { useQueryClient } from 'react-query'
import { OfferDataType } from '../../../types/shared/OfferDataTypes'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import useGetCalculatedPrices from '../components/Footer/hooks/useGetCalculatedPrices'
import queryKeys from '../constants/queryKeys'
import { putRequisitionValue } from '../services/services'
import useCheckOfferStatusAndUpdateIfSubmitted from './useCheckOfferStatusAndUpdateIfSubmitted'
import useGetToken from './useGetToken'

export default () => {
  const cache = useQueryClient()
  const token = useGetToken()

  const checkAndUpdateOfferStatus = useCheckOfferStatusAndUpdateIfSubmitted()
  const { refetch } = useGetCalculatedPrices()

  const updateInnerOfferValue = (key: string, value: any) => {
    cache.setQueryData<OfferDataType | undefined>(
      [queryKeys.offer],
      (oldData: OfferDataType | undefined) => {
        if (oldData) {
          const newOffer = {
            ...oldData,
            offer: {
              ...oldData?.offer,
              [key]: value,
            },

          }
          return newOffer
        }
        return oldData
      },
    )
  }

  const updateOuterOfferValue = (key: string, value: any) => {
    cache.setQueryData<OfferDataType | undefined>(
      [queryKeys.offer],
      (oldData: OfferDataType | undefined) => {
        if (oldData) {
          const newOffer = {
            ...oldData,
            [key]: value,
          }
          checkAndUpdateOfferStatus()
          return newOffer
        }
        return oldData
      },
    )
  }

  return async (
    key: string,
    value: any,
    updateAutoSave?: () => void,
  ) => {
    try {
      await putRequisitionValue(key, value, token)
      if (key === 'delivery_time_estimate_comment') updateInnerOfferValue(key, value)
      else { updateOuterOfferValue(key, value) }

      if (updateAutoSave) updateAutoSave()
    } catch {
      toastr.error('generic.invalid_input', 'errorRequisitionValue')
    }
    checkAndUpdateOfferStatus()
    refetch()
  }
}
