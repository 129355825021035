import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import Button from '../../../../../shared/components/atoms/buttons/Button'

interface INewReqRecommendedGrid {
  title: ReactNode
  isLoading: boolean
  gridItemComponent: ReactNode
  selectTab: () => void
  showMoreIcon: boolean
}

const NewReqRecommendedGrid: React.FC<INewReqRecommendedGrid> = ({
  title, isLoading, gridItemComponent, selectTab, showMoreIcon,
}) => {
  const { t } = useTranslation()
  return (
    <div className="w-full">
      <div className="flex flex-col h-full">
        {title}
        <div className="flex w-full mt-4">
          {isLoading
            ? <CoreLoadingIcon />
            : gridItemComponent}
        </div>
        {showMoreIcon
          && (
            <div className="flex justify-end">
              <Button color="sky-blue" onClick={selectTab}>{t('generic.more')}</Button>
            </div>
          )}
      </div>

    </div>
  )
}

export default NewReqRecommendedGrid
