import type { RequireAtLeastOne } from 'type-fest'
import {
  apiDelete, apiGet, apiPost, apiPut,
} from '../../../core/utils/api/generic'
import { Offer } from '../../../types/Offering'
import { PaginatedResponse } from '../../../types/shared/PaginatedResponse'
import { CalculateOfferTotalPriceParams } from '../types'

export const getOffers = (searchParams?: RequireAtLeastOne<{reference_key: string, offering_uuid: string}>) => apiGet<{ data: Offer[] }>('/offering-offers', searchParams).then(({ data }) => data)

export const getPaginatedOffers = (searchParams?: RequireAtLeastOne<{reference_key: string, offering_uuid: string}>, page = 1, per_page = 5) => apiGet<PaginatedResponse<Offer>>('/offering-offers', { ...searchParams, page, per_page })

export const postOffer = (data: Partial<Omit<Offer, 'uuid'>> & RequireAtLeastOne<{reference_key: string, offering_uuid: string}> & { intake_flow_uuid?: string}) => apiPost('/offering-offers', data)

export const putOffer = ({ uuid, ...data }: Partial<Offer> & Pick<Offer, 'uuid'>) => apiPut(`/offering-offers/${uuid}`, data)

export const deleteOffer = (uuid: string) => apiDelete(`/offering-offers/${uuid}`)

export const calculateOfferTotalPrice = (data: CalculateOfferTotalPriceParams) => apiPost<{ data: Offer }>('/offering-offers/calculate-price', data).then(({ data: return_data }) => return_data)
