import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import BasecampNoResults from '../NoResults'
import useGetIntakes from './hooks/useGetIntakes'
import IntakeFlowRow from './components/IntakeFlowRow'

const IntakeFlowsTable = () => {
  const { data: intakes, isLoading } = useGetIntakes()

  if (isLoading) return <CoreLoadingIcon />

  if (intakes?.length === 0 && !isLoading) return <BasecampNoResults />

  return (
    <div className="flex flex-col space-y-2.5">
      {intakes?.map(
        (intake) => <IntakeFlowRow key={intake.uuid} intake={intake} />,
      )}
    </div>
  )
}

export default IntakeFlowsTable
