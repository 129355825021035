import { useQueryClient } from 'react-query'
import keys from '../../constants/SuppliersQueryKeys'
import SupplierBranchDataType from '../../../../types/Suppliers/SupplierBranchDataType'

export default (supplierBranchId: number) => {
  const cache = useQueryClient()
  return () => cache.setQueryData<SupplierBranchDataType | undefined>(
    [keys.SUPPLIER_BRANCH, supplierBranchId],
    (oldData) => (oldData ? { ...oldData, preferred: !oldData?.preferred } : undefined),
  )
}
