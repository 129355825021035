import { useTranslation } from 'react-i18next'
import SelectAllSuppliersToggle from './SelectAllSuppliersToggle'

const SupplierNameHeader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row pl-3">
      <SelectAllSuppliersToggle />
      <span className="ml-2">{t('coordinate.supplier_name', 'Supplier Name')}</span>
    </div>
  )
}

export default SupplierNameHeader
