import { Listbox, Transition } from '@headlessui/react'
import {
  Fragment, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { MdDone, MdKeyboardArrowDown } from 'react-icons/md'
import OptionType from '../../../types/shared/OptionType'

export interface IInputSelect {
  options: OptionType[]
  value: OptionType
  handleChange: (v: OptionType) => void
  defaultOptionIndex?: number
  className?: string
  label?: string
}

const InputSelect: React.FC<IInputSelect> = ({
  options, defaultOptionIndex = 0, label = '', handleChange, className, value,
}) => {
  const { t } = useTranslation()

  const [selectedOption, setSelected] = useState(options[defaultOptionIndex])

  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  return (
    <div className={`flex ${className || 'w-full'}`}>
      <Listbox
        value={selectedOption}
        onChange={(v) => {
          setSelected(v)
          handleChange(v)
        }}
      >
        <div className="relative w-full">
          {label && <div className="mb-1">{t(label)}</div>}
          <Listbox.Button
            className="
          bg-white
          relative w-full
          border border-gray-200
          rounded-md
          pl-3 pr-10 py-2
          text-left
          cursor-default
          focus:outline-none
          focus:ring-1
          focus:ring-blue-600
          focus:border-blue-600
          "
          >
            <span className="block truncate">{t(selectedOption.label)}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <MdKeyboardArrowDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 w-48 bg-white drop-shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) => ` ${active ? 'text-white bg-blue-600' : 'text-gray-900'} cursor-default select-none relative py-2 pl-3 pr-9 `}
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                        {t(option.label)}
                      </span>
                      {selected ? (
                        <span
                          className={`${active ? 'text-white' : 'text-sky-blue'} absolute inset-y-0 right-0 flex items-center pr-4 `}
                        >
                          <MdDone className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default InputSelect
