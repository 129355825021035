import { FormQuestionType } from '../../../types/Forms/formTypes'
import useGetAllQuestions from './useGetAllQuestions'

export default (question?: FormQuestionType) => {
  if (!question) return undefined

  const allQuestions = useGetAllQuestions()

  return allQuestions.map((possibleQuestion) => possibleQuestion.__uuid)
    .indexOf(question.__uuid) + 1
}
