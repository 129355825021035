import { useTranslation } from 'react-i18next'
import generateUserName from '../../../../shared/utils/generateUserName'
import useAutoSave from '../../../Requisition/utils/useAutoSave'
import useGetOffer from '../../hooks/useGetOffer'
import AutoSaveBanner from '../DescriptionSection/Sections/AutoSaveBanner'
import SupplierNumberInput from './SupplierNumberInput'

const SupplierNumberSection: React.FC = () => {
  const { t } = useTranslation()

  const { data: offerData } = useGetOffer()

  const [descriptionAutosave, updateDescriptionAutosave] = useAutoSave()

  return (
    <div className="border border-gray-200 bg-white rounded-md">
      <AutoSaveBanner autoSave={descriptionAutosave} title={t('supplier_offer.supplier_details')} />
      <div className="flex justify-between p-5">
        <div className="flex flex-col">
          <p>{t(generateUserName(offerData?.supplier))}</p>
          <p className="text-gray-400 text-sm">{offerData?.supplier?.email}</p>
        </div>
        <SupplierNumberInput updateDescriptionAutosave={updateDescriptionAutosave} />
      </div>
    </div>
  )
}

export default SupplierNumberSection
