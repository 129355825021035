import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileUpload from '../../../../shared/components/atoms/FileUpload/FileUpload'
import SharedError from '../../../../shared/components/layout/SharedFileUploadPill/SharedError'
import useGetFinalOfferFileItem from '../../hooks/useGetFinalOfferFileItem'
import useRemoveSupplierOfferFile from '../../hooks/useRemoveSupplierOfferFile'
import { invalidIdStateType } from '../../utils/validationUtils'
import FinalOfferDocumentCommentButton from './CommentButton'
import useFinalOfferUploadFile from './hooks/useFinalOfferUploadFile'

interface IUploadAndCommentButtons {
  updateFinalOfferAutoSave: () => void
  invalidIds: invalidIdStateType
}

const UploadAndCommentButtons: React.FC<
  IUploadAndCommentButtons> = ({
    updateFinalOfferAutoSave,
    invalidIds,
  }) => {
    const { t } = useTranslation()

    const [fileLoading, setFileLoading] = useState(false)
    const uploadFile = useFinalOfferUploadFile(updateFinalOfferAutoSave, setFileLoading)

    const finalOfferItem = useGetFinalOfferFileItem()
    const removeFile = useRemoveSupplierOfferFile(finalOfferItem)

    const files = finalOfferItem?.line_items[0]?.files

    const isFinalOfferMissing = invalidIds?.rows?.includes(finalOfferItem.id)

    return (
      <div className="flex justify-between px-5 py-5 items-center gap-x-4">
        <div className="flex flex-col gap-y-2 w-1/2">
          <p className="w-full">{t('supplier_offer.upload_final_documents')}</p>
          {isFinalOfferMissing && (
            <SharedError
              text={t('supplier_offer.must_include_files')}
              dataCy="final-offer-error"
            />
          )}
        </div>
        <div className="flex items-center justify-end gap-x-4 align-center w-1/2 pl-[26px] break-all">
          <FileUpload
            uploadFile={uploadFile}
            removeFile={removeFile}
            files={files}
            fileIsLoading={fileLoading}
          />
          <FinalOfferDocumentCommentButton updateFinalOfferAutoSave={updateFinalOfferAutoSave} />
        </div>
      </div>
    )
  }

export default UploadAndCommentButtons
