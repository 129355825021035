// REFACTOR
import { useTranslation } from 'react-i18next'
import { MediaType } from '../../../../types/shared/MediaTypes'
import ConfirmationModal from '../../organisms/modals/ConfirmationModal'
import Menu, { MenuItemType } from '../../organisms/menus/Menu'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import apiClient from '../../../../core/utils/apiClient'

export type MediaTableRowActionsProps = {
  media: MediaType
  onDelete?: () => void
}

const MediaTableRowActions: React.FC<MediaTableRowActionsProps> = ({ media, onDelete }) => {
  const { t } = useTranslation()
  const utils = useUtils()

  const onDownloadFile = async () => {
    const { data } = await apiClient.get(`/media/${media.uuid}/download`, { responseType: 'blob' })
    const fileUlr = URL.createObjectURL(data)
    window.open(fileUlr, '_blank')
  }

  const handleDelete = () => utils.modal.set(
    {
      isOpen: true,
      title: t('generic.confirm'),
      content:
        (
          <ConfirmationModal
            onConfirm={() => {
              if (onDelete) onDelete()
              utils.modal.set({ isOpen: false })
            }}
          >
            {t('confirm.are_you_sure_delete')}
          </ConfirmationModal>
        ),
    },
  )

  const menuItems = [
    {
      name: 'generic.download',
      onClick: onDownloadFile,
      dataCy: 'download-media',
    },
    onDelete && ({
      name: 'generic.delete',
      onClick: handleDelete,
    }),
  ].filter((option) => !!option) as MenuItemType[]

  if (menuItems.length === 0) return null

  return (
    <div className="flex justify-end items-center">
      <Menu menuItems={menuItems} buttonId="address-context-menu" buttonCy="address-context-menu" />
    </div>
  )
}
export default MediaTableRowActions
