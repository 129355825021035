import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import Titlebar from '../../../../shared/components/layout/Titlebar'
import { useSuppliersProvider } from '../../providers/SuppliersProvider'
import useGetTotalResultsCount from '../../hooks/useGetTotalResultsCount'

const Header: React.FC = () => {
  const { t } = useTranslation()

  const { searchQuery: [searchQuery, setSearchQuery] } = useSuppliersProvider()
  const { totalResults, isLoading } = useGetTotalResultsCount(searchQuery)

  return (
    <Titlebar
      title={(
        <div className="flex gap-2 items-center">
          <p>{t('generic.supplier')}</p>
          {isLoading
            ? <CoreLoadingIcon />
            : `(${totalResults})`}
        </div>
      )}
      inputState={[searchQuery, setSearchQuery]}
      dataCy="searchSuppliersInput"
    />
  )
}

export default Header
