import CompanyFormsHeader from './FormsTable/CompanyFormsHeader'
import CompanyFormsList from './FormsTable/CompanyFormsList'

const CompanyForms = () => (
  <div className="flex w-full flex-col h-full">
    <CompanyFormsHeader />
    <CompanyFormsList />
  </div>
)

export default CompanyForms
