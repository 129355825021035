import MessageInput from './MessageInput'
import NewMessageReplyPreview from './NewMessageReplyPreview'

const ChatMessageEditor: React.FC = () => (
  <div className="relative">
    <NewMessageReplyPreview />
    <MessageInput />
  </div>
)

export default ChatMessageEditor
