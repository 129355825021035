import _ from 'lodash'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import useGetFormParams from '../../hooks/useGetFormParams'
import useGetFormQuestion from './useGetFormQuestion'

export default () => {
  const { question: [question] } = useFormContext()
  const formQuestion = useGetFormQuestion()
  const { questionId } = useGetFormParams()

  const hasUnsavedChanges = !!question
    && !!formQuestion
    && !!questionId
    && !_.isEqual(question, formQuestion)

  return hasUnsavedChanges
}
