import { useTranslation } from 'react-i18next'

const BannerSubmitted: React.FC = () => {
  const { t } = useTranslation()
  return (
    <p data-cy="banner-submitted" className="flex text-left py-3 px-8 bg-yellow-100 bg-opacity-80">
      {t('supplier_view.offer_submitted_alert')}
    </p>
  )
}

export default BannerSubmitted
