import { Combobox } from '@headlessui/react'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MdSearch } from 'react-icons/md'

interface IActionPanelInput {
  setRawQuery: Dispatch<SetStateAction<string>>
}

const ActionPanelInput: React.FC<IActionPanelInput> = ({
  setRawQuery,
}) => {
  const { t } = useTranslation()
  return (
    <div className="relative">
      <MdSearch
        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      <Combobox.Input
        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
        placeholder={`${t('generic.search')}...`}
        onChange={(event) => setRawQuery(event.target.value)}
        data-cy="action-panel-input"
      />
    </div>
  )
}

export default ActionPanelInput
