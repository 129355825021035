import { useTranslation } from 'react-i18next'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'

interface ILabelInfo extends INodeChildren {
  label?: string
  inverted?: boolean,
  className?: string
  textClassName?: string
}

const LabelInfo: React.FC<ILabelInfo> = ({
  label, children, inverted, className, textClassName = '',
}) => {
  const { t } = useTranslation()
  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label && <label htmlFor={label} className={`text-xs ${inverted ? 'text-gray-900' : 'text-gray-400'} ${textClassName}`}>{t(label)}</label>}
      <div id={label} className={`text-sm ${inverted ? 'text-gray-500' : 'text-gray-900'}`}>{children}</div>
    </div>
  )
}

export default LabelInfo
