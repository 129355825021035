import { useMutation, useQueryClient } from 'react-query'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import queryKeys from '../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorSectionType } from '../constants/editorTypes'
import { deleteOption } from '../services/editorService'

export default function useDeleteOption() {
  const requisitionId = useGetRequisitionId()
  const cache = useQueryClient()

  return useMutation(
    (optionId: number) => deleteOption(optionId),
    {
      onMutate: (optionId) => {
        const data: ReqDefEditorSectionType[] | undefined = cache.getQueryData([queryKeys.SECTIONS_DATA, requisitionId])

        cache.setQueryData(
          [queryKeys.SECTIONS_DATA, requisitionId],
          () => data?.map((d) => (
            {
              ...d,
              item_attributes: d?.item_attributes?.map((ia) => (
                {
                  ...ia,
                  options: ia.options.filter((o) => o.id !== optionId),
                }
              )),
            }
          )),
        )
      },
    },
  )
}
