import { useState } from 'react'
import TabsNavigation from '../../../../../../../shared/components/atoms/Tabs/TabsNavigation'
import { FormSectionType } from '../../../../../../../types/Forms'
import InputTemplatesProvider, {
} from '../../../../../../CompanyInputs/providers/InputTemplatesProvider'
import { QuestionTypesList } from './QuestionTypesList'
import useCreateNewQuestion from '../useCreateNewQuestion'
import FormElementsList from './FormElementsList'

interface IFormQuestionTypesModal {
  section: FormSectionType
}

const FormQuestionTypesModal: React.FC<IFormQuestionTypesModal> = ({ section }) => {
  const createNewQuestion = useCreateNewQuestion(section)

  const [currentTab, setCurrentTab] = useState<'Questions' | 'Form elements'>('Questions')

  const tabs = [
    { title: 'Questions', onClick: () => { setCurrentTab('Questions') }, id: 'Questions' },
    { title: 'Form elements', onClick: () => { setCurrentTab('Form elements') }, id: 'Form elements' },
  ]

  return (
    <div className="flex flex-col w-full h-full">
      <TabsNavigation
        tabs={tabs}
        currentTab={currentTab}
      />

      {currentTab === 'Questions' && (
        <>
          <div className="w-full border-b bg-quaternary-grey" />
          <InputTemplatesProvider>
            <QuestionTypesList onClick={createNewQuestion} />
          </InputTemplatesProvider>
        </>
      )}

      {currentTab === 'Form elements' && (
        <FormElementsList onClick={createNewQuestion} />
      )}
    </div>
  )
}

export default FormQuestionTypesModal
