import { PostOrPutLineItemBodyType } from '../../../../../../types/shared/LineItemDataTypes'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'
import useMutateLineItem from '../../../../hooks/useMutateLineItem'

const useUpdateLineItem = (
  updateAutoSave: () => void,
  sectionIndex: number,
  token?: string,
) => {
  const { mutate: mutateUpdateLineItem } = useMutateLineItem(sectionIndex, token)

  return (
    value: string | boolean | null,
    lineItemId: number | undefined,
    item_attribute_id: number,
    optionId: number,
    optionalAttributes: Partial<Pick<PostOrPutLineItemBodyType, 'netTotalShippingCost' | 'taxRatePercent' | 'selected'>> = {},
  ) => {
    try {
      mutateUpdateLineItem(
        {
          lineItemId,
          optionId,
          item_attribute_id,
          lineItemValue: value || '0',
          ...optionalAttributes,
        },
      )

      updateAutoSave()
    } catch { toastr.error() }
  }
}

export default useUpdateLineItem
