import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { FilterConditionType, InputTemplate } from '../../../types/Forms'
import useGetInputTypes from '../../Forms/hooks/useGetInputTypes'

interface IParentInputSelect {
  handleFilterConditionsUpdate: (updatedConditions: Partial<FilterConditionType>) => void
  selectedParentUuid: string | undefined
  currentInputUuid: string | undefined | null
}
const ParentInputSelect: React.FC<IParentInputSelect> = ({ handleFilterConditionsUpdate, selectedParentUuid, currentInputUuid }) => {
  const { t } = useTranslation()
  const { data: inputs } = useGetInputTypes()

  // Map inputs to SearchSelect options
  let options: InputSearchSelectItemType[] = []
  if (inputs) {
    // Build options from inputs, excluding the current input
    options = inputs.filter((input: InputTemplate) => input.uuid !== currentInputUuid).map((input: InputTemplate) => ({
      id: input.uuid as string,
      label: `${input.name} - ${input.type}`,
    }))
  }
  // Set options in state to avoid uuid changes on rerender
  const [optionsState, setOptionsState] = useState<InputSearchSelectItemType[]>(options)
  useEffect(() => {
    setOptionsState(options)
  }, [inputs])

  const selectedInput = optionsState.find(
    (option) => option.id === selectedParentUuid,
  )

  const onParentInputSelect = (parentUuid: string) => {
    handleFilterConditionsUpdate({ parent_input_uuid: parentUuid })
  }

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">{t('inputs.parent_input', 'Parent Input')}</label>
      <SearchSelect
        onChange={(item) => onParentInputSelect(item.id as string)}
        options={optionsState}
        placeholder={t('inputs.select_parent_input', 'If applicable, select a parent input to filter the data by')}
        currentSelection={selectedInput}
      />
    </div>
  )
}

export default ParentInputSelect
