import useDebouncedQuery from '../../../../../core/hooks/useDebouncedQuery'
import { CATALOG_ITEMS } from '../../NewRequisition/constants/newReqQueryStrings'
import { apiGetCatalogItems } from '../../NewRequisition/services/UnifiedSearchService'

const useGetCatalogItems = (query: string) => useDebouncedQuery(
  [CATALOG_ITEMS, query],
  apiGetCatalogItems,
  { query },
)

export default useGetCatalogItems
