import { FormQuestionType } from '../../../../types/Forms/formTypes'
import { useFormSection } from '../providers/FormSectionProvider'
import { getFirstError } from '../validation/formValidation'

export const useQuestionError = ({ __uuid }: FormQuestionType) => {
  const { errors } = useFormSection()

  return getFirstError(errors?.[__uuid])
}

export default useQuestionError
