import {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react'
import FiltersDataType from '../../../../../types/shared/FilterTypes'
import { INodeChildren } from '../../../../../types/shared/GeneralTypes'

interface IFiltersProvider {
  filters: [FiltersDataType, Dispatch<SetStateAction<FiltersDataType>>],
  query: [string, Dispatch<SetStateAction<string>>],
}

const filtersContext = createContext<IFiltersProvider>({} as IFiltersProvider)

const FiltersProvider: React.FC<INodeChildren> = ({ children }) => {
  const filters = useState<FiltersDataType>({
    dateRange: [],
    category: '',
    supplierName: '',
  })

  const query = useState<string>('')

  // TODO: reeplace with reducer.
  const values = useMemo(() => ({ filters, query }), [filters, query])

  return (
    <filtersContext.Provider value={values}>
      {children}
    </filtersContext.Provider>
  )
}

export default FiltersProvider

export function useFilters() { return useContext(filtersContext) }
