import useAssignUser from '../../../../shared/components/organisms/AssigneesSelector/hooks/useAssignUser'
import { advarioDefaultData, advarioPurchaserGroups } from '../sections/ConfirmationSection/advarioDefaultData'
import useGetFormSubmission from './useGetFormSubmission'
import useGetQuestionAttribute from './useGetQuestionAttribute'

/*
This is a hook to "Soft-commit" a FormSubmission. This is built especially
for Advario where Purchasers can submit a requisition that is then
checked and changed by purchasers.
*/

export default () => {
  const getQuestionAttribute = useGetQuestionAttribute()
  const { data: Form } = useGetFormSubmission()
  const costCenter = getQuestionAttribute('CostCenter') || advarioDefaultData[Form?.submitter?.email as string]?.costCenter
  const responsiblePurchasers = (costCenter && advarioPurchaserGroups[`_${costCenter.substring(0, 3)}` as string]) || []

  const { mutateAsync: assignUser } = useAssignUser({
    modelId: Form?.uuid!,
    origin: 'form_submission',
    model: 'form_submission',
    setLocalAssignments: () => {},
  })

  const softSubmit = async () => {
    await Promise.all(responsiblePurchasers.map((purchaser) => assignUser(purchaser.email)))

    window.location.href = `/forms/${Form?.form.uuid}/submission/${Form?.uuid}/confirmation`
  }

  return { responsiblePurchasers, softSubmit }
}
