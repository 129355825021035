import { useFormField } from '../providers/FormFieldProvider'
import FileField from '../../Forms/components/fields/FileField'
import useIntakeFlowFileInputMutations from '../hooks/useIntakeFlowFileInputMutations'

export const FormFieldMedia = () => {
  const { field } = useFormField()
  const { disabled } = field
  const { remove, enabled } = useIntakeFlowFileInputMutations()

  const uploadDisabled = !enabled || disabled || false
  const canModify = true
  const mediaList = [...(field.media ?? [])]

  return (
    <FileField
      mediaList={mediaList}
      onDelete={({ uuid }) => remove(uuid)}
      disabled={uploadDisabled}
      canModify={canModify}
      canAdd={false}
      errorMessage={null}
    />
  )
}

export default FormFieldMedia
