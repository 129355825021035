import WorkflowProvider from '../providers/WorkflowProvider'
import { WorkflowBuilderHeader } from '../components/WorkflowBuilderHeader'
import WorkflowBuilderChart from '../components/WorkflowBuilderChart/WorkflowBuilderChart'
import useGetWorkflowDraft from '../hooks/useGetWorkflowDraft'
import WorkflowBuilderSidepanel from '../components/WorkflowBuilderSidePanel/WorkflowBuilderSidepanel'

export const WorkflowBuilderPage = () => {
  const { data: workflow } = useGetWorkflowDraft()

  if (!workflow) return null

  return (
    <WorkflowProvider workflow={workflow} readonly={false}>
      <div className="flex flex-row w-full">
        <div className="flex flex-col relative w-full">
          <WorkflowBuilderHeader />
          <div className="flex flex-col gap-6 h-full">
            <div className="-ml-px h-full">
              <WorkflowBuilderChart />
            </div>
          </div>
        </div>
        <WorkflowBuilderSidepanel />
      </div>
    </WorkflowProvider>
  )
}

export default WorkflowBuilderPage
