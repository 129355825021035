import { getMergedDeepOverwritingArrays } from '../../../../../shared/utils/objectHelpers'
import { FormQuestionType, FormSectionType } from '../../../../../types/Forms'
import { cloneQuestion } from './cloneQuestion'

export const cloneSection = (originalSection: FormSectionType, loopNumber: number | undefined = undefined, cloningQuestion = (question: FormQuestionType) => cloneQuestion(question)) => {
  const loopedSectionCount = (loopNumber ?? originalSection.loop.__looped_section_count ?? 0) + 1
  const sourceUuid = originalSection.loop.__looped_parent_uuid || originalSection.__uuid

  return getMergedDeepOverwritingArrays(
    originalSection,
    {
      __uuid: `${sourceUuid}_${loopedSectionCount}`,
      loop: {
        __looped_parent_uuid: sourceUuid,
        __looped_section_count: loopedSectionCount,
      },
      fields: originalSection.fields.map(cloningQuestion),
    },
  )
}

export default cloneSection
