import { useQueryClient } from 'react-query'
import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import { deletePreSelectedSupplierUser } from '../../../services/RequisitionSuppliersService'
import reqCoordinateQueryKeys from '../constants/reqCoordinateQueryKeys'
import { useCoordinateProvider } from '../providers/CoordinateProvider'

export default () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()

  const {
    selectedInvites: [, setSelectedInvites],
    loadingInvites: [, setLoadingInvites],
  } = useCoordinateProvider()

  return async (supplierInviteId: number) => {
    setLoadingInvites((loadingInvites) => [...loadingInvites, supplierInviteId])
    await deletePreSelectedSupplierUser(requisitionId, supplierInviteId)
    cache.setQueryData<RequisitionInviteType[] | undefined>(
      [reqCoordinateQueryKeys.REQUISITION_INVITES, requisitionId],
      (oldData: RequisitionInviteType[] | undefined) => (oldData
        ? oldData.filter((data) => data.id !== supplierInviteId)
        : oldData),
    )
    setSelectedInvites((oldSelectedInvites) => oldSelectedInvites.filter(
      (invite) => invite !== supplierInviteId,
    ))
    setLoadingInvites((loadingInvites) => loadingInvites.filter(
      (invite) => invite !== supplierInviteId,
    ))
  }
}
