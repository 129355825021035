import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { apiResetPassword } from '../services/loginService'

const useResetPassword = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return useMutation(apiResetPassword, {
    onSuccess: (res) => {
      switch (res) {
        case 'passwords.reset': {
          toastr.success(t(res), 'password-reset-success')
          history.push('/login')
          break
        }
        default: { toastr.error(t(res), 'password-reset-failed') }
      }
    },
  })
}
export default useResetPassword
