import { useUtils } from '../../../../../core/providers/UtilsProvider'
import { RequisitionDataType } from '../../../../../types/shared/RequisitionDataTypes'
import SupplierOfferContainer from '../../../../SupplierOffer/SupplierOffer'
import RequisitionPreviewHeader from '../components/PastRequisitions/RequisitionPreviewHeader'

export default (preview: boolean = false) => {
  const { modal } = useUtils()

  return (requisition?: RequisitionDataType) => {
    if (requisition) {
      modal.set({
        isOpen: true,
        title: <RequisitionPreviewHeader requisitionData={requisition} preview={preview} />,
        content: <SupplierOfferContainer requisitionId={requisition.id} />,
      })
    }
  }
}
