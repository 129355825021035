import RowCell from '../../../../../shared/components/layout/RowCell'
import SupplierSearchType from '../../../../../types/Suppliers/SupplierSearchType'
import BranchName from '../../BranchName'
import SuppliersTableTag from '../../../../../shared/components/organisms/SupplierInfo/SupplierTags'

interface IBranchInfoCell {
  data: SupplierSearchType
}

const BranchInfoCell: React.FC<IBranchInfoCell> = ({ data }) => (
  <RowCell width="w-6/12" className="pt-0 pb-0">
    <div className="flex flex-col gap-y-3">
      <BranchName
        supplierBranch={data}
        className="truncate text-sm"
        disabledStar
      />
      <SuppliersTableTag
        tags={data?.tags}
        supplierBranchId={data.id}
      />
    </div>
  </RowCell>
)

export default BranchInfoCell
