import { Position } from 'reactflow'
import WorkflowChartNodeHandle from './components/WorkflowChartNodeHandle'
import { WorkflowChartGroupNodeProps } from '../../../../types/Workflow/WorkflowChart'
import { WorkflowChartBlockNodeRenderer } from './components/WorkflowChartBlockNodeRenderer'
import { NODE_HEIGHT } from '../../helpers'

type WorkflowChartNodesGroupProps = React.PropsWithChildren<WorkflowChartGroupNodeProps> & {
  footer?: JSX.Element
  header?: JSX.Element
}

export const WorkflowChartNodesGroup = ({
  id, data, children, header, footer, ...props
}: WorkflowChartNodesGroupProps) => (
  <div style={{ height: (data.blocks.length + 0.5) * NODE_HEIGHT }} className="flex flex-col overflow-y-visible justify-center">
    <div
      className="h-fit w-full"
      style={{
        backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(136, 136, 136)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
      }}
    >
      <div className="p-4 flex flex-col">
        {header && (
          <div>
            {header}
          </div>
        )}

        <div className="flex flex-col gap-6 m-6">
          {children || (
            data.blocks.map(
              (block) => (
                <WorkflowChartBlockNodeRenderer
                  key={`block-node-${block.uuid}`}
                  id={block.uuid}
                  data={{ ...block, position: data.position, stepUuid: data.uuid }}
                  {...props}
                />
              ),
            )
          )}
        </div>

        {footer && (
          <div>
            {footer}
          </div>
        )}
      </div>
      <WorkflowChartNodeHandle id={id} type="target" />
      <WorkflowChartNodeHandle id={id} type="source" />
      <WorkflowChartNodeHandle id={id} type="source" position={Position.Bottom} alias="helper" />
    </div>
  </div>
)

export default WorkflowChartNodesGroup
