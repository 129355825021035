import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionType } from '../../../../types/Forms'
import useQuestionValues from '../hooks/useQuestionValues'
import useQuestionErrorMessage from '../hooks/useQuestionErrorMessage'
import { FormEvents } from '../Questions'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'
import useSearchSelectOptions from '../../../../shared/hooks/useSearchSelectOptions'
import useSynchronizedFormUpdate from '../hooks/useSynchronizedFormUpdate'

interface ISearchSelectQuestion {
  question: FormQuestionType
}

const SearchSelectQuestion: React.FC<ISearchSelectQuestion> = ({
  question,
}) => {
  const { t } = useTranslation()

  const [, setQuestionValues] = useQuestionValues(question)
  const setQuestionValuesAndUpdateForm = useSynchronizedFormUpdate(setQuestionValues)
  const isDisabled = useIsQuestionDisabled(question)

  const error = useQuestionErrorMessage(question)
  const { options: questionOptions } = question
  const {
    options, selected, selectedToOption, visibleColumns, isLoading,
  } = useSearchSelectOptions(question)

  const onChange = (searchSelectType?: InputSearchSelectItemType | null) => {
    if (!searchSelectType) return

    const originalOption = questionOptions?.find(({ __uuid }) => __uuid === searchSelectType?.id)
    const newValue = [{ ...selectedToOption(searchSelectType), label: originalOption?.label || question.question_label }]
    setQuestionValuesAndUpdateForm(newValue)
    FormEvents.QuestionAnswered.dispatch(question.__uuid)
  }

  // If the ID of the selected option changes, update the form. Checking for ID prevents an infinite loop
  useEffect(() => {
    if (selected?.id) {
      onChange(selected)
    } else {
      // reset the question value if the selected option is null
      setQuestionValuesAndUpdateForm([])
    }
  }, [selected?.id])

  if (isLoading) return null

  return (
    <div className="flex gap-x-2 relative">
      <div className="w-full">
        <SearchSelect
          disabled={isDisabled}
          onChange={onChange}
          options={options}
          currentSelection={selected}
          placeholder={t('question.search_option')}
          error={error}
          visibleColumns={visibleColumns}
        />
      </div>
    </div>
  )
}

export default SearchSelectQuestion
