import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { errorMessages } from '../../../../../shared/utils/validation/validationErrorMessages'

export default () => {
  const { t } = useTranslation()
  return Yup.object().shape({
    city: Yup.string().trim().required(t(errorMessages.required)),
    country_id: Yup.number().required(t(errorMessages.required)),
    street: Yup.string().trim().required(t(errorMessages.required)),
    zip: Yup.string()
      .required(t(errorMessages.required))
      .typeError(t(errorMessages.type_number)),
    name: Yup.string().trim().required(t(errorMessages.required)),
  })
}
