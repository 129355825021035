import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import useSendSupplierInviteMutation from '../../../hooks/useSendSupplierInviteMutation'
import CoordinateCustomInviteModal from '../components/ReqCoordinate/SupplierRow/CustomInviteModal'
import useSendSupplierInvites from '../components/ReqCoordinate/SupplierRow/LeftButton/SelectSupplierCheckbox/hooks/useSendSupplierInvites'
import { useCoordinateProvider } from '../providers/CoordinateProvider'
import { createAndSendSupplierInvite } from '../utils/utils'

type OpenCustomInviteModalArgumentsType = {
  supplierName: string,
  supplierUserId: number,
  supplierInviteId?: number,
}

const useOpenCustomInviteModal = (
  { supplierName, supplierUserId, supplierInviteId }: OpenCustomInviteModalArgumentsType,
) => {
  const {
    loadingInvites: [, setLoadingInvites],
  } = useCoordinateProvider()

  const { mutate: postInvites } = useSendSupplierInviteMutation()

  const requisitionId = useGetRequisitionId()
  const sendSupplierInvite = useSendSupplierInvites()
  const utils = useUtils()
  const cache = useQueryClient()
  const { t } = useTranslation()
  return () => utils.modal.set({
    isOpen: true,
    title: t('req_coordinate.custom_invite'),
    content: (
      <CoordinateCustomInviteModal
        handleSendInvite={(reason?: string) => {
          if (supplierInviteId) {
            sendSupplierInvite(supplierInviteId, reason || '')
          } else {
            // TODO turn into hook
            createAndSendSupplierInvite(postInvites, cache, setLoadingInvites, requisitionId, supplierUserId, reason || '')
          }
        }}
        supplierName={supplierName}
      />),
  })
}

export default useOpenCustomInviteModal
