import { useQuery } from 'react-query'
import {
  getUniteCatalogItem,
} from '../../Requisition/subModules/NewRequisition/services/UnifiedSearchService'

export default function useUniteDetails(itemId?: string) {
  const item = useQuery(
    ['catalogItem', itemId],
    () => getUniteCatalogItem(itemId || ''),
    { enabled: !!itemId },
  )

  return { item }
}
