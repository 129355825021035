import { forwardRef, useEffect, useLayoutEffect } from 'react'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'

interface IPopoverModal extends INodeChildren {
  close: () => void
  popoverContentClassName: string | undefined
  closeOnClick: boolean | undefined
  scrollableElement: HTMLElement | undefined
  updateStyles: any
}

// eslint-disable-next-line react/display-name
const PopoverModal = forwardRef<HTMLDivElement, IPopoverModal>((
  {
    close, popoverContentClassName, closeOnClick, children, scrollableElement, updateStyles,
  },
  ref,
) => {
  useEffect(() => {
    // On scroll of page close popover
    window.addEventListener('scroll', close)
    return () => window.removeEventListener('scroll', close)
  }, [scrollableElement])

  useLayoutEffect(() => {
    updateStyles()
  }, [])

  return (
    <div
      className="rounded-md drop-shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden w-fit"
      ref={ref}
    >
      <div
        role="button"
        aria-hidden
        className={`relative bg-white z-50 ${popoverContentClassName}`}
        onClick={() => (closeOnClick ? close() : {})}
        data-cy="popover-component-menu"
      >
        {children}
      </div>
    </div>
  )
})

export default PopoverModal
