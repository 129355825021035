import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import TypeTag from './TypeTag'

interface IIsDeclinedTag {
  invite: RequisitionInviteType
}

const IsDeclinedTag: React.FC<IIsDeclinedTag> = ({
  invite,
}) => {
  const { t } = useTranslation()
  const isDeclined = invite?.status === 'OFFER_DECLINED_BY_SUPPLIER'

  return isDeclined
    ? (
      <TypeTag
        lhotseSupplier={false}
        title={t('requisition_offers.resubmitted')}
        waiting
        rejected
      />
    )
    : null
}

export default IsDeclinedTag
