import { LineItemItemAttributeType } from '../../../../../../../../../../types/shared/LineItemDataTypes'

const getRowButtonsInfo = (itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>, firstRow: boolean) => {
  const displayButtons = {
    isSum: itemAttribute.type === 'price',
    isRequired: (itemAttribute.type !== 'comment'),
    addComment: itemAttribute.type !== 'comment' && itemAttribute.type !== 'section',
  }

  const defaultButtonStates = {
    isSum: itemAttribute.type === 'price',
    isRequired: !!(itemAttribute.type === 'price' && firstRow),
    addComment: typeof itemAttribute.comment === 'string',
  }

  const buttonStates = {
    isSum: (itemAttribute?.is_sum !== null)
      ? itemAttribute.is_sum
      : defaultButtonStates.isSum,
    isRequired: itemAttribute?.is_required !== 0
      ? itemAttribute.is_required
      : defaultButtonStates.isRequired,
    addComment: typeof itemAttribute?.comment?.comment === 'string',
  }

  return { defaultButtonStates, buttonStates, displayButtons }
}

export default getRowButtonsInfo
