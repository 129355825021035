import { useTranslation } from 'react-i18next'
import useGetSupplierTags from '../../../../modules/SupplierDetails/hooks/useGetSupplierTags'
import { TagType } from '../../../../types/shared/GeneralTypes'
import useRemoveTag from './hooks/useRemoveTag'
import NewTagOptions from './NewTagOptions'
import TagsGroup from './TagsGroup'
import { TagCategoryType } from './type'

export interface ITagTypeCategory {
  category: TagCategoryType
  className?: string
  editable: boolean | undefined
}

const TagCategory: React.FC<ITagTypeCategory> = ({ category, className, editable }) => {
  const { t } = useTranslation()

  const { data: tagsGroup } = useGetSupplierTags(category)

  const { mutate: removeTag } = useRemoveTag(category)

  return (
    <div className={`flex w-full overflow-clip flex-col gap-y-4 ${className}`}>
      <p className="flex flex-col gap-y-1 font-semibold">
        {t(`suppliers.tag_${category}`)}
      </p>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-2 justify-between h-full w-full">
          <TagsGroup
            tags={tagsGroup}
            removeTag={(tag: TagType) => removeTag(tag)}
            editable={editable}
          />
        </div>
        {editable && (
          <div className="w-full flex justify-start py-2 pb-[40px]">
            <NewTagOptions category={category} />
          </div>
        )}
      </div>
    </div>
  )
}

export default TagCategory
