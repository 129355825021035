import { ChangeEventHandler, HTMLAttributes, InputHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import useUpdateSubmission from '../../hooks/useUpdateSubmission'
import CharacterLimit from './CharacterLimit'
import './FormInput.css'
import useGetFormSubmission from '../../hooks/useGetFormSubmission'
import InputError from './InputError'
import { inputErrorClassNames } from './helpers'

type IFormInput = {
  shortResponse: boolean
  value: string
  onChange: (string: string) => void
  onComplete?: (input: (EventTarget & HTMLInputElement) | (EventTarget & HTMLTextAreaElement)) => void
  characterLimit?: number
  error?: string
  invalid?: boolean
  wrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
} & Omit<InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, 'onChange' | 'onBlur' | 'type'>

const FormInput: React.FC<IFormInput> = ({
  shortResponse, value, onChange, onComplete, characterLimit, error, invalid = false, className, wrapperClassName, disabled, ...inputProps
}) => {
  const { t } = useTranslation()

  const updateForm = useUpdateSubmission()
  const { data: submissionFormData } = useGetFormSubmission()

  const characterLimitExceeded = characterLimit && value?.length > characterLimit

  const isInvalid = characterLimitExceeded || !!error || invalid
  const sharedInputClasses = classnames(
    isInvalid && inputErrorClassNames,
    !isInvalid && 'active:border-sky-blue-light',
    'base-form-input',
    (submissionFormData?.submitted || disabled) && 'bg-transparent',
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onComplete && event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      event.stopPropagation()
      onComplete(event.currentTarget)
    }
  }

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    onChange(e.currentTarget.value)
  }

  return (
    <div className={classnames('relative', wrapperClassName)}>
      <CharacterLimit characterLimit={characterLimit} value={value} />
      {shortResponse
        ? (
          // TODO: Use Base Input
          <input
            disabled={submissionFormData?.submitted || disabled}
            type="text"
            className={sharedInputClasses}
            placeholder={t('generic.type_your_response')}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={value}
            onBlur={() => updateForm()}
            {...inputProps}
          />
        )
        : (
          <textarea
            disabled={submissionFormData?.submitted || disabled}
            className={`${classnames('textarea', sharedInputClasses)} overflow-y-auto px-3 py-1.5 resize-y`}
            onChange={handleChange}
            rows={3}
            onKeyDown={handleKeyDown}
            placeholder={t('generic.type_your_response')}
            value={value}
            onBlur={() => updateForm()}
            {...inputProps}
          />
        )}
      <InputError error={error} />
    </div>
  )
}

export default FormInput
