import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import Button from '../../../../shared/components/atoms/buttons/Button'
import InputTextGray from '../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import { dateTimeFormatter } from '../../../../shared/constants/dateFormat'
import { undef } from '../../../../types/shared/GeneralTypes'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import NDAFormLocationSearch from './NDAFormLocationSearch'
import { declineNDA, handleAcceptNDA, signNDA } from './NDAFormUtils'

export type NDASignatureStateType = {
  signed: boolean,
  name: string,
  location: string
}

const NDAFormInteractiveElements: React.FC<{ activeNDA: undef<CustomerDocumentType> }> = (
  { activeNDA },
) => {
  const { t } = useTranslation()
  const { token } = useParams<{ token: string }>()
  const cache = useQueryClient()

  const [NDASignatureState, setNDASignatureState] = useState<NDASignatureStateType>({
    signed: false,
    name: '',
    location: '',
  })

  const [acceptingNDA, setAcceptingNDA] = useState(false)

  return (
    <>
      <div className="flex px-8 py-2 gap-x-4 justify-between w-full">
        <div className="w-5/12">
          <InputTextGray
            required
            placeholder={t('generic.full_name')}
            className="h-max w-full"
            value={NDASignatureState?.name}
            onChange={(e) => setNDASignatureState({ ...NDASignatureState, name: e.target.value })}
          />
        </div>
        <div className="flex items-center justify-center w-1/6">
          <p className="whitespace-nowrap">{dateTimeFormatter(new Date())}</p>
        </div>
        <div className="w-5/12">
          <NDAFormLocationSearch
            location={NDASignatureState.location}
            setLocation={(location: string) => setNDASignatureState(
              { ...NDASignatureState, location },
            )}
          />
        </div>
      </div>
      <div className="flex justify-between px-8 py-4 items-center gap-x-8">
        <button
          className="h-28 border border-gray-200 rounded-md w-full flex items-center justify-center cursor-pointer"
          onClick={() => signNDA(NDASignatureState, setNDASignatureState)}
          type="button"
        >
          {NDASignatureState.signed
            ? <p className="signature text-4xl">{NDASignatureState.name}</p>
            : <p>{t('nda_screen.click_to_sign')}</p>}
        </button>
        <div className="flex gap-x-4 h-12">
          <Button variant="outlined" color="lhotse-gray" onClick={declineNDA}>{t('generic.decline')}</Button>
          <Button variant="solid" color="lhotse-orange" onClick={() => handleAcceptNDA(token, activeNDA, cache, NDASignatureState, setAcceptingNDA)}>
            {acceptingNDA && <CoreLoadingIcon />}
            {t('generic.accept')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default NDAFormInteractiveElements
