import { useCallback } from 'react'
import useGetFormData from './useGetFormData'
import { FormSectionCategoryType } from '../../../types/Forms'
import useGetFormBuilder from '../FormBuilder/hooks/useGetFormBuilder'

export default () => {
  const { data } = useGetFormData()
  const { data: formBuilderData } = useGetFormBuilder()

  const byType = useCallback(
    (type: FormSectionCategoryType) => data?.template.sections.filter(
      (section) => section.type === type,
    ) || [],
    [data],
  )

  const getTemplate = useCallback((type: FormSectionCategoryType) => formBuilderData?.section_types
    ?.find((builderSectionType) => builderSectionType.type === type), [formBuilderData])

  return {
    byType,
    getTemplate,
    canAdd: useCallback(
      (type: FormSectionCategoryType) => {
        const { max_sections } = getTemplate(type) || {}
        return max_sections === null || byType(type).length < (max_sections || 0)
      },
      [byType, getTemplate],
    ),
  }
}
