import Tooltip from '../../../../../shared/components/organisms/tooltip/Tooltip'
import { calculateTimeAgo } from '../../../../../shared/constants/dateFormat'
import { RequisitionSearchDataCatalogType, RequisitionSearchDataDataType } from '../../../../../types/Requisition/RequisitionSearchResultsDataTypes'
import NewRequisitionTemplateSelector from './NewRequisitionTemplateTypeSelector'

interface INewRequisitionTemplateItem {
  item: RequisitionSearchDataCatalogType | RequisitionSearchDataDataType,
  onClick?: () => void
}
const NewRequisitionTemplateItem: React.FC<INewRequisitionTemplateItem> = ({ item, onClick }) => {
  const show = item.name.length > 60

  return (
    <Tooltip tooltip={item.name} showCondition={show}>
      <button type="button" onClick={onClick} className="border rounded-lg w-full px-5 bg-white py-3 hover:bg-blue-50 hover:bg-opacity-50">
        <div className="flex justify-between">
          <div className="flex items-start flex-col gap-y-2">
            <div className="font-regular text-left text-sm line-clamp-1">
              {item.name}
            </div>
            <NewRequisitionTemplateSelector type={parseInt(item.type || '1', 10)} />
          </div>
          <div className="flex flex-col gap-y-2 items-end justify-center">
            {/* TODO: add assigneees - There's a type error item returns assignees.user */}
            {/* <Assignees assignments={item.assignees || []} readOnly /> */}
            <div className="text-xs text-gray-400">
              <p className="whitespace-nowrap">
                {item.created_at && calculateTimeAgo(item.created_at)}
              </p>
            </div>
          </div>
        </div>
      </button>
    </Tooltip>
  )
}

export default NewRequisitionTemplateItem
