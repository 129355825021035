import { useQuery } from 'react-query'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import { getRequisitionDocuments } from '../services/documentsService'
import keys from '../components/ReqDefContainer/DocumentsSection/ReqDefDocumentsQueryKeys'

const useGetRequisitionDocuments = () => {
  const requisitionId = useGetRequisitionId()
  return useQuery(
    [keys.REQUISITION_DOCUMENTS, requisitionId],
    () => getRequisitionDocuments(requisitionId),
  )
}

export default useGetRequisitionDocuments
