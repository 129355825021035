import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'
import Tooltip from '../../../../../../../../../../shared/components/organisms/tooltip/Tooltip'
import { CATALOG_ITEM } from '../../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../../services/UnifiedSearchService'

const CatalogBuyReturnPolicy: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { t } = useTranslation()
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  if (!data) return <CoreLoadingIcon />

  if (data && !data.returnPolicy?.additionalText) return null

  const tooltipData = data.returnPolicy.additionalText
  return (
    <Tooltip
      tooltip={tooltipData}
      className="cursor-default text-blue-700"
    >
      {t('catalog.return_policy')}

    </Tooltip>
  )
}

export default CatalogBuyReturnPolicy
