import classnames from 'classnames'

export const getUnderlineInputClasses = (error: boolean, className?: string) => classnames(`border-0 w-full
border-b-2 border-gray-200
focus:bg-white
text-gray-900 text-sm
placeholder-gray-300
pb-1 px-0 focus:ring-0
disabled:border-b disabled:border-dashed disabled:bg-white
disabled:opacity-60 disabled:border-gray-400
relative
mb-6`, { 'border-red-600': error }, className)

export default getUnderlineInputClasses
