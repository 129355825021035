import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import LabelSettings from './LabelSettings'
import UserSettings from './UserSettings'
import QuestionSubSection from '../../components/QuestionSubSection'
import OptionSettings from './OptionSettings'
import MiscSettings from './MiscSettings'
import IntakeOverviewSettings from './IntakeOverviewSettings'

const OtherSettings: React.FC = () => {
  const { t } = useTranslation()
  const { question: [question] } = useFormContext()

  if (!question?.type) return null

  return (
    <>
      <QuestionSubSection title={t('forms.settings.user_section')}>
        <UserSettings />
      </QuestionSubSection>
      <LabelSettings />
      <OptionSettings />
      <MiscSettings />
      <IntakeOverviewSettings />
    </>
  )
}

export default OtherSettings
