import React from 'react'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import useApprovals from '../../hooks/useApprovals'
import ApprovalFlowTimelineStep from './ApprovalFlowTimelineStep'

const ApprovalFlowTimeline: React.FC = () => {
  const { approvalData, isLoadingApprovalData } = useApprovals()

  if (isLoadingApprovalData) return <CoreLoadingIcon />
  return (
    <div className="relative ">
      <div className="text-center italic text-sm mt-4">
        {approvalData?.status === 'NO_APPROVAL_REQUIRED' && (
          <div>
            <div>No Approval required</div>
          </div>
        )}

      </div>
      <ul className="-mb-8 ">
        {approvalData?.steps?.filter((s) => s.approval_status !== 'NOT_REQUIRED').map((approval, approvalIdx, array) => (
          <ApprovalFlowTimelineStep
            index={approvalIdx}
            key={approval.uuid}
            approval={approval}
            last={approvalIdx === array.length - 1}
          />
        ))}

      </ul>
    </div>

  )
}

export default ApprovalFlowTimeline
