import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import InputWithSaveCancelButtons from '../../../shared/components/organisms/InputWithSaveCancelButtons/InputWithSaveCancelButtons'
import useGetSupplierBranch from '../hooks/useGetSupplierBranch'
import useUpdateBranchMutation from '../hooks/useUpdateBranchMutation'

interface ISupplierBranchName {
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
}

const SupplierBranchName: React.FC<ISupplierBranchName> = ({ editMode, setEditMode }) => {
  const { data: supplierBranchData } = useGetSupplierBranch()
  const [title, setTitle] = useState(supplierBranchData?.name || '')

  const { mutate: updateBranchMutation } = useUpdateBranchMutation(
    supplierBranchData?.id,
    () => setEditMode(false),
  )

  useEffect(() => { setTitle(supplierBranchData?.name || '') }, [supplierBranchData])

  return !editMode || !supplierBranchData
    ? <p className="text-[24px]">{supplierBranchData?.name}</p>
    : (
      <InputWithSaveCancelButtons
        title={title}
        setTitle={setTitle}
        onCancel={() => setEditMode(false)}
        onSave={() => {
          updateBranchMutation({
            body: { name: title },
            supplierId: supplierBranchData?.supplier_id,
          })
        }}
        placeholder="Type the name of the branch"
        className="w-full"
      />

    )
}

export default SupplierBranchName
