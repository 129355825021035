import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import useGetReqSections from '../../Coordinate/hooks/useGetReqSections'
import getGeneralInfoLineItems from './getGeneralInfoLineItems'
import useGetInvitesWithOffers from './useGetInvitesWithOffers'

export default (): RequisitionInviteType[] => {
  const { data: allInvites } = useGetInvitesWithOffers()
  const { data: sectionsData } = useGetReqSections()

  const finalOfferItemId = sectionsData?.find((section) => section.section_type === 'FINAL_OFFER')?.item_attributes[0].id

  return allInvites?.map((invite) => {
    const finalOfferFilesItem = invite.offer?.line_items?.find(
      (lineItem) => lineItem.item_attribute_id === finalOfferItemId,
    )

    return {
      ...invite,
      offer: {
        ...invite.offer,
        line_items: [
          ...invite?.offer?.line_items || [],
          ...getGeneralInfoLineItems(invite, finalOfferFilesItem),
        ],
      },
      calculated_price: {
        ...invite?.calculated_price,
        product_prices: Array.isArray(invite?.calculated_price?.product_prices)
          ? invite?.calculated_price?.product_prices
          : Object.values(invite?.calculated_price?.product_prices),
      },
    }
  }) || []
}
