import CoreContainerLayout from '../../../../../../../core/components/layout/CoreContainerLayout'
import useGetRequisitionData from '../../../hooks/useGetRequisition'
import AttachmentsTable from './AttachmentsTable'
import AttachmentUpload from './AttachmentUpload'

interface IAttachmentsSectionContent {
    expanded: boolean
    updateAutoSave: () => void
}

const AttachmentsSectionContent: React.FC<IAttachmentsSectionContent> = ({
  expanded, updateAutoSave,
}) => {
  const { data: requisition } = useGetRequisitionData()

  if (!expanded || !requisition) return null

  return (
    <CoreContainerLayout className="w-full flex flex-col p-7 gap-4 rounded-t-none border-t-0">
      <AttachmentsTable />
      <AttachmentUpload />
    </CoreContainerLayout>
  )
}

export default AttachmentsSectionContent
