import { MdAutorenew } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

interface ISharedAutosave {
  autoSave: boolean
}

const SharedAutoSave: React.FC<ISharedAutosave> = ({ autoSave }) => {
  const { t } = useTranslation()
  return (!autoSave ? null : (
    <div className="flex items-center gap-x-1">
      <MdAutorenew className="text-green-600" />
      <p className="text-green-600 text-sm">{t('generic.automatically_saved')}</p>
    </div>
  ))
}

export default SharedAutoSave
