import PageTopbar from '../../../../shared/components/layout/PageTopBar'
import FormBuilderTopbar from '../QuestionPage/FormBuilderTopbar/FormBuilderTopbar'
import EditFormActions from './SaveQuestionButton'
import FormNavigation from './FormNavigation'

const FormOverviewHeader = () => (
  <div
    id="expanded-header"
    className="bg-white green"
  >
    <FormNavigation />
    <PageTopbar
      titleComponent={<FormBuilderTopbar disabled={false} />}
      actions={<EditFormActions />}
    />
  </div>
)

export default FormOverviewHeader
