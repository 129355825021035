import { useEffect } from 'react'
import { useParams } from 'react-router'
import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'
import { useFormContext } from '../../../core/providers/FormProvider'
import FormNotSupported from '../components/FormNotSupported'
import { isSupported } from '../constants/FormVersion'
import useGetFormData from '../hooks/useGetFormData'
import ConfirmationSectionAdvario from './sections/ConfirmationSection/ConfirmationSectionAdvario'
import Header from './Header/Header'
import useCheckForSubmission from './hooks/useCheckForSubmission'
import useGetFormSubmission from './hooks/useGetFormSubmission'
import useLoadState from './hooks/useLoadState'
import FormDataProvider from './providers/FormDataProvider'
import FormLogicProvider from './providers/FormLogicProvider'
import FormSectionProvider from './providers/FormSectionProvider'
import QuestionNavigator from './QuestionNavigator/QuestionNavigator'

const FormResponseConfirmationPage: React.FC = () => {
  const { isLoading: isLoadingSubmissionCheck } = useCheckForSubmission()
  const { isLoading: isLoadingFormData, data: form } = useGetFormData()
  const { isLoading: isLoadingSubmission } = useGetFormSubmission()
  const { responseConfig: [responseConfig, setResponseConfig], submission: [submission] } = useFormContext()
  const { page } = useParams<{ page: string }>()

  useLoadState()

  const isLoading = isLoadingSubmission || isLoadingSubmissionCheck || isLoadingFormData

  useEffect(() => {
    setResponseConfig({ ...responseConfig, activeSectionUUID: 'Confirmation' })
  }, [page])

  if (isLoading) {
    return (
      <div className="w-full h-full bg-white">
        <CoreLoadingIcon />
      </div>
    )
  }

  return (
    <FormDataProvider form={submission}>
      <FormLogicProvider>
        <div className="flex flex-col w-full bg-[#FAFAFA]">
          <Header />
          <div className="w-full max-w-screen font-inter justify-center relative">
            <QuestionNavigator />
            {isSupported(form)
              ? (
                <div className="flex flex-col justify-center" id="form-container">
                  <FormSectionProvider>
                    <div className="max-w-[1300px] mx-auto m-8">
                      <ConfirmationSectionAdvario />
                    </div>
                  </FormSectionProvider>
                </div>
              ) : <FormNotSupported />}
          </div>

        </div>

      </FormLogicProvider>
    </FormDataProvider>

  )
}

export default FormResponseConfirmationPage
