import {
  apiDelete, apiGetData, getFile,
} from '../../../../../core/utils/api/generic'
import apiClient from '../../../../../core/utils/apiClient'
import { getApiUrl } from '../../../../../core/utils/tenancy'
import { MediaType } from '../../../../../types/shared/MediaTypes'

export const mediaService = (model: 'requisition' | 'item_section' | 'submission' | 'offering' | 'offering_offer'| 'intake_flow_input', id: string) => {
  const download = (attachmentId: string): Promise<string> => getFile(`${getApiUrl()}/media/${model}/${id}/download/${attachmentId}`)
  return {
    list: () => apiGetData<MediaType[]>(`/media/${model}/${id}`),
    download,
    saveFile: async (attachmentId: string, name: string): Promise<void> => {
      const link = document.createElement('a')

      link.href = await download(attachmentId)
      link.setAttribute('download', name)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    remove: (attachmentId: string): Promise<{success: boolean}> => apiDelete(`${getApiUrl()}/media/${model}/${id}/delete/${attachmentId}`),
    upload: (files: File[]): Promise<MediaType[]> => {
      const formData = new FormData()
      formData.append('model', model)
      formData.append('model_id', id)
      files.forEach((file) => formData.append('files[]', file))

      const url = `${getApiUrl()}/media/${model}/${id}/upload`

      return apiClient.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(({ data: { data } }) => data)
    },
  }
}

export const offerMediaService = (id: string) => mediaService('offering_offer', id)

export const offeringMediaService = (id: string) => mediaService('offering', id)

export const submissionMediaService = (id: string) => mediaService('submission', id)

export const requisitionMediaService = (id: string) => mediaService('requisition', id)

export const itemSectionMediaService = (id: string) => mediaService('item_section', id)

export const intakeFlowMediaService = (id: string) => mediaService('intake_flow_input', id)

export const getRequisitionAttachments = (requisitionUuid: string): Promise<MediaType[]> => requisitionMediaService(requisitionUuid).list()

export const downloadRequisitionAttachment = (requisitionUuid: string, attachmentUuid: string): Promise<string> => requisitionMediaService(requisitionUuid).download(attachmentUuid)

export const removeRequisitionAttachment = (requisitionUuid: string, attachmentUuid: string): Promise<{success: boolean}> => requisitionMediaService(requisitionUuid).remove(attachmentUuid)

export const uploadRequisitionAttachments = (requisitionUuid: string, files: File[]): Promise<MediaType[]> => requisitionMediaService(requisitionUuid).upload(files)

export const removeItemSectionAttachment = (sectionUuid: string, attachmentUuid: string): any => mediaService('item_section', sectionUuid).remove(attachmentUuid)
