import { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import TableCell from '../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'
import { FullOffering } from '../../../../../Offerings/types'

interface IIntakeOfferingSectionMainTableRow {
  offering: FullOffering
  children?: React.ReactNode
  actions?: React.ReactNode
  defaultOpen?: boolean
}
const IntakeOfferingSectionMainTableRow: React.FC<IIntakeOfferingSectionMainTableRow> = ({
  offering, defaultOpen = false, children, actions,
}) => {
  const [childVisible, setChildVisible] = useState<boolean>(defaultOpen)
  const selectedOffer = offering.offers?.find((o) => o.is_selected)
  const productName = offering?.fields?.find((f) => f.input?.key === 'prod-name')?.value?.value as string || '-'
  const producQuantity = offering?.fields?.find((f) => f.input?.key === 'prod-quantity')?.value?.value as string
  const costCenter = offering?.fields?.find((f) => f.input?.key === 'prod-cost_center')?.value?.value as string || offering?.fields?.find((f) => f.input?.key === 'saps4-costcenter')?.value?.value as string || '-'
  return (
    <>
      <TableRow key={offering.uuid} onClick={() => setChildVisible(!childVisible)} active={childVisible} className="sticky top-12">
        <TableCell>
          <div className="flex">
            <div className="flex flex-col">
              <span className="font-semibold whitespace-nowrap">
                {selectedOffer?.quantity || producQuantity || 1}
                {' '}
                x
              </span>
              <span className="text-xs leading-6">&nbsp;</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className="flex">
            <div className="flex flex-col">
              <span className="font-semibold">
                {productName}
              </span>
              <span className="text-xs leading-6">{costCenter}</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className="flex-col ">
            <div>{selectedOffer?.supplier || '-'}</div>
            <div className="flex-col  text-xs leading-6">{selectedOffer?.total_net_price || 0}</div>
          </div>
        </TableCell>
        <TableCell className="text-right">
          <div className="flex justify-end gap-x-2">
            {actions}
            {children && childVisible ? <ChevronDownIcon className="w-5" /> : <ChevronUpIcon className="w-5" />}
          </div>
        </TableCell>
      </TableRow>

      {childVisible && (
        <TableRow>
          <TableCell className="border-t-2 border-sky-blue-light border-collapse py-8" colSpan={4}>
            {children}
          </TableCell>
        </TableRow>
      )}

    </>
  )
}

export default IntakeOfferingSectionMainTableRow
