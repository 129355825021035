import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCloseModal from '../../../../../../../core/hooks/useCloseModal'
import Calendar from '../../../../../../../shared/components/organisms/Calendar/Calendar'
import SaveCancelFormButtons from '../../../../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import { lastCallStateType } from '../../../../../components/layout/ReqTopbar/RequisitionTopbarLastCallConfirmation'
import useConfirmLastCall from './hooks/useConfirmLastCall'

interface ILastCallConfirmationModal {
  lastCallState: lastCallStateType
}

const LastCallConfirmationModal: React.FC<ILastCallConfirmationModal> = ({
  lastCallState,
}) => {
  const { t } = useTranslation()
  const handleCancel = useCloseModal()

  const [deadline, setDeadline] = useState<Date>(dayjs().add(7, 'days').toDate())

  const handleConfirm = useConfirmLastCall(deadline, lastCallState)

  return (
    <div className="flex flex-col gap-y-3">
      <p>{t('negotiation_modal.about_to_invite')}</p>
      <div className="flex gap-x-3 items-center">
        <p>Please choose a new deadline for final offers.</p>
      </div>
      <Calendar
        onChange={(date) => setDeadline(date)}
        selected={deadline}
        minDate={dayjs().add(1, 'day').toDate()}
        shouldFilterTime
      />
      <SaveCancelFormButtons onSave={handleConfirm} onCancel={handleCancel} />
    </div>
  )
}

export default LastCallConfirmationModal
