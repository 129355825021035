import { Editor, EditorContent } from '@tiptap/react'
import { useRef } from 'react'
import { FileType } from '../../../../types/shared/FileTypes'
import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import TipTapEditorAddFileButton from './TipTapEditorAddFileButton'
import TipTapEditorFileList from './TipTapEditorFileList'

interface ITipTapEditorWithFilesOnBottom {
  filesOnTop?: boolean
  handleDeleteFile?: RemoveUploadedFileFunctionType
  isSending?: boolean
  files?: FileType[]
  className?: string
  editor: Editor | null
  expandedEditor?: boolean
  dataCy?: string
  handleAddFiles: ((files: FileList) => void) | undefined
  editorContentClassName?: string
  editable: boolean | undefined
  noFocus?: boolean
}

const TipTapEditorWithFilesOnBottom: React.FC<ITipTapEditorWithFilesOnBottom> = ({
  files, filesOnTop, handleDeleteFile, isSending, editor, editable,
  expandedEditor, dataCy, handleAddFiles, editorContentClassName, noFocus,
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null)

  return (
    <div className={`flex h-full overflow-y-scroll overflow-overlay shadow-sm
      border rounded-md ${editor?.isFocused && !noFocus ? ' border-2  border-sky-blue' : ' border-none '}
      ${editor?.isEditable ? '  bg-white' : ' bg-transparent cursor-not-allowed '}`}
    >
      <div className="flex flex-col w-full ">
        {(editable || editor?.getText())
          && (
            <EditorContent
              editor={editor}
              className={`${expandedEditor ? 'h-full p-2' : 'h-12 max-h-28'} p-2 text-sm list-inside text-left  base-form-input 
               overflow-x-hidden list-disc w-full ${editorContentClassName} lhotse-texteditor`}
              data-cy={dataCy}
            />
          )}

        {(((!!files?.length) || isSending) && !filesOnTop) && (
          <div className="bottom-2">
            <TipTapEditorFileList
              files={files || []}
              handleDeleteFile={handleDeleteFile}
              isSending={isSending}
            />
          </div>
        )}
      </div>
      {handleAddFiles && (
        <TipTapEditorAddFileButton
          fileUploadRef={fileUploadRef}
          handleAddFiles={handleAddFiles}
        />
      )}
    </div>
  )
}

export default TipTapEditorWithFilesOnBottom
