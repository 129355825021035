import { useQuery } from 'react-query'
import { apiListExternalDataEntityColumns } from '../services/ExternalDataServices'

const useListExternalDataEntities = (fileUuid: string | undefined) => {
  const { data, isLoading, error } = useQuery(
    ['entity-columns', fileUuid],
    () => {
      if (!fileUuid) return Promise.resolve([])
      return apiListExternalDataEntityColumns(fileUuid)
    },
  )
  return {
    data,
    isLoading,
    error,
  }
}

export default useListExternalDataEntities
