import { FileDataBody, FileType } from '../../../types/shared/FileTypes'
import { apiDelete } from '../../../core/utils/api/generic'
import apiClient from '../../../core/utils/apiClient'
import { checkFileRules } from '../../../core/utils/fileUploadUtils'
import { getApiUrl } from '../../../core/utils/tenancy'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { MediaType } from '../../../types/shared/MediaTypes'

export const deleteFileWithToken = async (file: FileType, token: string) => {
  try {
    await apiDelete(`/offer/${token}/files/${file.id}`)
  } catch {
    toastr.error('error.failed_to_delete', 'failedDelete')
  }
}

// TODO:  Logic and HTTP requests should be separated
export const postFileWithToken = async (file: File, model: FileDataBody['model'], modelId: number, token: string, collection?: string): Promise<MediaType | null> => {
  try {
    const formData = new FormData()
    formData.append('model', model)
    formData.append('model_id', modelId?.toString())
    formData.append('file', file)
    if (collection) { formData.append('collection', collection) }

    const url = `${getApiUrl()}/offer/${token}/files`

    const { data: { data } } = await apiClient.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return data
  } catch (error) {
    toastr.error('error.upload_failed', 'failedUpload')
    return new Promise(() => { })
  }
}

export const checkAndUploadFileWithToken = (
  file: File | null,
  model: FileDataBody['model'],
  modelId: number,
  token: string,
): Promise<MediaType | null> => {
  if (file) {
    if (checkFileRules(file)) {
      return postFileWithToken(file, model, modelId, token)
    }
  }
  return Promise.resolve(null)
}
