import { useMutation, useQueryClient } from 'react-query'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import { apiPutRequisitionStatus } from '../services/RequisitionService'
import queryKeys from '../subModules/Definition/constants/queryKeys'

interface IUseUpdateRequisitionStatus { status: string, reason: string }

const useUpdateRequisitionStatus = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()
  return useMutation(
    ({ status, reason }: IUseUpdateRequisitionStatus) => apiPutRequisitionStatus(requisitionId, {
      status,
      reason,
    }),
    {
      onSuccess: ({ data }) => {
        cache.setQueryData(
          [queryKeys.requisitionData, requisitionId],
          (oldData: any) => ({ ...oldData, ...data }),
        )
      },
    },
  )
}

export default useUpdateRequisitionStatus
