import { useFormContext } from '../../../../../core/providers/FormProvider'
import QuestionChoices from '../QuestionChoices/QuestionChoices'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const SelectQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'SELECT') return null

  return (
    <div className="flex flex-col gap-y-8">
      <TextAndSubtitleInput />
      <QuestionChoices />
    </div>
  )
}

export default SelectQuestion
