import { useTranslation } from 'react-i18next'
import { MdLockOpen } from 'react-icons/md'
import CoreLoadingIcon from './CoreLoadingIcon'

const CoreAuthenticating: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="absolute z-50 top-0 left-0 w-screen h-screen items-center justify-center bg-indigo-100 bg-opacity-60">
      <div className="flex justify-center items-center h-screen">
        <div className="space-y-8 text-center">
          <MdLockOpen size={40} className="animate-pulse mx-auto my-4" />
          <div className="flex gap-x-1">
            <p>{t('general.authenticating')}</p>
            <CoreLoadingIcon className="ml-2 " />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoreAuthenticating
