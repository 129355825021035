import { useTranslation } from 'react-i18next'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'
import useAddAddressModal from './useAddAddressModal'

const CompanyAddressesNewButton = () => {
  const { t } = useTranslation()
  const triggerAddAddressModal = useAddAddressModal()

  return (
    <AddButton onClick={triggerAddAddressModal}>
      {t('company.add_address')}
    </AddButton>
  )
}

export default CompanyAddressesNewButton
