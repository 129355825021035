import { useAbility } from '@casl/react'
import { Dispatch, SetStateAction } from 'react'
import { ABILITIES } from '../../../config/ability'
import { AbilityContext } from '../../../core/providers/AbilityProvider'
import SupplierUserForm from '../SupplierDetailsPage/SupplierUserForm/SupplierUserForm'
import useGetSupplierBranchId from '../hooks/useGetSupplierBranchId'
import SupplierUsers from './SupplierUsers'

interface IUsers {
  showNewUserForm: boolean
  setShowNewUserForm: Dispatch<SetStateAction<boolean>>
}

const Users: React.FC<IUsers> = ({ showNewUserForm, setShowNewUserForm }) => {
  const supplierBranchId = useGetSupplierBranchId()

  const ability = useAbility(AbilityContext)
  const isEditable = ability.can(ABILITIES.createSupplier)

  return showNewUserForm
    ? (
      <div className="px-6 py-6">
        <SupplierUserForm
          supplierBranchId={supplierBranchId}
          hideForm={() => setShowNewUserForm(false)}
        />
      </div>
    )
    : <SupplierUsers editable={isEditable} className="p-6" />
}

export default Users
