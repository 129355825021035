import type { PartialDeep } from 'type-fest'
import { useFormContext } from '../../../../core/providers/FormProvider'
import {
  FormTemplateType, FormQuestionType, LoopQuestionLogic,
} from '../../../../types/Forms'
import useGetFormSubmission from './useGetFormSubmission'
import { getUpdatedByUuid } from '../../../../shared/utils/arrayHelpers'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import { getDefaultQuestionValues } from '../../constants/questions'
import { cloneQuestionValues } from './helpers'

export default (question?: FormQuestionType) => {
  const { submission: [submission, setSubmission], findQuestion } = useFormContext()
  const { data: submissionFormData } = useGetFormSubmission()

  const questionSection = submission?.sections?.find(
    (section) => section.fields.find((sectionQuestion) => sectionQuestion.__uuid === question?.__uuid),
  )

  return (newLoopSettings: PartialDeep<LoopQuestionLogic>) => {
    if (!submission || submissionFormData?.submitted || !questionSection || !question) return

    const originalQuestion = findQuestion(question.__loop?.parentQuestionUuid || '')

    const getNewValues = () => {
      if (!Object.hasOwn(newLoopSettings, 'hasCopiedValuesFromParent') || !originalQuestion) {
        return question.values
      }

      if (newLoopSettings.hasCopiedValuesFromParent) {
        return cloneQuestionValues(originalQuestion)
      }

      return getDefaultQuestionValues(question)
    }

    const updatedQuestions = getUpdatedByUuid(questionSection.fields, [{
      __uuid: question.__uuid,
      __loop: newLoopSettings,
      values: getNewValues(),
    }])

    const newForm: FormTemplateType = getMergedDeepOverwritingArrays(
      submission,
      {
        sections: getUpdatedByUuid(submission.sections, [{
          __uuid: questionSection.__uuid,
          fields: updatedQuestions,
        }]),
      },
    )

    setSubmission(newForm)
  }
}
