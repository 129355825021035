import DnDContext from '../../../../../Requisition/subModules/Coordinate/components/TemplateBuilder/TemplateBuilder/DnDContext'
import DnDDroppable from '../../../../../Requisition/subModules/Coordinate/components/TemplateBuilder/TemplateBuilder/DnDDroppable'
import { FormSectionType } from '../../../../../../types/Forms/formTypes'
import FormQuestion from '../FormQuestion'
import useHandleQuestionsDragEnd from '../hooks/useHandleQuestionsDragEnd'

interface IQuestionsTable {
  section: FormSectionType
  sectionIndex: number
}

const QuestionsTable: React.FC<IQuestionsTable> = ({ section, sectionIndex }) => {
  const handleEndDrag = useHandleQuestionsDragEnd(section)

  return (
    <DnDContext droppableId="questions" onDragEnd={handleEndDrag} droppableType="droppable">
      <DnDDroppable droppableId="questions" droppableType="droppable">
        {section.fields.map((question, questionIndex) => (
          <FormQuestion
            key={question.__uuid}
            question={question}
            questionIndex={questionIndex}
            sectionIndex={sectionIndex}
            section={section}
          />
        ))}
      </DnDDroppable>
    </DnDContext>
  )
}

export default QuestionsTable
