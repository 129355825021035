import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import Table from '../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../shared/components/atoms/SharedTable/TableHeader'
import useGetCompanyAddresses from '../hooks/useGetCompanyAddresses'
import AddressesListRowContent from './AddressesListRowContent'
import AddressListRowActions from './AddressListRowActions'

const AddressesList = () => {
  const { t } = useTranslation()

  const { data: addresses, isLoading } = useGetCompanyAddresses()

  if (isLoading) return <CoreLoadingIcon />

  return (
    <TableContainer>
      <TableHead>
        <TableHeader>{t('generic.name')}</TableHeader>
        <TableHeader>{t('account.company_street')}</TableHeader>
        <TableHeader>{t('account.company_place')}</TableHeader>
        <TableHeader>{t('account.company_country')}</TableHeader>
        <TableHeader>{t('generic.company_address_visibility')}</TableHeader>
        <TableHeader>{t('generic.main_company_address')}</TableHeader>
      </TableHead>
      <Table>
        {!!addresses?.length && addresses?.map((address) => (
          <AddressesListRowContent address={address} key={`address-row-${address.id}`}>
            <AddressListRowActions address={address} />
          </AddressesListRowContent>
        ))}
      </Table>
    </TableContainer>
  )
}

export default AddressesList
