import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../../../core/components/CoreLoadingIcon'
import Table from '../../../../../../../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../../../../../../shared/components/atoms/SharedTable/TableHeader'
import { CATALOG_ITEM } from '../../../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../../../services/UnifiedSearchService'
import CatalogTieredShippingTableRow from './CatalogTieredShippingTableRow'

interface ICatalogTieredShippingTable {
  catalogId: string
}
const CatalogTieredShippingTable: React.FC<ICatalogTieredShippingTable> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))
  const { t } = useTranslation()
  if (!data) return <CoreLoadingIcon />

  return (
    <TableContainer>
      <TableHead>
        <TableHeader>{t('generic.quantity')}</TableHeader>
        <TableHeader>{t('generic.price')}</TableHeader>
      </TableHead>
      <Table>
        {data.shippingCosts.shippingCosts[0].tiers.map((tier, index) => (
          <CatalogTieredShippingTableRow tier={tier} index={index} key={tier.value.amount.value} />
        ))}
      </Table>
    </TableContainer>
  )
}

export default CatalogTieredShippingTable
