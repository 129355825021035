import { merge, omitBy, isNil } from 'lodash'
// @ts-ignore
import { ref, Reference } from 'yup'
import { FormQuestionContentType, FormQuestionType, FormQuestionValidationProperty } from '../../../../types/Forms'

export const getType = (question: FormQuestionType): 'array' | 'object' | 'string' | 'number' => {
  const questionWithArrayAsValue: FormQuestionContentType[] = []
  const questionWithObjectAsValue: FormQuestionContentType[] = ['FILE', 'ADDRESS']

  if (questionWithArrayAsValue.includes(question.type)) { return 'array' }
  if (questionWithObjectAsValue.includes(question.type)) { return 'object' }
  if (question.type === 'NUMBER') return 'number'
  return 'string'
}

const hasArrayOfValues = (question: FormQuestionType) => getType(question) === 'array'

export const getRequired = (question: FormQuestionType) => {
  if (hasArrayOfValues(question)) { return false }

  return question.validation.required?.value
}

export const getRefOrValue = <T>(property?: FormQuestionValidationProperty<T>) => (property?.target
  ? ref(`$${property.target}`)
  : property?.value)

export const getMinValues = (question: FormQuestionType): number | undefined | Reference => question.validation.min_values?.value || (question.validation.required?.value ? 1 : 0)
export const getMaxValues = (question: FormQuestionType): number | undefined | Reference => question.validation.max_values?.value

export const getMin = (question: FormQuestionType): number | undefined | Reference => getRefOrValue(question.validation.min)
export const getMax = (question: FormQuestionType): number | undefined | Reference => getRefOrValue(question.validation.max)

export const getWholeQuestionValidationSchema = (question: FormQuestionType) => omitBy({
  min: getMinValues(question),
  max: getMaxValues(question),
  total: getRefOrValue(question.validation.total),
}, isNil)

export const getSingleInputFieldValidationSchema = (question: FormQuestionType) => omitBy({
  required: getRequired(question),
  customMin: getMin(question),
  customMax: getMax(question),
  min: question.validation.min_characters?.value,
  max: question.validation.max_characters?.value,
  exact: getRefOrValue(question.validation.exact),
}, isNil)

export const getQuestionValidationValue = (question: FormQuestionType) => {
  if (question.values.length === 0) return [undefined]

  return question.values.filter((v) => v.value).filter((v) => v !== undefined).map(({ value }) => (value === '' || value === null ? undefined : value))
}

export const getQuestionValueSchema = (question: FormQuestionType) => ({
  [question.__uuid]: getQuestionValidationValue(question),
})

export const getQuestionValues = (questions: FormQuestionType[]) => merge({}, ...questions.map(getQuestionValueSchema))
