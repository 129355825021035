import { useQuery } from 'react-query'
import useGetToken from '../../../SupplierOffer/hooks/useGetToken'
import messengerQueryKeys from '../../messengerQueryKeys'
import { apiGetChatMessages } from '../../service'
import useIntakeId from '../../../Intake/hooks/useIntakeId'

export default () => {
  const modelType = 'intake_flow'
  const modelIdParam = useIntakeId()

  const token = useGetToken()
  const paramsObject = { model_type: modelType, model_id: modelIdParam }

  const QUERY_KEY = messengerQueryKeys.messages(modelType, Number(modelIdParam))

  return useQuery(
    QUERY_KEY,
    () => apiGetChatMessages(token, paramsObject),
    { enabled: !!(modelType || token) },
  )
}
