import classnames from 'classnames'
import { FormQuestionResponseType, FormQuestionType } from '../../../../types/Forms'
import { inputErrorClassNames, inputErrorTextClassNames } from '../QuestionTypes/atoms/helpers'
import useIsQuestionDisabled from '../QuestionTypes/hooks/useIsQuestionDisabled'
import { FormEvents } from '../Questions'
import useLoopSection from '../hooks/useLoopSection'
import useQuestionValues from '../hooks/useQuestionValues'
import useSynchronizedFormUpdate from '../hooks/useSynchronizedFormUpdate'
import useUnloopSection from '../hooks/useUnloopSection'

interface IResponseChoice {
  index: number
  option: FormQuestionResponseType
  question: FormQuestionType
  error?: string | null
}

const ResponseChoice: React.FC<IResponseChoice> = ({
  option, question, error,
}) => {
  const [questionValues, setQuestionValues] = useQuestionValues(question)
  const isSelected = questionValues?.find((value) => value.__uuid === option.__uuid)
  const isSingleSelect = question.type === 'SELECT'
  const isLoopQuestion = !!question.__loop?.is_loop_trigger
  const isOtherResponse = option.__uuid === 'other'
  const isDisabled = useIsQuestionDisabled(question)

  const handleLoopSection = useLoopSection(question)
  const handleUnloopSection = useUnloopSection(question)

  const unselect = () => {
    if (isLoopQuestion) {
      handleUnloopSection(false)
      return
    }

    setQuestionValues(questionValues?.filter((value) => value.__uuid !== option.__uuid) || [])
  }

  const select = () => {
    if (isLoopQuestion) {
      if (option.__uuid === 'yes') {
        handleLoopSection()
      } else {
        handleUnloopSection(true)
      }
      return
    }

    const newAnswer = { ...option, label: option?.label || question.question_label }
    if (isOtherResponse) newAnswer.value = ''

    if (isSingleSelect) {
      setQuestionValues([newAnswer])
      FormEvents.QuestionAnswered.dispatch(question.__uuid)
    } else {
      setQuestionValues([...questionValues || [], newAnswer])
    }
  }

  const handleOnClick = useSynchronizedFormUpdate(() => {
    if (isSelected) unselect()
    else select()
  })

  return (
    <button
      type="button"
      className="flex gap-x-4 items-center w-full bg-white"
      onClick={handleOnClick}
      disabled={isDisabled}
    >

      <div className={classnames(
        'flex gap-x-3 items-center py-2 px-3 border rounded-md w-full text-left base-form-input ',
        {
          'bg-gray-100': isDisabled,
          [inputErrorClassNames]: !!error,
          'm-px text-nds-secondary-gray': !!error,
          'border-sky-blue border ': !error && isSelected,
          'm-px  border-white shadow': !error && !isSelected,
        },
      )}
      >
        <div className="w-fit">
          <div className={` ${error ? `${inputErrorClassNames} ${inputErrorTextClassNames}` : ''} p-0.5  border  shadow 
      ${isSelected ? 'border border-sky-blue bg-sky-blue bg-opacity-10' : 'bg-gray-50'} w-fit flex items-center justify-center 
        ${isSingleSelect ? 'rounded-full' : 'rounded-sm'}`}
          >
            <p className={`w-2 h-2  ${!isSelected ? 'bg-white' : 'bg-sky-blue '} ${isSingleSelect ? 'rounded-full' : 'rounded-sm'}`} />
          </div>
        </div>
        <p className="leading-6">{option.display_name || option.value}</p>
      </div>
    </button>
  )
}

export default ResponseChoice
