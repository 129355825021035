import useGetTransmissionPreview from '../../../hooks/useGetTransmissionPreview'

const SapTransferPayload = () => {
  const { data: transmissionPreview } = useGetTransmissionPreview()

  return (
    <pre>{JSON.stringify(transmissionPreview, null, 2)}</pre>
  )
}

export default SapTransferPayload
