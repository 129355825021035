import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import useGetOffer from '../../hooks/useGetOffer'
import useIsSupplierOfferEditable from '../../hooks/useIsSupplierOfferEditable'
import { checkIfInputDisabled } from '../../utils/utils'
import { invalidIdStateType } from '../../utils/validationUtils'
import DeliverySection from '../DeliverySection/DeliverySection'
import DescriptionSection from '../DescriptionSection/DescriptionSection'
import DocumentsSection from '../DocumentSection/DocumentsSection'
import FinalOfferDocumentsSection from '../FinalOfferFilesSection/FinalOfferFilesSection'
import SupplierNumberSection from '../SupplierNumberSection/SupplierNumberSection'
import useGetSupplierOfferMaxContentHeight from './hooks/useGetSupplierOfferMaxContentHeight'
import ReqClosedOverlay from './ReqClosedOverlay'

interface ICoreSections {
  invalidIds: invalidIdStateType
}

const CoreSections: React.FC<ICoreSections> = ({ invalidIds }) => {
  const { userCanEditAnyOffer } = useIsSupplierOfferEditable()
  const { data: offerData } = useGetOffer()

  const filteredDocuments = offerData?.customer_documents?.filter((document: CustomerDocumentType) => document.type !== 'nda')
  const requisitionData = offerData?.requisition
  const mainContentMaxHeight = useGetSupplierOfferMaxContentHeight()

  const isInputDisabled = checkIfInputDisabled(offerData, userCanEditAnyOffer)

  return (
    <>
      <ReqClosedOverlay />
      <div
        className="flex justify-center overflow-y-auto w-full h-full"
        style={{ maxHeight: mainContentMaxHeight }}
      >
        <div className="px-5 h-full max-w-6xl w-full">
          <div className={`flex flex-col gap-y-4 py-8 ${isInputDisabled && 'pointer-events-none'}`}>
            <p className="text-xl">{requisitionData?.name}</p>
            <SupplierNumberSection />
            <DescriptionSection invalidIds={invalidIds} />
            <DeliverySection />
            <FinalOfferDocumentsSection invalidIds={invalidIds} />
            <DocumentsSection
              filteredDocuments={filteredDocuments}
              invalidIds={invalidIds}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CoreSections
