import { FormNumberOfChoicesOptionSettingType } from '../../../../../../types/Forms'
import QuestionRadioButton from '../../components/inputs/QuestionRadioButton'
import ChoicesRangeInput from './ChoicesRangeInput'
import DefinedNumberInput from './DefinedNumberInput'

interface INumberOfChoicesOption {
  type: FormNumberOfChoicesOptionSettingType
  onSelect: (type: FormNumberOfChoicesOptionSettingType) => void
  isChecked: boolean
}

const choiceProperties: {
  [choice in FormNumberOfChoicesOptionSettingType]: {
    title: string
    description?: string
  }
} = {
  unlimited: { title: 'Unlimited', description: 'An unlimited number of choices can be selected.' },
  single: { title: 'Single choice', description: 'The user can select only one of the options available' },
  defined: { title: 'Defined number' },
  range: { title: 'Range', description: 'You can set a minimum and maximum value of choices that can be selected' },
}

const NumberOfChoicesOption: React.FC<INumberOfChoicesOption> = ({
  type, onSelect, isChecked,
}) => {
  const { title, description } = choiceProperties[type]

  return (
    <div className="flex gap-x-2">
      <QuestionRadioButton
        checked={isChecked}
        name="number-of-choices"
        onClick={() => onSelect(type)}
      />
      <div className="flex flex-col gap-y-2 align-top">
        <p className="leading-none text-nds-primary-gray">{title}</p>
        {description && <p className="text-nds-secondary-gray">{description}</p>}
        {type === 'defined' && isChecked && <DefinedNumberInput />}
        {type === 'range' && isChecked && <ChoicesRangeInput />}
      </div>
    </div>
  )
}

export default NumberOfChoicesOption
