import { useTranslation } from 'react-i18next'
import Image from '../../../../../../../shared/components/atoms/Image'

interface ICatalogThumbnail {
  image: string | undefined
  name: string
}
const CatalogThumbnail: React.FC<ICatalogThumbnail> = ({ image, name }) => {
  const { t } = useTranslation()

  if (!image) {
    return (
      <div className="w-[60px] h-[60px] bg-gray-400 flex items-center justify-center rounded">
        <p className="text-xs">{t('generic.no_image')}</p>
      </div>
    )
  }

  return (
    <Image
      src={image}
      alt={name}
      className="max-w-[60px] max-h-[60px]"
    />
  )
}

export default CatalogThumbnail
