import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import { useUtils } from '../../../../../core/providers/UtilsProvider'

const Content = () => {
  const { t } = useTranslation()
  const { modal: { get: { content, isLoading } } } = useUtils()

  return isLoading
    ? <div className="flex items-center justify-center h-72"><CoreLoadingIcon /></div>
    : (
      <div className="p-6 pt-0 max-h-[80vh] overflow-auto overflow-overlay">
        {typeof content === 'string'
          ? t(content)
          : content}
      </div>
    )
}

export default Content
