import type { PartialDeep } from 'type-fest'
import { mergeDeepOverwritingArrays } from '../../shared/utils/objectHelpers'
import { FormFieldSettings } from './types'
import { AddressQuestionValue, FormQuestionValue, InputTemplate } from '../../types/Forms'
import AddressType from '../../types/shared/AddressType'

export const generateField = <T extends string = string> (settings: PartialDeep<FormFieldSettings<T>> & {id: string}) => mergeDeepOverwritingArrays<FormFieldSettings<T>>({
  id: '' as T,
  input: {
    id: 0,
    name: '',
    description: '',
    type: 'TEXT',
  },
  label: '',
  value: null,
  media: null,
  options: null,
}, settings)

export const toFieldValue = (value: string, { type, options, uuid }: InputTemplate): FormQuestionValue => {
  if (type === 'NUMBER' || type === 'PRICE') return { value: parseFloat(value) } as FormQuestionValue

  if (type === 'SELECT') {
    return (options?.find((option) => option.value === value) || null) as unknown as FormQuestionValue
  }

  if (type === 'ADDRESS') {
    return { value: { id: parseInt(value, 10) } as AddressType } as unknown as FormQuestionValue
  }

  return { value } as FormQuestionValue
}

export const fromFieldValue = (value: FormQuestionValue | undefined | null, { type }: InputTemplate): string => {
  if (value === undefined || value === null) return ''

  if (type === 'NUMBER' || type === 'PRICE') return value.value.toString()

  // @ts-ignore
  if (type === 'SELECT') return value.label as string

  if (type === 'ADDRESS') return (value.value as AddressQuestionValue).id.toString()

  return value.value as unknown as string
}
