import { LineItemType } from '../../../../../../../../types/shared/LineItemDataTypes'
import SharedFileUploadPill from '../../../../../../../../shared/components/layout/SharedFileUploadPill/SharedFileUploadPill'

interface IFileValue {
  singleLineItem: LineItemType
}

const FileValue: React.FC<IFileValue> = ({
  singleLineItem,
}) => (
  <div className="flex gap-y-2 gap-x-2 flex-wrap w-full">
    {singleLineItem?.files?.map((file) => (
      <SharedFileUploadPill
        file={file}
        className="font-semibold text-blue-700 bg-blue-100 hover:bg-blue-200"
        iconClassName="text-blue-700"
        key={file.name}
      />
    ))}
  </div>
)

export default FileValue
