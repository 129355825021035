import SidepanelTabTransition from '../../../../shared/components/layout/SidepanelTabTransition'
import WorkflowBuilderSidepanelBlockDetails
  from '../../../Workflow/components/WorkflowBuilderSidePanel/WorkflowBuilderSidepanelBlockDetails/WorkflowBuilderSidepanelBlockDetails'
import WorkflowBlockDraftProvider from '../../../Workflow/providers/WorkflowBlockDraftProvider'
import WorkflowProvider from '../../../Workflow/providers/WorkflowProvider'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'
import { Workflow } from '../../../../types/Workflow/Workflow'

export const IntakeSidepanelBlockDetails = () => {
  const { activeWorkflow, selectedBlock } = useIntakeFlow()

  if (!activeWorkflow || !selectedBlock) return null

  return (
    <SidepanelTabTransition>
      <WorkflowProvider workflow={activeWorkflow as unknown as Workflow} readonly>
        <WorkflowBlockDraftProvider block={selectedBlock}>
          <WorkflowBuilderSidepanelBlockDetails />
        </WorkflowBlockDraftProvider>
      </WorkflowProvider>
    </SidepanelTabTransition>
  )
}

export default IntakeSidepanelBlockDetails
