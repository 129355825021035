import { FormSubmissionType } from '../../../../../../types/Forms'

/*
This hook is used to infer the status of a submission based on the data in the submission object.
It is only relevant for softSubmissionclients (currentyl advario)
*/

export type InferStatusStatusType = 'draft' | 'submitted' | 'transferred' | 'transfer_pending'

export type InferStatusType = {
  status: InferStatusStatusType
  info: string
}

export default function useInferStatus() {
  const inferStatus = (submission: FormSubmissionType): InferStatusType => {
    const submissionStatus: InferStatusType = { status: 'draft', info: '' }

    if (submission.assignments.length > 0) {
      submissionStatus.status = 'submitted'
    }

    if (submission.transmissions.length > 0) {
      if (submission.transmissions[submission.transmissions.length - 1].status === 'success') {
        submissionStatus.status = 'transferred'
        submissionStatus.info = submission.transmissions[submission.transmissions.length - 1].external_creation_id
      } else {
        submissionStatus.status = 'transfer_pending'
        submissionStatus.info = 'pending'
      }
    }

    return submissionStatus
  }

  return { inferStatus }
}
