import useGetSSOProviders from '../hooks/useGetSSOProviders'
import LoginContainerSSOProviders from './LoginSSOProviders'

const SSOProviders = () => {
  const { data: ssoProviders } = useGetSSOProviders()

  return (
    <div>
      {ssoProviders && ssoProviders?.data?.length > 0
        ? <LoginContainerSSOProviders ssoProviders={ssoProviders?.data} />
        : ''}
    </div>
  )
}

export default SSOProviders
