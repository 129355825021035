import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { apiPostDuplicateSubmission } from '../../../../../Forms/constants/formService'
import useGetRequisitionData from '../../../../subModules/Definition/hooks/useGetRequisition'

const useDuplicateSubmission = () => {
  const { data } = useGetRequisitionData()
  const history = useHistory()

  const formId = data?.form_submission?.uuid || ''

  return useMutation(
    ['duplicateSubmission', formId],
    (formSubmissionId?:string) => apiPostDuplicateSubmission(formSubmissionId || formId),
    { onSuccess: ({ form_uuid, form_submission_uuid }) => history.push(`/forms/${form_uuid}/submission/${form_submission_uuid}`) },
  )
}

export default useDuplicateSubmission
