import { useTranslation } from 'react-i18next'

const NoResults = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex justify-center items-center mt-4 text-slate-500" data-cy="no-results">
      {t('generic.no_results')}
    </div>
  )
}

export default NoResults
