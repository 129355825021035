import { useLocation, useParams } from 'react-router'

export default () => {
  const { pathname } = useLocation()
  const { requisitionId } = useParams<{ requisitionId: string }>()

  if (pathname.includes('req')) {
    return requisitionId || pathname?.split('-')?.[1]?.split('/')?.[0]
  }

  return ''
}
