const scrollIntoViewWithOffset = (element: HTMLDivElement, offset: number) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
        element.getBoundingClientRect().top
        - document.body.getBoundingClientRect().top
        - offset,
  })
}

export default scrollIntoViewWithOffset
