import { useTranslation } from 'react-i18next'
import { redirectToInstance } from '../../../../../core/utils/tenancy'
import Button from '../../../../../shared/components/atoms/buttons/Button'

interface props {
  links: number[]
}
const SelectInstance = ({ links }: props) => {
  const { t } = useTranslation()

  return (
    <>
      <p className="text-gray-500 pb-3">
        {t('login.select_instance')}
      </p>
      {links.map((link) => (
        <Button
          key={link}
          color="lhotse-orange"
          variant="solid"
          onClick={() => redirectToInstance(link)}
        >
          {link}
        </Button>
      ))}
    </>
  )
}

export default SelectInstance
