import { useTranslation } from 'react-i18next'
import { TrashIcon } from '@heroicons/react/24/outline'
import uuid from 'react-uuid'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import { WorkflowBlockTask } from '../../../../../types/Workflow/WorkflowBlock'
import WorkflowActionForm from './WorkflowActionForm'
import WorkflowAddButton from '../../misc/WorkflowAddButton'
import { useWorkflow } from '../../../providers/WorkflowProvider'

const WorkflowActionList = () => {
  const { t } = useTranslation()
  const { block, updateBlock } = useWorkflowBlockDraft()
  const { modal } = useUtils()
  const { readonly } = useWorkflow()

  const onWorkflowTaskConfirm = (task: WorkflowBlockTask, taskIndex?: number) => {
    let updatedTask = task

    if (taskIndex !== undefined && block.tasks[taskIndex]) {
      const t = block.tasks[taskIndex]
      updatedTask = {
        uuid: t.uuid,
        parent_id: t.parent_id,
        name: task.name,
        description: task.description,
        required: task.required,
        assignees: task.assignees,
      }

      // set block task to updated task
      block.tasks[taskIndex] = updatedTask
      return
    }

    // Get the approvers from the block and add them to the task
    updatedTask.assignees = block.approvers?.map((approver) => ({
      user_id: approver.user.id,
    }))
    block.tasks.push(updatedTask)
  }

  const openActionModal = (selectedTask?: WorkflowBlockTask, taskIndex?: number) => {
    const content = (
      <WorkflowActionForm
        onConfirm={onWorkflowTaskConfirm}
        readonly={readonly}
        selectedTask={selectedTask || undefined}
        taskIndex={selectedTask ? taskIndex : undefined}
      />
    )

    modal.set({
      isOpen: true,
      title: 'Create an action',
      content,
    })
  }

  const handleDelete = (taskId: string | number) => {
    // if taskUuid is a number, then it's a draft task
    if (typeof taskId === 'number') {
      block.tasks.splice(taskId, 1)
      updateBlock(block)
    } else {
      // find task in block based on uuid (taskId) and remove it
      const taskIndex = block.tasks.findIndex((task) => task.uuid === taskId)
      block.tasks.splice(taskIndex, 1)
      updateBlock(block)
    }
  }

  return (
    <div className="flex flex-col w-full">
      {!readonly && (
        <div className="mb-4">
          <WorkflowAddButton
            variant="ghost"
            onClick={() => openActionModal()}
            className="text-sky-blue-light hover:text-sky-blue transition-colors bg-transparent !p-0"
          >
            {t('workflow.block.create_action', 'Create an action')}
          </WorkflowAddButton>
        </div>
      )}
      { block.tasks.map((task, taskIdx) => (
        <div
          key={uuid()}
          role="button"
          tabIndex={0}
          onClick={() => openActionModal(task, taskIdx)}
          className="flex w-full border border-rock-gray-light hover:shadow-md
          transition-shadow shadow-sm py-2.5 px-5 rounded-md mb-4 cursor-pointer"
        >
          <div className="text-sm font-medium">
            {task.name}
          </div>
          {!readonly && (
          <div className="flex ml-auto">
            <TrashIcon
              className="w-5 h-5 my-auto text-rock-gray
              hover:text-red-light transition-colors cursor-pointer"
              onClick={(event) => {
                event.stopPropagation()
                handleDelete(task.uuid || taskIdx)
              }}
            />
          </div>
          )}

        </div>
      ))}
    </div>
  )
}

export default WorkflowActionList
