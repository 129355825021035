import { ReactNode } from 'react'

export type ISettingRow = React.PropsWithChildren<{
  title: string
  description?: string
  input: ReactNode | undefined
}>

const SettingRow: React.FC<ISettingRow> = ({
  children, title, description, input,
}) => (
  <div className="flex gap-x-4">
    <div>
      {input}
    </div>
    <div className="flex flex-col gap-y-2 justify-center">
      <p className="text-primary-gray text-sm leading-none">{title}</p>
      <p className="text-secondary-gray text-xs">{description}</p>
      {children}
    </div>
  </div>
)

export default SettingRow
