interface IActionButton {
  action: {
    buttonClasses: string;
    buttonTitle: JSX.Element;
    disabled: boolean;
    onClick: () => void;
    icon: JSX.Element;
    dataCy: string
  }
}

const ActionButton: React.FC<IActionButton> = ({
  action,
}) => (
  <button
    className={`flex flex-col items-center justify-center text-center group w-1/3 p-1 ${action.buttonClasses}`}
    onClick={action.onClick}
    type="button"
    disabled={action.disabled}
    data-cy={action.dataCy}
  >
    <div className="flex w-full justify-center mb-1">
      {action.icon}
    </div>
    {action.buttonTitle}
  </button>
)

export default ActionButton
