import { useMutation, useQueryClient } from 'react-query'
import { apiDeclineRequisitionOffers } from '../../../../services/RequisitionService'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'
import ReqDefQueryKeys from '../../../../subModules/Definition/constants/queryKeys'
import queryKeys from '../../../../subModules/Award/constants/OfferViewQueryKeys'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'

export const useDeclineRequisitionOffers = (requisitionId: string, callback?: Function) => {
  const utils = useUtils()
  const queryClient = useQueryClient()

  const { mutate: deleteMutate } = useMutation(() => apiDeclineRequisitionOffers(requisitionId), {
    onSuccess: () => {
      queryClient.refetchQueries([ReqDefQueryKeys.requisitionData, requisitionId])
      queryClient.invalidateQueries([queryKeys.OFFERS_DATA, requisitionId])
      toastr.success('supplier_offer.offer_declined', 'successDeclineRequisitionOffers')
      utils.modal.set({ isOpen: false })
      callback && callback()
    },
    onError: () => {
      toastr.error('error.generic', 'errorDeclineRequisitionOffers')
      callback && callback()
    },
  })

  return deleteMutate
}

export default useDeclineRequisitionOffers
