import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react'
import { omit } from 'lodash'
import useBus, { dispatch } from 'use-bus'
import { recenter } from '../../../../../shared/utils/documentHelpers'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import validate, { ErrorsIndex } from '../../validation/formValidation'
import useCurrentSection from './hooks/useCurrentSection'
import { EvaluatedFormSectionType, FormQuestionType } from '../../../../../types/Forms'
import { useFormLogic } from '../FormLogicProvider'
import { FormEvents } from '../../Questions'
import { getUpdatedByUuid } from '../../../../../shared/utils/arrayHelpers'
import useIntakeFlowSubmissionFlag from '../../hooks/useIntakeFlowSubmissionFlag'
import { useFormData } from '../FormDataProvider'

type IFormSectionContext = {
  isValid: boolean
  isQuestionValid: (question: FormQuestionType) => boolean,
  errors?: ErrorsIndex | null,
  validateSection: () => boolean,
  validateSectionFields: () => boolean,
  validateQuestions: (questions: FormQuestionType[]) => boolean,
  validateQuestion: (question: FormQuestionType) => boolean,
  currentSection?: EvaluatedFormSectionType
  goTo: (targetSectionUuid: string) => void
  goToNextSection: () => void
  goToPreviousSection: () => void
}

const formSectionContext = createContext({} as IFormSectionContext)

export const FormSectionEvents = {
  ProceedTo: {
    key: 'form-section/proceed-to',
    dispatch: (sectionUuid: string) => dispatch({ type: FormSectionEvents.ProceedTo.key, payload: sectionUuid }),
  },
} as const

const FormSectionProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const isIntakeFlowSubmission = useIntakeFlowSubmissionFlag()
  const [errors, setErrors] = useState<ErrorsIndex | null>(null)
  const { updateConfig, submission: [submission] } = useFormContext()
  const { navigationSectionsIndex } = useFormData()

  const currentSection = useCurrentSection()
  const { findSectionIndex, allEnabledQuestions } = useFormLogic()

  const { nextSection, previousSection, enabledFields } = currentSection || {}

  const validateQuestions = useCallback((questions: FormQuestionType[]) => validate(getUpdatedByUuid(allEnabledQuestions, questions), questions), [allEnabledQuestions])

  const isQuestionValid = useCallback((question: FormQuestionType) => !validateQuestions([question]), [validateQuestions])

  const validateQuestionsAndUpdateErrors = useCallback((questions: FormQuestionType[]) => {
    const newErrors = validateQuestions(questions)

    const previousErrors = omit(errors, questions.map(({ __uuid }) => __uuid))
    setErrors({ ...previousErrors, ...(newErrors || {}) })

    return !newErrors
  }, [validateQuestions, enabledFields, setErrors, errors])

  const validateSectionFieldsAndUpdateErrors = useCallback(() => validateQuestionsAndUpdateErrors(enabledFields || []), [validateQuestionsAndUpdateErrors, enabledFields])

  const validateOfferings = useCallback(() => {
    // Don't validate offerings if it's not an intake flow submission
    if (isIntakeFlowSubmission) {
      return false
    }

    if (!submission?.__settings?.offerRequired) return true
    const offeringsWithMissingOffers = currentSection?.offerings?.filter(({ number_of_offers }) => number_of_offers === 0)

    if (!offeringsWithMissingOffers?.length) return true
    setErrors({ ...errors, ...({ offerings: Object.fromEntries(offeringsWithMissingOffers.map((offering) => [offering.reference_key, { name: 'offer_required' }])) }) })
    return false
  }, [submission, currentSection])

  const validateSectionAndUpdateErrors = useCallback(() => {
    if (currentSection?.type === 'PRODUCT') {
      return validateOfferings()
    }

    return validateSectionFieldsAndUpdateErrors()
  }, [validateSectionFieldsAndUpdateErrors, enabledFields, currentSection, validateOfferings])

  const goTo = useCallback((targetSectionUuid: string) => {
    const currentSectionIndex = findSectionIndex(currentSection?.__uuid || '')
    const furtherSectionIndex = findSectionIndex(targetSectionUuid)
    const isFurtherSection = currentSectionIndex !== -1 && (currentSectionIndex < furtherSectionIndex)

    if (isFurtherSection
      && submission?.__settings?.shouldValidateOnSectionChange && !validateSectionAndUpdateErrors()) {
      const firstInvalidQuestion = enabledFields?.find((question) => !isQuestionValid(question))
      firstInvalidQuestion && FormEvents.QuestionHasError.dispatch(firstInvalidQuestion.__uuid)
      return
    }

    updateConfig({ activeSectionUUID: targetSectionUuid })
    recenter()
    setErrors(null)
  }, [findSectionIndex, currentSection, submission?.__settings, validateSectionFieldsAndUpdateErrors, updateConfig])

  useBus(FormSectionEvents.ProceedTo.key, ({ payload: sectionUuid }) => goTo(sectionUuid), [goTo])

  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  const values: IFormSectionContext = useMemo(() => ({
    isValid: !errors || Object.keys(errors || {}).length === 0,
    isQuestionValid,
    errors,
    validateSection: validateSectionFieldsAndUpdateErrors,
    validateSectionFields: validateSectionFieldsAndUpdateErrors,
    validateQuestions: validateQuestionsAndUpdateErrors,
    validateQuestion: (question: FormQuestionType) => validateQuestionsAndUpdateErrors([question]),
    currentSection,
    goTo,
    goToNextSection: () => {
      if (!nextSection?.__uuid) return
      if (currentSection?.type === 'PRODUCT' && !isFunkeOrAdvario) {
        // Mechanism to skip multiple product section in the navigation
        const nextNavSection = navigationSectionsIndex.findIndex((section) => section.__uuid === currentSection.__uuid)
        goTo(navigationSectionsIndex[nextNavSection + 1].__uuid)
        return
      }
      goTo(nextSection.__uuid)
    },
    goToPreviousSection: () => {
      if (!previousSection?.__uuid) return
      if (previousSection?.type === 'PRODUCT' && !isFunkeOrAdvario) {
        // Mechanism to skip multiple product section in the navigation
        const prevProductNavSection = navigationSectionsIndex.find((section) => section.type === 'PRODUCT')
        goTo(prevProductNavSection?.__uuid || navigationSectionsIndex[0].__uuid)
        return
      }
      goTo(previousSection.__uuid)
    },
  }), [errors, nextSection?.__uuid, previousSection?.__uuid, currentSection, enabledFields, goTo])

  return (
    <formSectionContext.Provider value={values}>
      {children}
    </formSectionContext.Provider>
  )
}

export default FormSectionProvider

export const useFormSection = () => useContext(formSectionContext)
