import { useUtils } from '../../../../../core/providers/UtilsProvider'
import { RequisitionSearchDataCatalogType } from '../../../../../types/Requisition/RequisitionSearchResultsDataTypes'
import NewReqCatalogItemModal from '../components/Catalog/Modal/NewReqCatalogItemModal'
import NewReqCatalogModalHeader from '../components/Catalog/Modal/NewReqCatalogModalHeader'

const useOpenCatalogItemModal = () => {
  const { modal } = useUtils()

  return (selected: RequisitionSearchDataCatalogType) => modal.set({
    isOpen: true,
    title: <NewReqCatalogModalHeader item={selected} />,
    content: <NewReqCatalogItemModal catalogId={selected?.id} />,
  })
}

export default useOpenCatalogItemModal
