import { MdTranslate } from 'react-icons/md'
import i18n from '../../../../i18n'
import Button from '../../../../shared/components/atoms/buttons/Button'
import useSwitchLanguage from '../../../Settings/hooks/useSwitchLanguage'

const TopBannerLanguageButton: React.FC = () => {
  const switchLanguage = useSwitchLanguage()

  return (
    <Button
      color="sky-blue"
      onClick={() => switchLanguage()}
      dataCy="supplier-offer-language-button"
    >
      <MdTranslate className="text-xl" />
      <p className="font-bold text-sm">
        {i18n.language.toUpperCase()}
      </p>
    </Button>
  )
}

export default TopBannerLanguageButton
