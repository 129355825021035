import { useTranslation } from 'react-i18next'
import { useFormField } from '../providers/FormFieldProvider'
import MediaUploadButton from '../../../shared/components/atoms/MediaUploadButton'
import Button from '../../../shared/components/atoms/buttons/Button'
import useIntakeFlowFileInputMutations from '../hooks/useIntakeFlowFileInputMutations'

export const FormFieldControls = () => {
  const { field: { input: { type, id, uuid } } } = useFormField()
  const { upload } = useIntakeFlowFileInputMutations()
  const { t } = useTranslation()

  if (type !== 'FILE') return null

  return (
    <MediaUploadButton onUpload={upload}>
      <Button variant="solid" className="bg-sky-blue-light text-white">
        {t('attachments.upload')}
      </Button>
    </MediaUploadButton>
  )
}

export default FormFieldControls
