import { useTranslation } from 'react-i18next'
import ConfirmationSectionPriceCheck from './ConfirmationSectionPriceCheck'
import ConfirmationSections from './ConfirmationSections'
import ActionButton from '../../Header/ActionButton'
import useFormValidation from '../../hooks/useFormValidation'

interface IConfirmationSection { }

const ConfirmationSection: React.FC<IConfirmationSection> = () => {
  const { t } = useTranslation()
  const { errors } = useFormValidation()

  return (
    <div className="flex justify-center">
      <div className="w-full flex flex-col py-11 gap-x-6 px-4 max-w-[1000px]">
        <div className={`flex flex-col text-sm
        ${errors ? 'bg-yellow-50 text-yellow-700 ' : 'bg-green-50 text-green-700  border-green-400  '}
         px-8 py-7 mb-5 space-y-4  rounded shadow`}
        >
          <div className="text-xl font-semibold mb-2">
            {!errors && t(
              'forms.all_questions_answered_new',
              'Great job! You\'ve successfully answered all the questions. It\'s time to submit the form!',
            )}
            {errors && t(
              'forms.validation_errors_found',
              'There are some validation issues!',
            )}
          </div>
          <div>
            {t(
              'forms.review_your_answers_new',
              'Kindly review your answers and make any necessary adjustments. Once you are ready, click to save the draft and then on the Send button to submit the form.',
            )}
          </div>
          {!errors && <div className="pt-4 flex justify-end"><ActionButton /></div>}
        </div>
        <div className="flex flex-col w-full ">
          <ConfirmationSectionPriceCheck />
          <ConfirmationSections />
        </div>
      </div>
    </div>
  )
}

export default ConfirmationSection
