import { useTranslation } from 'react-i18next'
import { LineItemItemAttributeType } from '../../../../../../../types/shared/LineItemDataTypes'
import RowOptions from '../../RowOptions'
import OneLineInput from './OneLineInput'

interface IOneLineInputContainer {
  row: LineItemItemAttributeType
  placeholder: string
  updateAutoSave: () => void
  firstRow: boolean
  sectionIndex: number
}

const OneLineInputContainer: React.FC<IOneLineInputContainer> = ({
  row, placeholder, updateAutoSave, firstRow, sectionIndex,
}) => {
  const { t } = useTranslation()
  const isMultiLineInput = !!row.options.length && row.type

  return (
    !isMultiLineInput
      ? (
        <div className="flex w-1/2 justify-end pl-[17px]">
          <div className="flex w-full justify-end gap-x-4">
            <OneLineInput
              row={row}
              placeholder={t(placeholder)}
              updateAutoSave={updateAutoSave}
              sectionIndex={sectionIndex}
            />
            <RowOptions
              row={row}
              firstRow={firstRow}
              sectionIndex={sectionIndex}
            />
          </div>
        </div>
      )
      : null)
}

export default OneLineInputContainer
