import React, { useCallback } from 'react'
import { isEqual } from 'lodash'
import { WorkflowBlock } from '../../../types/Workflow/WorkflowBlock'
import { getMergedDeepOverwritingArrays } from '../../../shared/utils/objectHelpers'
import useWorkflowStructureMutations from '../hooks/useWorkflowStructureMutations'
import { useWorkflow } from './WorkflowProvider'
import useDraftState from '../../../core/useDraftState'

type WorkflowBlockDraftContextType = {
    block: WorkflowBlock;
    isDirty: boolean;
    updateBlock: (block: Partial<WorkflowBlock> | ((prevBlock: WorkflowBlock) => Partial<WorkflowBlock>)) => void;
    reset: () => void;
    save: () => void;
}

const WorkflowBlockDraftContext = React.createContext({} as WorkflowBlockDraftContextType)

type WorkflowBlockDraftProviderProps = {
  block: WorkflowBlock;
}

export const WorkflowBlockDraftProvider = ({ block, children }: React.PropsWithChildren<WorkflowBlockDraftProviderProps>) => {
  const { workflow, selectedStep } = useWorkflow()
  const [draftBlock, setDraftBlock] = useDraftState(block)
  const { updateBlock: sendBlockUpdate, addStep, addBlock } = useWorkflowStructureMutations(workflow)

  const updateBlock = useCallback((blockOrUpdater: Partial<WorkflowBlock> | ((prevBlock: WorkflowBlock) => Partial<WorkflowBlock>)) => {
    setDraftBlock((prevBlock) => {
      if (typeof blockOrUpdater === 'function') {
        return getMergedDeepOverwritingArrays<WorkflowBlock>(prevBlock, blockOrUpdater(prevBlock))
      }
      return getMergedDeepOverwritingArrays<WorkflowBlock>(prevBlock, blockOrUpdater)
    })
  }, [setDraftBlock])

  const blockDraftState = React.useMemo<WorkflowBlockDraftContextType>(() => ({
    block: draftBlock,
    isDirty: !isEqual(draftBlock, block),
    updateBlock,
    reset: () => setDraftBlock(block),
    save: () => {
      if (!selectedStep) return

      if (!selectedStep.uuid) {
        addStep({ ...selectedStep, blocks: [draftBlock] })
        return
      }

      if (!draftBlock.uuid) {
        addBlock({ stepUuid: selectedStep.uuid, data: draftBlock })
        return
      }

      sendBlockUpdate(draftBlock)
    },
  }), [draftBlock, setDraftBlock, updateBlock, sendBlockUpdate, addStep, selectedStep, block])

  return (
    <WorkflowBlockDraftContext.Provider value={blockDraftState}>
      {children}
    </WorkflowBlockDraftContext.Provider>
  )
}

export default WorkflowBlockDraftProvider

export const useWorkflowBlockDraft = () => React.useContext(WorkflowBlockDraftContext)
