import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import ConfirmationModal from '../../../../../shared/components/organisms/modals/ConfirmationModal'
import { FormSubmissionType } from '../../../../../types/Forms/formTypes'
import useDeleteSubmission from './hooks/useDeleteSubmission'
import useDuplicateSubmission from '../../../../Requisition/components/layout/ReqTopbar/hooks/useDuplicateSubmission'
import Assignees from '../../../../../shared/components/organisms/AssigneesSelector/AssigneesSelector'

interface IActions {
  submission: FormSubmissionType
}

const SubmissionActions: React.FC<IActions> = ({ submission }) => {
  const { t } = useTranslation()
  const utils = useUtils()

  const { mutate: deleteMutate } = useDeleteSubmission()
  const { mutate } = useDuplicateSubmission()

  const menuItems = [
    {
      name: 'generic.archive',
      onClick: () => utils.modal.set({
        isOpen: true,
        title: t('generic.confirm'),
        content: (
          <ConfirmationModal confirmString={t('confirm.are_you_sure_delete')} onConfirm={() => deleteMutate(submission)}>
            {t('basecamp.are_you_sure_delete_submission')}
          </ConfirmationModal>),
      }),
    },
    {
      name: 'generic.duplicate',
      onClick: () => mutate(submission.uuid),
    },
  ]

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      role="button"
      className="w-5 flex flex-col items-between justify-between h-full"
      tabIndex={0}
    >
      <div className="flex items-center justify-end gap-2">
        <Menu menuItems={menuItems} />
      </div>
      <div>
        {submission.assignments.length ? <Assignees modelId={submission.uuid} model="form_submission" origin="form_submission" assignments={submission.assignments} /> : null}
      </div>
    </div>
  )
}
export default SubmissionActions
