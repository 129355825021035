import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import CoreLoadingIcon from '../../../../../../core/components/CoreLoadingIcon'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import RowCell from '../../../../../../shared/components/layout/RowCell'
import AddressFormTextInput from '../../../../../Company/components/addresses/AddressForm/AddressFormTextInput'
import useSubmitNewSupplier from '../../hooks/useSubmitNewSupplier'
import NewSupplierFormType from '../../../../../../types/Requisition/NewSupplierFormType'

const emptyValues: NewSupplierFormType = {
  name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone_number: Yup.string().min(4),
})

interface INewSupplierModal {
  initialValues?: Partial<NewSupplierFormType>
}

const NewSupplierModal: React.FC<INewSupplierModal> = ({
  initialValues,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const submitSupplier = useSubmitNewSupplier()

  const handleFormSubmit = (form: NewSupplierFormType) => {
    setIsLoading(true)
    submitSupplier(form)
    setIsLoading(false)
  }

  return (isLoading ? <CoreLoadingIcon /> : (
    <Formik
      initialValues={{ ...emptyValues, ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      validateOnBlur={false}
    >
      {({
        touched,
        values,
        handleChange,
        handleSubmit,
        errors,
      }) => {
        const constants = {
          values, errors, handleChange, touched,
        }
        return (
          <Form>
            <p className="my-6 text-sm">{t('req_coordinate.add_new_supplier')}</p>
            <div className="flex">
              <RowCell width="w-full items-center pl-0">
                <AddressFormTextInput
                  constants={constants}
                  placeholder={t('supplier_view.supplier_name')}
                  label={t('generic.name')}
                  errorKey="name"
                  className="w-full"
                  dataCy="new-supplier-name"
                />
              </RowCell>
            </div>
            <p className="my-6 text-sm">{t('req_coordinate.add_new_supplier_user')}</p>
            <div className="flex">
              <RowCell width="w-full items-center pl-0">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="Jane"
                  label={t('generic.first_name')}
                  errorKey="first_name"
                  className="w-full"
                />
              </RowCell>
              <RowCell width="w-full items-center">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="Doe"
                  label={t('generic.last_name')}
                  errorKey="last_name"
                  className="w-full"
                />
              </RowCell>
              <RowCell width="w-full items-center">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="supplier@email.com"
                  label={t('generic.email')}
                  errorKey="email"
                  className="w-full"
                />
              </RowCell>
              <RowCell width="w-full items-center pr-0">
                <AddressFormTextInput
                  constants={constants}
                  placeholder="+49 000 00000000"
                  label={t('account.company_phone_number')}
                  errorKey="phone_number"
                  className="w-full"
                />
              </RowCell>
            </div>

            <div className="justify-end w-full pt-4 flex">
              <Button
                color="lhotse-orange"
                variant="solid"
                onClick={handleSubmit}
                className="text-sm"
              >
                {t('supplier.invite_to_req')}
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
  )
}

export default NewSupplierModal
