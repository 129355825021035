import { useTranslation } from 'react-i18next'
import useIsTooltipTextTruncated from '../../../shared/components/organisms/tooltip/hooks/useIsTooltipTextTruncated'
import Tooltip from '../../../shared/components/organisms/tooltip/Tooltip'
import generateName from '../../../shared/utils/generateName'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import SupplierPreferredStar from './SupplierPreferredStar/SupplierPreferredStar'
import { ISupplierTableRow } from './SuppliersTable/SupplierTableRow/SupplierTableRow'

interface IBranchName {
  supplierBranch: ISupplierTableRow['data'] | SupplierBranchDataType
  className?: string
  disabledStar?: boolean
  onClick?: () => void
  wasDeleted?: string
}

const BranchName: React.FC<IBranchName> = ({
  supplierBranch, wasDeleted, className, disabledStar = false, onClick,
}) => {
  const { t } = useTranslation()
  const { ref, isTextTruncated } = useIsTooltipTextTruncated()

  return (
    <div className="flex gap-1 items-center">
      <button
        className={`flex gap-1 items-center ${className}`}
        type="button"
        onClick={!wasDeleted ? onClick : () => { }}
        data-cy="supplier-branch-name"
      >
        <Tooltip
          tooltip={t(generateName(supplierBranch.name))}
          showCondition={isTextTruncated}
          className="overflow-ellipsis"
        >
          <p ref={ref} className={`line-clamp-1 w-full text-left text-sm break-all leading-none ${wasDeleted && 'line-through'}`}>{t(generateName(supplierBranch.name))}</p>
        </Tooltip>
      </button>
      <SupplierPreferredStar
        supplierBranchId={supplierBranch.id}
        disabled={disabledStar}
        className="text-base"
      />
    </div>
  )
}

export default BranchName
