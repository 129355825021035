import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import ConfirmationModal from '../../../../../shared/components/organisms/modals/ConfirmationModal'
import TipTapEditor from '../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'

interface IConfirmationSectionAdvarioDeclineModal {
  handleDecline: (reason?: string) => void
}

const ConfirmationSectionAdvarioDeclineModal: React.FC<IConfirmationSectionAdvarioDeclineModal> = (
  { handleDecline },
) => {
  const { t } = useTranslation()
  const utils = useUtils()

  const [customMessage, setCustomMessage] = useState<string>('')

  const handleModalSuccess = () => {
    utils.modal.set({ isOpen: false })
    handleDecline(customMessage)
  }

  return (
    <ConfirmationModal
      onConfirm={handleModalSuccess}
      confirmString="generic.decline"
    >
      <div className="flex flex-col gap-4">
        <p>
          {t('advario.decline-provide-reason', 'Please provide a reason for declining the request')}
        </p>
        <div className="flex w-full border rounded-md h-24">
          <TipTapEditor
            onUpdate={(v) => setCustomMessage(v)}
            content=""
            editable
            placeholder="generic.decline-reason"
            expandedEditor
          />
        </div>
      </div>
    </ConfirmationModal>
  )
}

export default ConfirmationSectionAdvarioDeclineModal
