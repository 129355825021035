import { LineItemItemAttributeType } from '../../../../../../../types/shared/LineItemDataTypes'
import TipTapInputGray from '../../../../../../../shared/components/atoms/inputs/TipTapInputGray'
import useUpdateLineItem from '../../hooks/useUpdateLineItem'
import OfferFileUpload from '../../MultiLineInput/OfferFileUpload'
import PriceInput from '../PriceInput'
import YesNoRow from '../YesOrNoRow'

interface IOneLineInput {
  row: LineItemItemAttributeType
  placeholder: string
  updateAutoSave: () => void
  sectionIndex: number
}

const OneLineInput: React.FC<IOneLineInput> = ({
  row, placeholder, sectionIndex, updateAutoSave,
}) => {
  const currentLineItem = row.line_items && row.line_items[0]

  const updateLineItem = useUpdateLineItem(updateAutoSave, sectionIndex)

  if (row.type === 'yes-no') {
    return (
      <YesNoRow
        onClick={(answer: '0' | '1' | null) => {
          updateLineItem(answer, currentLineItem?.id as number, row.id, 0)
        }}
        lineItem={currentLineItem}
      />
    )
  }

  if (row.type === 'price') {
    return (
      <PriceInput
        placeholder={placeholder}
        updateLineItem={
          (value: boolean | string | null) => updateLineItem(value, currentLineItem?.id as number, row.id, 0)
        }
        row={row}
      />
    )
  }

  if (row.type === 'file') {
    return <OfferFileUpload row={row} updateAutoSave={updateAutoSave} />
  }

  return (
    <TipTapInputGray
      placeholder={placeholder}
      onBlur={(e) => {
        updateLineItem(e.editor.getHTML(), currentLineItem?.id as number, row.id, 0)
      }}
      content={currentLineItem?.value || ''}
      editorContentClassName="px-1 py-1"
      dataCy="supplier-offer-text-input"
    />
  )
}

export default OneLineInput
