import { FormQuestionType } from '../../../../../types/Forms'

export const useQuestionAnswersLimit = (question?: FormQuestionType) => {
  const { min, max } = { min: 0, max: Infinity, ...question?.__settings?.answers || { min: 1, max: 1 } }

  return {
    isSingle: max - min === 0,
    hasLimit: max !== Infinity,
    canAdd: (question?.values.length || 0) < max,
    canRemove: (question?.values.length || 0) > min,
    min,
    max,
  }
}

export default useQuestionAnswersLimit
