import { useTranslation } from 'react-i18next'
import { ValidationProperty, ValidationPropertyConfig } from '../ValidationSchemas'
import QuestionInputNumber from '../../../components/inputs/QuestionInputNumber'
import QuestionSliderSettingRow from '../../../components/inputs/QuestionSliderSettingRow'
import useValidationProperty from './hooks/useValidationProperty'
import useGetAllQuestions from '../../../../../hooks/useGetAllQuestions'
import { AnswerSelectMethod, FormQuestionType, FormQuestionValidationProperty } from '../../../../../../../types/Forms'
import { replaceByUuid } from '../../../../../../../shared/utils/arrayHelpers'
import QuestionSearchSelect from '../../../components/inputs/QuestionSearchSelect'

export type NumberPropertyProps = {
  question: FormQuestionType
  property: ValidationProperty
  config: ValidationPropertyConfig
  onUpdate: (updatedValue?: FormQuestionValidationProperty<number>) => void
  availableQuestionCondition?: (question: FormQuestionType) => boolean
}

export const NumberOrQuestionValidationProperty = ({
  question, property, config, onUpdate, availableQuestionCondition,
}: NumberPropertyProps) => {
  const { t } = useTranslation()
  const {
    currentValue, currentTarget, isChecked, answerSelector,
  } = useValidationProperty<number>(property)

  const allQuestions = useGetAllQuestions()

  const questionOptions = replaceByUuid(allQuestions, question.__uuid)
    .filter(availableQuestionCondition || (() => true))
    .map((questionOption) => ({
      label: questionOption.label || '',
      id: questionOption.__uuid,
    }))

  const currentSelection = questionOptions.find(({ id }) => id === currentTarget)

  const answerOptions: {id: AnswerSelectMethod, label: string }[] = [
    { id: 'sum', label: 'sum' }, { id: 'first_value', label: 'first answer' },

  ]
  const currentAnswerSelection = answerOptions.find(({ id }) => id === answerSelector)

  return (
    <QuestionSliderSettingRow
      title={t(`forms.validation.settings.${property}.label`)}
      description={t(`forms.validation.settings.${property}.description`)}
      onChange={() => (
        onUpdate(isChecked ? undefined : { value: config.default as number, target: undefined })
      )}
      checked={isChecked}
    >
      <div className="flex gap-x-2 flex-row flex-grow">
        <div className="w-full">
          {t('forms.validation.settings.staticValue')}
          <QuestionInputNumber
            placeholder={t(`forms.validation.settings.${property}.placehoder`)}
            value={currentValue || ''}
            precision={0}
            onChange={(e) => onUpdate({ value: parseInt(e.currentTarget.value.split('.').join(''), 10), target: undefined })}
          />
        </div>
        {questionOptions.length > 0 && (
          <>
            <div className="flex items-center">
              {t('forms.validation.settings.orQuestion')}
            </div>
            <div className="w-full flex flex-col">
              <div>
                {t('forms.validation.settings.questionTarget')}
                <QuestionSearchSelect
                  options={questionOptions}
                  currentSelection={currentSelection}
                  placeholder={t(`forms.validation.settings.${property}.question_placeholder`)}
                  onChange={({ id }) => onUpdate({ value: undefined, target: id as string, answerSelector: 'first_value' })}
                  onClear={() => onUpdate({ value: undefined, target: undefined, answerSelector: undefined })}
                  nullable
                />
              </div>
              {currentSelection && (
              <div>
                {t('forms.validation.settings.questionTarget_answerSelector')}
                <QuestionSearchSelect
                  options={answerOptions}
                  currentSelection={currentAnswerSelection}
                  placeholder={t(`forms.validation.settings.${property}.question_placeholder`)}
                  onChange={({ id }) => onUpdate({ value: undefined, target: currentSelection.id, answerSelector: id as AnswerSelectMethod })}
                />
              </div>
              )}
            </div>
          </>
        )}
      </div>

    </QuestionSliderSettingRow>
  )
}

export default NumberOrQuestionValidationProperty
