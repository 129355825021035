import { Combobox } from '@headlessui/react'
import classNames from 'classnames'
import { HTMLAttributes } from 'react'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'

export interface ISearchSelectOption {
  option: InputSearchSelectItemType
  className?: HTMLAttributes<HTMLDivElement>['className']
}

const SearchSelectOption: React.FC<ISearchSelectOption> = ({
  option, className,
}) => (
  <Combobox.Option
    as="div"
    key={option.id}
    value={option}
    className={({ active, selected }) => classNames(
      'relative cursor-pointer select-none py-2 pl-3 pr-2',
      {
        'bg-ice-blue': active || selected,
        'text-sky-blue': active,
        'text-sky-blue-light': selected,
        'font-semibold': selected,
      },
      className,
    )}
  >
    {option.dataFields ? (
      <div className={classNames('flex truncate gap-1 items-center')}>
        {Object.keys(option.dataFields).map((key) => (
          <div key={key} className="flex flex-col flex-1">
            <span className="text-sm capitalize">{option.dataFields?.[key]}</span>
          </div>
        ))}
      </div>
    ) : (
      <div className={classNames('flex truncate gap-1 items-center')}>
        {option.icon}
        {option.label}
      </div>
    )}

  </Combobox.Option>
)

export default SearchSelectOption
