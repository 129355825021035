import _ from 'lodash'
import {
  ChangeEventHandler, Dispatch, SetStateAction, useCallback,
} from 'react'
import { germanPriceStringToFloat } from '../../../constants/currencyFormat'

export default (
  minimumState: [number | undefined, Dispatch<SetStateAction<number | undefined>>],
  maximumState: [number | undefined, Dispatch<SetStateAction<number | undefined>>],
  setShouldUpdate: Dispatch<SetStateAction<string>>,
  maxValue?: number,
): ChangeEventHandler<HTMLInputElement> => {
  const [minimum, setMinimum] = minimumState
  const [maximum, setMaximum] = maximumState

  return useCallback((e) => {
    const value = germanPriceStringToFloat(e.target.value)

    if (maxValue && value >= maxValue) {
      setMaximum(maxValue)
      return setShouldUpdate(_.uniqueId())
    }

    if (minimum && value < minimum) {
      setMaximum(value)
      setMinimum(value)
    }

    if (value < 0) return setMinimum(0)

    return setMaximum(value)
  }, [maximum, minimum])
}
