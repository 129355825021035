import * as React from 'react'
import { ArrowPathIcon, CalendarIcon, UsersIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { WorkflowChartNodeTemplate } from '../../../../WorkflowChart'
import { WorkflowChartBlockNodeProps } from '../../../../../types/Workflow/WorkflowChart'
import { useWorkflow } from '../../../providers/WorkflowProvider'

export const WorkflowBuilderBlock = (node: WorkflowChartBlockNodeProps) => {
  const { t } = useTranslation()
  const { toggleBlockSelection } = useWorkflow()
  const { data: block } = node

  const handleClick = () => {
    toggleBlockSelection(block)
  }

  return (
    <WorkflowChartNodeTemplate node={node} onClick={handleClick}>
      <div className="flex flex-row gap-3 border-b border-b-ice-blue pb-2 w-full items-center">
        <div className="flex-grow-0 rounded-md p-1 border-2 border-sky-blue-light text-sky-blue-light">
          <ArrowPathIcon className="h-6" />
        </div>
        <div className="flex-grow-1 font-medium">
          {block.name}
        </div>
      </div>
      <div className="flex flex-col gap-6 text-black-light">
        <div className="flex flex-row justify-between gap-6">
          <div className="flex flex-col gap-2 w-1/2">
            <div className="flex flex-row  text-gray-400 gap-2">
              <div className="flex-grow-0 whitespace-nowrap">
                <UsersIcon className="h-5" />
              </div>
              <div className="flex-grow-1 whitespace-nowrap">
                {t('intake.flowchart.approvalNodeContent.approver_counter', 'Approver(s)')}
              </div>
            </div>
            <div className="flex flex-row">
              {block.approvers.length}
              {' '}
              {t(
                'intake.flowchart.approvalNodeContent.number_of_approvers',
                block.approvers.length > 1 ? 'approvers' : 'approver',
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 w-1/2 overflow-hidden">
            <div className="flex flex-row  text-gray-400 gap-2">
              <div className="flex-grow-0">
                <CalendarIcon className="h-5" />
              </div>
              <div className="flex-grow-1">
                {t('intake.flowchart.approvalNodeContent.deadline', 'Deadline')}
              </div>
            </div>
            <div className="flex flex-row">
              {block.reminder}
            </div>
          </div>
        </div>
      </div>
    </WorkflowChartNodeTemplate>
  )
}

export default WorkflowBuilderBlock
