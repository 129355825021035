import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import useGetRequisitionData from '../../../subModules/Definition/hooks/useGetRequisition'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import RequisitionTopbarCancelRequisitionModal from './RequisitionTopbarCancelRequisitionModal'

const RequisitionTopbarCancelRequisitionBtn: React.FC = () => {
  const utils = useUtils()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { data: requisitionData, isLoading: isLoadingReq } = useGetRequisitionData()

  if (!requisitionData) return <CoreLoadingIcon />

  const confirmDeclineOffers = () => {
    utils.modal.set({
      isOpen: true,
      title: `${t('requisition.abort_requisition')} ${requisitionData.name}`,
      content: <RequisitionTopbarCancelRequisitionModal />,
    })
  }

  if (pathname.includes('award') && !isLoadingReq && requisitionData && requisitionData.status !== 'CANCELED') {
    return (
      <Button
        variant="outlined"
        color="lhotse-orange"
        onClick={confirmDeclineOffers}
      >
        {t('requisition.abort_requisition')}
      </Button>
    )
  }

  return null
}

export default RequisitionTopbarCancelRequisitionBtn
