import { MdSend } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import useGetToken from '../../../../SupplierOffer/hooks/useGetToken'
import useGetAndSetActiveMessage from '../../../hooks/useGetAndSetActiveMessage'
import useGetMessengerUrlParams from '../../../hooks/useGetMessengerUrlParams'
import useSendMessage from '../../../hooks/useSendMessage'
import useGetAndSetActiveIntakeMessage from '../../../hooks/IntakeFlowChat/useGetAndSetActiveIntakeMessage'
import useSendIntakeChatMessage from '../../../hooks/IntakeFlowChat/useSendIntakeChatMessage'
import useIntakeId from '../../../../Intake/hooks/useIntakeId'

const SendMessageButton: React.FC = () => {
  const { t } = useTranslation()
  const { mutate: handleSendMessage } = useSendIntakeChatMessage()
  const [messageContent] = useGetAndSetActiveIntakeMessage()
  const { messageProperties: [{ files }] } = useMessengerContext()

  const modelId = useIntakeId()
  const token = useGetToken()

  const isSendDisabled = (!modelId && !token) || (!files.length && !messageContent)

  return (
    <div className="flex flex-row justify-end pb-1">
      <button
        type="button"
        className="flex items-center rounded-md px-5 py-2 mr-2 bg-sky-blue-light text-white hover:bg-sky-blue disabled:hover:brightness-100
        disabled:bg-rock-gray-light disabled:text-rock-gray focus:outline-none focus:ring focus:border-blue-300 transition-all"
        onClick={() => handleSendMessage()}
        disabled={isSendDisabled}
        data-cy="chat-send"
      >
        { t('messenger.send', 'Send') }
      </button>
    </div>
  )
}

export default SendMessageButton
