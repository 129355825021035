// REFACTOR
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../core/providers/UtilsProvider'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import Button from '../../../../../../../shared/components/atoms/buttons/Button'
import { OfferActionType } from '../../../constants/OfferViewTypes'
import useSubmitReason from './hooks/useSubmitReason'
import useGetActionModalData from './useGetOfferViewActionModalData'
import AcceptanceStateType from '../../../../../../../types/Requisition/AcceptanceStateType'
import TipTapEditor from '../../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'

interface IActionModal {
  type: OfferActionType
  invite: RequisitionInviteType
  setStatus: (status: AcceptanceStateType) => any,
}

const ActionModal: React.FC<IActionModal> = ({
  type, invite, setStatus,
}) => {
  const { t } = useTranslation()
  const utils = useUtils()

  const [modalType, setModalType] = useState(type)
  const [reason, setReason] = useState('')

  const data = useGetActionModalData(modalType, setStatus, invite, reason)

  const closeModal = () => utils.modal.set({ isOpen: false })
  const submitReason = useSubmitReason(data, modalType)

  return (
    <div data-cy="request-changes-modal-content">
      <div className="flex flex-col w-full gap-y-4">
        <p>{data.text}</p>
        {type !== 'accept' && (
          <div className="border h-24 rounded-md">
            <TipTapEditor
              expandedEditor
              onUpdate={(e) => setReason(e)}
              dataCy="offer-view-action-modal-input"
            />
          </div>
        )}
      </div>
      <div className="flex pt-4 w-full justify-between items-center">
        <p className="text-sm text-gray-500">{data.finePrint}</p>
        <div className="gap-x-8 flex items-center">
          <button type="button" onClick={closeModal}>
            {t('generic.cancel')}
          </button>
          {modalType === 'decline' && (
            <button type="button" onClick={() => setModalType('changes')} className="font-semibold">
              {t('req_award.request_changes_instead')}
            </button>
          )}
          <Button
            className="font-semibold"
            variant="solid"
            color={data.buttonColor}
            onClick={() => submitReason(reason)}
            dataCy="offer-view-action-modal-submit"
          >
            {data.buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ActionModal
