import { useTranslation } from 'react-i18next'
import useFilteredSubmissions from '../../hooks/useFilteredSubmissions'
import LhotseHomeStat from './LhotseHomeStat'

const LhotseHomeStats = () => {
  const { t } = useTranslation()

  const {
    draftSubmissions, filteredSubmissions, setFilter, getFilter,
  } = useFilteredSubmissions()

  return (
    <div className="grid grid-cols-3 gap-4">
      <LhotseHomeStat name={t('generic.intake-flows', 'Intake Flows')} value={filteredSubmissions?.length || 0} />
      <LhotseHomeStat
        name={t('generic.drafts', 'Drafts')}
        filterActive={getFilter('status') === 'DRAFT'}
        value={draftSubmissions?.length || 0}
        filterAction={() => setFilter({ status: getFilter('status') === 'DRAFT' ? undefined : 'DRAFT' })}
      />
      <LhotseHomeStat name={t('generic.action-required', 'Action required')} value="-" />
    </div>
  )
}

export default LhotseHomeStats
