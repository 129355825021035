import AddressType from '../../../../../types/shared/AddressType'
import { RequisitionDataType } from '../../../../../types/shared/RequisitionDataTypes'
import {
  apiGet, apiGetData, apiPostData, apiPut,
} from '../../../../../core/utils/api/generic'
import { CategoryType } from '../../../../../types/Requisition/CategoryType'
import { ApprovalRuleType } from '../constants/ApprovalTypes'
import { AdditionalDataFieldType } from '../constants/editorTypes'

export const apiGetRequisitionData = async (reqId: string) => apiGetData<RequisitionDataType>(`/requisitions/${reqId}`)
export const apiGetCategories = () => apiGet<CategoryType[]>('/categories')
export const getCompanyAddresses = () => apiGetData<AddressType[]>('/addresses/company')
export const getAdditionalDataFields = () => apiGetData<AdditionalDataFieldType[]>('/additional-data-fields')
export const updateRequisition = (reqId: string, payload: Partial<RequisitionDataType & { delivery_address_id: number | null } & { category_id: number | null }>) => apiPut(`/requisitions/${reqId}`, payload)
export const getAdditionalFields = () => apiGetData<AdditionalDataFieldType[]>('/additional-data-fields')

export const apiGetApprovalRules = (reqId: string) => apiGetData<ApprovalRuleType[]>(`/requisitions/${reqId}/approval-rules`)
export const apiPostApproval = (requisitionId: string | number, approvalRuleId: number): Promise<any> => apiPostData(`/requisitions/${requisitionId}/approval-rules/${approvalRuleId}`)
