import useGetRequisitionData from '../../Definition/hooks/useGetRequisition'
import useGetOrderedInvites from '../hooks/useGetOrderedInvites'
import SupplierHeader from './SupplierHeader/SupplierHeader'
import OfferCarousel from './OfferCarousel/OfferCarousel'
import { useOfferVisibilityContext } from '../providers/OfferVisibilityProvider'

interface IOfferViewHeaderRow {
  useLastCallState: [
    number[] | undefined,
    React.Dispatch<React.SetStateAction<number[] | undefined>>
  ],
}

const OfferViewHeaderRow: React.FC<IOfferViewHeaderRow> = ({ useLastCallState }) => {
  const { data } = useGetRequisitionData()
  const invites = useGetOrderedInvites()

  // Get offer visibility state from context
  const { visibleOffers } = useOfferVisibilityContext()

  return (
    <div className="flex flex-col justify-start sticky pr-4 bg-gray-50 pt-4 max-w-full lg:ml-[10px]">
      <OfferCarousel currentView="matrix">
        {invites?.map((invite, index) => (
          <SupplierHeader
            key={invite.id}
            invite={invite}
            useLastCallState={useLastCallState}
            isPurchased={data?.status === 'PURCHASED'}
            isFullyVisible={visibleOffers.includes(index)}
          />
        ))}
      </OfferCarousel>
    </div>
  )
}

export default OfferViewHeaderRow
