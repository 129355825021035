import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useSubmitUserData from '../hooks/useSubmitUserData'
import useValidatePassword from './FormikPasswordInput/useValidatePassword'

interface IJoinFormSubmitButton {
  password: string
  setShowErrors: Dispatch<SetStateAction<boolean>>
}

const JoinFormSubmitButton: React.FC<IJoinFormSubmitButton> = ({
  password, setShowErrors,
}) => {
  const { t } = useTranslation()
  const { isLoading } = useSubmitUserData()

  const { canSubmit } = useValidatePassword(password)

  return (
    <Button
      variant="solid"
      color="lhotse-orange"
      type="submit"
      onClick={() => (canSubmit ? {} : setShowErrors(true))}
      loading={isLoading}
      dataCy="joinFormSubmitButton"
    >
      {t('generic.save')}
    </Button>
  )
}

export default JoinFormSubmitButton
