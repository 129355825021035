import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import useGetOffer from '../../../hooks/useGetOffer'
import BannerModal from './BannerModal'

const BannerDeclined: React.FC = () => {
  const { t } = useTranslation()
  const utils = useUtils()

  const { data: offerData } = useGetOffer()
  const reason = offerData?.status_timeline[0]?.reason

  const rejectedOfferClassNames = `flex text-left py-3 px-8 bg-red-300 bg-opacity-60 text-red-600 w-full ${reason ? 'cursor-pointer' : 'cursor-default'}`

  return reason
    ? (
      <button
        type="button"
        className={rejectedOfferClassNames}
        onClick={() => utils.modal.set({
          isOpen: true,
          title: t('supplier_offer.offer_declined'),
          content: <BannerModal reason={reason} />,
        })}
      >
        {t('supplier_offer.offer_rejected_with_note')}
      </button>
    )
    : <p className={rejectedOfferClassNames}>{t('supplier_offer.offer_rejected')}</p>
}

export default BannerDeclined
