import { FaPercentage } from 'react-icons/fa'
import {
  MdTitle, MdOutlineModeComment, MdOutlineToggleOn,
  MdChecklist, MdOutlineDescription, MdSquareFoot, MdTag,
  MdEuroSymbol, MdEvent, MdRadioButtonUnchecked, MdPinDrop,
} from 'react-icons/md'
import ReqDefRowType from '../../../../../types/Requisition/rowType'

const possibleTypes: ReqDefRowType[] = ['text', 'measurement', 'file', 'percentage', 'yes-no', 'multi-select', 'number', 'price', 'date']

export const requisitionDefinitionEditorRowInfoPicker = (
  type: ReqDefRowType | undefined,
) => {
  if (type === undefined) return {}

  const rowInfo: any = {
    icon: null,
    placeholder: '',
  }

  switch (type) {
    case 'text':
      rowInfo.icon = MdTitle
      rowInfo.placeholder = 'req_def.provide_text'
      break
    case 'comment':
      rowInfo.icon = MdOutlineModeComment
      rowInfo.placeholder = 'req_def.provide_comment'
      break
    case 'date':
      rowInfo.icon = MdEvent
      rowInfo.placeholder = 'req_def.provide_date'
      break
    case 'measurement':
      rowInfo.icon = MdSquareFoot
      rowInfo.placeholder = 'req_def.provide_measurement'
      break
    case 'file':
      rowInfo.icon = MdOutlineDescription
      rowInfo.placeholder = 'req_def.provide_file'
      break
    case 'percentage':
      rowInfo.icon = FaPercentage
      rowInfo.placeholder = 'req_def.provide_percentage'
      break
    case 'yes-no':
      rowInfo.icon = MdOutlineToggleOn
      rowInfo.placeholder = 'req_def.provide_yes_no'
      break
    case 'multi-select':
      rowInfo.icon = MdChecklist
      rowInfo.placeholder = 'req_def.provide_multi_select'
      break
    case 'number':
      rowInfo.icon = MdTag
      rowInfo.placeholder = 'req_def.provide_number'
      break
    case 'price':
      rowInfo.icon = MdEuroSymbol
      rowInfo.placeholder = 'req_def.provide_price'
      break
    case 'options':
      rowInfo.icon = MdRadioButtonUnchecked
      rowInfo.placeholder = 'Options'
      break
    case 'address':
      rowInfo.icon = MdPinDrop
      rowInfo.placeholder = 'req_def.provide_address'
      break
    default:
      break
  }
  return rowInfo
}

export const requisitionDefinitionGetAllDropdownOptions = () => possibleTypes.map((type) => {
  const { icon } = requisitionDefinitionEditorRowInfoPicker(type)
  return [icon, type]
})

export const getRemainingDropdownOptions = (
  type: ReqDefRowType,
) => requisitionDefinitionGetAllDropdownOptions().filter(
  (val) => val[1] !== type,
)
