// REFACTOR
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssignmentType } from '../../../../types/shared/AssignmentTypes'
import InputGrayUnderline from '../../atoms/inputs/underlined/InputGrayUnderline'

import AssignableUserList from './AssignableUserList'
import NewAssigneeComponent from './NewAssignee'
import useUsers from '../../../hooks/queries/useUsers'

interface IUsersList {
  onClick: (email: string) => void
  localAssignments: AssignmentType[] | undefined
}

const UsersList: React.FC<IUsersList> = ({ onClick, localAssignments }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const { data: users } = useUsers()

  const existingAssignees = localAssignments?.map((assignment) => assignment.user.email)

  const unassignedUsers = users?.filter((user) => !existingAssignees?.includes(user.email))

  const displayedUsers = search
    ? unassignedUsers?.filter((user) => {
      const firstName = user.first_name?.toLowerCase()
      const lastName = user.last_name?.toLowerCase()
      const email = user.email?.toLowerCase()
      const lowercaseSearch = search.toLowerCase()

      return firstName?.startsWith(lowercaseSearch)
        || lastName?.startsWith(lowercaseSearch)
        || email?.startsWith(lowercaseSearch)
    })
    : unassignedUsers

  const createAssignment = (email: string) => {
    onClick(email)
    setSearch('')
  }

  return (
    <div className="flex flex-col w-full gap-2">
      <InputGrayUnderline
        placeholder={t('generic.enter_a_name')}
        handleChange={(v: string) => setSearch(v)}
        value={search}
        dataCy="assignee-name-search"
        name="assignee-name-search"
      />
      {displayedUsers?.length || search.length === 0
        ? <AssignableUserList onClick={createAssignment} displayedUsers={displayedUsers} />
        : <NewAssigneeComponent onClick={() => createAssignment(search)} />}
    </div>
  )
}

export default UsersList
