import {
  FormQuestionType, SwitchLogicAdditionalCase, SwitchLogicCase,
} from '../../../../../../../../../types/Forms'
import LogicComparatorDateInput from './LogicComparatorDateInput'
import LogicComparatorTextInput from './LogicComparatorTextInput'
import LogicComparatorDropdown from './LogicComparatorDropdown'
import LogicAddressValue from './LogicAddressValue'

type LogicComparatorDropdownProps = {
  question?: FormQuestionType
  logicCase: SwitchLogicCase | SwitchLogicAdditionalCase
  onChange: (value: any) => void
}

const LogicConditionValue = ({
  logicCase, onChange, question,
}: LogicComparatorDropdownProps) => {
  if (question?.type === 'TEXT' || question?.type === 'TEXT_AREA' || question?.type === 'NUMBER') {
    return (
      <LogicComparatorTextInput
        value={logicCase.value as string}
        onChange={({ id: value }) => onChange(value)}
      />
    )
  }

  if (question?.type === 'DATE') {
    return (
      <LogicComparatorDateInput
        value={logicCase.value as string}
        onChange={({ label: value }) => onChange(value)}
      />
    )
  }

  if (logicCase.comparisonType === 'empty' || logicCase.comparisonType === 'not_empty') {
    return null
  }

  if (question?.type === 'ADDRESS' && (logicCase.comparisonType === 'equal' || logicCase.comparisonType === 'not_equal')) {
    return (
      <LogicAddressValue
        value={logicCase.value as unknown as number}
        onChange={({ id: value }) => {
          onChange(value)
        }}
        question={question}
      />
    )
  }

  return (
    <LogicComparatorDropdown
      value={logicCase.value as string}
      onChange={({ id: value }) => onChange(value)}
      question={question}
    />
  )
}

export default LogicConditionValue
