import mixpanel from 'mixpanel-browser'
import { useLocation } from 'react-router-dom'

import { useCallback } from 'react'
import { useLocalStorage } from '../providers/LocalStorageProvider'
import { getLocalInfo } from '../services/userService'
import { TrackingEvent } from '../../config/analytics'
import { IS_DEVELOPMENT } from '../../shared/constants/environments'

export const useTracking = () => {
  const { pathname } = useLocation()
  const [getLocalStorage] = useLocalStorage()
  const { enabled } = getLocalStorage('tracking', { enabled: true })
  const { id: userId } = getLocalInfo()

  return useCallback((event: TrackingEvent, properties: Record<string, string | number> = {}) => {
    const props = {
      userId,
      pathname,
      ...properties,
    }

    if (IS_DEVELOPMENT || !enabled) {
      return console.log('Tracked: ', { event, ...props })
    }

    return mixpanel.track(event, { ...props })
  }, [pathname, enabled, userId])
}

export default useTracking
