import { Dispatch, SetStateAction } from 'react'
import CoreLoadingIcon from '../../CoreLoadingIcon'
import ActionPanelHelpView from './ActionPanelHelpView'
import ActionPanelNoResults from './ActionPanelNoResults'
import { useFilterActionPanelData } from '../ActionPanelUtils'
import useSetRequisitionStatus from '../hooks/useSetRequisitionStatus'
import ActionPanelResultsFound from './ActionPanelResultsFound'

interface IActionPanelResults {
  rawQuery: string
  setOpen: Dispatch<SetStateAction<boolean>>
}

const ActionPanelResults: React.FC<IActionPanelResults> = ({
  rawQuery, setOpen,
}) => {
  const { mutate: setStatus, isLoading } = useSetRequisitionStatus(setOpen)

  const filterData = useFilterActionPanelData(setStatus)
  const filteredActions = filterData(rawQuery)

  return (
    <>
      {isLoading && <CoreLoadingIcon />}
      <ActionPanelResultsFound filteredActions={filteredActions} isLoading={isLoading} />
      {rawQuery === '?' && <ActionPanelHelpView />}
      {rawQuery !== '' && rawQuery !== '?' && filteredActions.length === 0 && (
        <ActionPanelNoResults />
      )}
    </>
  )
}

export default ActionPanelResults
