/* eslint-disable no-continue */
import { useMemo } from 'react'
import { FormQuestionType } from '../../../../../../../types/Forms'
import checkIfAnswerFulfillsRequirement, { FormLogicQuestionType } from './checkIfAnswerFulfillsRequirement'
import generateLogicTree from './generateLogicTree'
import { useFormData } from '../../../FormDataProvider'

export default () => {
  const { form } = useFormData()

  const allQuestions = form?.sections?.flatMap(
    (formSections) => formSections.fields,
  ) || []

  const findAllowedIndex = (currentIndex: number, uuid?: string | null) => {
    const foundIndex = allQuestions.slice(currentIndex).findIndex((question) => uuid === question.__uuid) + currentIndex
    return foundIndex !== -1 ? foundIndex : allQuestions.length
  }

  const questionToLogicTest = (question: FormQuestionType): FormLogicQuestionType => ({
    questionUUID: question.__uuid,
    questionType: question.type,
    switchLogic: question.__logic?.switch,
    question,
  })

  const analyzeQuestions = () => {
    const enabledQuestionIds: string[] = []

    if (!allQuestions.length) return []

    const { generateTree } = generateLogicTree(allQuestions)
    const tree = generateTree()

    let currentStep = 0

    while (currentStep !== -1 && currentStep < tree.length) {
      const {
        question: {
          __uuid, values, __logic,
        },
        nextQuestion, hasLogic, isLoopTrigger,
      } = tree[currentStep]

      enabledQuestionIds.push(__uuid)

      if (!hasLogic) {
        currentStep = nextQuestion[0] || 0
        continue
      }

      if (isLoopTrigger) { currentStep = nextQuestion[0] || 0; continue }

      const matchingCase = __logic?.switch?.cases
      // eslint-disable-next-line no-loop-func
        .find((condition) => {
          const baseConditionMet = checkIfAnswerFulfillsRequirement(
            condition,
            questionToLogicTest(tree[currentStep].question),
            values,
          )

          if (!condition.additionalConditions) return baseConditionMet

          const allAdditionalConditionsMet = condition.additionalConditions?.every((additionalCondition) => {
            // Scope to current section when question within loop
            // All questions until here and then last occurance
            const qustionsUntilHere = allQuestions.slice(0, currentStep)
            const sourceQuestion = qustionsUntilHere
              .filter((q) => q.__uuid.startsWith(additionalCondition.source)).pop()

            if (!sourceQuestion || !sourceQuestion?.values) {
              return false
            }
            const conditionIsMet = checkIfAnswerFulfillsRequirement(
              additionalCondition,
              questionToLogicTest(sourceQuestion),
              sourceQuestion?.values,
            )

            return conditionIsMet
          })

          return baseConditionMet && allAdditionalConditionsMet
        })

      const nextQuestionUUID = matchingCase?.target || __logic?.switch?.default

      if (!nextQuestionUUID) {
        currentStep += 1
        continue
      }

      currentStep = findAllowedIndex(currentStep, nextQuestionUUID)
    }

    return enabledQuestionIds
  }

  return useMemo(analyzeQuestions, [form?.sections])
}
