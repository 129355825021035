import { Dispatch, SetStateAction } from 'react'
import { ArrayElement } from '../../../../../types/shared/CoreTypes'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import useCreateSupplierInvite from '../../../../Requisition/subModules/Coordinate/hooks/useCreateSupplierInvite'
import SupplierUserType from '../../../../../types/Suppliers/SupplierUserType'
import usePostMainSupplierUser from '../../../../SupplierDetails/hooks/usePostMainSupplierUser'
import useDeleteUserMutation from './useDeleteUserMutation'

const useGetUserMenuItems = (
  setEditMode: Dispatch<SetStateAction<boolean>>,
  user: SupplierUserType,
) => {
  const supplierId = user.supplier_branch.supplier_id as number
  const supplierBranchId = user.supplier_branch.id
  const { mutate: handleInviteSupplier } = useCreateSupplierInvite()
  const deleteUserMutation = useDeleteUserMutation(supplierId, supplierBranchId, user)
  const { mutate: postMainSupplierUser } = usePostMainSupplierUser(supplierBranchId)
  const requisitionId = useGetRequisitionId()

  const menuItems = [
    {
      name: 'generic.edit',
      onClick: () => {
        setEditMode(true)
      },
    },
    {
      name: 'generic.delete',
      onClick: () => { deleteUserMutation.mutate(user.id) },
    },
    {
      name: 'req_coordinate.invite_to_req',
      onClick: () => handleInviteSupplier({ supplierUserId: user.id }),
      disabled: !requisitionId,
      dataCy: 'invite-to-req-button',
    },
    {
      name: 'suppliers.set_main_supplier_contact',
      onClick: () => postMainSupplierUser(user.id),
      disabled: !!user.main,
    },
  ]

  return menuItems.filter((item) => !item.disabled)
}

export default useGetUserMenuItems

export type supplierModalUserMenuItemType = ArrayElement<ReturnType<typeof useGetUserMenuItems>>
