import { useTranslation } from 'react-i18next'
import { MdLocationOn } from 'react-icons/md'
import AddressType from '../../../../../types/shared/AddressType'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../shared/components/atoms/SharedTable/TableRow'
import Badge from '../../../../../shared/components/atoms/tags/Badge'
import Tooltip from '../../../../../shared/components/organisms/tooltip/Tooltip'
import { INodeOrStringChildren } from '../../../../../types/shared/GeneralTypes'

interface IAddressesListRowContent extends INodeOrStringChildren {
  address: AddressType
}

const AddressesListRowContent: React.FC<IAddressesListRowContent> = ({ address, children }) => {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell first>
        <div className="flex gap-2 items-center">
          <MdLocationOn className="text-gray-400 text-2xl" />
          <div className="truncate max-w-xs 2xl:max-w-md ">
            {address?.name}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <Tooltip
          tooltip={address?.street}
          showCondition={(address?.street)?.length > 25}
        >
          <div className="truncate">
            {address?.street}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          tooltip={`${address?.zip} ${address?.city}`}
          showCondition={(`${address?.zip} ${address?.city}`).length > 25}
        >
          <div className="truncate max-w-2xs">
            {address?.zip}
            {' '}
            {address?.city}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          tooltip={address?.country?.name!}
          showCondition={(address?.country?.name!)?.length > 25}
        >
          <div className="line-clamp-1">
            {address?.country?.name!}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        {address?.public_address ? t('address.public') : t('address.private')}
      </TableCell>
      <TableCell>
        {address?.main && <Badge><div className="whitespace-nowrap">{t('suppliers.company_address_tag')}</div></Badge>}
      </TableCell>
      <TableCell last>
        {children}
      </TableCell>
    </TableRow>
  )
}

export default AddressesListRowContent
