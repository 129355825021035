import { useTranslation } from 'react-i18next'
import TableHead from '../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../shared/components/atoms/SharedTable/TableHeader'

type IProductSectionOffersTableHead = {
}

const ProductSectionOffersTableHead: React.FC<IProductSectionOffersTableHead> = () => {
  const { t } = useTranslation()

  return (
    <TableHead>
      <TableHeader>
        {t('offers.offer_id', 'Offer Id')}
      </TableHeader>
      <TableHeader>
        {t('offers.vendor', 'Vendor')}
      </TableHeader>
      <TableHeader>
        {t('offers.total_price', 'Total Net Price')}
      </TableHeader>
      <TableHeader />
    </TableHead>
  )
}

export default ProductSectionOffersTableHead
