import { TrashIcon } from '@heroicons/react/24/outline'
import { t } from 'i18next'
import Button from './Button'
import Tooltip from '../../organisms/tooltip/Tooltip'

interface IDeleteButtonProps {
  deleteFunction: () => void,
  hasTooltip?: boolean,
  tooltipText?: string,
}
const DeleteButton: React.FC<IDeleteButtonProps> = ({ deleteFunction, tooltipText = '', hasTooltip = false }) => (
  <Tooltip
    tooltip={tooltipText}
    showCondition={hasTooltip}
    className="flex items-center justify-center h-full"
  >
    <Button
      className="text-red-light hover:text-red-600 transition-colors px-2"
      onClick={deleteFunction}
    >
      <TrashIcon className="h-5" />
    </Button>
  </Tooltip>
)

export default DeleteButton
