import { debouncedSearchResponseType } from '../../../../../core/hooks/useDebouncedQuery'
import { apiPost, apiGetData, apiGet } from '../../../../../core/utils/api/generic'
import { RequisitionSearchDataType } from '../../../../../types/Requisition/RequisitionSearchResultsDataTypes'
import { UnifiedElementsType } from '../../../../../types/Requisition/UnifiedElementsType'
import { MercateoItemType } from '../../../../../types/Requisition/MercateoItemType'

export const fetchUnifiedElements = async (query: UnifiedElementsType): Promise<RequisitionSearchDataType> => apiPost('/full-search', query)
export const apiGetCatalogItems = async (query: debouncedSearchResponseType): Promise<RequisitionSearchDataType> => apiPost(`/unite?search=${query.query}`)
export const apiGetSearchCatalogItems = async (query: string): Promise<RequisitionSearchDataType> => apiGet(`/unite?search=${query}`)

export const getCatalogItem = (id: string) => apiGetData<MercateoItemType>(`/unite/items/${id}`)

export const getUniteCatalogItem = (id: string) => apiGetData(`/unite/items/${id}`)
