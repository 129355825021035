import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import Tag from './Tag'

interface ILastCallTag {
  invite: RequisitionInviteType
}

const LastCallTag: React.FC<ILastCallTag> = ({
  invite,
}) => {
  const { t } = useTranslation()

  return invite.invited_to_last_call
    ? (
      <Tag
        text={t('award.last_call')}
        className="bg-yellow-500 text-yellow-600"
      />
    )
    : null
}

export default LastCallTag
