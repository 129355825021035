import { useFormField } from '../providers/FormFieldProvider'
import SupplierBranchDataType from '../../../types/Suppliers/SupplierBranchDataType'
import SupplierField from '../../Forms/components/fields/SupplierField'

export const FormFieldSupplier = () => {
  const { field: { value, disabled }, setValues } = useFormField()

  const handleAdd = (newSupplier: SupplierBranchDataType) => {
    setValues({ __uuid: newSupplier.id.toString(), value: newSupplier.name })
  }

  return (
    <SupplierField
      selected={value || undefined}
      onSelect={(selectedSupplier) => setValues(selectedSupplier)}
      onAdd={handleAdd}
      disabled={disabled}
    />
  )
}

export default FormFieldSupplier
