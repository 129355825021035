import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import InfiniteScrollWithScroller from '../../../../shared/components/organisms/InfiniteScroll/InfiniteScrollWithScroller'
import useGetRequisitions from '../../hooks/useGetRequisitions'
import BasecampNoResults from './NoResults'
import Requisition from './Requisition'
import useRequisitionFiltersApiValues from './Filters/hooks/useRequisitionFiltersApiValues'

const RequisitionsTable = () => {
  const filterApiValues = useRequisitionFiltersApiValues()

  const {
    data: requisitionData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetRequisitions(filterApiValues)

  if (isLoading) return <CoreLoadingIcon />
  if (!requisitionData?.pages && !isFetchingNextPage && !isLoading) return <BasecampNoResults />

  return (
    <InfiniteScrollWithScroller
      data={requisitionData?.pages || []}
      getMoreData={fetchNextPage}
      rowComponent={Requisition}
      hasNextPage={hasNextPage}
    />
  )
}

export default RequisitionsTable
