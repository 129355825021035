import AddressQuestion from '../QuestionTypes/AddressQuestion'
import DateQuestion from '../QuestionTypes/DateQuestion'
import FileQuestion from '../QuestionTypes/FileQuestion'
import LongQuestion from '../QuestionTypes/LongQuestion'
import MultiSelectQuestion from '../QuestionTypes/MultiSelectQuestion'
import NumberQuestion from '../QuestionTypes/NumberQuestion'
import SearchSelectQuestion from '../QuestionTypes/SearchSelectQuestion'
import SelectQuestion from '../QuestionTypes/SelectQuestion'
import ShortQuestion from '../QuestionTypes/ShortQuestion'
import SupplierQuestion from '../QuestionTypes/SupplierQuestion'
import PriceQuestion from '../QuestionTypes/PriceQuestion'
import KeyValuesQuestion from '../QuestionTypes/KeyValuesQuestion'
import ValuesDefinition from '../QuestionTypes/ValuesDefinition/ValuesDefinition'

const QuestionDetails: React.FC = () => (
  <div className="rounded-lg flex flex-col gap-y-4 w-full">
    <ShortQuestion />
    <LongQuestion />
    <FileQuestion />
    <DateQuestion />
    <MultiSelectQuestion />
    <SelectQuestion />
    <SupplierQuestion />
    <NumberQuestion />
    <PriceQuestion />
    <AddressQuestion />
    <SearchSelectQuestion />
    <KeyValuesQuestion />
    <ValuesDefinition />
  </div>
)

export default QuestionDetails
