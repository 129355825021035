import { PlusIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import Collapsible from '../../../../../../shared/components/atoms/Collapsible/Collapsible'
import Label from '../../../../../../shared/components/atoms/Label'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import DeleteButton from '../../../../../../shared/components/atoms/buttons/DeleteButton'
import EmptyState from '../../../../../../shared/components/organisms/EmptyState/EmptyState'
import TipTapEditor from '../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import useAutoSave from '../../../../utils/useAutoSave'
import useIsReqEditable from '../../../../utils/useIsReqEditable'
import useGetReqSections from '../../../Coordinate/hooks/useGetReqSections'
import { ReqDefEditorSectionType } from '../../constants/editorTypes'
import { SECTION_TYPE_PRODUCT } from '../../constants/sectionKeys'
import useAddSection from '../../hooks/useAddSection'
import { putSectionTitle } from '../../services/editorService'
import useDeleteSection from '../ReqDefContainer/EditorSection/ItemAttributeSection/hooks/useDeleteSection'
import MetaTable from '../Shared/MetaTable'
import SupplierQuestions from '../Shared/SupplierQuestions'
import ProductDetailsFiles from './ProductDetailsFiles'
import ProductDetailsQuantity from './ProductDetailsQuantity'
import ProductDetailsRequirements from './ProductDetailsRequirements'

const ProductDetails = () => {
  const { t } = useTranslation()

  const { data: sectionsData, isLoading, refetch } = useGetReqSections()
  const { editable: isEditable } = useIsReqEditable()
  const { mutate: updateSectionDescription } = useMutation(
    ({ sectionId, description }: { sectionId: number, description: string }) => putSectionTitle(sectionId, description),
  )
  const addSection = useAddSection()

  const shouldBeVisibleOnRequisitionDefinition = (
    { section_type }: ReqDefEditorSectionType,
  ) => SECTION_TYPE_PRODUCT === section_type!

  const [, updateAutoSave] = useAutoSave()
  const deleteSection = useDeleteSection(updateAutoSave)

  const sectionsEmpty = _.isEmpty(sectionsData?.filter(shouldBeVisibleOnRequisitionDefinition))

  return (
    <Collapsible title={t('requisition.product_details', 'Product Details')} classNames="text-3xl">
      <div className="flex flex-col space-y-6">
        {!isLoading && sectionsEmpty ? <EmptyState onClick={() => addSection()} /> : null}
        {sectionsData?.filter(shouldBeVisibleOnRequisitionDefinition).map((section, index) => (
          <Collapsible
            key={section.id}
            title={`${t('requisition.line_item', 'Line Item')} #${index + 1}`}
            classNames="text-xl"
            extraButtons={isEditable
              && (
                <DeleteButton
                  deleteFunction={() => deleteSection(section.id)}
                  hasTooltip
                  tooltipText={t('requisition.delete_line_item', 'Delete this line item')}
                />
              )}
          >
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col space-y-2">
                <Label label={t('requisition.name_of_line_item', 'Name of Line Item')} />
                <TipTapEditor
                  editable={isEditable}
                  content={section.description}
                  onBlur={(editor) => updateSectionDescription({ sectionId: section.id, description: editor.editor.getHTML() })}
                />
              </div>

              <ProductDetailsQuantity attributes={section?.item_attributes} section={section} disabled={!isEditable} />

              <ProductDetailsRequirements attributes={section?.item_attributes} section={section} disabled={!isEditable} />

              {/* <Label label="Delivery Address" />
              <AddressSelect /> */}

              <ProductDetailsFiles section={section} disabled={!isEditable} refreshSectionData={refetch} />

              {/* <Label label="Metadata" /> */}
              <SupplierQuestions
                section={section}
                attributes={section?.item_attributes}
                isLoading={isLoading}
                disabled={!isEditable}
              />

              <MetaTable metaData={section?.meta_data_fields} />
            </div>
          </Collapsible>
        ))}
      </div>
      <div className="w-full justify-end flex mt-12">
        {isEditable && !sectionsEmpty ? (
          <Button className="btn-primary bg-sky-blue-light hover:bg-sky-blue transition-colors" onClick={() => addSection()}>
            <PlusIcon className="h-5" />
            {t('requisition.add_prod_or_service', 'Add Product or Service')}
          </Button>
        ) : null}
      </div>
    </Collapsible>

  )
}
export default ProductDetails
