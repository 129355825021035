import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'

interface props {
  setSubmitted: Dispatch<SetStateAction<boolean>>
}
const NoInstanceFound = ({ setSubmitted }: props) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-end">
      <p>
        {t('login.no_instance')}
      </p>
      <Button
        color="lhotse-orange"
        variant="solid"
        className="mt-4"
        onClick={() => setSubmitted(false)}
      >
        {t('login.use_different_email')}
      </Button>
    </div>
  )
}

export default NoInstanceFound
