import { useQuery } from 'react-query'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import useGetToken from '../../SupplierOffer/hooks/useGetToken'
import { apiGetChats } from '../service'

export default () => {
  const requisitionId = useGetRequisitionId()
  const token = useGetToken()

  return useQuery(['ALL_CHATS', requisitionId], () => apiGetChats(requisitionId), { enabled: !token })
}
