import classNames from 'classnames'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { INodeOrStringChildren } from '../../../../types/shared/GeneralTypes'
import { ColorStyle } from '../../../../types/shared/Styles'
import Button from '../buttons/Button'

export type BadgeProps = INodeOrStringChildren & Partial<{
  txtColor: ColorStyle
  bgColor: ColorStyle
  txtSize: string
  rounded: string
  className: string
  dataCy: string
  onClick?: (event: React.MouseEvent) => void
  onRemove?: () => void
}>

const Badge = ({
  className,
  children, dataCy,
  bgColor = 'ice-blue',
  txtColor = 'sky-blue-light',
  txtSize = 'xs',
  rounded = 'rounded-full',
  onClick,
  onRemove,
}: BadgeProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    onClick={onClick}
    className={
      classNames(
        'flex items-center px-5 py-0.5 group relative',
        {
          [`bg-${bgColor}`]: !!bgColor,
          [`text-${txtColor}`]: !!txtColor,
          [`text-${txtSize}`]: !!txtSize,
        },
        rounded,
        className,
      )
    }
    data-cy={dataCy}
  >
    {children}
    {onRemove && (
    <Button
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onRemove()
      }}
      className="group/controls hidden hover:stroke-2 group-hover:flex absolute bg-gray-600 bg-opacity-75 right-0 px-1 h-full items-center rounded rounded-l-none"
    >
      <XMarkIcon className="h-4 group-hover/controls:stroke-[3]" />
    </Button>
    )}
  </div>
)

export default Badge
