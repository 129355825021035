import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import SupplierUserName from '../../../../../Suppliers/components/SupplierProfile/SupplierUserName'
import useOpenSupplierDetailsModal from '../../../../../SupplierDetails/hooks/useOpenSupplierDetailsModal'

interface ISupplierName {
  invite: RequisitionInviteType
}

const SupplierName: React.FC<ISupplierName> = ({
  invite,
}) => {
  const supplierBranch = invite?.supplier_user?.supplier_branch
  const openModal = useOpenSupplierDetailsModal(supplierBranch?.id)

  return invite?.supplier_user?.email
    ? (
      <SupplierUserName
        className="flex gap-x-1 text-gray-400 items-center pl-0.5 mb-2 text-xs"
        supplierUser={invite.supplier_user}
        onClick={openModal}
      />
    )
    : <p className="line-clamp-1 leading-none text-left text-sm mb-2">-</p>
}

export default SupplierName
