import { FormQuestionValue } from '../../../../../types/Forms'
import useGetFormSubmission from '../../hooks/useGetFormSubmission'
import AddressField from '../../../components/fields/AddressField'

type AddressQuestionInputProps = {
  answer: FormQuestionValue
  onUpdate: (updated: FormQuestionValue) => void
}

export const AddressQuestionInput: React.FC<AddressQuestionInputProps> = ({
  answer, onUpdate,
}) => {
  const { data: submissionFormData } = useGetFormSubmission()

  return (
    <AddressField answer={answer} onUpdate={onUpdate} disabled={submissionFormData?.submitted} />
  )
}

export default AddressQuestionInput
