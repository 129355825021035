import { useTranslation } from 'react-i18next'
import TipTapReadOnly from '../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import { MessageChatPreviewType } from '../../../../../types/Messenger/types'

export const messageContentClasses = 'text-xs font-light text-gray-500 text-left line-clamp-2 break-words'

interface IMessagePreview {
  preview: MessageChatPreviewType
}

const MessagePreview: React.FC<IMessagePreview> = ({
  preview,
}) => {
  const { t } = useTranslation()

  const isNotes = preview.model === 'requisition'

  if (!preview.last_message) {
    return (
      <p className={messageContentClasses}>
        {isNotes ? t('sherpa.no_notes') : t('sherpa.no_messages')}
      </p>
    )
  }

  return (
    <TipTapReadOnly
      text={preview.last_message}
      className={messageContentClasses}
    />
  )
}

export default MessagePreview
