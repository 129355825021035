import { useTranslation } from 'react-i18next'
import { FormQuestionType, FormQuestionValueType } from '../../../../../types/Forms'
import useQuestionValues from '../../hooks/useQuestionValues'
import uuid from '../../../../../core/utils/uuid'
import { useQuestionAnswersLimit } from '../hooks/useQuestionAnswersLimit'
import { getIndexedQuestionValueLabel, getIndexedGenericLabels } from '../../../constants/labels'

interface IAddAnswerButton {
  question: FormQuestionType,
  defaultValue?: FormQuestionValueType
}

const AddAnswerButton: React.FC<IAddAnswerButton> = ({ question, defaultValue = '' }) => {
  const [questionValues, setQuestionValues] = useQuestionValues(question)

  const { canAdd } = useQuestionAnswersLimit(question)

  const handleAddAnswer = () => {
    const newQuestionValue = [...questionValues, {
      __uuid: uuid('form-question-value'),
      value: defaultValue,
      label: getIndexedQuestionValueLabel(question, questionValues.length + 1),
    }]

    setQuestionValues(getIndexedGenericLabels(newQuestionValue, question.question_label || ''))
  }

  const { t } = useTranslation()

  if (!question) return null

  return (
    <button
      type="button"
      className={` flex gap-x-2 items-center text-primary-gray text-xs ${!canAdd && 'hidden'}`}
      onClick={handleAddAnswer}
    >
      <p>+</p>
      <p className="underline">{t('forms.add_another_answer')}</p>
    </button>
  )
}

export default AddAnswerButton
