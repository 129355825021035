import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import SharedFileUploadPill from '../../layout/SharedFileUploadPill/SharedFileUploadPill'
import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import { ITipTapEditor } from './TipTapEditor'

interface ITipTapEditorFileList extends Pick<ITipTapEditor, 'files'> {
  handleDeleteFile?: RemoveUploadedFileFunctionType
  isSending?: boolean
  filesOnTop?: boolean
}

const TipTapEditorFileList: React.FC<ITipTapEditorFileList> = ({
  files, handleDeleteFile, isSending, filesOnTop,
}) => (
  <div
    className={`flex ${filesOnTop && 'flex-col'} my-2 gap-y-2 gap-x-2 flex-wrap`}
    data-cy="tiptap-file-list"
  >
    {files?.length
      ? files.map((file) => (
        <SharedFileUploadPill
          key={file.name}
          file={file}
          removeFile={handleDeleteFile}
          isUploading={isSending}
        />
      ))
      : (
        <div className="h-4 w-4">
          <CoreLoadingIcon
            className="max-h-full"
            dataCy="tiptap-file-loading-icon"
          />
        </div>
      )}

  </div>
)

export default TipTapEditorFileList
