import { Draggable } from 'react-beautiful-dnd'
import { MdDragIndicator } from 'react-icons/md'
import QuestionInputWrapper from '../QuestionInputWrapper'
import DeleteQuestionItemButton from './DeleteQuestionItemButton'
import { useQuestionItemsList } from './QuestionItemsList'

export type QuestionItemType = {
  __uuid: string,
  value: string,
}

interface IQuestionChoice {
  index: number
  item: QuestionItemType
}

const QuestionItem: React.FC<IQuestionChoice> = ({
  index, item,
}) => {
  const { ItemComponent } = useQuestionItemsList()

  return (
    <QuestionInputWrapper>
      {({ disabled }) => (
        <Draggable
          draggableId={item.__uuid}
          key={item.__uuid}
          index={index}
          isDragDisabled={disabled}
        >
          {(provided) => (
            <div
              className="flex w-full space-x-4 my-2 items-center"
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <span {...provided.dragHandleProps} tabIndex={-1}>
                <MdDragIndicator />
              </span>
              <div className="flex gap-x-2 w-full py-1">
                {ItemComponent({ item })}
                <DeleteQuestionItemButton item={item} />
              </div>
            </div>
          )}
        </Draggable>
      )}
    </QuestionInputWrapper>
  )
}

export default QuestionItem
