import useBus from 'use-bus'
import { useRef } from 'react'
import { FormEvents } from '../Questions'
import { useFormSection } from '../providers/FormSectionProvider'
import useGetQuestionIndex from './useGetQuestionIndex'

const scrollToQuestionElement = (questionElement?: HTMLElement | null) => {
  if (!questionElement) return

  const el = questionElement
  el.style.scrollMargin = '50px';

  (el.querySelectorAll('input, textarea')[0] as HTMLInputElement)?.focus()
  el.scrollIntoView({ behavior: 'smooth' })
}

export const useQuestionsScroll = () => {
  const { currentSection } = useFormSection()
  const getQuestionIndex = useGetQuestionIndex()

  const questionsRef = useRef<(HTMLElement | null)[]>([])

  const scrollTo = (questionUuid: string) => {
    const questionIndex = getQuestionIndex(questionUuid)
    scrollToQuestionElement(questionsRef.current[questionIndex])
  }

  const scrollToNextQuestion = (answeredQuestionUuid: string) => {
    const answeredQuestionIndex = getQuestionIndex(answeredQuestionUuid)
    const nextQuestionIndex = answeredQuestionIndex !== -1 ? answeredQuestionIndex + 1 : undefined

    if (nextQuestionIndex === undefined || !currentSection?.enabledFields[nextQuestionIndex]) return

    scrollToQuestionElement(questionsRef.current[nextQuestionIndex])
  }

  useBus(
    FormEvents.QuestionHasError.key,
    ({ payload: invalidQuestionUuid }) => scrollTo(invalidQuestionUuid),
    [currentSection, questionsRef],
  )

  useBus(
    FormEvents.QuestionAnswered.key,
    ({ payload: answeredQuestionUuid }) => scrollToNextQuestion(answeredQuestionUuid),
    [currentSection, questionsRef],
  )

  return questionsRef
}

export default useQuestionsScroll
