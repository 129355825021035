import { Dispatch, SetStateAction } from 'react'
import { LineItemItemAttributeType, LineItemType } from '../../../../../../types/shared/LineItemDataTypes'
import { filterForNonDuplicateFiles } from '../../../../../../core/utils/fileUploadUtils'
import useCheckOfferStatusAndUpdateIfSubmitted from '../../../../hooks/useCheckOfferStatusAndUpdateIfSubmitted'
import useGetToken from '../../../../hooks/useGetToken'
import { checkAndUploadFileWithToken } from '../../../../services/fileService'
import { getLineItemFromRow } from '../../../../utils/utils'
import useFindAndUpdateLineItemFiles from './useFindAndUpdateLineItemFiles'

export default (
  row: LineItemItemAttributeType | undefined,
  setFileIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const token = useGetToken()

  const checkOfferStatusAndUpdateIfSubmitted = useCheckOfferStatusAndUpdateIfSubmitted()

  const lineItem = getLineItemFromRow(row)

  const findAndUpdateLineItemFiles = useFindAndUpdateLineItemFiles()

  return (e: React.ChangeEvent<HTMLInputElement>, newLineItem?: LineItemType) => {
    const currentLineItem = lineItem || newLineItem as LineItemType

    const newFiles = lineItem
      ? filterForNonDuplicateFiles(e?.target?.files as FileList, lineItem?.files)
      : Array.from(e?.target?.files || [])

    if (newFiles?.length) {
      checkOfferStatusAndUpdateIfSubmitted()
      return newFiles.forEach(async (fileToPost) => {
        setFileIsLoading(true)
        const returnedFile = await checkAndUploadFileWithToken(fileToPost, 'LineItem', currentLineItem?.id as number, token)
        setFileIsLoading(false)

        if (returnedFile) {
          if (newLineItem) {
            findAndUpdateLineItemFiles(returnedFile, row, false, currentLineItem)
          } else {
            findAndUpdateLineItemFiles(returnedFile, row)
          }
        }
      })
    }
    return null
  }
}
