import useGetFormData from '../../../hooks/useGetFormData'
import useGetFormParams from '../../hooks/useGetFormParams'

export default () => {
  const { questionId } = useGetFormParams()
  const { data: formData } = useGetFormData()

  return formData?.template?.sections
    ?.flatMap((section) => section.fields).find((question) => question.__uuid === questionId)
}
