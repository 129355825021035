import { useMemo } from 'react'
import TableCell from '../../../../../../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../../../../../../shared/components/atoms/SharedTable/TableRow'
import { MercateoShippingCostTierType } from '../../../../../../../../../../../types/Requisition/MercateoItemType'
import calculateMercateoPrice from '../../../../../../../utils/Catalog/calculateMercateoPrice'

interface ICatalogTieredShippingTableRow {
  tier: MercateoShippingCostTierType,
  index: number
}
const CatalogTieredShippingTableRow: React.FC<ICatalogTieredShippingTableRow> = (
  { tier, index },
) => {
  const amount = useMemo(
    () => calculateMercateoPrice(tier.threshold.amount),
    [tier.threshold.amount],
  )

  const price = useMemo(() => calculateMercateoPrice(tier.value.amount), [tier.value.amount])

  return (
    <TableRow>
      <TableCell first>
        {index > 0 && '>= '}
        {amount}
      </TableCell>
      <TableCell last>
        €
        {price}
      </TableCell>
    </TableRow>
  )
}
export default CatalogTieredShippingTableRow
