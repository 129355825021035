import { FormQuestionType } from '../../../../types/Forms'
import { getUpdatedByUuid, replaceByUuid } from '../../../../shared/utils/arrayHelpers'
import useQuestionValues from './useQuestionValues'
import useFormFileDownload from './useFormFileDownload'
import useFormMedia from './useFormMedia'
import useSynchronizedFormUpdate from './useSynchronizedFormUpdate'

export const useFormUploads = (question: FormQuestionType) => {
  const [questionValues, setQuestionValues] = useQuestionValues(question)
  const setQuestionMedia = useSynchronizedFormUpdate(setQuestionValues)
  const download = useFormFileDownload()
  const media = useFormMedia()

  const upload = async (files: File[]) => {
    if (!media) return

    const uploadedFiles = await media.upload(files)
    setQuestionMedia(getUpdatedByUuid(questionValues, uploadedFiles.map((file) => ({ __uuid: file.uuid, value: file }))))
  }

  const remove = async (fileUuid: string) => {
    media?.remove(fileUuid)
    setQuestionMedia(replaceByUuid(questionValues, fileUuid))
  }

  return {
    download, upload, remove, enabled: !!media,
  }
}

export default useFormUploads
