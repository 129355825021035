import SupplierUserType from '../../../../../../../../types/Suppliers/SupplierUserType'
import useGetSupplierInvites from '../../../../hooks/useGetSupplierInvites'

const useGetFilteredCoordinateSupplierUsers = (
  supplierUsers: SupplierUserType[],
  isRecommended: boolean,

) => {
  const { data: invites } = useGetSupplierInvites()

  if (!supplierUsers?.length) return []

  const parsedSupplierUsers = supplierUsers?.map(
    (supplier) => ({ ...supplier, isRecommended }),
  ) || []

  if (!invites) return parsedSupplierUsers

  const alreadyInvitedSupplierUsersIds = invites?.map((invite) => invite.supplier_user.id)

  return parsedSupplierUsers
    .filter((supplierUser) => !alreadyInvitedSupplierUsersIds.includes(supplierUser.id))
}

export default useGetFilteredCoordinateSupplierUsers
