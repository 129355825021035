import { useTranslation } from 'react-i18next'
import usePermissions from '../../../core/hooks/usePermissions'
import VerticalNav from '../../../shared/components/layout/VerticalNav/VerticalNav'
import { FORM_ADD } from '../../../shared/constants/permissions'

const IntakeConfigurationTabbar: React.FC = () => {
  const { t } = useTranslation()
  const permissions = usePermissions([{ slug: FORM_ADD }])

  const tabs = [{
    link: '/intake-configuration/forms',
    title: t('generic.forms_plural'),
    hidden: !permissions.get(FORM_ADD).allowed,
  }, {
    link: '/intake-configuration/inputs',
    title: t('generic.inputs_plural'),
    hidden: !permissions.get(FORM_ADD).allowed,
  },
  {
    link: '/intake-configuration/workflows',
    title: t('generic.workflows', 'Workflows'),
    // hidden: !permissions.get(FORM_ADD).allowed,
  },
  ]

  return (
    <VerticalNav items={tabs} />
  )
}

export default IntakeConfigurationTabbar
