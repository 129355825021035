import { Dispatch, SetStateAction } from 'react'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import useGetNegotiationOptions from './hooks/useGetNegotiationOptions'
import NegotiationModalOption from './NegotiationModalOption'

interface INegotiationModal {
  invite: RequisitionInviteType
  setLastCall: Dispatch<SetStateAction<number[] | undefined>>
}

const NegotiationModal: React.FC<INegotiationModal> = ({
  invite, setLastCall,
}) => {
  const negotiationOptions = useGetNegotiationOptions(setLastCall, invite)

  return (
    <div
      className="flex flex-col gap-y-4"
      data-cy="negotiation-modal"
    >
      {negotiationOptions.map((option) => (
        <NegotiationModalOption
          option={option}
          key={option.title}
        />
      ))}
    </div>
  )
}

export default NegotiationModal
