import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../core/providers/UtilsProvider'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import companyQueryStrings from '../constants/companyQueryStrings'
import { apiPostCustomerDocuments } from '../services/CompanyService'
import { DocumentContentType, DocumentType } from '../../../types/Company/DocumentType'

function usePostCustomerDocuments({ document }: { document: DocumentContentType }) {
  const cache = useQueryClient()
  const utils = useUtils()

  const { mutate: postMutate } = useMutation(() => apiPostCustomerDocuments({ document }), {
    onSuccess: (newData) => {
      cache.setQueryData(
        [companyQueryStrings.customer_documents],
        (oldData: DocumentType[] | undefined) => (oldData ? [...oldData, newData] : [newData]),
      )
      utils.modal.set({ isOpen: false })
      toastr.success('success.item_created', 'company-document')
    },
  })

  return postMutate
}
export default usePostCustomerDocuments
