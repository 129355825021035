import { useTranslation } from 'react-i18next'
import { useAbility } from '@casl/react'
import { CATALOG_ITEMS } from '../../constants/newReqQueryStrings'
import { useFilters } from '../../providers/FiltersProvider'
import { apiGetCatalogItems } from '../../services/UnifiedSearchService'
import NewRequisitionTemplateCatalogItem from './NewRequisitionTemplateCatalogItem'
import NewRequisitionTemplateItemsGrid from '../NewRequisitionTemplateItemsGrid'
import useOpenCatalogItemModal from '../../hooks/useOpenCatalogItemModal'
import NewReqRecommendedGrid from '../NewReqRecommendedGrid'
import useDebouncedQuery from '../../../../../../core/hooks/useDebouncedQuery'
import { ABILITIES } from '../../../../../../config/ability'
import { AbilityContext } from '../../../../../../core/providers/AbilityProvider'

interface INewReqRecommendedGrid {
  selectTab: () => void
}

const NewReqRecommendedCatalog: React.FC<INewReqRecommendedGrid> = ({ selectTab }) => {
  const { t } = useTranslation()
  const { query: [query] } = useFilters()
  const ability = useAbility(AbilityContext)

  const { data: resolvedUnifiedData, isLoading } = useDebouncedQuery(
    [CATALOG_ITEMS, query],
    apiGetCatalogItems,
    { query },
  )

  const openModal = useOpenCatalogItemModal()

  if (!ability.can(ABILITIES.mercateoSearch)) return null

  return (
    <NewReqRecommendedGrid
      selectTab={selectTab}
      isLoading={isLoading}
      title={t('new_requisition.catalogue')}
      showMoreIcon={!!resolvedUnifiedData?.data?.length}
      gridItemComponent={(
        <NewRequisitionTemplateItemsGrid
          openModal={openModal}
          itemComponent={NewRequisitionTemplateCatalogItem}
          data={resolvedUnifiedData?.data || []}
        />
      )}
    />
  )
}

export default NewReqRecommendedCatalog
