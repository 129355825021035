import Avatar from 'react-avatar'
import { useTranslation } from 'react-i18next'
import { MdLogout } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'
import CoreContainerLayout from '../../../core/components/layout/CoreContainerLayout'
import CorePageContainerLayout from '../../../core/components/layout/CorePageContainerLayout'
import Button from '../../../shared/components/atoms/buttons/Button'
import { logOut } from '../../Authentication/services/loginService'
import settingsQueryKeys from '../constants/settingsQueryKeys'
import { apiGetUser } from '../services/SettingsService'
import SettingsProfileForm from './ProfileForm/ProfileForm'

const SettingsContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { data: userData } = useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser)

  const logoutHandler = async () => {
    await logOut()
    history.push('login')
  }

  return (
    <CorePageContainerLayout className="flex w-full justify-center">
      <div className="flex flex-col w-full max-w-7xl">
        <CoreContainerLayout className="flex flex-col p-6 gap-y-6">
          <div className="flex gap-x-6 items-center">
            <div className="w-16 h-16">
              <Avatar size="100%" name={userData?.email} round />
            </div>
            <p className="text-2xl">{t('settings.profile_settings')}</p>
          </div>
          <SettingsProfileForm />
        </CoreContainerLayout>
        <div className="w-full flex justify-end mt-2">
          <Button color="sky-blue" onClick={logoutHandler}>
            <MdLogout />
            <p className="text-xs ">{t('generic.logout')}</p>
          </Button>
        </div>
      </div>
    </CorePageContainerLayout>
  )
}

export default SettingsContainer
