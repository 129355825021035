import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import InfiniteScrollWithScroller from '../../../../shared/components/organisms/InfiniteScroll/InfiniteScrollWithScroller'
import NoResults from '../../../Basecamp/components/BasecampTable/NoResults'
import useGetPaginatedSuppliers from '../../hooks/useGetPaginatedSuppliers'
import SupplierTableRow from './SupplierTableRow/SupplierTableRow'

interface ISuppliersResults {
  searchQuery: string
}

const SuppliersResults: React.FC<ISuppliersResults> = ({ searchQuery }) => {
  const {
    data: suppliersData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetPaginatedSuppliers()

  if (searchQuery) return null

  if (isLoading) { return <CoreLoadingIcon /> }

  if (!suppliersData?.pages.length) return <NoResults />

  return (
    <InfiniteScrollWithScroller
      data={suppliersData?.pages}
      getMoreData={fetchNextPage}
      rowComponent={SupplierTableRow}
      hasNextPage={hasNextPage}
    />
  )
}

export default SuppliersResults
