import { CompanyUserType } from '../../../types/shared/RequisitionDataTypes'
import {
  apiDelete, apiGet, apiGetData, apiPost, apiPostData, apiPut, apiPutData,
} from '../../../core/utils/api/generic'
import { useApiDelete } from '../../../core/utils/queryHooks'
import companyQueryStrings from '../constants/companyQueryStrings'
import { DocumentContentType } from '../../../types/Company/DocumentType'
import UserInviteType from '../../../types/Company/UserTypes'
import { PaginatedResponse } from '../../../types/shared/PaginatedResponse'
import { CountryType } from '../../../types/Company/CountryType'

// COMPANY
export const apiUpdateCompany = async (company: { address_id: number }) => apiPutData('company', company)
export const apiMakeCompanyAddressPublic = async (company: { address_id: number }) => apiPost(`/addresses/${company.address_id}/make-public`)

// COUNTRIES
export const apiGetCountries = () => apiGetData<CountryType[]>('/countries')

// DOCUMENTS
export const apiGetCompanyUsers = (page = 1) => apiGetData<CompanyUserType[]>(`users?page=${page}`)
export const apiGetPaginatedCompanyUsers = (page = 1) => apiGet<PaginatedResponse<CompanyUserType>>(`users?page=${page}`)
export const apiDeleteDocument = async (documentId: number) => {
  await apiDelete(`/customer-documents/${documentId}`, documentId)
  return documentId
}

export const apiPostCustomerDocuments = async ({ document }: { document: DocumentContentType }) => apiPostData('/customer-documents', document)

export const apiUpdateCustomerDocuments = async ({ document, documentId }: any) => apiPutData(`/customer-documents/${documentId}`, document)

export const useDeleteDocument = (documentId: number) => useApiDelete(documentId, {
  queryId: companyQueryStrings.customer_documents,
  request: apiDeleteDocument,
  onSuccess: () => { },
  onError: () => { },
})

// USERS

export const apiPostSendInviteEmailToCompanyUser = async (item: UserInviteType) => apiPost('/user/invite', item)
export const apiUpdateUserRole = async (body: { role: string }, userId: number) => apiPut(`/user/${userId}/role`, body)
export const apiPostResetUserPassword = async (data: { email: string }) => apiPost('/forgot-password', data)
