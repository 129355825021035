import { Dispatch, SetStateAction } from 'react'
import usePostTag from '../hooks/usePostTag'
import { TagCategoryType } from '../type'

export default (
  inputValue: string,
  setInputValue: Dispatch<SetStateAction<string>>,
  category: TagCategoryType,
) => {
  const { mutate: handlePostTag } = usePostTag(category)

  return () => {
    handlePostTag(inputValue)
    setInputValue('')
  }
}
