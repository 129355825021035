import ability, { ABILITIES } from '../../../config/ability'
import TagCategory from '../../../shared/components/atoms/tags/TagCategory'
import { TagCategoryType } from '../../../shared/components/atoms/tags/type'

interface IAllTags {
  editable?: boolean
  className?: string
}

const AllTags: React.FC<IAllTags> = ({ editable, className }) => {
  const tagCategoriesData: { category: TagCategoryType, shouldBeHidden?: boolean }[] = [
    { category: 'general' },
    { category: 'certificates' },
    {
      category: 'portfolio',
      shouldBeHidden: !ability.can(ABILITIES.editPortfolioTags),
    },
  ]

  return (
    <div className={`${className} flex flex-col w-full gap-y-5 p-7`}>
      {tagCategoriesData.map((tagCategory) => (
        !tagCategory.shouldBeHidden
          ? (
            <TagCategory
              key={tagCategory.category}
              category={tagCategory.category}
              editable={editable}
            />
          )
          : null
      ))}
    </div>
  )
}

export default AllTags
