import { FormQuestionResponseType } from '../../../../../types/Forms'
import QuestionInputTextGray from '../components/inputs/QuestionInputTextGray'
import { useQuestionItemsList } from '../components/QuestionItemsList/QuestionItemsList'
import { useFormContext } from '../../../../../core/providers/FormProvider'

interface IQuestionChoiceInput {
  item: FormQuestionResponseType
}

const QuestionOptionInput: React.FC<IQuestionChoiceInput> = ({ item }) => {
  const { onChange } = useQuestionItemsList<FormQuestionResponseType>()
  const { question: [question] } = useFormContext()

  const getInputProps = (property: keyof FormQuestionResponseType) => ({
    value: item[property] as string,
    error: !item[property] && 'Field cannot be empty',
  })

  const handleDisplayNameUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...item,
      display_name: e.target.value,
      value: question?.__settings?.options?.customValues ? item.value : e.target.value,
    })
  }

  return (
    <>
      <QuestionInputTextGray
        placeholder="Enter label here..."
        fullWidth
        {...getInputProps('display_name')}
        onChange={handleDisplayNameUpdate}
      />
      {question?.__settings?.options?.customValues && (
      <QuestionInputTextGray
        placeholder="Enter value here..."
        fullWidth
        {...getInputProps('value')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange({ ...item, value: e.target.value })
        }}
      />
      )}
    </>
  )
}

export default QuestionOptionInput
