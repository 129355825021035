import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { useFormContext } from '../../../../core/providers/FormProvider'

const AutosaveIndicator: React.FC = () => {
  const { lastSaved: [lastSaved] } = useFormContext()

  const checkIsRecentlySaved = () => (lastSaved ? Math.abs(dayjs(lastSaved).diff() / 1000) : 6)

  const [recentlySaved, setRecentlySaved] = useState<boolean>()

  const updateRecentlySaved = () => {
    const isSaved = checkIsRecentlySaved() < 3
    if (isSaved) {
      setRecentlySaved(true)
    } else {
      setRecentlySaved(false)
    }
  }

  useEffect(() => {
    updateRecentlySaved()
    const checkDateOnceASecond = setInterval(() => {
      updateRecentlySaved()
    }, 1000)
    return () => clearInterval(checkDateOnceASecond)
  }, [lastSaved])

  if (!recentlySaved) return null

  return (
    <div className="flex gap-x-2 text-nds-dark-green-accessible items-center">
      <MdCheck
        className="bg-nds-dark-green-accessible text-white rounded-full p-[2px]"
        size={16}
      />
      <p>Autosaved</p>
    </div>
  )
}

export default AutosaveIndicator
