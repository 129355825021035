import _ from 'lodash'
import usePermissions from '../../../../../core/hooks/usePermissions'
import { CompanyUserType } from '../../../../../types/shared/RequisitionDataTypes'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import { USER_EDIT } from '../../../../../shared/constants/permissions'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import UserTypeSelect from '../UsersForm/UserTypeSelect'

interface IRoleInfo {
  user: CompanyUserType
  currentUser: UserType | undefined
}

const RoleInfo: React.FC<IRoleInfo> = ({ user, currentUser }) => {
  const permissions = usePermissions([{ slug: USER_EDIT }])

  return (
    <TableCell>
      {permissions.get(USER_EDIT).allowed
        ? <UserTypeSelect userId={user.id} userRole={user.role} currentUser={currentUser} />
        : <p>{_.capitalize(user.role)}</p>}
    </TableCell>
  )
}

export default RoleInfo
