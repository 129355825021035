import { useQuery } from 'react-query'
import { apiGetFormTags } from '../../../../../constants/formService'
import useGetFormId from '../../../../hooks/useGetFormId'

export const useGetFormTags = () => {
  const formId = useGetFormId()

  return useQuery(['form-tags', formId], () => apiGetFormTags(formId))
}

export default useGetFormTags
