import { useTranslation } from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import RequiredLabel from '../../../../shared/components/atoms/Labels/RequiredLabel'

interface INDAFormLocationSearch {
  location: string
  setLocation: (location: string) => any
}

const NDAFormLocationSearch: React.FC<INDAFormLocationSearch> = ({ location, setLocation }) => {
  const { t } = useTranslation()

  return (
    <PlacesAutocomplete
      value={location}
      onChange={setLocation}
      onSelect={setLocation}
    >
      {({
        getInputProps, suggestions, getSuggestionItemProps,
      }) => (
        <div className="relative inline-blockF">
          <div className="w-full relative">
            <RequiredLabel className="absolute l-0 h-6 top-2" />
            <input
              name="name"
              className={`border border-gray-200 rounded-md text-sm placeholder-gray-400 w-full ${!!suggestions.length && 'rounded-t-none'}`}
              {...getInputProps({
                placeholder: `${t('company.search_for_address')}...`,
              })}
            />
          </div>
          {!!suggestions.length && (
            <div className="overflow-hidden rounded-b-none flex flex-col gap-1 absolute bg-white border border-gray-300 rounded-lg bottom-full w-full">
              {suggestions.map((suggestion) => (
                <div
                  className={`rounded-sm px-1 cursor-pointer bg-white w-full ${suggestion.active && 'bg-gray-800 text-white'}`}
                  {...getSuggestionItemProps(suggestion)}
                  key={suggestion.id}
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default NDAFormLocationSearch
