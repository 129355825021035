import { useQueryClient } from 'react-query'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import OfferViewQueryKeys from '../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorDataType } from '../constants/editorTypes'
import { postNewSection } from '../services/editorService'
import useAutoSave from '../../../utils/useAutoSave'

const useAddSection = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()
  const [_, updateAutoSave] = useAutoSave()

  return async (sectionType:string = 'PRODUCT') => {
    try {
      const { data: newSection } = await postNewSection(requisitionId, sectionType)

      cache.setQueryData<ReqDefEditorDataType>([OfferViewQueryKeys.SECTIONS_DATA, requisitionId], (
        oldSections: undef<ReqDefEditorDataType>,
      ) => (oldSections
        ? [...oldSections, newSection]
        : newSection))

      updateAutoSave()
      return newSection
    } catch {
      toastr.error()
    }
    return ''
  }
}

export default useAddSection
