import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import TipTapReadOnly from '../../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'

const BannerModal: React.FC<
  { reason: string | undefined, changesRequested?: boolean }> = ({ reason, changesRequested }) => {
    const utils = useUtils()
    const { t } = useTranslation()
    return (
      <div className="flex flex-col gap-y-8">
        <TipTapReadOnly text={reason || ''} />
        <div className="flex justify-between w-full items-center">
          {changesRequested
            && (
              <p className="w-2/3 text-xs text-gray-500">
                {t('supplier_view.resubmit_alert')}
              </p>
            )}
          <Button variant="solid" color="lhotse-orange" onClick={() => utils.modal.set({ isOpen: false })}>
            {t('generic.ok')}
          </Button>
        </div>
      </div>
    )
  }

export default BannerModal
