import { useTranslation } from 'react-i18next'

export type CompanyInputKeyValuePair = {
  id?: string
  key: string,
  value: string
}

type CompanyInputFormProps = {
  items: CompanyInputKeyValuePair[]
  onChange: (item: CompanyInputKeyValuePair, index: number) => void
  onDelete: (item: CompanyInputKeyValuePair, index: number) => void
  onAdd: () => void
  keyInputProps?: React.InputHTMLAttributes<HTMLInputElement>
  valueInputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const CompanyInputForm: React.FC<CompanyInputFormProps> = ({
  items, onChange, onDelete, onAdd, keyInputProps, valueInputProps,
}) => {
  const { t } = useTranslation()

  return (

    <div className=" space-y-2 pl-4">
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`input-option-${item.value}-${item.key}-${item.id}-${index}`} className="flex space-x-2">
          <input
            placeholder={t('forms.display_name', 'Display name')}
            name="display_name"
            defaultValue={item.key || ''}
            onBlur={(e) => onChange({ ...item, key: e.currentTarget.value }, index)}
            className="border border-gray-300 rounded-lg p-2 flex w-full"
            {...keyInputProps}
          />
          <input
            placeholder={t('forms.value_placeholder', 'Value')}
            name="value"
            defaultValue={item.value || ''}
            onBlur={(e) => onChange({ ...item, value: e.currentTarget.value }, index)}
            className="border border-gray-300 rounded-lg p-2 flex"
            {...valueInputProps}
          />
          <button
            type="button"
            className="flex gap-x-2 items-center text-primary-gray text-xs"
            onClick={() => onDelete(item, index)}
          >
            <p>+</p>
            <p className="underline">{t('forms.remove_choice', 'Remove choice')}</p>
          </button>
        </div>
      ))}

      <button
        type="button"
        className="flex gap-x-2 items-center text-primary-gray text-xs"
        onClick={onAdd}
      >
        <p>+</p>
        <p className="underline">{t('forms.add_choice', 'Add choice')}</p>
      </button>
    </div>
  )
}
export default CompanyInputForm
