import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import SupplierBranchName from '../../../../../Suppliers/components/BranchName'
import useOpenSupplierDetailsModal from '../../../../../SupplierDetails/hooks/useOpenSupplierDetailsModal'

interface IBranchName { invite: RequisitionInviteType }

const BranchName: React.FC<IBranchName> = ({ invite }) => {
  const supplierBranch = invite?.supplier_user?.supplier_branch
  const openModal = useOpenSupplierDetailsModal(supplierBranch?.id)

  return supplierBranch
    ? (
      <SupplierBranchName
        supplierBranch={supplierBranch}
        disabledStar
        onClick={openModal}
      />
    ) : null
}

export default BranchName
