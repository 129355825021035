import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import SearchSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import useAvailableRequisitionFilterValues from '../hooks/useAvailableRequisitionFilterValues'
import { RequisitionFilterType, RequisitionFilterValueType } from '../types'

type RequisitionFilterValueSelectorProps = {
 filter: RequisitionFilterType
 onChange: (selectedValue: RequisitionFilterValueType) => void
}

export const RequisitionFilterValueSelector = ({ filter, onChange }: RequisitionFilterValueSelectorProps) => {
  const availableValues = useAvailableRequisitionFilterValues(filter)

  const { t } = useTranslation()

  if (availableValues.length === 0) return null

  return (
    <SearchSelect
      alwaysVisible
      onChange={onChange}
      options={availableValues}
      currentSelection={undefined}
      placeholder={t('basecamp.requisitions.filter_value')}
      className={classnames('text-xs text-black', { hidden: availableValues.length === 1 })}
      optionsWrapperClassName="min-w-max"
      optionsClassName="min-w-max"
      optionClassName="w-fit"
      wrapperClassName="text-black"
      nullable={false}
      showArrow={false}
      inputWrapperClassNames="p-1"
    />
  )
}

export default RequisitionFilterValueSelector
