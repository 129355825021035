import { useTranslation } from 'react-i18next'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionResponseType } from '../../../types/Forms'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import { useFormField } from '../providers/FormFieldProvider'

export const FormFieldSelect = () => {
  const { field: { value, input: { options, type }, disabled }, setValues } = useFormField()
  const { t } = useTranslation()

  if (!type.includes('SELECT') || !options) return null

  const selectOptions: InputSearchSelectItemType[] = options.map((option) => ({
    id: option.__uuid || option.uuid,
    label: option.value,
  } as InputSearchSelectItemType))

  const selectedOption = selectOptions.find((option) => option.id === value?.__uuid || option.id === value?.id || option.id === value?.uuid)

  return (
    <SearchSelect
      disabled={disabled}
      onChange={(selected) => setValues({
        __uuid: selected?.id!, label: selected?.label!, display_name: selected?.label!, value: selected?.label!,
      } as FormQuestionResponseType)}
      options={selectOptions}
      currentSelection={selectedOption}
    />
  )
}

export default FormFieldSelect
