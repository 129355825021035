import { useQuery } from 'react-query'
import queryKeys from '../constants/queryKeys'
import { getOfferData } from '../services/services'
import useGetToken from './useGetToken'

const useGetOffer = () => {
  const token = useGetToken()
  return useQuery([queryKeys.offer], () => getOfferData(token), { enabled: !!token })
}

export default useGetOffer
