import { Tab } from '@headlessui/react'
import { GoDot } from 'react-icons/go'
import { MercateoMimeItemType } from '../../../../../../../../../types/Requisition/MercateoItemType'
import CatalogProductImage from './CatalogProductImages/CatalogProductImage'

const CatalogProductImages: React.FC<{ images: MercateoMimeItemType[] }> = ({ images }) => (
  <Tab.Group>
    <Tab.Panels>
      {images.map((img) => (
        <Tab.Panel key={img.href}>
          <CatalogProductImage image={img} />
        </Tab.Panel>
      ))}
    </Tab.Panels>
    <Tab.List className="w-full flex justify-center py-2" data-cy="carousel-selectors">
      {images.map((img) => (
        <Tab key={img.href}>
          {({ selected }) => (
            <GoDot
              size={20}
              className={selected ? 'text-orange-400' : 'text-gray-500'}
            />
          )}
        </Tab>
      ))}
    </Tab.List>
  </Tab.Group>
)

export default CatalogProductImages
