import { useState } from 'react'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import SearchInput from '../../../../../shared/components/atoms/inputs/SearchInput'
import { FormSubmissionType } from '../../../../../types/Forms'
import BasecampNoResults from '../NoResults'
import Submission from './Submission'
import SubmissionsTableFilterButtons from './SubmissionsTableFilterButtons'
import useGetSubmissionsData from './hooks/useGetSubmissionsData'
import useInferStatus, { InferStatusStatusType } from './hooks/useInferStatus'

const SubmissionsTable = () => {
  const [query, setQuery] = useState<string[]>([])
  const [search, setSearch] = useState<string>('')
  const { data: submissionData, isLoading } = useGetSubmissionsData()
  const { inferStatus } = useInferStatus()

  if (isLoading) return <CoreLoadingIcon />
  if (submissionData?.length === 0 && !isLoading) return <BasecampNoResults />
  const handleQueryUpdate = (queryString: InferStatusStatusType) => {
    // Add or remove query to the query array
    if (query.includes(queryString)) {
      setQuery(query.filter((q) => q !== queryString))
    } else {
      setQuery([...query, queryString])
    }
  }

  const searchNames = (submission: FormSubmissionType) => {
    if (!search) return true
    const text = submission?.fields?.find((f) => f.key === 'req-name')?.value[0].value as string
    return text?.toLowerCase().includes(search.toLowerCase())
  }

  const isAdvario = window.location.href.includes('advario')

  const filterSubmissions = (submission: FormSubmissionType) => (query.length === 0 || query.includes(inferStatus(submission).status)) && searchNames(submission)

  return (
    <div className="flex flex-col space-y-2 pt-4 ">
      <div className="relative">
        <SearchInput placeholder="Search Submissions" debounce={0} onChange={({ target: { value } }) => setSearch(value)} />
      </div>
      {isAdvario ? <SubmissionsTableFilterButtons handleQueryUpdate={handleQueryUpdate} query={query} /> : null}

      {
        submissionData?.filter((s) => filterSubmissions(s))?.map(
          (submission) => <Submission key={submission.uuid} submission={submission} />,
        )
      }
    </div>
  )
}

export default SubmissionsTable
