import classnames from 'classnames'
import type { SetOptional } from 'type-fest'
import FormFieldProvider, { FormFieldProviderProps } from './providers/FormFieldProvider'

import FormFieldInput from './FormFieldInput'
import { FormFieldDisplay } from './FormFieldDisplay'
import FormFieldControls from './components/FormFieldControls'
import { FormFieldSettings } from './types'
import { FormQuestionResponseType } from '../../types/Forms'
import { IntakeFieldOptionType } from '../../types/Intake'

export type FormFieldProps<T extends string = string> = Omit<FormFieldProviderProps<T>, 'field'> & {
  readonly?: boolean
  className?: React.HTMLAttributes<HTMLDivElement>['className']
  field: SetOptional<FormFieldSettings<T>, 'options' | 'media'>
}
const inputOptionToFieldOption = (option: FormQuestionResponseType): IntakeFieldOptionType => ({
  id: option.uuid || option.__uuid,
  value: option.value,
  uuid: option.uuid,
})

export const FormField = <T extends string = string>({
  readonly, field, className, ...props
}: FormFieldProps<T>) => {
  const normalizedField = { media: [], options: field.input.options?.map(inputOptionToFieldOption), ...field }

  return (
    <FormFieldProvider<T> field={normalizedField} {...props}>
      <div className={classnames('flex flex-col gap-3 p-1 pl-0', className)}>
        {field.label && (
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-1  font-semibold">
              <div className="">
                {field.label}
              </div>
            </div>
            <div>
              {!readonly && <FormFieldControls />}
            </div>
          </div>
        )}
        <div className="w-full">
          {!readonly && <FormFieldInput />}
          {readonly && <FormFieldDisplay />}
        </div>
      </div>
    </FormFieldProvider>
  )
}

export default FormField
