import {
  useCallback, useMemo, useState,
} from 'react'
import { TabbedNavItemType } from '../../types/shared/TabbedNavItemType'

type TabNavItemData = Omit<TabbedNavItemType, 'tabIndex'> & { tabIndex?: number }

const parseTabsData = (tabsData: (TabNavItemData | null | undefined)[]): TabbedNavItemType[] => ((tabsData.filter((tab) => !!tab)) as TabNavItemData[])
  .map((tab, index) => ({ tabIndex: index, ...tab }))

export const useTabs = (tabs: (TabNavItemData | null | undefined)[]) => {
  const [navItems, setNavItems] = useState<TabbedNavItemType[]>(parseTabsData(tabs))

  const changeTab = useCallback((tabIndex: number) => {
    const newNavItems = navItems.map((item: TabbedNavItemType) => ({
      ...item,
      current: item.tabIndex === tabIndex,
    }))

    setNavItems(newNavItems)
  }, [navItems])

  const current = useMemo(() => navItems.find((item) => item.current), [navItems])

  const updateTabs = useCallback((tabs: (TabNavItemData | null | undefined)[]) => {
    setNavItems(parseTabsData(tabs))
  }, [])

  return {
    navItems, current, changeTab, updateTabs,
  } as const
}

export default useTabs
