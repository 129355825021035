import ContextMenu from './ContextMenu'
import PreviewButton from './PreviewButton'
import ApprovalFlowButtons from '../../../subModules/ApprovalFlowDrawer/ApprovalFlowButtons'
import ReqTopbarSubmitButton from './ReqTopbarSubmitButton'

const ReqTopbarActionButtons: React.FC = () => (
  <div className="flex gap-x-4 items-center">
    <ContextMenu />
    <PreviewButton />
    <ReqTopbarSubmitButton />
    <ApprovalFlowButtons />
    {/* <RequisitionTransmitButton /> */}
  </div>
)

export default ReqTopbarActionButtons
