import { useTranslation } from 'react-i18next'
import BaseInput from '../../../../shared/components/atoms/Input/BaseInput'
import { getUpdatedByUuid } from '../../../../shared/utils/arrayHelpers'
import { FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import { FormEvents } from '../Questions'
import useQuestionErrorMessage from '../hooks/useQuestionErrorMessage'
import useQuestionValues from '../hooks/useQuestionValues'
import useUpdateSubmission from '../hooks/useUpdateSubmission'
import KeyValueQuestion from './atoms/KeyValueQuestion'
import { inputErrorClassNames } from './atoms/helpers'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'

interface INumberQuestion {
  question: FormQuestionType
}

const NumberQuestion: React.FC<INumberQuestion> = ({
  question,
}) => {
  const { t } = useTranslation()
  const [questionValues, setQuestionValues] = useQuestionValues(question)
  const error = useQuestionErrorMessage(question)

  const updateForm = useUpdateSubmission()
  const isDisabled = useIsQuestionDisabled(question)

  const onChange = (newValue: FormQuestionValue) => {
    setQuestionValues(getUpdatedByUuid(questionValues, [newValue]))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.code !== 'Enter') return

    event.preventDefault()
    event.stopPropagation()

    FormEvents.QuestionAnswered.dispatch(question.__uuid)
  }

  return (
    <KeyValueQuestion question={question}>
      {(answer) => (
        <div className="flex gap-x-2 relative w-full" key={answer.__uuid}>
          <BaseInput
            disabled={isDisabled}
            type="number"
            className={`${error ? inputErrorClassNames : ''}  `}
            placeholder={t('type_number')}
            onChange={({ currentTarget: { value } }) => onChange({ ...answer, value })}
            onKeyDown={handleKeyDown}
            onWheel={(e) => { e.currentTarget.blur() }}
            onBlur={updateForm}
            value={answer.value as string || ''}
          />

        </div>
      )}
    </KeyValueQuestion>
  )
}

export default NumberQuestion
