import { useFormContext } from '../../../../../core/providers/FormProvider'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const NumberQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'NUMBER') return null

  return <TextAndSubtitleInput />
}

export default NumberQuestion
