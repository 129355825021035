// REFACTOR
import { useTranslation } from 'react-i18next'
import { LineItemType } from '../../../../../../types/shared/LineItemDataTypes'
import useOfferIsDisabled from '../../../../hooks/useOfferIsDisabled'

interface IYesOrNoRow {
  onClick: (answer: '0' | '1' | null) => void
  lineItem: LineItemType
}

const YesOrNoRow: React.FC<IYesOrNoRow> = ({ onClick, lineItem }) => {
  const { t } = useTranslation()
  // TODO: clean up, remove 'yes'/'no' legacy cases.
  const parseYesNo = (yesNo?: string | undefined | null) => (yesNo === undefined || yesNo === null
    ? undefined
    : !(!yesNo || yesNo === '0' || yesNo === 'No'))

  const yesOrNo = parseYesNo(lineItem?.value)

  const isDisabled = useOfferIsDisabled()

  return (
    <div className="flex gap-x-2 yes-or-no">
      <button
        className={`border ${yesOrNo === true && 'border-blue-700 text-blue-700'} text-gray-400 border-gray-300 hover:bg-gray-100  rounded-lg`}
        type="button"
        onClick={() => onClick(yesOrNo === true ? null : '1')}
        data-cy="supplier-offer-yes-button"
        disabled={isDisabled}
      >
        <p className="px-4">{t('generic.yes')}</p>
      </button>
      <button
        className={`border ${yesOrNo === false && 'border-blue-700 text-blue-700'} text-gray-400 border-gray-300 hover:bg-gray-100 rounded-lg`}
        type="button"
        onClick={() => onClick(yesOrNo === false ? null : '0')}
        data-cy="supplier-offer-no-button"
        disabled={isDisabled}
      >
        <p className="px-4 ">{t('generic.no')}</p>
      </button>
    </div>
  )
}

export default YesOrNoRow
