import { useTranslation } from 'react-i18next'

interface ITypeTag {
  title?: string
  lhotseSupplier: boolean
  waiting?: boolean
  rejected?: boolean
}

const TypeTag: React.FC<ITypeTag> = ({
  title, lhotseSupplier, waiting, rejected,
}) => {
  const { t } = useTranslation()
  const waitingClasses = 'bg-yellow-500 text-yellow-600'
  const rejectedClasses = 'bg-red-500 text-red-600'
  return (
    <div
      className={`text-center text-xs rounded-full bg-gray-300 bg-opacity-20 py-0.5 px-2 
      ${waiting && waitingClasses}
      ${rejected && rejectedClasses}`}
      style={{ width: 'fit-content' }}
    >
      <p>{title || (lhotseSupplier ? t('tags.lhotse_supplier') : t('tags.your_supplier'))}</p>
    </div>
  )
}

export default TypeTag
