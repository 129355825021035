import { useTranslation } from 'react-i18next'
import Tooltip from '../../../../../shared/components/organisms/tooltip/Tooltip'
import RequisitionRowType from '../../../../../types/Basecamp/RequisitionRowType'

const Title: React.FC<{ data: RequisitionRowType }> = ({ data }) => {
  const show = data.name.length > 77
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-start ">
      <div className="flex flex-col gap-2 flex-grow mb-4">
        <Tooltip tooltip={data.name} showCondition={show}>
          <div className="line-clamp-1 mr-2">
            {data.name}
          </div>
        </Tooltip>
        <div className="flex space-x-2 text-secondary-gray text-xs">
          {data.customer_requisition_id
            ? <div>{data.customer_requisition_id}</div>
            : <div>{`REQ-${data.id}`}</div>}
          <p>·</p>
          <div>
            {`${t('generic.create_date')}: ${new Date(data.created_at).toLocaleDateString('de-DE')}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Title
