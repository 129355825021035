import { WorkflowStep } from '../../../types/Workflow/WorkflowBlock'
import { WorkflowChartBlockNodeType, WorkflowChartNode } from '../../../types/Workflow/WorkflowChart'
import uuid from '../../../core/utils/uuid'

export const isColumnNode = (node: WorkflowChartNode): boolean => node.type !== 'HELPER' && !node.parentNode

export const createNode = (node: Partial<WorkflowChartNode>): WorkflowChartNode => ({
  id: uuid('node'),
  type: 'APPROVAL',
  position: { x: 0, y: 0 },
  data: { position: -1 } as WorkflowChartBlockNodeType,
  draggable: false,
  connectable: false,
  deletable: false,
  ...node,
  style: {
    opacity: 0,
  },
})

export const createGroupNode = (
  workflowStep: WorkflowStep,
): WorkflowChartNode | null => {
  if (workflowStep.blocks.length <= 1) {
    return null
  }

  return createNode({
    id: workflowStep.uuid,
    type: 'GROUP',
    data: workflowStep,
  })
}

export const createHelperNode = (node?: Partial<WorkflowChartNode>): WorkflowChartNode => createNode({
  id: node?.id ? `helper-${node.id}` : uuid('helper'),
  type: 'HELPER',
  data: {
    target: (node?.data as (WorkflowChartBlockNodeType | WorkflowStep))?.uuid
      ? node?.data as (WorkflowChartBlockNodeType | WorkflowStep) : undefined,
    nodeId: node?.id,
    position: node?.data?.position ?? 0,
  },
})

export const createBlockNodes = (
  workflowStep: WorkflowStep,
): WorkflowChartNode[] => workflowStep.blocks.map((block) => createNode({
  id: block.uuid,
  type: block.workflow_block_type?.name || 'APPROVAL',
  data: { ...block, position: workflowStep.position, stepUuid: workflowStep.uuid },
  parentNode: workflowStep.blocks.length > 1 ? workflowStep.uuid : undefined,
}))
