import { useHistory } from 'react-router'
import { useCallback } from 'react'
import { Workflow } from '../../../types/Workflow/Workflow'
import { ActiveWorkflow } from '../../../types/Workflow/ActiveWorkflow'

export const useOpenWorkflowBuilder = (workflow: Workflow | ActiveWorkflow) => {
  const history = useHistory()

  return useCallback(() => {
    history.push(`/workflows/${workflow.uuid}/builder`)
  }, [history, workflow])
}

export default useOpenWorkflowBuilder
