import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { RequisitionDataType } from '../../../../../types/shared/RequisitionDataTypes'
import { updateRequisition } from '../services/metadataService'

const useUpdateRequisition = () => {
  const { requisitionId } = useParams<{ requisitionId: string }>()

  // TODO: React Query and disable if nothing changed
  return useMutation(
    'UPDATE_REQUISITION',
    (data:Partial<RequisitionDataType & { delivery_address_id: number | null; }& { billing_address_id: number | null; }
       & { category_id: number | null; }>) => updateRequisition(requisitionId, data),
    {
      // onSuccess: () => {},
      onError: () => { toastr.error() },
    },
  )
}

export default useUpdateRequisition
