import { useTranslation } from 'react-i18next'
import useUpdateUserLanguage from '../../modules/Settings/hooks/useUpdateUserLanguage'

export default () => {
  const { language } = navigator
  const { i18n } = useTranslation()
  const languageCode = language.split('-')[0]
  const { mutate: updateLanguage } = useUpdateUserLanguage()
  const user = localStorage.getItem('lhotseAuth')

  const localStorageLanguage = localStorage.getItem('language')

  if (!localStorageLanguage) {
    const parsedLanguage = languageCode === 'de' ? 'de' : 'en'

    localStorage.setItem('language', parsedLanguage)
    if (user) updateLanguage({ language: parsedLanguage })
    i18n.changeLanguage(parsedLanguage)
  }
}
