import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useState } from 'react'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import Table from '../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../shared/components/atoms/SharedTable/TableHeader'
import UsersListRow from './UsersListRow/UsersListRow'
import settingsQueryKeys from '../../../Settings/constants/settingsQueryKeys'
import { apiGetUser } from '../../../Settings/services/SettingsService'
import useGetPaginatedUsers from './hooks/useGetPaginatedUsers'
import { Pagination } from '../../../../shared/components/atoms/SharedTable/Pagination'

const UsersList = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useQuery([settingsQueryKeys.USER_PROFILE], apiGetUser)
  const [page, setPage] = useState(1)
  const { data: paginatedResponse, isLoading } = useGetPaginatedUsers(page)

  const { data: users, meta: { per_page, total } } = paginatedResponse || { meta: { total: 1, per_page: 1 } }
  const pageCount = Math.ceil(total / per_page)

  return (
    <>
      <TableContainer>
        <TableHead>
          <TableHeader>{t('generic.name')}</TableHeader>
          <TableHeader>{t('generic.email')}</TableHeader>
          <TableHeader>{t('account.user_role')}</TableHeader>
          <TableHeader>{t('account.user_member_since')}</TableHeader>
        </TableHead>
        <Table>
          {!isLoading && users && users?.map((user) => (
            <UsersListRow
              user={user}
              key={user.id}
              currentUser={currentUser}
            />
          ))}
          {!isLoading && !users && <div className="flex w-full text-gray-900">{t('account.no_users')}</div>}
        </Table>
      </TableContainer>
      {isLoading && <div className="p-8"><CoreLoadingIcon /></div>}
      {pageCount > 1
      && (
      <div className="mt-4 w-full xl:w-3/4 self-center flex justify-center">
        <Pagination
          onPageChange={({ selected }) => setPage(selected + 1)}
          forcePage={page - 1}
          pageCount={pageCount}
        />
      </div>
      )}
    </>
  )
}

export default UsersList
