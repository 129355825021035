import _ from 'lodash'
import { MercateoNumberFormatType } from '../../../../../../types/Requisition/MercateoItemType'
import calculateMercateoPrice from './calculateMercateoPrice'

const calculateSavingPercentage = (
  originalPrice : MercateoNumberFormatType,
  newPrice: MercateoNumberFormatType,
) => _.round(_.divide(
  (100 * (calculateMercateoPrice(originalPrice) - calculateMercateoPrice(newPrice))
  ),
  calculateMercateoPrice(originalPrice),
), 2)

export default calculateSavingPercentage
