import { INodeOrStringChildren } from '../../../types/shared/GeneralTypes'

interface IIndentationWrapper extends INodeOrStringChildren {
  className?: string
  disabled?: boolean
}

const IndentationWrapper: React.FC<IIndentationWrapper> = ({ children, className, disabled }) => (
  <div className={`${!disabled && 'border-l-2 border-gray-300 pl-6 ml-2'} ${className} `}>
    {children}
  </div>
)

export default IndentationWrapper
