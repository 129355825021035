import useGetCatalogItems from '../../../../Definition/hooks/useGetCatalogItems'
import useGetRequisitionData from '../../../../Definition/hooks/useGetRequisition'
import NewRequisitionTemplateCatalogItem from '../../../../NewRequisition/components/Catalog/NewRequisitionTemplateCatalogItem'
import NewRequisitionTemplateItemsGrid from '../../../../NewRequisition/components/NewRequisitionTemplateItemsGrid'
import { useCoordinateProvider } from '../../../providers/CoordinateProvider'

const CatalogTab: React.FC = () => {
  const { data: requisitionData } = useGetRequisitionData()
  const { searchQuery: [query] } = useCoordinateProvider()
  const { data: catalogItems } = useGetCatalogItems(query || requisitionData?.name || '')

  return catalogItems
    ? (
      <NewRequisitionTemplateItemsGrid
        openModal={() => { }}
        itemComponent={NewRequisitionTemplateCatalogItem}
        data={catalogItems?.data}
        maxElements={50}
      />
    )
    : null
}

export default CatalogTab
