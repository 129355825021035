import useQuestionErrorMessage from '../../hooks/useQuestionErrorMessage'
import { FormQuestionType } from '../../../../../types/Forms/formTypes'
import InputError from './InputError'

const QuestionError = ({ question }: {question: FormQuestionType}) => {
  const error = useQuestionErrorMessage(question)

  return (<InputError error={error} />)
}

export default QuestionError
