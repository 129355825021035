import { Field } from 'formik'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface ITextInput {
  type?: string
  name: string
  handleChange: (value: string) => any
  dataCy?: string
  placeholder?: string
  values: any
  error?: ReactNode
  handleOnEnter?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  min?: number
  max?: number
  validate?: (value: string) => any
  disabled?: boolean
  handleOnBlur?: (e: { name: string, value: string }) => any
  customOnChange?: (value: string) => any
  className?: string
  inputClassName?: string
  label?: string
  labelClassName?: string
  required?: boolean
}

const TextInput: React.FC<ITextInput> = ({
  type = 'text', name, handleChange, dataCy, placeholder, values, error, handleOnEnter,
  min, max, validate, disabled, handleOnBlur, customOnChange, inputClassName,
  className, label, labelClassName, required,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={`flex flex-col ${className} relative`}>
        {label && <label htmlFor={name} className={labelClassName}>{t(label)}</label>}
        <Field
          disabled={disabled}
          name={name}
          type={type}
          placeholder={t(placeholder || '')}
          value={(values && values[name]) || ''}
          onChange={(v: any) => {
            handleChange(v)
            if (customOnChange) customOnChange(v)
          }}
          data-cy={dataCy}
          onBlur={(e: any) => handleOnBlur && handleOnBlur({ name, value: e.target.defaultValue })}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter' && e.target.defaultValue && handleOnEnter) {
              e.preventDefault()
              handleOnEnter()
            }
          }}
          min={min}
          max={max}
          validate={validate}
          className={`${disabled && 'bg-gray-200 pointer-events-none'} ${inputClassName}`}
        />
        {required && <div className="absolute h-[75%] top-[15%] bg-orange-600 w-1 rounded-r-xl" />}
      </div>
      {error}
    </>
  )
}

export default TextInput
