import { omit } from 'lodash'
import {
  apiDelete, apiGet, apiGetData, apiPost, apiPut,
} from '../../../core/utils/api/generic'
import { InputCategory, InputTemplate } from '../../../types/Forms'

export type InputSearchParams = Partial<{
    search: string,
    category_uuid: string
}>

export const apiGetCompanyInputs = async (searchParams?: InputSearchParams) => apiGetData<InputTemplate[], InputSearchParams>('/inputs', searchParams)

export const apiGetInputById = async (uuid: string) => apiGetData<InputTemplate>(`/inputs/${uuid}`)

type SerializedInput = Partial<Omit<InputTemplate, 'category'>> & {
    category_uuid: string
}

const serializeInput = (input: Partial<InputTemplate>): SerializedInput => ({
  ...omit(input, 'category'),
  category_uuid: input.category?.uuid || '',
})

export const apiPostCompanyInputs = async (companyInput: Partial<InputTemplate>) => apiPost<InputTemplate>('/inputs', serializeInput(companyInput))

export const apiPutCompanyInputs = async (companyInput: Partial<InputTemplate>) => apiPut(`/inputs/${companyInput.uuid}`, serializeInput(companyInput))

export const apiDeleteCompanyInput = async (uuid: string): Promise<{success: boolean}> => apiDelete(`/inputs/${uuid}`)

export const apiGetInputCategories = async () => apiGetData<InputCategory[]>('inputs/categories')
