import { Form, Formik } from 'formik'
import { useState } from 'react'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import ErrorMessage from '../../../../shared/components/atoms/ErrorMessage'
import FormikInputGrayUnderline from '../../../../shared/components/atoms/inputs/underlined/FormikInputGrayUnderline'
import TransparentError from '../../../../shared/components/atoms/TransparentErrorMessage'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import useGetSettingsProfileFormValidationSchema from '../../hooks/useGetSettingsProfileFormValidationSchema'
import useGetUser from '../../hooks/useGetUser'
import useGetUserFormInitialValues from '../../hooks/useGetUserFormInitialValues'
import useUpdateUserLanguage from '../../hooks/useUpdateUserLanguage'
import useUpdateUserMutation from '../../hooks/useUpdateUserMutation'
import LanguageSelect from './LanguageSelect'

const ProfileForm = () => {
  const [showSaveCancel, setShowSaveCancel] = useState(false)

  const { data: userData, isLoading: fetchIsLoading } = useGetUser()

  const { mutate: updateUserMutation } = useUpdateUserMutation(setShowSaveCancel)

  const initialValues = useGetUserFormInitialValues()
  const validationSchema = useGetSettingsProfileFormValidationSchema()

  const {
    isLoading: isLoadingUpdateLanguage,
  } = useUpdateUserLanguage()

  if (fetchIsLoading || !userData || isLoadingUpdateLanguage) {
    return (
      <div className="w-full flex justify-center">
        <CoreLoadingIcon />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => updateUserMutation(values)}
    >
      {({
        errors, touched, values, handleChange, handleSubmit, resetForm,
      }) => (
        <Form className="flex flex-col">
          <div className="flex flex-wrap gap-x-8 gap-y-4">
            <FormikInputGrayUnderline
              type="text"
              name="first_name"
              handleChange={handleChange}
              placeholder="John"
              values={values}
              label="generic.first_name"
              className="w-1/5"
              error={errors.first_name && touched.first_name ? (
                <ErrorMessage error={errors.first_name} />
              )
                : <TransparentError />}
              customOnChange={() => setShowSaveCancel(true)}
            />
            <FormikInputGrayUnderline
              type="text"
              name="last_name"
              handleChange={handleChange}
              placeholder="John"
              values={values}
              label="generic.last_name"
              className="w-1/5"
              error={errors.last_name && touched.last_name ? (
                <ErrorMessage error={errors.last_name} />
              )
                : <TransparentError />}
              customOnChange={() => setShowSaveCancel(true)}
            />
            <FormikInputGrayUnderline
              type="email"
              name="email"
              handleChange={handleChange}
              placeholder="hi@gmail.com"
              values={values}
              error={errors.email && touched.email
                ? <ErrorMessage error={errors.email} />
                : <TransparentError />}
              label="generic.email"
              className="w-2/5"
              customOnChange={() => setShowSaveCancel(true)}
            />
            <FormikInputGrayUnderline
              type="text"
              name="added_on"
              handleChange={handleChange}
              placeholder="22.09.2021"
              values={values}
              disabled
              label="account.user_member_since"
              className="w-1/5"
              customOnChange={() => setShowSaveCancel(true)}
            />
            <FormikInputGrayUnderline
              type="text"
              name="id_provider"
              handleChange={handleChange}
              placeholder="generic.lhotse_capitalized"
              values={values}
              disabled
              label="account.user_identity_provider"
              className="w-1/5"
              customOnChange={() => setShowSaveCancel(true)}
            />
            <FormikInputGrayUnderline
              type="text"
              name="role"
              handleChange={handleChange}
              placeholder="Admin/Business"
              values={values}
              disabled
              label="account.user_role"
              className="w-1/5"
              customOnChange={() => setShowSaveCancel(true)}
            />
            <LanguageSelect handleChange={handleChange} values={values} />
          </div>
          {showSaveCancel && (
            <SaveCancelFormButtons
              onCancel={() => {
                resetForm()
                setShowSaveCancel(false)
              }}
              onSave={handleSubmit}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ProfileForm
