import { useTranslation } from 'react-i18next'
import useAutoSave from '../../../Requisition/utils/useAutoSave'
import CommentInput from './CommentInput'
import DeliveryInput from './DeliveryInput'
import Header from './Header'
import ToggleDeliveryCommentButton from './ToggleDeliveryCommentButton'

const DeliverySection: React.FC = () => {
  const [deliveryAutosave, updateDeliveryAutosave] = useAutoSave()
  const { t } = useTranslation()

  return (
    <div className="border border-gray-200 bg-white rounded-md">
      <Header deliveryAutosave={deliveryAutosave} />
      <div>
        <div className="flex justify-between px-5 py-5 items-center gap-x-4">
          <p className="w-1/2">{t('supplier_offer.expected_delivery_time')}</p>
          <div className="flex items-center gap-x-4 align-center w-1/2 pl-[26px]">
            <DeliveryInput updateDeliveryAutosave={updateDeliveryAutosave} />
            <ToggleDeliveryCommentButton />
          </div>
        </div>
        <CommentInput />
      </div>
    </div>
  )
}

export default DeliverySection
