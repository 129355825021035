import { useMemo } from 'react'
import { useFormLogic } from '../../FormLogicProvider'
import { useFormContext } from '../../../../../../core/providers/FormProvider'

export default () => {
  const {
    responseConfig: [responseConfig],
  } = useFormContext()

  const { enabledSections } = useFormLogic()

  return useMemo(() => enabledSections?.find(
    (section) => section.__uuid === responseConfig.activeSectionUUID || '',
  ) || (enabledSections?.length > 0 && enabledSections[0]) || undefined, [responseConfig.activeSectionUUID, enabledSections])
}
