import { useUtils } from '../../../../core/providers/UtilsProvider'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import SaveCancelFormButtons from '../formik/SaveCancelFormButtons'

interface IConfirmationModal extends INodeChildren {
  onCancel?: () => void
  onConfirm: () => void
  confirmString?: string
  leftNote?: string
}

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  children, onCancel, onConfirm, confirmString, leftNote,
}) => {
  const { modal } = useUtils()
  return (
    <div className="flex flex-col w-full">
      <div className="py-4 text-sm">{children}</div>
      <div className={`flex items-center pt-4 ${leftNote ? 'justify-between' : 'justify-end'}`}>
        <p className="text-xs">{leftNote}</p>
        <SaveCancelFormButtons
          onCancel={() => {
            onCancel?.()
            modal.set({ isOpen: false })
          }}
          onSave={onConfirm}
          saveString={confirmString || 'Ok'}
        />
      </div>
    </div>
  )
}

export default ConfirmationModal
