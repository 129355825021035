interface ITableCell extends React.HTMLAttributes<HTMLTableCellElement> {
  first?: boolean
  last?: boolean
  highlight?: boolean
  className?: string
  dataCy?: string
  colSpan?: number
}

const TableCell: React.FC<ITableCell> = ({
  children, first, highlight, className, dataCy, colSpan, ...rest
}) => (
  <td
    className={`${highlight && 'border-indigo-500'}
   table-cell px-4 py-3 text-sm align-middle border-t ${className} `}
    colSpan={colSpan}
    data-cy={dataCy}
    {...rest}
  >
    {children}
  </td>
)

export default TableCell
