import { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../../core/providers/UtilsProvider'
import { RequisitionInviteType } from '../../../../../../../../types/shared/RequisitionDataTypes'
import useOpenActionModal from '../../../SupplierHeader/ButtonRow/hooks/useOpenActionModal'
import { addOfferToBeLastCalled } from '../../../../utils/OfferViewUtils'
import useGetRequisitionData from '../../../../../Definition/hooks/useGetRequisition'

export default (
  setLastCall: React.Dispatch<SetStateAction<number[] | undefined>>,
  invite: RequisitionInviteType,
) => {
  const { t } = useTranslation()
  const { data: requisition } = useGetRequisitionData()
  const { modal } = useUtils()

  const openActionModal = useOpenActionModal(invite)

  const handleLastCallOption = () => {
    addOfferToBeLastCalled(setLastCall, invite.offer?.id)
    modal.set({
      isOpen: false,
    })
  }

  const requestChangesOption = {
    title: t('offer_view.negotation_individual_change'),
    description: t('offer_view.negotation_individual_change_description'),
    onClick: () => openActionModal('changes'),
    dataCy: 'request-changes-modal-button',
  }

  const lastCallOption = {
    title: t('offer_view.negotation_last_call'),
    description: t('offer_view.negotation_last_call_description'),
    onClick: handleLastCallOption,
    dataCy: 'last-call-negotiation-button',
  }

  if (requisition?.status !== 'CLOSED') {
    return [requestChangesOption]
  }

  return [lastCallOption]
}
