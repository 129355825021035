import { useTranslation } from 'react-i18next'
import { germanPriceStringToFloat } from '../../../../../../../shared/constants/currencyFormat'
import { LineItemItemAttributeType, LineItemOptionType, LineItemType } from '../../../../../../../types/shared/LineItemDataTypes'
import { findLineItem } from '../../../../../utils/utils'
import useGetCalculatedLineItems from '../../../../Footer/hooks/useGetCalculatedLineItems'
import useUpdateLineItem from '../../hooks/useUpdateLineItem'
import LineItem from '../LineItem'
import Comment from './Comment'

interface IOneLineItemPrice {
  option: LineItemOptionType
  row: LineItemItemAttributeType
  updateAutoSave: () => void
  sectionIndex: number
}

const
  OneLineItemPrice: React.FC<IOneLineItemPrice> = ({
    option, row, updateAutoSave, sectionIndex,
  }) => {
    const { t } = useTranslation()
    const { getById } = useGetCalculatedLineItems()
    const initialLineItem = findLineItem(row, option.id) as LineItemType | undefined

    const itemCalculatedPrices = getById(initialLineItem?.id as number)

    const fullLineItem = {
      id: undefined,
      value: null,
      netTotalShippingCost: 0,
      taxRatePercent: 0,
      totalGrossPrice: 0,
      ...initialLineItem,
      ...itemCalculatedPrices,
    }

    const updateLineItem = useUpdateLineItem(updateAutoSave, sectionIndex)

    if (row.type !== 'price' || !fullLineItem) return null

    const handleUpdate = (value: string | boolean | null, attributes = {}) => {
      updateLineItem(
        value,
        fullLineItem.id as number,
        row.id,
        option.id,
        { ...fullLineItem, ...attributes },
      )
    }

    const priceDivider = parseFloat(option?.option_value as string) > 1000 ? 1000 : 1

    return (
      <>
        <div className="flex gap-x-2 items-center">
          <p>
            {`${(parseFloat(option?.option_value as string) || 0).toLocaleString('de-De', { style: 'decimal', maximumFractionDigits: 2 })} 
            ${row?.unit_of_measurement || t('generic.piece_plural')}`}
          </p>
        </div>
        <div className="flex gap-x-4 items-center w-3/4">
          <div className="flex w-full gap-2 items-center justify-end">
            <div className=" flex w-32 ">
              <LineItem
                placeholder="req_def.provide_net_price"
                value={germanPriceStringToFloat(fullLineItem.value || '0') * priceDivider}
                unit="€"
                updateLineItem={(value: string | null) => handleUpdate((germanPriceStringToFloat(value || '0') / priceDivider).toLocaleString('de-De', { style: 'decimal', minimumFractionDigits: 8 }))}
                precision={4}
                dataCy="supplier-offer-price-input"
              />
            </div>
            {priceDivider === 1000 && (
              <span className=" whitespace-nowrap text-sm mr-2 ">
                {`${priceDivider === 1000 ? `/ ${priceDivider} ${row?.unit_of_measurement || t('generic.piece_plural')} ` : ' '}`}
              </span>
            )}
          </div>
          <div className="flex items-center w-full border-r pr-4">
            <LineItem
              placeholder="req_def.total_net_price"
              value={(fullLineItem.totalNetPrice || 0) || null}
              unit="€"
              dataCy="supplier-offer-calculated-price"
            />
          </div>

          <LineItem
            placeholder="req_def.provide_net_shipping_cost"
            updateLineItem={(netTotalShippingCost: string | null) => handleUpdate(fullLineItem.value, { netTotalShippingCost: netTotalShippingCost ? parseFloat(netTotalShippingCost.replace(',', '.')) : 0 })}
            value={fullLineItem.netTotalShippingCost}
            unit="€"
            dataCy="supplier-offer-item-shipping-cost-input"
          />
          <LineItem
            placeholder="req_def.provide_tax_rate"
            updateLineItem={(taxRatePercent: string | null) => handleUpdate(fullLineItem.value, { taxRatePercent: taxRatePercent ? parseFloat(taxRatePercent.replace(',', '.')) : 0 })}
            value={fullLineItem.taxRatePercent}
            unit="%"
            dataCy="supplier-offer-item-tax-rate-input"
          />
          <Comment
            initialLineItem={initialLineItem}
            sectionIndex={sectionIndex}
            row={row}
            option={option}
          />
        </div>
      </>
    )
  }

export default OneLineItemPrice
