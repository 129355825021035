import { MouseEventHandler } from 'react'
import useNavigate from '../../../../../../shared/hooks/useNavigate'
import { IntakeType } from '../../../../../../types/Intake'
import IntakeFlowTitle from './IntakeFlowTitle'

type IntakeFlowRowProps = {
  intake: IntakeType
}

const IntakeFlowRow = ({ intake }: IntakeFlowRowProps) => {
  const navigate = useNavigate()
  const rowOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    const url = `/intake-flow/${intake.uuid}`
    navigate(url, e)
  }

  return (
    <button
      type="button"
      className=" bg-white rounded-md px-6 py-4 flex w-full justify-between
      border border-gray-300 border-opacity-60 text-start items-center
    hover:bg-blue-50 hover:bg-opacity-50"
      onClick={rowOnClick}
    >
      <div>
        <IntakeFlowTitle intake={intake} />
      </div>
    </button>
  )
}

export default IntakeFlowRow
