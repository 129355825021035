import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import SearchSelect from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import useAvailableRequisitionFilters from '../hooks/useAvailableRequisitionFilters'
import { InputSearchSelectItemType } from '../../../../../../types/shared/InputSearchSelectTypes'
import useRequisitionFilterMethods from '../hooks/useRequisitionFilterMethods'

export const RequisitionFilterSelector = () => {
  const { addFilter } = useRequisitionFilterMethods()
  const availableFilters = useAvailableRequisitionFilters()
  const { t } = useTranslation()

  if (availableFilters.length === 0) return null

  const handleChange = (selectedFilter: InputSearchSelectItemType) => {
    const selectedFilterConfig = availableFilters.find(({ id }) => id === selectedFilter.id)
    if (!selectedFilterConfig) return

    if (selectedFilter) addFilter(selectedFilterConfig)
  }

  return (
    <SearchSelect
      alwaysVisible
      onChange={handleChange}
      options={availableFilters}
      placeholder={t('basecamp.requisitions.filter_type')}
      className={classnames('text-xs text-black', { hidden: availableFilters.length === 1 })}
      nullable={false}
      showArrow={false}
      inputWrapperClassNames="p-1"
      showSelectionAsQuery={false}
    />
  )
}

export default RequisitionFilterSelector
