import { useTranslation } from 'react-i18next'
import InputTextGray from '../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import useGetOffer from '../../hooks/useGetOffer'
import useUpdateOfferValue from '../../hooks/useUpdateOfferValue'
import useUpdateOfferValueCache from '../../hooks/useUpdateOfferValueCache'

interface ISupplierNumberInput {
  updateDescriptionAutosave: () => void
}

const SupplierNumberInput: React.FC<ISupplierNumberInput> = ({
  updateDescriptionAutosave,
}) => {
  const { t } = useTranslation()

  const updateOfferValue = useUpdateOfferValue()
  const updateOfferValueCache = useUpdateOfferValueCache()

  const { data: offerData } = useGetOffer()

  return (
    <div className="w-1/2 pl-[33px]">
      <InputTextGray
        placeholder={t('supplier_offer.add_offer_id')}
        fullWidth
        onBlur={(e) => updateOfferValue('supplier_offer_no', e.target.value, updateDescriptionAutosave)}
        value={offerData?.offer?.supplier_offer_no}
        onChange={(e) => updateOfferValueCache(e.target.value, 'supplier_offer_no')}
      />
    </div>
  )
}

export default SupplierNumberInput
