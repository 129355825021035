import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/de'

dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    m: '1 minute',
    mm: '%d min',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
})
dayjs.updateLocale('de', {
  relativeTime: {
    future: 'in %s',
    past: 'vor %s',
    s: 'Sekunden',
    m: '1 Minute',
    mm: '%d min',
    h: '1 Stunde',
    hh: '%d Std.',
    d: '1 Tag',
    dd: '%d Tage',
    M: '1 Monat',
    MM: '%d Monate',
    y: '1 Jahr',
    yy: '%d Jahre',
  },
})

const userLanguage = localStorage.lhotseAuth && JSON.parse(localStorage.lhotseAuth)?.language
dayjs.locale(localStorage.language || userLanguage)
