import { useState } from 'react'
import { useQuery } from 'react-query'
import ContainerRoundedGray from '../../../../../../../../../shared/components/atoms/containers/ContainerRoundedGray'
import Titlebar from '../../../../../../../../../shared/components/layout/Titlebar'
import { CATALOG_ITEM } from '../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../services/UnifiedSearchService'
import { MercateoArticleFeatureType } from '../../../../../../../../../types/Requisition/MercateoItemType'
import CatalogFeaturesTable from './CatalogFeatures/CatalogFeaturesTable'

const CatalogFeatures: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const [articleFeatures, setArticleFeatures] = useState<MercateoArticleFeatureType[] | []>([])

  useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId), {
    onSuccess: (res) => {
      const filteredArticleFeatures = res
        .articleFeatures?.filter((feature) => feature.features?.length)
        .flatMap((feature) => feature.features)
      setArticleFeatures(filteredArticleFeatures)
    },
  })

  if (!articleFeatures.length) return null

  return (
    <ContainerRoundedGray>
      <Titlebar small title="Article Features" />
      <CatalogFeaturesTable articleFeatures={articleFeatures} />
    </ContainerRoundedGray>
  )
}

export default CatalogFeatures
