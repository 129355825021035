import { MdSearch } from 'react-icons/md'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import { useFilters } from '../../providers/FiltersProvider'
import { apiCreateRequisition } from '../../../../services/RequisitionService'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'

const Topbar = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { query: [searchInput, setSearchInput] } = useFilters()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  // TODO: Discuss Changes in React Query
  const { mutate: handleStartFromScratch, isLoading } = useMutation(apiCreateRequisition, {
    onError: () => { toastr.error() },
    onSuccess: (data) => { history.push(`/requisition/req-${data.id}/define`) },
  })

  return (
    <div className="w-full border-b flex justify-between items-center right-0 bg-white h-16 px-8">
      {/* eslint-disable jsx-a11y/no-autofocus */}
      <div className="flex w-1/2 gap-x-4 relative">
        <MdSearch className="absolute text-gray-500 text-xl h-full ml-2" />
        <input
          type="search"
          placeholder={t('generic.what_you_need')}
          className="font-semi pl-8 focus:border-b-2 focus:border-blue-600 border-0 border-b border-gray-200 focus:ring-0 w-4/5"
          onChange={handleSearchChange}
          value={searchInput}
          autoFocus
          data-cy="new-req-search"
        />
      </div>
      <div className="flex w-1/2 justify-end">
        <Button
          className="text-xs w-max"
          color="lhotse-orange"
          variant="solid"
          onClick={() => handleStartFromScratch({ name: searchInput })}
          disabled={searchInput.length === 0}
          loading={isLoading}
          dataCy="create-new-requisition-button"
        >
          {t('requisition.create_new_requisition')}
        </Button>
      </div>
    </div>
  )
}

export default withRouter(Topbar)
