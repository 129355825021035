import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../../core/providers/AbilityProvider'
import { FEATURE_FLAGS } from '../../../../config/ability'

export const useIntakeFlowSubmissionFlag = () => {
  const ability = useAbility(AbilityContext)

  return ability.can(FEATURE_FLAGS.intakeFlowSubmission)
}

export default useIntakeFlowSubmissionFlag
