import { t } from 'i18next'
import Table from '../../../../../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../../../../shared/components/atoms/SharedTable/TableHeader'
import TableRow from '../../../../../../../../../shared/components/atoms/SharedTable/TableRow'
import { MercateoArticleReferenceType } from '../../../../../../../../../types/Requisition/MercateoItemType'

interface ICatalogReferencesTable {
  articleReference: MercateoArticleReferenceType[]
}

const CatalogReferencesTable: React.FC<ICatalogReferencesTable> = ({ articleReference }) => (
  <TableContainer>
    <TableHead>
      <TableHeader>{t('catalog.references.system_name')}</TableHeader>
      <TableHeader>{t('catalog.references.group_id')}</TableHeader>
    </TableHead>
    <Table>
      {articleReference.map((feat) => (
        <TableRow key={feat.groupId}>
          <TableCell first>{feat.systemName}</TableCell>
          <TableCell last>{feat.groupId}</TableCell>
        </TableRow>
      ))}
    </Table>
  </TableContainer>
)

export default CatalogReferencesTable
