import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { RequisitionDataType } from '../../../../../types/shared/RequisitionDataTypes'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import queryKeys from '../constants/queryKeys'
import { updateRequisition } from '../services/metadataService'
import useAutoSave from '../../../utils/useAutoSave'

const useUpdateRequisitionProperty = (key: string) => {
  const cache = useQueryClient()
  const { requisitionId } = useParams<{ requisitionId: string }>()
  const [_, updateAutoSave] = useAutoSave()

  return async (value: any) => {
    try {
      cache.setQueryData<undef<RequisitionDataType>>(
        [queryKeys.requisitionData, requisitionId],
        (oldData) => (
          oldData
            ? ({
              ...oldData,
              [key]: value,
            })
            : oldData
        ),
      )
      await updateRequisition(requisitionId, { [key]: value })
      if (updateAutoSave) {
        updateAutoSave()
      }
    } catch {
      toastr.error()
    }
  }
}

export default useUpdateRequisitionProperty
