import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SourcingContentWrapper from './ReqCoordinate/ReqCoordinateSourcingContent/SourcingContentWrapper'
import SourcingGuideHeader from './ReqCoordinate/SourcingGuideTableHeader/SourcingGuideHeader'
import useGetReqCoordinateTableHeaderData from './ReqCoordinate/SourcingGuideTableHeader/useGetReqCoordinateTableHeaderData'
import SearchInProgressBanner from './ReqCoordinate/SupplierSection/SearchInProgressBanner'
import useGetInitialSourcingGuidePage from './ReqCoordinate/useGetInitialSourcingGuidePage'

const SourcingGuide: React.FC = () => {
  const { t } = useTranslation()

  const getInitialSourcingGuidePage = useGetInitialSourcingGuidePage()

  const [activeSection, setActiveSection] = useState(getInitialSourcingGuidePage)

  const sectionData = useGetReqCoordinateTableHeaderData(setActiveSection)
  const SectionContent = sectionData.find((section) => section.id === activeSection)?.content

  useEffect(() => {
    if (sectionData?.length === 1) { setActiveSection(3) }
  }, [sectionData])

  return (
    <div>
      <div
        className="flex flex-col rounded-lg mb-8"
        data-cy="coordinate-sourcing-guide"
      >
        <SearchInProgressBanner />
        <SourcingGuideHeader
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        {SectionContent
          && (
            <SourcingContentWrapper>
              <SectionContent />
            </SourcingContentWrapper>
          )}
      </div>
    </div>
  )
}

export default SourcingGuide
