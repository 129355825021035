import uuid from 'react-uuid'
import QuestionTypeOption from './QuestionTypeOption'
import { InputTemplate } from '../../../../../../../types/Forms'

type FormElementsListProps = {
  onClick: (input: InputTemplate) => void
}

export const FormElementsList = ({ onClick }: FormElementsListProps) => {
  const formElements: InputTemplate[] = [
    {
      uuid: uuid(),
      category: {
        uuid: '__form-elements', name: 'form-elements',
      },
      type: 'TEXT',
      translate_display_name: null,
      name: 'Sub-header',
      options: [],
      default: false,
      validation: { required: false, min: 0, max: 50000 },
      children: null,
      is_in_use: false,
    },
  ]

  return (
    <div className="flex flex-col gap-y-3 py-3">
      {formElements.map((formElement) => (
        <QuestionTypeOption
          key={formElement.uuid}
          input={formElement}
          handleClick={() => onClick(formElement)}
        />
      ))}
    </div>
  )
}

export default FormElementsList
