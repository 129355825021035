import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export default () => {
  const { t } = useTranslation()

  return Yup.object().shape({
    email: Yup
      .string()
      .required(t('generic.required'))
      .matches(
        /^\S+@\S+\.\S+$/,
        'Please enter a valid email',
      ),
  })
}
