import React, {
  createContext, useCallback, useContext, useMemo, useState,
} from 'react'
import { InputSearchParams } from '../../IntakeConfiguration/services/CompanyInputsService'
import useGetInputTypes, { InputTypesQuery } from '../../Forms/hooks/useGetInputTypes'
import { getMergedDeepOverwritingArrays } from '../../../shared/utils/objectHelpers'

type InputTemplatesContext = {
  templates: InputTypesQuery
  filters: InputSearchParams
  update: (filters: Partial<InputSearchParams>) => void
}

const inputTemplatesContext = createContext({} as InputTemplatesContext)

const InputTemplatesProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [filters, setFilters] = useState<InputSearchParams>({})
  const templates = useGetInputTypes(filters)

  const update = useCallback<InputTemplatesContext['update']>(
    (partialFilters) => {
      setFilters((currentFilters) => getMergedDeepOverwritingArrays(currentFilters, partialFilters))
    },
    [],
  )

  const state: InputTemplatesContext = useMemo(() => ({
    templates,
    filters,
    update,
  }), [templates, filters, setFilters, update])

  return (
    <inputTemplatesContext.Provider value={state}>
      {children}
    </inputTemplatesContext.Provider>
  )
}

export default InputTemplatesProvider

export const useInputTemplates = () => useContext(inputTemplatesContext)
