import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import useUploadSupplierOfferFile from '../../../hooks/useUploadSupplierOfferFile'
import useGetSectionsOrLineItems from '../../DescriptionSection/Sections/hooks/useGetSectionsOrLineItems'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'

const useFinalOfferUploadFile = (
  updateAutoSave: () => void,
  setFileLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const { data: sectionsData } = useGetSectionsOrLineItems()

  const finalOfferSectionData = sectionsData?.find((section) => section.section_type === 'FINAL_OFFER')

  const row = finalOfferSectionData?.item_attributes?.[0]
  const uploadSectionFiles = useUploadSupplierOfferFile(row as LineItemItemAttributeType, setFileLoading, updateAutoSave)

  return async (e: ChangeEvent<HTMLInputElement> | undefined) => {
    try {
      if (e?.target?.files?.length) {
        uploadSectionFiles(e)
      }
    } catch {
      toastr.error()
    }
  }
}

export default useFinalOfferUploadFile
