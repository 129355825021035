import { EllipsisHorizontalIcon, EnvelopeIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import SupplierUserType from '../../../../../../types/Suppliers/SupplierUserType'

import Button from '../../../../../../shared/components/atoms/buttons/Button'
import useOpenCustomInviteModal from '../../hooks/useOpenCustomInviteModal'
import useDeleteSupplierInvite from '../../hooks/useDeleteSupplierInvite'
import Menu, { MenuItemType } from '../../../../../../shared/components/organisms/menus/Menu'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'

interface ISelectionActionButtons {
  supplierInvite: RequisitionInviteType
  supplierUser: SupplierUserType,
}

const SelectionActionButtons: React.FC<ISelectionActionButtons> = ({ supplierInvite, supplierUser }) => {
  const { t } = useTranslation()
  const supplierName = supplierInvite?.supplier_user?.supplier_branch?.name || `${supplierUser.first_name} ${supplierUser.last_name} `

  const handleCustomInviteOnClick = useOpenCustomInviteModal({
    supplierName,
    supplierUserId: supplierUser.id,
    supplierInviteId: supplierInvite?.id,
  })

  const inviteCreated = !supplierInvite?.status || supplierInvite?.status === 'CREATED'
  const handleDeleteInvite = useDeleteSupplierInvite()

  let menuItems: MenuItemType[]
  if (inviteCreated) {
    menuItems = [
      {
        name: t('coordinate.custom_invite', 'Custom Invite'),
        onClick: () => handleCustomInviteOnClick(),
      },
      {
        name: t('generic.delete', 'Delete'),
        onClick: () => handleDeleteInvite(supplierInvite.id),
      },
    ]
  } else {
    menuItems = [
      {
        name: 'req_coordinate.copy_requisition_url',
        onClick: () => {
          const baseURL = window.location.origin
          const url = `${baseURL}/supplier-portal/offer/${supplierInvite?.token}`
          navigator?.clipboard?.writeText(url)
          toastr.success('success.url_copied', 'url-copy')
        },
      },
    ]
  }

  const customButton = (
    <Button className="relative px-1 py-2 text-mountain-gray hover:text-sky-blue-light transition-colors">
      <EllipsisHorizontalIcon className="h-5" />
    </Button>
  )

  return (
    <div className="flex">
      {supplierInvite?.status === 'CREATED' && (
        <Button
          className="px-1 text-mountain-gray hover:text-sky-blue-light transition-colors"
          onClick={handleCustomInviteOnClick}
        >
          <EnvelopeIcon className="h-5" />
        </Button>
      )}

      <Menu menuItems={menuItems} customButton={customButton} />
    </div>
  )
}

export default SelectionActionButtons
