import { ChangeEventHandler, Dispatch, SetStateAction } from 'react'
import { LineItemItemAttributeType } from '../../../types/shared/LineItemDataTypes'
import useUploadNewFiles from '../components/DescriptionSection/Row/hooks/useUploadNewFiles'
import { postLineItem } from '../services/services'
import { getLineItemFromRow } from '../utils/utils'
import useAddLineItemValue from './useAddLineItemValue'
import useGetToken from './useGetToken'

const useUploadSupplierOfferFile = (
  row: LineItemItemAttributeType | undefined,
  setFileIsLoading: Dispatch<SetStateAction<boolean>>,
  updateAutoSave: () => void,
): ChangeEventHandler<HTMLInputElement> => {
  const token = useGetToken()

  const uploadNewFiles = useUploadNewFiles(row, setFileIsLoading)
  const lineItem = getLineItemFromRow(row)

  const addLineItemValue = useAddLineItemValue()

  return async (e) => {
    if (row) {
      if (!lineItem) {
        const { data: newLineItem } = await postLineItem({
          token, item_attribute_id: row.id, lineItemValue: null, optionId: 0,
        })
        addLineItemValue(row.id, newLineItem)
        uploadNewFiles(e, newLineItem)
      }

      if (e?.target?.files && lineItem) { uploadNewFiles(e) }

      updateAutoSave()
    }
  }
}

export default useUploadSupplierOfferFile
