import { useTranslation } from 'react-i18next'
import { IntakeType } from '../../../../../../types/Intake'
import generateUserName from '../../../../../../shared/utils/generateUserName'

type IntakeFlowTitleProps = {
  intake: IntakeType
}

const IntakeFlowTitle = ({ intake }: IntakeFlowTitleProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-start ">
      <div className="flex flex-col gap-2 flex-grow">
        <div className="line-clamp-1 mr-2">
          {`${intake.name}`}
        </div>
        <div className="flex space-x-2 text-gray-400  text-xs">
          <div>
            {`${t('generic.created_by_plural')}: ${generateUserName(intake.creator)}`}
          </div>
          <p>·</p>
        </div>
      </div>
    </div>
  )
}

export default IntakeFlowTitle
