import { FocusEventHandler } from 'react'
import IndentationWrapper from '../../../../../shared/components/atoms/IndentationWrapper'
import InputTextGray from '../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import useOfferIsDisabled from '../../../hooks/useOfferIsDisabled'

interface IComment {
  comment: string | null
  onChange?: (value: string | null) => void
  onBlur: FocusEventHandler<HTMLInputElement>
  noIndent?: boolean
  className?: string
  dataCy?: string
}

const Comment: React.FC<IComment> = ({
  comment, onChange, onBlur, noIndent, className, dataCy,
}) => {
  const isDisabled = useOfferIsDisabled()

  return (
    <IndentationWrapper
      className={`flex items-center gap-x-2 w-full ${className}`}
      disabled={noIndent}
    >
      <div className="flex items-center gap-x-2 relative w-full mr-9">
        <InputTextGray
          value={comment}
          onChange={(e) => onChange && onChange(e.target.value)}
          fullWidth
          placeholder="supplier_view.attributes_item_response_placeholder"
          onBlur={onBlur}
          className="pr-10"
          dataCy={dataCy}
          disabled={isDisabled}
        />
      </div>
    </IndentationWrapper>
  )
}
export default Comment
