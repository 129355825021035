export interface IRadioButton {
  disabled?: boolean
  checked: boolean | undefined
  onClick?: (value: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  dataCy?: string
  name?: string
}

const RadioButton: React.FC<IRadioButton> = ({
  disabled, checked, onClick, dataCy, name,
}) => (
  <input
    type="radio"
    checked={checked}
    disabled={disabled}
    onClick={onClick}
    data-cy={dataCy || 'radio-button'}
    className={`${disabled && 'cursor-disabled bg-gray-200'} ${!disabled && 'cursor-pointer'} text-lhotse-orange`}
    onChange={() => { }}
    name={name}
  />
)

export default RadioButton
