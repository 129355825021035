import { useTranslation } from 'react-i18next'
import CoreLoading from '../../../../../core/components/CoreLoading'
import useGetSearchedSuppliers from '../../../../Suppliers/hooks/useGetSearchedSuppliers'
import ConfirmationSectionAdvarioSupplierInfoModalAction from './ConfirmationSectionAdvarioSupplierInfoModalAction'
import './advario-confirmation.css'

const ConfirmationSectionAdvarioSupplierInfo = ({ supplierName }: { supplierName?: string }) => {
  const { data: searchedSuppliers, isLoading: isSearching } = useGetSearchedSuppliers(supplierName || '', !!supplierName)
  const { t } = useTranslation()
  if (!supplierName) return null
  if (isSearching) return <CoreLoading />

  return (
    <div className="">
      <span className="text-sm mb-2">Details:</span>
      {searchedSuppliers?.data?.[0]?.users?.map((u) => (
        <div key={u.email} className="flex w-full justify-between items-center text-sm  py-4  rounded-md divide-x-2">
          <div className="flex space-x-2 items-center pr-2">
            <b>
              {t('generic.email', 'Email')}
              :
            </b>
            <span>{u.email || <div className="bg-red-500 w-fit px-2 py-1 text-white rounded-lg">No Email</div>}</span>

          </div>
          <div className="flex space-x-2 items-center px-2">
            <b>
              {' '}
              {t('generic.first_name', 'First Name')}
              :
            </b>
            <span>{u.first_name || 'No First Name'}</span>

          </div>
          <div className="flex space-x-2 items-center px-2">
            <b>
              {' '}
              {t('generic.last_name', 'Last Name')}
              :
            </b>
            <span>{u.last_name || 'No Last Name'}</span>
          </div>
          <div className="flex space-x-2 items-center px-2">
            <b>SAP ID:</b>
            <span>{u.supplier_branch.customer_supplier_id || <div className="bg-red-500 w-fit px-2 py-1 text-white rounded-lg">No external Id!</div>}</span>
          </div>
          <div className="flex space-x-2 items-center px-2">
            <ConfirmationSectionAdvarioSupplierInfoModalAction branchId={u.supplier_branch.id} />
          </div>
          {' '}
        </div>
      ))}
    </div>
  )
}

export default ConfirmationSectionAdvarioSupplierInfo
