import { useTranslation } from 'react-i18next'
import useGetInputTypes from '../../../../hooks/useGetInputTypes'
import FormFieldPlaceholder from '../../../../../Forms-v2/FormFieldPlaceholder'
import FormField from '../../../../../Forms-v2/FormField'
import { fromFieldValue, toFieldValue } from '../../../../../Forms-v2/helpers'
import { InputType } from '../../../../../../types/shared/InputType'

type VendorFieldProps = {
  onChange: (value: string) => void
  value: string
}

export const VendorField = ({ value, onChange }: VendorFieldProps) => {
  const { data: inputs } = useGetInputTypes()
  const { t } = useTranslation()

  if (!inputs || inputs.length === 0) return <FormFieldPlaceholder />

  const vendorInput = inputs.find((input) => input.key === 'offer-vendor')

  if (!vendorInput) return <FormFieldPlaceholder />

  return (
    <FormField
      field={{
        id: 'value',
        input: vendorInput as unknown as InputType,
        label: t('generic.vendor', 'Vendor'),
        value: toFieldValue(value, vendorInput),
        disabled: false,
      }}
      updateField={({ value }) => {
        onChange(fromFieldValue(value, vendorInput))
      }}
    />
  )
}

export default VendorField
