import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import useIsSupplierOfferEditable from '../../../hooks/useIsSupplierOfferEditable'
import SubmitModal from '../../SubmitModal'

const useSubmitOffer = (
  validateForm: () => boolean,
  setValidatingOffer: Dispatch<SetStateAction<boolean>>,
) => {
  const utils = useUtils()
  const { userCanEditAnyOffer } = useIsSupplierOfferEditable()
  const { t } = useTranslation()

  return () => {
    const showModal = userCanEditAnyOffer || validateForm()
    setValidatingOffer(false)

    if (showModal) {
      utils.modal.set({
        isOpen: true,
        title: t('confirm.submit_offer'),
        content: <SubmitModal />,
      })
    }
  }
}

export default useSubmitOffer
