import { ChangeEvent } from 'react'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionInputTextGray from '../../components/inputs/QuestionInputTextGray'
import { FormQuestionType } from '../../../../../../types/Forms'
import { IInputTextGray } from '../../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import type { KeysMatching } from '../../../../../../types/shared/TypeHelpers'

type QuestionItemInputProps = {
    title: string
    property: KeysMatching<FormQuestionType, string>
    onChange?: (newValue: string) => void
} & Omit<IInputTextGray, 'onChange' | 'value'>

const QuestionItemInput: React.FC<QuestionItemInputProps> = ({
  title, onChange, property, ...inputProps
}) => {
  const { question: [question], updateQuestion } = useFormContext()

  const handleQuestionChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(value)
    else updateQuestion({ [property]: value })
  }

  return (
    <div className="flex flex-col gap-y-2">
      <p className="text-xs text-primary-gray">
        {title}
      </p>
      <QuestionInputTextGray
        className="w-full max-w-lg"
        onChange={handleQuestionChange}
        value={question?.[property] || ''}
        {...inputProps}
      />
    </div>
  )
}

export default QuestionItemInput
