import { useQuery } from 'react-query'
import usePermissions from '../../../../core/hooks/usePermissions'
import { FORM_VIEW } from '../../../../shared/constants/permissions'
import { apiGetForms, formsQueryKeys, FormsQueryParams } from '../../constants/formService'

export default (params: FormsQueryParams = {}) => {
  const permissions = usePermissions([{ slug: FORM_VIEW }])

  return useQuery(
    [formsQueryKeys.apiGetForms()],
    () => apiGetForms(params),
    { enabled: permissions.get(FORM_VIEW).allowed },
  )
}
