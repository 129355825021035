import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SyntheticInputEvent } from 'react-number-format'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import BaseInputWrapper from '../../../../../../shared/components/atoms/Input/BaseInputWrapper'
import Input from '../../../../../../shared/components/atoms/Input/Input'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import Calendar from '../../../../../../shared/components/organisms/Calendar/Calendar'
import { Offer } from '../../../../../../types/Offering'
import FormNumericInput from '../../../../../Forms-v2/FormInputs/FormNumericInput'
import ProductSectionOfferModalFiles from './ProductSectionOfferModalFiles'
import TextareaInput from '../../../../../../shared/components/atoms/Input/TextareaInput'
import VendorField from './ProductSectionOfferVendorField'

type IProductSectionOfferModal = {
  offer: Offer
  updateOffer: (offer: Offer) => Promise<void>
}
const ProductSectionOfferModal: React.FC<IProductSectionOfferModal> = ({ offer, updateOffer }) => {
  const [currentOffer, setCurrentOffer] = useState<Offer | Partial<Offer>>(offer)
  const [prices, setPrices] = useState({ total_vat: 0, total_gross: 0, total_net: 0 })
  const { t } = useTranslation()

  const { modal } = useUtils()

  const handlePriceCalculation = () => {
    const total_net = (currentOffer?.quantity || 0) * (currentOffer?.price_per_unit || 0)
    const total_vat = total_net * ((currentOffer?.vat || 0) / 100)
    const total_gross = total_net + total_vat

    setTimeout(() => {
      setPrices({
        total_vat,
        total_gross,
        total_net,
      })
    }, 200)
  }

  useEffect(() => {
    handlePriceCalculation()
  }, [])

  const handleValueChange = (key: string, value: string | number | Date | null) => {
    setCurrentOffer({ ...currentOffer, [key]: value })
  }

  const handleUpdate = (e: SyntheticInputEvent) => {
    handleValueChange(e.target.id, e.target.value)
  }

  const submit = () => {
    updateOffer(currentOffer as Offer)
    modal.set({ isOpen: false })
  }

  return (
    <div>
      <div>
        <div className="grid grid-cols-6 gap-4 max-w-3xl mx-auto ">
          <div className="col-span-6">
            <VendorField value={currentOffer.supplier || ''} onChange={(value) => handleValueChange('supplier', value)} />
          </div>
          <div className="col-span-3">
            <Input type="text" label={t('generic.offer-id', 'Offer Id')} id="custom_id" onChange={handleUpdate} defaultValue={currentOffer.custom_id} />
          </div>
          <div className="col-span-3">
            <Calendar
              onChange={(val) => handleValueChange('delivery_date', val)}
              label={t('generic.delivery-date', 'Delivery Date')}
              selected={(currentOffer.delivery_date && new Date(currentOffer.delivery_date)) || undefined}
            />

          </div>
          <div className="col-span-6">
            <TextareaInput
              type="number"
              label={t('generic.comment', 'Comment')}
              id="comment"
              key="comment"
              defaultValue={currentOffer.comment}
              onBlur={(e) => handleValueChange('comment', e as string)}
            />
          </div>
          <div className="col-span-6 border-t my-4" />
          <div className="col-span-5 col-start-2 grid grid-cols-3 gap-4">
            <div>
              <Input
                type="number"
                label={t('generic.quantity', 'Quantity')}
                id="quantity"
                defaultValue={currentOffer.quantity}
                onChange={handleUpdate}
                onBlur={handlePriceCalculation}
              />

            </div>
            <div>
              <BaseInputWrapper label={t('generic.unit-price', 'Unit price')}>
                <FormNumericInput
                  onChange={(val) => handleValueChange('price_per_unit', val)}
                  id="price_per_unit"
                  defaultValue={currentOffer.price_per_unit}
                  onBlur={handlePriceCalculation}
                />
              </BaseInputWrapper>
            </div>
            <div>
              <BaseInputWrapper label={t('generic.total-net-price', 'Total Net Price')}>
                <FormNumericInput disabled onChange={() => { }} value={prices.total_net} className="text-right" />
              </BaseInputWrapper>
            </div>
            <div className="col-start-2">
              <BaseInputWrapper label={t('generic.vat', 'VAT %')}>
                <FormNumericInput
                  onChange={(val) => handleValueChange('vat', val)}
                  id="vat"
                  value={currentOffer.vat}
                  onBlur={handlePriceCalculation}
                />
              </BaseInputWrapper>
            </div>
            <div>
              <BaseInputWrapper label={t('generic.total-vat', 'Total VAT')}>
                <FormNumericInput disabled onChange={() => { }} value={prices.total_vat} className="text-right" />
              </BaseInputWrapper>
            </div>
            <div className="col-start-3">
              <BaseInputWrapper label={t('generic.total-gross', 'Total Gross')}>
                <FormNumericInput disabled onChange={() => { }} value={prices.total_gross} className="text-right" />
              </BaseInputWrapper>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t my-8 py-8">
        <ProductSectionOfferModalFiles offer={offer} />
      </div>

      <div className="flex justify-end w-full gap-4  max-w-3xl mx-auto my-8">
        <Button onClick={() => modal.set({ isOpen: false })} className="btn-ghost">{t('generic.cancel', 'Cancel')}</Button>
        <Button onClick={submit} className="btn-primary">{t('generic.save', 'Save')}</Button>
      </div>
    </div>
  )
}

export default ProductSectionOfferModal
