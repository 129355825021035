/* eslint-disable import/prefer-default-export */

import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../core/providers/UtilsProvider'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import companyQueryStrings from '../constants/companyQueryStrings'
import { apiUpdateCompany } from '../services/CompanyService'

export function useUpdateCompanyAddress(company: {
  address_id: number
}) {
  const cache = useQueryClient()
  const utils = useUtils()

  const { mutate } = useMutation(() => apiUpdateCompany(company), {
    onSuccess: (newData) => {
      cache.setQueryData([companyQueryStrings.company_addresses], () => newData?.addresses)
      utils.modal.set({ isOpen: false })
      toastr.success('success.company_address_change', 'companyAddressChange')
    },
  })
  return mutate
}
