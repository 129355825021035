import { OffersOfferType } from '../../../../../types/shared/OfferDataTypes'
import { RequisitionDataType, RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import { apiPut } from '../../../../../core/utils/api/generic'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { priceToGermanPriceString } from '../../../../../shared/constants/currencyFormat'
import AcceptanceStateType from '../../../../../types/Requisition/AcceptanceStateType'
import { putAcceptedOffer, putCloseRequisition } from '../services/offersService'

export const offerOrder = ['OFFER_ACCEPTED', 'OFFER_CHANGES_REQUESTED', 'OFFER_SUBMITTED', 'OFFER_DECLINED_BY_CUSTOMER', 'OFFER_DECLINED_BY_SUPPLIER', 'OFFER_IN_EDITING', 'INVITED', 'DECLINED_TO_OFFER', 'SUSPENDED']

export type MergedOfferInviteType = OffersOfferType & { supplier_invite_id: number, status_timeline: RequisitionInviteType['status_timeline'] | undefined }

export const getSupplierButtonStyles = (
  invite: RequisitionInviteType,
  buttonStatus: AcceptanceStateType,
  isDisabled: boolean,
) => {
  const isSelected = buttonStatus === invite.status
  const textColorClass = `${isDisabled ? 'text-gray-300' : 'text-secondary-gray'}`

  let iconClasses = textColorClass
  let textClasses = textColorClass
  let buttonClasses = 'rounded'

  if (isSelected) {
    textClasses = 'text-black'
    buttonClasses += ' border'

    switch (invite.status) {
      case 'OFFER_ACCEPTED':
        iconClasses += ' text-green-900'
        buttonClasses += ' border-green-900 bg-green-100'
        break
      case 'OFFER_DECLINED_BY_CUSTOMER':
      case 'OFFER_DECLINED_BY_SUPPLIER':
      case 'DECLINED_TO_OFFER':
        iconClasses += ' text-red-700'
        buttonClasses += ' border-red-700 bg-red-100'
        break
      case 'OFFER_CHANGES_REQUESTED':
        iconClasses += ' text-yellow-500'
        buttonClasses += ' border-yellow-500 bg-yellow-50'
        break
      default:
        break
    }
  }

  if (!isDisabled) {
    buttonClasses += ' hover:bg-blue-100'
    iconClasses += ' group-hover:text-blue-500'
    textClasses += ' group-hover:text-black'
  }

  return { iconClasses, textClasses, buttonClasses }
}

export const acceptOffer = async (
  invite: RequisitionInviteType,
  setStatus: (status: AcceptanceStateType) => any,
  reason: string,
  language: string,
  requisition: RequisitionDataType | undefined,
) => {
  try {
    if (requisition?.status === 'LAST_CALL_NEGOTIATION') { putCloseRequisition(requisition) }
    await putAcceptedOffer(invite, reason, language)
    setStatus('OFFER_ACCEPTED')
    toastr.success('award.congrats_offer_accepted')
  } catch {
    toastr.error()
  }
}

export const declineOffer = async (
  invite: RequisitionInviteType,
  setStatus: (status: AcceptanceStateType) => any,
  reason: string,
  language: string,
) => {
  try {
    await apiPut(`/offers/${invite?.offer?.id}/decline`, { reason, language })
    setStatus('OFFER_DECLINED_BY_CUSTOMER')
  } catch {
    toastr.error()
  }
}

export const requestChanges = async (
  invite: RequisitionInviteType,
  setStatus: (status: AcceptanceStateType) => any,
  reason: string,
  language: string,
) => {
  try {
    await apiPut(`/offers/${invite.offer.id}/request-changes`, { reason, language, negotiation_type: 'NEGOTIATION' })
    setStatus('OFFER_CHANGES_REQUESTED')
  } catch {
    toastr.error()
  }
}

export const calculateNetPrice = (
  grossPrice: number | undefined,
  taxMultiplier: number | undefined,
  shippingCosts: number | undefined,
  isNet: boolean,
) => {
  const parsedTaxMultiplier = 1 + ((taxMultiplier || 0) / 100)
  const netPrice = ((grossPrice || 0) / parsedTaxMultiplier)
  return priceToGermanPriceString((isNet ? grossPrice || 0 : netPrice || 0))
}

export const addOfferToBeLastCalled = (
  setLastCall: React.Dispatch<React.SetStateAction<undefined | number[]>>,
  offerId: number | undefined,
) => {
  if (offerId) {
    setLastCall((lastCallIds) => (lastCallIds ? [...lastCallIds, offerId] : [offerId]))
  }
}

export const removeOfferFromLastCall = (
  setLastCall: React.Dispatch<React.SetStateAction<number[] | undefined>>,
  offerId: number | undefined,
) => {
  if (offerId) {
    setLastCall((lastCallIds) => (lastCallIds
      ? lastCallIds.filter((lastCallId) => lastCallId !== offerId)
      : []
    ))
  }
}
