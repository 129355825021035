import { MdArrowBackIos } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useFormSection } from './providers/FormSectionProvider'
import { useFormContext } from '../../../core/providers/FormProvider'

interface IPreviousSectionButton { }

const PreviousSectionButton: React.FC<IPreviousSectionButton> = () => {
  const { t } = useTranslation()
  const { goToPreviousSection, currentSection } = useFormSection()
  const { responseConfig: [responseConfig] } = useFormContext()

  if (responseConfig?.viewType === 'form' && currentSection?.__uuid !== 'Confirmation') return null

  if (!currentSection?.previousSection) return <div />

  return (
    <button
      type="button"
      className="disabled:opacity-25 px-6 text-sm py-2.5
        text-sky-blue-light border border-sky-blue-light bg-background hover:border-sky-blue hover:text-sky-blue
        transition-colors rounded-md flex gap-x-2 items-center w-fit"
      onClick={goToPreviousSection}
    >
      <MdArrowBackIos />
      <p className="whitespace-nowrap">
        {t('forms.previous_section', 'Previous section')}
      </p>
    </button>
  )
}

export default PreviousSectionButton
