import { Combobox } from '@headlessui/react'
import { FilteredActionPanelResultType } from '../../../../types/shared/ActionPanelTypes'

interface IActionPanelFoundResult {
  project: FilteredActionPanelResultType
}

const ActionPanelFoundResult: React.FC<IActionPanelFoundResult> = ({ project }) => (
  <Combobox.Option
    value={project}
    className={({ active }) => `flex cursor-default select-none items-center px-4 cursor-pointer'${active && 'text-sky-blue font-bold'}`}
  >
    {() => <span className="ml-3 flex-auto truncate py-2">{project.name}</span>}
  </Combobox.Option>
)

export default ActionPanelFoundResult
