import { useAbility } from '@casl/react'
import CorePageContainerLayout from '../../../core/components/layout/CorePageContainerLayout'
import Header from './SuppliersTable/Header'
import SuppliersTable from './SuppliersTable/SuppliersTable'
import { ABILITIES } from '../../../config/ability'
import { AbilityContext } from '../../../core/providers/AbilityProvider'
import Warning from '../../../shared/components/organisms/WarningBanner/WarningBanner'

const SuppliersContainer: React.FC = () => {
  const ability = useAbility(AbilityContext)
  return (
    <CorePageContainerLayout className="flex justify-center">
      <div className="flex flex-col w-full 2xl:w-[1300px]">
        {ability.cannot(ABILITIES.createSupplier) && (
          <Warning severity="warning" className="mb-8">
            Zur Anlage oder Änderung von Lieferanten (vorhandene bevorzugen) bitte ans Einkaufsteam wenden unter:
            {' '}
            <a href="mailto:einkauf-funke-mediengruppe@funkemedien.de">einkauf-funke-mediengruppe@funkemedien.de</a>

          </Warning>
        )}
        {ability.cannot(ABILITIES.larrySearch) ? <Header /> : ''}
        <SuppliersTable />
      </div>
    </CorePageContainerLayout>
  )
}
export default SuppliersContainer
