import useIsTooltipTextTruncated from '../../../../shared/components/organisms/tooltip/hooks/useIsTooltipTextTruncated'
import Tooltip from '../../../../shared/components/organisms/tooltip/Tooltip'

interface INameInner {
  userName: string
}

const NameInner: React.FC<INameInner> = ({
  userName,
}) => {
  const { ref, isTextTruncated } = useIsTooltipTextTruncated()

  return (
    <Tooltip
      tooltip={userName}
      showCondition={isTextTruncated}
      className="overflow-ellipsis max-w-xs"
    >
      <p className="line-clamp-1 leading-snug text-left text-sm" ref={ref}>
        {userName}
      </p>
    </Tooltip>
  )
}

export default NameInner
