import { PopupBox, PopupBoxProps } from './PopupBox'
import Button from '../buttons/Button'
import usePopupBoxState from './hooks/usePopupBoxState'

type ButtonWithPopupBoxProps = {
  text: string,
  disabled?: boolean
} & Omit<PopupBoxProps, 'open' | 'onClose'>

export const ButtonWithPopupBox = ({
  text, children, disabled = false, ...props
}: ButtonWithPopupBoxProps) => {
  const { isOpen, close, toggle } = usePopupBoxState()

  return (
    <div className="relative z-10">
      <Button
        disabled={disabled}
        className="inline-flex items-center px-4 font-sans text-sm font-medium text-sky-blue-light hover:text-sky-blue cursor-pointer"
        onClick={toggle}
      >
        {text}
      </Button>
      <PopupBox open={isOpen} onClose={close} x="right-0" {...props}>
        {children}
      </PopupBox>
    </div>

  )
}

export default ButtonWithPopupBox
