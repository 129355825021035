import { useEffect, useState } from 'react'
import apiClient from '../../../core/utils/apiClient'

const useIsActiveAPICall = () => {
  const [numActiveCalls, setNumActiveCalls] = useState(0)

  const incrementNumActiveCalls = (direction: 1 | -1) => {
    setNumActiveCalls((currNumActiveCalls) => currNumActiveCalls + direction)
  }


  useEffect(() => {
    const requestInterceptor = apiClient.interceptors.request.use((config) => {
      incrementNumActiveCalls(1)
      return config
    }, (error) => Promise.reject(error))

    const responseInterceptor = apiClient.interceptors.response.use((response) => {
      incrementNumActiveCalls(-1)
      return response
    }, (error) => {
      incrementNumActiveCalls(-1)
      return Promise.reject(error)
    })

    return () => {
      apiClient.interceptors.request.eject(requestInterceptor)
      apiClient.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return numActiveCalls
}

export default useIsActiveAPICall
