import { Dispatch, SetStateAction } from 'react'
import { MdExpandMore } from 'react-icons/md'
import CoreContainerLayout from '../../../../../../../../core/components/layout/CoreContainerLayout'
import SharedAutoSave from '../../../../../../../../shared/components/organisms/AutoSave/AutoSave'

interface ISectionHeader {
  title: string
  toggleSection: Dispatch<SetStateAction<boolean>>
  sectionVisible: boolean
  autoSave: boolean
}

const SectionHeader: React.FC<ISectionHeader> = (
  {
    title, toggleSection, sectionVisible, autoSave,
  },
) => (
  <CoreContainerLayout
    className={`flex justify-between items-center px-7 bg-white py-4 ${sectionVisible && 'rounded-b-none'} cursor-pointer`}
    onClick={() => toggleSection((prev) => !prev)}
  >
    <p className="text-sm">{title}</p>
    <div className="flex items-center gap-x-3">
      <SharedAutoSave autoSave={autoSave} />
      <MdExpandMore className={`text-gray-400 cursor-pointer transform ${!sectionVisible && 'rotate-180'}`} size={25} />
    </div>
  </CoreContainerLayout>
)

export default SectionHeader
