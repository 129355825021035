import { useTranslation } from 'react-i18next'
import { CalendarIcon, UsersIcon } from '@heroicons/react/24/outline'
import { WorkflowBlock } from '../../../../../../../types/Workflow/WorkflowBlock'
import Button from '../../../../../../../shared/components/atoms/buttons/Button'
import generateUserName from '../../../../../../../shared/utils/generateUserName'
import { dateTimeFormatter } from '../../../../../../../shared/constants/dateFormat'
import useBlockStatus from '../hooks/useBlockStatus'

export const IntakeFlowchartApprovalNodeContentDeadline = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-2 ">
      <div className="flex flex-row  text-gray-400 gap-2">
        <div className="flex-grow-0">
          <CalendarIcon className="h-5" />
        </div>
        <div className="flex-grow-1">
          {t('intake.flowchart.approvalNodeContent.deadline', 'Deadline')}
        </div>
      </div>
      <div className="flex flex-row">
        {block.workflow_block_status && dateTimeFormatter(block.workflow_block_status.action_date)}
      </div>
    </div>
  )
}

export const IntakeFlowchartApprovalNodeContentApprovers = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row  text-gray-400 gap-2">
        <div className="flex-grow-0">
          <UsersIcon className="h-5" />
        </div>
        <div className="flex-grow-1">
          {t('intake.flowchart.approvalNodeContent.approver_counter', 'Approver')}
        </div>
      </div>
      <div className="flex flex-row">
        {block.approvers.length}
        {' '}
        {t(
          'intake.flowchart.approvalNodeContent.number_of_approvers',
          block.approvers.length > 1 ? 'approvers' : 'approver',
        )}
      </div>
    </div>
  )
}
export const IntakeFlowchartApprovalNodeContent = ({ block }: { block: WorkflowBlock }) => {
  const { t } = useTranslation()

  const { isApproved, requiresCurrentUserApproval } = useBlockStatus(block)

  return (
    <div className=" ">
      <div className="flex w-full gap-6 ">
        <IntakeFlowchartApprovalNodeContentApprovers block={block} />
        <IntakeFlowchartApprovalNodeContentDeadline block={block} />
      </div>
      {isApproved && (
        <div className="flex flex-col gap-2 w-full">
          <div className="text-sky-blue-light font-semibold">
            {t('intake.flowchart.approvalNodeContent.approved_by', 'Approved by')}
          </div>
          <div>
            {block.approvers.map((approver) => (
              <div key={`block-${block.uuid}-approver-${approver.user.id}`}>
                {t(generateUserName(approver.user))}
                {', '}
                {dateTimeFormatter(approver.updated_at)}
              </div>
            ))}
          </div>
        </div>
      )}
      {requiresCurrentUserApproval && (
        <div className="flex flex-row">
          <Button className="flex-grow-1 bg-sky-blue-light w-full text-white justify-center" variant="primary">
            {t('intake.flowchart.approvalNodeContent.approve', 'Waiting for approval')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default IntakeFlowchartApprovalNodeContent
