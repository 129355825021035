import Menu, { IMenu, MenuItemType } from '../../../../shared/components/organisms/menus/Menu'

type WorkflowMenuProps = Omit<IMenu, 'customButton' | 'menuItems'> & {
  items: (MenuItemType | undefined | false)[]
}
export const WorkflowMenu = ({ items, ...props }: WorkflowMenuProps) => (
  <Menu
    {...props}
    menuItems={items.filter(Boolean) as MenuItemType[]}

  />
)

export default WorkflowMenu
