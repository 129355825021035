import { useQuery } from 'react-query'
import { apiGetExternalDataEntityData } from '../services/ExternalDataServices'

const useGetExternalDataEntityData = (fileUuid: string | undefined, queryString?: string) => {
  const { data, isLoading, error } = useQuery(
    ['entity-column-data', fileUuid, queryString],
    () => {
      if (!fileUuid) return Promise.resolve([])

      return apiGetExternalDataEntityData(fileUuid, queryString)
    },
  )

  return {
    data,
    isLoading,
    error,
  }
}

export default useGetExternalDataEntityData
