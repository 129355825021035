import { useQuery } from 'react-query'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import queryKeys from '../constants/OfferViewQueryKeys'
import { getInvitesWithOffers } from '../services/offersService'

const useGetInvitesWithOffers = () => {
  const requisitionId = useGetRequisitionId()

  return useQuery(
    [queryKeys.OFFERS_DATA, requisitionId],
    () => getInvitesWithOffers(requisitionId),
  )
}

export default useGetInvitesWithOffers
