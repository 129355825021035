// REFACTOR
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { MdFilePresent } from 'react-icons/md'
import usePermissions from '../../../../core/hooks/usePermissions'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import TableCell from '../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../shared/components/atoms/SharedTable/TableRow'
import EditDeleteOptions from '../../../../shared/components/organisms/menus/EditDeleteMenu'
import { dateTimeFormat } from '../../../../shared/constants/dateFormat'
import { ORGANISATION_EDIT } from '../../../../shared/constants/permissions'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import { documentTypesObject } from '../../constants/documentTypeConstants'
import { useDeleteDocument } from '../../services/CompanyService'
import DocumentForm from './DocumentForm/DocumentForm'

interface IDocumentsListRow {
  document: CustomerDocumentType
}
const DocumentsListRow: React.FC<IDocumentsListRow> = ({ document }) => {
  const { t } = useTranslation()
  const utils = useUtils()
  const permissions = usePermissions([{ slug: ORGANISATION_EDIT }])
  const handleOnEdit = () => {
    utils.modal.set({
      isOpen: true,
      title: t('generic.edit'),
      content: <DocumentForm document={document} />,
    })
  }

  const handleOnDelete = useDeleteDocument(document.id)

  return (
    <TableRow>
      <TableCell first>
        <div className="flex items-center gap-2">
          <MdFilePresent className="text-gray-400 text-2xl" />
          <div className="truncate max-w-[600px]">{document.name}</div>
        </div>
      </TableCell>
      <TableCell>
        {t(documentTypesObject[document.type].label) || ''}
      </TableCell>
      <TableCell>
        {document.updated_at && dayjs(document.updated_at).format(dateTimeFormat)}
      </TableCell>
      <TableCell last>
        {permissions.get(ORGANISATION_EDIT).allowed
          && <EditDeleteOptions onEdit={handleOnEdit} onDelete={handleOnDelete} />}
      </TableCell>
    </TableRow>
  )
}

export default DocumentsListRow
