import { getQuestionValidationValue } from '../formValidationSchema'

/**
 *
 * @param {FormQuestionType} relatedQuestion
 * @param {AnswerSelectMethod} answerSelector
 * @returns {string|number|string[]|number[]|undefined}
 */
export const getQuestionAnswerForComparison = (relatedQuestion, answerSelector) => {
  if (!relatedQuestion) return undefined
  const values = getQuestionValidationValue(relatedQuestion)?.filter((value) => value !== undefined)

  if (values.length === 0) return undefined

  const formatValue = (value) => (relatedQuestion.type !== 'NUMBER' ? value : parseFloat(value))

  if (!answerSelector || answerSelector === 'first_value') {
    return formatValue(values[0])
  }

  if (answerSelector === 'sum') { return values.reduce((sum, value) => sum + formatValue(value), 0) }

  return values
}

export default getQuestionAnswerForComparison
