import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { priceToGermanPriceString } from '../../../../shared/constants/currencyFormat'
import { SupplierRequisitionType } from '../../../../types/SupplierDetails/SupplierRequisitionType'

interface ISupplierRequisitionRow {
  requisition: SupplierRequisitionType
  isMinified?: boolean
}

const SupplierRequisitionRow: React.FC<ISupplierRequisitionRow> = ({
  requisition, isMinified,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full gap-x-3">
      <div className="w-2/12">{dayjs(requisition.offer_created_at).format('DD.MM.YYYY')}</div>
      <div className="w-4/12 border-t-0 border-b-0 font-medium">{t(requisition.status)}</div>
      {!isMinified && <div className="w-2/12">{`${priceToGermanPriceString(requisition.offer_price)}`}</div>}
      <div className="w-4/12 truncate">{requisition.requisition_name}</div>
    </div>
  )
}

export default SupplierRequisitionRow
