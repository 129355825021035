import { useQuery } from 'react-query'
import { apiGetTransmissionPreview } from '../../../../Requisition/services/WorkflowService'
import './advario-confirmation.css'
import Collapsible from '../../../../../shared/components/atoms/Collapsible/Collapsible'

const ConfirmationSectionAdvarioTransmission = ({ requisitionId }: { requisitionId?: string }) => {
  const { data, isLoading } = useQuery(['transmission-preview', requisitionId], () => apiGetTransmissionPreview(requisitionId || ''), { enabled: !!requisitionId })
  if (isLoading) return null

  return (
    <Collapsible title="Payload Preview" defaultOpen={false}>
      <pre className="bg-gray-100 border border-gray-300 rounded-lg p-4 overflow-clip text-xs">{JSON.stringify(data, null, 2)}</pre>
    </Collapsible>
  )
}

export default ConfirmationSectionAdvarioTransmission
