import { useTranslation } from 'react-i18next'
import Titlebar from '../../../shared/components/layout/Titlebar'
import WorkflowsTable from '../components/WorkflowsTable/WorkflowsTable'
import CreateNewWorkflowButton from '../components/misc/CreateNewWorkflowButton'

export const WorkflowsPage = () => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col h-full">
      <Titlebar title={t('generic.workflows', 'Workflows')}>
        <div className="flex gap-x-2">
          <CreateNewWorkflowButton />
        </div>
      </Titlebar>
      <WorkflowsTable />
    </div>
  )
}

export default WorkflowsPage
