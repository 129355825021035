import { useMessengerContext } from '../../../../core/providers/MessengerProvider'
import useIntakeId from '../../../Intake/hooks/useIntakeId'

export default () => {
  const modelId = useIntakeId()
  const { messageProperties: [{ messageContent }, setMessageProperties] } = useMessengerContext()

  const activeMessage = messageContent?.[modelId] || ''

  const setActiveMessage = (text: string) => {
    setMessageProperties((messageProperties) => ({
      ...messageProperties,
      messageContent: {
        ...messageProperties.messageContent,
        [modelId]: text,
      },
    }))
  }

  return [activeMessage, setActiveMessage] as const
}
