import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import Checkbox from '../../../../../../shared/components/atoms/inputs/Checkbox'
import RequisitionOptionType from '../../../../../../types/Requisition/RequisitionOptionType'
import useOfferIsDisabled from '../../../../hooks/useOfferIsDisabled'
import { findLineItem } from '../../../../utils/utils'
import useUpdateLineItem from '../hooks/useUpdateLineItem'
import Comment from './OneLineItem/Comment'

interface IMultiSelect {
  option: RequisitionOptionType
  sectionIndex: number
  row: LineItemItemAttributeType
  updateAutoSave: () => void
}

const MultiSelect: React.FC<IMultiSelect> = ({
  option, sectionIndex, row, updateAutoSave,
}) => {
  const isDisabled = useOfferIsDisabled()
  const updateLineItem = useUpdateLineItem(updateAutoSave, sectionIndex)

  const currentLineItem = findLineItem(row, option.id)

  return row.type === 'multi-select'
    ? (
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-x-2 ">
          <Checkbox
            checked={!!JSON.parse(currentLineItem?.value || 'false')}
            onChange={(value) => updateLineItem(value, currentLineItem?.id as number, row.id, option.id)}
            disabled={isDisabled}
            dataCy="multiselect-option"
          />
          <p>{option?.option_value}</p>
        </div>
        <Comment
          initialLineItem={currentLineItem}
          sectionIndex={sectionIndex}
          row={row}
          option={option}
        />
      </div>
    )
    : null
}

export default MultiSelect
