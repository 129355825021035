import { useBasecampContext } from '../../../core/providers/BasecampProvider'
import RequisitionsSearch from './BasecampTable/RequisitionSearch'
import RequisitionsTable from './BasecampTable/RequisitionsTable'
import RequisitionsSearchBar from './BasecampTable/Filters/RequisitionsSearchBar'
import RequisitionsFiltersBar from './BasecampTable/Filters/RequisitionsFiltersBar'
import { RequisitionFiltersProvider } from './BasecampTable/Filters/provider/RequisitionFiltersProvider'

const Table: React.FC = () => {
  const { requisitionSearch: [searchQuery] } = useBasecampContext()

  return (
    <RequisitionFiltersProvider>
      <div className="max-w-4xl w-full flex flex-col">
        <div className="flex flex-col justify-end mb-2.5">
          <RequisitionsSearchBar />
          <RequisitionsFiltersBar />
        </div>
        {searchQuery
          ? <RequisitionsSearch />
          : <RequisitionsTable />}
      </div>
    </RequisitionFiltersProvider>
  )
}

export default Table
