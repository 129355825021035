import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../core/providers/UtilsProvider'
import ConfirmationModal from '../../../../../../../shared/components/organisms/modals/ConfirmationModal'
import TipTapEditor from '../../../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'

interface ICustomInviteModal {
  handleSendInvite: (reason?: string) => void
  supplierName: string
}

const CustomInviteModal: React.FC<ICustomInviteModal> = (
  { handleSendInvite, supplierName },
) => {
  const { t } = useTranslation()
  const utils = useUtils()

  const [customMessage, setCustomMessage] = useState('')

  const handleModalSuccess = () => {
    utils.modal.set({ isOpen: false })
    handleSendInvite(customMessage)
  }

  return (
    <ConfirmationModal
      onConfirm={handleModalSuccess}
      confirmString="generic.send"
      leftNote={t('req_coordinate.after_inviting_supplier_cannot_edit_requisition')}
    >
      <div className="flex flex-col gap-4">
        <p>
          {t('req_coordinate.you_are_about_to_invite')}
          {' '}
          {supplierName}
          {' '}
          {t('req_coordinate.to_this_requisition')}
        </p>
        <div className="flex w-full border rounded-md h-24">
          <TipTapEditor
            onUpdate={(v) => setCustomMessage(JSON.stringify(v))}
            content=""
            editable
            placeholder="generic.add_custom_note"
            expandedEditor
            dataCy="custom-invite-input"
          />
        </div>
      </div>
    </ConfirmationModal>
  )
}

export default CustomInviteModal
