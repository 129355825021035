import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import CoreLoading from '../../../../core/components/CoreLoading'
import { useFormContext } from '../../../../core/providers/FormProvider'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import Button from '../../../../shared/components/atoms/buttons/Button'
import ConfirmationModal from '../../../../shared/components/organisms/modals/ConfirmationModal'
import BlueButton from '../../../../shared/newui/BlueButton'
import useGetFormParams from '../../FormBuilder/hooks/useGetFormParams'
import { apiPostSubmission, apiSubmitSubmission } from '../../constants/formService'
import useBackendSubmission from '../hooks/useBackendSubmission'
import useFormValidation from '../hooks/useFormValidation'
import useGetFormSubmission from '../hooks/useGetFormSubmission'
import useGetTotalPrice from '../hooks/useGetTotalPrice'
import useHandleAdvarioStatusUpdate from '../hooks/useHandleAdvarioStatusUpdate'
import useHandleAdvarioSubmit from '../hooks/useHandleAdvarioSubmit'
import useHandleSoftSubmit from '../hooks/useHandleSoftSubmit'
import { useFormLogic } from '../providers/FormLogicProvider'
import AdvarioTransmitButton from '../sections/ConfirmationSection/AdvarioTransmitButton'
import ConfirmationSectionAdvarioDeclineModal from '../sections/ConfirmationSection/ConfirmationSectionAdvarioDeclineModal'

const ActionButton = () => {
  const { t } = useTranslation()
  const [advarioIsNotYetSubmittedByRequestor, setAdvarioIsNotYetSubmittedByRequestor] = useState<boolean>(true)
  const { formId, submissionId } = useGetFormParams()
  const { isLoading: isLoadingSubmission, data: submissionFormData } = useGetFormSubmission()
  const { responseConfig: [responseConfig, setResponseConfig] } = useFormContext()
  const client = useQueryClient()
  const submissionWithBackendData = useBackendSubmission()

  const isAdvarioTenant = !!window.location.href.includes('advario')

  useEffect(() => {
    setAdvarioIsNotYetSubmittedByRequestor(isAdvarioTenant && !submissionFormData?.assignments[0])
  }, [submissionFormData])

  const {
    isLoading, priceEqual,
  } = useGetTotalPrice()

  const { allProcessableQuestions } = useFormLogic()
  const { errors } = useFormValidation()
  const utils = useUtils()
  const history = useHistory()

  const shouldCheckOfferValue = window.location.host.includes('fraport') // Currently only Fraport specific, but logic might be extended in the future

  const { mutate: saveDraftMutation } = useMutation('createFormSubmission', () => apiPostSubmission(formId, submissionWithBackendData), {
    onSuccess: (data: { uuid: string }) => {
      if (data.uuid) {
        history.push(`/forms/${formId}/submission/${data.uuid}`)
      }
    },
  })

  const { mutate: submittedSubmission } = useMutation(
    'submitSubmission',
    () => apiSubmitSubmission(formId, submissionId),
    {
      onSuccess: async (data) => {
        utils.modal.set({ isOpen: false })

        if (!data.success) return

        await client.resetQueries(['requisitions'])

        if (data.entity === 'REQUISITION') {
          history.push(`/requisition/req-${data.entity_id}`)
        }
        if (data.entity === 'INTAKE_FLOW') {
          history.push(`/intake-flow/${data.entity_id}`)
        }
      },
    },
  )

  const { softSubmit } = useHandleSoftSubmit()
  const [isLoadingAdvarioTransmission, setIsLoadingAdvarioTransmission] = useState<boolean>(false)
  const { submitAndTransfer } = useHandleAdvarioSubmit(() => setIsLoadingAdvarioTransmission(false))

  const handleAdvarioSubmit = () => {
    // This is an advario specific function that only assignes a user
    if (advarioIsNotYetSubmittedByRequestor) { softSubmit(); return }
    setIsLoadingAdvarioTransmission(true)
    submitAndTransfer()
  }

  const handleClickSubmitForm = () => {
    if ((!!errors || allProcessableQuestions.length === 0 || (!priceEqual && shouldCheckOfferValue) || responseConfig.activeSectionUUID !== 'Confirmation')
      && !window.location.href.includes('/confirmation')) {
      setResponseConfig({ ...responseConfig, activeSectionUUID: 'Confirmation' })
      return
    }

    utils.modal.set({
      isOpen: true,
      title: t('generic.confirm_submission_title'),
      content: (
        <ConfirmationModal
          confirmString={t('submission.confirm_submission_button')}
          onConfirm={isAdvarioTenant ? handleAdvarioSubmit : submittedSubmission}
        >
          {t('submission.confirm_submission')}
        </ConfirmationModal>),
    })
  }

  const { mutate: updateStatus } = useHandleAdvarioStatusUpdate()

  const handleAdvarioDeclineConfirmation = () => {
    // This is an advario specific function that only assignes a user
    utils.modal.set({
      isOpen: true,
      title: t('advario.decline-request', 'Decline Request'),
      content: <ConfirmationSectionAdvarioDeclineModal handleDecline={(reason?: string) => updateStatus({ reason, status: 'DECLINED' })} />,
    })
  }

  if (isLoadingSubmission) return <CoreLoading />

  if (submissionFormData?.submitted && submissionFormData.requisition_id && isAdvarioTenant) {
    return (
      <AdvarioTransmitButton requisitionId={submissionFormData.requisition_id?.toString()} />
    )
  }

  if (submissionId && isAdvarioTenant) {
    if (submissionFormData?.status?.name === 'DECLINED') return <Button className="bg-red-200 border-red-400 border" disabled>{t('advario.request-declined', 'This Request has been declined')}</Button>
    return (
      <>
        {!submissionFormData?.requisition_id && window.location.href.includes('confirmation') && <Button className="bg-red-200 border-red-400 border" onClick={handleAdvarioDeclineConfirmation}>{t('generic.decline', 'Decline')}</Button>}

        <BlueButton
          onClick={handleClickSubmitForm}
          disabled={(!window.location.href.includes('confirmation') && responseConfig.activeSectionUUID !== 'Confirmation') || submissionFormData?.submitted || (!priceEqual && shouldCheckOfferValue) || isLoading || isLoadingAdvarioTransmission}
        >
          {advarioIsNotYetSubmittedByRequestor ? t('generic.submit-request', 'Submit Request') : t('generic.transfer-to-sap', 'Transfer to SAP')}

        </BlueButton>
      </>
    )
  }

  if (submissionId) {
    return (
      <BlueButton
        onClick={handleClickSubmitForm}
        disabled={(!window.location.href.includes('confirmation') && responseConfig.activeSectionUUID !== 'Confirmation') || submissionFormData?.submitted || (!priceEqual && shouldCheckOfferValue) || isLoading}
      >
        {advarioIsNotYetSubmittedByRequestor ? 'Confirm Submission to Procurement' : t('forms.submit_form')}
      </BlueButton>
    )
  }

  return (
    <BlueButton onClick={saveDraftMutation}>
      {t('forns.start_submission')}
    </BlueButton>
  )
}

export default ActionButton
