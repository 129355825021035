import { useTranslation } from 'react-i18next'
import Input from '../../../../../shared/components/atoms/Input/Input'
import { useWorkflowBlockDraft } from '../../../providers/WorkflowBlockDraftProvider'
import { useWorkflow } from '../../../providers/WorkflowProvider'

export const WorkflowBuilderBlockMisc = () => {
  const { t } = useTranslation()
  const { readonly } = useWorkflow()
  const { block, updateBlock } = useWorkflowBlockDraft()

  return (
    <div className="flex flex-col">
      <Input disabled={readonly} value={block.name} onChange={({ currentTarget: { value: name } }) => updateBlock({ name })} placeholder={t('workflow.block_name_placeholder', 'Block name')} />
    </div>
  )
}

export default WorkflowBuilderBlockMisc
