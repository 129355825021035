import { Combobox } from '@headlessui/react'
import classnames from 'classnames'
import {
  ChangeEvent, HTMLAttributes, useEffect, useState,
} from 'react'
import { HiChevronDown, HiX } from 'react-icons/hi'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { isEqual } from 'lodash'
import InputError from '../../../../modules/Forms/FormResponsePage/QuestionTypes/atoms/InputError'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelectOption from './SearchSelectOption'
import SearchSelectOptions from './SearchSelectOptions'

export interface ISearchSelect {
  onChange: (value: InputSearchSelectItemType) => void, // todo this callback actually receives value: InputSearchSelectItemType | undefined
  onClear?: () => void,
  disabled?: boolean,
  children?: ReactI18NextChild | Iterable<ReactI18NextChild>,
  options?: InputSearchSelectItemType[],
  currentSelection?: InputSearchSelectItemType
  onInputChange?: (value: string) => any
  placeholder?: string
  className?: HTMLAttributes<HTMLDivElement>['className']
  wrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionsWrapperClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionsClassName?: HTMLAttributes<HTMLDivElement>['className']
  optionClassName?: HTMLAttributes<HTMLDivElement>['className']
  nullable?: boolean
  error?: string,
  onAdd?: () => void,
  addButtonText?: string,
  isLoading?: boolean,
  alwaysVisible?: boolean,
  showArrow?: boolean,
  inputWrapperClassNames?: HTMLAttributes<HTMLDivElement>['className']
  showSelectionAsQuery?: boolean
  showSearchIcon?: boolean,
  inputId?: string
  autoClear?: boolean
  visibleColumns?: { [key: string]: any } // Column headers from an external data source, if applicable
}

const SearchSelect: React.FC<ISearchSelect> = ({
  options,
  onChange,
  currentSelection,
  disabled,
  onInputChange,
  placeholder,
  nullable,
  wrapperClassName,
  error,
  onAdd,
  addButtonText,
  onClear = () => { },
  children,
  isLoading = false,
  alwaysVisible = false,
  showArrow = true,
  inputWrapperClassNames,
  showSelectionAsQuery = true,
  optionsWrapperClassName,
  optionsClassName,
  optionClassName,
  showSearchIcon = false,
  inputId = '',
  autoClear = false,
  visibleColumns,
}) => {
  const [query, setQuery] = useState('')
  const [selection, setSelection] = useState<InputSearchSelectItemType | undefined>(
    (currentSelection && { id: currentSelection?.id, label: currentSelection.label }) || undefined,
  )

  const clear = () => {
    setSelection(undefined)
    setQuery('')
    onClear()
  }

  useEffect(() => {
    setSelection((currentSelection && { id: currentSelection.id, label: currentSelection.label }) || undefined)
  }, [currentSelection])

  // If options change, and currentSelection is in options, update selection
  useEffect(() => {
    if (selection && options?.some((option) => option.id === selection.id)) {
      setSelection({ id: selection?.id, label: selection.label })
    }
  }, [JSON.stringify(options)])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    onInputChange?.(e.target.value)
  }

  const handleSelection = (value: InputSearchSelectItemType | undefined) => {
    if (!value) {
      setSelection(undefined)
      onClear && onClear()
    }

    setSelection(value)
    onChange(value as InputSearchSelectItemType)
    if (autoClear) clear()
  }

  const filteredOptions = query === ''
    ? options
    : options?.filter((option) => {
      if (option.dataFields) { // dataFields are used for searching data in the select returned by external data sources
        // Check if any values in option.dataFields match the query
        return Object.values(option.dataFields).some(
          (field) => field.toLowerCase().includes(query.toLowerCase()),
        )
      }
      // If no dataFields, check if the option label matches the query (default behaviour)
      return option.label.toLowerCase().includes(query.toLowerCase())
    })

  const optionsElements = filteredOptions && filteredOptions.length > 0 ? (
    <div className={classnames('max-h-44 overflow-auto', optionsWrapperClassName)}>
      {visibleColumns && (
        <div className="flex flex-row items-center bg-gray-100 p-2 pl-3 pr-1">
          {Object.keys(visibleColumns).map((key) => (
            <div key={key} className="flex flex-col flex-1 truncate">
              <span className="text-sm capitalize">{visibleColumns[key]}</span>
            </div>
          ))}
        </div>
      )}
      {filteredOptions?.map((option) => (
        <SearchSelectOption key={option.id} option={option} className={optionClassName} />
      ))}
    </div>
  ) : null

  return (
    <Combobox
      as="div"
      value={selection || null}
      disabled={disabled}
      onChange={handleSelection}
      className={classnames(wrapperClassName)}
    >
      <div className="relative">
        <Combobox.Button as="div" className={classnames('relative flex flex-row gap-1 items-center', inputWrapperClassNames)}>
          {showSearchIcon && (
            <MagnifyingGlassIcon className="h-5 w-5 text-black" aria-hidden="true" />
          )}
          <Combobox.Input
            id={inputId}
            className={classnames(
              { inputErrorClassNames: !!error },
              'w-full base-form-input pr-10 ',
            )}
            onChange={handleInputChange}
            displayValue={() => (showSelectionAsQuery ? (selection?.label || '') : query)}
            placeholder={placeholder}
          />
          <div className="absolute inset-y-0 right-0 flex items-center mx-1 ">
            {nullable && selection && (
              <button
                type="button"
                className="flex items-center rounded-r-md focus:outline-none"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClear()
                  if (autoClear) clear()
                }}
              >
                <HiX className="h-4 w-4 " aria-hidden="true" />
              </button>
            )}
            {showArrow && (
              <Combobox.Button
                className="flex items-center rounded-r-md focus:outline-none px-2"
              >
                <HiChevronDown className="h-5 w-5 text-black" aria-hidden="true" />
              </Combobox.Button>
            )}
          </div>

        </Combobox.Button>

        <SearchSelectOptions
          alwaysVisible={alwaysVisible}
          optionsElements={optionsElements}
          onAdd={onAdd}
          addButtonText={addButtonText}
          isLoading={isLoading}
          className={optionsClassName}
        >
          {children}
        </SearchSelectOptions>
      </div>
      <InputError error={error} />
    </Combobox>
  )
}

export default SearchSelect
