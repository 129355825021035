import { useRef } from 'react'
import { FileType } from '../../../../types/shared/FileTypes'
import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'
import FileUploadDragAndDrop from './FileUploadDragAndDrop'
import FileUploadFileList from './FileUploadFileList'

interface IFileUpload {
  uploadFile: React.ChangeEventHandler<HTMLInputElement>
  downloadFile?: RemoveUploadedFileFunctionType
  removeFile?: RemoveUploadedFileFunctionType
  files: FileType[] | undefined
  fileIsLoading?: boolean
  disabled?: boolean
}

const FileUpload: React.FC<IFileUpload> = ({
  uploadFile, downloadFile, removeFile, files, fileIsLoading, disabled = false, // removeFile,
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null)

  return (
    <div className="flex flex-col gap-y-2 items-end">
      <FileUploadDragAndDrop fileUploadRef={fileUploadRef} disabled={disabled} />
      <FileUploadFileList
        removeFile={removeFile}
        downloadFile={downloadFile}
        files={files}
        fileIsLoading={fileIsLoading}
      />
      <input
        type="file"
        ref={fileUploadRef}
        className="hidden"
        multiple
        onChange={uploadFile}
        data-cy="file-upload-input"
        disabled={disabled}
      />
    </div>
  )
}

export default FileUpload
