import { FormQuestionType } from '../../../types/Forms'
import AddressQuestion from './QuestionTypes/AddressQuestion/AddressQuestion'
import DateQuestion from './QuestionTypes/DateQuestion'
import FileQuestion from './QuestionTypes/FileQuestion'
import MultiSelectQuestion from './QuestionTypes/MultiSelectQuestion'
import NumberQuestion from './QuestionTypes/NumberQuestion'
import SearchSelectQuestion from './QuestionTypes/SearchSelectQuestion'
import SupplierQuestion from './QuestionTypes/SupplierQuestion'
import TextQuestion from './QuestionTypes/TextQuestion'
import PriceQuestion from './QuestionTypes/PriceQuestion'
import KeyValuesQuestion from './QuestionTypes/KeyValuesQuestion'

interface IQuestion {
  question: FormQuestionType
}

const Question: React.FC<IQuestion> = ({
  question,
}) => {
  if (question.isFormElement) {
    return null
  }

  const questionType = question.type

  if (['MULTI_SELECT', 'SELECT'].includes(questionType)) {
    return (
      <MultiSelectQuestion question={question} />
    )
  }

  if (questionType === 'FILE') {
    return (
      <FileQuestion question={question} />
    )
  }

  if (['TEXT', 'TEXT_AREA'].includes(questionType)) {
    return (
      <TextQuestion question={question} />
    )
  }

  if (['DATE', 'DATE_TIME', 'DATE_RANGE'].includes(questionType)) {
    return (
      <DateQuestion question={question} />
    )
  }

  if (questionType === 'NUMBER') {
    return (
      <NumberQuestion question={question} />
    )
  }

  if (questionType === 'PRICE') {
    return (
      <PriceQuestion question={question} />
    )
  }

  if (questionType === 'ADDRESS') {
    return (
      <AddressQuestion question={question} />
    )
  }

  if (questionType === 'SEARCH_SELECT') {
    return (
      <SearchSelectQuestion question={question} />
    )
  }

  if (questionType === 'SUPPLIER') {
    return (
      <SupplierQuestion question={question} />
    )
  }

  if (questionType === 'KEY_VALUE') {
    return (
      <KeyValuesQuestion question={question} />
    )
  }

  return null
}

export default Question
