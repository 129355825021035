import useGetPaginatedSuppliers from './useGetPaginatedSuppliers'
import useGetSearchedSuppliers from './useGetSearchedSuppliers'

export default (inputQuery: string) => {
  const { data: suppliersData, isLoading: isLoadingPaginatedSuppliers } = useGetPaginatedSuppliers()
  const {
    data: searchedSuppliersData,
    isLoading: isLoadingSearchedSuppliers,
  } = useGetSearchedSuppliers(inputQuery)

  const isSearchLoading = inputQuery && isLoadingSearchedSuppliers

  const totalResults = (!inputQuery
    ? suppliersData?.pages[0].meta.total
    : searchedSuppliersData?.data?.length) || 0

  const isSuppliersLoading = isLoadingPaginatedSuppliers || isSearchLoading

  return { totalResults, isLoading: isSuppliersLoading }
}
