import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import useGetRequisitionData from '../../../subModules/Definition/hooks/useGetRequisition'
import useUpdateRequisitionStatus from '../../../hooks/useUpdateRequisitionStatus'
import generateCSVData from '../../../utils/generateCSVData'

const ContextMenu = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { mutate: updateReqStatus } = useUpdateRequisitionStatus()
  const { data, isLoading } = useGetRequisitionData()

  const extendDeadline = () => {
    updateReqStatus({
      status: 'DEADLINE_EXTENDED',
      reason: 'Manually extended by user',
    })
  }

  if (isLoading || !data) { return <CoreLoadingIcon /> }

  if (pathname.includes('award')) {
    return (
      <Menu menuItems={[
        {
          name: t('requisition.export_as_xlsx'),
          onClick: () => generateCSVData(String(data.id)),
        },
        {
          name: t('requisition.extend_deadline'),
          onClick: extendDeadline,
          disabled: data.status !== 'CLOSED',
        },
      ]}
      />
    )
  }

  return null
}

export default ContextMenu
