import QuestionInputWrapper from '../QuestionInputWrapper'

export const QuestionButton = (props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
  <QuestionInputWrapper>
    {(inputProps) => (
      <button
        type="button"
        {...inputProps}
        {...props}
      />
    )}
  </QuestionInputWrapper>
)

export default QuestionButton
