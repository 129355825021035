import { useLocation } from 'react-router'
import { useFormContext } from '../../../core/providers/FormProvider'
import useGetFormData from './useGetFormData'

export default () => {
  const { data: formData } = useGetFormData()
  const { submission: [submission] } = useFormContext()

  const location = useLocation()

  const isSubmission = location.pathname.includes('submission')
    || location.pathname.includes('view')

  const sections = (isSubmission
    ? submission?.sections
    : formData?.template.sections
  ) || []

  return sections
    .flatMap((formSection) => formSection.fields) || []
}
