import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'
import { CATALOG_ITEM } from '../../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../../services/UnifiedSearchService'

const CatalogMOQ: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))
  const { t } = useTranslation()

  if (!data) return <CoreLoadingIcon />
  return (
    <div className="flex items-center gap-2">
      <p>
        {t('catalog.minimum_order_quantity')}
        :
      </p>
      <p className="font-medium">{data.orderDetails.minimumOrderQuantity}</p>
    </div>
  )
}

export default CatalogMOQ
