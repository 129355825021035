import { Dispatch, SetStateAction, useEffect } from 'react'

export default (
  setIsMouseOverChild: Dispatch<SetStateAction<boolean>>,
  setIsMouseOverModal: Dispatch<SetStateAction<boolean>>,
) => {
  useEffect(() => {
    const scrollableElement = document.getElementById('scrollableDiv')

    const hideOnScoll = () => {
      setIsMouseOverChild(false)
      setIsMouseOverModal(false)
    }

    scrollableElement?.addEventListener('scroll', hideOnScoll)
    return () => scrollableElement?.removeEventListener('scroll', hideOnScoll)
  }, [])
}
