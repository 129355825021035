import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { useHistory } from 'react-router'
import Button from '../../../../../shared/components/atoms/buttons/Button'

const RequisitionTopbarMessengerButton = () => {
  const history = useHistory()
  return (
    <Button
      className="text-sm  bg-white text-gray-500 border "
      onClick={() => history.push('chat')}
      dataCy="req-preview-button"
    >
      <EnvelopeIcon className="w-4 h-4" />
    </Button>
  )
}

export default RequisitionTopbarMessengerButton
