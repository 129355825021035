import { useEffect, useState } from 'react'
import ChatMessageEditor from '../../../Messenger/components/MessagingContainer/ChatMessageEditor/ChatMessageEditor'
import MessagesContainer from '../../../Messenger/components/MessagingContainer/MessagesContainer/MessagesContainer'

const Chat: React.FC = () => {
  const [surroundingHeight, setSurroundingHeight] = useState(0)

  useEffect(() => {
    const supplierOfferBanner = document.getElementById('supplier-offer-banner')?.getBoundingClientRect()
    // TODO: replace magic numbers with constants
    setSurroundingHeight((supplierOfferBanner?.height || 0) + 103 + 137 + 108)
  }, [])

  return (
    <div
      className="flex flex-col w-full h-full"
      id="messageBoxContainer"
      data-cy="supplier-offer-expanded-chat"
    >
      <MessagesContainer
        style={{ height: surroundingHeight ? `calc(100vh - ${surroundingHeight}px)` : 0 }}
      />
      <ChatMessageEditor />
    </div>
  )
}

export default Chat
