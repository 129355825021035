import { SectionOrLineItemType } from './hooks/useGetSectionsOrLineItems'

interface IAttributes {
  section: SectionOrLineItemType
}

const Attributes: React.FC<
  IAttributes> = ({
    section,
  }) => (
    <div className="flex flex-wrap gap-x-2 gap-y-2">
      <table className=" w-full bg-gray-100 border ">
        <tbody className="">
          {section.item_attributes.filter((a) => a.type === 'attribute').map((row) => (
            <tr key={row.id} className="border-b last:border-none">
              <td className=" p-2 ">{row.description}</td>
              <td className=" py-2 max-w-lg">{row.configuration ? JSON.parse(row.configuration).value : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

export default Attributes
