import { XMarkIcon } from '@heroicons/react/24/outline'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import CorePageContainerLayout from '../../../../core/components/layout/CorePageContainerLayout'
import CoreTopbarLayout from '../../../../core/components/layout/CoreTopbarLayout'
import Button from '../../../../shared/components/atoms/buttons/Button'
import PageTopbar from '../../../../shared/components/layout/PageTopBar'
import LhotseHomeCreateSearch from './LhotseHomeCreateSearch'
import LhotseHomeCreateResultList from './LhotseHomeCreateResultList'

const LhotseHomeCreate = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <CoreTopbarLayout topbar={(
      <PageTopbar
        title={t('generic.what-are-you-looking-for', 'What are you looking for today?')}
        actions={(
          <Button className="" title="Back" onClick={() => history.push('/intake-flows')}>
            <XMarkIcon className="w-4" />
          </Button>
        )}
      />
    )}
    >
      <CorePageContainerLayout className="flex justify-center">
        <div className="flex flex-col max-w-4xl w-full gap-y-4">
          <LhotseHomeCreateSearch />
          <LhotseHomeCreateResultList />
        </div>
      </CorePageContainerLayout>
    </CoreTopbarLayout>
  )
}

export default LhotseHomeCreate
