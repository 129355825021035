import { useEffect, useState } from 'react'

const useAutoSave = (): [boolean, () => void] => {
  const [autoSave, setAutoSave] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAutoSave(false)
    }, 3000)

    return () => clearTimeout(timeout)
  })

  return [autoSave, () => setAutoSave(true)]
}

export default useAutoSave
