import { LineItemItemAttributeType } from '../../../../../../../types/shared/LineItemDataTypes'
import RequisitionOptionType from '../../../../../../../types/Requisition/RequisitionOptionType'

export const getCurrentLineItem = (
  row: LineItemItemAttributeType,
  option: RequisitionOptionType,
) => row?.line_items?.find(
  (lineItem) => lineItem.item_attribute_option_id === option.id,
)

export const getActiveLineItem = (row: LineItemItemAttributeType) => row?.line_items?.find(
  (lineItem) => lineItem.item_attribute_id === row.id && lineItem.value === '1',
)
