import { Transition, Combobox } from '@headlessui/react'
import { Fragment } from 'react'
import ActionPanelInput from './ActionPanelInput'
import ActionPanelInputHint from './ActionPanelInputHint'
import ActionPanelResults from './ActionPanelResults/ActionPanelResults'

interface IActionPanel {
  setRawQuery: React.Dispatch<React.SetStateAction<string>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  rawQuery: string
}

const ActionPanel: React.FC<IActionPanel> = ({
  setRawQuery, setOpen, rawQuery,
}) => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Combobox
      as="div"
      className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
      onChange={(item: any) => item?.func()}
      value={null}
    >
      <ActionPanelInput setRawQuery={setRawQuery} />
      <ActionPanelResults rawQuery={rawQuery} setOpen={setOpen} />
      <ActionPanelInputHint rawQuery={rawQuery} />
    </Combobox>
  </Transition.Child>
)

export default ActionPanel
