import {
  MdAddCircleOutline, MdOutlineAdd, MdOutlineCancel,
} from 'react-icons/md'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useQuestionLogicSwitchState from '../../hooks/useQuestionLogicSwitchMutations'
import { FormSectionType, FormQuestionType } from '../../../../../../../../types/Forms'
import FormLogicGoToDefaultTargetSelector from './FormLogicGoToDefaultTargetSelector'
import FormLogicGoToCase from './LogicCases/FormLogicGoToCase'
import RemoveLogicButton from './RemoveLogicButton'
import FormLogicAdditionalCondition from './LogicAdditionalConditions/FormLogicAdditionalCondition'

interface IFormLogicGoToLogic {
  question: FormQuestionType
  section: FormSectionType
}

const FormLogicGoToLogic: React.FC<IFormLogicGoToLogic> = ({ question, section }) => {
  const { t } = useTranslation()
  const {
    addCase, removeCase, addCondition,
  } = useQuestionLogicSwitchState(question, section)

  const { __logic: logic } = question

  const cases = useMemo(
    () => (logic?.switch?.cases && logic?.switch?.cases.length > 0
      ? logic?.switch?.cases
      : []),
    [logic],
  )

  return (
    <div className="flex flex-col gap-y-4 text-primary-gray text-xs border-b border-primary-gray pb-8">
      <div className="flex flex-row  w-full gap-y-4 justify-between ">
        <div className="flex justify-between items-center">
          <p className="font-medium mr-2 uppercase">{t('forms.if')}</p>
          <p>{t('forms.question')}</p>
        </div>
        <RemoveLogicButton question={question} section={section} />
      </div>
      {cases.map((logicCase, logicCaseIndex) => (
        <div className="border-l-2 border-vibrant-blue" key={`logic-case-${question.__uuid}-${logicCase.__uuid}`}>
          <div className="flex px-4 items-center space-x-4">
            <FormLogicGoToCase logicCase={logicCase} question={question} section={section} />

            {logicCase.__uuid && (
              <>
                <button
                  type="button"
                  className=" flex gap-x-2 items-center"
                  onClick={() => { addCondition(logicCase) }}
                >
                  <MdOutlineAdd />
                  <p className="underline">{t('forms.add_condition')}</p>
                </button>
                <button
                  type="button"
                  className=" flex gap-x-2 items-center"
                  onClick={() => removeCase(logicCase)}
                >
                  <MdOutlineCancel />
                  <p className="underline">{t('forms.remove_case')}</p>
                </button>
              </>
            )}
          </div>
          <div>
            {logicCase?.additionalConditions?.map((additionalCondition, index) => (
              <div key={additionalCondition.__uuid} className="flex flex-col">
                <div className="pl-8 py-2 uppercase">{t('forms.and')}</div>
                <div className=" ml-4">
                  <FormLogicAdditionalCondition
                    logicCaseIndex={logicCaseIndex}
                    additionalCondition={additionalCondition}
                    question={question}
                    section={section}
                    additionalConditionIndex={index}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div>
        <button
          type="button"
          className="flex gap-x-2 items-center text-sm text-primary-gray ml-4"
          onClick={() => addCase()}
        >
          <MdAddCircleOutline />
          <p>{t('forms.add_case')}</p>
        </button>
      </div>
      <FormLogicGoToDefaultTargetSelector question={question} section={section} />
    </div>
  )
}

export default FormLogicGoToLogic
