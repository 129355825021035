import { FormikErrors, FormikTouched } from 'formik'
import { FormikHandleChangeType } from '../../../../../types/shared/FormikType'
import AuthTextInput from '../../shared/AuthTextInput'
import JoinFormValueType from '../../../../../types/Authentication/JoinFormValueType'

interface IJoinFormTextInput {
  name: keyof JoinFormValueType
  constants: {
    handleChange: FormikHandleChangeType, values: JoinFormValueType,
    touched: FormikTouched<JoinFormValueType>, errors: FormikErrors<JoinFormValueType>,
    handleSubmit: () => void
  }
  label: string
  placeholder: string
}

const JoinFormTextInput: React.FC<IJoinFormTextInput> = ({
  name, label, constants, placeholder,
}) => (
  <AuthTextInput
    constants={constants}
    name={name}
    label={label}
    placeholder={placeholder}
  />
)

export default JoinFormTextInput
