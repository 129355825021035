import Table from '../../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../../shared/components/atoms/SharedTable/TableContainer'
import Menu from '../../../../../../shared/components/organisms/menus/Menu'
import { Offering } from '../../../../../../types/Offering'
import ProductSectionTableHeader from '../../../../../Forms/FormResponsePage/sections/ProductSection/ProuctSectionTable/ProductSectionTableHeader'
import useOfferingMutations from '../../../../hooks/useOfferingMutations'
import IntakeOfferingSectionForm from './IntakeOfferingSectionForm'
import IntakeOfferingSectionMainTableRow from './IntakeOfferingSectionMainTableRow'

interface IIntakeOfferingSection {
  offerings: Offering[]
}
const IntakeOfferingSection: React.FC<IIntakeOfferingSection> = ({ offerings }) => {
  const { remove } = useOfferingMutations()

  const handleRemoveOffering = (offering: Offering) => {
    remove.mutate(offering.uuid)
  }

  return (
    <TableContainer border={false}>
      <ProductSectionTableHeader />
      <Table>
        {offerings.map((offering) => (
          <IntakeOfferingSectionMainTableRow
            key={offering.uuid}
            offering={offering}
            actions={(
              <Menu menuItems={[
                { name: 'Delete', onClick: () => handleRemoveOffering(offering), disabled: offerings.length === 1 },
              ]}
              />
            )}
          >
            <IntakeOfferingSectionForm offering={offering} />
          </IntakeOfferingSectionMainTableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default IntakeOfferingSection
