import { useTranslation } from 'react-i18next'

import VerticalNav from '../../../../shared/components/layout/VerticalNav/VerticalNav'
import useGetRequisitionId from '../../../../shared/hooks/useGetRequisitionId'
import { RequisitionInviteType } from '../../../../types/shared/RequisitionDataTypes'
import useGetSupplierInvites from '../../subModules/Coordinate/hooks/useGetSupplierInvites'
import useGetRequisitionData from '../../subModules/Definition/hooks/useGetRequisition'
import useIsSapIntegrationActive from '../../hooks/useIsSapIntegrationActive'
import { VerticalNavItemType } from '../../../../types/shared/VerticalNavItemType'

const RequisitionNavigation: React.FC = () => {
  const { t } = useTranslation()
  const { data: requisitionData } = useGetRequisitionData()
  const requisitionId = useGetRequisitionId()
  const { data: allInvites } = useGetSupplierInvites()
  const isSapIntegrationActive = useIsSapIntegrationActive()

  const filteredInvites = allInvites?.filter((invite: RequisitionInviteType) => invite.status !== 'CREATED')

  const items = [
    {
      link: `/requisition/req-${requisitionId}/define`,
      title: t('req.define'),
    },
    {
      link: `/requisition/req-${requisitionId}/execute`,
      title: t('req.coordinate'),
      disabled: ['CREATED', 'DRAFT'].includes(requisitionData?.status || ''),
      cy: 'coordinate-button',
    },
    {
      link: `/requisition/req-${requisitionId}/award`,
      title: t('req.award'),
      disabled: !filteredInvites?.length,
      cy: 'award-button',
    },
    isSapIntegrationActive && {
      link: `/requisition/req-${requisitionId}/transfer`,
      title: t('req.sap_transfer'),
      disabled: requisitionData?.status !== 'PURCHASED',
      cy: 'transition-button',
    },
  ].filter((entry) => !!entry) as VerticalNavItemType[]

  return (
    <VerticalNav items={items} />
  )
}

export default RequisitionNavigation
