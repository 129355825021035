import { useTranslation } from 'react-i18next'
import { MdChevronLeft } from 'react-icons/md'
import { Link } from 'react-router-dom'

const ButtonBackToLogin = () => {
  const { t } = useTranslation()

  return (
    <Link to="login" className="text-sm text-sky-blue flex gap-1 items-center hover:underline underline-offset-1">
      <MdChevronLeft size={18} />
      <p>{t('login.back_to_login')}</p>
    </Link>
  )
}

export default ButtonBackToLogin
