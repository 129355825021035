// @ts-nocheck
import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { useOfferVisibilityContext } from '../../providers/OfferVisibilityProvider'

const CarouselButton = ({
  visible, action, isDisabled, direction,
}) => (
  <div className="justify-between my-auto mx-2 h-full">
    <button
      type="button"
      onClick={action}
      className={`${visible ? 'visible' : 'invisible'}
          hover:text-sky-blue/75 text-black w-10 h-full
          text-center opacity-100 hover:opacity-100
          disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300
        `}
      disabled={isDisabled}
    >
      {direction === 'left' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-12 w-20 -ml-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      )}

      {direction === 'right' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-12 w-20 -ml-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 5l7 7-7 7"
          />
        </svg>
      )}
    </button>
  </div>
)

interface IOfferCarousel {
  children: React.ReactNode,
  currentView: 'focus' | 'matrix'
}

const OfferCarousel: React.FC<IOfferCarousel> = ({ children, currentView }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const previousView = useRef(currentView)
  const visibleChildren = useRef([])
  const carousel = useRef(null)

  // Use visibility state context to get set visible function
  const { setVisibleOffers } = useOfferVisibilityContext()

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1)
    }
  }

  const moveNext = () => {
    if (currentIndex < children.length - 1) {
      setCurrentIndex((prevState) => prevState + 1)
    }
  }

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0
    }

    if (direction === 'next' && carousel.current !== null && carousel.current.children.length > 0) {
      // If the last child is fully visible, or we're at the last index, disable clicking right
      if (visibleChildren.current.includes(children.length - 1) || (currentIndex === children.length - 1)) {
        return true
      }

      // Check that the scrollLeft value at the new index is less than the max scroll width
      const childWidth = carousel.current.children[0].offsetWidth + 40
      const newRight = currentIndex * childWidth + carousel.current.offsetWidth
      const carouselEnd = carousel.current.scrollWidth

      return newRight >= carouselEnd
    }

    return false
  }

  const calculateVisibleChildren = (carouselLeft, carouselRight) => {
    // If focus view, the only "visible" child is the current index
    if (currentView === 'focus') {
      visibleChildren.current = [currentIndex]
      setVisibleOffers([currentIndex])
      return
    }

    // Build array of which children are fully visible in the scroll view
    const fullyVisibleChildren = []
    for (let i = 0; i < carousel.current.children.length; i += 1) {
      const child = carousel.current.children[i]
      const childLeft = child.offsetLeft
      const childRight = child.offsetLeft + child.offsetWidth

      if (carouselLeft <= childLeft && carouselRight >= childRight) {
        fullyVisibleChildren.push(i)
      }
    }

    // Ensure that there's always at least one child visible, even if the carousel is scrolled to the end
    if (fullyVisibleChildren.length === 0) {
      fullyVisibleChildren.push(currentIndex)
    }

    // Only set the visible children if they have changed to avoid unnecessary re-renders
    if (!_.isEqual(visibleChildren.current, fullyVisibleChildren)) {
      visibleChildren.current = fullyVisibleChildren
      setVisibleOffers(fullyVisibleChildren)
    }
  }

  useEffect(() => {
    // Get width of children and add 2.5rem to that width
    // eslint-disable-next-line no-unsafe-optional-chaining
    const childWidth = carousel.current?.children[0]?.offsetWidth + 40
    const newPosition = currentIndex * childWidth

    const currentCarouselLeft = newPosition
    const currentCarouselRight = newPosition + carousel.current.offsetWidth > carousel.current.scrollWidth
      ? carousel.current.scrollWidth
      : newPosition + carousel.current.offsetWidth

    // // Scroll to the current index
    carousel.current.scrollLeft = newPosition

    // Calculate which children are fully visible in the scroll view
    calculateVisibleChildren(currentCarouselLeft, currentCarouselRight)
  }, [currentIndex])

  // Calculate which children are fully visible in the scroll view when the number of children changes, typically on mount
  useEffect(() => {
    if (previousView.current !== currentView) {
      previousView.current = currentView
      setCurrentIndex(0)
    }
    if (carousel.current !== null) {
      const currentCarouselLeft = carousel.current.scrollLeft
      const currentCarouselRight = carousel.current.scrollLeft + carousel.current.offsetWidth
      calculateVisibleChildren(currentCarouselLeft, currentCarouselRight)
    }
  }, [children.length, currentView])

  // Calculate which children are fully visible when browser is resized
  useEffect(() => {
    const handleResize = () => {
      if (carousel.current !== null) {
        const currentCarouselLeft = carousel.current.scrollLeft
        const currentCarouselRight = carousel.current.scrollLeft + carousel.current.offsetWidth
        calculateVisibleChildren(currentCarouselLeft, currentCarouselRight)
      }
    }

    // Add event listener
    window.addEventListener('resize', handleResize)
  }, [])

  return (
    <div className={`relative carousel mt-2 mb-12 mx-auto max-w-full ${children.length > 1 ? 'w-full' : ''}`}>
      <div className="flex flex-row relative overflow-hidden">
        { currentView === 'matrix' && (
          <CarouselButton direction="left" visible action={() => movePrev()} isDisabled={isDisabled('prev')} />
        )}
        <div
          ref={carousel}
          className="carousel-container relative flex flex-row gap-10 overflow-x-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
        >
          {children.map((child, index) => (
            <div
              key={child.key}
              className={`carousel-item relative flex flex-row
              ${currentView === 'focus' && index === currentIndex ? 'w-full mx-auto' : ''}
              ${currentView === 'focus' && index === 0 && children.length > 1 ? 'md:ml-[25%] xl:ml-[33%]' : ''}
              ${index === children.length - 1 ? 'md:mr-[25%] xl:mr-[33%]' : ''}
            `}
            >
              {currentView === 'focus'
                && (
                <CarouselButton
                  direction="left"
                  visible={currentIndex === index}
                  action={() => movePrev()}
                  isDisabled={isDisabled('prev')}
                />
                ) }
              {child}
              {currentView === 'focus'
                && (
                <CarouselButton
                  direction="right"
                  visible={currentIndex === index}
                  action={() => moveNext()}
                  isDisabled={isDisabled('next')}
                />
                )}
            </div>
          ))}
        </div>
        { currentView === 'matrix' && (
          <CarouselButton direction="right" visible action={() => moveNext()} isDisabled={isDisabled('next')} />
        )}

      </div>
    </div>
  )
}

export default OfferCarousel
