import { useTranslation } from 'react-i18next'
import InputNumber from '../../../../../../../../../../shared/components/atoms/inputs/InputNumber'
import LabelInfo from '../../../../../../../../../../shared/components/atoms/texts/LabelInfo'

const CatalogBuyQuantitySelector = () => {
  const { t } = useTranslation()
  return (
    <LabelInfo className="relative gap-2 flex">
      {t('generic.quantity')}
      :
      <InputNumber />
    </LabelInfo>
  )
}

export default CatalogBuyQuantitySelector
