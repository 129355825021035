import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useGetCurrentUser from '../../../../core/hooks/useGetCurrentUser'
import useGetCurrentIntake from '../../hooks/useGetCurrentIntake'
import useGetRequiredActionWorkflowBlocks from '../../hooks/useGetRequiredActionWorkflowBlocks'
import { useIntakeFlow } from '../../providers/IntakeFlowProvider'
import SidepanelTabTransition from '../../../../shared/components/layout/SidepanelTabTransition'
import useGetPaginatedIntakeOfferings from '../../hooks/useGetPaginatedIntakeOfferings'
import Checkbox from '../../../../shared/components/atoms/inputs/Checkbox'
import useUpdateTaskStatusMutation from '../../hooks/useUpdateTaskStatusMutation'
import { WorkflowBlock } from '../../../../types/Workflow/WorkflowBlock'

const IntakeSidepanelSummary = () => {
  const { t } = useTranslation()

  const { data: currentUser } = useGetCurrentUser()
  const { data: intake } = useGetCurrentIntake()
  const { updateTaskStatus: { mutate } } = useUpdateTaskStatusMutation()

  const { data: offerings } = useGetPaginatedIntakeOfferings(1)

  const { activeWorkflow, selectedBlock } = useIntakeFlow()

  // Get all inputs used in the triggers
  const extractSummaryItemsFromWorkflowsteps = () => {
    const triggerFieldUuids = _.uniq(activeWorkflow?.steps.flatMap((step) => step.blocks).flatMap((block) => block.triggers)
      .map((trigger) => trigger.input?.uuid))

    const triggerFieldsFromIntake = intake?.sections?.flatMap((section) => section.fields)
      .filter((field) => triggerFieldUuids?.includes(field.input.uuid)) || []

    const triggerFieldsFromOfferings = offerings?.data[0]?.fields
      .filter((field) => triggerFieldUuids?.includes(field.input.uuid)) || []

    return [...triggerFieldsFromIntake, ...triggerFieldsFromOfferings]
  }

  const summaryItems = extractSummaryItemsFromWorkflowsteps()
  // Check if the current user is assigned to an active approval workflow block
  const requiredActionWorkflowBlocks = useGetRequiredActionWorkflowBlocks()
  // const requiredActionWorkflowBlocks = activeWorkflow?.required_action_workflow_blocks

  const userActiveApprovalWorkflowBlocks = requiredActionWorkflowBlocks?.filter((workflowBlock: WorkflowBlock) => {
    const userIsAssignedToWorkflowBlock = workflowBlock?.approvers?.some(
      // @ts-ignore The type of user here is a little bit buggy, user_id does exist on the object, but adding it to the type breaks typing in a bunch of other places.
      (assignedUser) => assignedUser?.user?.user_id === currentUser?.id,
    )

    return workflowBlock?.type === 'APPROVAL'
      && (workflowBlock?.status === 'in_queue' || workflowBlock?.status === 'pending')
      && userIsAssignedToWorkflowBlock
  })

  // Set tasks to local state, for quicker local updates of the checkbox UI state
  const [tasks, setTasks] = useState(selectedBlock?.tasks || userActiveApprovalWorkflowBlocks?.[0]?.tasks)

  return (
    <SidepanelTabTransition>
      <div className="h-full flex flex-col justify-between ">
        <div className="border-b border-b-rock-gray-light pb-5">
          {summaryItems?.map((item) => (
            <div key={`summaryItem-${item.input.uuid}`} className="flex flex-row pb-4 text-sm">
              <div className="basis-1/3 text-black-light-2">{item.input.name}</div>
              <div className="basis-2/3 flex flex-row justify-between text-black-light-1">
                {item.value?.display_name?.toString() || item.value?.value?.toString()}
              </div>
            </div>
          ))}
        </div>
        {(tasks && tasks.length > 0) && (
        <div className="text-black-light-2 text-sm pb-2">
          <div className="text-sm font-semibold text-sky-blue-light mb-5">
            {t('intake.sidepanel.summary.tasks', 'Tasks')}
          </div>
          {tasks.map((task) => (
            <div
              key={`task-${task.id}`}
              className="flex flex-row text-sm border
              border-rock-gray-light shadow-sm py-5 px-6 rounded-md mb-4"
            >
              <div className="mr-5">
                <Checkbox
                  onChange={(v) => {
                    // Set the task status in the local state, for quicker tactile feedback to the user
                    setTasks((prevTasks) => prevTasks.map((prevTask) => {
                      if (prevTask.uuid === task.uuid) {
                        return {
                          ...prevTask,
                          status: v ? 1 : 0,
                        }
                      }
                      return prevTask
                    }))
                    mutate({ taskUuid: task.uuid || '', status: v ? 1 : 0 })
                  }}
                  checked={task.status === 1}
                />
              </div>
              <div className="text-sm font-medium text-black-light-1">
                {task.name}
              </div>
            </div>

          ))}
        </div>
        )}
        <div className="flex flex-col">
          {userActiveApprovalWorkflowBlocks && userActiveApprovalWorkflowBlocks.length > 0 && (
          <div className="mb-9">
            <div className="text-black-light-2 text-sm pb-2">
              {t(
                'intake.sidepanel.summary.actionsRequired',
                '1 out of {{ numActions }} actions to complete',
                { numActions: requiredActionWorkflowBlocks?.length },
              )}
            </div>
          </div>
          )}
        </div>
      </div>
    </SidepanelTabTransition>
  )
}

export default IntakeSidepanelSummary
