import { useTranslation } from 'react-i18next'
import generateUserName from '../../../../../shared/utils/generateUserName'
import { WorkflowBlock } from '../../../../../types/Workflow/WorkflowBlock'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import WorkflowRemoveButton from '../../misc/WorkflowRemoveButton'

type WorkflowBuilderApproversListProps = {
  block: WorkflowBlock
  readonly?: boolean
  onRemove?: (approver: UserType) => void
}

export const WorkflowBuilderApproversList = ({ block, readonly, onRemove }: WorkflowBuilderApproversListProps) => {
  const { t } = useTranslation()

  const getUserNameWithEmail = (user: UserType) => [generateUserName(user), user.email].filter(Boolean).join(', ')

  return (
    <div className="flex flex-col border rounded border-rock-gray-light">
      <div className="p-6 border-b border-rock-gray-light">{t('workflow.block.approvers_list', 'User')}</div>
      {block.approvers.map((approver) => (
        <div
          className="p-6 border-b border-rock-gray-light flex flex-row justify-between"
          key={`block-${block.uuid}-approver-${approver.user.id}`}
        >
          <div>
            {getUserNameWithEmail(approver.user)}
          </div>
          {!readonly && (
            <div>
              <WorkflowRemoveButton onClick={() => onRemove?.(approver.user)} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default WorkflowBuilderApproversList
