import CoreTopbarLayout from '../../core/components/layout/CoreTopbarLayout'
import PageTopbar from '../../shared/components/layout/PageTopBar'
import SettingsContainer from './components/SettingsContainer'

const SettingsPage = () => (
  <CoreTopbarLayout topbar={<PageTopbar title="generic.settings" />}>
    <SettingsContainer />
  </CoreTopbarLayout>
)

export default SettingsPage
