import { useSuppliersProvider } from '../../../../../../../Suppliers/providers/SuppliersProvider'
import useOpenSupplierDetailsModal from '../../../../../../../SupplierDetails/hooks/useOpenSupplierDetailsModal'

export default (supplierBranchId: number) => {
  const openSupplierDetailsModal = useOpenSupplierDetailsModal(supplierBranchId)
  const { activeSupplierModalTab: [, setModalTab] } = useSuppliersProvider()

  return () => {
    openSupplierDetailsModal()
    setModalTab('offers')
  }
}
