import IndentationWrapper from '../../../../../shared/components/atoms/IndentationWrapper'
import { invalidIdStateType } from '../../../utils/validationUtils'
import Attributes from './Attributes'
import Rows from './Rows'
import Files from './Files'
import Title from './Title'
import { SectionOrLineItemType } from './hooks/useGetSectionsOrLineItems'

interface ISection {
  requisitionId: number | undefined
  invalidIds: invalidIdStateType | undefined
  updateAutoSave: () => void
  section: SectionOrLineItemType
  sectionIndex: number
}

const Section: React.FC<ISection> = ({
  section, sectionIndex, invalidIds, updateAutoSave,
}) => (
  <div key={section.section_id || section.id}>
    <Title sectionIndex={sectionIndex} section={section} />
    <Files section={section} />
    <IndentationWrapper className="flex flex-col gap-y-3">
      <Attributes section={section} />
      <Rows
        section={section}
        updateAutoSave={updateAutoSave}
        invalidIds={invalidIds}
        sectionIndex={sectionIndex}
      />
    </IndentationWrapper>
  </div>
)

export default Section
