import { useQuery, useQueryClient } from 'react-query'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import keys from '../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorDataType } from '../../Definition/constants/editorTypes'
import { getRequisitionSections } from '../../Definition/services/editorService'

const useGetReqSections = () => {
  const requisitionId = useGetRequisitionId()
  const cache = useQueryClient()

  const queryKey = [keys.SECTIONS_DATA, requisitionId]

  const currentSectionsData = cache.getQueryData<ReqDefEditorDataType>(queryKey)

  const newSectionsData = useQuery(
    queryKey,
    () => (getRequisitionSections(requisitionId)),
    { enabled: !currentSectionsData },
  )

  const refetch = () => cache.refetchQueries(queryKey)

  return currentSectionsData ? { data: currentSectionsData, isLoading: false, refetch } : newSectionsData
}

export default useGetReqSections
