import { LineItemItemAttributeAwardScreenType } from '../../../../../../../types/shared/LineItemDataTypes'
import { OffersOfferType } from '../../../../../../../types/shared/OfferDataTypes'
import LineItem from '../LineItem'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'

interface ILineItems {
  invite: RequisitionInviteType
  offer: OffersOfferType
  itemAttribute: LineItemItemAttributeAwardScreenType
}

const LineItems: React.FC<ILineItems> = ({
  itemAttribute, offer, invite,
}) => (
  <div className="w-full">
    <div className="invisible">{itemAttribute.description}</div>
    <div className="flex flex-col gap-y-2 w-full mt-2">
      {itemAttribute.options?.map((option) => (
        <LineItem
          key={option.id}
          offer={offer}
          option={option}
          invite={invite}
          itemAttribute={itemAttribute}
        />
      ))}
    </div>
  </div>
)

export default LineItems
