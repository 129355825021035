import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputWithSaveCancelButtons from '../../../../shared/components/organisms/InputWithSaveCancelButtons/InputWithSaveCancelButtons'
import useGetRequisitionData from '../../subModules/Definition/hooks/useGetRequisition'
import useUpdateRequisitionProperty from '../../subModules/Definition/hooks/useUpdateRequisitionProperty'

interface IRequisitionTopbarSearch {
  disabled: boolean
}
const RequisitionTopbarSearch: React.FC<IRequisitionTopbarSearch> = ({
  disabled,
}) => {
  const { t } = useTranslation()

  const { data: requisitionData, isLoading } = useGetRequisitionData()

  const [title, setTitle] = useState(requisitionData?.name || '')

  const updateRequisitionTitle = useUpdateRequisitionProperty('name')
  const revertRequisitionTitle = () => setTitle(requisitionData?.name || '')

  const requisitionNameInvalid = !title.length

  useLayoutEffect(() => {
    setTitle(requisitionData?.name || '')
  }, [requisitionData?.name])

  if (isLoading) return null

  return (
    <div className="w-1/2">
      <InputWithSaveCancelButtons
        title={title}
        setTitle={setTitle}
        onCancel={revertRequisitionTitle}
        onSave={() => { updateRequisitionTitle(title) }}
        error={t('requisition.name_cant_be_empty')}
        showError={requisitionNameInvalid}
        disabled={disabled}
        placeholder={t('requisition.enter_req_name')}
        inputClassName="text-lg"
      />
    </div>
  )
}

export default RequisitionTopbarSearch
