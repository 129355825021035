// REFACTOR
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import useGetReqSections from '../../../Coordinate/hooks/useGetReqSections'
import OfferViewSection from './OfferViewSection'

const OfferViewSectionsWrapper: React.FC = () => {
  const { data: sectionsData } = useGetReqSections()

  const generalInformationSections = {
    id: 'general',
    description: 'req_offer.general_information',
    section_type: 'general_info',
    item_attributes: [
      { description: 'requisition_offers.list_offer_supplier_offer_no', id: 'supplierOfferNumber' },
      { description: 'generic.last_updated', id: 'lastUpdate' },
      { description: 'req_offer.delivery_in_days', id: 'deliveryPeriod' },
      { description: 'offer_view.final_offer_docs', id: 'finalOfferFiles' },
      { description: 'supplier.tags', id: 'supplierTags' },

    ],
  }

  const sectionsDataWithGeneralInfo = [
    generalInformationSections,
    ...sectionsData || [],
  ]
    ?.filter((section) => section.section_type !== 'FINAL_OFFER') as {
      id: string | number
      description: string
      section_type: string
      item_attributes: Partial<LineItemItemAttributeType>[]
    }[]

  return (
    <div className="flex flex-col gap-y-4 justify-start gap-x-4 p-6 bg-white rounded-md overflow-y-none z-10 items-center lg:-ml-[210px]">
      {sectionsDataWithGeneralInfo?.map((section) => (
        <OfferViewSection
          section={section}
          key={section.id}
        />
      ))}
    </div>
  )
}

export default OfferViewSectionsWrapper
