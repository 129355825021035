import generateLogicTree from '../../../../FormResponsePage/providers/FormLogicProvider/hooks/useGetLogicAllowedQuestions/generateLogicTree'
import useGetFormData from '../../../../hooks/useGetFormData'

interface IFormLogicGetGraphButton { }

const FormLogicGetGraphButton: React.FC<IFormLogicGetGraphButton> = () => {
  const { data: formData } = useGetFormData()
  const hasLogic = !!formData?.template.__settings?.logic

  if (!hasLogic) return null

  return (
    <button
      type="button"
      onClick={() => {
        const { mermaidMarkup } = generateLogicTree(
          formData.template.sections.flatMap((s) => s.fields),
        )
        // eslint-disable-next-line no-console
        console.log('Mermaid Markup of the chart, copy the following lines:\n', mermaidMarkup())
        // eslint-disable-next-line no-console
        console.log('\nadd above text here: https://mermaid.live/ \n')
      }}
    >
      Get Graph
    </button>
  )
}

export default FormLogicGetGraphButton
