import { CustomerDocumentType } from '../../../../../types/SupplierOffer/SupplierOfferDataTypes'

export default (
  customerDocuments: (CustomerDocumentType & { pivot: { required: boolean } })[],
) => customerDocuments
  ?.map((document) => ({
    ...document,
    required: document.pivot.required,
  }
  ))
