import { useQueryClient } from 'react-query'
import { LineItemItemAttributeType, LineItemSectionType, LineItemType } from '../../../../../../types/shared/LineItemDataTypes'
import { MediaType } from '../../../../../../types/shared/MediaTypes'
import queryKeys from '../../../../../Requisition/subModules/Award/constants/OfferViewQueryKeys'

export default () => {
  const cache = useQueryClient()
  return (
    uploadedFile: MediaType,
    row: LineItemItemAttributeType | undefined,
    shouldDelete?: boolean,
    lineItem?: LineItemType,
  ) => {
    cache.setQueryData<LineItemSectionType[] | undefined>(
      queryKeys.SECTIONS_DATA,
      (sections: LineItemSectionType[] | undefined) => {
        if (!sections) { return sections }

        const currentFiles = sections
          ?.map((section) => section.item_attributes).flat()?.find((item) => item.id === row?.id)
          ?.line_items[0]?.files || []

        const updatedFiles = shouldDelete
          ? currentFiles?.filter((file) => file.name !== uploadedFile.name)
          : [...currentFiles, uploadedFile]

        const newSections: LineItemSectionType[] = sections
          ?.map((sectionData) => ({
            ...sectionData,
            item_attributes: sectionData.item_attributes.map((item) => {
              if (item.id === row?.id) {
                const newLineItems = item.line_items.length
                  ? [{ ...item.line_items[0], files: updatedFiles }]
                  : [lineItem as LineItemType]

                return { ...item, line_items: newLineItems }
              }
              return item
            }),
          }))

        return newSections
      },
    )
  }
}
