import { useTranslation } from 'react-i18next'
import TipTapEditor from '../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import useGetRequisitionData from '../Definition/hooks/useGetRequisition'
import SupplierRow from '../Coordinate/components/ReqCoordinate/NewSupplierRow/NewSupplierRow'
import CardTable from '../../../../shared/components/atoms/CardTable/CardTable'
import Prices from '../Award/components/SupplierHeader/Prices/Prices'
import Warning from '../../../../shared/components/organisms/WarningBanner/WarningBanner'
import useIsSapPrSent from '../../hooks/useIsSapPrSent'
import Collapsible from '../../../../shared/components/atoms/Collapsible/Collapsible'
import SapTransferPayload from './components/SapTransferPayload'
import useSelectedOffer from '../../hooks/useSelectedOffer'
import useHasExternalSupplierIntegration from '../../hooks/useHasExternalSupplierIntegration'
import useIsSapIntegrationActive from '../../hooks/useIsSapIntegrationActive'
import Button from '../../../../shared/components/atoms/buttons/Button'

export const RequisitionSapTransferPage = () => {
  const { t } = useTranslation()
  const { data: requisitionData } = useGetRequisitionData()
  const isSapPrSent = useIsSapPrSent()
  const selectedOffer = useSelectedOffer()
  const hasExternalId = useHasExternalSupplierIntegration()
  const isSapIntegrationActive = useIsSapIntegrationActive()

  const tableColumns = [
    t('coordinate.supplier_name', 'Supplier Name'),
    t('coordinate.contact_name', 'Contact name'),
    t('coordinate.tags', 'Tags'),
    t('coordinate.status', 'Status'),
  ]

  const showSuccessMessage = window.location.host.includes('funke')

  if (!selectedOffer || !isSapIntegrationActive) return null

  return (
    <div className="flex flex-col space-y-6">
      {!hasExternalId && <Warning severity="warning">{t('req.sap_transfer_external_id_missing')}</Warning>}
      {isSapPrSent && showSuccessMessage && (
        <Warning severity="success">
          <div dangerouslySetInnerHTML={{ __html: `<div>${t('req.sap_transfer_funke_message')}</div>` }} />
          <Button type="button" className="btn-secondary mt-4" onClick={() => window.open('https://s1-eu.ariba.com/gb/?realm=FUNKECHILDSITE&locale=de_DE', '_blank')}>{t('req.sap_transfer_funke_cta', 'Jetzt zu FUNKEPRO')}</Button>
        </Warning>
      )}
      <TipTapEditor
        editable={false}
        label={t('req.general_description', 'General Description')}
        content={requisitionData?.description}
      />
      <CardTable columns={tableColumns}>
        <SupplierRow
          showSelect={false}
          key={`${selectedOffer.id}-supplierUser`}
          supplierUser={selectedOffer.supplier_user}
          supplierInvite={selectedOffer}
          status={selectedOffer.status}
        />
      </CardTable>
      <Prices invite={selectedOffer} currentStatus={selectedOffer.status} />
      <Collapsible title={t('req.sap_transfer_payload', 'Payload')}>
        <SapTransferPayload />
      </Collapsible>
    </div>
  )
}

export default RequisitionSapTransferPage
