import { MouseEventHandler } from 'react'
import useNavigate from '../../../../shared/hooks/useNavigate'
import { useCoordinateProvider } from '../../../Requisition/subModules/Coordinate/providers/CoordinateProvider'
import RequisitionRowType from '../../../../types/Basecamp/RequisitionRowType'
import Actions from './Requisition/Actions'
import StatusBar from './Requisition/StatusBar'
import Title from './Requisition/Title'

interface IRequisition {
  data: RequisitionRowType
}

const Requisition: React.FC<IRequisition> = ({ data }) => {
  const { searchQuery: [, setSearchQuery] } = useCoordinateProvider()

  const navigate = useNavigate()

  const rowOnClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const url = `/requisition/req-${data.id}`
    navigate(url, e)
    setSearchQuery('')
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className=" bg-white rounded-md p-6 flex flex-col
      border border-gray-300 border-opacity-60
    hover:bg-blue-50 hover:bg-opacity-50"
      onClick={rowOnClick}
    >
      <div className="flex justify-between">
        <Title data={data} />
        <Actions data={data} />
      </div>

      <StatusBar data={data} />
    </div>
  )
}

export default Requisition
