import CompanyUsersHeader from './CompanyUsersHeader'
import UsersList from './UsersList'

const CompanyUsers = () => (
  <div className="flex w-full flex-col pb-8">
    <CompanyUsersHeader />
    <UsersList />
  </div>
)

export default CompanyUsers
