import QuestionInputWrapper from '../QuestionInputWrapper'
import NumberRange, { INumberRange } from '../../../../../../shared/components/organisms/NumberRange/NumberRange'

export const QuestionNumberRange = (props: INumberRange) => (
  <QuestionInputWrapper>
    {(inputProps) => (
      <NumberRange
        {...inputProps}
        {...props}
      />
    )}
  </QuestionInputWrapper>
)

export default QuestionNumberRange
