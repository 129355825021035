import { Dispatch, SetStateAction } from 'react'
import CoreLoadingIcon from '../../../../../../../../core/components/CoreLoadingIcon'
import useGetCatalogItems from '../../../../../Definition/hooks/useGetCatalogItems'
import useGetRequisitionData from '../../../../../Definition/hooks/useGetRequisition'
import CatalogContent from '../CatalogTab'
import SupplierContent from './SupplierContent'

interface IRecommendedTab { setActiveSection: Dispatch<SetStateAction<number>> }

const RecommendedTab: React.FC<IRecommendedTab> = ({ setActiveSection }) => {
  const { data: requisitionData } = useGetRequisitionData()

  const { data: catalogItems, isLoading } = useGetCatalogItems(requisitionData?.name || '')

  return (
    <div className="flex flex-col w-full gap-y-8">
      <div className="flex flex-col gap-y-2">
        <CatalogContent />
        <SupplierContent setActiveSection={setActiveSection} />
      </div>
    </div>
  )
}

export default RecommendedTab
