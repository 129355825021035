import { useTranslation } from 'react-i18next'
import { ShareIcon, Square2StackIcon } from '@heroicons/react/24/solid'
import { useUtils } from '../../core/providers/UtilsProvider'
import EditDeleteOptions from '../../shared/components/organisms/menus/EditDeleteMenu'
import CompanyInputForm from './CompanyInputForm'
import { InputTemplate } from '../../types/Forms'
import useInputTemplateName from './hooks/useInputTemplateName'
import { apiDeleteCompanyInput } from '../IntakeConfiguration/services/CompanyInputsService'
import useInvalidateInputTypes from '../Forms/hooks/useInvalidateInputTypes'
import toastr from '../../shared/components/organisms/toastr/toastr'
import TableRow from '../../shared/components/atoms/SharedTable/TableRow'
import TableCell from '../../shared/components/atoms/SharedTable/TableCell'

interface ICompanyInput {
  input: InputTemplate
}

const CompanyInput: React.FC<ICompanyInput> = ({ input }) => {
  const { modal } = useUtils()
  const getName = useInputTemplateName()
  const { t } = useTranslation()
  const invalidateInputTemplates = useInvalidateInputTypes()

  const openInputEditModal = () => {
    modal.set({
      isOpen: true,
      title: `${input ? 'Update' : 'Create'} custom input type`,
      content: <CompanyInputForm input={input} />,
    })
  }

  const remove = async () => {
    if (!input.uuid) return

    const result = await apiDeleteCompanyInput(input.uuid)

    if (!result.success) {
      toastr.success('error.item_deleted', 'errorDelete')
      return
    }

    await invalidateInputTemplates()
    toastr.success('success.item_deleted', 'successDelete')
  }

  return (
    <TableRow
      onClick={() => openInputEditModal()}
    >
      <TableCell>
        {getName(input)}
      </TableCell>
      <TableCell>
        {input?.type ? t(`forms.input.${input?.type}`) : ''}
      </TableCell>
      <TableCell>
        {input?.key || '-'}
      </TableCell>
      <TableCell>
        {input?.custom_key || '-'}
      </TableCell>
      <TableCell className="text-sky-blue-light">
        <div className="flex space-x-6">
          {input?.children && input?.children?.length > 0 && (
            <div className="flex">
              <Square2StackIcon className="h-5 w-5 mr-2" />
              {input.children.length}
              {' '}
              {input.children.length === 1
                ? t('forms.input.single_relationship', 'input relationship')
                : t('forms.input.plural_relationship', 'input relationships')}
            </div>
          )}
          {(input.is_in_use) && (
            <div className="flex">
              <ShareIcon className="h-5 w-5 mr-2" />
              {t('forms.input.used_in_workflows', 'Used in workflows')}
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <EditDeleteOptions
          onEdit={() => openInputEditModal()}
          onDelete={remove}
          deleteDisabled={input.is_in_use || ((input.children && input.children?.length > 0) || false)}
        />
      </TableCell>
    </TableRow>
  )
}

export default CompanyInput
