import { useTranslation } from 'react-i18next'
import usePermissions from '../../../core/hooks/usePermissions'
import { FORM_SUBMIT } from '../../constants/permissions'
import TabsBar from '../atoms/Tabs/TabsBar'

const TabTitle: React.FC<any> = () => {
  const { t } = useTranslation()

  const permissions = usePermissions([{ slug: FORM_SUBMIT }])
  const tabs = [{
    link: '/requisitions',
    title: `${t('generic.requisition_plural')}`,
    // hidden: ability.can(ABILITIES.submissionsOnly),
  }, {
    link: '/submissions',
    title: `${t('generic.submission_plural')}`,
    hidden: !permissions.get(FORM_SUBMIT).allowed,
  }]

  return (
    <TabsBar tabs={tabs} />
  )
}

export default TabTitle
