// MOVE TO SHARED
import { Link } from 'react-router-dom'

interface ILinkButton {
  to: string
  text: string
  className?: string
}

const LinkButton: React.FC<ILinkButton> = ({
  to, text, className,
}) => (
  <Link to={to} className={`${className} text-xs text-primary underline flex items-center justify-center rounded-md`}>
    <p className="text-sm text-gray-600">{text}</p>
  </Link>
)

export default LinkButton
