import { FormikErrors, FormikTouched } from 'formik'
import ErrorMessage from '../../../../shared/components/atoms/ErrorMessage'
import TextInput from '../../../../shared/components/organisms/formik/FormikTextInput'
import { FormikHandleChangeType } from '../../../../types/shared/FormikType'

interface props {
  name: string
  constants: {
    handleChange: FormikHandleChangeType, values: any,
    touched: FormikTouched<any>, errors: FormikErrors<{ [key: string]: string }>,
    handleSubmit: () => void
  }
  label: string
  placeholder: string
}

const AuthTextInput = ({
  name, label, constants, placeholder,
}: props) => {
  const {
    handleChange, values, touched, handleSubmit, errors,
  } = constants

  return (
    <div className="relative">
      <label htmlFor={name}>
        <p className="py-3 text-sm">{label}</p>
        <TextInput
          type={name !== 'password' ? 'text' : 'password'}
          name={name}
          disabled={name === 'email'}
          handleChange={handleChange}
          placeholder={placeholder}
          values={values}
          inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors[name] && 'border-red-500'}`}
          error={errors[name] && touched[name] && (<ErrorMessage error={errors[name] || ''} />)}
          required={name !== 'email'}
          handleOnEnter={handleSubmit}
        />
      </label>
    </div>
  )
}

export default AuthTextInput
