import {
  FC,
  TextareaHTMLAttributes, useEffect,
  useState,
} from 'react'

export interface IBaseTextareaInput extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onBlur'> {
  required?: boolean | undefined
  label?: string
  onBlur?: (value: string | number | readonly string[] | undefined) => void
}

const BaseTextareaInput: FC<IBaseTextareaInput> = ({
  className, onBlur, ...rest
}) => {
  const [value, setValue] = useState<string | number | readonly string[] | undefined>(rest.defaultValue || '')

  const handleBlur = () => {
    onBlur && onBlur(value)
  }

  useEffect(() => {
    setValue(rest.defaultValue)
  }, [rest.defaultValue])

  return (
    <div className="relative rounded-md flex w-full ">
      <textarea
        onChange={(e) => setValue(e.target.value)}
        defaultValue={rest.defaultValue}
        onBlur={handleBlur}
        rows={3}
        className={`
         base-form-input peer
         block w-full  ${className} `}
        {...rest}
      />
    </div>
  )
}

export default BaseTextareaInput
