import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import AcceptanceStateType from '../../../../../../types/Requisition/AcceptanceStateType'

export const offerViewSupplierTypeTagTitle = (currentStatus: AcceptanceStateType) => {
  if (currentStatus === 'INVITED') return 'general.waiting'
  if (currentStatus === 'OFFER_IN_EDITING') return 'general.being_edited'
  if (currentStatus === 'DECLINED_TO_OFFER') return 'req_award.declined_to_offer'
  return ''
}

export const getIsWaiting = (offer: RequisitionInviteType) => {
  const currentStatus = offer?.activity_status || offer?.status

  return !!(['OFFER_IN_EDITING', 'INVITED', 'DECLINED_TO_OFFER', 'OFFER_DECLINED_BY_SUPPLIER'].includes(currentStatus || '') || (currentStatus === 'SUSPENDED' && !offer?.offer?.id))
}
