import { ReactNode } from 'react'
import { FormQuestionType, FormQuestionValue } from '../../../../../types/Forms'
import useQuestionValues from '../../hooks/useQuestionValues'
import AddAnswerButton from './AddAnswerButton'
import QuestionError from './QuestionError'
import KeyValueRow from './KeyValueRow'
import { getIndexedGenericLabels } from '../../../constants/labels'
import { replaceByUuid } from '../../../../../shared/utils/arrayHelpers'
import useSynchronizedFormUpdate from '../../hooks/useSynchronizedFormUpdate'

type KeyValuesQuestionProps = {
  question: FormQuestionType
  children: (answer: FormQuestionValue) => ReactNode
}

export const KeyValueQuestion: React.FC<KeyValuesQuestionProps> = ({
  question, children,
}) => {
  const [questionValues, updateQuestionValues] = useQuestionValues(question)

  const canAddMore = !question?.__settings?.answers?.max || questionValues.length < question?.__settings.answers.max

  const updateValuesAndSyncForm = useSynchronizedFormUpdate(
    (answerUuid: string) => {
      updateQuestionValues(
        getIndexedGenericLabels(replaceByUuid(questionValues, answerUuid), question.question_label || ''),
      )
    },
  )

  return (
    <div className="flex flex-col gap-y-4">
      {questionValues.map((answer, index) => (
        <KeyValueRow
          key={`${question.__uuid}-${answer.__uuid}`}
          question={question}
          answer={answer}
          index={index}
          visibleKey={questionValues.length > 1}
          onRemove={() => updateValuesAndSyncForm(answer.__uuid)}
        >
          {children(answer)}
        </KeyValueRow>
      ))}
      <QuestionError question={question} />
      {canAddMore && <AddAnswerButton question={question} />}
    </div>
  )
}

export default KeyValueQuestion
