import { LineItemItemAttributeType } from '../../../../../../../types/shared/LineItemDataTypes'
import { OffersOfferType } from '../../../../../../../types/shared/OfferDataTypes'
import LineItems from './LineItems'
import SingleLineItem from './SingleLineItem/SingleLineItem'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'

interface IItemAttributeValue {
  invite: RequisitionInviteType
  offer: OffersOfferType
  itemAttribute: LineItemItemAttributeType
}

const ItemAttributeValue: React.FC<IItemAttributeValue> = (
  { offer, itemAttribute, invite },
) => (
  <div className={`w-72 flex ${itemAttribute.type !== 'price' && 'items-center'} `}>
    {itemAttribute?.options?.length
      ? (
        <LineItems
          invite={invite}
          offer={offer}
          itemAttribute={itemAttribute}
        />
      )
      : (
        <SingleLineItem
          offer={offer}
          itemAttribute={itemAttribute}
        />
      )}
  </div>
)

export default ItemAttributeValue
