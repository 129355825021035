import { MdError, MdFunctions } from 'react-icons/md'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import Tooltip from '../../../../../../shared/components/organisms/tooltip/Tooltip'

interface IMultilineOptions {
  row: LineItemItemAttributeType
}

const MultilineOptions: React.FC<IMultilineOptions> = ({ row }) => (
  <div className="flex gap-x-2 items-center pb-4 pl-1">
    {!!(row.type === 'price' && row.is_sum)
      && (
        <Tooltip
          showCondition
          tooltip={row?.is_sum ? 'supplier_offer.value_included_in_total' : 'supplier_offer.value_not_included_in_total'}
          className="flex items-center"
          dataCy="supplier-offer-pricing-sum-icon"
        >
          <MdFunctions
            className={`text-gray-400 ${(row.is_sum) && 'text-sky-blue'}`}
            size={17}
          />
        </Tooltip>
      )}
    {!!row.is_required
      && (
        <Tooltip
          showCondition
          tooltip={row.type === 'price' ? 'supplier_view.must_fill_out_minimum_one_quantitiy' : 'generic.input_required'}
          className="flex text-orange-600 items-center"
        >
          <MdError data-cy="supplier-offer-pricing-required-icon" />
        </Tooltip>
      )}
  </div>
)

export default MultilineOptions
