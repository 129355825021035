import SendMessageButton from './SendMessageButton'
import TextInput from './TextInput'

const MessageInput: React.FC = () => (
  <div
    id="chatMessageBox"
    className="flex items-start space-x-2 bg-white border-rock-gray-light border rounded w-full p-2 cursor-auto shadow-md"
  >
    <TextInput />
    <SendMessageButton />
  </div>
)

export default MessageInput
