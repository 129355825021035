import { useQuery } from 'react-query'
import { getWorkflow } from '../services'
import useWorkflowIdentification from './useWorkflowIdentification'

export const useGetWorkflow = () => {
  const { uuid, version } = useWorkflowIdentification()

  return useQuery(
    ['workflow', uuid, version],
    () => getWorkflow(uuid, version!),
    { enabled: !!version },
  )
}

export default useGetWorkflow
