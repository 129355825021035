import { useTranslation } from 'react-i18next'
import { ISubmission } from './Submission'
import { FormSubmissionType } from '../../../../../types/Forms'
import useInferStatus, { InferStatusType } from './hooks/useInferStatus'

const SubmissionStatus = ({ submission }: { submission: FormSubmissionType }) => {
  const { t } = useTranslation()

  const { inferStatus } = useInferStatus()
  const { status, info }: InferStatusType = inferStatus(submission)

  if (submission?.status?.name === 'DECLINED') return <div className="flex items-center px-4 py-0.5 group relative bg-red-200 rounded-lg text-red-700 ">{t('advario.request-declined', 'Request Declined')}</div>

  if (status === 'transferred') {
    return (
      <div className="flex items-center px-4 py-0.5 group relative bg-green-600 rounded-lg text-white">
        {t('generic.transfer-successful', ' Transfer successfull, External ID:')}
        {' '}
        {info}
      </div>
    )
  }
  if (status === 'transfer_pending') return <div className="flex items-center px-4 py-0.5 group relative bg-yellow-200 rounded-lg text-yellow-700 ">{t('generic.transfer-pending', 'Transfer Pending')}</div>
  if (status === 'submitted') return <div className="flex items-center px-4 py-0.5 group relative bg-blue-200 rounded-lg text-blue-700">{t('generic.submitted-to-procurement', 'Submitted to Procurement')}</div>

  return <div className="flex items-center px-4 py-0.5 group relative bg-gray-200 rounded-lg text-gray-700">{t('generic.draft', 'Draft')}</div>
}
const SubmissionTitle: React.FC<ISubmission> = ({ submission }) => {
  const { t } = useTranslation()

  const title = submission.fields?.find((f) => f.key === 'req-name')?.value[0].value

  return (
    <div className="flex justify-between items-start ">
      <div className="flex flex-col gap-8 flex-grow">
        <div className="line-clamp-1 mr-2">
          {`${title || submission?.form?.name.toUpperCase()} `}
        </div>
        <div className="flex space-x-2 text-gray-400  text-xs">
          <div>
            <SubmissionStatus submission={submission} />
          </div>

          <p>·</p>
          <div>
            {`${t('generic.created_by_plural')}: ${submission?.submitter?.first_name || submission?.submitter?.email}`}
          </div>
          <p>·</p>
          <div>
            {`${t('generic.create_date')}: ${new Date(submission?.created_at).toLocaleDateString('de-DE')} ${new Date(submission?.created_at).toLocaleTimeString('de-DE')}`}
          </div>
          <p>·</p>
          <div>
            <a href={`/forms/${submission?.form?.uuid}/view`} className="text-blue-600 hover:underline">
              {`${t('forms.form')}`}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmissionTitle
