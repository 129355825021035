import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import WorkflowBuilderApproversList from './WorkflowBuilderApproversList'
import SearchSelect from '../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import companyQueryStrings from '../../../../Company/constants/companyQueryStrings'
import { apiGetCompanyUsers } from '../../../../Company/services/CompanyService'
import { InputSearchSelectItemType } from '../../../../../types/shared/InputSearchSelectTypes'
import { WorkflowBlock, WorkflowBlockApprover } from '../../../../../types/Workflow/WorkflowBlock'

type WorkflowBuilderApproversModalProps = {
  block: WorkflowBlock
  addApprover: (approver: WorkflowBlockApprover) => void
  removeApprover: (userId: number) => void
  readonly?: boolean
}

export const WorkflowBuilderApproversModal = ({
  block, addApprover, removeApprover, readonly,
}: WorkflowBuilderApproversModalProps) => {
  const { data: usersData, isLoading: isLoadingUsers } = useQuery(
    [companyQueryStrings.company_users],
    () => apiGetCompanyUsers(1),
  )

  if (!block || isLoadingUsers) return null

  const selectableUsers = usersData?.filter(
    (user) => !block.approvers?.map(({ user: { id } }) => id).includes(user.id),
  ).map((user) => ({ id: user.id, label: `${user.first_name} ${user.last_name}` }))

  const handleAddition = (userOption?: InputSearchSelectItemType) => {
    if (!userOption) return

    const selectedUser = usersData?.find((user) => user.id === userOption.id)

    if (!selectedUser) return

    addApprover({ user_id: userOption.id, user: selectedUser } as unknown as WorkflowBlockApprover)
  }

  return (
    <div className="flex flex-col gap-3">
      {!readonly && (
        <div>
          <SearchSelect
            options={selectableUsers}
            onChange={handleAddition}
            currentSelection={undefined}
            nullable
            autoClear
          />
        </div>
      )}
      <div>
        <WorkflowBuilderApproversList block={block} readonly={readonly} onRemove={({ id }) => removeApprover(id)} />
      </div>
    </div>
  )
}

export const WorkflowBuilderApproversModalHeader = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-y-2">
      <p>{t('workflow.block.add_approvers', 'Add approvers')}</p>
    </div>
  )
}
