import { useTranslation } from 'react-i18next'
import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import TipTapEditor from '../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import useGetAndSetActiveIntakeMessage from '../../../hooks/IntakeFlowChat/useGetAndSetActiveIntakeMessage'
import useSendIntakeChatMessage from '../../../hooks/IntakeFlowChat/useSendIntakeChatMessage'

const TextInput: React.FC = () => {
  const { t } = useTranslation()
  const [messageContent, setMessageContent] = useGetAndSetActiveIntakeMessage()
  const { messageProperties: [{ files, messageReply }] } = useMessengerContext()

  const { mutate: handleSendMessage } = useSendIntakeChatMessage()

  return (
    <div
      className="flex bg-white flex-grow"
    >
      <TipTapEditor
        expandedEditor
        onUpdate={setMessageContent}
        content={messageContent}
        onEnterShift={handleSendMessage}
        filesOnTop
        noFocus
        editorContentClassName="max-h-24"
        messageReply={!!messageReply?.referenceId}
        dataCy="tipTapChatEditor"
        customPlaceholder={t('messenger.intakeFlowChat.write_message_here', 'Write a message here...')}
      />
    </div>
  )
}

export default TextInput
