import { useFormContext } from '../../../../core/providers/FormProvider'
import { FormSectionType, FormTemplateType } from '../../../../types/Forms'
import useUpdateSubmission from './useUpdateSubmission'

export default () => {
  const { submission: [submissionData, setSubmission] } = useFormContext()
  const updateSubmission = useUpdateSubmission()

  const removeSection = (sectionId: string) => {
    const filteredSections = submissionData?.sections.filter((section) => section.__uuid !== sectionId) as FormSectionType[]

    const newFormResponse: FormTemplateType = {
      ...submissionData!,
      sections: filteredSections || submissionData?.sections!,
    }

    setSubmission(newFormResponse)
    updateSubmission()
  }

  return removeSection
}
