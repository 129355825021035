import { useTranslation } from 'react-i18next'

const TransparentError = () => {
  const { t } = useTranslation()
  return (
    <div className="text-transparent ">
      {t('generic.required')}
    </div>
  )
}

export default TransparentError
