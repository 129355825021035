import { FormQuestionType } from '../../../../types/Forms/formTypes'
import useQuestionError from './useQuestionError'

export const useQuestionErrorMessage = (question: FormQuestionType): string | undefined => {
  const error = useQuestionError(question)

  return error?.message
}

export default useQuestionErrorMessage
