import CoreLoadingIcon from '../../../../../../../../core/components/CoreLoadingIcon'
import NoPreviousRequisitionsMessage from '../../../../../../../SupplierDetails/components/ReqsAndOffers/NoPreviousRequisitionsMessage'
import SupplierRequisitionsTable from '../../../../../../../SupplierDetails/components/ReqsAndOffers/SupplierRequisitionsTable'
import useGetSupplierOffers from '../../../../../../../SupplierDetails/components/ReqsAndOffers/useGetSupplierOffers'
import SeeAllRequisitionsButton from './SeeAllRequisitionsButton'

interface ISupplierRequisitionsPreviewModal {
  supplierBranchId: number
}

const SupplierRequisitionsPreviewModal: React.FC<ISupplierRequisitionsPreviewModal> = ({
  supplierBranchId,
}) => {
  const { data, isLoading } = useGetSupplierOffers(supplierBranchId)

  if (isLoading) {
    return (
      <div className="bg-white w-[600px] max-w-[50vw] h-12 flex items-center justify-center">
        <CoreLoadingIcon />
      </div>
    )
  }

  return (
    <div className="w-[600px] max-w-[50vw] flex justify-end">
      <div className="bg-white p-3 w-full">
        {data?.length
          ? (
            <>
              <SupplierRequisitionsTable
                supplierBranchId={supplierBranchId}
                rowLimit={5}
              />
              <SeeAllRequisitionsButton supplierBranchId={supplierBranchId} />
            </>
          )
          : <NoPreviousRequisitionsMessage />}
      </div>
    </div>
  )
}

export default SupplierRequisitionsPreviewModal
