import { useTranslation } from 'react-i18next'
import SupplierUserType from '../../../../types/Suppliers/SupplierUserType'
import TextMidLineTitle from '../../atoms/texts/TextMidLineTitle'
import AssigneeButton from '../AssigneesSelector/AssigneeButton'

interface IAssignableUserList {
  onClick: (email: string) => void
  displayedUsers: SupplierUserType[] | undefined
}

const AssignableUserList: React.FC<IAssignableUserList> = ({ onClick, displayedUsers }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-gray-400 text-sm mt-2">
        <TextMidLineTitle>{t('generic.your_team')}</TextMidLineTitle>
        {displayedUsers?.length === 0 && <div className="text-gray-400">{t('assignments.no_users')}</div>}
      </div>
      {displayedUsers?.map((user) => (
        <AssigneeButton
          key={user.id}
          user={user}
          onClick={() => {
            onClick(user.email)
          }}
        />
      ))}
    </>
  )
}

export default AssignableUserList
