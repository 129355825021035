import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import SearchSelect from '../../../shared/components/atoms/SearchSelect/SearchSelect'
import { ExternalDataColumn } from '../../../types/ExternalDataManager/ExternalDataManagerTypes'
import { InputSearchSelectItemType } from '../../../types/shared/InputSearchSelectTypes'
import useListDataEntityColumns from '../../ExternalDataManager/hooks/useListDataEntityColumns'
import { FilterSettingsType } from '../../../types/Forms'

interface IDatasetInputValueColumnSelect {
  handleFilterSettingsInput: (updatedSettings: Partial<FilterSettingsType>) => void
  datasetUuid: string | undefined
  selectedColumnName: string | undefined
}
const DatasetInputValueColumnSelect: React.FC<IDatasetInputValueColumnSelect> = ({ handleFilterSettingsInput, datasetUuid, selectedColumnName }) => {
  const { t } = useTranslation()
  const { data: dataEntityColumns } = useListDataEntityColumns(datasetUuid)

  // Map externalDataEntities to SearchSelect options
  let options: InputSearchSelectItemType[] = []
  if (dataEntityColumns) {
    options = dataEntityColumns.map((entity: ExternalDataColumn) => ({
      id: uuid(),
      label: entity.column_name,
    }))
  }
  // Set options in state to avoid uuid changes on rerender
  const [optionsState, setOptionsState] = useState<InputSearchSelectItemType[]>(options)
  useEffect(() => {
    setOptionsState(options)
  }, [dataEntityColumns])

  const selectedColumn = optionsState.find((option) => option.label === selectedColumnName)

  const onColumnSelect = (column_name: string) => {
    handleFilterSettingsInput({ input_value_column: column_name })
  }

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="reference_dataset">{t('inputs.column', 'Column')}</label>
      <SearchSelect
        onChange={(item) => onColumnSelect(item.label)}
        options={optionsState}
        placeholder={t('inputs.select_column', 'Select column to populate select input options from')}
        currentSelection={selectedColumn}
      />
    </div>
  )
}

export default DatasetInputValueColumnSelect
