import { useMessengerContext } from '../../../core/providers/MessengerProvider'
import { MessageModelType } from '../../../types/Messenger/types'
import useSetSupplierInviteParams from './useSetSupplierInviteParams'

export default (chatTitle: string, modelId: string | number, modelType: MessageModelType) => {
  const { chatState: [, setChatState] } = useMessengerContext()
  const setSupplierInviteParam = useSetSupplierInviteParams(modelId, modelType)

  return () => {
    setSupplierInviteParam()
    setChatState((chatState) => ({
      ...chatState,
      chatTitle,
    }))
  }
}
