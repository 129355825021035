import { submissionMediaService } from '../../../Requisition/subModules/Definition/services/attachmentsService'
import useGetRequisitionData from '../../../Requisition/subModules/Definition/hooks/useGetRequisition'
import useGetFormParams from '../../FormBuilder/hooks/useGetFormParams'
import useGetFormSubmission from './useGetFormSubmission'

export const useFormMedia = () => {
  const { data: requisition } = useGetRequisitionData()
  const { data: submission } = useGetFormSubmission()
  const { submissionId } = useGetFormParams() || {}

  const fileParentId = submissionId || submission?.uuid || requisition?.form_submission?.uuid

  return fileParentId ? submissionMediaService(fileParentId) : undefined
}

export default useFormMedia
