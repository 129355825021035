import classnames from 'classnames'

type IntakeTextInputProps = {
  onChange: (newValue: string) => void
  type: 'TEXT' | 'TEXT_AREA',
  error?: string
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, HTMLInputElement | HTMLTextAreaElement>, 'onChange' | 'ref'>

export const FormTextInput = ({
  onChange, type, error, ...props
}:
  IntakeTextInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value)
  }

  const InputTag = type === 'TEXT' ? 'input' : 'textarea'

  return (
    <InputTag
      type={type === 'TEXT' ? 'text' : 'textarea'}
      className=" base-form-input"
      {...props}
      onChange={handleChange}
    />
  )
  // border-none bg-white text-sm p-4 w-full shadow rounded-md placeholder:text-black-light placeholder:opacity-50
}

export default FormTextInput
