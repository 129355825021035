import {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react'
import { INodeChildren } from '../../types/shared/GeneralTypes'

type BasecampContextType = {
  requisitionSearch: [string, Dispatch<SetStateAction<string>>]
}

const BasecampContext = createContext<BasecampContextType>({} as BasecampContextType)

export function useBasecampContext() {
  return useContext(BasecampContext)
}

const BasecampProvider: React.FC<INodeChildren> = ({ children }) => {
  const requisitionSearchState = useState('')

  const values = useMemo(
    () => ({ requisitionSearch: requisitionSearchState }),
    [requisitionSearchState],
  )

  return (
    <BasecampContext.Provider value={values}>
      {children}
    </BasecampContext.Provider>
  )
}

export default BasecampProvider
