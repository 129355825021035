import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import Input from '../../../../../../shared/components/atoms/Input/Input'
import Label from '../../../../../../shared/components/atoms/Label'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import DeleteButton from '../../../../../../shared/components/atoms/buttons/DeleteButton'
import EmptyState from '../../../../../../shared/components/organisms/EmptyState/EmptyState'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import { ReqDefEditorSectionType } from '../../constants/editorTypes'
import useAddItemAttributeOption from '../../hooks/useAddItemAttributeOption'
import usePostNewAttribute from '../../hooks/usePostNewAttribute'
import { putOption } from '../../services/editorService'

import useDeleteOption from '../../hooks/useDeleteOption'

export interface IProductDetailsQuantity {
  attributes?: Omit<LineItemItemAttributeType, 'line_items'>[]
  section: ReqDefEditorSectionType
  disabled?: boolean
}

const ProductDetailsQuantity: FC<IProductDetailsQuantity> = ({ attributes, section, disabled }) => {
  const { t } = useTranslation()
  const priceAttribute = attributes?.find((a) => a.type === 'price')

  const { mutate: updateAttribute } = useMutation(
    ({ optionId, value }: { optionId: number, value: string }) => putOption(optionId, value),
  )

  const { mutate: deleteFunction } = useDeleteOption()
  const addQuantity = useAddItemAttributeOption()
  const addNewPriceAttribute = usePostNewAttribute()

  const addPriceAttributeWithQuantity = async () => {
    const attr = priceAttribute || await addNewPriceAttribute(section.id, 'price')
    if (attr) {
      await addQuantity(section, attr)
    }
  }

  return (
    <div className="flex flex-col space-y-2">
      <Label label={t('generic.quantity', 'Quantity')} />
      <div className="flex space-x-2 items-center">
        {(!priceAttribute || priceAttribute?.options.length === 0) && !disabled ? (
          <EmptyState
            onClick={addPriceAttributeWithQuantity}
            message={t('req.no_quantity_added', 'No quantity added')}
            buttonMessage={t('req.add_quantity', 'Add Quantity')}
          />
        ) : null}
        <div className="flex flex-col">
          {priceAttribute?.options.map((option, index) => (
            <div className="flex py-2 justify-center items-center" key={option.id}>
              <span>{t('req.price_for', 'Price for')}</span>
              <div className="w-28 flex items-center mx-2">
                <Input
                  disabled={disabled}
                  type="number"
                  onBlur={(value) => {
                    updateAttribute({ optionId: option.id, value: value?.toString() || '' })
                  }}
                  defaultValue={option.option_value}
                />
                {index !== priceAttribute.options.length - 1 && disabled
                  && <span className="font-bold ml-2 center text-mountain-gray">•</span>}
              </div>
              <span>{t('generic.pieces', 'Pieces')}</span>
              <DeleteButton
                deleteFunction={() => deleteFunction(option.id)}
                hasTooltip
                tooltipText={t('generic.delete', 'Delete')}
              />
            </div>
          ))}
        </div>

      </div>
      {!disabled && priceAttribute && priceAttribute.options.length > 0
        ? <AddButton label={t('req.add_quantity', 'Add Quantity')} onClick={() => priceAttribute && addQuantity(section, priceAttribute)} />
        : null}
    </div>
  )
}
export default ProductDetailsQuantity
