import { CSSProperties } from 'react'

export const reveal = <T extends {style?: CSSProperties}> (nodes: T[]) => nodes.map((node) => ({
  ...node,
  style: {
    ...(node.style || {}),
    opacity: 1,
  },
}))

export const hide = <T extends {style: CSSProperties}> (nodes: T[]) => nodes.map((node) => ({
  ...node,
  style: {
    ...(node.style || {}),
    opacity: 0,
  },
}))
