import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { dateTimeFormat } from '../../../shared/constants/dateFormat'
import useApprovals from '../hooks/useApprovals'
import useIntegration from '../hooks/useIntegration'
import useUpdateRequisitionStatus from '../hooks/useUpdateRequisitionStatus'
import useGetRequisitionData from '../subModules/Definition/hooks/useGetRequisition'

const RequisitionCompletedBanner: React.FC = () => {
  const { approvalData } = useApprovals()
  const { mutate: updateStatus } = useUpdateRequisitionStatus()
  const { data, isLoading: isLoadingRequisition } = useGetRequisitionData()
  const { triggerTransmission, integrationData } = useIntegration()
  const { t } = useTranslation()

  const handleTransfer = () => {
    triggerTransmission()

    updateStatus({ status: 'TRANSFERRED', reason: 'manually transferred' })
  }

  if (isLoadingRequisition) return null

  if (!data?.form_submission) return null

  const steps = [
    {
      id: 'Step 1', name: t('generic.requisition'), href: '#', status: 'complete',
    },
    {
      id: 'Step 2', name: t('generic.approve'), href: '#', status: 'current',
    },
    {
      id: 'Step 3', name: t('generic.transfer'), href: '#', status: 'upcoming',
    },
  ]

  return (
    <div className="  w-full mb-6 py-4 text-gray-700 text-sm bg-white px-8 rounded-md border">
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
          {steps.map((step) => (
            <li key={step.name} className="md:flex-1">
              {step.status === 'complete' && (
                <a
                  href={step.href}
                  className="group flex flex-col border-l-4 border-vibrant-blue py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                >
                  <span className="text-sm   border-vibrant-blue group-hover:text-indigo-800">
                    {step.id}
                    {' '}
                    -
                    {' '}
                    {step.name}
                  </span>
                  <span className="text-sm font-bold">
                    {t('generic.submitted')}
                    {' '}
                    {dayjs(data?.created_at).format(dateTimeFormat)}
                  </span>
                </a>
              )}

              {step.status === 'current' && (
                <a
                  href={step.href}
                  className={`flex flex-col border-l-4  py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 border-vibrant-blue md:pb-0 ${approvalData?.status === 'APPROVAL_WORKFLOW_RUNNING' ? ' animate-pulse' : ''}
                  ${approvalData?.steps && approvalData?.steps?.findIndex((s) => s?.approval_status === 'REJECTED') > -1 ? ' border-red-600 ' : ''}
                  `}
                  aria-current="step"
                >
                  <span className="text-sm  border-vibrant-blue">
                    {step.id}
                    {' '}
                    -
                    {' '}
                    {approvalData?.steps && approvalData?.steps?.findIndex((s) => s?.approval_status === 'REJECTED') > -1 ? 'Rejected' : step.name}
                  </span>
                  {approvalData?.status === 'APPROVAL_WORKFLOW_COMPLETED' && <span className="text-sm font-bold">{t('approval.completed')}</span>}
                </a>
              )}

              {step.status === 'upcoming' && (
                <a
                  href={step.href}
                  className={`flex flex-col border-l-4  py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4  md:pb-0  
                  ${(approvalData?.status === 'APPROVAL_WORKFLOW_COMPLETED'
                      || data?.status === 'READY_FOR_TRANSFER') && data?.status !== 'TRANSFERRED' ? 'animate-pulse' : ''}
                  ${approvalData?.status === 'APPROVAL_WORKFLOW_COMPLETED'
                      || data?.status === 'READY_FOR_TRANSFER'
                      || data?.status === 'TRANSFERRED' ? ' border-vibrant-blue ' : 'border-gray-600'}              
                  `}
                >
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    {step.id}
                    {' '}
                    -
                    {' '}
                    {step.name}
                    <button type="button" onClick={() => handleTransfer()} className="w-4"> &nbsp; </button>
                  </span>
                  {(approvalData?.status === 'APPROVAL_WORKFLOW_COMPLETED' || approvalData?.status === 'NO_APPROVAL_REQUIRED') && data?.status !== 'TRANSFERRED' && (
                    <button
                      type="button"
                      className="bg-vibrant-blue text-white py-1 mt-2 rounded-md w-auto"
                      onClick={() => handleTransfer()}
                    >
                      Transfer
                    </button>
                  )}
                  {data?.status === 'TRANSFERRED' && (
                    <div className="font-bold">
                      <div>{t('submission.transfer_completed')}</div>
                    </div>
                  )}
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}

export default RequisitionCompletedBanner
