import { useInfiniteQuery } from 'react-query'
import { apiGetRequisitions, RequisitionFilters } from '../services/BasecampService'

export default (filters: RequisitionFilters = {}) => useInfiniteQuery(['requisitions', filters], ({ pageParam = 1 }) => apiGetRequisitions(pageParam, filters), {
  getNextPageParam: (data) => (
    (data.meta?.last_page && (data?.meta?.current_page < data?.meta?.last_page))
      ? data.meta.current_page + 1
      : undefined
  ),
})
