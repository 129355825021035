import { useTranslation } from 'react-i18next'
import usePermissions from '../../../../core/hooks/usePermissions'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import AddButton from '../../../../shared/components/atoms/buttons/AddButton'
import Titlebar from '../../../../shared/components/layout/Titlebar'
import { USER_ADD } from '../../../../shared/constants/permissions'
import CompanyUsersNew from './CompanyUsersNew'

const CompanyUsersHeader = () => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const permissions = usePermissions([{ slug: USER_ADD }])

  const triggerUsersModal = () => {
    modal.set(
      {
        isOpen: true,
        title: 'account.create_new_user',
        content: <CompanyUsersNew />,
      },
    )
  }

  return (
    <Titlebar title="generic.user_plural">
      {permissions.get(USER_ADD).allowed
        && (
          <AddButton onClick={triggerUsersModal} disabled={!permissions.get(USER_ADD).allowed}>
            {t('account.add_user')}
          </AddButton>
        )}
    </Titlebar>
  )
}

export default CompanyUsersHeader
