import { Dispatch, SetStateAction } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { UserType } from '../../../types/shared/AssignmentTypes'
import companyQueryStrings from '../../Company/constants/companyQueryStrings'
import keys from '../constants/settingsQueryKeys'
import { apiGetUser, apiUpdateUser } from '../services/SettingsService'

export default (
  setShowSaveCancel: Dispatch<SetStateAction<boolean>>,
) => {
  const cache = useQueryClient()
  const { data: userData } = useQuery([keys.USER_PROFILE], apiGetUser)

  return useMutation((data: Partial<UserType>) => apiUpdateUser(
    { userId: userData?.id as number, data },
  ), {
    onSuccess: (newData: UserType) => {
      cache.setQueryData(
        [companyQueryStrings.company_addresses],
        () => {
          setShowSaveCancel(false)
          toastr.success('success.item_updated', 'successAddressUpdated')
          return newData
        },
      )
    },
    onError: () => { toastr.error('error._item_not_updated', 'errorAddressUpdate') },
  })
}
