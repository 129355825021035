import { INodeOrStringChildren } from '../../types/shared/GeneralTypes'

interface IBlueButton extends INodeOrStringChildren {
  onClick: () => void
  disabled?: boolean
}

// TODO: REmove
const BlueButton: React.FC<IBlueButton> = ({
  onClick, children, disabled,
}) => (
  <button
    type="button"
    className={`
       px-3 text-sm py-2.5 text-white rounded-md 
      ${disabled ? 'bg-gray-300' : 'bg-sky-blue-light hover:bg-sky-blue transition-colors'}
    `}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export default BlueButton
