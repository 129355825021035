import { useMutation, useQueryClient } from 'react-query'
import { removeRequisitionAttachment } from '../../../../services/attachmentsService'
import useGetRequisitionData from '../../../../hooks/useGetRequisition'
import toastr from '../../../../../../../../shared/components/organisms/toastr/toastr'
import keys from './ReqDefAttachmentsQueryKeys'
import { MediaType } from '../../../../../../../../types/shared/MediaTypes'

export default () => {
  const { data: requisition } = useGetRequisitionData()
  const cache = useQueryClient()

  return useMutation(
    (attachment: MediaType) => {
      if (!requisition) return Promise.resolve(null)
      return removeRequisitionAttachment(requisition!.uuid, attachment.uuid)
    },
    {
      onSuccess: () => {
        cache.invalidateQueries([keys.REQUISITION_ATTACHMENTS, requisition!.uuid])
        toastr.success('success.item_deleted', 'requisitionAttachment')
      },
      onError: () => {
        toastr.error('error.item_not_deleted', 'requisitionAttachment')
      },
    },
  )
}
