import { useMutation, useQueryClient } from 'react-query'
import { OfferDataType } from '../../../../types/shared/OfferDataTypes'
import toastr from '../../../../shared/components/organisms/toastr/toastr'
import { replaceById } from '../../../../shared/utils/arrayHelpers'
import queryKeys from '../../constants/queryKeys'
import { toggleDocumentAcceptance } from '../../services/services'

export default function useToggleDocumentAcceptance({ token, onSuccess }
  : { token: string, onSuccess: () => void }) {
  const cache = useQueryClient()

  const { mutate } = useMutation(
    (documentId: number) => toggleDocumentAcceptance(token, documentId),
    {
      onSuccess: (newData) => {
        onSuccess()
        cache.setQueryData<OfferDataType | undefined>(
          [queryKeys.offer],
          (oldData) => {
            if (oldData?.customer_document_acceptance) {
              return {
                ...oldData,
                customer_document_acceptance:
                  replaceById(
                    oldData?.customer_document_acceptance,
                    newData.id,

                    newData,
                  ),
              }
            } return undefined
          },
        )
      },
      onError: () => {
        toastr.error('error.item_could_not_be_duplicated', 'error.duplicated')
      },
    },
  )
  return mutate
}
