import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useOpenNewFormModal from '../../hooks/useOpenNewFormModal'
import useGetCurrentUser from '../../../../../core/hooks/useGetCurrentUser'

interface ICreateFormButton {
    isTestForm?: boolean
}

const CreateFormButton: React.FC<ICreateFormButton> = ({ isTestForm = false }) => {
  const { data: currentUser } = useGetCurrentUser()
  const { t } = useTranslation()

  const openNewFormModal = useOpenNewFormModal(isTestForm)
  if (isTestForm && currentUser?.email !== 'service@lhotse.de') {
    return null
  }

  return (
    <Button
      color="sky-blue-light"
      variant="solid"
      onClick={openNewFormModal}
      hidden={currentUser?.email === 'service@lhotse.de'}
    >
      {isTestForm ? t('forms.create_new_test_form') : t('forms.create_new_form')}
    </Button>
  )
}

export default CreateFormButton
