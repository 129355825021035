import { useTranslation } from 'react-i18next'
import Table from '../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../shared/components/atoms/SharedTable/TableHeader'
import TableRow from '../../../../../shared/components/atoms/SharedTable/TableRow'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useGetWorkflows from '../../../../Workflow/hooks/useGetWorkflows'
import useGetFormData from '../../../hooks/useGetFormData'
import FormNavigationTabs from '../../FormNavigationTabs'
import useUpdateForm from '../../hooks/useUpdateForm'
import { dateTimeFormatter } from '../../../../../shared/constants/dateFormat'

interface ICompanyFormWorkflow { }

const CompanyFormWorkflow: React.FC<ICompanyFormWorkflow> = () => {
  const { t } = useTranslation()

  const { data } = useGetWorkflows()
  const { data: form } = useGetFormData()
  const { updateForm } = useUpdateForm()

  const handleSelectWorkflow = (workFlowUuid: string) => {
    updateForm({ workflow_uuid: workFlowUuid })
  }

  return (
    <div className="h-max flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <FormNavigationTabs />
      </div>
      <TableContainer>
        <TableHead>
          <TableHeader>{t('workflows.workflow_name', 'Workflow Name')}</TableHeader>
          <TableHeader>{t('workflows.published', 'Published')}</TableHeader>
          <TableHeader right />
        </TableHead>
        <Table>
          {data?.map((workflow) => (
            <TableRow key={workflow.uuid}>
              <TableCell>{workflow.name}</TableCell>
              <TableCell>{workflow.published_at && dateTimeFormatter(workflow.published_at, false)}</TableCell>

              <TableCell className="text-right">
                <div className="flex justify-end">
                  {workflow.published_at ? form?.workflow_uuid !== workflow.uuid && (
                  <Button
                    className="btn-primary"
                    onClick={
                    () => handleSelectWorkflow(workflow.uuid)
}
                  >
                    {t('workflows.select_workflow', 'Select Workflow')}
                  </Button>
                  ) : t('workflows.workflow_not_published', 'Workflow not published')}
                  {form?.workflow_uuid === workflow.uuid && t('workflows.selected_workflow', 'Selected Workflow')}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </div>
  )
}

export default CompanyFormWorkflow
