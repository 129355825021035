import CoreLoadingIcon from '../../../../../core/components/CoreLoadingIcon'
import usePermissions from '../../../../../core/hooks/usePermissions'
import { FORM_SUBMIT } from '../../../../../shared/constants/permissions'
import FormPreview from './FormPreview'
import useGetCompanyFormsWithTypes from '../../../../Forms/FormBuilder/hooks/useGetCompanyFormsWithTypes'

const FormsRecommendation: React.FC = () => {
  const permissions = usePermissions([{ slug: FORM_SUBMIT }])
  const { data: availableForms, isLoading: isLoadingForms } = useGetCompanyFormsWithTypes(true)

  if (!permissions.get(FORM_SUBMIT).allowed) return null
  if (!availableForms?.length) return null

  if (isLoadingForms) return <CoreLoadingIcon />

  return (

    <div className="flex gap-x-4 gap-y-4 flex-wrap w-full ">
      {availableForms?.length
        && availableForms.map((form) => (
          <FormPreview
            key={form.__uuid}
            form={form}
          />
        ))}
    </div>

  )
}

export default FormsRecommendation
