import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'
import IndentationWrapper from '../../../../../shared/components/atoms/IndentationWrapper'
import { requisitionDefinitionEditorRowInfoPicker } from '../../../../Requisition/subModules/Definition/utils/rowInfoPicker'
import useUpdateLineItemCommentCache from '../../../hooks/useUpdateLineItemCommentCache'
import { invalidIdStateType } from '../../../utils/validationUtils'
import Comment from './Comment'
import useUpdateOfferComment from './hooks/useUpdateOfferComment'
import LineItemContainer from './LineItemContainer/LineItemContainer'
import OneLineInputContainer from './LineItemContainer/OneLineItem/OneLineInputContainer'
import ReqInfo from './ReqInfo/ReqInfo'

interface IRow {
  row: LineItemItemAttributeType
  updateAutoSave: () => void
  firstRow: boolean
  sectionIndex: number
  invalidIds: invalidIdStateType | undefined
}

const Row: React.FC<IRow> = ({
  row, updateAutoSave, firstRow, sectionIndex, invalidIds,
}) => {
  const updateOfferComment = useUpdateOfferComment()
  const findAndUpdateSupplierComment = useUpdateLineItemCommentCache()

  const { placeholder } = requisitionDefinitionEditorRowInfoPicker(row.type)
  const isMultiLineInput = !!row.options.length && row.type

  if (row.type === 'attribute') return null

  return (
    <div className="gap-x-4 flex flex-col">
      <div className={`flex items-start ${!isMultiLineInput && 'justify-between'}`}>
        <ReqInfo row={row} invalidIds={invalidIds} />
        <OneLineInputContainer
          row={row}
          placeholder={placeholder}
          updateAutoSave={updateAutoSave}
          firstRow={firstRow}
          sectionIndex={sectionIndex}
        />
      </div>
      {(row?.line_items?.[0]?.comment && !isMultiLineInput)
        && (
          <Comment
            comment={row?.line_items[0]?.comment.comment}
            onChange={
              (newComment: string | null) => findAndUpdateSupplierComment(
                newComment,
                sectionIndex,
                row,
                0,
              )
            }
            onBlur={
              () => updateOfferComment(
                row?.line_items[0],
                updateAutoSave,
              )
            }
            noIndent
            className="mt-3"
          />
        )}
      <div className="flex-col gap-x-4">
        <div className="flex gap-x-4 w-full">
          <IndentationWrapper className="flex flex-col gap-y-2 w-full">
            {row.options.map((option) => (
              <LineItemContainer
                key={option.id}
                row={row}
                option={option}
                sectionIndex={sectionIndex}
                updateAutoSave={updateAutoSave}
              />
            ))}
          </IndentationWrapper>
        </div>
      </div>
    </div>
  )
}

export default Row
