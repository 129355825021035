import { useTranslation } from 'react-i18next'

import { ApprovalStepStatus, ApproverType } from '../../../../types/Requisition/ApprovalFlowDrawerTypes'

type StatusKeyType = keyof typeof ApprovalStepStatus
interface IApprovalFlowTimelineStepStatuses {
  status: StatusKeyType, reason?: string, approver?: ApproverType
}

const ApproverUserDisplay: React.FC<{ approver?: ApproverType }> = ({ approver }) => {
  const { t } = useTranslation()
  if (!approver) return null

  return (
    <p className="text-xs text-gray-500">
      {t('generic.by')}
      {' '}
      <b>{approver?.full_name}</b>
    </p>
  )
}

const ApprovalFlowTimelineStepStatuses:
  React.FC<IApprovalFlowTimelineStepStatuses> = ({ status, reason, approver }) => {
    const { t } = useTranslation()

    switch (status) {
      case ApprovalStepStatus[ApprovalStepStatus.APPROVED]:
        return (
          <>
            <p className="text-xs text-gray-500">{t('approval.approved_by')}</p>
            <ApproverUserDisplay approver={approver} />
          </>
        )
      case ApprovalStepStatus[ApprovalStepStatus.APPROVAL_PENDING]:
        return (
          <p className="text-xs text-gray-500">{t('approval.pending_approval')}</p>
        )
      case ApprovalStepStatus[ApprovalStepStatus.APPROVAL_QUEUED]:
        return (
          <p className="text-xs text-gray-500">{t('approval.waiting_for_previous_approval')}</p>
        )
      case ApprovalStepStatus[ApprovalStepStatus.NOT_REQUIRED]:
        return (
          null
        )
      case ApprovalStepStatus[ApprovalStepStatus.REJECTED]:
        return (
          <div className="bg-red-200 p-2 rounded-md mt-2 border border-red-600">
            <p className="text-xs text-gray-900">{t('approval.rejected')}</p>
            <ApproverUserDisplay approver={approver} />
            <p className="text-xs text-gray-900 mt-2">
              <div className="font-bold">
                {t('generic.reason')}
                :
              </div>
              {' '}
              {reason || '-'}
            </p>
          </div>
        )
      default:
        return <div>{status}</div>
    }
  }

export default ApprovalFlowTimelineStepStatuses
