const cleanStringFormMermaid = (string:string) => string?.replaceAll('"', "'")
  .replaceAll('(', '_')
  .replaceAll(')', '_')

export default (
  tree: any,
) => {
  let mermaidMarkup = '\ngraph TD\n'
  try {
    tree.forEach((t:any, index:number) => {
      t.nextQuestion.forEach((tq:any) => {
        mermaidMarkup += `${index}${t.question.__logic ? '{' : '('}${
          cleanStringFormMermaid(t.question.label)
        }${t.question.__logic ? '}' : ')'}-->${tq}\n`
      })
      if (t.question.__logic?.switch?.cases?.flatMap((c:any) => c.additionalConditions).length) {
        t.question.__logic.switch.cases.flatMap((c:any) => c.additionalConditions).forEach((ac:any) => {
          if (!ac?.source) return
          const sourceIndex = tree.findIndex((q:any) => q.question.__uuid === ac.source)
          mermaidMarkup += `${sourceIndex}-.->${index}\n`
        })
      }
    })
  } catch (e) {
    console.log('Mermaid did not work', e)
  }

  return mermaidMarkup
}
