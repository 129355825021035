import { useTranslation } from 'react-i18next'
import VerticalNav from '../../../shared/components/layout/VerticalNav/VerticalNav'

const CompanyTabbar: React.FC = () => {
  const { t } = useTranslation()

  const tabs = [{
    link: '/company/general',
    title: t('generic.general', 'General'),
  },
  ]

  return (
    <VerticalNav items={tabs} />
  )
}

export default CompanyTabbar
