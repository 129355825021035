import { useMutation } from 'react-query'
import { Offer } from '../../../types/Offering'
import { putOffer } from '../../Offerings/services'
import useRefetchOfferQuery from './useRefetchQuery'

export const useUpdateOffer = () => {
  const refetchOfferQuery = useRefetchOfferQuery()

  return useMutation((updatedOffer: Offer) => putOffer({
    ...updatedOffer,
  }), {
    onSuccess: async ({ data: updatedOffer }) => {
      // Refetch the Offerings List based on the position of the current process (form or intake)
      await refetchOfferQuery && refetchOfferQuery(updatedOffer.reference_key)
    },
  })
}

export default useUpdateOffer
