import { useFormContext } from '../../../../core/providers/FormProvider'
import { FormQuestionType, FormTemplateType } from '../../../../types/Forms'
import useQuestionValues from './useQuestionValues'
import useUpdateSubmission from './useUpdateSubmission'

export default (question: FormQuestionType) => {
  const { submission: [submissionData, setSubmission], findSection } = useFormContext()
  const updateSubmission = useUpdateSubmission()

  const [, setQuestionValues] = useQuestionValues(question)

  const activeSection = findSection(question.__uuid)

  const activeSectionIndex = submissionData?.sections
    .map((submissionSection) => submissionSection.__uuid)
    .indexOf(activeSection?.__uuid || '')

  const nextSectionParentUUID = activeSectionIndex
    && submissionData?.sections[activeSectionIndex + 1]?.loop.__looped_parent_uuid

  const nextSectionHasSameLoopParent = nextSectionParentUUID === activeSection?.__uuid
    || (
      activeSection?.loop.__looped_parent_uuid
      && nextSectionParentUUID === activeSection?.loop.__looped_parent_uuid
    )

  return (isNo: boolean) => {
    if (!submissionData || !activeSection) { return }

    const newQuestionValue = question?.options?.find(({ __uuid, uuid }) => uuid === 'no' || __uuid === 'no') || { __uuid: 'no', value: 'No' }

    const { newForm } = setQuestionValues(
      isNo
        ? [newQuestionValue]
        : [],
    )

    const sectionIndex = newForm?.sections
      .map((formSection) => formSection.__uuid)
      .indexOf(activeSection.__uuid)

    if (sectionIndex !== undefined) {
      const newSections = (newForm?.sections || [])
      if (nextSectionHasSameLoopParent) {
        newSections.splice(sectionIndex + 1, 1)
      }

      const newFormResponse: FormTemplateType = {
        ...submissionData,
        sections: newSections,
      }

      setSubmission(newFormResponse)
    }

    updateSubmission()
  }
}
