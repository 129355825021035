import { useQuery } from 'react-query'
import OfferViewQueryKeys from '../../../../../Requisition/subModules/Award/constants/OfferViewQueryKeys'
import { getRequisitionSections } from '../../../../../Requisition/subModules/Definition/services/editorService'
import useGetToken from '../../../../hooks/useGetToken'
import { getLineItemData } from '../../../../services/services'
import {
  ReqDefEditorSectionType,
} from '../../../../../Requisition/subModules/Definition/constants/editorTypes'
import { LineItemSectionType } from '../../../../../../types/shared/LineItemDataTypes'

export type SectionOrLineItemType = (ReqDefEditorSectionType | LineItemSectionType) & { section_id?: number, id?: number }

const useGetSectionsOrLineItems = ({ requisitionId, enabled = true }: {requisitionId?: number, enabled?: boolean} = {}) => {
  const token = useGetToken()

  return useQuery<SectionOrLineItemType[]>(
    [OfferViewQueryKeys.SECTIONS_DATA],
    () => (
      !token
        ? getRequisitionSections(JSON.stringify(requisitionId))
        : getLineItemData(token)
    ),
    { enabled },
  )
}

export default useGetSectionsOrLineItems
