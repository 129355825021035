import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../core/components/CoreLoadingIcon'
import { CATALOG_ITEM } from '../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../services/UnifiedSearchService'
import CatalogBlockPricingTable from './CatalogMainContainer/CatalogBlockPricing/CatalogBlockPricingTable'
import CatalogBuyContainer from './CatalogMainContainer/CatalogBuyContainer'
import CatalogProductImages from './CatalogMainContainer/CatalogProductImages'
import CatalogProductTitle from './CatalogMainContainer/CatalogProductTitle'

interface ICatalogMainContainer {
  catalogId: string
}

const CatalogMainContainer: React.FC<ICatalogMainContainer> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))

  if (!data) return <CoreLoadingIcon />

  return (
    <div className="flex justify-between ">
      <div className="w-1/3 flex justify-start flex-col">
        <div className="sticky top-0">
          <CatalogProductImages images={data.mimeInfo} />
        </div>
      </div>
      <div className="text-sm w-2/3 px-8 text-justify">
        <CatalogProductTitle catalogId={catalogId} />
        <div className="flex w-full gap-2 mt-2">
          <div className="w-full flex flex-col">
            <CatalogBlockPricingTable catalogId={catalogId} />
          </div>
          <CatalogBuyContainer catalogId={catalogId} />
        </div>
      </div>
    </div>
  )
}
export default CatalogMainContainer
