import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useGetRequisitionData from '../../../subModules/Definition/hooks/useGetRequisition'
import useSubmitRequisition from '../../../utils/useSubmitRequisition'

const ReqTopbarSubmitButton: React.FC = () => {
  const { data: requisitionData } = useGetRequisitionData()
  const { t } = useTranslation()

  const submitRequisition = useSubmitRequisition()

  if (requisitionData?.form_submission) return null

  if (!['DRAFT'].includes(requisitionData?.status || 'DRAFT')) return null

  return (
    <Button
      variant="solid"
      color="lhotse-orange"
      onClick={() => submitRequisition()}
      dataCy="submit-requisition"
    >
      {t('req_coordinate.submit_requisition')}
    </Button>
  )
}

export default ReqTopbarSubmitButton
