import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../../../shared/components/atoms/ErrorMessage'
import RequiredLabel from '../../../../shared/components/atoms/Labels/RequiredLabel'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import useGetOffer from '../../hooks/useGetOffer'
import { invalidIdStateType } from '../../utils/validationUtils'
import DocumentCheckbox from './DocumentCheckbox'
import DocumentName from './DocumentName'

interface IDocumentRow {
  invalidIds: invalidIdStateType
  document: CustomerDocumentType
}

const DocumentRow: React.FC<IDocumentRow> = ({ document, invalidIds }) => {
  const { t } = useTranslation()
  const { data: offerData } = useGetOffer()
  const isAccepted = !!offerData?.customer_document_acceptance?.find(
    (acceptedDocument) => (acceptedDocument.customer_document_id === document.id
      && acceptedDocument.accepted),
  )
  const [checked, setChecked] = useState(isAccepted)

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex relative border border-gray-200 p-4 items-center gap-x-4 rounded-md">
        <RequiredLabel
          className="absolute left-0 h-8"
          style={{ top: 'calc(50%-32px)' }}
          shouldHide={!document.required}
        />
        <DocumentCheckbox
          checked={checked}
          setChecked={setChecked}
          document={document}
        />
        <DocumentName
          document={document}
          checked={checked}
          setChecked={setChecked}
        />
      </div>
      <ErrorMessage
        error={t('supplier_offer.must_accept_document')}
        shouldHide={!(invalidIds?.documents?.includes(document.id) && !checked)}
      />
    </div>
  )
}

export default DocumentRow
