import Badge from '../../atoms/tags/Badge'

interface ISupplierCountryBadge {
  country: string | undefined
}

const SupplierCountryBadge: React.FC<ISupplierCountryBadge> = ({
  country,
}) => (
  country
    ? <Badge key={country}>{country}</Badge>
    : null)

export default SupplierCountryBadge
