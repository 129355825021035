import { ref } from 'yup'
import { getQuestionUuidFromPath } from './getQuestionUuidFromValidationPath'

/**
 * @param schema
 * @returns {FormQuestionType | undefined}
 */
export const getTestedQuestion = (schema) => schema.resolve(ref(`$${getQuestionUuidFromPath(schema.path)}`))

export default getTestedQuestion
