import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import CoreLoading from '../../../../../core/components/CoreLoading'
import ReqTopbarApprovalPendingText from '../../../components/layout/ReqTopbar/ReqTopbarApprovalPendingText'
import useShowCoordinate from '../hooks/useShowCoordinate'
import SourcingGuide from './SourcingGuide'
import SupplierSelectionContainer from './SupplierSelectionContainer'

import TabbedNav from '../../../../../shared/components/layout/TabbedNav/TabbedNav'
import { TabbedNavItemType } from '../../../../../types/shared/TabbedNavItemType'

const CoordinateContainer = () => {
  const { t } = useTranslation()

  const { data: showCoordinate, isLoading } = useShowCoordinate()

  const [navItems, setNavItems] = useState<TabbedNavItemType[]>([
    {
      tabIndex: 0,
      title: t('coordinate.supplier_search', 'Supplier search'),
      current: true,
    },
    {
      tabIndex: 1,
      title: t('coordinate.supplier_selection', 'Supplier selection'),
      current: false,
    },
  ])
  const [currentActiveTab, setCurrentActiveTab] = useState(0)

  const onTabChange = (tabIndex: number) => {
    const newNavItems = navItems.map((item: TabbedNavItemType) => {
      if (item.tabIndex === tabIndex) {
        return {
          ...item,
          current: true,
        }
      }
      return {
        ...item,
        current: false,
      }
    })
    setNavItems(newNavItems)
    setCurrentActiveTab(tabIndex)
  }

  if (isLoading) return <div><CoreLoading /></div>

  return (
    <div className="flex flex-col w-full gap-8">
      {showCoordinate
        ? (
          <>
            <h2 className="text-2xl font-semibold text-sky-blue-light">{t('req.coordinate')}</h2>
            <div className="w-1/2 md:w-1/3 mb-4">
              <TabbedNav items={navItems} onTabChange={onTabChange} />
            </div>

            {currentActiveTab === 0 && (<SourcingGuide />)}
            {currentActiveTab === 1 && <SupplierSelectionContainer />}
          </>
        )
        : (
          <div className="flex w-full justify-center text-gray-600 my-6 text-xl">
            <ReqTopbarApprovalPendingText
              text={t('coordinate.supplier_selection_approval_pending')}
            />
          </div>

        )}
    </div>
  )
}

export default CoordinateContainer
