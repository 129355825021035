import { WorkflowBlockTrigger } from '../../../../../../../types/Workflow/WorkflowBlock'
import useGetInputTypes from '../../../../../../Forms/hooks/useGetInputTypes'
import useWorkflowBlockDraftMutations from '../../../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflow } from '../../../../../providers/WorkflowProvider'
import FormFieldPlaceholder from '../../../../../../Forms-v2/FormFieldPlaceholder'
import SearchSelect from '../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { assureOperatorIsValid } from './TriggerComparisonSelector'
import { InputSearchSelectItemType } from '../../../../../../../types/shared/InputSearchSelectTypes'

export const TriggerInputTypeSelector = ({ trigger }: { trigger: WorkflowBlockTrigger }) => {
  const { data: inputTemplates } = useGetInputTypes()
  const { updateTrigger } = useWorkflowBlockDraftMutations()
  const { readonly } = useWorkflow()

  if (!inputTemplates || inputTemplates.length === 0) return <FormFieldPlaceholder />

  const handleUpdate = (item: InputSearchSelectItemType) => {
    const selectedInput = inputTemplates.find((it) => item.id === it.uuid)
    if (!selectedInput) return

    updateTrigger({
      ...trigger, input: selectedInput, value: '', comparison_operator: assureOperatorIsValid(selectedInput, trigger.comparison_operator),
    })
  }

  return (
    <SearchSelect
      options={inputTemplates.map((it) => ({ label: it.name, id: it.uuid! }))}
      onChange={handleUpdate}
      currentSelection={trigger.input ? { label: trigger.input.name || (trigger.input as unknown as { input_name: string }).input_name, id: trigger.input.uuid! } : undefined}
      disabled={readonly}
    />
  )
}

export default TriggerInputTypeSelector
