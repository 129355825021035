import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import ReqCoordinateContainerAllRows from '../../SupplierSection/SupplierRows'
import RCSourcingMoreButton from './RCSourcingMoreButton'

interface ISupplierContent { setActiveSection: Dispatch<SetStateAction<number>> }

const SupplierContent: React.FC<ISupplierContent> = ({
  setActiveSection,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <p>{t('generic.supplier_plural')}</p>
        <ReqCoordinateContainerAllRows />
      </div>
      <RCSourcingMoreButton newSectionNumber={3} setActiveSection={setActiveSection} />
    </div>
  )
}

export default SupplierContent
