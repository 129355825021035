import { useFormContext } from '../../../../../core/providers/FormProvider'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const KeyValuesQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'KEY_VALUE') return null

  return (
    <TextAndSubtitleInput />
  )
}

export default KeyValuesQuestion
