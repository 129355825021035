import { MdUnfoldMore } from 'react-icons/md'
import Badge from '../../../../../shared/components/atoms/tags/Badge'
import RowCell from '../../../../../shared/components/layout/RowCell'
import SupplierSearchType from '../../../../../types/Suppliers/SupplierSearchType'

interface ISupplierCategoryCell {
  data: SupplierSearchType
}

const SupplierCategoryCell: React.FC<ISupplierCategoryCell> = ({ data }) => (
  <RowCell width="w-4/12">
    <div className="flex items-center gap-2 text-sm ">
      {!!data?.categories?.length
        && (
          <Badge key={data.categories[0].id}>
            {data.categories[0].name}
          </Badge>
        )}
      {data?.categories?.length > 1
        && <MdUnfoldMore />}
    </div>
  </RowCell>
)

export default SupplierCategoryCell
