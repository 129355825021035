import { useUtils } from '../../../../../core/providers/UtilsProvider'
import LastCallConfirmationModal from '../../../subModules/Award/components/NegotationModals/LastCallConfirmationModal/LastCallConfirmationModal'
import { lastCallStateType } from './RequisitionTopbarLastCallConfirmation'

const useOpenLastCallConfirmModal = (
  lastCallState: lastCallStateType,
) => {
  const { modal } = useUtils()

  return () => {
    modal.set({
      content: <LastCallConfirmationModal lastCallState={lastCallState} />,
      title: 'Confirm Last Call Selection',
      isOpen: true,
    })
  }
}

export default useOpenLastCallConfirmModal
