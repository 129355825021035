import { INodeChildren } from '../../../../../../../types/shared/GeneralTypes'

const SourcingContentWrapper: React.FC<INodeChildren> = ({
  children,
}) => (
  <div className="flex py-7">
    {children}
  </div>
)

export default SourcingContentWrapper
