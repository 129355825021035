import { useMessengerContext } from '../../../core/providers/MessengerProvider'

export default () => {
  const { messageProperties: [, setMessageProperties] } = useMessengerContext()

  return (newFiles: File[]) => setMessageProperties((messageProperties) => ({
    ...messageProperties,
    files: newFiles,
  }))
}
