import { useState } from 'react'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'
import { MercateoMimeItemType } from '../../../../../../../../../../types/Requisition/MercateoItemType'
import CatalogProductImageError from './CatalogProductImageError'

interface ICatalogProductImage {
  image: MercateoMimeItemType
}
const CatalogProductImage: React.FC<ICatalogProductImage> = ({ image }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  return (
    <div className="border h-96 w-96 flex justify-center">

      {isLoading
        && !error
        && <CoreLoadingIcon />}

      {error && (
        <div className="h-full flex items-center" data-cy="image-not-found">
          <CatalogProductImageError />
        </div>
      )}

      {!error
        && (
          <img
            src={image.href}
            alt={image.alt}
            onLoad={() => setIsLoading(false)}
            onError={() => setError(true)}
            data-cy="single-image"
          />
        )}

    </div>
  )
}

export default CatalogProductImage
