import Table from '../../../../../../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableRow from '../../../../../../../../../../shared/components/atoms/SharedTable/TableRow'
import { MercateoArticleFeatureType } from '../../../../../../../../../../types/Requisition/MercateoItemType'

interface ICatalogFeaturesTable {
  articleFeatures: MercateoArticleFeatureType[]
}

const CatalogFeaturesTable: React.FC<ICatalogFeaturesTable> = ({ articleFeatures }) => (
  <TableContainer>
    <Table>
      {articleFeatures.map((feat) => (
        <TableRow key={feat.name}>
          <TableCell first>{feat.name}</TableCell>
          <TableCell last>
            {feat.values.map((value) => (<p key={value}>{value}</p>))}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </TableContainer>
)

export default CatalogFeaturesTable
