import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import AddButton from '../../../../../shared/components/atoms/buttons/AddButton'
import { Offer } from '../../../../../types/Offering'
import getOfferTemplate from '../../../../Offerings/components/forms/constants/OfferTemplate'
import useCreateOffer from '../../../hooks/useCreateOffer'
import useUpdateOffer from '../../../hooks/useUpdateOffer'
import ProductSectionOfferModal from './ProductSectionOfferModal/ProductSectionOfferModal'
import ProductSectionOffersTable from './ProductSectionOffersTable/ProductSectionOffersTable'
import useGetOffers from '../../../hooks/useGetOffers'

type IProductSectionOffers = {
  offerReferenceKey: string
  defaultValues?: Partial<Offer>
}

const ProductSectionOffers: React.FC<IProductSectionOffers> = ({ offerReferenceKey, defaultValues }) => {
  const utils = useUtils()
  const { t } = useTranslation()
  const { mutateAsync: createOffer } = useCreateOffer()
  const { mutateAsync: updateOffer } = useUpdateOffer()
  const { data } = useGetOffers(offerReferenceKey)

  const handleAddOffer = async () => {
    const newOffer = getOfferTemplate(
      defaultValues || {},
    )

    const { data: offer } = await createOffer({ ...newOffer, reference_key: offerReferenceKey, is_selected: data?.length === 0 })

    utils.modal.set({ content: <ProductSectionOfferModal offer={offer} updateOffer={updateOffer} />, title: 'Add Offer', isOpen: true })
  }

  return (

    <div className="flex flex-col gap-2">
      <span className="font-semibold">{t('generic.offers', 'Offers')}</span>
      <ProductSectionOffersTable offerReferenceKey={offerReferenceKey} />
      <div className="flex items-end">
        <AddButton onClick={handleAddOffer} label={t('generic.add-offer', 'Add offer')} />
      </div>
    </div>
  )
}

export default ProductSectionOffers
