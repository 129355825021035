import { useTranslation } from 'react-i18next'
import { MdMoreHoriz, MdMoreVert } from 'react-icons/md'
import PopoverComponent from '../popover/PopoverComponent'
import MenuItem from './MenuItem'

export type MenuItemType = {
  name: string
  dataCy?: string
  onClick: (e?: any) => void
  disabled?: boolean
}

export interface IMenu {
  menuItems: MenuItemType[]
  popoverClassName?: string
  buttonClassName?: string
  customButton?: React.ReactElement,
  buttonId?: string
  buttonCy?: string
  last?: boolean
}

const Menu: React.FC<IMenu> = ({
  menuItems, popoverClassName = ' min-w-[80px] w-max-[120px] z-10 ', buttonId, buttonCy, buttonClassName, customButton,
}) => {
  if (!menuItems.length) {
    return null
  }

  return (
    <PopoverComponent
      popoverButton={customButton || (<div className={`${buttonClassName} p-1 rounded-full hover:bg-gray-100`}><MdMoreVert className=" h-5 w-5" /></div>)}
      popoverClassName={popoverClassName}
      popoverContentClassName="p-0"
      closeOnClick
      buttonId={buttonId}
      buttonCy={buttonCy}
    >
      {menuItems?.map((item, index) => (
        <MenuItem
          key={item.name}
          item={item}
          menuItemsLength={menuItems.length}
          index={index}
        />
      ))}
    </PopoverComponent>
  )
}

export default Menu
