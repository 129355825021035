import { useEffect } from 'react'
import { useFormContext } from '../../../../core/providers/FormProvider'
import useGetFormParams from '../hooks/useGetFormParams'
import useGetFormQuestion from './hooks/useGetFormQuestion'
import QuestionDetails from './QuestionDetails/QuestionDetails'
import QuestionSettings from './QuestionSettings/QuestionSettings'

const Body = () => {
  const { question: [, setQuestion] } = useFormContext()

  const questionDetails = useGetFormQuestion()

  const { location } = useGetFormParams()

  useEffect(() => {
    setQuestion(questionDetails)
  }, [questionDetails])

  if (location === 'content') {
    return (
      <div className="flex flex-col gap-y-8 ">
        <QuestionDetails />
      </div>
    )
  }

  if (location === 'settings') {
    return (
      <QuestionSettings />
    )
  }

  return null
}

export default Body
