import _ from 'lodash'
import { MercateoNumberFormatType } from '../../../../../../types/Requisition/MercateoItemType'
import calculateMercateoPrice from './calculateMercateoPrice'

const calculatePriceDifference = (
  originalPrice : MercateoNumberFormatType,
  newPrice: MercateoNumberFormatType,
) => _.round(calculateMercateoPrice(originalPrice) - calculateMercateoPrice(newPrice), 2)

export default calculatePriceDifference
