import { MdSearch } from 'react-icons/md'

interface IInputTextGraySearchIcon {
  search: boolean | undefined
}

const InputTextGraySearchIcon: React.FC<IInputTextGraySearchIcon> = ({
  search,
}) => (
  search
    ? (
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <span className="text-gray-500 text-xl" id="search-icon">
          <MdSearch className="cursor-pointer" />
        </span>
      </div>
    )
    : null
)

export default InputTextGraySearchIcon
