import { ChangeEventHandler } from 'react'
import { MdClose } from 'react-icons/md'

interface IInputTextGrayClearTextButton {
  close: boolean | undefined
  textValueWrapper: string
  disabled: boolean | undefined
  onInputChange: ChangeEventHandler<any>
}

const InputTextGrayClearTextButton: React.FC<IInputTextGrayClearTextButton> = ({
  close, textValueWrapper, disabled, onInputChange,
}) => ((close && textValueWrapper?.length > 0 && !disabled)
  ? (
    <button type="button" onClick={onInputChange} className="absolute right-3 text-gray-400 group-hover:visible invisible">
      <MdClose size={14} />
    </button>
  )
  : null)

export default InputTextGrayClearTextButton
