import { FormQuestionType, FormQuestionValue } from '../../../../../types/Forms'
import useQuestionValues from '../../hooks/useQuestionValues'
import AddressQuestionInput from './AddressInput'
import { getUpdatedByUuid } from '../../../../../shared/utils/arrayHelpers'
import KeyValueQuestion from '../atoms/KeyValueQuestion'
import { FormEvents } from '../../Questions'

interface IAddressQuestion {
  question: FormQuestionType
}

export const AddressQuestion: React.FC<IAddressQuestion> = ({
  question,
}) => {
  const [answers, setQuestionValues] = useQuestionValues(question)

  const handleUpdate = (updated: FormQuestionValue) => {
    setQuestionValues(getUpdatedByUuid(answers, [updated]))
    if (answers.every(({ value }) => !!value)) FormEvents.QuestionAnswered.dispatch(question.__uuid)
  }

  return (
    <KeyValueQuestion question={question}>
      {(answer) => (
        <AddressQuestionInput key={answer.__uuid} answer={answer} onUpdate={handleUpdate} />
      )}
    </KeyValueQuestion>
  )
}

export default AddressQuestion
