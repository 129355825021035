import { useFormContext } from '../../../../../core/providers/FormProvider'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const DateQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'DATE' && question?.type !== 'DATE_TIME') return null

  return <TextAndSubtitleInput />
}

export default DateQuestion
