import lhotseLogo from '../../../../core/assets/lhotse-logo.svg'

const TopBannerLogo: React.FC = () => (
  <div className="py-1">
    <img src={lhotseLogo} alt="Lhotse logo" className="h-6" />
  </div>

)

export default TopBannerLogo
