import {
  ChangeEvent, ChangeEventHandler, useEffect, useState,
} from 'react'
import ErrorMessage from '../../ErrorMessage'
import RequiredLabel from '../../Labels/RequiredLabel'
import InputTextGrayClearTextButton from './InputTextGrayClearTextButton'
import InputTextGrayEuroIcon from './InputTextGrayEuroIcon'
import InputTextGrayInput from './InputTextGrayInput'
import InputTextGraySearchIcon from './InputTextGraySearchIcon'

export interface IInputTextGray {
  placeholder?: string,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string,
  search?: boolean,
  euro?: boolean,
  disabled?: boolean,
  required?: boolean,
  close?: boolean,
  value?: string | null,
  fullWidth?: boolean,
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  id?: string
  dataCy?: string
  error?: string | false
  useInternalState?: boolean
  name?: string
}

const InputTextGray: React.FC<IInputTextGray> = ({
  placeholder, className, search, disabled, dataCy,
  required, euro, close, onChange, value, fullWidth, onBlur,
  id, error,
  useInternalState = false,
  name,
}) => {
  const [textValue, setTextValue] = useState(value || '')

  useEffect(() => {
    if (typeof value === 'string' && onChange) setTextValue(value)
  }, [value, onChange])

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (useInternalState || !onChange) setTextValue(e.target.value)
    if (onChange) onChange(e)
  }

  return (
    <div className="flex flex-col w-full">
      <div
        className={`relative h-full flex items-center group ${fullWidth && 'w-full '}`}
      >
        {required && <RequiredLabel className="l-0 h-6" />}
        <InputTextGrayInput
          name={name}
          placeholder={placeholder}
          textValueWrapper={textValue}
          onInputChange={onInputChange}
          required={required}
          disabled={disabled}
          id={id}
          dataCy={dataCy}
          onBlur={onBlur}
          fullWidth={fullWidth}
          className={className}
          hasIcon={search || euro}
        />
        <InputTextGrayClearTextButton
          close={close}
          textValueWrapper={textValue}
          disabled={disabled}
          onInputChange={onInputChange}
        />
        <InputTextGraySearchIcon search={search} />
        {euro && <InputTextGrayEuroIcon />}
      </div>
      <div className="relative w-full">
        {error && (
          <ErrorMessage
            className="absolute right-0 -bottom-4 w-fit"
            error={error}
          />
        )}
      </div>
    </div>
  )
}

export default InputTextGray
