import lhotseLogoWhite from '../../../core/assets/lhotse-logo.svg'
import { INodeChildren } from '../../../types/shared/GeneralTypes'
import authBackground from '../assets/mountain-background.png'

const AuthenticationLayout: React.FC<INodeChildren> = ({ children }) => (
  <div className="flex w-screen h-screen ">
    <div
      className="w-1/2 flex justify-center items-center"
      style={{ background: `url(${authBackground})`, backgroundSize: 'cover' }}
    >
      <img src={lhotseLogoWhite} alt="lhotse" className="w-56 mx-auto " />
    </div>
    <div className="w-1/2 flex items-center justify-center relative">
      <div className="w-7/12 flex flex-col gap-y-6">
        {children}
      </div>
    </div>
  </div>
)

export default AuthenticationLayout
