import useIntegration from './useIntegration'

export const useIsSapPrSent = () => {
  const { integrationData } = useIntegration()

  if (!integrationData) return undefined

  return ['success', 'pending'].includes(integrationData[integrationData.length - 1]?.status)
}

export default useIsSapPrSent
