import rowType from '../../../../../types/Requisition/rowType'

const QuestionTypes: {id: rowType, label: string}[] = [
  { id: 'text', label: 'Text' },
  { id: 'file', label: 'File' },
  { id: 'yes-no', label: 'Yes/No' },
  { id: 'date', label: 'Date' },
]

export default QuestionTypes
