// REFACTOR
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SubmitButton from '../../../../../shared/components/atoms/buttons/SubmitButton'
import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import TextInput from '../../../../../shared/components/organisms/formik/FormikTextInput'
import useLoginMutation from '../hooks/useLoginMutation'
import validationSchema from '../utils/LoginForm.validation'
import OrWithEmailMessage from './OrWithEmailMessage'

const LoginContainerLoginForm = () => {
  const { t } = useTranslation()

  const initialValues = {
    email: '',
    password: '',
  }

  const { mutate, isLoading } = useLoginMutation()

  return (
    <>
      <OrWithEmailMessage />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(v) => mutate(v)}
        validateOnBlur={false}
      >
        {({
          errors, touched, values, handleChange, handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="relative">
              <label htmlFor="email">
                <p className="py-2">{t('generic.email')}</p>
                <TextInput
                  type="email"
                  name="email"
                  handleChange={handleChange}
                  placeholder="login.email_placeholder"
                  values={values}
                  inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors.email && 'border-red-500'}`}
                  error={errors.email && touched.email && (
                    <ErrorMessage error={errors.email} />
                  )}
                  handleOnEnter={handleSubmit}
                  required
                />
              </label>
            </div>
            <div className="relative">
              <label htmlFor="password">
                <div className="flex justify-between items-center pt-4 pb-1">
                  <p className="py-2">{t('login.password')}</p>
                  <Link tabIndex={-1} to="/forgot-password" className="text-xs focus:underline text-sky-blue">
                    {t('login.forgot_password_question')}
                  </Link>
                </div>
                <TextInput
                  type="password"
                  name="password"
                  handleChange={handleChange}
                  placeholder="login.password_placeholder"
                  values={values}
                  inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors.password && 'border-red-500'}`}
                  error={errors.password && touched.password && (
                    <ErrorMessage error={errors.password} />
                  )}
                  handleOnEnter={handleSubmit}
                  required
                />
              </label>
            </div>
            <div className="relative flex justify-end pt-5">
              <SubmitButton isLoading={isLoading}>
                {t('login.login')}
              </SubmitButton>
            </div>

          </Form>
        )}
      </Formik>
    </>
  )
}
export default LoginContainerLoginForm
