import useGetOffer from '../../hooks/useGetOffer'
import useIsReqClosed from '../../hooks/useIsReqClosed'
import useIsSupplierOfferEditable from '../../hooks/useIsSupplierOfferEditable'
import {
  checkIfInputDisabled,
} from '../../utils/utils'
import Banner from './Banner/Banner'
import GrossPrice from './GrossPrice'
import SubtotalPrice from './SubtotalPrice'
import Buttons from './Buttons/Buttons'
import TaxRate from './TaxRate'

interface IFooter { validateForm: () => boolean }

const Footer: React.FC<IFooter> = ({ validateForm }) => {
  const isReqClosed = useIsReqClosed()
  const { data: offerData } = useGetOffer()
  const { userCanEditAnyOffer } = useIsSupplierOfferEditable()

  return (
    <div className="bg-white border-t bottom-0 w-full">
      <Banner />
      <div className={`flex justify-between px-8 py-4 items-top relative ${checkIfInputDisabled(offerData, userCanEditAnyOffer) && 'pointer-events-none'}`}>
        {isReqClosed && (<div className="bg-gray-50 bg-opacity-50 z-10 absolute top-0 left-0 h-full cursor-not-allowed w-full" />)}
        <SubtotalPrice />
        <TaxRate />
        <GrossPrice />
        <Buttons validateForm={validateForm} />
      </div>
    </div>
  )
}

export default Footer
