import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons/lib'
import { MdAutoStories, MdBadge, MdStar } from 'react-icons/md'
import ability, { ABILITIES } from '../../../../../../../config/ability'
import useGetSupplierInvites from '../../../hooks/useGetSupplierInvites'
import CatalogTab from '../ReqCoordinateSourcingContent/CatalogTab'
import ReqCoordinateSourcingRecommendedContent from '../ReqCoordinateSourcingContent/Recommended/RecommendedTab'
import SuppliersTab from '../ReqCoordinateSourcingContent/SuppliersTab'

export type reqCoordinateTableHeaderDataType = {
  id: number
  icon: IconType
  title: string
  content: React.FC
}

const useGetReqCoordinateTableHeaderData = (
  setActiveSection: Dispatch<SetStateAction<number>>,
): reqCoordinateTableHeaderDataType[] => {
  const { t } = useTranslation()
  const { data: invitations } = useGetSupplierInvites()

  const isSupplierInvited = invitations?.filter((invite) => invite.status !== 'CREATED').length

  const recommendedAndCatalogInfo = [{
    id: 1,
    icon: MdStar,
    title: t('RECOMMENDED'),
    content: () => ReqCoordinateSourcingRecommendedContent({ setActiveSection }),
  },
  {
    id: 2,
    icon: MdAutoStories,
    title: t('coordinate.catalog_items'),
    content: CatalogTab,
  }]

  const suppliersInfo = [{
    id: 3,
    icon: MdBadge,
    title: t('generic.supplier_plural'),
    content: SuppliersTab,
  }]

  return (ability.can(ABILITIES.mercateoSearch) && !isSupplierInvited)
    ? [...recommendedAndCatalogInfo, ...suppliersInfo]
    : [...suppliersInfo]
}

export default useGetReqCoordinateTableHeaderData
