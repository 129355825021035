import { useTranslation } from 'react-i18next'
import Titlebar from '../../../../../../shared/components/layout/Titlebar'
import BulkInviteButton from './BulkInviteButton'
import RemoveInvitesButton from './RemoveInvitesButton'
import SelectAllSuppliersToggle from './SelectAllSuppliersToggle'

const InviteHeader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-between">
      <div className="flex flex-col w-full pb-4">
        <div className="flex gap-x-2 items-end ml-auto">
          <RemoveInvitesButton />
          <BulkInviteButton />
        </div>
      </div>

    </div>
  )
}

export default InviteHeader
