import { useUtils } from '../../../../core/providers/UtilsProvider'
import CompanyFormsModalHeader from '../FormsPage/NewCompanyFormModal/CompanyFormsModalHeader'
import NewCompanyFormModal from '../FormsPage/NewCompanyFormModal/NewCompanyFormModal'

export default (isTestForm: boolean) => {
  const { modal } = useUtils()

  return () => {
    modal.set(
      {
        isOpen: true,
        title: <CompanyFormsModalHeader />,
        content: <NewCompanyFormModal isTestForm={isTestForm} />,
        className: 'max-w-[500px]',
      },
    )
  }
}
