import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'
import { apiGetTransmissionHistory, apiPostTransmission } from '../services/WorkflowService'

export default (requisitionIdProp?: string) => {
  let requisitionId = requisitionIdProp || ''
  if (requisitionId === '') {
    ({ requisitionId } = useParams<{ requisitionId: string }>())
  }

  const { data: integrationData, refetch } = useQuery(['transmissions', requisitionId], () => apiGetTransmissionHistory(requisitionId))
  const { mutate: triggerTransmission, isLoading: loadingTransmission } = useMutation(
    ['transmissions', requisitionId],
    () => apiPostTransmission(requisitionId),
  )

  return {
    integrationData, refetch, triggerTransmission, loadingTransmission,
  }
}
