import React, { useCallback, useMemo } from 'react'
import { Workflow } from '../../../types/Workflow/Workflow'
import { WorkflowChart, WorkflowChartProps } from '../components/WorkflowChart'
import { WorkflowBlock } from '../../../types/Workflow/WorkflowBlock'
import { ActiveWorkflow } from '../../../types/Workflow/ActiveWorkflow'
import { EdgeTypes, NodeTypes, useWorkflowChartNodeTypes } from './hooks/useWorkflowChartNodeTypes'

export type WorkflowChartContextType = {
  workflow: Workflow | ActiveWorkflow,
  readonly: boolean,
  addStep: (atPosition: number) => void,
  addToStep: (stepUuid: string) => void,
  isBlockSelected: (blockId: string) => boolean,
  nodeTypes: NodeTypes,
  edgeTypes: EdgeTypes,
  rerender: () => void,
  rerenderRequested: number,
}

export type WorkflowChartProviderProps = {
  workflow: Workflow | ActiveWorkflow
  readonly?: boolean
  onAddStep?: (at: number) => void,
  onAddToStep?: (stepUuid: string) => void
  selectedBlock?: WorkflowBlock | null
  nodes?: Partial<NodeTypes>
  edges?: Partial<EdgeTypes>
} & WorkflowChartProps

const WorkflowChartContext = React.createContext({} as WorkflowChartContextType)

export const WorkflowChartProvider = React.forwardRef<HTMLDivElement, WorkflowChartProviderProps>(({
  nodes, edges,
  workflow, readonly = true, selectedBlock, onAddStep, onAddToStep, ...chartProps
}, ref) => {
  const [rerenderRequested, setRerenderRequested] = React.useState(0)
  const addStep = useCallback((at: number) => {
    onAddStep && onAddStep(Math.min(workflow.steps.length + 1, Math.max(1, at)))
  }, [workflow])

  const addToStep = useCallback((stepUuid: string) => {
    onAddToStep && onAddToStep(stepUuid)
  }, [workflow])

  const types = useWorkflowChartNodeTypes({ nodes, edges })

  const workflowChartContextState = useMemo(() => ({
    workflow,
    readonly,
    addStep,
    addToStep,
    isBlockSelected: (blockUuid: string) => selectedBlock?.uuid === blockUuid,
    rerender: () => setRerenderRequested(rerenderRequested + 1),
    rerenderRequested,
    ...types,
  }), [workflow, readonly, selectedBlock, rerenderRequested])

  return (
    <WorkflowChartContext.Provider value={workflowChartContextState}>
      <WorkflowChart {...chartProps} ref={ref} />
    </WorkflowChartContext.Provider>
  )
})

WorkflowChartProvider.displayName = 'WorkflowChartProvider'

export const useWorkflowChart = () => React.useContext(WorkflowChartContext)

export default WorkflowChartProvider
