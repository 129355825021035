import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CoreTopbarLayout from '../../../../core/components/layout/CoreTopbarLayout'
import TabsNavigation from '../../../../shared/components/atoms/Tabs/TabsNavigation'
import RequisitionTopbar from '../../components/layout/ReqTopbar/RequisitionTopbar'

import FormDataProvider from '../../../Forms/FormResponsePage/providers/FormDataProvider'
import FormLogicProvider from '../../../Forms/FormResponsePage/providers/FormLogicProvider'
import RequisitionCompletedBanner from '../../components/RequisitionCompletedBanner'
import ApprovalFlowTimeline from '../ApprovalFlowDrawer/ApprovalFlowDrawerTimeline'
import useGetRequisitionData from '../Definition/hooks/useGetRequisition'
import SubmissionPageHeader from './SubmissionPageHeader'
import SubmissionPageMessageInput from './SubmissionPageMessageInput'
import SubmissionPageMessageList from './SubmissionPageMessageList'
import SubmissionSectionOverview from './SubmissionSectionOverview'

const SubmissionPage = () => {
  const [activeTab, setActiveTab] = useState('approval')
  const { t } = useTranslation()
  const { data: requisitionData } = useGetRequisitionData()
  const form = requisitionData?.form_submission?.body

  return (
    <CoreTopbarLayout topbar={<RequisitionTopbar />}>
      <div className="py-8">
        <div className="flex mx-auto w-full 2xl:w-[1300px] mb-8 border-b">
          <RequisitionCompletedBanner />
        </div>
        <div className="flex mx-auto w-full 2xl:w-[1300px] space-x-4 ">
          <div className="flex flex-grow flex-col ">
            <FormDataProvider form={form}>
              <FormLogicProvider>
                <SubmissionPageHeader />
                <SubmissionSectionOverview />
              </FormLogicProvider>
            </FormDataProvider>
          </div>
          <div className="flex flex-col bg-white rounded-md border px-6 pt-4  w-96 space-y-4 pb-12">
            <TabsNavigation
              tabs={[{
                title: t(
                  'approval',
                ),
                onClick: () => setActiveTab('approval'),
                id: 'approval',
              },
              {
                title: t(
                  'communication',
                ),
                id: 'communication',
                onClick: () => setActiveTab('communication'),
              }]}
              currentTab={activeTab}
            />

            {activeTab === 'communication'
              && (
                <>
                  <div className="w-full">
                    {requisitionData && <SubmissionPageMessageInput model="requisition" modelId={requisitionData?.id.toString()} />}
                  </div>
                  <div className="w-full">
                    {requisitionData && <SubmissionPageMessageList model="requisition" modelId={requisitionData?.id.toString()} />}
                  </div>
                </>
              )}

            {activeTab === 'approval'
              && (
                <ApprovalFlowTimeline />
              )}
          </div>
        </div>
      </div>
    </CoreTopbarLayout>
  )
}

export default SubmissionPage
