import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import { requisitionDefinitionEditorRowInfoPicker } from '../../../Definition/utils/rowInfoPicker'
import useGetOrderedInvites from '../../hooks/useGetOrderedInvites'
import ItemAttributeDescription from './ItemAttributeDescription'
import ItemAttributeValue from './ItemAttributeValue/ItemAttributeValue'
import { useOfferVisibilityContext } from '../../providers/OfferVisibilityProvider'

interface IItemAttribute {
  itemAttribute: LineItemItemAttributeType
  lastItem: boolean
}

const ItemAttribute: React.FC<IItemAttribute> = ({
  itemAttribute, lastItem,
}) => {
  const { icon: Icon } = requisitionDefinitionEditorRowInfoPicker(itemAttribute?.type)
  const invites = useGetOrderedInvites()

  const { visibleOffers } = useOfferVisibilityContext()

  return (
    <div className="flex w-full pl-5 text-sm">
      <ItemAttributeDescription itemAttribute={itemAttribute} lastItem={lastItem} />
      <div className="my-3 items-center flex h-[24px] w-[69px]">
        {Icon && <Icon className="text-gray-300 ml-1 h-[20px] w-[20px]" />}
      </div>
      <div className="flex gap-x-10 py-3">
        {invites?.map((invite, index) => (
          visibleOffers.includes(index) && (
            <ItemAttributeValue
              key={invite.id}
              offer={invite.offer}
              invite={invite}
              itemAttribute={itemAttribute}
            />
          )
        ))}
      </div>
    </div>
  )
}

export default ItemAttribute
