import { useTranslation } from 'react-i18next'
import useGetOffer from '../../hooks/useGetOffer'
import TopBannerLanguageButton from './TopBannerLanguageButton'
import TopBannerLogo from './TopBannerLogo'

const TopBanner: React.FC = () => {
  const { t } = useTranslation()
  const { data: offer } = useGetOffer()

  const getStatus = () => {
    if (['CLOSED', 'CANCELED', 'DEADLINE_PASSED', 'NOT_PURCHASED'].includes(offer?.requisition?.status || '')) return 'step.closed'
    if (offer?.requisition?.status === 'LAST_CALL_NEGOTIATION') return 'LAST_CALL_NEGOTIATION'
    return 'generic.open'
  }

  const isClosed = getStatus() === 'step.closed'

  return (
    <div className="flex justify-between px-4 bg-white py-2 items-center top-0 border-b border-gray-200 h-20">
      <TopBannerLogo />
      <div className={`text-md font-medium ${isClosed ? 'text-red-600' : 'text-yellow-600'}`}>
        {t(getStatus())}
      </div>
      <TopBannerLanguageButton />
    </div>
  )
}

export default TopBanner
