import { useTranslation } from 'react-i18next'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import { invalidIdStateType } from '../../utils/validationUtils'
import DocumentRow from './DocumentRow'

interface IDocumentsSection {
  filteredDocuments: CustomerDocumentType[] | undefined
  invalidIds: invalidIdStateType
}

const DocumentsSection: React.FC<IDocumentsSection> = (
  { filteredDocuments, invalidIds },
) => {
  const { t } = useTranslation()

  return filteredDocuments?.length
    ? (
      <div className="border border-gray-200 bg-white rounded-md mb-4">
        <div className="border-b border-gray-200 p-5">{t('generic.attached_documents')}</div>
        <div className="flex justify-between p-5 gap-x-12 w-full">
          <div className="flex flex-col w-full gap-y-3">
            {filteredDocuments?.map((document) => (
              <DocumentRow
                key={document.id}
                document={document}
                invalidIds={invalidIds}
              />
            ))}
          </div>
        </div>
      </div>
    )
    : null
}

export default DocumentsSection
