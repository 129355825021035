// TODO: Refactor this component DEV-1461

import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import queryKeys from '../subModules/Definition/constants/queryKeys'

import { apiPutRequisitionStatus } from '../services/RequisitionService'
import useIsActiveAPICall from './useIsActiveAPICall'
import { RequisitionDataType } from '../../../types/shared/RequisitionDataTypes'
import useGetRequisitionId from '../../../shared/hooks/useGetRequisitionId'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../types/shared/GeneralTypes'
import useValidateRequisition from '../subModules/Definition/utils/validationUtils'

const useSubmitRequisition = () => {
  const requisitionId = useGetRequisitionId()
  const errors = useValidateRequisition()
  const history = useHistory()
  const cache = useQueryClient()

  const allowSubmit = useRef(errors?.allowSubmit)
  allowSubmit.current = errors?.allowSubmit

  const isActiveAPICall = useIsActiveAPICall()

  const activeAPIRef = useRef(isActiveAPICall)
  activeAPIRef.current = isActiveAPICall

  let timeout: NodeJS.Timeout | undefined

  const submitRequisition = async (status: string = 'ANALYZING') => {
    if (!activeAPIRef.current) {
      if (timeout) { clearTimeout(timeout) }
      try {
        if (!allowSubmit.current) {
          toastr.error('req_def.fill_all_fields')
        } else {
          const { data: { status: newStatus } } = await apiPutRequisitionStatus(requisitionId, {
            status,
            reason: 'user submitted requisition',
          })

          cache.setQueryData<undef<RequisitionDataType>>(
            [queryKeys.requisitionData, requisitionId],
            (oldRequisitionData) => (oldRequisitionData
              ? ({ ...oldRequisitionData, status: newStatus })
              : oldRequisitionData),
          )

          history.push(`/requisition/req-${requisitionId}/execute`)
          toastr.success('success.requisition_submitted', 'successRequisitionSubmitted')
        }
      } catch { toastr.error() }
    } else {
      if (timeout) { clearTimeout(timeout) }
      timeout = setTimeout(() => submitRequisition(status), 0)
    }
  }

  return submitRequisition
}

export default useSubmitRequisition
