import { useTranslation } from 'react-i18next'
import lhotseLogo from '../../../../core/assets/lhotse-logo.svg'
import TipTapReadOnly from '../../../../shared/components/organisms/TipTapEditor/TipTapReadOnly'
import useGetOffer from '../../hooks/useGetOffer'
import './NDAForm.scss'
import NDAFormInteractiveElements from './NDAFormInteractiveElements'
import { checkActiveNDAs } from './NDAFormUtils'

const NDAForm: React.FC = () => {
  const { t } = useTranslation()

  const { data: offer } = useGetOffer()

  const activeNDA = checkActiveNDAs(offer)

  return (
    <div className="bg-gray-200 bg-opacity-40 w-screen h-full min-h-screen flex flex-col relative">
      <div className="flex justify-between bg-white z-20 px-12 py-2 items-center sticky top-0 border-b border-gray-200">
        <img src={lhotseLogo} alt="Lhotse logo" className="w-28" />
        <div className="flex gap-x-3 items-center">
          {offer?.company?.image && (
            <img
              src={offer?.company?.image}
              alt="Buyer logo"
              className="h-6"
            />
          )}
          {offer?.company?.name
            && (
              <div>
                <p>{offer?.company?.name}</p>
                <p className="text-sm text-gray-400">{t('supplier_view.buyer')}</p>
              </div>
            )}
        </div>
      </div>
      <div className="m-12 bg-white flex-grow flex flex-col rounded-lg border border-gray-200">
        <div className="w-full border-b border-gray-200 px-6 py-4">
          <p className="text-lg">{t('supplier_offer.confidentiality_agreement')}</p>
        </div>
        <div className="p-8 flex-grow">
          {activeNDA
            && (
              <pre className="whitespace-pre-wrap" style={{ fontFamily: 'inherit' }}>
                <TipTapReadOnly text={activeNDA.content} />
              </pre>
            )}
        </div>
        <NDAFormInteractiveElements activeNDA={activeNDA} />
      </div>
    </div>
  )
}

export default NDAForm
