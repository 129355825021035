import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { ActiveWorkflowApprovalActionsType } from '../../../../../types/Workflow/ActiveWorkflow'
import { WorkflowBlock } from '../../../../../types/Workflow/WorkflowBlock'
import useActiveWorkflowMutation from '../../../hooks/useActiveWorkflowMutations'
import useGetCurrentWorkflow from '../../../hooks/useGetCurrentWorkflow'
import ConfirmDeclineModal from '../components/ConfirmDeclineModal'

interface IIntakeSidePanelActiveStepItem {
  block: WorkflowBlock
}
const IntakeSidePanelActiveStepItem: FC<IIntakeSidePanelActiveStepItem> = ({ block }) => {
  const { data } = useGetCurrentWorkflow()
  const { t } = useTranslation()
  const { modal } = useUtils()
  const { update: { mutate } } = useActiveWorkflowMutation()

  const handleWorkflowAction = (action: ActiveWorkflowApprovalActionsType, blockUuid: string, comment?: string) => {
    if (!data?.uuid) return

    mutate({
      active_workflow_uuid: data.uuid,
      action,
      workflow_block_uuid: blockUuid,
      comment,
    })

    modal.close()
  }

  const handleDeclineWorkflowAction = (blockUuid: string) => {
    modal.set({
      isOpen: true,
      content: <ConfirmDeclineModal blockUuid={blockUuid} handleWorkflowAction={handleWorkflowAction} />,
    })
  }

  return (
    <div className="gap-y-8 flex flex-col">
      <div>
        <span className="font-semibold text-sm text-sky-blue-light">{block.name}</span>
      </div>
      <div className="flex gap-x-2 w-full">
        <Button
          className="btn-primary w-full"
          onClick={() => handleWorkflowAction('approved', block.uuid)}
        >
          {t('generous.approve', 'Approve')}
        </Button>
        <Button
          className="bg-red-light w-full text-white"
          onClick={() => handleDeclineWorkflowAction(block.uuid)}
        >
          {t('generous.decline', 'decline')}
        </Button>
      </div>
    </div>
  )
}

export default IntakeSidePanelActiveStepItem
