import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../../core/providers/FormProvider'
import QuestionSection from '../../components/QuestionSection'
import ValuesInput from './ValuesInput'
import { getUpdatedByUuid } from '../../../../../../shared/utils/arrayHelpers'
import { QuestionItemType } from '../../components/QuestionItemsList/QuestionItem'

export const ValuesDefinition = () => {
  const { question: [question], updateQuestion } = useFormContext()
  const { t } = useTranslation()

  if (!question?.__settings?.labels?.predefined && !question?.__settings?.misc?.hidden) return null

  const handleValueUpdate = ({ __uuid, value }: QuestionItemType) => {
    updateQuestion({
      values: getUpdatedByUuid(question.values, [{ __uuid, value }]),
    })
  }

  const handleLabelUpdate = ({ __uuid, value }: QuestionItemType) => {
    updateQuestion({
      values: getUpdatedByUuid(question.values, [{ __uuid, label: value }]),
    })
  }

  return (
    <QuestionSection title={t('forms.question.values')}>
      { question?.__settings?.labels?.predefined && (
      <ValuesInput
        onChange={handleLabelUpdate}
        items={question.values
          .map(({ __uuid, label }) => ({ __uuid, value: label || '' }))}
        title={t('forms.question.labels')}
      />
      ) }
      {question?.__settings?.misc?.hidden && (
      <ValuesInput
        items={question.values
          .map(({ __uuid, value }) => ({ __uuid, value: value as string || '' }))}
        onChange={handleValueUpdate}
        title={t('forms.question.values')}
      />
      )}
    </QuestionSection>
  )
}

export default ValuesDefinition
