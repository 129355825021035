import dayjs from 'dayjs'
import { FC } from 'react'
import Label from '../../../../../../shared/components/atoms/Label'
import Calendar from '../../../../../../shared/components/organisms/Calendar/Calendar'
import { RequisitionDataType } from '../../../../../../types/shared/RequisitionDataTypes'
import useUpdateRequisition from '../../hooks/useUpdateRequisition'

interface IGeneralInformationDateInput {
  isLoading: boolean
  requisition?: RequisitionDataType
  dateField: 'ends_at' | 'expected_delivery_date' | 'questions_due_date'
  label?: string
  disabled?: boolean
}
const GeneralInformationDateInput: FC<IGeneralInformationDateInput> = ({
  requisition, dateField, label, isLoading, disabled,
}) => {
  const { mutate: updateRequisition } = useUpdateRequisition()

  if (isLoading) return <div>Loading...</div>

  return (
    <div className="flex flex-col space-y-2">
      <Label label={label} />
      <Calendar
        disabled={disabled}
        onChange={(date) => updateRequisition({ [dateField]: date })}
        selected={(requisition?.[dateField] && dayjs(requisition?.[dateField]).toDate()) || undefined}
      />
    </div>

  )
}
export default GeneralInformationDateInput
