import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router'

export const useGetUrlSearchParam = (param: string) => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search).get(param), [search])
}

export const useGetUrlParamAsNumber = (param: string) => {
  const params = useParams<{[key: string]: string}>()
  return parseInt(params[param], 10) || undefined
}
