export const germanPriceStringToFloat = (price: string): number => {
  if (typeof price === 'string') {
    return parseFloat(price.replaceAll('.', '')?.replace(',', '.'))
  }
  return 0
}
export const priceToGermanPriceString = (price: number): string => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
export const numberStringToGermanFloat = (price: string): string => {
  const germanPrice = priceToGermanPriceString(germanPriceStringToFloat(price))
  return germanPrice.substring(0, germanPrice.length - 2)
}
