export enum ApprovalStepStatus {'APPROVAL_QUEUED', 'APPROVAL_PENDING', 'APPROVED', 'NOT_REQUIRED', 'REJECTED'}

export type ApproverType = {
  email: string,
  first_name: string,
  full_name: string,
  last_name: string
}

export type ApprovalStepType = {
  uuid: string,
  approval_status: keyof typeof ApprovalStepStatus
  approvers: ApproverType[]
  reason?: string
  approval_user?: ApproverType
}

export enum ApprovalFlowStatus {'APPROVAL_WORKFLOW_RUNNING', 'APPROVAL_WORKFLOW_COMPLETED', 'NO_APPROVAL_REQUIRED'}

export type ApprovalFlowType = {
  status: keyof typeof ApprovalFlowStatus,
  current_user_approval_pending: boolean,
  steps: ApprovalStepType[]
}
