import * as _ from 'lodash'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { apiPost } from '../utils/api/generic'
import * as PERMISSIONS_CONSTANTS from '../../shared/constants/permissions'

type SlugType = typeof PERMISSIONS_CONSTANTS[keyof typeof PERMISSIONS_CONSTANTS]
type PermissionType = {
  slug: SlugType,
  reference_id?: string | number
}

const usePermissions = (requestedPermissions:PermissionType[]) => {
  const { data: permissions, isLoading: isLoadingPermissions } = useQuery(
    ['permissions', ...(_.sortBy(requestedPermissions, ['slug']))],
    () => apiPost('/permissions/check', {
      items: requestedPermissions,
    }),
    { enabled: true },
  )

  const [permissionCache, setPermissionCache] = useState<any[]>(permissions?.items)

  useEffect(() => {
    if (!isLoadingPermissions) {
      setPermissionCache((pc) => _.uniqBy([...permissions?.items || [], ...pc || []], 'slug'))
    }
  }, [isLoadingPermissions])

  const get = (permissionSlug: string, id?: string | number) => {
    const permissionResult = permissionCache?.find((permission: {slug: string, reference_id: string | number }) => (id ? permission.slug === permissionSlug && permission.reference_id === id : permission.slug === permissionSlug))

    return ({
      isLoading: isLoadingPermissions,
      allowed: !isLoadingPermissions && permissionResult ? permissionResult?.allowed : undefined,
    })
  }

  const oneOf = (permissionsToCheck: PermissionType[]) => permissionsToCheck.some(
    (toCheck) => permissionCache?.find((pc: any) => pc.slug === toCheck.slug).allowed,
  ) || false

  return { get, oneOf }
}

export default usePermissions
