import { useQuery } from 'react-query'
import { apiGetData } from '../../core/utils/api/generic'
import { SearchSelectType } from '../../types/Forms'

export type CustomerOptionType = {
  id: number,
  type: string,
  value: string,
}

export const useGetCustomerSearch = (searchType: SearchSelectType | undefined | null) => {
  // TODO: Inconsisten use of searchType. In the form the attribute is called search_type, in the intakeFlow endpoint it is called search_type (CA)
  const isCustomSearch = searchType === 'CUSTOM' || searchType === undefined

  const query = useQuery(['customer-import', searchType], () => apiGetData<CustomerOptionType[]>(`/customer-import?type=${searchType || ''}`), {
    enabled: !!(searchType && !isCustomSearch),
  })

  return {
    ...query, isCustomSearch,
  }
}
