import CoreLoadingIcon from '../../../../../../../../../core/components/CoreLoadingIcon'
import { RequisitionInviteType } from '../../../../../../../../../types/shared/RequisitionDataTypes'
import Checkbox from '../../../../../../../../../shared/components/atoms/inputs/Checkbox'
import { useCoordinateProvider } from '../../../../../providers/CoordinateProvider'
import useHandleCoordinateCheckboxChange from './hooks/useHandleCoordinateCheckboxChange'

interface ISelectSupplierCheckbox {
  supplierInvite: RequisitionInviteType | undefined
}

const SelectSupplierCheckbox: React.FC<ISelectSupplierCheckbox> = ({
  supplierInvite,
}) => {
  const {
    selectedInvites: [selectedInvites],
    loadingInvites: [loadingInvites],
  } = useCoordinateProvider()

  const handleCheckboxChange = useHandleCoordinateCheckboxChange()

  const checked = (!!supplierInvite?.id && supplierInvite?.status !== 'CREATED') || selectedInvites.includes(supplierInvite?.id || 0)
  const isLoading = loadingInvites.includes(supplierInvite?.id || 0)
  const isDisabled = !!supplierInvite?.status && supplierInvite?.status !== 'CREATED'

  if (isLoading) {
    <div>
      <CoreLoadingIcon />
    </div>
  }

  if (isDisabled) return null

  return (
    <Checkbox
      disabled={isDisabled}
      dataCy="coordinate-checkbox"
      checked={checked}
      className="coordinate-supplier-row-checkbox"
      onChange={() => handleCheckboxChange(checked, supplierInvite?.id)}
    />
  )
}

export default SelectSupplierCheckbox
