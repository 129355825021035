import { useMutation } from 'react-query'
import { matchPath } from 'react-router'
import useGetRequisitionId from '../../../../shared/hooks/useGetRequisitionId'
import apiClient from '../../../utils/apiClient'

const useSetRequisitionStatus = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const pathData = matchPath<{ requisitionId: string }>(window.location.pathname, { path: '/requisition/req-:requisitionId' })

  const requisitionId = useGetRequisitionId() || pathData?.params?.requisitionId

  return useMutation(
    (data) => apiClient.put(`/requisitions/${requisitionId}/status`, data),
    { onSuccess: () => setOpen(false) },
  )
}

export default useSetRequisitionStatus
