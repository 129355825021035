// REFACTOR
import { QueryClient } from 'react-query'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'
import { apiPut } from '../../../../../core/utils/api/generic'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import OfferViewQueryKeys from '../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorDataType, ReqDefEditorSectionType } from '../constants/editorTypes'
import {
  deleteComment, postComment, putRowDescription,
} from '../services/editorService'

export const updateItemAttribute = (cache: QueryClient, requisitionId: string, section: ReqDefEditorSectionType, itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>, key: string, value: any) => {
  cache.setQueryData<ReqDefEditorDataType>([OfferViewQueryKeys.SECTIONS_DATA, requisitionId], (
    oldSections: undef<ReqDefEditorDataType>,
  ) => (oldSections
    ? oldSections.map((oldSection) => (
      oldSection.id === section.id
        ? {
          ...oldSection,
          item_attributes: oldSection.item_attributes.map(
            (oldItemAttribute) => (oldItemAttribute.id === itemAttribute.id
              ? {
                ...oldItemAttribute,
                [key]: value,
              }
              : oldItemAttribute),
          ),
        }
        : oldSection
    ))
    : []))
}
export const updateSection = (
  cache: QueryClient,
  requisitionId: string,
  section: ReqDefEditorSectionType,
  key: string,
  value: any,
) => {
  cache.setQueryData<ReqDefEditorDataType>([OfferViewQueryKeys.SECTIONS_DATA, requisitionId], (
    oldSections: undef<ReqDefEditorDataType>,
  ) => (oldSections
    ? oldSections.map((oldSection) => (
      oldSection.id === section.id
        ? {
          ...oldSection,
          [key]: value,
        }
        : oldSection
    ))
    : []))
}

export const replaceItemAttribute = (cache: QueryClient, requisitionId: string, section: ReqDefEditorSectionType, itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>, newItemAttribute: Omit<LineItemItemAttributeType, 'line_items'>) => {
  cache.setQueryData<ReqDefEditorDataType>([OfferViewQueryKeys.SECTIONS_DATA, requisitionId], (
    oldSections: undef<ReqDefEditorDataType>,
  ) => (oldSections
    ? oldSections.map((oldSection) => (
      oldSection.id === section.id
        ? {
          ...oldSection,
          item_attributes: oldSection.item_attributes.map(
            (oldItemAttribute) => (oldItemAttribute.id === itemAttribute.id
              ? newItemAttribute
              : oldItemAttribute),
          ),
        }
        : oldSection
    ))
    : []))
}

export const updateSectionsData = (
  cache: QueryClient,
  requisitionId: string,
  newSections: ReqDefEditorSectionType[],
) => {
  cache.setQueryData<ReqDefEditorDataType>(
    [OfferViewQueryKeys.SECTIONS_DATA, requisitionId],
    () => newSections,
  )
}

export const saveDataOnBlur = async (itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>, updateAutoSave: () => void): Promise<any> => {
  try {
    await putRowDescription(itemAttribute.id, itemAttribute?.description)
    updateAutoSave()
  } catch {
    toastr.error()
  }
}

export const handleToggleComment = async (
  cache: QueryClient,
  requisitionId: string,
  section: ReqDefEditorSectionType,
  updateAutoSave: () => void,
  itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>,
) => {
  const originalComment = itemAttribute.comment

  try {
    if (!originalComment) {
      const data = await postComment(itemAttribute.id, 'ItemAttribute')
      updateItemAttribute(cache, requisitionId, section, itemAttribute, 'comment', data.data)
      updateAutoSave()
    } else {
      await deleteComment(originalComment.id as number)
      updateItemAttribute(cache, requisitionId, section, itemAttribute, 'comment', null)
      updateAutoSave()
    }
  } catch {
    toastr.error()
  }
}

export const handleToggleButton = async (
  buttonType: 'is_sum' | 'is_required',
  itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>,
  defaultButtonStates: any,
  cache: QueryClient,
  requisitionId: string,
  section: ReqDefEditorSectionType,
  updateAutoSave: () => void,
) => {
  try {
    const currentValue = itemAttribute[buttonType] !== undefined
      ? itemAttribute[buttonType]
      : defaultButtonStates[buttonType]

    updateItemAttribute(
      cache,
      requisitionId,
      section,
      itemAttribute,
      buttonType,
      currentValue ? 0 : 1,
    )

    await apiPut(`/item-attributes/${itemAttribute.id}`, { [buttonType]: !currentValue })
    updateAutoSave()
  } catch {
    toastr.error()
  }
}
