import { useMutation, useQueryClient } from 'react-query'
import { useMessengerContext } from '../../../core/providers/MessengerProvider'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import useGetToken from '../../SupplierOffer/hooks/useGetToken'
import { emptyMessageReply } from '../constants'
import messengerQueryKeys from '../messengerQueryKeys'
import { apiPostChatMessage } from '../service'
import { MessageType } from '../../../types/Messenger/types'
import { scrollToBottomOfChat } from '../utils'
import useGetAndSetActiveMessage from './useGetAndSetActiveMessage'
import useGetChats from './useGetChats'
import useGetMessagePayload from './useGetMessagePayload'
import useGetMessengerUrlParams from './useGetMessengerUrlParams'

export default () => {
  const { messageProperties: [, setMessageProperties] } = useMessengerContext()
  const { modelType, modelId, requisitionId } = useGetMessengerUrlParams()
  const [, setMessageContent] = useGetAndSetActiveMessage()
  const cache = useQueryClient()
  const token = useGetToken()
  const { refetch } = useGetChats()
  const getMessagePayload = useGetMessagePayload()

  return useMutation(
    async () => apiPostChatMessage(await getMessagePayload(), token || undefined),
    {
      onMutate: () => {
        setMessageContent('')
        setMessageProperties((messageProperties) => ({
          ...messageProperties,
          messageReply: emptyMessageReply,
          files: [],
        }))
      },
      onSuccess: (newData: MessageType) => {
        const QUERY_KEY = messengerQueryKeys.messages(modelType, Number(modelId))
        cache.setQueryData(QUERY_KEY, (oldData: MessageType[] | undefined) => (
          oldData
            ? [...oldData, newData]
            : [newData]
        ))

        if (requisitionId) refetch()

        scrollToBottomOfChat()
      },
      onError: () => { toastr.error('error.item_not_sent', 'errorSherpaMessageSend') },
    },
  )
}
