import { InputSearchSelectItemType } from '../../../../../../../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import {
  FormQuestionType,
  FormSectionType,
} from '../../../../../../../../types/Forms/formTypes'
import useGetAllQuestions from '../../../../../../hooks/useGetAllQuestions'
import useGetQuestionIndex from '../../../../../../hooks/useGetQuestionIndex'

interface IFormLogicSourceSelector {
  question: FormQuestionType
  section: FormSectionType
  classes?: string
  onChange: (value: InputSearchSelectItemType) => void
  selected?: string | null // uuid
}

const FormLogicSourceSelector: React.FC<IFormLogicSourceSelector> = ({
  classes = '', onChange, selected, question, section,
}) => {
  const allQuestions = useGetAllQuestions()
  const questionIndex = useGetQuestionIndex(question)

  const followingQuestions = allQuestions
    ?.slice(0, questionIndex)
    ?.map((followingQuestion) => ({
      label: followingQuestion.label || '',
      id: followingQuestion.__uuid,
    }))

  const currentSelection = followingQuestions.find(({ id }) => id === selected) || { id: 0, label: '' }

  return (
    <div className={`flex  gap-x-4 items-center ${classes}`}>

      <div className="w-96 flex-grow relative">
        <SearchSelect
          className="max-w-xl text-sm"
          disabled={!section.actions.edit}
          options={followingQuestions}
          onChange={onChange}
          currentSelection={currentSelection}
        />
      </div>
    </div>
  )
}

export default FormLogicSourceSelector
