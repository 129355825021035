import MessageInput from './MessageInput'
import NewMessageReplyPreview from './NewMessageReplyPreview'

const IntakeMessageEditor: React.FC = () => (
  <div
    className="flex flex-col h-full justify-end"
  >
    <NewMessageReplyPreview />
    <MessageInput />
  </div>
)

export default IntakeMessageEditor
