import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import { priceToGermanPriceString } from '../../../../../../../shared/constants/currencyFormat'
import { defaultPriceString } from './constants'

interface IGrossPrice {
  invite: RequisitionInviteType
}

const GrossPrice: React.FC<IGrossPrice> = ({ invite }) => {
  const { t } = useTranslation()

  // here is where the bug is
  const grossPrice = invite?.calculated_price
    ? priceToGermanPriceString(invite?.calculated_price?.gross)
    : defaultPriceString

  return (
    <div className="flex justify-between items-center text-sm">
      <p>{t('req_award.gross_price')}</p>
      <p className="text-sm font-semibold">{grossPrice}</p>
    </div>
  )
}

export default GrossPrice
