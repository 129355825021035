import { useMutation, useQueryClient } from 'react-query'
import {
  deleteWorkflow, postWorkflow, publishWorkflow, putWorkflow,
} from '../services'
import { Workflow } from '../../../types/Workflow/Workflow'

export const useWorkflowMutations = () => {
  const queryClient = useQueryClient()

  const invalidate = (data: any, errors: any, variables: unknown) => {
    queryClient.invalidateQueries(['workflows'])

    const { uuid, version } = (variables || {}) as {uuid: string, version: number}
    if (uuid || version) {
      queryClient.invalidateQueries(['workflow', uuid, version].filter((key) => !!key))
    }
  }

  const create = useMutation(['createWorkflow'], (data: Partial<Workflow> & Pick<Workflow, 'name'>) => postWorkflow(data), {
    onSettled: invalidate,
  })

  const remove = useMutation(['deleteWorkflow'], ({ uuid, version }: Partial<Workflow> & Pick<Workflow, 'uuid' | 'version'>) => deleteWorkflow(uuid, version), {
    onSettled: invalidate,
  })

  const update = useMutation(['updateWorkflow'], (data: Partial<Workflow> & Pick<Workflow, 'uuid'>) => putWorkflow(data.uuid, data), {
    onSettled: invalidate,
  })

  const publish = useMutation(['publishWorkflow'], ({ uuid, version }: Partial<Workflow> & Pick<Workflow, 'uuid' | 'version'>) => publishWorkflow(uuid, version), {
    onSettled: invalidate,
  })

  return {
    create: create.mutate,
    remove: remove.mutate,
    update: update.mutate,
    publish: publish.mutate,
  }
}

export default useWorkflowMutations
