import {
  createContext, FC, useContext, useMemo, useState,
} from 'react'
import { INodeChildren } from '../../types/shared/GeneralTypes'

const localStorageContext = createContext<((key: string, initialValue?: string | object) => any)[]>([])

export function useLocalStorage() {
  return useContext(localStorageContext)
}

const safeJSONParse = (value: string | undefined | null) => {
  if (!value) return undefined

  try {
    return JSON.parse(value)
  } catch (e) {
    return undefined
  }
}

const LocalStorageProvider: FC<INodeChildren> = ({ children }) => {
  const [storedValue, setStoredValue] = useState(window.localStorage)

  const getLocalStorage = (key: string, initialValue: string | object = '') => {
    const stringifiedInitialValue = typeof initialValue === 'string' ? initialValue : JSON.stringify(initialValue)

    if (Object.prototype.hasOwnProperty.call(storedValue, key)) {
      const value = storedValue[key] ?? ''
      if (value === '') return undefined

      return safeJSONParse(value)
    }

    const localStoredValue = window.localStorage.getItem(key)

    if (localStoredValue) {
      setStoredValue((obj) => ({ ...obj, [key]: localStoredValue }))
      return safeJSONParse(localStoredValue)
    }

    setStoredValue((obj) => ({ ...obj, [key]: stringifiedInitialValue }))
    window.localStorage.setItem(key, stringifiedInitialValue)

    return safeJSONParse(stringifiedInitialValue)
  }

  const setValue = (key: string, value: string | object | undefined) => {
    const valueToStore = typeof value === 'string' ? value : JSON.stringify(value)
    setStoredValue((obj) => ({ ...obj, [key]: valueToStore }))
    window.localStorage.setItem(key, valueToStore)
    return valueToStore
  }

  const values = useMemo(() => [getLocalStorage, setValue], [getLocalStorage, setValue])

  return (
    <localStorageContext.Provider value={values}>
      {children}
    </localStorageContext.Provider>
  )
}

export default LocalStorageProvider
