import { useCoordinateProvider } from '../providers/CoordinateProvider'
import useDeleteSupplierInvite from './useDeleteSupplierInvite'

export default () => {
  const { selectedInvites: [selectedInvites] } = useCoordinateProvider()

  const deleteSupplierInvite = useDeleteSupplierInvite()

  return () => {
    selectedInvites.forEach(async (supplierInviteId) => {
      deleteSupplierInvite(supplierInviteId)
    })
  }
}
