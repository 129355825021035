import { useTranslation } from 'react-i18next'
import { OffersOfferType } from '../../../../../../../types/shared/OfferDataTypes'
import { priceToGermanPriceString } from '../../../../../../../shared/constants/currencyFormat'
import { defaultPriceString } from './constants'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'

interface IShippingCosts {
  invite: RequisitionInviteType
}

const ShippingCosts: React.FC<IShippingCosts> = ({
  invite,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex justify-between items-center">
      <p>{t('requisition_offers.list_offer_shipping_costs')}</p>
      <p>{priceToGermanPriceString(invite.calculated_price.shipping_net)}</p>
    </div>
  )
}

export default ShippingCosts
