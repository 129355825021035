// REFACTOR
import { AssignmentType } from '../../../../types/shared/AssignmentTypes'
import UserAvatar from '../../atoms/UserAvatar/UserAvatar'

interface IAssigneePopoverButton {
  localAssignments: AssignmentType[] | undefined
}

const AssigneePopoverButton: React.FC<IAssigneePopoverButton> = ({
  localAssignments,
}) => {
  const totalAssignments = localAssignments?.length || 0

  return (
    <div className="flex items-center justify-end">
      <div
        className="flex justify-center items-center relative font-light text-xs h-[22px] min-w-[22px] rounded-full cursor-pointer ring-1 ring-white bg-gray-50 text-gray-400 border-gray-200 border hover:bg-gray-300 "
      >
        <p className="leading-snug pb-px">+</p>
      </div>
      <div className="flex flex-row w-full">
        {localAssignments?.slice(0, 2).map((assignee) => (
          <UserAvatar className="relative -ml-[3px] -mr-[3px]" key={`${assignee.id}-assignment`} name={assignee?.user.email} />
        ))}
        {totalAssignments > 2 && (
          <span className="flex items-center text-xs text-gray-500 mr-2 whitespace-nowrap ml-1">
            {`+ ${totalAssignments - 2}`}
          </span>
        )}
      </div>
    </div>
  )
}

export default AssigneePopoverButton
