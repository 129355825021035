import { useTranslation } from 'react-i18next'
import AcceptanceStateType from '../../../../../../../types/Requisition/AcceptanceStateType'

const SingleReqStepper: React.FC<{ step: AcceptanceStateType }> = ({ step }) => {
  const { t } = useTranslation()
  const currentStep = {
    name: '',
    status: step,
  }

  let ndaStyles

  switch (currentStep.status) {
    case 'CREATED': currentStep.name = 'requisition_suppliers.selected' // 'error.email_failed_to_send'
      break
    case 'INVITED': currentStep.name = 'requistition_suppliers.invited'
      break
    case 'RFP_VIEWED':
      currentStep.name = 'req_coordinate.req_opened'
      ndaStyles = 'border-orange-500'
      break
    case 'OFFER_CHANGES_REQUESTED':
      currentStep.name = 'generic.changes_requested'
      break
    case 'NDA_ACCEPTED':
      currentStep.name = 'NDA_ACCEPTED'
      ndaStyles = 'border-orange-500'
      break
    case 'OFFER_IN_EDITING':
      currentStep.name = 'generic.offer_being_edited'
      ndaStyles = 'border-orange-500'
      break
    case 'OFFER_SUBMITTED': currentStep.name = 'OFFER_SUBMITTED'
      break
    case 'OFFER_ACCEPTED': currentStep.name = 'OFFER_ACCEPTED'
      break
    case 'OFFER_DECLINED_BY_CUSTOMER':
      currentStep.name = 'requisition_offers.badge_declined'
      break
    case 'OFFER_DECLINED_BY_SUPPLIER':
    case 'DECLINED_TO_OFFER':
      currentStep.name = 'DECLINED_TO_OFFER'
      break
    default: currentStep.name = 'generic.not_invited'
  }

  const stepStyles = 'w-1/4 h-2 border-b-2'
  let stepColour

  if (currentStep.status === 'OFFER_DECLINED_BY_CUSTOMER' || currentStep.status === 'DECLINED_TO_OFFER' || currentStep.status === 'OFFER_DECLINED_BY_SUPPLIER') {
    stepColour = 'text-red-600'
  } else if (currentStep.status === 'OFFER_CHANGES_REQUESTED') {
    stepColour = 'text-orange-600'
  } else if (currentStep.status === 'CREATED' || currentStep.status === 'INVITED') {
    stepColour = 'text-sky-blue-light'
  } else if (currentStep.status === 'OFFER_SUBMITTED' || currentStep.status === 'OFFER_ACCEPTED') {
    stepColour = 'text-green-500'
  }

  return (
    <div className="flex flex-col w-full">
      <div className={`text-sm ${stepColour} list-item list-disc `}>
        {t(currentStep.name)}
      </div>
      {/* <div className="flex gap-3"> */}
      {/*  {currentStep.status === 'CREATED' && <div className={`${stepStyles} ${currentStep.status === 'CREATED' ? 'border-red-600' : 'border-gray-300'}`} />} */}
      {/*  {currentStep.status !== 'CREATED' && <div className={`${stepStyles} ${currentStep.status === 'INVITED' ? 'border-blue-600' : 'border-gray-300'}`} />} */}
      {/*  <div className={`${stepStyles} ${ndaStyles || 'border-gray-300'}`} /> */}
      {/*  {currentStep.status === 'OFFER_CHANGES_REQUESTED' */}
      {/*    ? <div className={`${stepStyles} border-orange-600`} /> */}
      {/*    : <div className={`${stepStyles} ${currentStep.status === 'OFFER_SUBMITTED' ? 'border-green-500' : 'border-gray-300'}`} />} */}
      {/*  {['OFFER_DECLINED_BY_CUSTOMER', 'DECLINED_TO_OFFER', 'OFFER_DECLINED_BY_SUPPLIER'].includes(currentStep.status || '') */}
      {/*    ? <div className={`${stepStyles} border-red-600`} /> */}
      {/*    : <div className={`${stepStyles} ${currentStep.status === 'OFFER_ACCEPTED' ? 'border-green-500' : 'border-gray-300'}`} />} */}
      {/* </div> */}
    </div>
  )
}

export default SingleReqStepper
