import { MixedSchema, Reference } from 'yup'

/**
 * @param {string | number | array | Reference}validationValue
 * @param {MixedSchema} schema
 * @returns {FormQuestionType | undefined}
 */
export const getReferencedQuestion = (validationValue, schema) => (
  validationValue.isValue === false ? schema.resolve(validationValue) : undefined
)

export default getReferencedQuestion
