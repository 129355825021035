import GeneralInformation from './components/GeneralInformation/GeneralInformation'
import ProductDetails from './components/ProductDetails/ProductDetails'
import './requisition-definition.css'

const RequisitionDefinitionPage = () => (

  <div className="flex flex-col space-y-12 max-w-[1300px] w-full pb-24" id="requisition-definition">
    <GeneralInformation />
    <ProductDetails />
  </div>
)

export default RequisitionDefinitionPage
