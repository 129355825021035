import { downloadFileFromURL, legacyDownloadFileFromURL } from '../../../../core/utils/fileUploadUtils'
import SharedFileUploadPillContent from './SharedFileUploadPillContent'
import SharedFileUploadPillDeleteButton from './SharedFileUploadPillDeleteButton'
import { RemoveUploadedFileFunctionType, UploadedFileType } from './SharedFileUploadPillTypes'
import { getToken } from '../../../../core/utils/apiClient'

interface ISharedFileUploadPill {
  file: { name: string, url?: string, uuid?: string, type?: string }
  removeFile?: RemoveUploadedFileFunctionType
  downloadFile?: RemoveUploadedFileFunctionType
  isUploading?: boolean
  className?: string
  iconClassName?: string
}

const SharedFileUploadPill: React.FC<ISharedFileUploadPill> = ({
  file, downloadFile, removeFile, isUploading, className, iconClassName,
}) => {
  if (!file.name) return null

  const isAuthenticated = getToken()

  const handleRemoval = removeFile && ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    removeFile(e, file as UploadedFileType)
  })

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!file.url) return

    if (downloadFile) downloadFile(e, file as UploadedFileType)
    else if (isAuthenticated && file.url.includes('signed_url')) downloadFileFromURL(file.url, file.name)
    else legacyDownloadFileFromURL(file.url, file.name)
  }

  return (
    <button
      className={`items-center px-2 py-1 text-xs bg-white hover:bg-gray-100 w-fit max-w-[14rem] flex gap-x-2 group rounded-xl border-lhotse-orange border ${className}`}
      data-cy="uploaded-file-pill"
      onClick={handleDownload}
      type="button"
    >
      <SharedFileUploadPillContent
        file={file}
        isUploading={isUploading}
        iconClassName={iconClassName}
      />
      {file.url && (
        <SharedFileUploadPillDeleteButton
          removeFile={handleRemoval}
          file={file as UploadedFileType}
        />
      )}
    </button>

  )
}

export default SharedFileUploadPill
