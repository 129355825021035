import { useMutation, useQueryClient } from 'react-query'
import { putAttribute } from '../services/attributesService'
import keys from '../../Award/constants/OfferViewQueryKeys'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'

const usePutAttribute = () => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()
  const queryKey = [keys.SECTIONS_DATA, requisitionId]

  const queryFunction = (
    itemAttribute: Omit<LineItemItemAttributeType, 'line_items'>,
  ) => putAttribute(itemAttribute.id, itemAttribute)

  return useMutation(queryFunction, {
    onError: () => {
      toastr.error('error.item_not_updated', 'errorAttributeUpdate')
    },

    onSuccess: () => {
      cache.refetchQueries(queryKey)
    },

  })
}

export default usePutAttribute
