import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

type MediaUploadButtonProps = {
  onUpload: (files: File[]) => void
}

const MediaUploadButton: React.FC<React.PropsWithChildren<MediaUploadButtonProps>> = ({ onUpload, children }) => {
  const { t } = useTranslation()
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onUpload, noDrag: true })

  return (
    <div className="flex">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {children || (
        <button
          type="button"
          className="flex gap-x-2 items-center text-primary-gray text-xs"
        >

          <p>+</p>
          <p className="underline">{t('attachments.upload')}</p>
        </button>
        )}
      </div>
    </div>
  )
}

export default MediaUploadButton
