import { useFormContext } from '../../../../core/providers/FormProvider'
import FormSectionsNavigationHeader from '../FormSectionsNavigationHeader/FormSectionsNavigationHeader'
import FormSectionsNavigationButtons from '../SectionNavigationButtons'
import { useFormSection } from '../providers/FormSectionProvider'
import ConfirmationSection from './ConfirmationSection/ConfirmationSection'
import PlainFormSection from './PlainFormSection'
import ProductSection from './ProductSection/ProductSection'

export const FormResponsePageSection = () => {
  const { responseConfig: [responseConfig] } = useFormContext()
  const { currentSection } = useFormSection()

  if (!currentSection) return null

  return (
    <div className="flex flex-col justify-center" id="form-container">
      <div className="w-full max-w-3xl mx-auto pt-8 pb-20">

        {['GENERAL_INFO', 'OFFER', 'GENERAL'].includes(currentSection?.type) && <PlainFormSection />}
        {(currentSection?.type === 'FINAL' || responseConfig.activeSectionUUID === 'Confirmation') && <ConfirmationSection />}
        {(currentSection?.type === 'PRODUCT') && <ProductSection />}
      </div>
      <FormSectionsNavigationButtons />
    </div>
  )
}

export default FormResponsePageSection
