import { useLocation } from 'react-router'
import useOpenSupplierDetailsModal from '../../../../modules/SupplierDetails/hooks/useOpenSupplierDetailsModal'
import useOpenSupplierDetailsPage from '../../../../modules/SupplierDetails/hooks/useOpenSupplierDetailsPage'
import { useSuppliersProvider } from '../../../../modules/Suppliers/providers/SuppliersProvider'
import { TagType } from '../../../../types/shared/GeneralTypes'
import Badge from '../../atoms/tags/Badge'

interface IUnshownTagCount {
  uniqueTags: TagType[]
  supplierBranchId: number
}

const UnshownTagCount: React.FC<IUnshownTagCount> = ({
  uniqueTags, supplierBranchId,
}) => {
  const unshownTags = uniqueTags.length - 3
  const location = useLocation()
  const openDetailsPage = useOpenSupplierDetailsPage(supplierBranchId, 'tags')
  const openDetailsModal = useOpenSupplierDetailsModal(supplierBranchId)
  const { activeSupplierModalTab: [, setSupplierTab] } = useSuppliersProvider()

  const openSupplierDetailsTags = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (location.pathname.includes('/execute')) {
      openDetailsModal()
      setSupplierTab('tags')
    } else {
      openDetailsPage()
    }
  }

  if (unshownTags < 1) return null

  return (
    <button
      type="button"
      onClick={openSupplierDetailsTags}
    >
      <Badge className="hover:bg-sky-blue-light hover:text-white transition-colors">
        {`+${unshownTags}`}
      </Badge>
    </button>
  )
}

export default UnshownTagCount
