import { apiPut, apiDelete } from '../../../../../core/utils/api/generic'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import { CustomerDocumentType } from '../../../../../types/SupplierOffer/SupplierOfferDataTypes'
import formatCustomerDocuments from '../utils/formatCustomDocuments'
import useUpdateRequisitionDocuments from '../components/ReqDefContainer/DocumentsSection/Hooks/useUpdateRequisitionDocuments'

export default () => {
  const updateReqDocsState = useUpdateRequisitionDocuments()
  const requisitionId = useGetRequisitionId()

  return async (document: CustomerDocumentType) => {
    try {
      if (!document.attached) {
        const { data: newCustomerDocuments } = await apiPut(`/requisitions/${requisitionId}/document/attach/${document.id}`, { required: true })
        updateReqDocsState(formatCustomerDocuments(newCustomerDocuments))
      } else {
        const { data: newCustomerDocuments } = await apiDelete(`/requisitions/${requisitionId}/document/detach/${document.id}`)
        updateReqDocsState(formatCustomerDocuments(newCustomerDocuments))
      }
    } catch {
      toastr.error()
    }
  }
}
