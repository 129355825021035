import Avatar from 'react-avatar'
import coreColors from '../../../constants/coreColors'

interface IUserAvatar {
  className?: string
  color?: string
  name: string
  size?: string
  textSizeRatio?: number
}

const UserAvatar: React.FC<IUserAvatar> = ({
  name, className = '', color = '', size = '22', textSizeRatio = 2.4,
}) => (
  <Avatar
    name={name}
    round
    size={size}
    textSizeRatio={textSizeRatio}
    className={className}
    color={color || coreColors['sky-blue-light']}
  />
)

export default UserAvatar
