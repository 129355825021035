import { useQuery } from 'react-query'
import { getIntakeChangeLog } from '../services'
import { IntakeChangeLogItemType } from '../../../types/Intake'
import { Query } from '../../../types/shared/Api'

export type IntakeSummaryQuery = Query<IntakeChangeLogItemType[]>

export const useGetIntakeChangeLog = (id: string): IntakeSummaryQuery => useQuery(['intake', id, 'logs'], () => getIntakeChangeLog(id))

export default useGetIntakeChangeLog
