import { AiOutlineSync } from 'react-icons/ai'
import { MdInfo, MdThumbUp, MdWarning } from 'react-icons/md'
import activeMarkInfo from '../../../../core/assets/active-item-markInfo.svg'
import activeMarkSuccess from '../../../../core/assets/active-item-markSuccess.svg'
import activeMarkRed from '../../../../core/assets/active-item-markRed.svg'
import activeMarkWarning from '../../../../core/assets/active-item-markWarning.svg'

import CoreContainerLayout from '../../../../core/components/layout/CoreContainerLayout'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'
import LabelInfo from '../../atoms/texts/LabelInfo'

interface IWarning extends INodeChildren {
  title?: string
  severity: 'success' | 'danger' | 'info' | 'warning'
  className?: string
  showSpinner?: boolean
}

const Warning: React.FC<IWarning> = ({
  title, children, severity, className, showSpinner,
}) => {
  const colorScheme = {
    success: {
      icon: MdThumbUp,
      backgroundColor: 'bg-green-50',
      textColor: 'green-600',
      spinning: false,
      image: activeMarkSuccess,
    },
    danger: {
      icon: MdWarning,
      backgroundColor: 'bg-red-50',
      textColor: 'red-600',
      spinning: false,
      image: activeMarkRed,
    },
    info: {
      icon: MdInfo,
      backgroundColor: 'bg-blue-50',
      borderColor: 'border-blue-500',
      textColor: 'text-blue-500',
      spinning: false,
      image: activeMarkInfo,
    },
    warning: {
      icon: showSpinner ? AiOutlineSync : MdWarning,
      backgroundColor: 'bg-white',
      borderColor: 'border-yellow-400',
      textColor: 'text-yellow-400',
      spinning: !!showSpinner,
      image: activeMarkWarning,
    },
  }

  return (
    <CoreContainerLayout
      className={`
        relative
        flex flex-col justify-center ${className}
        shadow-sm
        ${colorScheme[severity].backgroundColor}        
      `}
      border={`border ${colorScheme[severity].textColor}`}
    >
      <img
        src={colorScheme[severity].image}
        alt=""
        className="absolute l-0 h-full rotate-180"
      />
      <div className={`flex items-center p-4 gap-4 px-6 ${colorScheme[severity].textColor}`}>
        {colorScheme[severity].icon({ className: `text-3xl ${colorScheme[severity].spinning ? 'animate-spin' : null}` })}
        <LabelInfo label={title} inverted>
          {children}
        </LabelInfo>
      </div>
    </CoreContainerLayout>
  )
}

export default Warning
