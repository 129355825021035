import { useTranslation } from 'react-i18next'
import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import { priceToGermanPriceString } from '../../../../../../../shared/constants/currencyFormat'
import { defaultPriceString } from './constants'

interface INetPrice {
  invite: RequisitionInviteType
}

const NetPrice: React.FC<INetPrice> = ({ invite }) => {
  const { t } = useTranslation()

  const netPrice = invite?.calculated_price
    ? priceToGermanPriceString(invite?.calculated_price?.net)
    : defaultPriceString

  return (
    <div className="flex justify-between items-center">
      <p className="w-max whitespace-nowrap">{t('req_award.net_price')}</p>
      <p className="text-xl font-semibold">{netPrice}</p>
    </div>
  )
}

export default NetPrice
