import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import ActionButtonRow from './ButtonRow/ActionButtonRow'
import Prices from './Prices/Prices'
import { getIsWaiting } from './utils'

interface IExpandedInfo {
  invite: RequisitionInviteType
  pricesExpanded: boolean
  useLastCallState: [
    number[] | undefined,
    React.Dispatch<React.SetStateAction<number[] | undefined>>
  ]
  isPurchased: boolean
}

const ExpandedInfo: React.FC<IExpandedInfo> = ({
  invite, pricesExpanded, useLastCallState, isPurchased,
}) => {
  const currentStatus = invite?.status
  const isWaiting = getIsWaiting(invite)

  return pricesExpanded
    ? (
      <>
        <Prices invite={invite} currentStatus={currentStatus} />
        <ActionButtonRow
          invite={invite}
          isDisabled={isWaiting || isPurchased}
          useLastCallState={useLastCallState}
        />
      </>
    )
    : null
}

export default ExpandedInfo
