import { useMutation, useQueryClient } from 'react-query'
import { Offer } from '../../../types/Offering'
import useIntakeId from '../../Intake/hooks/useIntakeId'
import { postOffer } from '../../Offerings/services'
import { RequireAtLeastOne } from '../../../types/shared/UtilityTypes'

export const useCreateOffer = () => {
  const intakeFlowUuid = useIntakeId()
  const client = useQueryClient()

  return useMutation((newOffer: Partial<Omit<Offer, 'uuid'>> & RequireAtLeastOne<{reference_key: string, offering_uuid: string}>) => postOffer({
    ...newOffer,
    intake_flow_uuid: intakeFlowUuid,
  }), {
    onSettled: ({ data: newOffer }) => {
      client.refetchQueries(['offering-offers', newOffer.reference_key])
      client.refetchQueries(['intake-offerings', intakeFlowUuid, 'paginated', 1])
    },
  })
}

export default useCreateOffer
