import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../../core/providers/UtilsProvider'
import ConfirmationModal from '../../../../../../../shared/components/organisms/modals/ConfirmationModal'
import useSendBulkInvite from './useSendBulkInvite'

const useHandleOpenInviteModal = () => {
  const utils = useUtils()
  const sendBulkInvites = useSendBulkInvite()
  const { t } = useTranslation()

  return () => {
    utils.modal.set({
      isOpen: true,
      title: t('req_coordinate.bulk_invite'),
      content: (
        <ConfirmationModal onConfirm={sendBulkInvites}>
          {t('req_coordinate._are_you_sure_bulk_invite')}
        </ConfirmationModal>),
    })
  }
}

export default useHandleOpenInviteModal
