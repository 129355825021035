import { useHistory, useRouteMatch } from 'react-router'
import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'
import { useFormContext } from '../../../core/providers/FormProvider'
import FormNotSupported from '../components/FormNotSupported'
import { isSupported } from '../constants/FormVersion'
import useGetFormData from '../hooks/useGetFormData'
import Header from './Header/Header'
import QuestionNavigator from './QuestionNavigator/QuestionNavigator'
import useCheckForSubmission from './hooks/useCheckForSubmission'
import useGetFormSubmission from './hooks/useGetFormSubmission'
import useLoadState from './hooks/useLoadState'
import FormDataProvider from './providers/FormDataProvider'
import FormLogicProvider from './providers/FormLogicProvider'
import FormSectionProvider from './providers/FormSectionProvider'
// import './sections/ConfirmationSection/advario-confirmation.css'
import FormResponsePageSection from './sections/FormResponsePageSection'
import FormSectionsNavigationHeader from './FormSectionsNavigationHeader/FormSectionsNavigationHeader'

const FormResponsePage: React.FC = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { isLoading: isLoadingSubmissionCheck } = useCheckForSubmission()
  const { isLoading: isLoadingFormData, data: form } = useGetFormData()
  const { isLoading: isLoadingSubmission, data: submissionData } = useGetFormSubmission()
  const { responseConfig: [responseConfig], submission: [submission] } = useFormContext()

  useLoadState()

  const isLoading = isLoadingSubmission || isLoadingSubmissionCheck || isLoadingFormData

  if (isLoading) {
    return (
      <div className="w-full h-full bg-white">
        <CoreLoadingIcon />
      </div>
    )
  }

  // Advario specific redirect for SoftSubmit
  if (submissionData?.assignments && submissionData?.assignments?.length > 0) {
    history.replace(`${url}/confirmation`)
  }

  return (
    <FormDataProvider form={submission}>
      <FormLogicProvider>
        <div className="flex flex-col w-full form-sections bg-[#FAFAFA]">
          {/* <Header /> */}
          <FormSectionProvider>
            <FormSectionsNavigationHeader />
            <div className="w-full max-w-screen justify-center bg-none relative pb-24">
              {/* <QuestionNavigator /> */}
              {isSupported(form)
                ? (
                  <FormResponsePageSection />
                ) : <FormNotSupported />}
            </div>
          </FormSectionProvider>

        </div>

      </FormLogicProvider>
    </FormDataProvider>

  )
}

export default FormResponsePage
