import { useTranslation } from 'react-i18next'
import { MdOutlineLoop } from 'react-icons/md'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import SectionWrapper from '../../../../../shared/components/organisms/SectionWrapper'
import { EvaluatedFormSectionType } from '../../../../../types/Forms'
import useFormValidation from '../../hooks/useFormValidation'
import useGetQuestionIndex from '../../hooks/useGetQuestionIndex'
import { FormSectionEvents } from '../../providers/FormSectionProvider'
import ConfirmationQuestionComponent from './ConfirmationQuestionComponent'

interface IConfirmationSectionsSection {
  section: EvaluatedFormSectionType
}
const ConfirmationSectionsSection: React.FC<IConfirmationSectionsSection> = ({ section }) => {
  const { t } = useTranslation()
  const { invalidQuestions } = useFormValidation()
  const getQuestionIndex = useGetQuestionIndex()

  return (

    <SectionWrapper
      className="pb-8"
      key={section.__uuid}
      title={(
        <div className="flex space-x-2 items-center">
          <span>{section.name || t('form.line_item')}</span>
          {' '}
          <span>{section.loop?.__looped && <MdOutlineLoop />}</span>
        </div>
      )}
      actions={(
        <div>
          <Button
            className="btn btn-ghost text-sky-blue-light "
            onClick={() => {
              FormSectionEvents.ProceedTo.dispatch(section.__uuid)
            }}
          >
            {t('forms.proceed_to_section', 'Jump to section')}
          </Button>
        </div>
      )}
    >
      {section.enabledFields
        .filter((question) => !question.isFormElement)
        .map(
          (q) => (
            <ConfirmationQuestionComponent
              key={q.__uuid}
              questionIndex={getQuestionIndex(q) + 1}
              invalidQuestions={invalidQuestions.map(({ __uuid }) => __uuid)}
              question={q}
            />
          ),
        )}
    </SectionWrapper>

  )
}

export default ConfirmationSectionsSection
