import Badge, { BadgeProps } from '../../../../../../shared/components/atoms/tags/Badge'
import { RequisitionFilterType, RequisitionFilterValueType } from '../types'
import { useRequisitionFilters } from '../provider/RequisitionFiltersProvider'

type RequisitionFilterBadgeProps = {
  filter: RequisitionFilterType,
  value: RequisitionFilterValueType
} & BadgeProps

const RequisitionFilterBadge = ({
  children, filter, value, ...badgeProps
}: React.PropsWithChildren<RequisitionFilterBadgeProps>) => {
  const { removeFilterValue } = useRequisitionFilters()

  return (
    <div className="w-fit cursor-pointer">
      <Badge
        txtColor="white"
        txtSize="sx"
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
        rounded="rounded"
        bgColor="sky-blue-light"
        onRemove={() => removeFilterValue(filter, value)}
        {...badgeProps}
      >
        {children}
      </Badge>
    </div>
  )
}
export default RequisitionFilterBadge
