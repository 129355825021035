import { useTranslation } from 'react-i18next'
import Table from '../../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../shared/components/atoms/SharedTable/TableHeader'
import useGetOffers from '../../../../hooks/useGetOffers'
import ProductSectionOffersTableRow from './ProductSectionOffersTableRow'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'

type IProductSectionOffersTable = {
  offerReferenceKey: string
}

const ProductSectionOffersTable: React.FC<IProductSectionOffersTable> = ({ offerReferenceKey }) => {
  const { t } = useTranslation()
  const { data } = useGetOffers(offerReferenceKey)

  return (
    <TableContainer border={false}>
      <TableHead>
        <TableHeader>
          {t('offers.offer_id', 'Offer Id')}
        </TableHeader>
        <TableHeader>
          {t('offers.vendor', 'Vendor')}
        </TableHeader>
        <TableHeader>
          {t('offers.total_price', 'Total Net Price')}
        </TableHeader>
        <TableHeader />
      </TableHead>
      <Table>
        {data?.map((offer) => <ProductSectionOffersTableRow key={offer.uuid} offer={offer} />)}
      </Table>

    </TableContainer>
  )
}

export default ProductSectionOffersTable
