import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import { postSelectSupplier } from '../../../services/RequisitionSuppliersService'
import reqCoordinateQueryKeys from '../constants/reqCoordinateQueryKeys'
import { useCoordinateProvider } from '../providers/CoordinateProvider'

export default function useCreateSupplierInvite(reqId?: string) {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId() ?? reqId

  const { selectedInvites } = useCoordinateProvider()

  const setSelectedInvites = selectedInvites?.[1] || []

  const { modal } = useUtils()

  const { mutate, isLoading } = useMutation(
    ({ supplierUserId }: { supplierUserId: number }) => postSelectSupplier(
      requisitionId,
      supplierUserId,
    ),
    {
      onSuccess: (newData) => {
        setSelectedInvites((invites) => {
          const newInvites = [...invites, newData.id]
          return newInvites
        })
        toastr.success('success.supplier_added_to_selected_suppliers', 'successSupplierInvite')

        cache.setQueryData<RequisitionInviteType[] | undefined>(
          [reqCoordinateQueryKeys.REQUISITION_INVITES, requisitionId],
          (oldData: RequisitionInviteType[] | undefined) => (
            oldData
              ? [...oldData, newData]
              : oldData
          ),
        )

        modal.set({ isOpen: false })

        return newData
      },
    },
  )

  return { mutate, isLoading }
}
