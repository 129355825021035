import BasecampPage from '../../modules/Basecamp/Basecamp'
import CompanyPage from '../../modules/Company/CompanyPage'
import DashPanelAuth from '../../modules/DashPanel/DashPanelAuth'
import FormCreatePage from '../../modules/Forms/FormBuilder/FormCreatePage'
import FormBuilderPage from '../../modules/Forms/FormBuilder/FormsPage/FormBuilderPage'
import FormQuestionPage from '../../modules/Forms/FormBuilder/QuestionPage/FormQuestionPage'
import FormResponseConfirmationPage from '../../modules/Forms/FormResponsePage/FormResponseConfirmationPage'
import FormResponsePage from '../../modules/Forms/FormResponsePage/FormResponsePage'
import { IntakeFlowOverviewPage } from '../../modules/Intake/IntakeFlowOverviewPage'
import IntakeConfiguration from '../../modules/IntakeConfiguration/IntakeConfiguration'
import LhotseHome from '../../modules/LhotseHome/LhotseHome'
import LhotseHomeCreate from '../../modules/LhotseHome/components/LhotseHomeCreate/LhotseHomeCreate'
import RequisitionModuleRouter from '../../modules/Requisition/Requisition'
import NewRequisitionPage from '../../modules/Requisition/subModules/NewRequisition/NewRequisition'
import SettingsPage from '../../modules/Settings/SettingsPage'
import SupplierDetailsPage from '../../modules/SupplierDetails/SupplierDetailsPage/SupplierDetailsPage'
import SuppliersPage from '../../modules/Suppliers/SuppliersPage'
import { WorkflowBuilderPage } from '../../modules/Workflow'
import WorkflowDetailsPage from '../../modules/Workflow/pages/WorkflowDetailsPage'
import RouteType from '../../types/shared/RouteType'

const formRoutes: RouteType[] = [
  {
    path: '/forms/:formId/view',
    exact: true,
    component: <FormResponsePage />,
  },
  {
    path: '/forms/:formId/create',
    exact: true,
    component: <FormCreatePage />,
  },
  {
    path: '/forms/:formId/:location',
    exact: true,
    component: <FormBuilderPage />,
  },
  {
    path: '/forms/:formId/section/:sectionId/question/:questionId/:location',
    exact: true,
    component: <FormQuestionPage />,
  },
  {
    path: '/forms/:formId/submission/:submissionId',
    exact: true,
    component: <FormResponsePage />,
  },
  {
    path: '/forms/:formId/submission/:submissionId/confirmation',
    exact: true,
    component: <FormResponseConfirmationPage />,
  },
]

const workflowRoutes = [
  {
    path: '/workflows/:uuid/builder',
    exact: true,
    component: <WorkflowBuilderPage />,
  },
  {
    path: '/workflows/:uuid/version/:version',
    exact: true,
    component: <WorkflowDetailsPage />,
  },
]

const privateRoutes: RouteType[] = [
  ...formRoutes,
  ...workflowRoutes,
  {
    path: '/new/:tab?',
    component: <NewRequisitionPage />,
  },
  {
    path: '/requisition',
    component: <RequisitionModuleRouter />,
  },
  {
    path: '/intake-flow/:intakeId',
    component: <IntakeFlowOverviewPage />,
  },
  {
    path: '/settings',
    component: <SettingsPage />,
  },
  {
    path: '/dash-panel/authenticate',
    component: <DashPanelAuth />,
    exact: true,
  },
  {
    path: '/company',
    component: <CompanyPage />,
  },
  {
    path: '/intake-configuration',
    component: <IntakeConfiguration />,
  },
  {
    path: '/suppliers',
    component: <SuppliersPage />,
    exact: true,
  },
  {
    path: '/supplier/:supplierBranchId/:screen?',
    exact: true,
    component: <SupplierDetailsPage />,
  },
  {
    path: '/basecamp/:error?',
    exact: true,
    component: <BasecampPage />,
  },
  {
    path: '/dashboard',
    exact: true,
    component: <BasecampPage />,
  },

  {
    path: '/',
    exact: true,
    component: <BasecampPage />,
  },
  {
    path: '/submissions',
    exact: true,
    component: <BasecampPage />,
  },
  {
    path: '/requisitions',
    exact: true,
    component: <BasecampPage />,
  },
  {
    path: '/intake-flows',
    exact: true,
    component: <LhotseHome />,
  },
  {
    path: '/intake-flows/create',
    exact: true,
    component: <LhotseHomeCreate />,
  },
]

export default privateRoutes
