import QuestionInputWrapper from '../QuestionInputWrapper'
import SearchSelect, { ISearchSelect } from '../../../../../../shared/components/atoms/SearchSelect/SearchSelect'

export const QuestionSearchSelect = (props: ISearchSelect) => (
  <QuestionInputWrapper>
    {(inputProps) => (
      <SearchSelect
        {...inputProps}
        {...props}
      />
    )}
  </QuestionInputWrapper>
)

export default QuestionSearchSelect
