import mousetrap from 'mousetrap'
import { Dispatch, SetStateAction, useEffect } from 'react'

const useBindCmdKShortcut = (setOpen: Dispatch<SetStateAction<boolean>>) => {
  useEffect(() => {
    mousetrap.bind(['command+k', 'ctrl+k'], () => {
      setOpen(true)
      return false
    })
    return function cleanup() {
      mousetrap.unbind(['command+k', 'ctrl+k'])
    }
  }, [])
}

export default useBindCmdKShortcut
