import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { RequisitionDataType } from '../../../../../types/shared/RequisitionDataTypes'
import useGetRequisitionId from '../../../../../shared/hooks/useGetRequisitionId'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import ReqDefQueryKeys from '../constants/queryKeys'
import { apiGetRequisitionData } from '../services/metadataService'

const useGetRequisitionData = () => {
  const requisitionId = useGetRequisitionId()
  const cache = useQueryClient()
  const queryKey = [ReqDefQueryKeys.requisitionData, requisitionId]

  const currentReqData = cache.getQueryData<RequisitionDataType>(queryKey)

  const history = useHistory()

  const newReqData = useQuery(
    queryKey,
    () => apiGetRequisitionData(requisitionId),
    {
      enabled: !currentReqData && !!requisitionId,
      onError: () => {
        history.push('/')
        toastr.error('error.requisition_not_found')
      },
    },
  )

  return currentReqData
    ? { data: currentReqData, isLoading: false }
    : newReqData
}

export default useGetRequisitionData
