import { useQuery } from 'react-query'
import { getPaginatedIntakeOfferings } from '../services'
import useIntakeId from './useIntakeId'

export const useGetPaginatedIntakeOfferings = (page: number) => {
  const intakeUuid = useIntakeId()

  return useQuery(
    ['intake-offerings', intakeUuid, 'paginated', page],
    () => getPaginatedIntakeOfferings(intakeUuid, page),
  )
}

export default useGetPaginatedIntakeOfferings
