import { ValidationError } from 'yup'
import AddressType from '../../shared/AddressType'
import { MediaType } from '../../shared/MediaTypes'
import { LoopQuestionLogic } from './formLogic'
import type { FormQuestionLogicType } from './formQuestionLogic'
import type { FormSectionActionsType, FormSectionCategoryType } from './formTypes'

export const BasicFormInputTypes = [
  'TEXT',
  'TEXT_AREA',
  'DATE',
  'DATE_TIME',
  'NUMBER',
  'PRICE',
  'FILE',
  'SELECT',
  'MULTI_SELECT',
  'SEARCH_SELECT',
  'SUPPLIER',
  'ADDRESS',
  'KEY_VALUE',
] as const

export type FormQuestionContentType = typeof BasicFormInputTypes[number]

export type FormNumberOfChoicesOptionSettingType = 'unlimited' | 'single' | 'defined' | 'range'

export type FormQuestionResponseType = {
    is_selected?: boolean
    __uuid: string
    uuid?: string
    value: string
    label?: string
    order?: number
    display_name?: string
    identifier?: string
    default?: boolean
}

export type FormQuestionNumberOfChoiceSettingType = {
    type: FormNumberOfChoicesOptionSettingType | undefined
    value?: (number | undefined)[] | undefined
    uuid?: string
    identifier?: string
}

export const QuestionUserTypes = ['REQUESTER', 'SUPPLIER'] as const
export type QuestionUserType = typeof QuestionUserTypes[number]

export type FormQuestionDateValue = Date

export type AddressQuestionValue = Pick<AddressType, 'id' | 'name'>
export type AddressQuestionValueNameWithFullAddress = Pick<AddressType, 'id' | 'name' | 'street' | 'state' | 'zip' | 'city' | 'country'>
export type FormQuestionValueType = string | number | MediaType | FormQuestionDateValue | AddressQuestionValue
export type FormQuestionValue = { id?: string,
  __uuid: string, value: FormQuestionValueType, label?: string, display_name?: string, is_selected?: boolean, uuid?: string}

export type AnswerSelectMethod = 'sum' | 'first_value'

export type FormQuestionValidationProperty<T> = {
  value?: T
  target?: string
  answerSelector?: AnswerSelectMethod
}

export type FormQuestionValidation = {
    required?: FormQuestionValidationProperty<boolean>
    min_values?: FormQuestionValidationProperty<number>
    max_values?: FormQuestionValidationProperty<number>
    max_characters?: FormQuestionValidationProperty<number>
    min_characters?: FormQuestionValidationProperty<number>
    min?: FormQuestionValidationProperty<number>
    max?: FormQuestionValidationProperty<number>
    exact?: FormQuestionValidationProperty<number>
    total?: FormQuestionValidationProperty<number>
}

export type FormQuestionSettings = Partial<{
    options: {
      customValues: boolean
      other: boolean
    },
    answers: {
        min?: number,
        max?: number
    },
    labels: {
        predefined?: boolean
        readonly?: boolean
    }
    misc: {
      hidden?: boolean
    }
    intakeOverview: {
      showInSummary?: boolean
    }
}>

export const SearchSelectOptions = ['CUSTOM', 'MATERIAL_GROUPS', 'COST_CENTER', 'ORDERS', 'PS_VALUE'] as const

export type SearchSelectType = typeof SearchSelectOptions[number]

export type ProductQuestionKey = 'prod-description' | 'prod-name' | 'prod-quantity' | 'prod-cost_center'

export type FormQuestionType = {
    __uuid: string
    type: FormQuestionContentType
    key?: string | ProductQuestionKey | null
    label: string | null
    question_label?: string
    subtitle?: string
    searchType?: SearchSelectType
    search_type?: SearchSelectType
    hint?: string | null
    actions?: FormSectionActionsType
    values: FormQuestionValue[]
    options?: FormQuestionResponseType[]
    __logic?: FormQuestionLogicType | null
    __loop?: LoopQuestionLogic
    validation: FormQuestionValidation
    forceUpdateId?: string
    __active_for: QuestionUserType
    __settings?: FormQuestionSettings
    inputLabel?: string
    isFormElement?: boolean
    inputId?: string | null
    sectionType?: FormSectionCategoryType
    inputName?: string | null
}

export type EvaluatedFormQuestionType = FormQuestionType & {
    __error?: ValidationError | null
}
