import { t } from 'i18next'
import AcceptanceStateType from '../../../../../../../types/Requisition/AcceptanceStateType'
import useGetRequisitionData from '../../../../Definition/hooks/useGetRequisition'
import { offerViewSupplierTypeTagTitle } from '../utils'
import TypeTag from './TypeTag'

interface IIsWaitingTag {
  currentStatus: AcceptanceStateType
}

const IsWaitingTag: React.FC<IIsWaitingTag> = ({
  currentStatus,
}) => {
  const { data: requisition } = useGetRequisitionData()

  const isWaiting = ['OFFER_IN_EDITING', 'INVITED'].includes(currentStatus || '') && requisition?.status !== 'LAST_CALL_NEGOTIATION'

  return isWaiting
    ? (
      <TypeTag
        lhotseSupplier={false}
        title={t(offerViewSupplierTypeTagTitle(currentStatus))}
        waiting
      />
    )
    : null
}

export default IsWaitingTag
