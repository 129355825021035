interface TableContainerProps {
  disabled?: boolean
  border?: boolean
  children?: React.ReactNode
  emptyState?: React.ReactNode
}
const TableContainer: React.FC<TableContainerProps> = ({
  children, disabled, border = true, emptyState,
}) => (
  <div className={
    ` rounded-md
  ${border ? ' border border-gray-200 shadow-sm  bg-white' : null}
  ${disabled ? 'bg-gray-100 ' : ''}`
  }
  >
    {emptyState ? <div className="text-center text-sm py-4">{emptyState}</div>
      : (
        <table className="table table-auto w-full">
          {children}
        </table>
      )}
  </div>
)

export default TableContainer
