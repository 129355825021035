import { FormQuestionType } from '../../../../types/Forms/formTypes'
import useQuestionValues from '../hooks/useQuestionValues'
import FormInput from '../QuestionTypes/atoms/FormInput'

interface IOtherResponseInput {
  question: FormQuestionType
}

const OtherResponseInput: React.FC<IOtherResponseInput> = ({ question }) => {
  const otherValue = question.values?.find((value) => value.__uuid === 'other')
  const [questionValues, setQuestionValues] = useQuestionValues(question)

  const handleOnChange = (value: string) => {
    const newQuestionValues = questionValues.map((questionValue) => (questionValue.__uuid === 'other'
      ? { __uuid: 'other', value, label: question.question_label }
      : questionValue
    ))

    setQuestionValues(newQuestionValues)
  }

  if (!otherValue) return null

  return (
    <div className="ml-[43px]">
      <FormInput
        shortResponse
        value={(otherValue?.value || '') as string}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default OtherResponseInput
