import { useQuery } from 'react-query'
import { getCompanyAddresses } from '../../../../Requisition/subModules/Definition/services/metadataService'
import companyQueryStrings from '../../../constants/companyQueryStrings'

const useGetCompanyAddresses = () => useQuery(
  [companyQueryStrings.company_addresses],
  getCompanyAddresses,
)

export default useGetCompanyAddresses
