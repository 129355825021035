import { useTranslation } from 'react-i18next'
import { MdAllInbox } from 'react-icons/md'
import { RequisitionSearchDataCatalogType } from '../../../../../../../types/Requisition/RequisitionSearchResultsDataTypes'

interface INewReqCatalogModalHeader {
  item: RequisitionSearchDataCatalogType
}

const NewReqCatalogModalHeader: React.FC<INewReqCatalogModalHeader> = ({ item }) => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-between text-xs items-center gap-2 w-full px-4">
      <div className="flex items-center gap-2 text-gray-500">
        <MdAllInbox />
        <p>{t('requisition.catalogue_item')}</p>
      </div>
      <div className="truncate text-gray-400">
        ID:
        {' '}
        {item.id}
      </div>
    </div>
  )
}
export default NewReqCatalogModalHeader
