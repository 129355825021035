import * as Yup from 'yup'

import { useTranslation } from 'react-i18next'
import { errorMessages } from '../../../shared/utils/validation/validationErrorMessages'

export default () => {
  const { t } = useTranslation()

  return Yup.object().shape({
    first_name: Yup.string().required(t(errorMessages.required)),
    email: Yup.string()
      .required(t(errorMessages.required))
      .email(t(errorMessages.email)),
    added_on: Yup.string(),
    id_provider: Yup.string(),
    role: Yup.string(),
  })
}
