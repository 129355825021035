import { Dispatch, SetStateAction } from 'react'
import { useMutation } from 'react-query'
import { apiUnassignUser } from '../../../../../modules/Basecamp/services/BasecampService'
import { AssignmentType } from '../../../../../types/shared/AssignmentTypes'
import toastr from '../../toastr/toastr'

const useDeleteAssignment = (
  setLocalAssignments: Dispatch<SetStateAction<AssignmentType[] | undefined>>,
) => useMutation((assignmentId: number) => apiUnassignUser({
  assignmentId,
}), {
  onSuccess: (newData) => {
    setLocalAssignments((current) => (current
      ? current.filter((assignment) => assignment.id !== newData)
      : current))
  },
  onError: () => {
    toastr.error('error.item_deleted', 'errorAssignmentDelete')
  },
})

export default useDeleteAssignment
