import { useTranslation } from 'react-i18next'
import { dateTimeFormatter } from '../../../../../shared/constants/dateFormat'
import useGetOffer from '../../../hooks/useGetOffer'

const BannerDeclinedToOffer: React.FC = () => {
  const { t } = useTranslation()
  const { data: offerData } = useGetOffer()

  const deadline = offerData?.requisition.ends_at

  return (
    <p className="flex text-left py-3 px-8 bg-yellow-100 bg-opacity-80">
      {`${t('supplier_offer.declined_to_offer_banner')}: ${dateTimeFormatter(deadline || '')}`}
    </p>
  )
}

export default BannerDeclinedToOffer
