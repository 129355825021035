import { useTranslation } from 'react-i18next'
import useIsReqClosed from '../../hooks/useIsReqClosed'
import useGetSupplierOfferMaxContentHeight from './hooks/useGetSupplierOfferMaxContentHeight'

const ReqClosedOverlay: React.FC = () => {
  const isReqClosed = useIsReqClosed()
  const { t } = useTranslation()

  const mainContentMaxHeight = useGetSupplierOfferMaxContentHeight()

  return isReqClosed
    ? (
      <>
        <div
          className="bg-red-400 bg-opacity-50 z-20 left-0 bottom-0 absolute w-full"
          style={{ maxHeight: mainContentMaxHeight }}
          data-cy="req-closed-overlay"
        >
          <p className="py-3 px-8 bg-red-50 bg-opacity-60 text-red-600 font-medium">
            {t('supplier_offer.req_closed')}
          </p>
        </div>
        <div
          className="bg-gray-50 bg-opacity-50 z-10 left-0 bottom-0 h-full absolute cursor-not-allowed w-full"
          style={{ maxHeight: mainContentMaxHeight }}
        />
      </>
    )
    : null
}

export default ReqClosedOverlay
