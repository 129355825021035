import { Dispatch, SetStateAction, useEffect } from 'react'

const useHandleWindowResize = (setWindowSize: Dispatch<SetStateAction<{
  width: number;
  height: number;
}>>) => {
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
}

export default useHandleWindowResize
