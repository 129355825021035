import { TagType } from '../../../../types/shared/GeneralTypes'
import Badge from '../../atoms/tags/Badge'

interface ISupplierTag {
  tag: TagType
}

const SupplierTag: React.FC<ISupplierTag> = ({ tag }) => (
  <div className="flex items-center">
    <Badge className="w-full max-w-[128px]">
      <p className="truncate">
        {tag.name}
      </p>
    </Badge>
  </div>
)

export default SupplierTag
