import { cloneDeep } from 'lodash'
import uuid from '../../../../../core/utils/uuid'
import { FormQuestionType } from '../../../../../types/Forms'
import { getDefaultQuestionValues } from '../../../constants/questions'

export const cloneQuestionValues = (question: FormQuestionType) => {
  if (question.type === 'SELECT' || question.type === 'MULTI_SELECT' || question.type === 'SEARCH_SELECT' || question.type === 'SUPPLIER') return cloneDeep(question.values)

  return question.values.map(
    ({ __uuid, ...originalValue }) => ({ ...originalValue, __uuid: __uuid?.includes('date') ? __uuid : uuid() }),
  )
}

export const getLoopedQuestionValues = (question: FormQuestionType) => {
  if (question.__loop?.hasCopiedValuesFromParent) return cloneQuestionValues(question)

  return getDefaultQuestionValues(question)
}
