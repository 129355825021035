import useGetCompanyAddresses from '../../addresses/hooks/useGetCompanyAddresses'
import useGetCompanyInfo from './useGetCompanyInfo'
import useGetCustomerDocuments from './useGetCustomerDocuments'

export default () => {
  const { isLoading: isLoadingCompanyAddresses } = useGetCompanyAddresses()
  const { isLoading: isLoadingCustomerDocs } = useGetCustomerDocuments()
  const { isLoading: isLoadingCompanyInfo } = useGetCompanyInfo()

  return isLoadingCompanyAddresses
    || isLoadingCustomerDocs
    || isLoadingCompanyInfo
}
