import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { omit } from 'lodash'
import toastr from '../../../../shared/components/organisms/toastr/toastr'
import { apiPostForm, formsQueryKeys } from '../../constants/formService'
import { FormDataType } from '../../../../types/Forms'
import uuid, { assuringHasUuid } from '../../../../core/utils/uuid'
import { getMergedDeepOverwritingArrays } from '../../../../shared/utils/objectHelpers'
import FormVersion from '../../constants/FormVersion'
import { getQuestionDefaults } from '../FormsPage/buttons/AddQuestionButton/useCreateNewQuestion'

export default () => {
  const cache = useQueryClient()
  const history = useHistory()

  return useMutation('postCompanyForm', (form: Partial<FormDataType>) => apiPostForm(
    omit(getMergedDeepOverwritingArrays(form, {
      template: {
        __version: FormVersion,
        sections: (form.template?.sections?.map(assuringHasUuid('')) || [])
          .map((section) => getMergedDeepOverwritingArrays(section, {
            fields: section.fields.map((field) => {
              if (field.values.length === 0) field.values.push({ __uuid: uuid('form-question-value'), value: '' })
              return { ...getQuestionDefaults(field), ...field }
            }),
          })),
      },
      published: false,
    }), ['updated_at', 'created_at', 'uuid', '__uuid']),
  ), {
    onSuccess: (newData: FormDataType) => {
      cache.setQueryData(
        [formsQueryKeys.apiGetForm(newData.__uuid)],
        (oldData: FormDataType | undefined) => newData || oldData,
      )
      history.push(`/forms/${newData.__uuid}/content`)

      toastr.success('success.item_created', 'company-form')
    },
  })
}
