import MessageTimeline from '../../../Messenger/components/IntakeMessenger/MessageTimeline/MessageTimeline'
import IntakeMessageEditor from '../../../Messenger/components/IntakeMessenger/IntakeMessageEditor/IntakeMessageEditor'

import useElementSize from '../../../../shared/hooks/useElementSize'
import SidepanelTabTransition from '../../../../shared/components/layout/SidepanelTabTransition'

const IntakeSidepanelComments = () => {
  const [editorRef, { height: editorHeight }] = useElementSize()
  const maxHeight = editorHeight ? `calc(100vh - 250px - ${editorHeight}px)` : 'calc(100vh - 365px)'

  return (
    <SidepanelTabTransition>
      <div
        className="flex flex-col w-full h-full max-h-full justify-between"
        id="sidepanelCommentContainer"
      >
        <div style={{ maxHeight }}>
          <MessageTimeline />
        </div>

        <div ref={editorRef}>
          <IntakeMessageEditor />
        </div>
      </div>
    </SidepanelTabTransition>
  )
}

export default IntakeSidepanelComments
