import { useTranslation } from 'react-i18next'
import {
  MdCheckCircleOutline, MdOutlinePending, MdOutlineRemoveCircleOutline, MdUpdate,
} from 'react-icons/md'
import ApprovalFlowTimelineStepStatuses from './ApprovalFlowTimelineStepStatuses'
import { ApprovalStepType } from '../../../../types/Requisition/ApprovalFlowDrawerTypes'

interface IApprovalFlowTimelineStep {
  approval: ApprovalStepType
  index: number
  last: boolean
}

const steps = ['approval.cost_center_responsible', 'approval.controller', 'approval.buyer']

const ApprovalFlowTimelineStep
  : React.FC<IApprovalFlowTimelineStep> = ({ approval, last, index }) => {
    const { t } = useTranslation()
    return (
      <li key={approval.uuid}>
        <div className="relative pb-8">
          {!last ? (
            <span className="absolute top-4 left-4 -ml-1 h-full border-l border-dashed bg-blue-200" aria-hidden="true" />
          ) : null}
          <div className="relative flex space-x-3">
            <div>
              <span
                className={`h-6 w-6 rounded-full flex items-center justify-center text-white
                ${approval.approval_status === 'APPROVED' && 'bg-green-500'}
                ${approval.approval_status === 'APPROVAL_PENDING' && 'bg-yellow-500'}
                ${approval.approval_status === 'APPROVAL_QUEUED' && 'bg-grey-500'}
                ${approval.approval_status === 'APPROVAL_QUEUED' && 'bg-gray-500'}
                ${approval.approval_status === 'REJECTED' && 'bg-red-500'}
                `}
              >
                {approval.approval_status === 'APPROVED' && <MdCheckCircleOutline />}
                {approval.approval_status === 'APPROVAL_PENDING' && <MdUpdate />}
                {approval.approval_status === 'APPROVAL_QUEUED' && <MdOutlinePending />}
                {approval.approval_status === 'REJECTED' && <MdOutlineRemoveCircleOutline />}
              </span>
            </div>
            <div className="flex min-w-0 flex-1 justify-between space-x-4 ">
              <div>
                <div className="font-sm font-bold">{t(steps[index])}</div>
                <ApprovalFlowTimelineStepStatuses
                  status={approval.approval_status}
                  reason={approval?.reason}
                  approver={approval?.approval_user || undefined}
                />
                <div className="flex flex-col space-y-1 text-sm mt-4">
                  <ul>
                    {approval.approvers.map((a) => (
                      <li key={a.full_name} className="">
                        -
                        {' '}
                        {a.full_name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </li>
    )
  }

export default ApprovalFlowTimelineStep
