import { useCallback, useState } from 'react'

export type ToggleStateFunction = (on?: boolean) => void

export const useToggleableState = (initialState: boolean = false) => {
  const [state, setState] = useState(initialState)

  const toggleState: ToggleStateFunction = useCallback((on?: boolean) => {
    setState((prevState) => ((on === true || on === false) ? on : !prevState))
  }, [])

  return [state, toggleState, setState] as const
}

export default useToggleableState
