import { HiRefresh } from 'react-icons/hi'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import Button from '../../../../shared/components/atoms/buttons/Button'
import useIntegration from '../../hooks/useIntegration'
import useGetRequisitionData from '../../subModules/Definition/hooks/useGetRequisition'

const RequisitionTransmitButton: React.FC = () => {
  const { data } = useGetRequisitionData()
  const {
    triggerTransmission, integrationData, loadingTransmission, refetch,
  } = useIntegration()

  console.table(integrationData)

  const handleTransfer = async () => {
    await triggerTransmission()
    await setTimeout(refetch, 1000)
  }

  const status = integrationData?.length ? integrationData[integrationData.length - 1]?.status : undefined

  return (
    <div className="flex space-x-2 items-center">
      {loadingTransmission ? <CoreLoadingIcon /> : null}
      {!loadingTransmission && integrationData?.length ? (
        <div className="flex space-x-2 items-center">
          <div
            className={`rounded-md text-white items-center
             text-sm px-3 py-1 flex space-x-4 
             ${status === 'success' ? ' bg-green-500 ' : ' bg-gray-300 '}`}
          >
            {status === 'success' ? 'Successfully transferred data to SAP ' : null}
            {status === 'pending' ? 'Transmission pending' : null}
            {status === 'error'
              ? 'There was an error. We will get back to you shortly' : null}
          </div>
        </div>
      ) : null}
      {integrationData && status !== 'success' ? (
        <Button
          disabled={loadingTransmission || data?.status !== 'PURCHASED'}
          type="button"
          variant={integrationData?.length ? 'outline' : 'contained'}
          color="lhotse-blue"
          className="text-sm py-1"
          onClick={handleTransfer}
        >
          {
            integrationData?.length ? (
              <span className=" flex items-center space-x-2">
                <HiRefresh className="w-5" />
                <span>Re-send</span>
              </span>
            ) : <span>Send to SAP</span>
          }
        </Button>
      ) : null}

    </div>
  )
}

export default RequisitionTransmitButton
