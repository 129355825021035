import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { MdQuestionAnswer } from 'react-icons/md'

interface IExpandChatButton {
  setChatExpanded: Dispatch<SetStateAction<boolean>>
  chatExpanded: boolean
}

const ExpandChatButton: React.FC<IExpandChatButton> = ({
  setChatExpanded, chatExpanded,
}) => {
  const { t } = useTranslation()
  return (
    <button
      className="flex border-b border-gray-200 p-4 items-center gap-x-4 text-orange-400 bg-white text-left"
      type="button"
      onClick={() => setChatExpanded((expanded) => !expanded)}
      data-cy="supplier-offer-open-sherpa"
    >
      <MdQuestionAnswer className="text-lg" />
      <p>
        {chatExpanded ? t('supplier_view.hide_q_a') : t('supplier_view.questions_ask_question')}
      </p>
    </button>
  )
}

export default ExpandChatButton
