import { useQuery } from 'react-query'
import ReqDefQueryKeys from '../../../../subModules/Definition/constants/queryKeys'
import { apiFetchRequisitionApproval } from '../../../../services/RequisitionService'

const useGetApprovalStatus = (requisitionId: string) => useQuery(
  [ReqDefQueryKeys.requisitionApproval, requisitionId],
  () => apiFetchRequisitionApproval(requisitionId),
)

export default useGetApprovalStatus
