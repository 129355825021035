import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal'
import Menu from './Menu'

interface IEditDeleteOptions {
  onEdit?: () => void
  onDelete?: () => void
  deleteDisabled?: boolean
  onCopy?: () => void
  hidden?: boolean
  className?: string
  customJustify?: boolean
}

const EditDeleteOptions: React.FC<IEditDeleteOptions> = ({
  onEdit, onDelete, onCopy, hidden, className, customJustify,
  deleteDisabled,
}) => {
  const utils = useUtils()
  const { t } = useTranslation()

  const modalContent = (
    <DeleteConfirmationModal
      handleDelete={() => {
        utils.modal.close()
        onDelete?.()
      }}
      text="Once deleted, the item cannot be recovered. Are you sure you want to delete?"
      deleteConfirmationText="Yes, delete"
    />
  )

  const menuItems = []

  if (onEdit) {
    menuItems.push(
      {
        name: 'generic.edit',
        onClick: onEdit,
      },
    )
  }

  if (onDelete) {
    menuItems.push(
      {
        name: 'generic.delete',
        disabled: deleteDisabled,
        onClick: () => utils.modal.set(
          {
            isOpen: true,
            title: 'Delete question?',
            content: modalContent,
            className: 'max-w-[650px]',
          },
        ),
      },
    )
  }

  if (onCopy) {
    menuItems.push(
      {
        name: 'generic.copy',
        onClick: onCopy,
      },
    )
  }

  if (menuItems.length === 0) return null

  return (
    <div className={classnames('flex items-center h-full', { hidden, 'justify-end': !customJustify }, className)}>
      <Menu menuItems={menuItems} />
    </div>
  )
}
export default EditDeleteOptions
