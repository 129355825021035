// REFACTOR
import { useTranslation } from 'react-i18next'
import usePermissions from '../../../../../core/hooks/usePermissions'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import AddressType from '../../../../../types/shared/AddressType'
import AddressInput from '../../../../../shared/components/organisms/inputs/AddressInput'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import ConfirmationModal from '../../../../../shared/components/organisms/modals/ConfirmationModal'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { ORGANISATION_EDIT } from '../../../../../shared/constants/permissions'
import useDeleteAddress from '../../../hooks/useDeleteAddress'
import { useUpdateCompanyAddress } from '../../../hooks/useUpdateCompanyAddress'
import useMakeCompanyAddressesPublic from '../../../hooks/useMakeCompanyAddressesPublic'

interface IAddressListRowActions {
  address: AddressType
}

const AddressListRowActions: React.FC<IAddressListRowActions> = ({ address }) => {
  const { t } = useTranslation()
  const utils = useUtils()
  const { modal } = useUtils()
  const permissions = usePermissions([{ slug: ORGANISATION_EDIT }])

  const handleOnEdit = () => {
    modal.set({
      isOpen: true,
      title: 'account.edit_company_address',
      content: <AddressInput address={address} />,
    })
  }
  const handleOnDelete = useDeleteAddress({
    addressId: address.id,
    onSuccess: () => { },
    onError: () => toastr.error('error.item_not_deleted', 'errorAddressDelete'),
  })

  const handleOnSetCompanyAddress = useUpdateCompanyAddress({ address_id: address.id })
  const handleMakeUserCompanyAddressPublic = useMakeCompanyAddressesPublic({ address_id: address.id })

  const menuItems = [
    {
      name: 'generic.edit',
      onClick: () => handleOnEdit(),
      dataCy: 'edit-address',
    },
    {
      name: 'generic.delete',
      onClick: () => utils.modal.set(
        {
          isOpen: true,
          title: t('generic.confirm'),
          content:
            (
              <ConfirmationModal
                onConfirm={() => {
                  utils.modal.set({ isOpen: false })
                  handleOnDelete()
                }}
              >
                {t('confirm.are_you_sure_delete')}
              </ConfirmationModal>
            ),
        },
      ),
    },
  ]

  const setCompanyAddress = {
    name: 'account.company_make_company_address',
    onClick: () => handleOnSetCompanyAddress(),
  }

  const setAddressAsPublicCompanyAddress = {
    name: 'account.make_user_address_public',
    onClick: () => handleMakeUserCompanyAddressPublic(),
  }

  if (permissions.get(ORGANISATION_EDIT).allowed && address.public_address) {
    menuItems.splice(1, 0, setCompanyAddress)
  }

  if (permissions.get(ORGANISATION_EDIT).allowed && !address.public_address) {
    menuItems.splice(1, 0, setAddressAsPublicCompanyAddress)
  }

  return (
    <div className="flex justify-end items-center">
      <Menu menuItems={menuItems} buttonId="address-context-menu" buttonCy="address-context-menu" />
    </div>
  )
}
export default AddressListRowActions
