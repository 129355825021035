import { NegotiationOptionType } from './OfferViewNegotiationTypes'

interface INegotiationModalOption {
  option: NegotiationOptionType
}

const NegotiationModalOption: React.FC<INegotiationModalOption> = ({
  option,
}) => (
  <button
    className="border border-gray-300 rounded-lg p-4 hover:bg-indigo-50 hover:bg-opacity-50 cursor-pointer text-left"
    type="button"
    onClick={option.onClick}
    data-cy={option.dataCy}
  >
    <p className="font-medium">{option.title}</p>
    <p className="text-gray-600 text-xs">{option.description}</p>
  </button>
)

export default NegotiationModalOption
