import { INodeChildren } from '../../../../types/shared/GeneralTypes'

interface IContainerRoundedGray extends INodeChildren {
  className?: string
}
const ContainerRoundedGray: React.FC<IContainerRoundedGray> = ({ children, className }) => (
  <div className={`w-full mt-2 bg-gray-100 rounded p-4 ${className}`}>
    {children}
  </div>
)

export default ContainerRoundedGray
