import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import CorePageContainerLayout from '../../../../core/components/layout/CorePageContainerLayout'
import CoreTopbarLayout from '../../../../core/components/layout/CoreTopbarLayout'
import useGetFormData from '../../hooks/useGetFormData'
import Body from './Body'
import FormOverviewHeader from './FormOverviewHeader'
import { isSupported } from '../../constants/FormVersion'
import FormNotSupported from '../../components/FormNotSupported'

const FormBuilderPage: React.FC = () => {
  const { isLoading, data: form } = useGetFormData()

  if (isLoading) return <CoreLoadingIcon />

  return (
    <CoreTopbarLayout topbar={<FormOverviewHeader />}>
      <CorePageContainerLayout>
        {isSupported(form) ? <Body /> : <FormNotSupported /> }
      </CorePageContainerLayout>
    </CoreTopbarLayout>
  )
}

export default FormBuilderPage
