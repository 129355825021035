import React from 'react'
import { useFormContext } from '../../../../../core/providers/FormProvider'

type QuestionInputContext = {
  disabled: boolean
}

type IQuestionInputWrapperProps = {
  children: (context: QuestionInputContext) => JSX.Element
}

export const QuestionInputWrapper = ({ children }: IQuestionInputWrapperProps) => {
  const { question: [question] } = useFormContext()

  return children({ disabled: !question?.actions?.edit })
}

export default QuestionInputWrapper
