import SearchSelect from '../../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import getLogicComparisonOptions from './getLogicComparisonOptions'
import { FormQuestionType, FormLogicComparisonType } from '../../../../../../../../../types/Forms'

interface ILogicOperatorDropdown {
  question?: FormQuestionType
  selected: FormLogicComparisonType
  onChange: (comparisonType: FormLogicComparisonType) => void
}

const LogicConditionComparator: React.FC<ILogicOperatorDropdown> = ({
  selected, onChange, question,
}) => {
  const isLoopQuestion = question?.__loop?.is_loop_trigger

  if (isLoopQuestion) {
    return (
      <p className="text-sm">
        When answer is
      </p>
    )
  }

  const options = question ? getLogicComparisonOptions(question) : undefined
  const currentComparisonOperator = options?.find((option) => option.id === selected)

  return (
    <SearchSelect
      className="text-sm"
      currentSelection={currentComparisonOperator}
      options={options}
      onChange={(e) => { onChange(e.id as FormLogicComparisonType) }}
    />
  )
}

export default LogicConditionComparator
