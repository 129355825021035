import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { CoreNavigationLinkType } from '../../../../types/shared/CoreNavigationLinkType'

interface ICoreSidebarButton {
  item: CoreNavigationLinkType
  minified?: boolean
}

const CoreSidebarButton: React.FC<ICoreSidebarButton> = ({ item, minified }) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center mt-6">
      <div className="w-full mx-4 rounded-md">
        <NavLink
          to={item.href}
          className={`
          w-full flex items-center text-white drop-shadow-lg p-2 hover:opacity-80 bg-sky-blue
          ${minified ? 'rounded-full' : 'rounded-md pr-5'}
           `}
          data-cy={item.cy}
        >
          <span className="w-8 h-8 flex justify-center items-center align-middle text-2xl "><item.icon /></span>
          {!minified && (
            <span className="ml-2 whitespace-nowrap text-sm">
              {t(item.name)}
            </span>
          )}
        </NavLink>
      </div>
    </div>
  )
}

export default CoreSidebarButton
