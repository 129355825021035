import { Redirect, useRouteMatch } from 'react-router'
import CoreLoading from '../../../core/components/CoreLoading'
import RequisitionStatusesType from '../../../types/Basecamp/RequisitionStatusesType'
import useGetRequisitionData from '../subModules/Definition/hooks/useGetRequisition'

const ExistingRequisitionRoutes = () => {
  const { data } = useGetRequisitionData()
  const { url } = useRouteMatch()

  const toExecute = ['ANALYZING', 'CANCELED', 'DEADLINE_PASSED', 'NOT_PURCHASED', 'OPEN', 'PUBLISHED', 'SUPPLIERS_SELECTED', 'SUPPLIERS_INVITED']
  const toAward = ['ARCHIVED', 'AWARDED', 'CLOSED', 'NOT_PURCHASED', 'OFFERS_RECEIVED']
  const toSapTransfer = ['PURCHASED']

  const redirectToStage = (status: RequisitionStatusesType) => {
    if (toExecute.includes(status)) {
      return <Redirect to={`${url}/execute`} />
    } if (toAward.includes(status)) {
      return <Redirect to={`${url}/award`} />
    } if (toSapTransfer.includes(status)) {
      return <Redirect to={`${url}/transfer`} />
    } if (data?.form_submission) {
      return <Redirect to={`${url}/submission`} />
    }
    return <Redirect to={`${url}/define`} />
  }

  return data === undefined
    ? <CoreLoading />
    : redirectToStage(data?.status)
}

export default ExistingRequisitionRoutes
