import { WorkflowBlockStatus } from './WorkflowBlockStatus'
import { UserType } from '../shared/AssignmentTypes'
import { FormQuestionContentType, InputTemplate } from '../Forms'

export type WorkflowBlockLink = {
  id: number;
  workflow_block_from_id?: number | null;
  workflow_block_to_id?: number | null;
}

export type WorkflowBlockActionType = 'APPROVER' | 'APPROVAL'
export type WorkflowNodeMetaType = 'GROUP' | 'HELPER'

export type WorkflowNodeType = WorkflowBlockActionType | WorkflowNodeMetaType

export type WorkflowBlockType = {
  id: number;
  name: WorkflowNodeType;
}

export type WorkflowBlockApprover = {
  user_id: number;
  created_at: string;
  updated_at: string;
  user: UserType;
}

export type WorkflowBlockTriggerComparisonOperator = 'EQUAL' | 'NON_EQUAL' | 'BIGGER_THAN' | 'LOWER_THAN' | 'EQUAL_LOWER_THAN' | 'EQUAL_BIGGER_THAN' | 'STARTS_WITH' | 'ENDS_WITH';

export type WorkflowBlockTrigger = {
  uuid: string;
  other?: string | null;
  order: number;
  condition_operator: 'AND' | 'OR';
  comparison_operator: WorkflowBlockTriggerComparisonOperator;
  value: string;
  input: InputTemplate
  input_uuid: string;
  input_type: FormQuestionContentType;
}

export const WorkflowBlockReminders = [
  '12H', '24H', '48H', '72H',
] as const

export type WorkflowBlockReminder = typeof WorkflowBlockReminders[number]

export type WorkflowBlockStatuses = 'skipped' | 'completed' | 'ongoing' | 'in_queue'| 'pending' | 'approved'|'failed'|'declined'

export type WorkflowBlockTask = {
  id?: number;
  name: string;
  uuid?: string;
  parent_type?: 'active_workflow_block';
  parent_id?: number;
  description: string;
  required: 0 | 1;
  assignees: Partial<WorkflowBlockApprover>[];
  status?: number;
}

export type WorkflowBlock = {
  uuid: string;
  name: string;
  type: WorkflowBlockActionType;
  created_by?: number;
  triggers: WorkflowBlockTrigger[];
  approvers: WorkflowBlockApprover[];
  reminder: WorkflowBlockReminder;
  tasks: WorkflowBlockTask[]
  workflow_block_type?: WorkflowBlockType;
  workflow_block_status?: WorkflowBlockStatus;
  status?: WorkflowBlockStatuses
  current_user_can_approve?: boolean
  options?: { allowApproveUserRequester: boolean}
  status_comment?: string;
}

export type WorkflowStep = {
  uuid: string;
  position: number;
  blocks: WorkflowBlock[];
  status?: WorkflowBlockStatuses
}

export type WorkflowStepDraft = Omit<WorkflowStep, 'uuid'> & { uuid?: string | null }
