interface ITag {
  className?: string
  text: string
}

const Tag: React.FC<ITag> = ({
  className, text,
}) => (
  <div className={`text-center text-xs rounded-full bg-gray-300 bg-opacity-20 py-0.5 px-2 ${className} w-fit`}>
    <p>{text}</p>
  </div>
)

export default Tag
