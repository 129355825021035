import { useAbility } from '@casl/react'
import { ABILITIES } from '../../../../../../config/ability'
import { AbilityContext } from '../../../../../../core/providers/AbilityProvider'

export default () => {
  const ability = useAbility(AbilityContext)
  const recommendedPageIndex = 1
  const suppliersPageIndex = 3

  return !ability.can(ABILITIES.mercateoSearch)
    ? suppliersPageIndex
    : recommendedPageIndex
}
