import { useTranslation } from 'react-i18next'

const NoPreviousRequisitionsMessage = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-2 text-sm">
      <p className="font-medium text-black">{t('supplier.past_requisition_data')}</p>
      <p>{t('supplier.no_supplier_data')}</p>
    </div>
  )
}

export default NoPreviousRequisitionsMessage
