interface IActionPanelRequisitionDetailsBox {
  title: string
  text: string | undefined
  shouldHide?: boolean
}

const ActionPanelRequisitionDetailsBox: React.FC<
  IActionPanelRequisitionDetailsBox> = ({
    title, text, shouldHide,
  }) => (
    !shouldHide
      ? (
        <div className="flex flex-col">
          <p>{text}</p>
          <p className="text-sm text-gray-400">{title}</p>
        </div>
      )
      : null)

export default ActionPanelRequisitionDetailsBox
