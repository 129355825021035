import { useMutation } from 'react-query'
import { useBasecampContext } from '../../../core/providers/BasecampProvider'
import { useUtils } from '../../../core/providers/UtilsProvider'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { apiDeleteRequisition } from '../../Requisition/services/RequisitionService'
import useSearchRequisitions from '../components/BasecampTable/useSearchRequisitions'
import useGetRequisitions from './useGetRequisitions'

const useDeleteRequisition = () => {
  const { requisitionSearch: [searchQuery] } = useBasecampContext()
  const { refetch: refetchSearch } = useSearchRequisitions()

  const { refetch: refetchRequisitions } = useGetRequisitions()

  const { modal } = useUtils()
  return useMutation((requisitionId: number) => apiDeleteRequisition(requisitionId), {
    onSuccess: () => {
      setTimeout(() => {
        if (searchQuery) refetchSearch()
        else refetchRequisitions()

        toastr.success('success.item_deleted', 'successRequisitionDeleted')
        modal.set({ isOpen: false })
      }, 500)
    },
    onError: () => {
      toastr.error('error.item_deleted', 'errorReqDuplicate')
    },
  })
}

export default useDeleteRequisition
