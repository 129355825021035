import { createPortal } from 'react-dom'
import { INodeChildren } from '../../../../../types/shared/GeneralTypes'

interface IModalPortal extends INodeChildren {
  style: React.CSSProperties
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const ModalPortal: React.FC<IModalPortal> = ({
  style, children, onMouseEnter, onMouseLeave,
}) => {
  const root = document.getElementById('root') as HTMLElement

  return createPortal(
    <div
      className="absolute z-50 shadow-xl rounded-xl border-border-gray border overflow-hidden"
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>,
    root,
  )
}

export default ModalPortal
