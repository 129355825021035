import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputNumber from '../../atoms/inputs/InputNumber'
import useOnMaximumChange from './useOnMaximumChange'
import useOnMinimumChange from './useOnMinimumChange'

export interface INumberRange {
  minimumState: [number | undefined, Dispatch<SetStateAction<number | undefined>>]
  maximumState: [number | undefined, Dispatch<SetStateAction<number | undefined>>]
  maxValue?: number
  placeholder?: string
}

const NumberRange: React.FC<INumberRange> = ({
  minimumState, maximumState, maxValue, placeholder,
}) => {
  const { t } = useTranslation()

  const [shouldUpdate, setShouldUpdate] = useState<string>('')
  const [minimum] = minimumState
  const [maximum] = maximumState

  const onMinimumChange = useOnMinimumChange(minimumState, maximumState, setShouldUpdate, maxValue)
  const onMaximumChange = useOnMaximumChange(minimumState, maximumState, setShouldUpdate, maxValue)

  return (
    <div className="flex gap-x-2 items-center text-primary-gray pt-2">
      <p>{t('general.between')}</p>
      <div className="w-fit">
        <InputNumber
          precision={0}
          className="max-w-[200px]"
          placeholder={placeholder}
          value={minimum}
          onChange={onMinimumChange}
          allowNegative={false}
          forceUpdate={shouldUpdate}
        />
      </div>
      <p>{t('generic.and')}</p>
      <div className="w-fit">
        <InputNumber
          precision={0}
          className="max-w-[200px]"
          placeholder={placeholder}
          value={maximum}
          onChange={onMaximumChange}
          allowNegative={false}
          forceUpdate={shouldUpdate}
        />
      </div>
    </div>
  )
}

export default NumberRange
