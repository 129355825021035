import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import Button from '../../atoms/buttons/Button'

interface IDeleteConfirmationButtons {
  handleDelete: () => void
  deleteConfirmationText?: string
}

const DeleteConfirmationButtons: React.FC<IDeleteConfirmationButtons> = ({
  handleDelete, deleteConfirmationText,
}) => {
  const { t } = useTranslation()
  const { modal } = useUtils()

  const handleCancel = () => {
    modal.set({
      isOpen: false,
    })
  }

  return (
    <div className="flex gap-x-2">
      <Button
        variant="outlined"
        color="secondary-gray"
        onClick={handleCancel}
      >
        No, cancel
      </Button>
      <Button
        variant="solid"
        color="alert-dark"
        onClick={handleDelete}
      >
        {deleteConfirmationText || 'Yes, delete'}
      </Button>
    </div>
  )
}

export default DeleteConfirmationButtons
