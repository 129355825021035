import { useFormContext } from '../../../../../core/providers/FormProvider'
import QuestionChoices from '../QuestionChoices/QuestionChoices'
import TextAndSubtitleInput from './TextAndSubtitleInput/TextAndSubtitleInput'

const MultiSelectQuestion = () => {
  const { question: [question] } = useFormContext()

  if (question?.type !== 'MULTI_SELECT') return null

  return (
    <div>
      <TextAndSubtitleInput />
      <QuestionChoices />
    </div>
  )
}

export default MultiSelectQuestion
