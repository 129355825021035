import useGetFormSubmissionData from '../hooks/useCheckForSubmission'
import AdvarioHeader from './AdvarioHeader'

const Header = () => {
  const { data: formData } = useGetFormSubmissionData()
  const urlHasAdvarioAndConfirmation = window.location.href.includes('advario') && window.location.href.includes('confirmation')

  return (
    !urlHasAdvarioAndConfirmation ? (
      <div className="px-4 flex justify-between text-sm items-center h-full ">
        <div className="flex h-fit">
          <p className="pl-2.5 font-medium text-nds-primary-gray">{formData?.name || ''}</p>
        </div>
      </div>
    ) : <AdvarioHeader />
  )
}

export default Header
