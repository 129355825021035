import { useEffect, useState } from 'react'
import useUpdateSubmission from './useUpdateSubmission'

export const useSynchronizedFormUpdate = <T>(update: (args: T) => void) => {
  const updateForm = useUpdateSubmission()
  const [shouldUpdateForm, setShouldUpdateForm] = useState(false)

  useEffect(() => {
    if (shouldUpdateForm) {
      setShouldUpdateForm(false)
      updateForm()
    }
  }, [shouldUpdateForm])

  return (args: T) => {
    update(args)
    setShouldUpdateForm(true)
  }
}

export default useSynchronizedFormUpdate
