import toastr from '../../shared/components/organisms/toastr/toastr'

export const getRedirectURL = () => `?redirect=${window.location.pathname}`

export const redirectToLogin = () => {
  const redirectUrl = getRedirectURL()
  window.location = `/login${redirectUrl}`
}

export const redirectToNotFound = () => {
  if (process.env.NODE_ENV !== 'development') {
    window.location = '/404'
  } else {
    toastr.error()
  }
}

export const redirectToError = () => { window.location = '/oops' }
export const redirectToUnauthorized = () => { window.location = '/403' }

export const redirectToDashboard = (error) => {
  if (window.location.pathname !== `/${error}`) { window.location = `/${error}` }
}
