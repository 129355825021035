import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import RequisitionOptionType from '../../../../../../types/Requisition/RequisitionOptionType'
import useGetOffer from '../../../../hooks/useGetOffer'
import useGetToken from '../../../../hooks/useGetToken'
import useIsSupplierOfferEditable from '../../../../hooks/useIsSupplierOfferEditable'
import useUpdateLineItemCommentCache from '../../../../hooks/useUpdateLineItemCommentCache'
import { checkIfInputDisabled, findLineItem } from '../../../../utils/utils'
import Comment from '../Comment'
import useUpdateOfferComment from '../hooks/useUpdateOfferComment'
import MultiSelect from './MultiSelect'
import OneLineItemPrice from './OneLineItem/OneLineItemPrice'
import OptionSelect from './OptionSelect/OptionSelect'

interface ILineItemContainer {
  row: LineItemItemAttributeType
  option: RequisitionOptionType
  sectionIndex: number
  updateAutoSave: () => void
}

const LineItemContainer: React.FC<ILineItemContainer> = ({
  row, option,
  sectionIndex, updateAutoSave,
}) => {
  const token = useGetToken()
  const { userCanEditAnyOffer } = useIsSupplierOfferEditable()

  const handleSupplierCommentOnBlur = useUpdateOfferComment()
  const findAndUpdateSupplierComment = useUpdateLineItemCommentCache()

  const { data: offer } = useGetOffer()
  const initialLineItem = findLineItem(row, option.id)

  return (
    <div className={`flex flex-col gap-y-2 ${(!token || checkIfInputDisabled(offer, userCanEditAnyOffer)) && 'pointer-events-none'}`}>
      <div className="flex gap-x-2 items-center justify-between">
        <OneLineItemPrice
          option={option}
          row={row}
          updateAutoSave={updateAutoSave}
          sectionIndex={sectionIndex}
        />
        <MultiSelect
          option={option}
          sectionIndex={sectionIndex}
          row={row}
          updateAutoSave={updateAutoSave}
        />
        <OptionSelect
          option={option}
          sectionIndex={sectionIndex}
          row={row}
          updateAutoSave={updateAutoSave}
        />
      </div>
      {initialLineItem?.comment
        && (
          <Comment
            comment={initialLineItem?.comment.comment}
            onChange={
              (newComment: string | null) => findAndUpdateSupplierComment(
                newComment,
                sectionIndex,
                row,
                option.id,
              )
            }
            onBlur={() => handleSupplierCommentOnBlur(initialLineItem, updateAutoSave)}
            noIndent
            dataCy="supplier-offer-price-comment-input"
          />
        )}
    </div>
  )
}

export default LineItemContainer
