import dayjs from 'dayjs'
import usePermissions from '../../../../../core/hooks/usePermissions'
import { CompanyUserType } from '../../../../../types/shared/RequisitionDataTypes'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../shared/components/atoms/SharedTable/TableRow'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import { dateFormat } from '../../../../../shared/constants/dateFormat'
import { USER_EDIT } from '../../../../../shared/constants/permissions'
import { UserType } from '../../../../../types/shared/AssignmentTypes'
import useGetUserRowMenuItems from '../hooks/useGetUserRowMenuItems'
import RoleInfo from './RoleInfo'
import UserName from './UserName'

interface IUsersListRow {
  user: CompanyUserType,
  currentUser: UserType | undefined
}

const UsersListRow: React.FC<IUsersListRow> = ({ user, currentUser }) => {
  const menuItems = useGetUserRowMenuItems(user)
  const permissions = usePermissions([{ slug: USER_EDIT }])

  return (
    <TableRow>
      <UserName user={user} />
      <TableCell>{user.email}</TableCell>
      <RoleInfo user={user} currentUser={currentUser} />
      <TableCell>{dayjs(user.created_at).format(dateFormat)}</TableCell>
      <TableCell last>
        {permissions.get(USER_EDIT).allowed && <Menu menuItems={menuItems} />}
      </TableCell>
    </TableRow>
  )
}

export default UsersListRow
