import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './assets/css/index.css'
import './assets/css/nprogress.css'
import CoreLoading from './core/components/CoreLoading'
import CoreProviders from './core/providers/CoreProviders'
import './dayjs'
import './i18n'
import './tracking'
import './apiMock'
import { IS_DEVELOPMENT } from './shared/constants/environments'

if (!IS_DEVELOPMENT) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || ''),
    replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_ERROR_REPLAYS_SAMPLE_RATE || '1.0'),
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SAMPLE_RATE || '0.1'),
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      })],
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <Suspense
    fallback={<CoreLoading />}
  >
    <CoreProviders>
      <App />
    </CoreProviders>
  </Suspense>,
)
