import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import useGetToken from '../../../../SupplierOffer/hooks/useGetToken'

const SelectChatText: React.FC = () => {
  const { modelId } = useParams<{ modelId: string }>()
  const token = useGetToken()
  const { t } = useTranslation()

  if (modelId || token) return null

  return (
    <div className="flex w-full h-full items-center justify-center text-sm text-gray-500">
      {t('sherpa.select_chat_window')}
    </div>
  )
}

export default SelectChatText
