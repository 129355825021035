import { useState } from 'react'
import Menu from '../../../shared/components/organisms/menus/Menu'
import useGetSupplierBranch from '../hooks/useGetSupplierBranch'
import SupplierPreferredStar from '../../Suppliers/components/SupplierPreferredStar/SupplierPreferredStar'
import SupplierBranchName from './SupplierBranchName'
import getEditMenu from '../../../shared/components/organisms/menus/hooks/getEditMenu'

interface ISupplierTitle {
  editable: boolean
}

const SupplierTitle: React.FC<ISupplierTitle> = ({ editable }) => {
  const { data: supplierBranchData } = useGetSupplierBranch()
  const [editMode, setEditMode] = useState(false)

  const menuItems = getEditMenu(setEditMode)

  if (!supplierBranchData) return null

  return (
    <div className="flex justify-between w-full bg-white items-center px-7">
      <div className="flex gap-x-2 items-center py-4 w-full">
        <SupplierPreferredStar supplierBranchId={supplierBranchData?.id} />
        <SupplierBranchName editMode={editMode} setEditMode={setEditMode} />
        {supplierBranchData.deleted_at && (
          <p className="bg-red-500 px-2 text-red-100 py-1 rounded-full">
            Deleted
          </p>
        )}
      </div>
      {editable && !editMode && <Menu menuItems={menuItems} />}
    </div>
  )
}

export default SupplierTitle
