import { useState } from 'react'
import AddNewTagButton from './AddNewTagButton'
import TagInput from './TagInput/TagInput'
import { TagCategoryType } from './type'

interface INewTagOptions {
  category: TagCategoryType
}

const NewTagOptions: React.FC<INewTagOptions> = ({ category }) => {
  const [showCreate, setShowCreate] = useState(false)

  if (showCreate) {
    return (
      <div className="w-full">
        <TagInput
          handleHideInput={() => setShowCreate(false)}
          category={category}
        />
      </div>
    )
  }

  return <AddNewTagButton setShowCreate={setShowCreate} />
}

export default NewTagOptions
