import { useQueryClient } from 'react-query'
import {
  LineItemCommentType, LineItemItemAttributeType, LineItemSectionType, LineItemType,
} from '../../../types/shared/LineItemDataTypes'
import { deepCopy } from '../../../shared/utils/dataParsing'
import OfferViewQueryKeys from '../../Requisition/subModules/Award/constants/OfferViewQueryKeys'
import { findLineItem } from '../utils/utils'

export default () => {
  const cache = useQueryClient()

  return (
    newComment: LineItemCommentType | null | string,
    sectionIndex: number,
    row: LineItemItemAttributeType,
    optionId: number,
  ) => {
    cache.setQueryData([OfferViewQueryKeys.SECTIONS_DATA], (
      oldData: LineItemSectionType[] | undefined,
    ) => {
      const sections: LineItemSectionType[] = deepCopy(oldData)
      const editedRow = sections[sectionIndex].item_attributes?.find(
        (itemAttribute) => itemAttribute.id === row.id,
      ) as LineItemItemAttributeType
      const oldLineItem = findLineItem(editedRow, optionId) as LineItemType
      if (typeof newComment === 'string') {
        if (oldLineItem?.comment?.comment !== undefined) {
          oldLineItem.comment.comment = newComment
        }
      } else {
        oldLineItem.comment = newComment
      }
      return sections
    })
  }
}
