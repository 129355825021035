import SearchSelect from '../../../../../../../../../shared/components/atoms/SearchSelect/SearchSelect'
import { FormQuestionType } from '../../../../../../../../../types/Forms/formTypes'
import {
  InputSearchSelectItemType,
} from '../../../../../../../../../types/shared/InputSearchSelectTypes'

interface ILogicComparatorDropdown {
  question?: FormQuestionType
  value: string | number | undefined | null
  onChange: (value: InputSearchSelectItemType) => void
}

const LogicComparatorDropdown: React.FC<ILogicComparatorDropdown> = ({
  value, onChange, question,
}) => {
  const options = question?.options?.map((option, index) => ({
    id: option.__uuid || index,
    label: option.value,
  })) || []

  const selected = options.find((option) => option.id === value)

  return (
    <SearchSelect
      className="text-sm"
      options={options}
      onChange={onChange}
      currentSelection={selected}
    />
  )
}

export default LogicComparatorDropdown
