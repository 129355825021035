import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { useReactFlow, useStore, Edge } from 'reactflow'
import { WorkflowChartNode } from '../../../types/Workflow/WorkflowChart'
import { calculateLayout } from '../../Intake/helpers'
import { useWorkflowChart } from '../providers/WorkflowChartProvider'
import { reveal } from '../helpers/WorkflowChartNodesDisplay'

export const WorkflowChartLayout = ({ updateNodes, updateEdges }: { updateNodes: Dispatch<SetStateAction<WorkflowChartNode[]>>, updateEdges: Dispatch<SetStateAction<Edge[]>>}) => {
  const nodes = useStore((state) => state.getNodes() as WorkflowChartNode[])
  const edges = useStore((state) => state.edges)
  const { fitView } = useReactFlow()
  const [initialFitTrigger, triggerInitialFit] = useState(false)
  const { workflow, readonly, rerenderRequested } = useWorkflowChart()
  const [requiresLayoutUpdate, setRequiresLayoutUpdate] = useState(false)

  useEffect(() => {
    setRequiresLayoutUpdate(true)
  }, [workflow, rerenderRequested])

  useEffect(() => {
    if (!requiresLayoutUpdate) return

    setRequiresLayoutUpdate(false)
    triggerInitialFit(true)

    const repositionedNodes = calculateLayout(nodes, {
      columnsGap: readonly ? 50 : 100,
    })

    updateNodes(reveal(repositionedNodes))
    updateEdges(reveal(edges))
  }, [nodes])

  useEffect(() => {
    if (initialFitTrigger) fitView()
  }, [initialFitTrigger])
  return null
}

export default WorkflowChartLayout
