import useGetMessengerUrlParams from '../../../hooks/useGetMessengerUrlParams'
import useOpenChat from '../../../hooks/useOpenChat'
import { MessageChatPreviewType } from '../../../../../types/Messenger/types'
import ChatInfo from './ChatInfo'
import MessagePreview from './MessagePreview'

interface IChatWindow {
  preview: MessageChatPreviewType
}

const ChatWindow: React.FC<IChatWindow> = ({
  preview,
}) => {
  const { modelId, modelType } = useGetMessengerUrlParams()
  const openChat = useOpenChat(preview.title, preview.modelId, preview.model)

  const isActiveChat = modelType === preview.model && modelId === preview.modelId?.toString()

  if (!preview) return null

  return (
    <button
      type="button"
      className={`border-b p-3 flex w-full justify-between cursor-pointer hover:bg-blue-50 hover:bg-opacity-50 ${isActiveChat ? 'bg-blue-50 bg-opacity-50' : 'bg-white'}`}
      onClick={openChat}
      data-cy="messageClientChatWindow"
    >
      <div className="flex flex-col w-full items-start gap-y-1 pl-1">
        <ChatInfo preview={preview} />
        <MessagePreview preview={preview} />
        {preview.timestamp && <p className="text-gray-500 text-xs">{preview.timestamp}</p>}
      </div>
    </button>
  )
}

export default ChatWindow
