import { useTranslation } from 'react-i18next'
import SharedAutoSave from '../../../../shared/components/organisms/AutoSave/AutoSave'
import { dateTimeFormatter } from '../../../../shared/constants/dateFormat'
import useGetOffer from '../../hooks/useGetOffer'

interface IHeader {
  deliveryAutosave: boolean
}

const Header: React.FC<IHeader> = ({
  deliveryAutosave,
}) => {
  const { t } = useTranslation()

  const { data: offerData } = useGetOffer()
  const requisitionData = offerData?.requisition

  return (
    <div className="flex justify-between border-b border-gray-200 p-5 w-full text-sm">
      <p className="text-base">{t('generic.delivery')}</p>
      {requisitionData?.expected_delivery_date
        && (
          <div className="flex gap-x-4 text-sm items-center">
            <p className="text-gray-400">{t('supplier_offer.preferred_delivery_date')}</p>
            <span className="font-semibold text-black">{dateTimeFormatter(requisitionData?.expected_delivery_date)}</span>
          </div>
        )}
      <SharedAutoSave autoSave={deliveryAutosave} />
    </div>
  )
}

export default Header
