import RequisitionFilterSelector from './RequisitionFilterSelector'
import ButtonWithPopupBox from '../../../../../../shared/components/atoms/PopupBox/ButtonWithPopupBox'
import useAvailableRequisitionFilters from '../hooks/useAvailableRequisitionFilters'

type RequisitionFilterButtonProps = { text:string, className: string}

const RequisitionFilterButton = ({ text, className } :RequisitionFilterButtonProps) => {
  const availableFilters = useAvailableRequisitionFilters()

  return (
    <ButtonWithPopupBox className={`${className}`} text={`${text}`} disabled={availableFilters.length === 0}>
      <RequisitionFilterSelector />
    </ButtonWithPopupBox>
  )
}

export default RequisitionFilterButton
