import { WorkflowStep } from '../../../types/Workflow/WorkflowBlock'
import { WorkflowChartNode, WorkflowChartNodeHelperType } from '../../../types/Workflow/WorkflowChart'
import {
  createBlockNodes, createGroupNode, createHelperNode, isColumnNode,
} from './WorkflowChartNodeCreationHelpers'

const mapWorkflowStepToFlowchartNodes = (workflowStep: WorkflowStep) => {
  const groupNode = createGroupNode(workflowStep)
  const blockNodes = createBlockNodes(workflowStep)

  return groupNode !== null ? [groupNode] : blockNodes
}

export const mapWorkflowStepsToFlowchartNodes = (workflowSteps: WorkflowStep[]) => (
  workflowSteps.flatMap(mapWorkflowStepToFlowchartNodes)
)

export const generateHelperNodes = (nodes: WorkflowChartNode[]): WorkflowChartNode[] => {
  const nodesRequiringHelpers = nodes.filter(isColumnNode)

  if (nodesRequiringHelpers.length > 0) {
    return [
      createHelperNode({ data: { position: 0 } as WorkflowChartNodeHelperType }),
      ...nodesRequiringHelpers.map(createHelperNode),
      createHelperNode({ data: { position: nodesRequiringHelpers.length + 1 } as WorkflowChartNodeHelperType }),
    ]
  }

  return [createHelperNode({ data: { position: 1 } as WorkflowChartNodeHelperType })]
}
