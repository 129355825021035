import { useTranslation } from 'react-i18next'
import { MdFunctions } from 'react-icons/md'
import { priceToGermanPriceString } from '../../../../shared/constants/currencyFormat'
import useGetCalculatedPrices from './hooks/useGetCalculatedPrices'
import NetGrossDropdown from './NetGrossDropdown/NetGrossDropdown'
import useGetCalculatedLineItems from './hooks/useGetCalculatedLineItems'

const SubtotalPrice: React.FC = () => {
  const { t } = useTranslation()

  const { data: calculatedPrices } = useGetCalculatedPrices()

  const { hasAnyLineItems } = useGetCalculatedLineItems()

  return (
    <div className="flex gap-x-3 w-max justify-center items-center">
      <div className="flex flex-col text-right justify-center items-center">
        <p className="text-2xl">{priceToGermanPriceString(calculatedPrices?.net || 0)}</p>
        <div className="flex items-center py-1 gap-x-1">
          <MdFunctions className="text-sky-blue" />
          <p className="text-xs">{t('supplier_offer.subtotal_price')}</p>
        </div>
      </div>
      {!hasAnyLineItems && (
        <NetGrossDropdown />
      )}
    </div>
  )
}

export default SubtotalPrice
