import { useEffect, useState } from 'react'
import useGetMessages from '../../../Messenger/hooks/useGetMessages'
import useGetToken from '../../hooks/useGetToken'
import BuyerInfo from './BuyerInfo'
import Chat from './Chat'
import ExpandChatButton from './ExpandChatButton'
import RequisitionDetails from './RequisitionDetails/RequisitionDetails'

const ActionPanel: React.FC = () => {
  const token = useGetToken()

  const [chatExpanded, setChatExpanded] = useState(false)

  const { data: messages } = useGetMessages()

  useEffect(() => {
    if (messages?.length) { setChatExpanded(true) }
  }, [messages])

  return token
    ? (
      <div className="w-1/3 border-l border-gray-200">
        <div className="bg-white sticky top-0 h-full flex flex-col">
          <BuyerInfo />
          {!chatExpanded && <RequisitionDetails />}
          <ExpandChatButton setChatExpanded={setChatExpanded} chatExpanded={chatExpanded} />
          {chatExpanded && (<Chat />)}
        </div>
      </div>
    )
    : null
}

export default ActionPanel
