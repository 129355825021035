import { useMutation, useQueryClient } from 'react-query'
import { useMemo } from 'react'
import { intakeFlowMediaService } from '../../Requisition/subModules/Definition/services/attachmentsService'
import { useFormField } from '../providers/FormFieldProvider'
import useIntakeId from '../../Intake/hooks/useIntakeId'

export const useIntakeFlowFileInputMutations = () => {
  const { field: { uuid } } = useFormField()
  const intakeId = useIntakeId()

  const queryClient = useQueryClient()

  const media = uuid ? intakeFlowMediaService(uuid) : undefined

  // Invalidate intake query so that the intake flow will re-fetch when a file is uploaded
  const invalidate = () => { queryClient.invalidateQueries(['intake', intakeId]) }

  const remove = useMutation((uuid: string) => media!.remove(uuid), {
    onSettled: invalidate,
  })

  const upload = useMutation((files: File[]) => media!.upload(files), {
    onSettled: invalidate,
  })

  return useMemo(() => ({
    ...(media || { saveFile: () => {} }),
    remove: media ? remove.mutate : () => {},
    upload: media ? upload.mutate : () => {},
    enabled: !!media,
  }), [media])
}

export default useIntakeFlowFileInputMutations
