import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useAbility } from '@casl/react'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../core/providers/UtilsProvider'
import Button from '../../shared/components/atoms/buttons/Button'
import { InputSearchSelectItemType } from '../../types/shared/InputSearchSelectTypes'
import SearchSelect from '../../shared/components/atoms/SearchSelect/SearchSelect'
import companyQueryStrings from '../Company/constants/companyQueryStrings'
import { apiPostCompanyInputs, apiPutCompanyInputs } from '../IntakeConfiguration/services/CompanyInputsService'
import useGetInputTypes from '../Forms/hooks/useGetInputTypes'
import {
  FilterConditionType, FilterSettingsType,
  FormQuestionContentType, InputCategory, InputTemplate,
} from '../../types/Forms'
import { deleteAt, replaceAt } from '../../shared/utils/arrayHelpers'
import { getMergedDeepOverwritingArrays } from '../../shared/utils/objectHelpers'
import { AbilityContext } from '../../core/providers/AbilityProvider'
import CompanyInputKeyValueFields, { CompanyInputKeyValuePair } from './CompanyInputKeyValueFields'
import useGetInputCategories from '../Forms/hooks/useGetInputCategories'
import { ABILITIES } from '../../config/ability'
import InputTextGray from '../../shared/components/atoms/inputs/InputTextGray/InputTextGray'
import ToggleButton from '../../shared/components/atoms/buttons/ToggleButton'
import { ToggleOption } from '../../types/shared/ToggleButtonTypes'
import DatasetSelect from './ExternalDataSelect/DatasetSelect'
import DatasetInputValueColumnSelect from './ExternalDataSelect/DatasetInputValueColumnSelect'
import DatasetDisplayColumnMultiSelect from './ExternalDataSelect/DatasetDisplayColumnMultiSelect'
import ParentInputSelect from './ExternalDataSelect/ParentInputSelect'
import DatasetParentComparisonColumnSelect from './ExternalDataSelect/DatasetParentComparisonColumnSelect'
import Input from '../../shared/components/atoms/Input/Input'
import BaseInputWrapper from '../../shared/components/atoms/Input/BaseInputWrapper'

interface ICompanyInputForm {
  input?: InputTemplate
}

const getDefaultQuestionTemplate = (): Partial<InputTemplate> => ({
  name: '',
  options: [],
  key: null,
  type: 'TEXT',
  uuid: null,
  category: undefined,
  default: false,
  validation: { required: false, min: undefined, max: undefined },
})

const getOptionFromCategory = (category: InputCategory) => ({ id: category.uuid, label: category.name })
const CompanyInputForm: React.FC<ICompanyInputForm> = ({ input }) => {
  const { modal } = useUtils()
  const client = useQueryClient()
  const { basicTypes: questionTypes } = useGetInputTypes()
  const [errors, setErrors] = useState({ name: undefined })
  const ability = useAbility(AbilityContext)
  const { t } = useTranslation()
  const { data: categories, getById: getCategoryById, defaultCategory } = useGetInputCategories()

  const inputOptions = questionTypes.map((type) => ({ id: type, label: t(`forms.input.${type}`) })) || []
  const [companyInputData, setCompanyInputData] = useState<Partial<InputTemplate>>(
    input || getDefaultQuestionTemplate(),
  )

  // For search selects, show options to allow a user to reference an external data source
  const toggleOptions: ToggleOption[] = [
    { id: 'NORMAL', label: t('inputs.input_normal', 'Normal') },
    {
      id: 'REFERENCED',
      label: t('inputs.input_referenced', 'Referenced'),
      tooltip: t('inputs.referenced_tooltip', 'Reference column values from an external data source as selectable options'),
    },
  ]
  const [selectedToggleOption,
    setSelectedToggleOption,
  ] = useState(companyInputData.filter_settings ? toggleOptions[1] : toggleOptions[0])

  useEffect(() => {
    if (!companyInputData.category && categories && categories.length > 0) {
      setCompanyInputData({ ...companyInputData, category: defaultCategory })
    }
  }, [categories])

  const closeModal = () => {
    modal.set({ isOpen: false })
  }

  const handleSuccess = () => {
    client.invalidateQueries(companyQueryStrings.company_inputs)
    closeModal()
  }

  const { mutate: postCompanyInput } = useMutation(
    () => apiPostCompanyInputs(companyInputData),
    { onSuccess: handleSuccess },
  )

  const { mutate: putCompanyInput } = useMutation(
    () => apiPutCompanyInputs(companyInputData),
    { onSuccess: handleSuccess },
  )

  const handleInput = (e: any, slugValue: boolean = false) => {
    setCompanyInputData({ ...companyInputData, [e.target.name]: slugValue ? e.target.value.replace(' ', '-') : e.target.value })
  }

  const handleFilterSettingsInput = (settingsToUpdate: Partial<FilterSettingsType>) => {
    const newFilterSettings = { ...companyInputData.filter_settings, ...settingsToUpdate }
    setCompanyInputData({ ...companyInputData, filter_settings: newFilterSettings })
  }

  const validate = () => {
    const newErrors = { name: undefined }
    if (!companyInputData.name) newErrors.name = t('generic.required')

    setErrors(newErrors)

    return Object.values(newErrors).filter((error) => !!error).length === 0
  }

  const handleSave = () => {
    const isValid = validate()
    if (!isValid) return

    if (input?.uuid) { putCompanyInput(); return }
    postCompanyInput()
  }

  const handleTypeSelect = (selection: InputSearchSelectItemType) => {
    setCompanyInputData({ ...companyInputData, type: selection.id as FormQuestionContentType || 'TEXT' })
  }

  const handleCategorySelect = (selection: InputSearchSelectItemType) => {
    const selectedCategory = getCategoryById(selection.id as string)
    setCompanyInputData({ ...companyInputData, category: selectedCategory })
  }

  const removeChoice = (item: CompanyInputKeyValuePair, index: number) => {
    if (!companyInputData.options) return

    setCompanyInputData(
      getMergedDeepOverwritingArrays(companyInputData, {
        options: deleteAt(companyInputData.options, index),
      }),
    )
  }

  const addChoice = () => {
    setCompanyInputData(
      getMergedDeepOverwritingArrays(companyInputData, {
        options: [...(companyInputData.options || []), {
          value: '',
          display_name: '',
        }],
      }),
    )
  }

  const handleOptionInput = ({ id, key, value }: CompanyInputKeyValuePair, index: number) => {
    if (!companyInputData?.options) { addChoice(); return }

    setCompanyInputData({
      ...companyInputData,
      options: replaceAt(companyInputData.options, index, { ...companyInputData.options[index], display_name: key, value }),
    })
  }

  const handleSearchSelectTypeChange = (selectedOption: ToggleOption) => {
    if (selectedOption.id === 'NORMAL') {
      // Reset filter settings when switching back to normal
      setCompanyInputData({ ...companyInputData, filter_settings: null })
    } else if (selectedOption.id === 'REFERENCED') {
      // Set default filter settings when switching to referenced
      setCompanyInputData({
        ...companyInputData,
        filter_settings: {
          data_source: undefined,
          data_source_type: 1,
          input_value_column: undefined,
          visible_columns: undefined,
          conditions: [],
        },
      })
    }
    setSelectedToggleOption(selectedOption)
  }

  const handleFilterConditionsUpdate = (conditionToUpdate: Partial<FilterConditionType>) => {
    const newConditions = companyInputData.filter_settings?.conditions || []
    if (newConditions.length === 0
      && companyInputData.filter_settings?.data_source
      && companyInputData.filter_settings?.data_source_type) {
      // Set defaults
      newConditions[0] = {
        parent_data_source_type: 1,
        parent_data_source: companyInputData.filter_settings?.data_source,
        parent_column: '',
        parent_input_uuid: '',
        operator: 'eq', // We only support EQ for now
      }
    }

    // Since we only support 1 condition for now, always set the first one
    newConditions[0] = {
      ...newConditions[0],
      ...conditionToUpdate,
    }
    handleFilterSettingsInput({ conditions: newConditions })
  }

  return (
    <div className="flex flex-col gap-4">
      <Input
        label={t('generic.input-name', 'Input Name')}
        onChange={handleInput}
        defaultValue={companyInputData.name}
        name="name"
        id="name"
      />
      {categories && categories.length > 0 && companyInputData.category && (
        <BaseInputWrapper label={t('generic.category', 'Category')}>
          <SearchSelect
            currentSelection={getOptionFromCategory(companyInputData.category)}
            onChange={handleCategorySelect}
            nullable={false}
            options={categories.map(getOptionFromCategory)}
          />
        </BaseInputWrapper>
      )}
      <div className="flex flex-col gap-2">
        <BaseInputWrapper label={t('generic.input-type', 'Input type')}>
          <SearchSelect
            currentSelection={{ id: 0, label: t(`forms.input.${companyInputData.type}`) }}
            onChange={handleTypeSelect}
            nullable={false}
            options={inputOptions}
          />
        </BaseInputWrapper>
        {companyInputData?.type === 'SEARCH_SELECT' && selectedToggleOption.id === 'NORMAL' && (
          <div>
            <ToggleButton
              options={toggleOptions}
              onChange={handleSearchSelectTypeChange}
              selectedOptionId={selectedToggleOption.id}
            />
          </div>
        )}
      </div>
      {['SELECT', 'MULTI_SELECT', 'KEY_VALUE', 'SEARCH_SELECT'].includes(companyInputData.type || '')
        && (
          <CompanyInputKeyValueFields
            onChange={handleOptionInput}
            onDelete={removeChoice}
            onAdd={addChoice}
            items={companyInputData?.options?.map((option) => ({ id: option.uuid, key: option.display_name || '', value: option.value })) || []}
            keyInputProps={{ name: 'display_name', placeholder: companyInputData.type === 'KEY_VALUE' ? 'Key' : 'Display name' }}
            valueInputProps={{ name: 'value', placeholder: 'Value' }}
          />
        )}
      {selectedToggleOption.id === 'REFERENCED' && (
        <>
          <DatasetSelect
            handleFilterSettingsInput={handleFilterSettingsInput}
            selectedFileUuid={companyInputData.filter_settings?.data_source}
          />
          <DatasetInputValueColumnSelect
            handleFilterSettingsInput={handleFilterSettingsInput}
            datasetUuid={companyInputData.filter_settings?.data_source}
            selectedColumnName={companyInputData.filter_settings?.input_value_column}
          />
          <DatasetDisplayColumnMultiSelect
            handleFilterSettingsInput={handleFilterSettingsInput}
            datasetUuid={companyInputData.filter_settings?.data_source}
            selectedColumns={companyInputData.filter_settings?.visible_columns}
          />
          <ParentInputSelect
            handleFilterConditionsUpdate={handleFilterConditionsUpdate}
            selectedParentUuid={companyInputData.filter_settings?.conditions?.[0]?.parent_input_uuid}
            currentInputUuid={companyInputData.uuid}
          />
          <DatasetParentComparisonColumnSelect
            handleFilterConditionsUpdate={handleFilterConditionsUpdate}
            datasetUuid={companyInputData.filter_settings?.data_source}
            selectedColumnName={companyInputData.filter_settings?.conditions?.[0]?.parent_column}
          />
        </>
      )}
      <Input
        label={t('generic.input-key', 'Input Key')}
        onChange={(e) => { handleInput(e, true) }}
        defaultValue={companyInputData.key || ''}
        name="key"
        id="key"
      />

      {ability.can(ABILITIES.editInputMetadata) && (
        <>
          <Input
            label={t('generic.input-custom-key', 'Custom Key')}
            onChange={(e) => { handleInput(e, true) }}
            defaultValue={companyInputData.custom_key || ''}
            name="custom_key"
            id="custom_key"
          />
          <Input
            label={t('generic.input-name-translation-key', 'Name translation key')}
            onChange={handleInput}
            value={companyInputData.translate_display_name || ''}
            name="translate_display_name"
            id="translate_display_name"
          />
        </>
      )}

      <div className="flex justify-end gap-2">
        <Button
          variant="outlined"
          className="text-sm"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          className="text-sm"
          color="lhotse-orange"
          variant="solid"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
export default CompanyInputForm
