import usePermissions from '../../../core/hooks/usePermissions'
import { REQUISITION_EDIT } from '../../../shared/constants/permissions'
import useGetSupplierInvites from '../subModules/Coordinate/hooks/useGetSupplierInvites'

const useIsReqEditable = () => {
  const { data: allInvites } = useGetSupplierInvites()
  const permissions = usePermissions([{ slug: REQUISITION_EDIT }])

  const canEditSubmittedRequisitions = permissions.get(REQUISITION_EDIT).allowed
  const allowedToEdit = (canEditSubmittedRequisitions || !allInvites?.length)

  return { editable: allowedToEdit }
}

export default useIsReqEditable
