import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { FormQuestionType, FormSectionType, SwitchLogicAdditionalCase } from '../../../../../../../../../types/Forms'
import LogicConditionValue from '../LogicComparatorSelector/LogicConditionValue'
import LogicConditionComparator from '../LogicOperatorDropdown/LogicConditionComparator'
import {
  InputSearchSelectItemType,
} from '../../../../../../../../../types/shared/InputSearchSelectTypes'
import useGetFormData from '../../../../../../../hooks/useGetFormData'
import useQuestionLogicSwitchState from '../../../hooks/useQuestionLogicSwitchMutations'
import FormLogicSourceSelector from '../FormLogicSourceSelector'

interface IFormLogicOptions {
  question: FormQuestionType
  section: FormSectionType
  additionalCondition: SwitchLogicAdditionalCase<string>
  logicCaseIndex: number
  additionalConditionIndex: number
}

const FormLogicAdditionalCondition: React.FC<IFormLogicOptions> = (
  {
    additionalCondition, question, section, logicCaseIndex, additionalConditionIndex,
  },
) => {
  const { t } = useTranslation()
  const {
    updateCondition,
    removeCondition,
  } = useQuestionLogicSwitchState(question, section)

  const { data: formData } = useGetFormData()

  const allQuestions = formData?.template?.sections?.flatMap(
    (formSections) => formSections.fields,
  ) || []

  const sourceQuestion = allQuestions.find((q) => q.__uuid === additionalCondition.source)

  return (
    <div className="flex space-x-4 items-center">
      <FormLogicSourceSelector
        onChange={(
          { id }: InputSearchSelectItemType,
        ) => updateCondition(logicCaseIndex, additionalConditionIndex, { source: id as string })}
        selected={additionalCondition.source}
        question={question}
        section={section}
      />

      <LogicConditionComparator
        selected={additionalCondition.comparisonType}
        question={sourceQuestion}
        onChange={(comparisonType) => updateCondition(logicCaseIndex, additionalConditionIndex, { comparisonType })}
      />

      <LogicConditionValue
        logicCase={additionalCondition}
        question={sourceQuestion}
        onChange={(value) => {
          updateCondition(logicCaseIndex, additionalConditionIndex, { value })
        }}
      />

      <button
        type="button"
        className="flex space-x-1 items-center"
        onClick={() => removeCondition(logicCaseIndex, additionalConditionIndex)}
      >
        <MdClose />
        {' '}
        <div className="underline">{t('forms.delete')}</div>
      </button>
    </div>
  )
}

export default FormLogicAdditionalCondition
