import { FormQuestionContentType } from '../../types/Forms'
import AddressType from '../../types/shared/AddressType'
import { InputSearchSelectItemType } from '../../types/shared/InputSearchSelectTypes'

export const getQuestionTypeTranslation = (type: FormQuestionContentType) => `forms.${type.toLowerCase()}_question`

export const getOptionFromAddress = (address: AddressType): InputSearchSelectItemType => ({
  id: address.id as number,
  label: `${address.name}, ${address.street}, ${address.city} ${address.zip}, ${address.country?.name}` as string,
})

export const getValueFromAddress = ({
  id, name, street, city, zip, country,
}: AddressType) => ({
  id, name, street, city, zip, country,
})

export default getQuestionTypeTranslation
