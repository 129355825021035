interface ICharacterLimit {
  characterLimit: number | undefined
  value: string
}

const CharacterLimit: React.FC<ICharacterLimit> = ({ characterLimit, value }) => {
  const characterLimitExceeded = characterLimit && value?.length > characterLimit

  if (!characterLimit) return null

  return (
    <div className={`flex justify-between 
    p-2 text-xs text-secondary-gray  w-fit absolute -mt-8 right-2 bottom-0 ${characterLimitExceeded ? 'text-red-600' : null}`}
    >
      <p />
      {`${value?.length || 0}/${characterLimit}`}
    </div>
  )
}

export default CharacterLimit
