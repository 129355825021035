import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { inject } from '@vercel/analytics'
import CoreLoading from './core/components/CoreLoading'
import ErrorHandler from './core/components/ErrorHandler/ErrorHandler'
import ErrorToastr from './core/components/ErrorHandler/ErrorToastr'
import useSetDefaultLanguage from './core/hooks/useSetDefaultLanguage'
import Routes from './core/routes/Routes'
import NavigationPromptProvider from './shared/providers/NavigationPropmptProvider'
import useFormValidationLocalization from './core/hooks/useFormValidationLocalization'
import RoutesTrackingProvider from './shared/providers/RoutesTrackingProvider'
import useCrispChat from './core/hooks/useCrispChat'

const fallback = ({ error }: { error: Error }) => <ErrorHandler error={error} />

const App = () => {
  useTranslation()
  useSetDefaultLanguage()
  useFormValidationLocalization()
  inject()
  useCrispChat()

  return (
    <div>
      <BrowserRouter getUserConfirmation={() => { }}>
        <ErrorBoundary FallbackComponent={fallback}>
          <NavigationPromptProvider>
            <RoutesTrackingProvider>
              <Routes />
              <ErrorToastr />
            </RoutesTrackingProvider>
          </NavigationPromptProvider>
        </ErrorBoundary>
      </BrowserRouter>
      <Toaster reverseOrder />
      <CoreLoading />
    </div>
  )
}
export default App
