import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { getLocalInfo } from '../../../../../core/services/userService'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import { dateFormat } from '../../../../../shared/constants/dateFormat'
import { FormQuestionValueType } from '../../../../../types/Forms'
import SubmissionPageMessageInput from '../../../../Requisition/subModules/Submission/SubmissionPageMessageInput'
import SubmissionPageMessageList from '../../../../Requisition/subModules/Submission/SubmissionPageMessageList'
import Question from '../../Question'
import QuestionContext from '../../QuestionContext/QuestionContext'
import useGetFormSubmission from '../../hooks/useGetFormSubmission'
import useGetQuestionAttribute from '../../hooks/useGetQuestionAttribute'
import useGetTotalPrice from '../../hooks/useGetTotalPrice'
import useHandleSoftSubmit from '../../hooks/useHandleSoftSubmit'
import { useFormLogic } from '../../providers/FormLogicProvider'
import ConfirmationSectionAdvarioSupplierInfo from './ConfirmationSectionAdvarioSupplierInfo'
import ConfirmationSectionAdvarioTransmission from './ConfirmationSectionAdvarioTransmission'
import './advario-confirmation.css'
import { advarioDefaultData } from './advarioDefaultData'

const ConfirmationSectionAdvario = () => {
  const { submissionId } = useParams<{ submissionId: string }>()
  const { allEnabledQuestions } = useFormLogic()
  const history = useHistory()
  const getQuestionAttribute = useGetQuestionAttribute()
  const { responsiblePurchasers } = useHandleSoftSubmit()
  const { data: Form, isLoading } = useGetFormSubmission()
  const { value } = useGetTotalPrice()
  const userData = getLocalInfo()

  const { t } = useTranslation()

  const supplierName: FormQuestionValueType = getQuestionAttribute('off-supplier_id')
  const deliverDate: FormQuestionValueType = getQuestionAttribute('req-expected_delivery_date')
  const currency: FormQuestionValueType = getQuestionAttribute('currency')
  const costCenter: string | undefined = getQuestionAttribute('CostCenter')
    || advarioDefaultData[Form?.submitter?.email as string]?.costCenter

  const isDeclined = Form?.status?.name === 'DECLINED'

  if (isLoading) return null

  return (
    <div className="">
      <div className=" overflow-hidden rounded-lg  shadow mt-4 mb-8">
        {isDeclined
          ? (
            <div className="px-4 py-5 sm:px-6 flex flex-col justify-between bg-red-200 border-red-700 ">
              <span className="font-bold">{t('advario.request-declined', 'Request Declined')}</span>
              {Form.status.reason ? (
                <span>
                  <span dangerouslySetInnerHTML={{ __html: Form.status.reason }} />
                </span>
              ) : null}
            </div>
          ) : null}
        {['000', ''].includes(costCenter)
          ? <div className="px-4 py-5 sm:px-6 flex items-center justify-between bg-red-200 border-red-700 space-x-8">{t('advario.cost-center-undefined', 'Cost center undefined')}</div> : null}
        {!['super_admin', 'purchaser'].includes(userData?.role) && !isDeclined
          ? (
            <div className="px-4 py-5 sm:px-6 flex items-center justify-between bg-green-200 border-green-700 space-x-8">
              <div className="text-sm">
                {t(
                  'advario.confirmation-message.requestor',
                  'Your request has successfully been submitted! Nothing else for you to do here. ☺️ Procurement is now checking your request and will transmit it to SAP or get back to you with questions and/or change requests.',
                )}
              </div>
              <Button className="btn btn-secondary" onClick={() => history.push('/')}>
                {' '}
                {t('advario.back-to-dashboard', 'Back to Dashboard')}
              </Button>
            </div>
          ) : null}
        <div className="px-4 py-5 sm:p-6 flex justify-between bg-white">
          <div className="flex flex-col">
            <span className="text-sm font-bold">
              {' '}
              {t('advario.requested-by', 'Requested by')}
            </span>
            {Form?.submitter.full_name}
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-bold">{t('advario.total-value', 'Total Value')}</span>
            <div>
              {parseFloat(value).toFixed(2)}
              {' '}
              <span className="text-xs">{currency}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-bold">{t('advario.delivery-date', 'Delivery Date')}</span>
            {(typeof deliverDate === 'string' ? dayjs(deliverDate).format(dateFormat) : null)}
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-bold">{t('advario.cost-center', 'Cost Center')}</span>
            {(typeof costCenter === 'string' ? costCenter : 'unknown')}
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-bold">{t('advario.supplier', 'Supplier')}</span>
            {(typeof supplierName === 'string' ? supplierName : null)}
          </div>
        </div>
      </div>

      <div className="flex gap-4 divide-x">
        <div className="flex flex-col overflow-y-visible h-auto pb-20" id="advario-confirmation">
          {Form?.body.sections.map((s) => (
            <div key={s.__uuid} className="advario-confirmation-section mt-4">

              <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-[#FAFAFA] px-3 text-base font-semibold leading-6 text-gray-900">{s.name}</span>
                </div>
              </div>
              {s.fields.map((f) => {
                const question = allEnabledQuestions.find((q) => q.__uuid === f.__uuid)
                if (!question) return null
                return (
                  <span key={f.__uuid}>
                    <QuestionContext
                      key={question.__uuid}
                      questionText={question.label || ''}
                      subtitle={question.subtitle || ''}
                      required={question.validation.required?.value}
                      questionHint={question.hint}
                      question={question}
                    >
                      <Question question={question} />
                      {question.type === 'SUPPLIER' && <ConfirmationSectionAdvarioSupplierInfo supplierName={question?.values?.[0]?.value as string || undefined} />}
                    </QuestionContext>
                  </span>
                )
              })}
            </div>
          ))}

        </div>
        <div className="w-96 divide-y pl-4">
          <div className="p-2 mb-4">
            <div className="font-bold">{t('advario.responsible-purchaser', 'Responsible Purchaser')}</div>
            <div>
              <ul className="mt-2">
                {responsiblePurchasers.map((purchaser) => (
                  <li className="text-sm list-disc ml-4" key={purchaser.email}>
                    {purchaser.name}
                  </li>
                ))}
              </ul>
            </div>

          </div>
          <SubmissionPageMessageInput model="form_submission" modelId={submissionId} />
          <SubmissionPageMessageList model="form_submission" modelId={submissionId} />
        </div>
      </div>
      {Form?.requisition_id && <ConfirmationSectionAdvarioTransmission requisitionId={Form?.requisition_id?.toString()} />}
      {/* {Form?.requisition_id && <AdvarioTransmitButton requisitionId={Form?.requisition_id?.toString()} />} */}
    </div>
  )
}

export default ConfirmationSectionAdvario
