import * as Yup from 'yup'
import validation from '../../../../../shared/constants/validationErrors'

const validationSchema = Yup.object().shape({
  email: Yup
    .string()
    .required(validation.required)
    .email(validation.email),
})
export default validationSchema
