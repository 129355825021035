import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import SubmitButton from '../../../../shared/components/atoms/buttons/SubmitButton'
import ErrorMessage from '../../../../shared/components/atoms/ErrorMessage'
import TextInput from '../../../../shared/components/organisms/formik/FormikTextInput'
import ButtonBackToLogin from './components/ButtonBackToLogin'
import usePostForgotPassword from './hooks/usePostForgotPassword'
import validationSchema from './utils/ForgotPasswordForm.validation'

const initialValues = {
  email: '',
}
const ForgotPassword = () => {
  const { t } = useTranslation()

  const { mutate: submitResetPassword, isLoading } = usePostForgotPassword()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(v) => submitResetPassword(v.email)}
      validateOnBlur={false}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="relative">
            <label htmlFor="email">
              <p className="py-2">
                {t('login.enter_address')}
              </p>
              <TextInput
                type="email"
                name="email"
                handleChange={handleChange}
                placeholder="Email"
                values={values}
                inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors.email && 'border-red-500'}`}
                error={errors.email && touched.email && (
                  <ErrorMessage error={errors.email} />
                )}
                handleOnEnter={handleSubmit}
                required
                dataCy="email-input"
              />

            </label>
          </div>
          <div className="flex justify-between pt-5 align-middle">
            <ButtonBackToLogin />
            <SubmitButton isLoading={isLoading}>
              {t('generic.reset_password')}
            </SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPassword
