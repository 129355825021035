import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../core/providers/FormProvider'
import QuestionNavigatorRow from './QuestionNavigatorRow'

const QuestionNavigator: React.FC = () => {
  const { t } = useTranslation()

  const { responseConfig: [responseConfig, setResponseConfig] } = useFormContext()

  if (!responseConfig.questionNavigatorActive) return null

  return (
    <div className="sticky top-20 h-screen bg-white right-0 shadow-md z-50 w-[600px]">
      <div className="flex border-b p-6 pb-4 w-full justify-between">
        <p className="text-primary-gray text-xl">{t('forms.question_navigator')}</p>
        <button
          type="button"
          onClick={() => setResponseConfig({ ...responseConfig, questionNavigatorActive: false })}
          className="text-nds-secondary-gray"
        >
          <MdClose size={24} />
        </button>
      </div>
      <div className="flex flex-col p-6 gap-y-4">
        <p className="text-nds-secondary-gray text-xs font-semibold">
          {t('forms.general_information')}
        </p>
        <QuestionNavigatorRow title="something" />
      </div>
    </div>
  )
}

export default QuestionNavigator
