import DeliverySection from './DeliverySection/DeliverySection'
import DescriptionSection from './DescriptionSection/DescriptionSection'

interface IContentPreview {
  requisitionId?: number
}

const ContentPreview: React.FC<IContentPreview> = ({ requisitionId }) => (
  <div className="flex flex-col gap-y-4 pt-4 w-full">
    <DescriptionSection requisitionId={requisitionId} />
    <DeliverySection />
  </div>
)

export default ContentPreview
