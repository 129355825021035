import { Dispatch, SetStateAction } from 'react'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import SaveCancelFormButtons from '../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import DocumentForm from '../../../Company/components/documents/DocumentForm/DocumentForm'
import { CustomerDocumentType } from '../../../../types/SupplierOffer/SupplierOfferDataTypes'
import useToggleDocumentAcceptance from './useToggleDocumentAcceptance'

interface IDocumentPreview {
  document: CustomerDocumentType
  token: string
  setChecked: Dispatch<SetStateAction<boolean>>,
  checked: boolean
}

const DocumentPreview:
  React.FC<IDocumentPreview> = ({
    document, token, setChecked, checked,
  }) => {
    const { modal } = useUtils()
    const closeModal = () => modal.set({ isOpen: false })

    const toggleDocumentAcceptance = useToggleDocumentAcceptance({
      token, onSuccess: () => setChecked((prevChecked) => !prevChecked),
    })

    return (
      <div className="flex flex-col gap-4">
        <DocumentForm document={document} editable={false} />
        <SaveCancelFormButtons
          onCancel={closeModal}
          onSave={() => {
            toggleDocumentAcceptance(document.id)
            closeModal()
          }}
          saveString={checked ? 'generic.decline' : 'generic.accept'}
        />
      </div>
    )
  }

export default DocumentPreview
