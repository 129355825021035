import { Dialog } from '@headlessui/react'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Body from './Body'

const SharedModal: React.FC = () => {
  const {
    modal: {
      get: { isOpen, keepOpenOnBackdrop },
      close,
    },
  } = useUtils()

  const onClose = () => {
    if (keepOpenOnBackdrop) return
    close()
  }

  return (
    <Dialog
      className="fixed flex justify-center items-start z-50 inset-0 overflow-y-auto  mx-auto"
      open={isOpen}
      onClose={onClose}
      data-cy="shared-modal"
      id="shared-modal"
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity overflow-hidden" />
      <Body />
    </Dialog>
  )
}

export default SharedModal
