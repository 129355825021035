import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import { FileType } from '../../../../types/shared/FileTypes'
import SharedFileUploadPill from '../../layout/SharedFileUploadPill/SharedFileUploadPill'
import { RemoveUploadedFileFunctionType } from '../../layout/SharedFileUploadPill/SharedFileUploadPillTypes'

interface IFileUploadFileList {
  removeFile?: RemoveUploadedFileFunctionType
  downloadFile?: RemoveUploadedFileFunctionType
  files: FileType[] | undefined
  fileIsLoading: boolean | undefined
}

const FileUploadFileList: React.FC<IFileUploadFileList> = ({
  files, downloadFile, fileIsLoading, removeFile,
}) => (
  <div
    className="flex gap-y-2 gap-x-2 items-end w-fit flex-wrap justify-end"
    data-cy="file-list"
  >
    {files?.map((file) => (
      <SharedFileUploadPill
        file={file}
        removeFile={removeFile}
        downloadFile={downloadFile}
        key={file.id}
      />
    ))}
    {fileIsLoading && <div className="justify-center flex w-full"><CoreLoadingIcon /></div>}
  </div>
)

export default FileUploadFileList
