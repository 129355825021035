import { FormQuestionType } from '../../../../../types/Forms'
import useGetFormSubmission from '../../hooks/useGetFormSubmission'

export const useIsQuestionDisabled = (question: FormQuestionType) => {
  const { data: submissionFormData } = useGetFormSubmission()

  return !!submissionFormData?.submitted || !!(
    question.__loop?.hasCopiedValuesFromParent
    && question.__loop.parentQuestionUuid
    && question.__loop.parentQuestionUuid !== question.__uuid
  )
}

export default useIsQuestionDisabled
