import { useMutation, useQueryClient } from 'react-query'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../types/shared/GeneralTypes'
import useGetSupplierBranch from '../../../../SupplierDetails/hooks/useGetSupplierBranch'
import keys from '../../../constants/SuppliersQueryKeys'
import SupplierUserType from '../../../../../types/Suppliers/SupplierUserType'
import { apiPostSupplierBranchUser } from '../../../services/SuppliersService'

export type NewUserFormType = {
  first_name: string
  last_name: string
  email: string
  phone_number: string
}

const usePostUserMutation = (hideForm: () => void) => {
  const cache = useQueryClient()

  const { data: supplierBranch } = useGetSupplierBranch()
  const supplierId = supplierBranch?.supplier_id
  const supplierBranchId = supplierBranch?.id

  return useMutation(
    (item: NewUserFormType) => apiPostSupplierBranchUser(
      { supplierGroupId: supplierId, supplierBranchId, userData: item },
    ),
    {
      onSuccess: (newUser) => {
        if (newUser.is_new) {
          cache.setQueryData<undef<SupplierUserType[]>>(
            [keys.SUPPLIER_USERS, supplierBranchId],
            (oldData: undef<SupplierUserType[]>) => (
              oldData ? ([...oldData, newUser]) : oldData),
          )
          toastr.success('success.item_created')
          hideForm()
        } else {
          toastr.error('info.user_with_this_error_already_exists', 'errorUserAlreadyExists')
        }
      },
      onError: () => {
        toastr.error(undefined, 'errorSupplierUserCreation')
      },
    },
  )
}

export default usePostUserMutation
