import { useTranslation } from 'react-i18next'
import SearchInput from '../../../../../shared/components/atoms/inputs/SearchInput'
import { useBasecampContext } from '../../../../../core/providers/BasecampProvider'
import RequisitionFilterButton from './components/RequisitionFilterButton'

export const RequisitionsSearchBar = () => {
  const { requisitionSearch: [searchText, updateSearchText] } = useBasecampContext()
  const { t } = useTranslation()

  return (
    <div className="flex flex-grow">
      <div className="relative mt-1 z-20 flex flex-grow items-center">
        <SearchInput onChange={(event) => updateSearchText(event.target.value)} value={searchText} className="py-3 border-white shadow-sm" />
        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
          <RequisitionFilterButton
            className="shadow-sm rounded !p-0 min-w-[12em]"
            text={t('basecamp.requisitions.filter')}
          />
        </div>
      </div>
    </div>
  )
}
export default RequisitionsSearchBar
