import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import { useWorkflow } from '../../providers/WorkflowProvider'

type ToggleProps<T = string> = {
  selected: T;
  options: [T, T];
  onSelect?: (option: T) => void;
  render: (option: T) => React.ReactNode;
  generateKey?: (option: T) => string;
};

// eslint-disable-next-line comma-spacing
export const WorkflowToggle = <T,>({
  selected, options, onSelect, render, generateKey,
}: ToggleProps<T>) => {
  const { readonly } = useWorkflow()

  const [selectedOption, setSelectedOption] = useState(selected || options[0])

  useEffect(() => {
    setSelectedOption(selected || options[0])
  }, [selected])

  const handleSelect = (option: T) => {
    setSelectedOption(option)
    onSelect && onSelect(selectedOption)
  }

  const getKey = (option: T) => (generateKey ? generateKey(option) : `toggle-option-${(option as InputSearchSelectItemType).id || (option as { uuid: string }).uuid || (option as { __uuid: string }) || option}`)

  return (
    <div className="bg-white border border-rock-gray-light px-3 py-2 rounded transition-all duration-200">
      {options.map((option) => (
        <button
          disabled={readonly}
          type="button"
          key={getKey(option)}
          onClick={() => handleSelect(option)}
          className={classnames(
            'px-2 py-1 rounded cursor-pointer transition-all duration-200 capitalize min-w-[4rem]',
            {
              'bg-ice-blue text-sky-blue-light': selectedOption === option,
              'text-black-light-2': selectedOption !== option,
            },
          )}
        >
          {render(option)}
        </button>
      ))}
    </div>
  )
}

export default WorkflowToggle

export const WorkflowStringToggle = (props: Omit<ToggleProps, 'render'>) => (<WorkflowToggle<string> {...props} render={(option) => option} />)
