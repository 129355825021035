/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import { INodeChildren } from '../../../types/shared/GeneralTypes'

interface ICoreContainerLayout extends INodeChildren {
  className?: string,
  full?: boolean,
  id?: string,
  border?: string,
  onClick?: () => void,
  style?: React.CSSProperties,
  fit?: boolean
}

// TODO: Remove from all Subcomponents (CA)
const CoreContainerLayout: React.FC<ICoreContainerLayout> = ({
  children, className, full, id, border, onClick, style, fit,
}) => {
  const commonClassNames = `bg-white rounded-md ${border || 'border border-gray-300 border-opacity-50'} ${full && 'w-full h-full'} ${fit && 'w-full min-h-full h-fit'} ${className}`
  return onClick
    ? (
      <div
        id={id}
        className={commonClassNames}
        onClick={onClick}
        style={style}
      >
        {children}
      </div>
    )
    : (
      <div
        id={id}
        className={commonClassNames}
        style={style}
      >
        {children}
      </div>
    )
}

export default CoreContainerLayout
