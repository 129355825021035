import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../core/components/CoreLoadingIcon'
import useGetSSOProviders from '../Login/hooks/useGetSSOProviders'
import JoinForm from './components/JoinForm'
import LinkButton from './components/LinkButton'

const JoinPage = () => {
  const {
    data: ssoProviders,
    isLoading: isLoadingSSOProviders,
  } = useGetSSOProviders()

  const { t } = useTranslation()

  if (isLoadingSSOProviders) return <CoreLoadingIcon />

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-col gap-y-2">
        <p className="text-[24px]">{t('join.sign_up')}</p>
        <div className="w-fit"><LinkButton to="login" text={t('join.or_go_back_to_login')} /></div>
      </div>
      {ssoProviders && ssoProviders?.data?.includes('email') && <JoinForm />}
    </div>
  )
}

export default JoinPage
