import {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react'
import { INodeChildren } from '../../../../../types/shared/GeneralTypes'

interface IReqCoordinateContext {
  selectedInvites: [number[], Dispatch<SetStateAction<number[]>>],
  loadingInvites: [number[], Dispatch<SetStateAction<number[]>>],
  toggleAll: [boolean, Dispatch<SetStateAction<boolean>>]
  searchQuery: [string, Dispatch<SetStateAction<string>>]
}

const ReqCoordinateContext = createContext({} as IReqCoordinateContext)

const CoordinateProvider: React.FC<INodeChildren> = ({ children }) => {
  const selectedInvites = useState<number[]>([]) // checked for invite
  const loadingInvites = useState<number[]>([]) // invited and awaiting response
  const toggleAll = useState(true) // invite all toggle
  const searchQuery = useState('')

  const values = useMemo(() => ({
    selectedInvites,
    loadingInvites,
    toggleAll,
    searchQuery,
  }), [{
    selectedInvites,
    loadingInvites,
    toggleAll,
    searchQuery,
  }])

  return (
    <ReqCoordinateContext.Provider value={values}>
      {children}
    </ReqCoordinateContext.Provider>
  )
}

export default CoordinateProvider

export const useCoordinateProvider = () => useContext(ReqCoordinateContext)
