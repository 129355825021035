// REFACTOR (all of these could be one conditional render component)
import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../../../core/components/CoreLoadingIcon'
import { useCoordinateProvider } from '../../../providers/CoordinateProvider'
import useGetSupplierRecommendations from '../hooks/useGetSupplierRecommendations'
import useGetFilteredCoordinateSupplierUsers from '../SearchResults/hooks/useGetFilteredCoordinateSupplierUsers'
import RequisitionCoordinateSearchResults from '../SearchResults/SearchResults'
import SupplierRow from '../NewSupplierRow/NewSupplierRow'
import AddSupplierBtn from '../NewSupplierRow/AddSupplierBtn/AddSupplierBtn'
import CardTable from '../../../../../../../shared/components/atoms/CardTable/CardTable'

interface ISupplierRows {
  showSearchResults?: boolean
}

const SupplierRows: React.FC<ISupplierRows> = ({
  showSearchResults,
}) => {
  const { t } = useTranslation()

  const { data: supplierRecommendations, isLoading } = useGetSupplierRecommendations()
  const { searchQuery: [searchQuery] } = useCoordinateProvider()

  const recommendedRows = useGetFilteredCoordinateSupplierUsers(supplierRecommendations || [], true)

  const tableColumns = ['Supplier name', 'Contact name', 'Tags', 'Action']
  let tableContents
  if (isLoading) {
    tableContents = <td colSpan={tableColumns.length} className="h-48"><CoreLoadingIcon /></td>
  }

  if (!supplierRecommendations?.length && !searchQuery) {
    return (
      <p className="text-sm text-primary-gray text-center">
        {t('coordinate.search_for_suppliers')}
      </p>
    )
  }

  if ((searchQuery || (supplierRecommendations?.length === 0)) && showSearchResults) {
    return <RequisitionCoordinateSearchResults />
  }

  return recommendedRows?.length
    ? (
      <CardTable columns={tableColumns}>
        {recommendedRows.map((recommendedSupplier) => (
          <SupplierRow
            showSelect={false}
            key={recommendedSupplier?.id}
            supplierUser={recommendedSupplier}
            actionComponent={<AddSupplierBtn supplierUser={recommendedSupplier} />}
          />

        ))}
        { tableContents }
      </CardTable>
    ) : (
      <div className="flex justify-center py-4">
        <p className="text-gray-500 text-sm">{t('req_coordinate.no_more_recommendations')}</p>
      </div>
    )
}

export default SupplierRows
