import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { apiDuplicateRequisition } from '../services/RequisitionService'

export function useDuplicateRequisition() {
  const history = useHistory()

  const { mutate } = useMutation(
    (requisitionId: number) => apiDuplicateRequisition(requisitionId),
    {
      onSuccess: (newRequisitionId) => {
        history.push(`requisition/req-${newRequisitionId.id}/define/`)
      },
      onError: () => {
        toastr.error('error.item_could_not_be_duplicated', 'error.duplicated')
      },
    },
  )

  return mutate
}

export default useDuplicateRequisition
