import { Dispatch, SetStateAction } from 'react'
import { LineItemItemAttributeType, LineItemSectionType, LineItemType } from '../../../types/shared/LineItemDataTypes'
import { CustomerDocumentAcceptanceType, OfferDataType } from '../../../types/shared/OfferDataTypes'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import { SectionOrLineItemType } from '../components/DescriptionSection/Sections/hooks/useGetSectionsOrLineItems'

export type invalidIdStateType = {
  rows?: number[]
  documents?: number[]
}

export const getUnacceptedDocumentIds = (offer: OfferDataType | undefined) => {
  const acceptedDocumentIds = offer?.customer_document_acceptance?.filter(
    (acceptance: CustomerDocumentAcceptanceType) => acceptance.accepted,
  )
    .map((acceptance: CustomerDocumentAcceptanceType) => acceptance.customer_document_id)

  return offer?.customer_documents
    ?.filter((document) => document?.required && !acceptedDocumentIds?.includes(document.id))
    .map((document) => document.id)
}

export const validateForm = (
  sectionData: SectionOrLineItemType[],
  offer: OfferDataType | undefined,
  setInvalidIds: Dispatch<SetStateAction<invalidIdStateType>>,
) => {
  const hasAtLeastOneLineItemHasValue = (lineItems: LineItemType[]) => lineItems
    .find((lineItem) => !!lineItem.value || lineItem.files?.length)

  const allMissingRowIds = sectionData.map((section) => section.item_attributes).flat()
    .filter((row) => (row.is_required && row.type !== 'multi-select' && row.type !== 'attribute'))
    .filter((row) => row.type === 'options' || !hasAtLeastOneLineItemHasValue((row as LineItemItemAttributeType).line_items))
    .filter((row) => {
      if (row.type === 'options') {
        return !(row as LineItemItemAttributeType).line_items?.find((item) => item.value === '1')
      }
      return true
    })
    ?.map((row) => row.id)

  const unacceptedDocuments = getUnacceptedDocumentIds(offer)

  if (allMissingRowIds.length || unacceptedDocuments?.length) {
    setInvalidIds({ documents: unacceptedDocuments, rows: allMissingRowIds })
    if (allMissingRowIds.length) {
      toastr.error('generic.please_fill_out_all_fields', 'errorSupplierOfferForm')
    }

    if (unacceptedDocuments?.length) {
      toastr.error('supplier_offer.accept_documents', 'errorDocumentsSupplierOffer')
    }
    return false
  }

  return true
}
