import { useQuery } from 'react-query'
import keys from './ReqDefAttachmentsQueryKeys'
import { getRequisitionAttachments } from '../../../../services/attachmentsService'
import useGetRequisitionData from '../../../../hooks/useGetRequisition'
import { MediaType } from '../../../../../../../../types/shared/MediaTypes'

const useGetRequisitionAttachments = () => {
  const { data: requisition } = useGetRequisitionData()
  return useQuery<MediaType[]>(
    [keys.REQUISITION_ATTACHMENTS, requisition?.uuid],
    () => getRequisitionAttachments(requisition!.uuid),
    { enabled: !!requisition },
  )
}

export default useGetRequisitionAttachments
