import { MdDoubleArrow } from 'react-icons/md'
import { FormSectionType, FormQuestionType, SwitchLogicCase } from '../../../../../../../../../types/Forms'
import LogicConditionValue from '../LogicComparatorSelector/LogicConditionValue'
import LogicConditionComparator from '../LogicOperatorDropdown/LogicConditionComparator'
import FormLogicTargetSelector from '../FormLogicTargetSelector'

import useQuestionLogicSwitchState from '../../../hooks/useQuestionLogicSwitchMutations'
import {
  InputSearchSelectItemType,
} from '../../../../../../../../../types/shared/InputSearchSelectTypes'

interface IFormLogicOptions {
  question: FormQuestionType
  section: FormSectionType
  logicCase: SwitchLogicCase<string>
}

const FormLogicGoToCase: React.FC<IFormLogicOptions> = ({ logicCase, question, section }) => {
  const {
    updateCase,
  } = useQuestionLogicSwitchState(question, section)

  return (
    <div className="flex space-x-4 items-center">
      <LogicConditionComparator
        selected={logicCase.comparisonType}
        question={question}
        onChange={(comparisonType) => updateCase(logicCase.__uuid, { comparisonType })}
      />
      <LogicConditionValue
        logicCase={logicCase}
        question={question}
        onChange={(value) => {
          updateCase(logicCase.__uuid, { value })
        }}
      />
      <p className="text-base flex"><MdDoubleArrow /></p>
      <FormLogicTargetSelector
        onChange={({ id }: InputSearchSelectItemType) => updateCase(logicCase.__uuid, { target: id as string })}
        selected={logicCase.target}
        question={question}
        section={section}
      />
    </div>
  )
}

export default FormLogicGoToCase
