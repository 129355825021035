/**
 * @param {FormQuestionType} targetQuestion
 * @param schema
 * @returns {error}
 */
export const getMissingTargetValueError = (targetQuestion, schema) => schema.createError(
  { message: `Related question "${targetQuestion.label}" has to be answered`, path: schema.path },
)

export default getMissingTargetValueError
