import { Dispatch, SetStateAction } from 'react'
import { QueryClient } from 'react-query'
import { RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import toastr from '../../../../../shared/components/organisms/toastr/toastr'
import { postSelectSupplier } from '../../../services/RequisitionSuppliersService'
import reqCoordinateQueryKeys from '../constants/reqCoordinateQueryKeys'
import { PostInvitesMutationType } from '../constants/reqCoordinateTypes'

export const createAndSendSupplierInvite = async (
  postInvites: PostInvitesMutationType,
  cache: QueryClient,
  setLoadingInvites: Dispatch<SetStateAction<number[]>>,
  requisitionId: string,
  supplierUserId: number,
  reason?: string,
) => {
  try {
    setLoadingInvites((loading) => [...loading, supplierUserId])
    const invite = await postSelectSupplier(requisitionId, supplierUserId)
    cache
      .setQueryData<RequisitionInviteType[]>(
        [reqCoordinateQueryKeys.REQUISITION_INVITES, requisitionId],
        (oldData) => (oldData ? [...oldData, invite] : [invite]),
      )

    setLoadingInvites((loading) => [...loading.filter((id) => id !== supplierUserId), invite.id])
    await postInvites({ supplierInviteId: invite.id, reason: reason || '' })
  } catch {
    toastr.error()
  }
}

export const resendEmailInvite = async (postInvites: (any: any) => void, tokenId: string) => {
  try {
    await postInvites({ tokenId })
    toastr.success('success.email_sent')
  } catch {
    toastr.error('error.generic', 'errorInviteSend')
  }
}
