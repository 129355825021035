import { useTranslation } from 'react-i18next'
import RequisitionStatusType from '../../../../types/Basecamp/RequisitionStatusesType'

interface ISubmissionPageHeaderRequisitionStatus {
  status?: RequisitionStatusType
}

const SubmissionPageHeaderRequisitionStatus
  : React.FC<ISubmissionPageHeaderRequisitionStatus> = ({ status }) => {
    const { t } = useTranslation()
    switch (status) {
      case 'PENDING_APPROVAL':
        return <div>{t('PENDING_APPROVAL')}</div>
      case 'READY_FOR_TRANSFER':
        return <div>{t('READY_FOR_TRANSFER')}</div>
      case 'TRANSFERRED':
        return <div>{t('TRANSFERRED')}</div>
      case 'REJECTED':
        return <div>{t('REJECTED')}</div>
      default:
        return <div>{status}</div>
    }
  }

export default SubmissionPageHeaderRequisitionStatus
