import { RequisitionInviteType } from '../../../../../../../types/shared/RequisitionDataTypes'
import AdditionalCosts from './AdditionalCosts'
import GrossPrice from './GrossPrice'
import NetPrice from './NetPrice'

interface IPrices {
  invite: RequisitionInviteType
  currentStatus: string | undefined
}

const Prices: React.FC<IPrices> = ({ invite, currentStatus }) => (
  <>
    <div className="flex flex-col text-sm border-t border-gray-300 border-px pt-2">
      <NetPrice invite={invite} />
      <AdditionalCosts invite={invite} currentStatus={currentStatus} />
    </div>
    <div className="border-b border-gray-300 border-px" />
    <GrossPrice invite={invite} />
  </>
)

export default Prices
