import { useMessengerContext } from '../../../core/providers/MessengerProvider'
import { MAX_FILE_SIZE } from '../../../core/utils/fileUploadUtils'
import toastr from '../../../shared/components/organisms/toastr/toastr'
import useSetMessagingFiles from './useSetMessagingFiles'

export default () => {
  const setFiles = useSetMessagingFiles()
  const { messageProperties: [{ files }] } = useMessengerContext()

  return (newFiles: FileList) => {
    const filteredFiles = Array.from(newFiles).filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        toastr.error('error.file_too_large')
        return false
      }
      return true
    })
    setFiles([...files, ...filteredFiles])
  }
}
