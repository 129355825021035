import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSearchSelectItemType } from '../../../../types/shared/InputSearchSelectTypes'
import SearchSelect, { ISearchSelect } from '../../../../shared/components/atoms/SearchSelect/SearchSelect'
import useGetPaginatedSuppliers from '../../../Suppliers/hooks/useGetPaginatedSuppliers'
import useGetSearchedSuppliers from '../../../Suppliers/hooks/useGetSearchedSuppliers'
import SupplierSearchType from '../../../../types/Suppliers/SupplierSearchType'
import { FormQuestionValue } from '../../../../types/Forms'
import SupplierNewModal from '../../../Suppliers/components/SupplierNewModal'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import SupplierBranchDataType from '../../../../types/Suppliers/SupplierBranchDataType'

type SupplierFieldProps = {
  selected: FormQuestionValue | undefined
  onAdd: (supplier: SupplierBranchDataType) => void
  onSelect: (value: FormQuestionValue) => void
} & Omit<ISearchSelect, 'onChange' | 'options' | 'currentSelection' | 'onAdd'>

const SupplierField: React.FC<SupplierFieldProps> = ({
  selected, onAdd, onSelect, ...searchSelectProps
}) => {
  const { t } = useTranslation()
  const { modal } = useUtils()
  const [searchQuery, setSearchQuery] = useState('')

  const { data: paginatedSuppliers, isLoading } = useGetPaginatedSuppliers(100)
  const { data: searchedSuppliers, isLoading: isSearching } = useGetSearchedSuppliers(searchQuery)

  const openNewSupplierModal = () => {
    modal.set({
      title: t('suppliers.add_supplier'),
      content: <SupplierNewModal onFinish={onAdd} />,
      isLoading: false,
      isOpen: true,
    })
  }

  const onChange = (value: InputSearchSelectItemType) => {
    if (!value) return

    // String has to be split because backend selects supplier by name!
    onSelect({ __uuid: value.id as string, value: value.label.split('||')[0] })
  }

  const suppliers = searchQuery
    ? searchedSuppliers?.data
    : paginatedSuppliers?.pages[0].data

  const options = suppliers?.map((supplier: SupplierSearchType) => ({
    id: supplier.id,
    label: `${supplier.name} || ${supplier?.users?.[0]?.first_name || ''} ${supplier?.users?.[0]?.last_name || ''} ${supplier?.users?.[0]?.email || 'No Email'}`,
  })) || []

  const activeValue = selected ? {
    id: selected?.__uuid,
    label: selected?.value as string,
  } : undefined

  return (
    <SearchSelect
      onChange={onChange}
      isLoading={isLoading || (isSearching && (!searchedSuppliers || searchedSuppliers.data.length === 0))}
      options={options}
      addButtonText={t('question.new_supplier')}
      onAdd={openNewSupplierModal}
      onInputChange={(value) => { setSearchQuery(value || '') }}
      currentSelection={activeValue}
      placeholder={t('question.enter_supplier')}
      {...searchSelectProps}
    />
  )
}

export default SupplierField
