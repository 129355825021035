import { useMemo } from 'react'
import useGetCurrentUser from '../../../../../../../core/hooks/useGetCurrentUser'
import { WorkflowBlock } from '../../../../../../../types/Workflow/WorkflowBlock'
import { useIntakeFlow } from '../../../../../providers/IntakeFlowProvider'
import useGetRequiredActionWorkflowBlocks from '../../../../../hooks/useGetRequiredActionWorkflowBlocks'

export const useBlockStatus = (block: WorkflowBlock) => {
  const { data: currentUser } = useGetCurrentUser()

  const { activeWorkflow } = useIntakeFlow()
  const activeBlocks = useGetRequiredActionWorkflowBlocks()

  const isActive = useMemo(() => activeBlocks.some((activeBlock) => activeBlock.uuid === block.uuid), [activeBlocks, block])

  return useMemo(() => (
    {
      requiresCurrentUserApproval: isActive
          && currentUser?.id
          && block.approvers.some((approver) => approver.user_id === currentUser?.id)
          && block.status === 'in_queue',
      isApproved: block.status === 'completed',
      isActive,
    }), [block, currentUser, activeWorkflow])
}

export default useBlockStatus
