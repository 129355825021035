import { MdOutlineModeComment } from 'react-icons/md'
import { LineItemItemAttributeType, LineItemType } from '../../../../../../types/shared/LineItemDataTypes'
import { OffersOfferType } from '../../../../../../types/shared/OfferDataTypes'
import Checkbox from '../../../../../../shared/components/atoms/inputs/Checkbox'
import RadioButton from '../../../../../../shared/components/atoms/inputs/RadioButton'
import Tooltip from '../../../../../../shared/components/organisms/tooltip/Tooltip'
import RequisitionOptionType from '../../../../../../types/Requisition/RequisitionOptionType'
import { RequisitionInviteType } from '../../../../../../types/shared/RequisitionDataTypes'
import { priceToGermanPriceString } from '../../../../../../shared/constants/currencyFormat'

interface ILineItem {
  invite: RequisitionInviteType
  offer: OffersOfferType
  option: RequisitionOptionType
  itemAttribute: LineItemItemAttributeType
}

const LineItem: React.FC<ILineItem> = (
  {
    offer, option, itemAttribute, invite,
  },
) => {
  const currentLineItem = (offer?.line_items as LineItemType[])?.find(
    (lineItem) => lineItem?.item_attribute_option_id === option.id,
  )

  const calculatedLineItem = invite.calculated_price.product_prices.flatMap(
    ({ lineItemsPrices }) => Object.values(lineItemsPrices),
  ).find(({ lineItemId }) => currentLineItem?.id === lineItemId)

  const comment = currentLineItem?.comment?.comment || ''

  const isMultiSelectWithSelection = itemAttribute.type === 'multi-select' && offer?.line_items?.length
  const isNotMultiLineItem = itemAttribute.type !== 'options' && itemAttribute.type !== 'multi-select'

  const isPriceItem = itemAttribute.type === 'price' && calculatedLineItem

  const singleLineItem = isPriceItem ? (
    <div className="flex gap-2 items-center">
      {priceToGermanPriceString(calculatedLineItem!.totalNetPrice)}
    </div>
  ) : (
    <p className="w-fit whitespace-normal break-all">{currentLineItem?.value || '-'}</p>
  )

  return (
    <Tooltip
      className="flex justify-between w-full px-4 items-center line-clamp-1 gap-x-4"
      tooltip={comment}
      showCondition={!!comment}
    >
      <div className="flex w-full justify-between items-center">
        {isMultiSelectWithSelection && <Checkbox checked={currentLineItem?.value === '1'} disabled />}
        {itemAttribute.type === 'options' && <RadioButton checked={currentLineItem?.value === '1'} disabled />}
        {isNotMultiLineItem && singleLineItem}
        {!!comment && <MdOutlineModeComment className="text-gray-400 min-w-[16px]" />}
      </div>
    </Tooltip>
  )
}

export default LineItem
