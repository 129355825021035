import { useGetUrlSearchParam } from '../../../../core/hooks/useGetUrlParam'
import ButtonBackToLogin from '../ForgotPassword/components/ButtonBackToLogin'
import ResetPasswordForm from './components/ResetPasswordForm'

const ResetPassword = () => {
  const token = useGetUrlSearchParam('token')
  const email = useGetUrlSearchParam('email')

  return (
    <div className="flex flex-col">
      <ButtonBackToLogin />
      <ResetPasswordForm token={token || ''} email={email || ''} />
    </div>
  )
}

export default ResetPassword
