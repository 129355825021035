import {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react'
import useSelectableState from '../../../core/hooks/useSelectableState'
import useToggleableState from '../../../core/hooks/useToggleableState'
import { getUpdatedByProperty } from '../../../shared/utils/arrayHelpers'
import { getMergedDeepOverwritingArrays } from '../../../shared/utils/objectHelpers'
import { IntakeFieldType, IntakeType } from '../../../types/Intake'
import { ActiveWorkflow } from '../../../types/Workflow/ActiveWorkflow'
import { WorkflowBlock } from '../../../types/Workflow/WorkflowBlock'
import useGetCurrentIntake from '../hooks/useGetCurrentIntake'
import useGetCurrentWorkflow from '../hooks/useGetCurrentWorkflow'
import useIntakeSidebar from '../hooks/useIntakeSidebar'

type IntakeFlowContext = {
  activeWorkflow: ActiveWorkflow | undefined,
  workflow: ActiveWorkflow | undefined,
  intake: IntakeType | undefined,
  updateField: (updatedField: IntakeFieldType) => void,
  updateActiveWorkflowBlock: (updatedBlock: WorkflowBlock) => void,
  editModeOn: boolean,
  toggleEditMode: (on?: boolean) => void,
  inspectBlock: (block: WorkflowBlock) => void,
  toggleBlockInspection: (block: WorkflowBlock) => void,
  selectedBlock: WorkflowBlock | null,
}

const intakeFlowContext = createContext({} as IntakeFlowContext)

type IntakeFlowProviderProps = React.PropsWithChildren<{}>

const IntakeFlowProvider: React.FC<IntakeFlowProviderProps> = ({ children }) => {
  const [editModeOn, toggleEditMode] = useToggleableState(false)
  const [selectedBlock, select, deselect] = useSelectableState<WorkflowBlock>(null)
  const { data: intakeData } = useGetCurrentIntake()
  const { data: activeWorkflow } = useGetCurrentWorkflow()

  /* this is temporary solution, we should get rid of this local state once we will have api mutations ready */
  const [intake, setIntake] = useState<IntakeType | undefined>(intakeData)
  const [activeWorkflowState, setActiveWorkflowState] = useState<ActiveWorkflow | undefined>(activeWorkflow)

  const { minified, setMinifiedState } = useIntakeSidebar()

  useEffect(() => {
    setIntake(intakeData)
  }, [intakeData])

  useEffect(() => {
    setActiveWorkflowState(activeWorkflow)
  }, [activeWorkflow])

  useEffect(() => {
    if (minified) deselect()
  }, [minified])

  const updateField = useCallback((updatedField: IntakeFieldType) => {
    if (!intake) return

    const fieldSection = intake.sections.find((section) => section.fields.find(({ id }) => id === updatedField.id))

    if (!fieldSection) return

    const updatedIntake = getMergedDeepOverwritingArrays(intake, {
      sections: getUpdatedByProperty('id', intake.sections, [{
        id: fieldSection.id,
        fields: getUpdatedByProperty('id', fieldSection.fields, [updatedField]),
      }]),
    })

    setIntake(updatedIntake)
  }, [intake])

  const updateActiveWorkflowBlock = useCallback((updatedBlock: WorkflowBlock) => {
    if (!activeWorkflowState) return

    const stepToUpdate = activeWorkflowState.steps.find((step) => step.blocks.find(({ uuid }) => uuid === updatedBlock.uuid))

    if (!stepToUpdate) return

    const updatedActiveWorkflow = getMergedDeepOverwritingArrays(activeWorkflowState, {
      steps: getUpdatedByProperty('uuid', activeWorkflowState.steps, [
        {
          uuid: stepToUpdate.uuid,
          blocks: getUpdatedByProperty('uuid', stepToUpdate.blocks, [updatedBlock]),
        },
      ]),
    })

    // also set activeWorkflowState.required_action_workflow_blocks block with the same ID to updatedBlock
    // updatedActiveWorkflow.required_action_workflow_blocks = updatedActiveWorkflow.required_action_workflow_blocks
    //   ?.map((block) => {
    //     if (block.uuid === updatedBlock.uuid) {
    //       return updatedBlock
    //     }
    //
    //     return block
    //   })

    setActiveWorkflowState(updatedActiveWorkflow)
  }, [activeWorkflowState])

  const inspectBlock = useCallback((block: WorkflowBlock) => {
    select(block)
    setMinifiedState(false)
  }, [select, setMinifiedState])

  const contextState = useMemo<IntakeFlowContext>(() => ({
    activeWorkflow: activeWorkflowState,
    workflow: activeWorkflow,
    intake,
    selectedBlock,
    inspectBlock,
    toggleBlockInspection: (block: WorkflowBlock) => {
      if (selectedBlock?.uuid === block.uuid) {
        deselect()
      } else {
        inspectBlock(block)
      }
    },
    editModeOn,
    toggleEditMode,
    updateField,
    updateActiveWorkflowBlock,
  }), [activeWorkflowState, editModeOn, intake, selectedBlock])

  return (
    <intakeFlowContext.Provider value={contextState}>
      {children}
    </intakeFlowContext.Provider>
  )
}

export default IntakeFlowProvider

export const useIntakeFlow = () => useContext(intakeFlowContext)
