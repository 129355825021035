import {
  Redirect, Route, Switch,
} from 'react-router'
import CorePageContainerLayout from '../../../core/components/layout/CorePageContainerLayout'
import TabTitle from '../../../shared/components/layout/TabTitle'
import SubmissionsTable from './BasecampTable/Submissions/SubmissionsTable'
import IntakeFlowsTable from './BasecampTable/IntakeFlows/IntakeFlowsTable'
import Table from './Table'

const BasecampContainer = () => {
  const submissionOnly = window.location.href.includes('advario')
  const isFunkeOrAdvario = window.location.href.includes('advario') || window.location.href.includes('funke')

  return (
    <CorePageContainerLayout className="flex justify-center">
      <div className="flex flex-col max-w-4xl w-full">
        <TabTitle />
        {isFunkeOrAdvario && (
          <Switch>
            <Route path="/" exact>
              <Redirect to={submissionOnly ? '/submissions' : '/requisitions'} />
            </Route>
            <Route path="/dashboard" exact>
              <Redirect to={submissionOnly ? '/submissions' : '/requisitions'} />
            </Route>
            <Route path="/requisitions" component={Table} />
            <Route path="/submissions" component={SubmissionsTable} />
            <Route path="/intake-flows" component={IntakeFlowsTable} />
          </Switch>
        )}
        {!isFunkeOrAdvario && (
          <Switch>
            <Route path="/" exact>
              <Redirect to="/intake-flows" />
            </Route>
            <Route path="/dashboard" exact>
              <Redirect to="/intake-flows" />
            </Route>
          </Switch>
        )}
      </div>
    </CorePageContainerLayout>
  )
}

export default BasecampContainer
