import { RequisitionDataType, RequisitionInviteType } from '../../../../../types/shared/RequisitionDataTypes'
import { apiGetData, apiPut } from '../../../../../core/utils/api/generic'
import apiClient from '../../../../../core/utils/apiClient'

export const getInvitesWithOffers = (requisitionId: string) => apiGetData<RequisitionInviteType[]>(`/requisitions/${requisitionId}/current-offers`)
export const putAcceptedOffer = (invite: RequisitionInviteType, reason: string | undefined, language: string | undefined) => apiPut(`/offers/${invite?.offer?.id}/accept`, { reason, language })
export const putCloseRequisition = (requisition: RequisitionDataType) => apiClient.put(`/requisitions/${requisition.id}/status`, { status: 'CLOSED' })

export const inviteToLastCall = (offerId: number, deadline: Date) => apiPut(`/offers/${offerId}/request-changes`, {
  negotiation_type: 'LAST_CALL_NEGOTIATION',
  deadline_date: deadline,
  reason: '',
})

export const triggerLastCall = (requisitionId: string) => apiPut(`/requisitions/${requisitionId}/status`, { status: 'LAST_CALL_NEGOTIATION' })
