import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import MediaTable from '../../../../../../shared/components/atoms/FileUpload/MediaTable'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import { Offer } from '../../../../../../types/Offering'
import { offerMediaService } from '../../../../../Requisition/subModules/Definition/services/attachmentsService'
import { MediaType } from '../../../../../../types/shared/MediaTypes'

type IProductSectionOfferModalFiles = {
  offer?: Offer
}
const ProductSectionOfferModalFiles: React.FC<IProductSectionOfferModalFiles> = ({ offer }) => {
  const ref = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  const { upload: uploadFiles, remove, download } = offerMediaService(offer?.uuid || '')

  const { data, refetch, isLoading } = useQuery<MediaType[]>(['offerMedia', offer?.uuid], () => offerMediaService(offer?.uuid || '').list())

  const handleUpload = async (files: File[]) => {
    await uploadFiles(files)
    refetch()
  }

  return (
    <div className="pb-8">
      <MediaTable
        media={data || []}
        onDelete={(media) => remove(media.uuid)}
      />

      <AddButton
        label={t('generic.add_attachment', 'Add Attachment')}
        onClick={() => ref.current?.click()}
        loading={isLoading}
      />

      <input
        ref={ref}
        multiple
        className="hidden"
        type="file"
        onChange={async (UploadFiles) => UploadFiles.target.files && handleUpload([UploadFiles.target.files[0] || []])}
      />
    </div>

  )
}

export default ProductSectionOfferModalFiles
