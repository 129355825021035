import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import TableCell from '../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'
import { FormSectionType } from '../../../../../../types/Forms'
import { Offer } from '../../../../../../types/Offering'
import getOfferReference from '../../../../hooks/getOfferReference'
import { priceToGermanPriceString } from '../../../../../../shared/constants/currencyFormat'

interface IProductSectionTableMainRow {
  productSection: FormSectionType
  actions?: React.ReactNode
  children?: React.ReactNode
  defaultOpen?: boolean
}
const ProductSectionTableMainRow: FC<IProductSectionTableMainRow> = ({
  productSection, actions, children, defaultOpen = false,
}) => {
  const [childVisible, setChildVisible] = useState<boolean>(defaultOpen)

  const offerReference = getOfferReference(productSection?.__uuid || '')
  const cache = useQueryClient()

  const offers = cache.getQueryData(['offering-offers', offerReference]) as Offer[]
  const selectedOffer = offers?.find((o) => o.is_selected)

  const productName = productSection?.fields?.find((f) => f.key === 'prod-name')?.values[0]?.value as string || '-'
  const producQuantity = productSection?.fields?.find((f) => f.key === 'prod-quantity')?.values[0]?.value as string
  const costCenter = productSection?.fields?.find((f) => f.key === 'prod-cost_center')?.values[0]?.value as string || productSection?.fields?.find((f) => f.key === 'saps4-costcenter')?.values[0]?.value as string || '-'

  return (
    <>
      <TableRow
        key={productSection.__uuid}
        onClick={() => setChildVisible(!childVisible)}
        active={childVisible}
        className="sticky top-0 z-20"
      >
        <TableCell className="w-1 text-sm font-semibold whitespace-nowrap ">

          <div className="flex">
            <div className="flex flex-col">
              <span className="font-semibold">
                {selectedOffer?.quantity || producQuantity || 1}
                {' '}
                x
              </span>
              <span className="text-xs leading-6">&nbsp;</span>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div className="flex">
            <div className="flex flex-col">
              <span className="font-semibold">
                {productName}
              </span>
              <span className="text-xs leading-6">{costCenter}</span>
            </div>
          </div>
        </TableCell>

        <TableCell>
          <div className="flex-col ">
            <div className="font-semibold">{selectedOffer?.supplier || '-'}</div>
            <div className="flex-col  text-xs leading-6">{priceToGermanPriceString(selectedOffer?.total_net_price || 0)}</div>
          </div>
        </TableCell>

        <TableCell className="text-right">
          <div className="flex justify-end gap-x-2">
            {actions}
            {children && childVisible ? <ChevronDownIcon className="w-5" /> : <ChevronUpIcon className="w-5" />}
          </div>
        </TableCell>
      </TableRow>
      {childVisible && children}
    </>
  )
}

export default ProductSectionTableMainRow
