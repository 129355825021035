import CoreLoading from '../../../../core/components/CoreLoading'
import ConfirmationSections from '../../../Forms/FormResponsePage/sections/ConfirmationSection/ConfirmationSections'
import AttachmentsSection from '../Definition/components/ReqDefContainer/AttachmentsSection/AttachmentsSection'
import useGetRequisitionData from '../Definition/hooks/useGetRequisition'
import FormLogicProvider from '../../../Forms/FormResponsePage/providers/FormLogicProvider'
import FormDataProvider from '../../../Forms/FormResponsePage/providers/FormDataProvider'

const SubmissionSectionOverview = () => {
  const { isLoading: isLoadingRequisitionData } = useGetRequisitionData()

  if (isLoadingRequisitionData) return <CoreLoading />

  return (
    <>
      <ConfirmationSections />
      <AttachmentsSection />
    </>
  )
}

export default SubmissionSectionOverview
