import _ from 'lodash'
import { useEffect, useState } from 'react'

type QueryType = { query: string, [key: string]: any }

function useDebouncedValue(query: QueryType, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(query)

  useEffect(() => {
    if (!_(query).isEqual(debouncedValue)) {
      const handler = setTimeout(() => { setDebouncedValue(query) }, delay)
      return () => { clearTimeout(handler) }
    }

    return () => { }
  }, [query, delay])

  return debouncedValue
}

export default useDebouncedValue
