import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useOpenLastCallConfirmModal from './useOpenLastCallConfirmModal'

export type lastCallStateType = [
  number[] | undefined,
  React.Dispatch<React.SetStateAction<number[] | undefined>>
] | undefined

interface IRequisitionTopbarLastCallConfirmation {
  useLastCallState: lastCallStateType
}

const RequisitionTopbarLastCallConfirmation: React.FC<IRequisitionTopbarLastCallConfirmation> = ({
  useLastCallState,
}) => {
  const { t } = useTranslation()
  const [lastCall, setLastCall] = useLastCallState || []
  const handleConfirmLastCall = useOpenLastCallConfirmModal(useLastCallState)

  const handleCancel = () => {
    if (setLastCall) {
      setLastCall(undefined)
    }
  }

  return (
    <div className="flex gap-x-2">
      <Button
        variant="solid"
        color="lhotse-orange"
        disabled={!lastCall?.length}
        onClick={handleConfirmLastCall}
        dataCy="confirm-last-call"
      >
        {t('generic.confirm_selection')}
      </Button>
      <Button
        variant="outlined"
        color="lhotse-gray"
        onClick={handleCancel}
      >
        {t('requisition_qa.cancel')}
      </Button>
    </div>
  )
}

export default RequisitionTopbarLastCallConfirmation
