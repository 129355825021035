import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from '../../../../../core/providers/FormProvider'
import Table from '../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableRow from '../../../../../shared/components/atoms/SharedTable/TableRow'
import AddButton from '../../../../../shared/components/atoms/buttons/AddButton'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import { FormSectionType } from '../../../../../types/Forms'
import getOfferReference from '../../../hooks/getOfferReference'
import Question from '../../Question'
import QuestionContext from '../../QuestionContext/QuestionContext'
import useGetQuestionIndex from '../../hooks/useGetQuestionIndex'
import useLoopSection, { LoopSectionFieldValues } from '../../hooks/useLoopSection'
import useQuestionsScroll from '../../hooks/useQuestionsScroll'
import useRemoveLoopSection from '../../hooks/useRemoveLoopSection'
import { useFormLogic } from '../../providers/FormLogicProvider'
import ProductSectionOffers from './ProductSectionOffers'
import ProductSectionTableHeader from './ProuctSectionTable/ProductSectionTableHeader'
import ProductSectionTableMainRow from './ProuctSectionTable/ProductSectionTableMainRow'
import UniteSearch from './UniteSearch/UniteSearch'

type IProductSections = {
  productSections: FormSectionType[]
}

const ProductSections: React.FC<IProductSections> = ({ productSections }) => {
  const { t } = useTranslation()
  const [defaultOpen, setDefaultOpen] = useState(productSections.length === 1)
  const questionsRef = useQuestionsScroll()
  const { submission: [submission] } = useFormContext()
  const handleAddSection = useLoopSection(productSections[productSections.length - 1].fields[0])
  const getQuestionIndex = useGetQuestionIndex()
  const removeSection = useRemoveLoopSection()

  const handleAddSectionClick = () => {
    setDefaultOpen(true)
    handleAddSection()
  }

  const handleAddSectionWithData = (data: LoopSectionFieldValues) => {
    setDefaultOpen(false)
    handleAddSection(data)
  }

  const { allEnabledQuestions } = useFormLogic()

  return (
    <div className="mt-8 flex flex-col gap-8">
      <TableContainer>
        <ProductSectionTableHeader />
        <Table>
          {productSections?.map((p, index) => {
            const offerReferenceKey = getOfferReference(p.__uuid)

            const defaultValues = {
              quantity: p?.fields.find((f) => f.key === 'prod-quantity')?.values[0].value as number || 0,

            }

            return (
              <ProductSectionTableMainRow
                key={p.__uuid}
                productSection={p}
                defaultOpen={defaultOpen && index === productSections.length - 1}
                actions={(
                  <Menu menuItems={[
                    { name: 'Delete', onClick: () => removeSection(p.__uuid) },
                  ]}
                  />
                )}
              >
                <TableRow className="">
                  <TableCell colSpan={4} className="border-t-2 border-sky-blue-light border-collapse py-8">
                    {p.fields.filter((f) => allEnabledQuestions.map((q) => q.__uuid).includes(f.__uuid)).map((question) => (
                      <QuestionContext
                        key={question.__uuid}
                        questionText={question.label || ''}
                        subtitle={question.subtitle || ''}
                        required={question.validation.required?.value}
                        questionHint={question.hint}
                        question={question}
                        ref={(item: HTMLDivElement) => { questionsRef.current[getQuestionIndex(question)] = item }}
                      >
                        <Question question={question} />
                      </QuestionContext>
                    ))}
                    <ProductSectionOffers defaultValues={defaultValues} offerReferenceKey={offerReferenceKey} />
                  </TableCell>
                </TableRow>
              </ProductSectionTableMainRow>
            )
          })}
        </Table>
      </TableContainer>
      <div>
        <AddButton
          onClick={handleAddSectionClick}
          label={t('products.add_product', 'Add product')}
        />
        <UniteSearch
          formSettings={submission?.__settings}
          onAdd={(data) => {
            handleAddSectionWithData(data)
          }}
        />
      </div>
    </div>
  )
}

export default ProductSections
