import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'
import usePermissions from '../../../core/hooks/usePermissions'
import { USER_VIEW } from '../../../shared/constants/permissions'
import CompanyAddresses from './addresses/CompanyAddresses'
import CompanyDocuments from './documents/CompanyDocuments'
import useIsCompanyScreenLoading from './documents/hooks/useIsCompanyScreenLoading'
import CompanyImageUpload from './Image/ImageUpload'
import CompanyUsers from './users/CompanyUsers'

const CompanyGeneralTab: React.FC = () => {
  const isLoading = useIsCompanyScreenLoading()

  const permissions = usePermissions([{ slug: USER_VIEW }])

  if (isLoading) return <CoreLoadingIcon />
  return (
    <div className="flex flex-col gap-8 w-full h-full items-center">
      <CompanyImageUpload />
      <CompanyAddresses />
      <CompanyDocuments />
      {permissions.get(USER_VIEW).allowed && <CompanyUsers />}
    </div>
  )
}

export default CompanyGeneralTab
