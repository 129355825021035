import { useState } from 'react'
import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'

interface IImage {
  alt: string
  src: string
  className?: string
  dataCy?: string
}
const Image: React.FC<IImage> = ({
  src, alt, className, dataCy,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      <div
        className={`flex items-center justify-center ${!isLoading && 'hidden'} ${className}`}
        data-cy={dataCy}
      >
        <CoreLoadingIcon />
      </div>
      <img
        alt={alt}
        src={src}
        className={`${isLoading && 'hidden'} ${className}`}
        data-cy={dataCy}
        onLoad={() => { setIsLoading(false) }}
      />
    </>
  )
}
export default Image
