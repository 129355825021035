import { DocumentIcon, PlusIcon } from '@heroicons/react/24/solid'
import { FC } from 'react'
import Button from '../../atoms/buttons/Button'

export interface IEmptyState {
  onClick?: () => void
  icon?: React.ReactNode
  message?: string
  buttonMessage?: string
}

const EmptyState: FC<IEmptyState> = ({
  onClick,
  icon = <DocumentIcon className="h-7" />,
  message = 'Nothing here yet', buttonMessage = 'Add items',
}) => (
  <div className="w-full h-auto flex flex-col items-center justify-center bg-white p-8 space-y-4 shadow-sm">
    <div className="text-sky-blue-light">{icon}</div>
    <div className="text-mountain-gray text-sm">{message}</div>
    {onClick && (
      <Button onClick={onClick} className="btn-secondary hover:bg-sky-blue transition-colors">
        <PlusIcon className="h-5 " />
        <span>{buttonMessage}</span>
      </Button>
    )}

  </div>
)

export default EmptyState
