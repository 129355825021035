import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { useUtils } from '../../../../../core/providers/UtilsProvider'

const Title: React.FC = () => {
  const { t } = useTranslation()

  const { modal: { get: { title }, set } } = useUtils()

  const onClose = () => set({ isOpen: false })

  const titleIsNode = typeof title !== 'string'

  return (
    <div className="flex relative justify-between px-6 text-lg">
      {!titleIsNode ? t(title) : title}
      <button
        type="button"
        onClick={onClose}
        data-cy="close-modal-button"
        className="absolute right-6 top-2"
      >
        <MdClose className="text-gray-400 text-2xl" />
      </button>
    </div>
  )
}

export default Title
