import { useTranslation } from 'react-i18next'
import SectionHeader from '../../../shared/components/layout/SectionHeader'
import AllTags from '../SupplierDetailsPage/AllTags'

interface ITagsDetailsBody { editable?: boolean }

const TagsDetailsBody: React.FC<ITagsDetailsBody> = ({ editable }) => {
  const { t } = useTranslation()

  return (
    <div className="p-7 overflow-auto">
      <div className="bg-white rounded-lg">
        <SectionHeader title={t('suppliers.tags')} />
        <AllTags editable={editable} />
      </div>
    </div>
  )
}

export default TagsDetailsBody
