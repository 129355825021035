import { useAbility } from '@casl/react'
import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { ABILITIES } from '../../../config/ability'
import { AbilityContext } from '../../../core/providers/AbilityProvider'
import Button from '../../../shared/components/atoms/buttons/Button'

interface IAddUserButton {
  showNewUserForm: boolean
  setShowNewUserForm: Dispatch<SetStateAction<boolean>>
}

const AddUserButton: React.FC<IAddUserButton> = ({
  showNewUserForm, setShowNewUserForm,
}) => {
  const ability = useAbility(AbilityContext)
  if (showNewUserForm) return null

  if (ability.cannot(ABILITIES.createSupplier)) return null

  return (
    <Button
      variant="solid"
      color="lhotse-orange"
      className="text-xs"
      onClick={() => setShowNewUserForm(!showNewUserForm)}
    >
      {t('account.add_user')}
    </Button>
  )
}

export default AddUserButton
