import { FC } from 'react'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import { FormSettingsType } from '../../../../../../types/Forms'
import UniteSearchModal from './UniteSearchModal'
import UniteLogo from './UniteLogo.png'
import { LoopSectionFieldValues } from '../../../hooks/useLoopSection'

interface IUniteSearch {
  formSettings?: FormSettingsType
  onAdd: (data: LoopSectionFieldValues) => void
}

const UniteModalTitle: FC = () => (
  <div className="flex gap-x-2 items-center">
    <img src={UniteLogo} className="h-6" alt="Unite Logo" />
  </div>
)

const UniteSearch: FC<IUniteSearch> = ({ formSettings, onAdd }) => {
  const { modal } = useUtils()

  if (!formSettings?.uniteEnabled) return null

  const handleSearchClick = () => {
    modal.set({
      isOpen: true,
      title: <UniteModalTitle />,
      content: <UniteSearchModal onAdd={onAdd} />,
      className: 'max-w-6xl w-screen ',
    })
  }

  return (
    <div className="">
      <AddButton label="Add Catalog product" onClick={handleSearchClick}>Search with Unite</AddButton>
    </div>
  )
}
export default UniteSearch
