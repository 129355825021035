import { useEffect, useState } from 'react'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import { WorkflowBuilderApproversModal, WorkflowBuilderApproversModalHeader } from '../WorkflowBuilderApproversModal'
import { useWorkflowBlockDraft } from '../../../../providers/WorkflowBlockDraftProvider'
import useWorkflowBlockDraftMutations from '../../../../hooks/useWorkflowBlockDraftMutations'
import { useWorkflow } from '../../../../providers/WorkflowProvider'

export default () => {
  const { modal } = useUtils()
  const { block } = useWorkflowBlockDraft()
  const { readonly } = useWorkflow()
  const { addApprover, removeApprover } = useWorkflowBlockDraftMutations()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) return

    modal.update({
      content: <WorkflowBuilderApproversModal
        block={block}
        addApprover={addApprover}
        removeApprover={removeApprover}
        readonly={readonly}
      />,
    })
  }, [block, isOpen])

  return () => {
    setIsOpen(true)
    modal.set(
      {
        isOpen: true,
        title: <WorkflowBuilderApproversModalHeader />,
        content: <WorkflowBuilderApproversModal block={block} addApprover={addApprover} removeApprover={removeApprover} readonly={readonly} />,
        className: 'max-w-[500px]',
        onClose: () => {
          setIsOpen(false)
        },
      },
    )
  }
}
