import dayjs from 'dayjs'
import { AddressQuestionValue, FormQuestionValue } from '../../../../../../../types/Forms'
import { FormLogicQuestionType } from './checkIfAnswerFulfillsRequirement'
import { getUsesUUIDs } from './constants'

export default (
  question: FormLogicQuestionType,
  questionAnswers: FormQuestionValue[],
  questionLogic: string | number,
) => {
  const usesUUIDs = getUsesUUIDs(question)

  if (usesUUIDs) {
    return questionAnswers[0]?.__uuid === questionLogic
  }

  if (question.questionType === 'DATE') {
    return dayjs(questionAnswers[0]?.value as string).isSame(questionLogic)
  }

  if (question.questionType === 'ADDRESS') return (questionAnswers[0]?.value as AddressQuestionValue).id === questionLogic

  return questionAnswers[0]?.value === questionLogic
}
