import { useTranslation } from 'react-i18next'
import ErrorPage from '../ErrorPage'

const SomethingWentWrong = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage
      header="Oops!"
      title={t('error.something_went_wrong.title')}
    />
  )
}

export default SomethingWentWrong
