import { FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import useQuestionValues from '../hooks/useQuestionValues'
import SupplierBranchDataType from '../../../../types/Suppliers/SupplierBranchDataType'
import { FormEvents } from '../Questions'
import useIsQuestionDisabled from './hooks/useIsQuestionDisabled'
import SupplierField from '../../components/fields/SupplierField'
import useSynchronizedFormUpdate from '../hooks/useSynchronizedFormUpdate'

interface ISupplierQuestion {
  question: FormQuestionType
}

const SupplierQuestion: React.FC<ISupplierQuestion> = ({
  question,
}) => {
  const [questionValues, setQuestionValues] = useQuestionValues(question)
  const isDisabled = useIsQuestionDisabled(question)
  const setSelectedValue = useSynchronizedFormUpdate((updatedValue: FormQuestionValue) => {
    setQuestionValues([updatedValue])
  })

  const handleAdd = (supplier: SupplierBranchDataType) => {
    setQuestionValues([{
      __uuid: supplier.id.toString(),
      value: supplier.name,
      label: question.question_label,
    }])
  }

  const handleSelection = (updatedValue: FormQuestionValue) => {
    setSelectedValue(updatedValue)
    FormEvents.QuestionAnswered.dispatch(question.__uuid)
  }

  return (
    <div className="flex gap-x-2 relative">
      <div className="w-full">
        <SupplierField
          disabled={isDisabled}
          onAdd={handleAdd}
          onSelect={handleSelection}
          selected={questionValues[0]}
          className="text-xl py-4 px-4"
        />
      </div>
    </div>
  )
}

export default SupplierQuestion
