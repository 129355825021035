import { useTranslation } from 'react-i18next'
import { FormQuestionNumberOfChoiceSettingType, FormQuestionContentType } from '../../../../types/Forms/formTypes'

interface IDefinedChoiceSelectionInfo {
  choiceSelectionSetting: FormQuestionNumberOfChoiceSettingType | undefined
  questionType: FormQuestionContentType
}

const DefinedChoiceSelectionInfo: React.FC<IDefinedChoiceSelectionInfo> = ({
  choiceSelectionSetting, questionType,
}) => {
  const { t } = useTranslation()

  const numberOfChoicesValue = choiceSelectionSetting?.value?.[0]
  const getText = () => {
    const translation = ['TEXT', 'TEXT_AREA'].includes(questionType)
      ? t('forms.add_up_to_x_values')
      : t('forms.select_up_to_x_choices')

    return translation?.replace('{{VARIABLE1}}', String(numberOfChoicesValue))
  }

  if (choiceSelectionSetting?.type !== 'defined' || numberOfChoicesValue === 1) return null

  return <p className="text-sm text-nds-primary-gray">{getText()}</p>
}

export default DefinedChoiceSelectionInfo
