import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Label from '../../../../../../shared/components/atoms/Label'
import AddButton from '../../../../../../shared/components/atoms/buttons/AddButton'
import DeleteButton from '../../../../../../shared/components/atoms/buttons/DeleteButton'
import { LineItemItemAttributeType } from '../../../../../../types/shared/LineItemDataTypes'
import { ReqDefEditorSectionType } from '../../constants/editorTypes'
import useDeleteAttribute from '../../hooks/useDeleteAttribute'
import usePostNewAttribute from '../../hooks/usePostNewAttribute'
import ProductDetailsRequirementsItem from './ProductDetailsRequirementsItem'

export interface IProductDetailsRequirements {
  section: ReqDefEditorSectionType
  attributes?: Omit<LineItemItemAttributeType, 'line_items'>[]
  disabled?: boolean
}

const ProductDetailsRequirements: FC<IProductDetailsRequirements> = ({ attributes, section, disabled }) => {
  const { t } = useTranslation()
  const productAttributes = attributes?.filter(({ type }) => type === 'attribute')

  const addRequirement = usePostNewAttribute()
  const { mutate: deleteAttribute } = useDeleteAttribute()

  return (
    <div className="flex-col flex space-y-2">
      <Label label={t('req.product-requirements', 'Product Requirements')} />

      {productAttributes?.map((attribute) => (
        <div key={attribute.id} className="flex space-x-2 items-center">
          <ProductDetailsRequirementsItem attribute={attribute} disabled={disabled} />
          {!disabled && (
            <DeleteButton deleteFunction={() => deleteAttribute(attribute.id)} />
          )}
        </div>
      ))}
      {!disabled
        ? <AddButton label={t('req.add_requirement', 'Add requirement')} onClick={() => addRequirement(section.id)} />
        : null}
    </div>
  )
}

export default ProductDetailsRequirements
