import QuestionInputWrapper from '../QuestionInputWrapper'
import InputTextGray, {
  IInputTextGray,
} from '../../../../../../shared/components/atoms/inputs/InputTextGray/InputTextGray'

export const QuestionInputTextGray = (props: IInputTextGray) => (
  <QuestionInputWrapper>
    {(inputProps) => (
      <InputTextGray
        {...inputProps}
        {...props}
      />
    )}
  </QuestionInputWrapper>
)

export default QuestionInputTextGray
