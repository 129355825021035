const approvals = {
  coordinate: {
    url: 'execute',
    statues: ['ANALYZED'],
  },
  award: {
    url: 'award',
    statues: ['CLOSED'],
  },
}
export default approvals
