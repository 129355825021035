import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../../../../../core/components/CoreLoadingIcon'
import NoResults from '../NoResults'

import CardTable from '../../../../../../../shared/components/atoms/CardTable/CardTable'
import SupplierRow from '../NewSupplierRow/NewSupplierRow'
import useGetCoordinateSearch from './hooks/useGetCoordinateSearch'
import useGetFilteredCoordinateSupplierUsers from './hooks/useGetFilteredCoordinateSupplierUsers'

import AddSupplierBtn from '../NewSupplierRow/AddSupplierBtn/AddSupplierBtn'

const SearchResults: React.FC = () => {
  const { t } = useTranslation()
  const { data: searchSuppliersData, isLoading } = useGetCoordinateSearch()

  const searchRows = useGetFilteredCoordinateSupplierUsers(searchSuppliersData?.data, false)
  const tableColumns = [
    t('coordinate.supplier_name', 'Supplier Name'),
    t('coordinate.contact_name', 'Contact name'),
    t('coordinate.tags', 'Tags'),
    t('coordinate.status', 'Status'),
    t('coordinate.action', 'Action'),
  ]

  let tableContents
  if (isLoading) {
    tableContents = <td colSpan={tableColumns.length} className="h-48"><CoreLoadingIcon /></td>
  }

  if (!isLoading && !searchRows?.length) {
    tableContents = <td colSpan={tableColumns.length}><NoResults /></td>
  }

  return (
    <>
      <CardTable columns={tableColumns}>
        {_(searchRows)
          .orderBy(['supplier_branch.preferred', 'deleted_at'], ['desc', 'desc'])
          .map((supplierUser) => (
            <SupplierRow
              showSelect={false}
              key={`${supplierUser.id}-supplierUser`}
              supplierUser={supplierUser}
              actionComponent={<AddSupplierBtn supplierUser={supplierUser} />}
            />
          )).value()}
        {tableContents}
      </CardTable>

      {/* {_(searchRows) */}
      {/*  .orderBy(['supplier_branch.preferred', 'deleted_at'], ['desc', 'desc']) */}
      {/*  .map((supplierUser) => ( */}
      {/*    <CoordinateSupplierRow */}
      {/*      key={`${supplierUser.id}-supplierUser`} */}
      {/*      supplierUser={supplierUser} */}
      {/*    /> */}
      {/*  )).value()} */}
    </>

  )
}

export default SearchResults
