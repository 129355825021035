import { useTranslation } from 'react-i18next'
import ButtonWithDropdown from '../../../../../shared/components/organisms/ButtonWithDropdown/ButtonWithDropdown'
import { FormSectionCategoryType } from '../../../../../types/Forms/formTypes'
import useGetFormData from '../../../hooks/useGetFormData'
import useUpdateForm from '../../hooks/useUpdateForm'
import useGetFormSections from '../../../hooks/useGetFormSections'
import uuid from '../../../../../core/utils/uuid'

const AddQuestionGroupButton = () => {
  const { t } = useTranslation()
  const { updateForm } = useUpdateForm()
  const { data: formData } = useGetFormData()
  const { canAdd } = useGetFormSections()

  const createNewQuestionGroup = (sectionType: FormSectionCategoryType) => {
    const newSection = formData?.template.section_types
      ?.find((builderSectionType) => builderSectionType.type === sectionType)

    if (!newSection || !formData || !canAdd(sectionType)) return

    updateForm({
      template: {
        sections: [
          ...(formData?.template?.sections || []),
          { ...newSection, __uuid: uuid(), name: '' },
        ],
      },
    })
  }

  const sectionTypes = formData?.template.section_types?.map(({ type }) => ({
    name: `forms.${type.toLowerCase()}_section`,
    onClick: () => createNewQuestionGroup(type),
    disabled: !canAdd(type),
  })) || []

  return (
    <ButtonWithDropdown
      text={t('forms.add_question_group')}
      options={sectionTypes}
    />
  )
}

export default AddQuestionGroupButton
