// REFACTOR
import { Form, Formik } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import TextInput from '../../../../../shared/components/organisms/formik/FormikTextInput'
import useGetFindTenantValidationSchema from '../hooks/useGetFindTenantValidationSchema'
import useHandleValidSubmit from '../hooks/useHandleValidSubmit'

interface props {
  setLinks: Dispatch<SetStateAction<number[]>>
  setSubmitted: Dispatch<SetStateAction<boolean>>
}

const initialValues = { email: '' }

const FindTenantForm = ({
  setLinks, setSubmitted,
}: props) => {
  const { mutate: handleValidSubmit, isLoading } = useHandleValidSubmit(setLinks, setSubmitted)

  const validationSchema = useGetFindTenantValidationSchema()

  const { t } = useTranslation()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(v) => handleValidSubmit(v.email)}
      validateOnBlur={false}
    >
      {({
        errors, touched, values, handleChange, handleSubmit,
      }) => (
        <Form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
        >
          <div className="relative">
            <label htmlFor="email">
              <p className="py-2">{t('generic.email')}</p>
              <TextInput
                type="email"
                name="email"
                handleChange={handleChange}
                placeholder="Email"
                values={values}
                inputClassName={`rounded-md border-gray-300 placeholder-gray-400 p-3 text-sm ${errors.email && 'border-red-500'}`}
                error={errors.email && touched.email && (<ErrorMessage error={errors.email} />)}
                dataCy="loginEmail"
                handleOnEnter={handleSubmit}
              />
              <div className="absolute h-8 bg-orange-600 w-1 rounded-r-xl" style={{ top: 47 }} />
            </label>
          </div>
          <div className="relative flex justify-end pt-5">
            <Button
              variant="solid"
              color="lhotse-orange"
              id="login"
              type="submit"
              onClick={(e) => handleSubmit(e)}
              loading={isLoading}
            >
              {t('generic.submit')}
            </Button>
          </div>

        </Form>
      )}
    </Formik>
  )
}

export default FindTenantForm
