import { RequisitionFilterType, RequisitionFilterValueType } from '../types'
import Calendar from '../../../../../../shared/components/organisms/Calendar/Calendar'
import RequisitionFilterBadge from './RequisitionFilterBadge'
import { useRequisitionFilters } from '../provider/RequisitionFiltersProvider'
import { fromApiDate, toApiDate } from '../hooks/useRequistionFilterValues'

type RequisitionFilterDateSelectProps = {
  filter: RequisitionFilterType
  value: RequisitionFilterValueType
}
const RequisitionsFilterCustomDateInput = ({ filter, value }: RequisitionFilterDateSelectProps) => {
  const { setFilterValue } = useRequisitionFilters()
  const { apiValue } = value
  const [fromDate, toDate] = Array.isArray(apiValue) ? apiValue : []

  const updateFromDate = (newFromDate: Date | null) => {
    setFilterValue(filter, { ...value, apiValue: [toApiDate(newFromDate), toDate] })
  }

  const updateToDate = (newToDate: Date | null) => {
    setFilterValue(filter, { ...value, apiValue: [fromDate, toApiDate(newToDate)] })
  }

  return (
    <>
      <RequisitionFilterBadge value={value} filter={filter} onRemove={() => updateFromDate(null)}>
        <Calendar
          className="placeholder-ice-blue !p-0 !text-center max-w-[5.4rem] bg-sky-blue-light !text-ice-blue"
          dateFormat="dd.MM.yyyy"
          showTimeSelect={false}
          selected={fromApiDate(fromDate)}
          onChange={updateFromDate}
        />
      </RequisitionFilterBadge>
      <div className="text-sky-blue-light"> - </div>
      <RequisitionFilterBadge value={value} filter={filter} onRemove={() => updateToDate(null)}>
        <Calendar
          className="placeholder-ice-blue !p-0 !text-center max-w-[5.4rem] bg-sky-blue-light !text-ice-blue"
          dateFormat="dd.MM.yyyy"
          showTimeSelect={false}
          selected={fromApiDate(toDate)}
          onChange={updateToDate}
        />
      </RequisitionFilterBadge>
    </>
  )
}

export default RequisitionsFilterCustomDateInput
