import { MdUploadFile } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import FileDropzone from '../../../../../../shared/components/organisms/FileDropzone/FileDropzone'
import toastr from '../../../../../../shared/components/organisms/toastr/toastr'
import toBase64 from '../../../../../../shared/utils/toBase64'
import useGetFormData from '../../../../hooks/useGetFormData'
import useUpdateForm from '../../../hooks/useUpdateForm'

const ThumbnailDropzone = () => {
  const { t } = useTranslation()
  const { updateForm } = useUpdateForm()
  const { data: companyForm } = useGetFormData()

  const onFileUpload = async (files: File[]) => {
    if (!files || !companyForm) return

    try {
      const base64 = await toBase64(files[0])
      if (typeof base64 !== 'string') return

      updateForm({ thumbnail: { name: files[0].name, content: base64, url: '' } })
    } catch {
      toastr.error()
    }
  }

  return (
    <div className="max-w-xl w-full">
      <FileDropzone onFileUpload={onFileUpload}>
        <div className="flex flex-col w-full items-center justify-center text-center py-[14px] cursor-pointer font-inter gap-y-5">
          <MdUploadFile className="text-vibrant-blue" size={30} />
          <div className="flex flex-col gap-y-2">
            <p className="text-lg text-primary-gray">
              <span className="underline">{t('forms.choose_file')}</span>
              {' '}
              or drag here
            </p>
            <p className="text-primary-gray text-sm">{t('forms.upload_limit')}</p>
            <p className="text-primary-gray text-sm">{t('forms.supported_files')}</p>
          </div>
        </div>
      </FileDropzone>
    </div>
  )
}

export default ThumbnailDropzone
