import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CoreLoadingIcon from '../../../../../../../../../../core/components/CoreLoadingIcon'
import { CATALOG_ITEM } from '../../../../../../constants/newReqQueryStrings'
import { getCatalogItem } from '../../../../../../services/UnifiedSearchService'

const CatalogPiecesPerOrder: React.FC<{ catalogId: string }> = ({ catalogId }) => {
  const { data } = useQuery([CATALOG_ITEM, catalogId], () => getCatalogItem(catalogId))
  const { t } = useTranslation()

  if (!data) return <CoreLoadingIcon />

  return (
    <p>
      {t('catalog.pieces_per_order')}
      :
      {' '}
      {data.orderDetails.numberOfContentUnitsPerOrderUnit.value}
    </p>
  )
}
export default CatalogPiecesPerOrder
