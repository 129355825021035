import { useTranslation } from 'react-i18next'
import CoreLoadingIcon from '../../../core/components/CoreLoadingIcon'
import { useMessengerContext } from '../../../core/providers/MessengerProvider'
import useGetChats from '../hooks/useGetChatPreviews'
import ChatsColumn from './ChatsColumn/ChatsColumn'
import MessagingContainer from './MessagingContainer/MessagingContainer'

const MessageClient = () => {
  const { t } = useTranslation()

  const { isLoading: isLoadingChats } = useGetChats()
  const { chatState: [chatState] } = useMessengerContext()

  if (isLoadingChats) return <CoreLoadingIcon />

  return (
    <div className="flex h-full flex-col divide-y bg-white rounded-lg border overflow-y-hidden" id="message-client">
      <p className="py-4 px-4">{`${t('generic.q_and_a')} - ${chatState?.chatTitle || ''}`}</p>
      <div className="flex h-full divide-x" id="message-client-inner">
        <ChatsColumn />
        <MessagingContainer />
      </div>
    </div>
  )
}

export default MessageClient
