import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import Assignees from '../../../../../shared/components/organisms/AssigneesSelector/AssigneesSelector'
import Menu from '../../../../../shared/components/organisms/menus/Menu'
import ConfirmationModal from '../../../../../shared/components/organisms/modals/ConfirmationModal'
import { apiPostDuplicateSubmission } from '../../../../Forms/constants/formService'
import useDuplicateRequisition from '../../../../Requisition/hooks/useDuplicateRequisition'
import useDeleteRequisition from '../../../hooks/useDeleteRequisition'
import RequisitionRowType from '../../../../../types/Basecamp/RequisitionRowType'

interface IActions {
  data: RequisitionRowType
}

const Actions: React.FC<IActions> = ({ data }) => {
  const { t } = useTranslation()
  const utils = useUtils()

  const { mutate: deleteMutate } = useDeleteRequisition()
  const history = useHistory()

  const { mutate: duplicateSubmission } = useMutation(
    (formId: string) => apiPostDuplicateSubmission(formId),
    { onSuccess: ({ form_uuid, form_submission_uuid }) => history.push(`/forms/${form_uuid}/submission/${form_submission_uuid}`) },
  )

  const duplicateRequisition = useDuplicateRequisition()

  const menuItems = [
    {
      name: 'generic.archive',
      onClick: () => utils.modal.set({
        isOpen: true,
        title: t('generic.confirm'),
        content: (
          <ConfirmationModal
            confirmString={t('confirm.are_you_sure_delete')}
            onConfirm={() => deleteMutate(data.id)}
          >
            {t('basecamp.are_you_sure_delete_requisition')}
          </ConfirmationModal>),
      }),
    },
    {
      name: 'generic.duplicate',
      onClick: () => (data.form_submission?.uuid
        ? duplicateSubmission(data.form_submission.uuid) : duplicateRequisition(data.id)),
    },
  ]

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      role="button"
      className="block"
      tabIndex={0}
    >
      <div className="flex items-center justify-end">
        <Assignees
          origin="requisition"
          modelId={data.id}
          assignments={data.assignments}
        />
        <Menu menuItems={menuItems} />
      </div>
    </div>
  )
}
export default Actions
