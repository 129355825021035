import { useMutation, useQueryClient } from 'react-query'
import { useUtils } from '../../../../core/providers/UtilsProvider'
import companyQueryStrings from '../../constants/companyQueryStrings'
import { apiPostSendInviteEmailToCompanyUser } from '../../services/CompanyService'
import UserInviteType from '../../../../types/Company/UserTypes'
import UserForm from './UsersForm'

const CompanyUsersNew = () => {
  const cache = useQueryClient()
  const { modal } = useUtils()
  const { mutate: postMutateInviteUser } = useMutation(
    (item: UserInviteType) => apiPostSendInviteEmailToCompanyUser(item),
    {
      onSuccess: (newData) => cache.setQueryData(
        [companyQueryStrings.company_addresses],
        () => {
          modal.set({ isOpen: false })
          return newData.data
        },
      ),
    },
  )

  return (
    <UserForm
      hideForm={() => modal.set({ isOpen: false })}
      onSubmit={postMutateInviteUser}
    />
  )
}

export default CompanyUsersNew
