import React, { FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import Collapsible from '../../../../../../shared/components/atoms/Collapsible/Collapsible'
import Table from '../../../../../../shared/components/atoms/SharedTable/Table'
import TableCell from '../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableContainer from '../../../../../../shared/components/atoms/SharedTable/TableContainer'
import TableHead from '../../../../../../shared/components/atoms/SharedTable/TableHead'
import TableHeader from '../../../../../../shared/components/atoms/SharedTable/TableHeader'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'
import { MetaDataArrayType, MetaDataType } from '../../../../../../types/shared/RequisitionDataTypes'

interface IMetaTable {
  metaData?: MetaDataType[]
}

const DisplayArrayValue = ({ meta }: { meta: MetaDataArrayType[] }) => <div>{meta[0]?.value}</div>

const renderMetaArrayValue = (meta: MetaDataArrayType[]) => (
  <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => (
    <div role="alert">
      <pre>Error</pre>
    </div>
  )}
  >
    <DisplayArrayValue meta={meta} />
  </ErrorBoundary>
)
const renderMetaKey = (metaKey: string) => (
  <div className="capitalize">
    {metaKey
      .replaceAll('-', ' ').replaceAll('_', ' ')}
  </div>
)

const MetaTable: FC<IMetaTable> = ({ metaData }) => {
  const { t } = useTranslation()

  return (
    <Collapsible title={t('req.meta', 'Meta')} classNames="text-sm text-rock-gray" defaultOpen={false}>
      <div className="max-w-2xl">
        <TableContainer>
          <TableHead>
            <TableHeader>{t('generic.name')}</TableHeader>
            <TableHeader>{t('generic.type')}</TableHeader>
          </TableHead>
          <Table>
            {metaData?.map((meta) => (
              <TableRow key={meta.id}>
                <TableCell>{renderMetaKey(meta.key)}</TableCell>
                <TableCell>
                  <div>
                    {meta.type === 'array'
                      ? renderMetaArrayValue(meta.value as MetaDataArrayType[]) : meta.value}
                  </div>

                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </div>
    </Collapsible>
  )
}

export default MetaTable
