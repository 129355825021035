import { useHistory } from 'react-router'

const useGetNavigationActions = () => {
  const history = useHistory()
  return [
    {
      id: 1, name: 'Basecamp', func: () => history.push('/'),
    },
    {
      id: 2, name: 'Settings', func: () => history.push('/settings'),
    },
  ]
}

export default useGetNavigationActions
