import { useQueryClient } from 'react-query'
import { OfferDataType } from '../../../types/shared/OfferDataTypes'
import { germanPriceStringToFloat } from '../../../shared/constants/currencyFormat'
import queryKeys from '../constants/queryKeys'

export default () => {
  const cache = useQueryClient()
  return (
    value: string | number | null,
    offerKey: string,
    isNumber?: boolean,
  ) => {
    const parsedValue = (isNumber && typeof value === 'string') ? (germanPriceStringToFloat(value ?? '')) : value

    cache.setQueryData<OfferDataType | undefined>(
      [queryKeys.offer],
      (oldData: OfferDataType | undefined) => (
        oldData
          ? ({
            ...oldData,
            offer: { ...oldData.offer, [offerKey]: parsedValue },
          })
          : oldData),
    )
  }
}
