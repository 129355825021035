import WorkflowBuilderTriggers from '../WorkflowBuilderTriggers/WorkflowBuilderTriggers'
import WorkflowBuilderBlockApprovals from '../WorkflowBuilderApprovals/WorkflowBuilderBlockApprovals'
import WorkflowBuilderBlockControls from './WorkflowBuilderBlockControls'
import WorkflowBuilderBlockMisc from './WorkflowBuilderBlockMisc'
import SidepanelTabTransition from '../../../../../shared/components/layout/SidepanelTabTransition'

export const WorkflowBuilderSidepanelBlockDetails = () => (
  <SidepanelTabTransition>
    <div className="flex flex-col gap-11 ">
      <WorkflowBuilderBlockMisc />
      <WorkflowBuilderTriggers />
      <WorkflowBuilderBlockApprovals />
      <WorkflowBuilderBlockControls />
    </div>
  </SidepanelTabTransition>
)

export default WorkflowBuilderSidepanelBlockDetails
