import { MdOutlineCancel } from 'react-icons/md'
import { FormQuestionType, FormQuestionValue } from '../../../../types/Forms'
import useQuestionValues from '../hooks/useQuestionValues'
import AddAnswerButton from './atoms/AddAnswerButton'
import FormInput from './atoms/FormInput'
import useQuestionErrorMessage from '../hooks/useQuestionErrorMessage'
import { replaceByUuid, getUpdatedByUuid } from '../../../../shared/utils/arrayHelpers'
import QuestionError from './atoms/QuestionError'

interface ILongQuestion {
  question: FormQuestionType
}

type KeyValueRowProps = { question: FormQuestionType, index: number, answer: FormQuestionValue, visibleKey?: boolean }

// todo should be removed
const KeyValueRow = ({
  question, index, answer, visibleKey = true,
}: KeyValueRowProps) => {
  const [questionValues, updateQuestionValues] = useQuestionValues(question)
  const error = useQuestionErrorMessage(question)

  const update = (labelOrValue: { value?: string, label?: string }) => {
    updateQuestionValues(getUpdatedByUuid(questionValues, [
      {
        __uuid: answer.__uuid,
        ...labelOrValue,
      },
    ]))
  }

  const remove = () => {
    updateQuestionValues(replaceByUuid(questionValues, answer.__uuid))
  }

  if (answer.value && typeof answer.value !== 'string') return null

  return (
    <div className="flex flex-row gap-x-2 items-center justify-center">
      {visibleKey && (
        <FormInput
          shortResponse
          characterLimit={question.validation.max_characters?.value}
          value={answer.label || ''}
          onChange={(newKey) => update({
            label: newKey,
          })}
          wrapperClassName="flex-grow"
          invalid={!!error}
          disabled={question.__settings?.labels?.readonly}
        />
      )}
      <FormInput
        shortResponse
        characterLimit={question.validation.max_characters?.value}
        value={answer.value?.toString() || ''}
        onChange={(newValue) => update({
          value: newValue,
        })}
        wrapperClassName="flex-grow"
        invalid={!!error}
      />
      {!question.__settings?.labels?.readonly && visibleKey && (
        <button
          type="button"
          tabIndex={-1}
          className="flex justify-center items-center"
          onClick={remove}
        >
          <MdOutlineCancel className="w-5 h-5" />
        </button>
      )}
    </div>
  )
}

export const KeyValuesQuestion: React.FC<ILongQuestion> = ({
  question,
}) => {
  const [questionValues] = useQuestionValues(question)

  const canAddMore = !question?.validation.max_values?.value || questionValues.length < question.validation.max_values.value

  return (
    <div className="flex flex-col gap-y-4">
      {questionValues.map((answer, index) => (
        <KeyValueRow key={`${question.__uuid}-${answer.__uuid}`} question={question} answer={answer} index={index} visibleKey={questionValues.length > 1} />
      ))}
      <QuestionError question={question} />
      {canAddMore && <AddAnswerButton question={question} />}
    </div>
  )
}

export default KeyValuesQuestion
