import _ from 'lodash'
import { useMemo } from 'react'
import { MercateoBlockPriceType } from '../../../../../../../../../../types/Requisition/MercateoItemType'
import calculateDecimalDigits from '../../../../../../utils/Catalog/calculateDecimalDigits'
import calculateMercateoPrice from '../../../../../../utils/Catalog/calculateMercateoPrice'
import currencySymbol from '../../../../../../utils/currencySymbol'

interface ICatalogBlockPricingPrice {
  price: MercateoBlockPriceType
  highlight?: boolean
  disabled?: boolean
}

const CatalogBlockPricingPrice: React.FC<ICatalogBlockPricingPrice> = (
  { price, highlight, disabled },
) => {
  const amount = useMemo(() => _.floor(calculateMercateoPrice(price.amount)), [price.amount])
  const decimalDigits = useMemo(() => calculateDecimalDigits(price.amount), [price.amount])

  return (
    <div className={`flex items-start gap-0.5 
  ${disabled && 'line-through text-gray-500'} 
  ${highlight && 'text-orange-500'}`}
    >
      <p className="pt-1 text-xs">{currencySymbol[price.currency]}</p>
      <p className="text-xl">{amount}</p>
      <p className="pt-1 text-xs">{decimalDigits}</p>
    </div>
  )
}

export default CatalogBlockPricingPrice
