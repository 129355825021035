import { useEffect, useState } from 'react'
import LANGUAGES from '../../shared/constants/languages'
import useSwitchLanguage from '../Settings/hooks/useSwitchLanguage'
import useGetOffer from './hooks/useGetOffer'
import useGetToken from './hooks/useGetToken'
import { invalidIdStateType, validateForm } from './utils/validationUtils'
import ActionPanel from './components/ActionPanel/ActionPanel'
import Details from './components/Details'
import NDAForm from './components/NDAScreen/NDAForm'
import Footer from './components/Footer/Footer'
import useGetSectionsOrLineItems from './components/DescriptionSection/Sections/hooks/useGetSectionsOrLineItems'
import { getRequisitionSections } from '../Requisition/subModules/Definition/services/editorService'
import { getLineItemData } from './services/services'

const SupplierOffer: React.FC<{ requisitionId?: number }> = ({ requisitionId }) => {
  const token = useGetToken()

  const [invalidIds, setInvalidIds] = useState<invalidIdStateType>({})

  const { data: offer } = useGetOffer()
  const { data: sectionData } = useGetSectionsOrLineItems({ enabled: !!token })

  useEffect(() => {
    const x = async () => {
      let requisitionSection = null
      if (requisitionId) requisitionSection = await getRequisitionSections(JSON.stringify(requisitionId))
      const lineItemData = await getLineItemData(token)
    }
    if (token) x()
  }, [token])
  const switchLanguage = useSwitchLanguage()

  useEffect(() => {
    if (!localStorage.language) {
      switchLanguage(LANGUAGES.GERMAN)
    }
  }, [])

  if (offer === undefined && token) {
    return null
  }

  return (offer && !offer?.nda_accepted)
    ? <NDAForm />
    : (
      <div className={`flex flex-col bg-gray-200 bg-opacity-60 ${requisitionId ? 'pointer-events-none pb-4' : 'min-h-screen justify-between'}`}>
        <div className={`gap-y-6 flex bg-gray-100 flex-grow ${requisitionId && 'px-4'}`}>
          <Details requisitionId={requisitionId} invalidIds={invalidIds} />
          <ActionPanel />
        </div>
        {token && sectionData && <Footer validateForm={() => validateForm(sectionData, offer, setInvalidIds)} />}
      </div>
    )
}

export default SupplierOffer
