import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../../../../../shared/components/atoms/SharedTable/Table'
import TableContainer from '../../../../../../shared/components/atoms/SharedTable/TableContainer'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import { IntakeFieldType } from '../../../../../../types/Intake'
import { Offering } from '../../../../../../types/Offering'
import FormField from '../../../../../Forms-v2/FormField'
import ProductSectionOffersAddButton from '../../../../../Forms/FormResponsePage/sections/ProductSection/ProductSectionOffersAddButton'
import ProductSectionOffersTableHead from '../../../../../Forms/FormResponsePage/sections/ProductSection/ProductSectionOffersTable/ProductSectionOffersTableHead'
import ProductSectionOffersTableRow from '../../../../../Forms/FormResponsePage/sections/ProductSection/ProductSectionOffersTable/ProductSectionOffersTableRow'
import { OfferingField } from '../../../../../Offerings/types'
import useOfferingMutations from '../../../../hooks/useOfferingMutations'

interface IIntakeOfferingSectionForm {
  offering: Offering
  editModeOn?: boolean
}
const IntakeOfferingSectionForm: React.FC<IIntakeOfferingSectionForm> = ({ offering }) => {
  const [form, setForm] = useState<Offering>(offering)
  const { update } = useOfferingMutations()
  const [editModeOn, setEditModeOn] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleUpdateField = (field: IntakeFieldType) => {
    const newOffering = form.fields.map((f: OfferingField) => {
      if (f.id === field.id) {
        return field
      }
      return f
    }) as IntakeFieldType[]

    setForm({ ...form, fields: newOffering })
  }

  return (
    <div className="grid gap-8 grid-cols-1 xl:grid-cols-2">
      {
        offering.fields.map((field) => (
          <FormField key={field.id} field={field} readonly={!editModeOn} updateField={handleUpdateField} />
        ))
      }
      <div className="col-span-2">
        {editModeOn
          ? (
            <div className="flex gap-x-2">
              <Button className="btn-ghost" onClick={() => { setEditModeOn(false) }}>{t('generic.cancel', 'Cancel')}</Button>
              <Button className="btn-primary" onClick={() => { update.mutate(form); setEditModeOn(false) }}>
                {t('generic.save', 'Save')}
              </Button>
            </div>
          )
          : <Button className="btn-primary" onClick={() => setEditModeOn(true)}>{t('generic.edit', 'Edit')}</Button>}
      </div>
      <div>
        <TableContainer border={false}>
          <ProductSectionOffersTableHead />
          <Table>
            {offering.offers.map((offer) => <ProductSectionOffersTableRow key={offer.uuid} offer={offer} />)}
          </Table>
        </TableContainer>
      </div>
      <ProductSectionOffersAddButton defaultValues={{ offering_uuid: offering.uuid }} />
    </div>
  )
}

export default IntakeOfferingSectionForm
