import { useTranslation } from 'react-i18next'
import CheckableRequirement from '../../../../../../shared/components/atoms/buttons/validation/CheckableRequirement'
import useValidatePassword from './useValidatePassword'

interface IPasswordValidationInfo {
  password: string
  showErrors: boolean
}

const PasswordValidationInfo: React.FC<IPasswordValidationInfo> = ({ password, showErrors }) => {
  const { contains } = useValidatePassword(password)
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-2">
      <CheckableRequirement checked={password.length > 6} text={t('password.minimum_7')} showErrors={showErrors} />
      <CheckableRequirement checked={contains.length > 2} text={t('password.3_of_following')} showErrors={showErrors} />
      <div className="flex gap-x-3 ml-[21px]">
        <div className="flex flex-col gap-y-2">
          <CheckableRequirement checked={contains.includes('uppercase')} text={t('password.uppercase_letter')} showErrors={showErrors} />
          <CheckableRequirement checked={contains.includes('lowercase')} text={t('password.lowercase_letter')} showErrors={showErrors} />
        </div>
        <div className="flex flex-col gap-y-2">
          <CheckableRequirement checked={contains.includes('number')} text={t('password.number')} showErrors={showErrors} />
          <CheckableRequirement checked={contains.includes('symbol')} text={t('password.symbol')} showErrors={showErrors} />
        </div>
      </div>
    </div>
  )
}

export default PasswordValidationInfo
