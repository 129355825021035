import { useTranslation } from 'react-i18next'
import Button from '../../../../../shared/components/atoms/buttons/Button'
import useChangeOfferStatus from '../../../hooks/useChangeOfferStatus'
import useGetOffer from '../../../hooks/useGetOffer'

const DeclineToOfferButton: React.FC = () => {
  const { t } = useTranslation()
  const { data: offer } = useGetOffer()

  const changeOfferStatus = useChangeOfferStatus()

  const handleDeclineToOffer = () => {
    changeOfferStatus('OFFER_DECLINED_BY_SUPPLIER')
  }

  return !['OFFER_DECLINED_BY_SUPPLIER', 'OFFER_DECLINED_BY_CUSTOMER'].includes(offer?.status ?? '') ? (
    <Button
      variant="outlined"
      color="lhotse-orange"
      className="w-full flex h-full"
      onClick={handleDeclineToOffer}
    >
      <p className="whitespace-normal w-max">{t('supplier_view.offer_decline_to_offer')}</p>
    </Button>
  )
    : null
}

export default DeclineToOfferButton
