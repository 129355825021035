import useOpenSupplierDetailsModal from '../../../../SupplierDetails/hooks/useOpenSupplierDetailsModal'
import './advario-confirmation.css'

const ConfirmationSectionAdvarioSupplierInfoModalAction = ({ branchId }: { branchId?: number | undefined }) => {
  const openModal = useOpenSupplierDetailsModal(branchId)

  return (

    <button type="button" className="btn-secondary px-2 rounded-md py-1" onClick={openModal}>
      Details
    </button>

  )
}

export default ConfirmationSectionAdvarioSupplierInfoModalAction
