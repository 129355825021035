import { useTranslation } from 'react-i18next'
import Button from '../../../shared/components/atoms/buttons/Button'
import useIntakeMutations from '../hooks/useIntakeMutations'
import { useIntakeFlow } from '../providers/IntakeFlowProvider'

export const IntakeFlowDetailsActions = () => {
  const { editModeOn, toggleEditMode } = useIntakeFlow()
  const { t } = useTranslation()

  const { update: { mutate: updateIntake } } = useIntakeMutations()

  const handleUpdateIntake = () => {
    updateIntake()
    toggleEditMode()
  }

  if (!editModeOn) {
    return (
      <div className="flex items-center">
        <Button className="btn-ghost text-sky-blue-light !py-2" onClick={() => toggleEditMode(true)}>
          {t('general.click_to_edit', 'Click to edit')}
        </Button>
      </div>
    )
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <Button
        variant="outlined"
        onClick={() => toggleEditMode(false)}
      >
        {t('general.cancel')}
      </Button>
      <Button variant="solid" className="bg-sky-blue-light text-white" onClick={handleUpdateIntake}>
        {t('general.save')}
      </Button>
    </div>
  )
}

export default IntakeFlowDetailsActions
