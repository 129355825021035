import ErrorMessage from '../../../../../shared/components/atoms/ErrorMessage'
import TipTapEditor from '../../../../../shared/components/organisms/TipTapEditor/TipTapEditor'
import checkTiptapTextIsEmpty from '../../../../../shared/utils/checkTiptapTextIsEmpty'
import { CustomerDocumentType } from '../../../../../types/SupplierOffer/SupplierOfferDataTypes'
import { DocumentContentType } from '../../../../../types/Company/DocumentType'

interface IDocumentFormContentInput {
  documentForm: CustomerDocumentType | DocumentContentType
  document?: CustomerDocumentType
  formTouched: boolean
  editable: boolean
  onUpdate: (v: string) => void
}

const DocumentFormContentInput: React.FC<IDocumentFormContentInput> = ({
  document, documentForm, formTouched, editable, onUpdate,
}) => {
  const isContentError = !checkTiptapTextIsEmpty(documentForm.content)

  const msg = document?.content
  const text = msg && msg[0] === '{' ? JSON.parse(msg) : msg

  return (
    <>
      <TipTapEditor
        placeholder="generic.write_something"
        className="text-sm w-full h-96 relative border text-left border-gray-200 rounded-lg p-4"
        onUpdate={onUpdate}
        expandedEditor
        editable={editable}
        content={text}
        dataCy="document-content-input"
      />
      {formTouched && isContentError && <ErrorMessage error="generic.required" />}
    </>
  )
}

export default DocumentFormContentInput
