import { useEffect } from 'react'
import CompanyFormUnsavedChangesModal from '../../../../../shared/components/organisms/modals/CompanyFormUnsavedChangesModal'
import useRouterPromptModal from '../../../../../shared/components/organisms/modals/useRouterPromptModal'
import useHasUnsavedChanges from './useHasUnsavedChanges'

export default () => {
  const hasUnsavedChanges = useHasUnsavedChanges()

  useEffect(() => {
    const unsavedChangesHandler = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        const confirmationMessage = 'You have unsaved changes to this form. Any changes will be discarded if you navigate away from this page. Are you sure you want to discard changes to this form?';

        (e || window.event).returnValue = confirmationMessage
        return confirmationMessage
      }
      return null
    }

    window.addEventListener('beforeunload', unsavedChangesHandler)
    return () => window.removeEventListener('beforeunload', unsavedChangesHandler)
  }, [])

  useRouterPromptModal(hasUnsavedChanges, <CompanyFormUnsavedChangesModal />, 'You have unsaved changes!')
}
