import DeleteConfirmationButtons from './DeleteConfirmationButtons'

interface IDeleteConfirmationModal {
  handleDelete: () => void
  text: string
  deleteConfirmationText?: string
  leftNote?: string
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModal> = ({
  handleDelete, deleteConfirmationText, leftNote, text,
}) => (
  <div className="flex flex-col w-full">
    <div className="pt-4 text-sm text-secondary-gray" style={{ width: 'calc(100% - 30px)' }}>{text}</div>
    <div className={`flex items-center pt-4 ${leftNote ? 'justify-between' : 'justify-end'}`}>
      <p className="text-xs">{leftNote}</p>
      <DeleteConfirmationButtons
        handleDelete={handleDelete}
        deleteConfirmationText={deleteConfirmationText}
      />
    </div>
  </div>
)

export default DeleteConfirmationModal
