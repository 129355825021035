import SupplierRequisitionRow from './SupplierRequisitionRow'
import TableHeader from './TableHeader'
import useGetSupplierOffers from './useGetSupplierOffers'

interface ISupplierRequisitionsTable {
  supplierBranchId?: number
  rowLimit?: number
}

const SupplierRequisitionsTable: React.FC<ISupplierRequisitionsTable> = ({
  supplierBranchId, rowLimit,
}) => {
  const { data: supplierRequisitions } = useGetSupplierOffers(supplierBranchId)

  const isMinified = !!rowLimit

  const rows = rowLimit
    ? supplierRequisitions?.slice(0, rowLimit)
    : supplierRequisitions

  return (
    <div data-cy="supplier-req-table">
      <TableHeader isMinified={isMinified} />
      <div className="flex flex-col text-sm gap-y-2 pt-3 pb-2 max-h-[60vh] overflow-y-scroll overflow-overlay">
        {rows?.map((requisition) => (
          <SupplierRequisitionRow
            key={requisition.id}
            requisition={requisition}
            isMinified={isMinified}
          />
        ))}
      </div>
    </div>
  )
}

export default SupplierRequisitionsTable
