import { Dispatch, SetStateAction } from 'react'
import useGetReqCoordinateTableHeaderData from './useGetReqCoordinateTableHeaderData'
import ReqCoordinateSearch from './Search'
import ReqCoordinateTableHeaderSectionButton from './TableHeaderSectionButton'

interface ISourcingGuideHeader {
  activeSection: number
  setActiveSection: Dispatch<SetStateAction<number>>
}

const SourcingGuideHeader: React.FC<ISourcingGuideHeader> = ({
  activeSection, setActiveSection,
}) => {
  const coordinateTableHeaderData = useGetReqCoordinateTableHeaderData(setActiveSection)

  return (
    <div className="flex items-center justify-between w-full">
      {/* <div className="flex gap-x-8"> */}
      {/*  {coordinateTableHeaderData.map((header) => ( */}
      {/*    <ReqCoordinateTableHeaderSectionButton */}
      {/*      key={header.id} */}
      {/*      header={header} */}
      {/*      activeSection={activeSection} */}
      {/*      setActiveSection={setActiveSection} */}
      {/*    /> */}
      {/*  ))} */}
      {/* </div> */}
      <ReqCoordinateSearch activeSection={activeSection} />
    </div>
  )
}

export default SourcingGuideHeader
