import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../../../shared/components/atoms/inputs/Checkbox'
import { useCoordinateProvider } from '../../providers/CoordinateProvider'
import useMonitorSelectAllSuppliersToggle from './hooks/useMonitorSelectAllSuppliersToggle'

const SelectAllSuppliersToggle: React.FC = () => {
  const { t } = useTranslation()
  useMonitorSelectAllSuppliersToggle()

  const {
    toggleAll: [toggleAll, setToggleAll],
  } = useCoordinateProvider()

  return (
    <label htmlFor="select-all" className="flex gap-2">
      <Checkbox
        id="select-all"
        checked={toggleAll}
        onChange={(checked) => (checked ? setToggleAll(true) : setToggleAll(false))}
        dataCy="select-all-suppliers-coordinate"
      />
    </label>
  )
}

export default SelectAllSuppliersToggle
