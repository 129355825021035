import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import SectionHeader from '../../../shared/components/layout/SectionHeader'
import AddUserButton from './AddUserButton'

interface IHeader {
  showNewUserForm: boolean
  setShowNewUserForm: Dispatch<SetStateAction<boolean>>
}

const Header: React.FC<IHeader> = ({ showNewUserForm, setShowNewUserForm }) => {
  const { t } = useTranslation()

  return (
    <SectionHeader
      title={t('suppliers.supplier_users')}
      sectionButton={(
        <div className="flex items-center">
          <AddUserButton
            setShowNewUserForm={setShowNewUserForm}
            showNewUserForm={showNewUserForm}
          />
        </div>
      )}
    />
  )
}

export default Header
