import { useMessengerContext } from '../../../../../core/providers/MessengerProvider'
import ReplyPreview from '../ReplyPreview/ReplyPreview'

const NewMessageReplyPreview: React.FC = () => {
  const { messageProperties: [{ messageReply }] } = useMessengerContext()

  if (!messageReply?.referenceId) return null

  return (
    <div className="flex space-x-2 bg-blue-50 border border-white w-full p-2 absolute bottom-28 right-0">
      <ReplyPreview
        inChat
        author={messageReply.referenceName}
        message={messageReply.referenceText}
      />
    </div>
  )
}

export default NewMessageReplyPreview
