import { useTranslation } from 'react-i18next'
import WorkflowActionButton from '../misc/WorkflowActionButton'
import { useWorkflow } from '../../providers/WorkflowProvider'
import WorkflowMenu from '../misc/WorkflowMenu'
import useWorkflowMutations from '../../hooks/useWorkflowMutations'

export const WorkflowBuilderHeaderControls = () => {
  const { readonly: canOpenEditor, openBuilder, workflow } = useWorkflow()
  const { publish } = useWorkflowMutations()
  const { t } = useTranslation()

  const menuItems = [
    canOpenEditor && {
      name: t('general.edit', 'Edit'),
      onClick: openBuilder,
    },
    {
      name: t('general.history', 'History'),
      onClick: () => console.log('Side panel history clicked'),
    },
    {
      name: t('general.remove', 'Delete'),
      onClick: () => console.log('Side panel remove clicked'),
    },
  ]

  return (
    <div className="flex flex-row gap-2">
      { !workflow.published_at && <WorkflowActionButton onClick={() => publish(workflow)}>{t('general.publish')}</WorkflowActionButton> }
      <WorkflowMenu items={menuItems} />
    </div>
  )
}

export default WorkflowBuilderHeaderControls
