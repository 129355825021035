import useGetOffer from '../../../hooks/useGetOffer'
import { bannerStates } from '../../Footer/Banner/Banner'

const useGetSupplierOfferMaxContentHeight = () => {
  const { data: offerData } = useGetOffer()

  const heightOffsetWithBanner = 235
  const heightOffsetWithoutBanner = 179
  return `calc(100vh - ${(bannerStates.includes(offerData?.status || '')) ? heightOffsetWithBanner : heightOffsetWithoutBanner}px)`
}

export default useGetSupplierOfferMaxContentHeight
