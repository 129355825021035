import { Transition } from '@headlessui/react'

export const SidepanelTabTransition = ({ children } : React.PropsWithChildren) => (
  <Transition
    appear
    show
    enter="transition-opacity duration-300"
    enterFrom="opacity-0"
    enterTo="h-full opacity-100 w-full"
    leave="transition-opacity duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
)

export default SidepanelTabTransition
