import { useTranslation } from 'react-i18next'

interface ICompanyImageWrapper {
  image?: string
}

const CompanyImageWrapper: React.FC<ICompanyImageWrapper> = ({ image }) => {
  const { t } = useTranslation()
  return image
    ? <img width={150} src={image} alt="logo" className="object-contain h-20 max-w-[120px] w-fit" />
    : <p className="px-2">{`+ ${t('generic.add_a_logo')}`}</p>
}

export default CompanyImageWrapper
