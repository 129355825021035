import { useAbility } from '@casl/react'
import { AbilityContext } from '../../../core/providers/AbilityProvider'
import { FEATURE_FLAGS } from '../../../config/ability'

export const useIsSapIntegrationActive = () => {
  const ability = useAbility(AbilityContext)

  return ability.can(FEATURE_FLAGS.sapIntegrationActive)
}

export default useIsSapIntegrationActive
