import { apiGet, apiGetData, apiPostData } from '../../../core/utils/api/generic'
import { ApprovalFlowType } from '../../../types/Requisition/ApprovalFlowDrawerTypes'
import { TransmissionType } from '../../../types/shared/TransmissionTypes'

export const apiGetApprovalFlow = (requisitionId: string) => apiGetData<ApprovalFlowType>(`/requisitions/${requisitionId}/approval-flow`)
export const apiPostApprovalFlow = (requisitionId: string) => apiPostData<ApprovalFlowType[]>(`/requisitions/${requisitionId}/approval-flow/approve`)
export const apiPostUnrejectApprovalFlow = (requisitionId: string) => apiPostData<ApprovalFlowType[]>(`/requisitions/${requisitionId}/approval-flow/unreject`)
export const apiPostApprovalFlowReject = (requisitionId: string, reason: string) => apiPostData<ApprovalFlowType[]>(`/requisitions/${requisitionId}/approval-flow/reject`, { reason })

export const apiGetTransmissionHistory = (requisitionId: string) => apiGetData<TransmissionType[]>(`/integrations/transmissions/${requisitionId}`)
export const apiGetTransmissionPreview = (requisitionId: string) => apiGet<Record<string, string | number>>(`/integrations/sap/s4hana/preview/${requisitionId}`)
export const apiPostTransmission = async (requisitionId: string) => apiGetData<void>(`/integrations/sap/s4hana/${requisitionId}/process`)
