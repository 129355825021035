import { useState } from 'react'
import { useUtils } from '../../../../../core/providers/UtilsProvider'
import SaveCancelFormButtons from '../../../../../shared/components/organisms/formik/SaveCancelFormButtons'
import OptionType from '../../../../../types/shared/OptionType'
import checkTiptapTextIsEmpty from '../../../../../shared/utils/checkTiptapTextIsEmpty'
import { CustomerDocumentType } from '../../../../../types/SupplierOffer/SupplierOfferDataTypes'
import usePostCustomerDocuments from '../../../hooks/usePostCustomerDocuments'
import useUpdateCustomerDocuments from '../../../hooks/useUpdateCustomerDocuments'
import { DocumentContentType } from '../../../../../types/Company/DocumentType'
import DocumentFormContentInput from './DocumentFormContentInput'
import DocumentFormNameInput from './DocumentFormNameInput'
import DocumentFormTypeInput from './DocumentFormTypeInput'

interface IDocumentForm {
  document?: CustomerDocumentType
  editable?: boolean
}

const DocumentForm: React.FC<IDocumentForm> = ({ document, editable = true }) => {
  const { modal } = useUtils()

  const [documentForm, setDocumentForm] = useState<CustomerDocumentType |
    DocumentContentType>(document || {
      content: '',
      name: '',
      type: 'other',
    })

  const [formTouched, setFormTouched] = useState(false)

  const isContentError = !checkTiptapTextIsEmpty(documentForm.content)
  const isNameError = documentForm.name.length === 0

  const postDocument = usePostCustomerDocuments({ document: documentForm })
  const updateDocument = useUpdateCustomerDocuments({
    document: documentForm, documentId: document?.id,
  })

  const validateSubmit = () => (document?.id ? updateDocument() : postDocument())

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-4">
        {editable && (
          <div className="flex justify-between gap-2 w-full ">
            <DocumentFormNameInput
              handleChange={(v) => setDocumentForm((obj) => ({ ...obj, name: v }))}
              documentForm={documentForm}
              formTouched={formTouched}

            />
            <DocumentFormTypeInput
              documentForm={documentForm}
              handleChange={(v: OptionType) => {
                setFormTouched(false)
                setDocumentForm({ ...documentForm, type: v.value === 'nda' ? 'nda' : 'other' })
              }}
            />
          </div>
        )}
        <div className="flex flex-col w-full">
          <DocumentFormContentInput
            document={document}
            onUpdate={(e: string) => {
              setFormTouched(false)
              setDocumentForm((obj) => ({ ...obj, content: e }))
            }}
            documentForm={documentForm}
            formTouched={formTouched}
            editable={editable}
          />
        </div>
      </div>
      {editable && (
        <SaveCancelFormButtons
          onCancel={() => modal.set({ isOpen: false })}
          onSave={() => {
            setFormTouched(true)
            return isContentError || isNameError ? {} : validateSubmit()
          }}
        />
      )}
    </div>
  )
}

export default DocumentForm
