import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../../../../../shared/components/atoms/inputs/Checkbox'
import { FormQuestionType, FormSectionType } from '../../../../../../../../types/Forms'
import useQuestionMutations from '../../hooks/useQuestionMutations'

type CopyQuestionSetDefaultCheckboxProps = {
  question: FormQuestionType,
  section: FormSectionType
}

export const CopyQuestionSetDefaultCheckbox = ({ question, section }: CopyQuestionSetDefaultCheckboxProps) => {
  const { t } = useTranslation()
  const { updateLoop } = useQuestionMutations(question.__uuid, section)

  const handleChange = (checked: boolean) => {
    updateLoop({ hasCopiedValuesFromParent: checked, hasCopiedValuesFromParentisForced: checked })
  }

  return (
    <div className="flex items-center">
      <Checkbox checked={question.__loop?.hasCopiedValuesFromParent || false} onChange={handleChange} />
      <p className="text-sm ml-2">{t('form.should_copy_looped_value_by_default')}</p>
    </div>
  )
}

export default CopyQuestionSetDefaultCheckbox
