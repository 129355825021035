import { FC, PropsWithChildren } from 'react'
import Input from '../../shared/components/atoms/Input/Input'
import PageTopbar from '../../shared/components/layout/PageTopBar'
import Assignees from '../../shared/components/organisms/AssigneesSelector/AssigneesSelector'
import ContextMenu from './components/layout/ReqTopbar/ContextMenu'
import PreviewButton from './components/layout/ReqTopbar/PreviewButton'
import ReqTopbarSubmitButton from './components/layout/ReqTopbar/ReqTopbarSubmitButton'
import RequisitionTopbarMessengerButton from './components/layout/ReqTopbar/RequisitionTopbarMessengerButton'
import RequisitionNavigation from './components/layout/RequisitionNavigation'
import RequisitionTransmitButton from './components/layout/RequisitionTransmitButton'
import useIsSapIntegrationActive from './hooks/useIsSapIntegrationActive'
import ApprovalFlowButtons from './subModules/ApprovalFlowDrawer/ApprovalFlowButtons'
import useGetRequisitionData from './subModules/Definition/hooks/useGetRequisition'
import useUpdateRequisitionProperty from './subModules/Definition/hooks/useUpdateRequisitionProperty'
import useIsReqEditable from './utils/useIsReqEditable'

const RequisitionWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading } = useGetRequisitionData()

  const updateRequisitonName = useUpdateRequisitionProperty('name')
  const { editable: isEditable } = useIsReqEditable()
  const isSapIntegrationActive = useIsSapIntegrationActive()

  return (
    <div className="w-full">
      <PageTopbar
        backString="Back"
        backTarget="/requisitions"
        titleComponent={(
          <Input
            loading={isLoading}
            disabled={!isEditable}
            className="w-full text-2xl font-bold"
            defaultValue={data?.name}
            onBlur={(value) => updateRequisitonName(value)}
          />
        )}
        actions={(
          <div className=" h-full flex space-x-2 items-center mt-4">
            <div className="text-sm text-sky-blue capitalize">{data?.status}</div>
            <div>{data?.id && <Assignees origin="requisition" modelId={data.id} assignments={data.assignments} />}</div>
            <ContextMenu />
            <PreviewButton />
            <ReqTopbarSubmitButton />
            <ApprovalFlowButtons />
            <RequisitionTopbarMessengerButton />
            {isSapIntegrationActive && (window.location.href.includes('award') || window.location.href.includes('transfer')) && <RequisitionTransmitButton />}
          </div>
        )}
      />
      <div className="flex flex-1">
        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
          <div className="sticky top-0 pl-5 pt-5 flex w-52 flex-col overflow-y-auto">
            <RequisitionNavigation />
          </div>
        </aside>
        <div className="flex h-full min-w-0 flex-1 flex-col p-5 max-w-auto">
          {children}
        </div>
      </div>

    </div>
  )
}

export default RequisitionWrapper
