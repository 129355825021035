import { MdOutlineAddComment } from 'react-icons/md'
import { LineItemItemAttributeType } from '../../../../../types/shared/LineItemDataTypes'
import Tooltip from '../../../../../shared/components/organisms/tooltip/Tooltip'
import useOfferIsDisabled from '../../../hooks/useOfferIsDisabled'
import useToggleComment from '../../../hooks/useToggleComment'

interface ISupplierOfferRowOptions {
  row: LineItemItemAttributeType
  firstRow: boolean
  sectionIndex: number
}

const SupplierOfferRowOptions: React.FC<ISupplierOfferRowOptions> = (
  { row, sectionIndex },
) => {
  const initialLineItem = row?.line_items && row.line_items[0]
  const handleToggleComment = useToggleComment(initialLineItem, sectionIndex, row, 0)

  const isDisabled = useOfferIsDisabled()

  return (
    <div className="flex pt-3 relative">

      <div className="flex gap-x-2">
        <Tooltip
          showCondition={!isDisabled}
          tooltip="supplier_view.attributes_item_response_placeholder"
          className="h-fit"
        >
          <button
            onClick={handleToggleComment}
            type="button"
            disabled={isDisabled}
          >
            <MdOutlineAddComment
              className={`text-secondary-gray ${!!initialLineItem?.comment && 'text-sky-blue'}`}
              size={20}

            />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default SupplierOfferRowOptions
