import { useMutation, useQueryClient } from 'react-query'
import useGetCalculatedPrices from '../components/Footer/hooks/useGetCalculatedPrices'
import { putOrPostLineItem } from '../utils/utils'
import useGetToken from './useGetToken'
import useUpdateLineItemState from './useUpdateLineItemState'
import { PostOrPutLineItemBodyType } from '../../../types/shared/LineItemDataTypes'
import keys from '../../Requisition/subModules/Award/constants/OfferViewQueryKeys'

export default (sectionIndex: number, offerToken?: string) => {
  const token = useGetToken() || offerToken
  const updateLineItemState = useUpdateLineItemState()
  const { refetch } = useGetCalculatedPrices(offerToken)
  const queryClient = useQueryClient()

  return useMutation((attributes: PostOrPutLineItemBodyType) => putOrPostLineItem({
    ...attributes,
    token,
  }), {
    onSuccess: (data: any, variables: any) => {
      if (sectionIndex > -1) updateLineItemState(data, variables, sectionIndex)

      queryClient.invalidateQueries([keys.SECTIONS_DATA, token])
      refetch()
    },
  })
}
