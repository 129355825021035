import { useTranslation } from 'react-i18next'
import ErrorPage from '../ErrorPage'

const Unauthorized = () => {
  const { t } = useTranslation()

  return (
    <ErrorPage
      header="403"
      title={t('error.unauthorized')}
    />
  )
}

export default Unauthorized
