import { MdOutlineFileUpload } from 'react-icons/md'

interface ITipTapEditorAddFileButton {
  fileUploadRef: React.RefObject<HTMLInputElement>
  handleAddFiles: (files: FileList) => void
}

const TipTapEditorAddFileButton: React.FC<ITipTapEditorAddFileButton> = (
  { fileUploadRef, handleAddFiles },
) => (
  <div
    className="flex h-full items-center"
  >
    <button
      className="border-2 border-gray-500 p-3 rounded-md border-dotted cursor-pointer hover:border-indigo-800 group h-12 w-12 m-2"
      type="button"
      onClick={() => fileUploadRef?.current?.click()}
    >
      <MdOutlineFileUpload className="text-xl text-secondary-gray group-hover:text-indigo-800" />
      <input
        type="file"
        id="file"
        ref={fileUploadRef}
        multiple
        data-cy="tiptap-upload-file-button"
        name="image"
        className="hidden"
        onChange={(e) => handleAddFiles(e.target.files as FileList)}
      />
    </button>
  </div>
)

export default TipTapEditorAddFileButton
