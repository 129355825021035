interface IReqTopbarApprovalPendingText {
  text: string
}

const ReqTopbarApprovalPendingText: React.FC<IReqTopbarApprovalPendingText> = ({ text }) => (
  <div
    className="flex items-center px-3 py-2 text-xs rounded-md group whitespace-nowrap"
  >
    {text}
  </div>
)

export default ReqTopbarApprovalPendingText
