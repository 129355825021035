import {
  Dispatch, KeyboardEventHandler, SetStateAction, useEffect, useRef,
} from 'react'
import { IInputWithSaveCancelButtons } from './InputWithSaveCancelButtons'

type IInput = Pick<
  IInputWithSaveCancelButtons,
  'placeholder' | 'disabled' | 'showError' | 'setTitle' | 'title'
  | 'onSave' | 'onCancel' | 'inputClassName' | 'charLimit' | 'focusOnLoad'
>
  & { setIsActive: Dispatch<SetStateAction<boolean>> }

const Input: React.FC<IInput> = ({
  placeholder, disabled, showError, setTitle, title, onSave, onCancel, setIsActive, inputClassName,
  charLimit, focusOnLoad,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const isSaveDisabled = disabled || (charLimit && charLimit < title.length) || !title.length

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (['Enter', 'Escape'].includes(e.key)) {
      if (e.key === 'Enter') {
        if (!isSaveDisabled) {
          onSave()
          inputRef?.current?.blur()
          setIsActive(false)
          onCancel()
        }
      } else if (e.key === 'Escape') {
        onCancel()
        inputRef?.current?.blur()
        setIsActive(false)
      }
    }
  }

  useEffect(() => {
    if (focusOnLoad) inputRef.current?.focus()
  }, [])

  return (
    <input
      type="text"
      onFocus={() => setIsActive(true)}
      ref={inputRef}
      placeholder={placeholder}
      className={`${inputClassName} focus:ring-0 w-full rounded-lg ${showError ? 'border-red-600 focus:border-red-600' : 'focus:border-sky-blue border-gray-200 hover:border-gray-400'}`}
      disabled={disabled}
      data-cy="req-topbar-title"
      value={title}
      onKeyDown={onKeyDown}
      onChange={(e) => setTitle(e.currentTarget.value)}
    />
  )
}

export default Input
