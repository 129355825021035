import { useState } from 'react'
import OfferViewContainer from './components/OfferViewContainer'

const AwardPage = () => {
  const useLastCallState = useState<number[]>()

  return (
    <OfferViewContainer useLastCallState={useLastCallState} />
  )
}

export default AwardPage
