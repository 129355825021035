import { MdFilterList } from 'react-icons/md'
import { Popover } from '@headlessui/react'
import {
  Dispatch, SetStateAction, useEffect, useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import FiltersDataType from '../../../../types/shared/FilterTypes'
import { MetaObjectType } from '../../../../types/shared/GeneralDataTypes'
import useSearchUnifiedElements from '../../../../modules/Requisition/hooks/useSearchUnifiedElements'
import Button from '../../atoms/buttons/Button'
import FilterBlock from '../../atoms/Filters/FilterBlock'
import SharedDatepickerRange from '../DatePickerRange/DatePickerRange'

interface IFilters {
  meta?: {
    categories: MetaObjectType
    supplier: MetaObjectType
    max_date: {
      value: number
      value_as_string: string
    }
    min_date: {
      value: number
      value_as_string: string
    }
  },
  filterState: [FiltersDataType, Dispatch<SetStateAction<FiltersDataType>>],
  query: string,
  disabled: boolean
}

const SharedFilters: React.FC<IFilters> = ({
  meta,
  filterState,
  query,
  disabled,
}) => {
  const [filterData, setFilterData] = filterState
  const { t } = useTranslation()
  const { mutate: searchUnifiedElements } = useSearchUnifiedElements()

  const handlerDateRange = (value: (Date | null)[]) => {
    setFilterData(
      (filters) => ({ ...filters, dateRange: value }),
    )
  }

  const handlerOtherFilters = (value: string, key: 'category' | 'supplierName') => {
    setFilterData(
      (filters) => ({ ...filters, [key]: value }),
    )
  }

  const prevFilters = useRef(filterData)
  useEffect(() => {
    if (filterData !== prevFilters.current
      && filterData?.dateRange.length !== 1) { // Update if range filter is empty or completely set.
      searchUnifiedElements({ query, filters: filterData })
      prevFilters.current = filterData
    }
  }, [filterData])

  return (
    <Popover className="relative">
      <Popover.Button
        disabled={disabled}
        className={`flex px-4 py-2 rounded gap-2 
      ${filterData?.dateRange.length || filterData.supplierName || filterData.category ? 'bg-orange-400 text-white' : 'border-gray-200 text-gray-500 border'}`}
      >
        <MdFilterList className="text-lg" />
        <p className="text-xs">{t('generic.filters')}</p>
      </Popover.Button>

      <Popover.Panel
        className="absolute p-4 z-10 right-0 mt-1 h-fit
       bg-white border-gray-200 border rounded-lg
         flex gap-2"
      >
        {meta
          && (
            <div className="flex flex-col h-fit gap-y-1">
              <div className="flex justify-between items-center">
                <p className="text-sm text-gray-500">{t('dashboard.select_range')}</p>
                {!!filterData?.dateRange.length && (
                  <Button
                    color="lhotse-orange"
                    className="text-xs py-0"
                    onClick={() => setFilterData((f) => ({ ...f, dateRange: [] }))}
                  >
                    {t('generic.clear')}
                  </Button>
                )}
              </div>
              <SharedDatepickerRange
                selected={filterData?.dateRange || []}
                onChange={(value) => handlerDateRange(value)}
                placeholder="Date range"
                minDate={new Date(meta.min_date.value_as_string)}
                maxDate={new Date(meta.max_date.value_as_string)}
              />
            </div>
          )}
        {meta?.categories && (
          <FilterBlock
            filterTitle={t('suppliers.list_category')}
            filterOptions={meta.categories.buckets}
            selectedFilter={filterData.category}
            setSelectedFilter={(val: string) => handlerOtherFilters(val, 'category')}
          />
        )}

        {meta?.supplier && (
          <FilterBlock
            filterTitle={t('generic.supplier')}
            filterOptions={meta.supplier.buckets}
            selectedFilter={filterData.supplierName}
            setSelectedFilter={(val: string) => handlerOtherFilters(val, 'supplierName')}
          />
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default SharedFilters
