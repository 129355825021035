import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { FormikInputType } from '../../../../types/shared/FormikType'

interface IFormikSelect extends FormikInputType {
  placeholder?: string
  noPlaceholder?: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: { value: number | string, label: string, }[]
}

const FormikSelect: React.FC<IFormikSelect> = ({
  name, handleChange, dataCy, values, error, placeholder, noPlaceholder,
  handleOnEnter, min, max, validate, disabled, handleOnBlur,
  customOnChange, className, label, options,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`flex ${label && 'flex-col'} ${className}`}>
      {label && <label htmlFor={name} className="text-gray-400 text-xs outline-none">{t(label)}</label>}
      <Field
        as="select"
        id={dataCy}
        disabled={disabled}
        name={name}
        value={(values && values[name]) || ''}
        onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
          handleChange(v)
          if (customOnChange) {
            customOnChange(v)
          }
        }}
        data-cy={dataCy}
        onBlur={handleOnBlur
          ? (e: any) => handleOnBlur({ name, value: e.target.defaultValue })
          : () => { }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && handleOnEnter) {
            e.preventDefault()
            handleOnEnter()
          }
        }}
        min={min}
        max={max}
        validate={validate}
        className={`
        border-0 border-b-2 border-gray-200  w-full
        focus:bg-white
        text-gray-900 text-sm
        placeholder-gray-300
        px-0 focus:ring-0
        disabled:border-b disabled:border-dashed disabled:bg-white
        disabled:opacity-60 disabled:border-red-500
        ${error?.props.error && 'border-red-600'}
        `}
      >
        {!noPlaceholder && <option value="">{placeholder ? t(placeholder) : t('generic.select')}</option>}
        {options.map((o) => <option value={o.value} key={`${o.value}-${o.label}`}>{t(o.label)}</option>)}
      </Field>
      <span className="py-1">
        {error?.props.error}
      </span>
    </div>
  )
}

export default FormikSelect
