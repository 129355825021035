import axios, { AxiosError, AxiosHeaders, InternalAxiosRequestConfig } from 'axios'
import i18n from '../../i18n'
import toastr from '../../shared/components/organisms/toastr/toastr'
import { redirectToError, redirectToLogin, redirectToUnauthorized } from './authUtils'
import { getApiUrl, handleWrongTenantUrl } from './tenancy'

const STORAGE_ATTRIBUTE = 'lhotseAuth'

export const getToken = () => {
  const lhotseAuth = localStorage.getItem(STORAGE_ATTRIBUTE)
  if (lhotseAuth) {
    const parsedAuth = JSON.parse(lhotseAuth)
    return parsedAuth?.token as string
  }
  return undefined
}

export const apiClient = axios.create({
  baseURL: getApiUrl(),
  timeout: 0,
})

apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getToken()

    const headers = new AxiosHeaders()
    headers.set('Accept', 'application/json')
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('X-Language', i18n.language)

    return { ...config, headers }
  },
  (error) => {
    toastr.error('error.unable_to_connect', 'unableToConnectError')
    return Promise.reject(error)
  },
)

apiClient.interceptors.response.use((response) => response, (error: AxiosError) => {
  const status = error.response?.status
  const method = error.config?.method
  const pathname = window.location?.pathname

  if (status === 401 && pathname !== '/login') return redirectToLogin()
  if (status === 404 && method === 'get') return redirectToError()
  if (status === 403 && method === 'get') return redirectToUnauthorized()
  if (status === 501) return handleWrongTenantUrl()

  return toastr.error(error.response?.statusText || 'error.generic')
})

export function setHeaders() {
  apiClient.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  )
}

export default apiClient
