import { invalidIdStateType } from '../../../utils/validationUtils'
import useGetSectionsOrLineItems from './hooks/useGetSectionsOrLineItems'
import Section from './Section'

interface ISections {
  requisitionId: number | undefined
  invalidIds: invalidIdStateType | undefined
  updateAutoSave: () => void
}

const Sections: React.FC<ISections> = ({
  requisitionId, updateAutoSave, invalidIds,
}) => {
  const { data: sectionsData } = useGetSectionsOrLineItems({ requisitionId })

  return (
    <div>
      {sectionsData?.map((section, sectionIndex) => {
        if (section.section_type === 'FINAL_OFFER') return null

        return (
          <Section
            key={section.section_id || section.id}
            requisitionId={requisitionId}
            invalidIds={invalidIds}
            updateAutoSave={updateAutoSave}
            section={section}
            sectionIndex={sectionIndex}
          />
        )
      })}
    </div>
  )
}

export default Sections
