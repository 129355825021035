import { useTranslation } from 'react-i18next'
import { getUnderlineInputClasses } from './underlineConstants'

interface IInputGrayUnderline {
  name: string
  handleChange: (v: string) => void
  value?: string
  dataCy?: string,
  placeholder?: string,
  min?: number
  max?: number
  disabled?: boolean
  className?: string
  label?: string
}

const InputGrayUnderline: React.FC<IInputGrayUnderline> = ({
  value, handleChange, dataCy, placeholder, min, max, disabled, className, label,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`flex flex-col ${className}`}>
      {label && <label className="text-gray-300 mb-2 text-sm outline-none">{t(label)}</label>}
      <input
        value={value}
        disabled={disabled}
        placeholder={t(placeholder || '')}
        onChange={(e) => {
          handleChange(e.target.value)
        }}
        data-cy={dataCy}
        min={min}
        max={max}
        className={getUnderlineInputClasses(false, className)}
      />
    </div>
  )
}

export default InputGrayUnderline
