import { useQuery } from 'react-query'
import { CalculatedPriceType } from '../../../../../types/shared/RequisitionDataTypes'
import { apiGetData } from '../../../../../core/utils/api/generic'
import useGetToken from '../../../hooks/useGetToken'

export const useGetCalculatedPrices = (offerToken: string = '') => {
  const token = useGetToken() || offerToken

  const getCalculatedPrice = (supplierToken: string) => apiGetData<CalculatedPriceType>(`/offer/${supplierToken}/calculated-price`)
    .then(({ product_prices, ...metadata }) => ({
      ...metadata,
      product_prices: Array.isArray(product_prices) ? product_prices : Object.values(product_prices),
    } as CalculatedPriceType))

  return useQuery(['calculated-price', token], () => getCalculatedPrice(token), { enabled: !!token })
}

export default useGetCalculatedPrices
