import { useTranslation } from 'react-i18next'
import useGetSupplierBranch from '../hooks/useGetSupplierBranch'

const AddressInfo = () => {
  const { t } = useTranslation()
  const { data: supplierBranchData } = useGetSupplierBranch()

  const address = supplierBranchData?.address

  const formattedAddress = `
    ${address?.street ? `${address?.street},` : ''} 
    ${address?.zip ? `${address?.zip},` : ''}
    ${address?.city ? `${address?.city},` : ''},
    ${address?.country?.name || ''}
  `

  return (
    <div className="flex flex-col text-sm text-primary-gray">
      {address
        ? (
          <>
            <p data-cy="address-name">{address?.name}</p>
            <p>{formattedAddress}</p>
          </>
        )
        : <p>{t('suppliers.no_address')}</p>}
    </div>
  )
}

export default AddressInfo
