import {
  ArchiveBoxArrowDownIcon, CalendarDaysIcon, ClockIcon, UserIcon, UsersIcon,
} from '@heroicons/react/24/outline'
import { UsersIcon as UsersIconSolid } from '@heroicons/react/24/solid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import RequisitionsFilterMultiselect from '../RequisitionsFilterMultiselect'
import RequisitionsFilterSingleSelectWithDate from '../RequisitionsFilterSingleSelectWithDate'
import { RequisitionFilterConfig } from '../types'

export const useRequisitionFilterOptions = (): readonly RequisitionFilterConfig[] => {
  const { t } = useTranslation()

  return useMemo(() => [
    {
      id: 'current_status',
      label: t('requisition_filter.current_status'),
      icon: (<ClockIcon className="h-4" />),
      component: RequisitionsFilterMultiselect,
      type: 'text',
    },
    {
      id: 'user_id',
      label: t('requisition_filter.user_id'),
      icon: (<UserIcon className="h-4" />),
      component: RequisitionsFilterMultiselect,
      type: 'user',
    },
    {
      id: 'created_between',
      label: t('requisition_filter.created_between'),
      icon: (<CalendarDaysIcon className="h-4" />),
      component: RequisitionsFilterSingleSelectWithDate,
      type: 'date-range',
    },
    {
      id: 'approval_flow_assignees',
      label: t('requisition_filter.approvers'),
      icon: (<UsersIcon className="h-4" />),
      component: RequisitionsFilterMultiselect,
      type: 'user',
    },
    {
      id: 'ends_between',
      label: t('requisition_filter.ends_between'),
      icon: (<ArchiveBoxArrowDownIcon className="h-4" />),
      component: RequisitionsFilterSingleSelectWithDate,
      type: 'date-range',
    },
    {
      id: 'assigned_to',
      label: t('requisition_filter.assigned_to'),
      icon: (<UsersIconSolid className="h-4" />),
      component: RequisitionsFilterMultiselect,
      type: 'user',
    },
  ] as const, [])
}

export default useRequisitionFilterOptions
