import { FormQuestionType } from '../../../../../../../types/Forms'
import NumberOrQuestionValidationProperty, { NumberPropertyProps } from './NumberOrQuestionValidationProperty'

export const NumberOrSameTypeQuestionValidationProperty = ({ question, ...props }: Omit<NumberPropertyProps, 'availableQuestionCondition'>) => (
  <NumberOrQuestionValidationProperty
    {...props}
    question={question}
    availableQuestionCondition={({ type }: FormQuestionType) => question.type === type}
  />
)

export default NumberOrSameTypeQuestionValidationProperty
