import { useTranslation } from 'react-i18next'
import { FormSectionType } from '../../../../../../../types/Forms/formTypes'
import FormLogicQuestion from './FormLogicQuestion'
import LoopFormGroupsSwitch from './LoopFormGroupsSwitch'

interface IFormLogicGroup {
  section: FormSectionType
}

const FormLogicGroup: React.FC<IFormLogicGroup> = ({ section }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-white p-4 rounded-xl border border-border-gray gap-y-4 flex flex-col">
      <div className="flex flex-col gap-y-1">
        <p className="text-primary-gray font-medium">{section.name || 'Undefined'}</p>
        <p className="text-secondary-gray text-xs font-medium">{t(section.type)}</p>
      </div>
      <LoopFormGroupsSwitch section={section} />
      {section.fields.map((question) => (
        <FormLogicQuestion
          key={question.__uuid}
          question={question}
          section={section}
        />
      ))}
    </div>
  )
}

export default FormLogicGroup
