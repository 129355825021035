import { useTranslation } from 'react-i18next'
import { CompanyUserType } from '../../../../../types/shared/RequisitionDataTypes'
import TableCell from '../../../../../shared/components/atoms/SharedTable/TableCell'
import UserAvatar from '../../../../../shared/components/atoms/UserAvatar/UserAvatar'
import generateUserName from '../../../../../shared/utils/generateUserName'

interface IUserName {
  user: CompanyUserType
}

const UserName: React.FC<IUserName> = ({
  user,
}) => {
  const { t } = useTranslation()
  return (
    <TableCell first>
      <div className="flex gap-x-2">
        <UserAvatar name={user?.email} />
        {t(generateUserName(user))}
      </div>
    </TableCell>
  )
}

export default UserName
