import { useQueryClient } from 'react-query'
import toastr from '../../../../../../../../../shared/components/organisms/toastr/toastr'
import { undef } from '../../../../../../../../../types/shared/GeneralTypes'
import useGetRequisitionId from '../../../../../../../../../shared/hooks/useGetRequisitionId'
import OfferViewQueryKeys from '../../../../../../Award/constants/OfferViewQueryKeys'
import { ReqDefEditorDataType } from '../../../../../constants/editorTypes'
import { deleteSection } from '../../../../../services/editorService'

const useDeleteSection = (updateAutoSave: () => void) => {
  const cache = useQueryClient()
  const requisitionId = useGetRequisitionId()

  return async (sectionId: number) => {
    try {
      await deleteSection(sectionId)

      cache.setQueryData<ReqDefEditorDataType>([OfferViewQueryKeys.SECTIONS_DATA, requisitionId], (
        oldSections: undef<ReqDefEditorDataType>,
      ) => oldSections?.filter((section) => section.id !== sectionId) || [])

      updateAutoSave()
    } catch {
      toastr.error()
    }
  }
}

export default useDeleteSection
