import { useTranslation } from 'react-i18next'
import { MdOutlineEmail } from 'react-icons/md'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import { useCoordinateProvider } from '../../providers/CoordinateProvider'
import useHandleOpenInviteModal from './hooks/useHandleOpenInviteModal'

const BulkInviteButton: React.FC = () => {
  const { t } = useTranslation()
  const { selectedInvites: [selectedInvites] } = useCoordinateProvider()

  const handleOpenInviteModal = useHandleOpenInviteModal()

  return (
    <Button
      variant="solid"
      className="text-sm btn-secondary hover:bg-sky-blue transition-colors"
      onClick={handleOpenInviteModal}
      disabled={!selectedInvites.length}
      dataCy="coordinate-bulk-invite-button"
    >
      <MdOutlineEmail size={18} />
      <p className="text-xs whitespace-nowrap">{t('req_coordinate.bulk_invite')}</p>
    </Button>
  )
}

export default BulkInviteButton
