import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import { INodeChildren } from '../../../../types/shared/GeneralTypes'

interface IFileDropzone extends INodeChildren {
  onFileUpload: (acceptedFiles: File[]) => void
  classNames?: string | null,
  disabled?: boolean,
}

const FileDropzone: React.FC<IFileDropzone> = ({
  children, onFileUpload, classNames, disabled = false,
}) => {
  const onDrop = useCallback(onFileUpload, [onFileUpload])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={classnames('border border-dashed rounded-lg', {
          'border-vibrant-blue': isDragActive,
          'border-tertiary-gray': !isDragActive,
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        }, classNames)}
      >
        {children}
      </div>
    </div>
  )
}

export default FileDropzone
