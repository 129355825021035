import { StarIcon, TrashIcon } from '@heroicons/react/24/outline'
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { useUtils } from '../../../../../../core/providers/UtilsProvider'
import TableCell from '../../../../../../shared/components/atoms/SharedTable/TableCell'
import TableRow from '../../../../../../shared/components/atoms/SharedTable/TableRow'
import Button from '../../../../../../shared/components/atoms/buttons/Button'
import { Offer } from '../../../../../../types/Offering'
import useDeleteOffer from '../../../../hooks/useDeleteOffer'
import useUpdateOffer from '../../../../hooks/useUpdateOffer'
import ProductSectionOfferModal from '../ProductSectionOfferModal/ProductSectionOfferModal'
import { priceToGermanPriceString } from '../../../../../../shared/constants/currencyFormat'

type IProductSectionOffersTableRow = {
  offer: Offer
}

const ProductSectionOffersTableRow: React.FC<IProductSectionOffersTableRow> = ({ offer }) => {
  const { modal } = useUtils()
  const { mutate: deleteOffer } = useDeleteOffer()
  const { mutateAsync: updateOffer } = useUpdateOffer()
  const { t } = useTranslation()

  const handleEditOffer = () => {
    modal.set({ isOpen: true, content: <ProductSectionOfferModal offer={offer} updateOffer={updateOffer} />, title: 'Edit Offer' })
  }

  return (

    <TableRow
      key={offer.uuid}
      onClick={() => handleEditOffer()}
    >
      <TableCell>
        {offer.custom_id}
      </TableCell>
      <TableCell>
        {offer.supplier}
      </TableCell>
      <TableCell>
        {priceToGermanPriceString(offer.total_net_price)}
      </TableCell>

      <TableCell className="text-right">
        <div className="flex justify-end ">
          <Button
            className="btn-ghost"
            onClick={async (e) => {
              e.stopPropagation()
              deleteOffer(offer)
            }}
          >
            <TrashIcon className="w-4" />
          </Button>
          <Button
            className="btn-ghost"
            title={t('generic.preferred-offer', 'Preferred Offer')}
            onClick={async (e) => {
              e.stopPropagation()
              updateOffer({ ...offer, is_selected: !offer.is_selected })
            }}
          >
            {offer.is_selected ? <SolidStarIcon className="w-4 text-sun-yellow " /> : <StarIcon className="w-4" />}
          </Button>
        </div>
      </TableCell>
    </TableRow>

  )
}

export default ProductSectionOffersTableRow
