import useGetFinalOfferFileItem from '../../hooks/useGetFinalOfferFileItem'
import useUpdateLineItemCommentCache from '../../hooks/useUpdateLineItemCommentCache'
import Comment from '../DescriptionSection/Row/Comment'
import useUpdateOfferComment from '../DescriptionSection/Row/hooks/useUpdateOfferComment'

interface ICommentInput {
  updateAutoSave: () => void
}

const CommentInput: React.FC<ICommentInput> = ({
  updateAutoSave,
}) => {
  const finalOfferItem = useGetFinalOfferFileItem()
  const comment = finalOfferItem?.line_items[0]?.comment?.comment

  const updateCommentState = useUpdateLineItemCommentCache()
  const updateComment = useUpdateOfferComment()

  const handleOnBlur = () => updateComment(
    finalOfferItem?.line_items[0],
    updateAutoSave,
  )

  return typeof comment === 'string'
    ? (
      <div className="p-5 pt-0 w-full">
        <Comment
          comment={comment}
          onChange={(newComment: string | null) => {
            updateCommentState(newComment, 1, finalOfferItem, 0)
          }}
          onBlur={handleOnBlur}
          dataCy="final-offer-documents-comment"
        />
      </div>
    )
    : null
}

export default CommentInput
