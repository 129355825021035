import { FC } from 'react'
import useFilteredForms from '../../hooks/useFilteredForms'
import LhotseHomeCreateResult from './LhotseHomeCreateResult'

interface ILhotseHomeCreateResultList {
  searchQuery?: string
}
const LhotseHomeCreateResultList: FC<ILhotseHomeCreateResultList> = () => {
  const { filteredForms } = useFilteredForms()

  return (
    <div className="flex flex-col gap-y-4">
      {filteredForms?.map((form) => <LhotseHomeCreateResult form={form} key={form.__uuid} />)}
    </div>
  )
}

export default LhotseHomeCreateResultList
